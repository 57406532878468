import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './Modal.css';
import './LandingPage.module.css';
import AppContext from '../app/AppContext';
import { isValidURL } from '../helpers/validationFunctions';
import countries from '../helpers/countries';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import { localiseText } from '../../utils';

class OrganisationSettingsTab extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            passwordConfirmation: '',
            organisationAddressCountry: '',
            subscriptionDetailsSection: false,
            accountDetailsReadyForSubmission: false,
            accountAdditionalDetailsReadyForSubmission: false,
            organisationDetailsEdited: false,
            organisationAdditionalDetailsEdited: false,
            countryCodeOptions: countries,
            inputErrors: {},
            organisationCountryCode: '',
            organisationNumber: '',
            organisationSizes: [],
            organisationRegistrationPlatforms: [],
            currencies: [],

            name: '',
            address_line1: '',
            address_line2: '',
            postcode: '',
            city: '',
            country: {},
            website: '',
            phone: '',
            phoneCode: '',
            sizeTier: '',
            registrationPlatform: '',
            currency: '',

            currentName: '',
            currentAddress_line1: '',
            currentAddress_line2: '',
            currentPostcode: '',
            currentCity: '',
            currentCountry: '',
            currentWebsite: '',
            currentPhone: '',
            currentPhoneCode: '',
            currentSizeTier: '',
            currentRegistrationPlatform: '',
            currentCurrency: ''
        };
    }

    componentDidMount () {
        const token = this.context.token
            ? this.context.token
            : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
    
        let initialOrganisationSizes = [];
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react`;

        // Fetch Organisation Sizes
        axios
            .get(`${apiCallURL}/lookup/size_tiers/`, { headers })
            .then(res => res.data)
            .then(data => {
                
                initialOrganisationSizes = data?.data?.map(({ id, title, range }) => ({ id, name: `${title} (${range})` }));

                this.setState({
                    organisationSizes: initialOrganisationSizes,
                });
            });

        // Fetch currencies
        axios
            .get(`${apiCallURL}/lookup/currencies/`, { headers })
            .then(res => res.data)
            .then(({ data }) => {
                

                this.setState({
                    currencies: data,
                });
            });

        // Fetch Organisation Registration Platforms
        let initialOrganisationRegistrationPlatforms = [];

        axios
            .get(`${apiCallURL}/lookup/registration_platforms/`, { headers })
            .then(res => res.data)
            .then(data => {
                initialOrganisationRegistrationPlatforms = data?.data?.map(({ id, name }) => ({ id, name: `${name}` }));

                this.setState({
                    organisationRegistrationPlatforms: initialOrganisationRegistrationPlatforms,
                });
            });
        
        this.getOrganisationDetails();
        
    }

    getOrganisationDetails = () => {
        const token = this.context.token
            ? this.context.token
            : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
    
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react`;

        // Fetch current organization
        axios
            .get(`${apiCallURL}/organisations/${this.context.selectedOrganisation.id}/`, { headers })
            .then(res => res.data)
            .then(({ data }) => {
                this.setState({
                    currentName: data.name,
                    currentAddress_line1: data?.address?.address_line1,
                    currentAddress_line2: data?.address?.address_line2,
                    currentCity: data?.address?.city,
                    currentCountry: data?.address?.country?.id,
                    currentPostcode: data?.address?.postcode,
                    currentWebsite: data?.website,
                    currentPhone: data?.phone?.number,
                    currentPhoneCode: data?.phone?.country_id || data?.address?.country?.id,
                    currentSizeTier: data?.size_tier,
                    currentCurrency: data?.currency,
                    currentRegistrationPlatform: data?.registration_platform,

                    name: data.name,
                    address_line1: data?.address?.address_line1,
                    address_line2: data?.address?.address_line2,
                    city: data?.address?.city,
                    country: data?.address?.country?.id,
                    postcode: data?.address?.postcode,
                    website: data?.website,
                    phoneCode: data?.phone?.country_id || data?.address?.country?.id,
                    phone: data?.phone?.number,
                    sizeTier: data?.size_tier,
                    currency: data?.currency,
                    registrationPlatform: data?.registration_platform,
                });
            });
    }


    toggleSubscriptionDetailsSection = () => {
        this.setState(prevState => ({
            subscriptionDetailsSection: !prevState.subscriptionDetailsSection
        }));
    };

    handleAllowFormSubmit = () => {
        // eslint-disable-next-line max-len
        const newPhone = this.state.countryCodeOptions.find(countryCode => countryCode?.id === +this.state?.phoneCode)?.phone?.replaceAll('-','') + this.state?.phone;
        // eslint-disable-next-line max-len
        const currentPhone = this.state.countryCodeOptions.find(countryCode => countryCode?.id === +this.state?.currentPhoneCode)?.phone?.replaceAll('-','') + this.state?.currentPhone;
        
        if (this.state.accountDetailsReadyForSubmission === false) {
            if (
                this.state.name &&
                this.state.address_line1 &&
                this.state.city &&
                this.state.postcode &&
                this.state.country && 
                this.state.website &&
                this.state.phone && 
                this.state.phoneCode &&
                this.state.registrationPlatform &&
                (this.state.name !== this.state.currentName ||
                    this.state.address_line1 !== this.state.currentAddress_line1 ||
                    this.state.city !== this.state.currentCity ||
                    this.state.postcode !== this.state.currentPostcode || 
                    this.state.country !== this.state.currentCountry ||
                    this.state.website !== this.state.currentWebsite ||
                    newPhone !== currentPhone ||
                    this.state.address_line2 ||
                    this.state.registrationPlatform !== this.state.currentRegistrationPlatform
                )
            ) {
                this.setState({
                    accountDetailsReadyForSubmission: true,
                });
            }
        }
        else if (
            !this.state.name ||
            !this.state.address_line1 ||
            !this.state.city ||
            !this.state.postcode ||
            !this.state.country || 
            !this.state.website ||
            !this.state.phone ||
            !this.state.phoneCode ||
            !this.state.registrationPlatform
            // this.state.name === this.state.currentName ||
            // this.state.address_line1 === this.state.currentAddress_line1 ||
            // this.state.city === this.state.currentCity ||
            // this.state.postcode === this.state.currentPostcode || 
            // this.state.country === this.state.currentCountry ||
            // this.state.website === this.state.currentWebsite
        ) {
            this.setState({
                accountDetailsReadyForSubmission: false,
            });
        }
    };

    handleAdditionalInfoChange = e => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                organisationAdditionalDetailsEdited: true
            },
            () => {
                if (this.state.accountAdditionalDetailsReadyForSubmission === false) {
                    if (
                        this.state.sizeTier !== this.state.currentSizeTier || this.state.currency !== this.state.currentCurrency
                    ) {
                        this.setState({
                            accountAdditionalDetailsReadyForSubmission: true,
                        });
                    }
                }
                else if (
                    this.state.sizeTier === this.state.currentSizeTier || this.state.currency === this.state.currentCurrency
                ) {
                    this.setState({
                        accountAdditionalDetailsReadyForSubmission: false,
                    });
                }
            }
        );
    };

    handleChange = e => {
        this.setState({
            organisationDetailsEdited: true,

        });
        if (e.target.name === 'country') {

            if (!this.state.phoneCode) {
                this.setState({
                    phoneCode: e.target.value
                });
            }
            this.setState({
                [e.target.name]: e.target.value,
                organisationCountryCode: e.target.value,
            }, this.handleAllowFormSubmit);
        }
        else if (e.target.name === 'organisationRegistrationPlatform') {
            this.setState({
                registrationPlatform: e.target?.value,
            }, this.handleAllowFormSubmit);
        }
        else if (e.target.name === 'website') {
            if (e.target.value.length > 3) {
                if (!isValidURL(e.target.value)) {
                    this.setState({
                        ...this.state.inputErrors,
                        websiteError: true,
                    });
                }
                else {
                    this.setState({
                        ...this.state.inputErrors,
                        websiteError: null,
                    },this.handleAllowFormSubmit);
                }
            }
            this.setState({
                [e.target.name]: e.target.value,
            }, this.handleAllowFormSubmit);
        }
        else if (e.target.name === 'phone') {
            this.setState({
                phone: e.target?.value,
            }, this.handleAllowFormSubmit);
        }
        else {
            this.setState({
                [e.target.name]: e.target.value,
            }, this.handleAllowFormSubmit);
        }
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };


    handleOrganisationAdditionalInfoSubmit = e => {
        if (this.state.accountAdditionalDetailsReadyForSubmission) {
            e.preventDefault();
            const token = this.context.token ? this.context.token : localStorage.getItem('token');
            // eslint-disable-next-line max-len
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/`;
            const headers = {
                Authorization: `Token ${token}`,
            };
            const newAccountData = {};
            if (this.state.sizeTier !== this.state.currentSizeTier) {
                newAccountData.size_tier = this.state.sizeTier;
            }
            if (this.state.currency !== this.state.currentCurrency) {
                newAccountData.currency = this.state.currency;
            }

            axios
                .patch(
                    apiCallURL,
                    newAccountData,
                    { headers }
                )
                .then(() => {
                    this.setState(
                        {
                            passwordReadyForSubmission: false,
                            inputErrors:{},
                            organisationAdditionalDetailsEdited: false,
                            accountAdditionalDetailsReadyForSubmission: false,
                        },
                        () => {
                            this.props.displaySuccessMessage('Your subscription details have been updated.');
                            this.toggleSubscriptionDetailsSection();
                        }
                    );
                    this.getOrganisationDetails();
                    // this.state.currency && window.location.reload();
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            console.log('Add password form validation');
        }
    };

    handleAccountDetailsSubmit = e => {
        if (this.state.accountDetailsReadyForSubmission) {
            e.preventDefault();
            const token = this.context.token ? this.context.token : localStorage.getItem('token');
            // eslint-disable-next-line max-len
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/`;
            const headers = {
                Authorization: `Token ${token}`,
            };
            const newAccountData = {};
            if (this.state.name !== this.state.currentName) {
                newAccountData.name = this.state.name;
            }
            if (this.state.address_line1 !== this.state.currentAddress_line1) {
                newAccountData.address = { ...newAccountData.address, address_line1: this.state.address_line1 };
            }
            if (this.state.address_line2 !== this.state.currentAddress_line2) {
                newAccountData.address = { ...newAccountData.address, address_line2: this.state.address_line2 };
            }
            if (this.state.city !== this.state.currentCity) {
                newAccountData.address = { ...newAccountData.address, city: this.state.city };
            }
            if (this.state.country !== this.state.currentCountry) {
                newAccountData.address = { ...newAccountData.address, country_id: this.state.country };
            }
            if (this.state.postcode !== this.state.currentPostcode) {
                newAccountData.address = { ...newAccountData.address, postcode: this.state.postcode };
            }
            if (this.state.website !== this.state.currentWebsite) {
                newAccountData.website = this.state.website ;
            }
            if (this.state.phone !== this.state.currentPhone) {
                newAccountData.phone = this.state.phone ;
            }
            if (this.state.phoneCode !== this.state.currentPhoneCode || this.state.currentPhone !== this.state.phone) {
                const phone = this.state.countryCodeOptions
                    .find(countryCode => countryCode.id === +this.state.phoneCode).phone
                    .replaceAll('-','') + this.state.phone;
                newAccountData.phone = phone ;
            }
            if (this.state.registrationPlatform !== this.state.currentRegistrationPlatform) {
                newAccountData.registration_platform = this.state.registrationPlatform;
            }

            axios
                .patch(apiCallURL, newAccountData, {
                    headers,
                })
                .then(() => {
                    this.context.setSelectedOrganisation({
                        ...this.context.selectedOrganisation,
                        name: newAccountData.name
                    });
                    this.setState(
                        {
                            accountDetailsReadyForSubmission: false,
                            inputErrors: {}
                        },
                        () => {
                            this.props.displaySuccessMessage(`Your ${localiseText('organisation')} details have been updated.`);
                        }
                    );
                })
                .catch(error => {
                    if (!error.response.data.errors) {
                        return this.props.displayErrorMessage('Your details could not be updated. Please contact us.');
                    }
                    if (error.response) {
                        const formErrors = Object.keys(error.response.data.errors);
                        const errorList = {};

                        if (formErrors.includes('phone')) {
                            errorList.phoneError = true;
                        }
                        if (formErrors.includes('website')) {
                            errorList.websiteError = true;
                        }
                        this.setState({
                            formSubmitted: false,
                            inputErrors: errorList,
                        });
                    }
                });
        }
        else {
            console.log('Add event form validation');
        }
    };

    createCountryCodeSelectOptions = () => this.state.countryCodeOptions.map(option => (
        <option key={option.id} value={option.id}>
            ({option.phone}) {option.name}
        </option>
    ))
    
    createCountrySelectOptions = () =>
        this.state.countryCodeOptions.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));


    createSelectOptions = () =>
        this.state.organisationSizes.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));
    
    createCurrencySelectOptions = () =>
        this.state.currencies.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));

    createRegistrationPlatformSelectOptions = () =>
        this.state.organisationRegistrationPlatforms.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));


    render () {
        const {
            subscriptionDetailsSection,
            inputErrors,
            organisationDetailsEdited,
            accountDetailsReadyForSubmission,
            organisationAdditionalDetailsEdited,
            accountAdditionalDetailsReadyForSubmission,

            currentName,
            currentAddress_line1,
            currentAddress_line2,
            currentCity,
            currentPostcode,
            currentCountry,
            currentWebsite,
            currentSizeTier,
            currentCurrency,
            currentPhone,
            currentPhoneCode,
            currentRegistrationPlatform,
            name,
            address_line1,
            address_line2,
            city,
            postcode,
            country,
            website,
            phone,
            sizeTier,
            currency,
            phoneCode,
            registrationPlatform
        } = this.state;

        const selfIsAdmin = this.context?.selectedOrganisation?.is_admin;
        return (
            <>
                <div className="modal-content-section organisation-settings">
                    {!selfIsAdmin &&
                        <div className="payment-card-disclaimer modal-warning-container org-settings-permissions-warning">
                            <span className="payment-warning-icon">
                                <ExclamationIcon />
                            </span>
                            <p>
                                You don&#39;t have permission to update {localiseText('organisation')} or subscription details. 
                                Contact an {localiseText('organisation')} admin to make changes.
                            </p>
                        </div>
                    }
                    <h3 className="modal-section-header">{localiseText('Organisation')} details</h3>
                    <form className="log-in-form">
                        <div className="modal-input-row">
                            <div className="modal-input-group my-0">
                                <label htmlFor="account-details-first-name" className="modal-input-label">
                                    {localiseText('Organisation')} name{' '}
                                </label>
                                <input
                                    id="organisation-name"
                                    value={
                                        currentName !== name && organisationDetailsEdited
                                            ? name
                                            : currentName
                                    }
                                    onChange={this.handleChange}
                                    className="input modal-input"
                                    name="name"
                                    type="text"
                                />
                            </div>
                        
                        </div>
                        <div className="modal-input-row">
                            <div className="modal-input-group-half">
                                <label htmlFor="account-details-email" className="modal-input-label">
                                    {localiseText('Organisation')} address
                                </label>
                                <input
                                    id="account-details-email"
                                    value={
                                        currentAddress_line1 !== address_line1 && organisationDetailsEdited
                                            ? address_line1
                                            : currentAddress_line1
                                    }
                                    onChange={this.handleChange}
                                    className="input modal-input"
                                    name="address_line1"
                                    type="text"
                                    placeholder="Address line 1"
                                />
                            </div>
                            <div className="modal-input-group-half">
                                <label
                                    htmlFor="account-details-org-role"
                                    className="modal-input-label modal-input-label-disabled"
                                />
                                <input
                                    id="account-details-org-role"
                                    value={
                                        currentAddress_line2 !== address_line2 && organisationDetailsEdited
                                            ? address_line2
                                            : currentAddress_line2}
                                    onChange={this.handleChange}
                                    className="input modal-input"
                                    name="address_line2"
                                    type="text"
                                    placeholder="Address line 2 (optional)"
                                />
                            </div>
                        </div>

                        <div className="modal-input-row">
                            <div className="modal-input-group-half">
                                <input
                                    id="account-details-email"
                                    value={
                                        currentCity !== city && organisationDetailsEdited
                                            ? city
                                            : currentCity
                                    }
                                    onChange={this.handleChange}
                                    className="input modal-input"
                                    name="city"
                                    type="text"
                                    placeholder="City"
                                />
                            </div>
                            <div className="modal-input-group-half">
                                <input
                                    id="account-details-org-role"
                                    value={
                                        currentPostcode !== postcode && organisationDetailsEdited
                                            ? postcode
                                            : currentPostcode
                                    }
                                    onChange={this.handleChange}
                                    className="input modal-input"
                                    name="postcode"
                                    type="text"
                                    placeholder="Postcode"
                                />
                            </div>
                        </div>

                        <div className="modal-input-row">
                            <div className="modal-input-group-half">
                                <select
                                    className="input modal-input-select"
                                    id="organisation-address"
                                    name="country"
                                    onChange={this.handleChange}
                                    value={
                                        currentCountry !== country && organisationDetailsEdited
                                            ? country
                                            : currentCountry
                                    }
                                    required
                                >
                                    <option value="" disabled>
                                    Select country
                                    </option>
                                    {this.createCountrySelectOptions()}
                                </select>
                            </div>
                        
                        </div>

                        <div className="modal-input-row">
                            <div className="modal-input-group">
                                <label htmlFor="account-details-email" className="modal-input-label">
                                    {localiseText('Organisation')} website
                                </label>
                                <input
                                    id="account-details-email"
                                    value={
                                        currentWebsite !== website && organisationDetailsEdited
                                            ? website
                                            : currentWebsite
                                    }
                                    onChange={this.handleChange}
                                    className="input modal-input"
                                    name="website"
                                    type="text"
                                />
                                {inputErrors.websiteError && (
                                    <div className="small-form-error">
                                    Invalid website. Check the formatting of
                                    your website address is correct.
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="modal-input-row">
                            <div className="modal-input-group-half">
                                <label htmlFor="organisation-number" className="modal-input-label">
                                    {localiseText('Organisation')} contact number
                                </label>
                               
                                <select
                                    className="input modal-input-select"
                                    name="organisationCountryCode"
                                    onChange={e => {
                                        this.setState({ phoneCode: e.target.value, organisationDetailsEdited: true },
                                            this.handleAllowFormSubmit);
                                    }}
                                    value={
                                        currentPhoneCode !== phoneCode && organisationDetailsEdited
                                            ? phoneCode
                                            : currentPhoneCode
                                    }
                                    required
                                >
                                    <option value="" disabled>
                                        Select code
                                    </option>
                                    {this.createCountryCodeSelectOptions()}
                                </select>
                                
                            </div>
                            <div className="modal-input-group-half">
                                <label htmlFor="organisationNumber" className="modal-input-label" />
                                <input
                                    id="organisation-number"
                                    value={
                                        currentPhone !== phone && organisationDetailsEdited
                                            ? phone
                                            : currentPhone
                                    }
                                    onChange={this.handleChange}
                                    className="input modal-input"
                                    name="phone"
                                    type="text"
                                    placeholder="7123456789"
                                    required
                                />
                                {inputErrors.phoneError && (
                                    <div className="small-form-error">
                                        Invalid phone number. Check the formatting
                                        of your number is correct.
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="modal-input-row">
                            <div className="modal-input-group">
                                <label htmlFor="organisation-registration-platform" className="modal-input-label">
                                    Registration Platform{' '}
                                    <span className="required-field">*</span>
                                </label>

                                <select
                                    className="input modal-input-select"
                                    id="organisation-registration-platform"
                                    name="organisationRegistrationPlatform"
                                    onChange={this.handleChange}
                                    value={
                                        currentRegistrationPlatform !== registrationPlatform && organisationDetailsEdited
                                            ? registrationPlatform
                                            : currentRegistrationPlatform
                                    }
                                    required
                                >
                                    <option value="" disabled>
                                        Select registration platform
                                    </option>
                                    {this.createRegistrationPlatformSelectOptions()}
                                </select>
                            </div>
                        </div>

                        <div className="modal-btn-row">
                            <div
                                role="button"
                                className={`btn secondary-btn modal-form-btn ${
                                                accountDetailsReadyForSubmission && selfIsAdmin
                                                    ? 'secondary-btn-main-cta'
                                                    : 'disabled-secondary-btn'
                                            }`}
                                onClick={this.handleAccountDetailsSubmit}
                            >
                                Update {localiseText('organisation')} details
                            </div>
                        </div>
                    </form>
                </div>

                <div className="modal-content-section">
                    <h3 className="modal-section-header">Subscription details</h3>

                    {!subscriptionDetailsSection && (
                        <div className="modal-collapsed-info">
                            <p className="modal-subtext">
                                Change your {localiseText('organisation')}’s tier size and billing currency
                            </p>
                            <p className="modal-subtext">
                                <span className="modal-content-link" onClick={this.toggleSubscriptionDetailsSection}>
                            Edit subscription details
                                </span>
                            </p>
                        </div>
                    )}
                    {subscriptionDetailsSection && (
                        <form className="log-in-form">
                            <div className="modal-input-group">
                                <label
                                    htmlFor="account-details-current-password"
                                    className="modal-input-label"
                                >
                                    {localiseText('Organisation')} size tier
                                </label>
                                <select
                                    className="input modal-input-select"
                                    name="sizeTier"
                                    onChange={this.handleAdditionalInfoChange}
                                    value={currentSizeTier !== sizeTier && organisationAdditionalDetailsEdited
                                        ? sizeTier
                                        : currentSizeTier}
                                    required
                                    disabled={this.context.selectedOrganisation?.is_premium_subscriber}
                                >
                                    <option value="" disabled>Select number of annual participants</option>
                                    {this.createSelectOptions()}
                                </select>
                            </div>
                        
                            <div className="modal-input-group">
                                <label
                                    htmlFor="account-details-current-password"
                                    className="modal-input-label"
                                >
                                Billing currency
                                </label>
                                <select
                                    className="input modal-input-select"
                                    name="currency"
                                    onChange={this.handleAdditionalInfoChange}
                                    value={currentCurrency !== currency && organisationAdditionalDetailsEdited
                                        ? currency
                                        : currentCurrency}
                                    required
                                    disabled={this.context.selectedOrganisation?.is_premium_subscriber}
                                >
                                    <option value="" disabled>Select currency</option>
                                    {this.createCurrencySelectOptions()}
                                </select>
                                {this.context.selectedOrganisation?.is_premium_subscriber && (
                                    <div className="payment-card-disclaimer modal-warning-container">
                                        <span className="payment-warning-icon">
                                            <ExclamationIcon />
                                        </span>
                                        <p>
                                            You can&#39;t change subscription details with an active subscription.&nbsp;
                                            <span className="modal-content-link" onClick={e => {
                                                e.preventDefault();
                                                this.context.toggleContactUsModal();
                                            }}
                                            >Contact us
                                            </span>&nbsp;for help.                                    
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="modal-btn-row">
                                <div
                                    role="button"
                                    className="btn secondary-btn modal-form-btn"
                                    onClick={this.toggleSubscriptionDetailsSection}
                                >
                                    Cancel
                                </div>
                                <div
                                    role="button"
                                    className={`btn secondary-btn modal-form-btn ${
                                                    accountAdditionalDetailsReadyForSubmission && selfIsAdmin
                                                        ? 'secondary-btn-main-cta'
                                                        : 'disabled-secondary-btn'
                                                }`}
                                    onClick={this.handleOrganisationAdditionalInfoSubmit}
                                >
                                    Update subscription details
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </>
        );
    }
}

OrganisationSettingsTab.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    displayErrorMessage: PropTypes.func.isRequired,
    displaySuccessMessage: PropTypes.func.isRequired,
};

export default OrganisationSettingsTab;
