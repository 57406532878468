import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../organiserDashboard/Modal.css';
import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import SuccessMessage from '../helpers/successMessage';
import ErrorMessage from '../helpers/errorMessage';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import { ReactComponent as MastercardIcon } from '../../images/mastercard.svg';
import { ReactComponent as TrashIcon } from '../../images/icons/trashcan.svg';
import SmallLoader from '../../images/small_loader.gif';
import AppContext from '../app/AppContext';
import countries from '../helpers/countries';

class PayInvoiceModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        let linkedCreditCards = [];
        if (
            this.props.subscriptionDetails.details.statuses.customer_status === 'OK' &&
            this.props.subscriptionDetails.details.statuses.credit_card_status === 'OK'
        ) {
            linkedCreditCards = this.props.subscriptionDetails.details.credit_cards;
        }

        const currentInvoice = this.props.subscriptionDetails.details.open_invoices.filter(
            obj => obj.invoice_id === this.props.invoiceID
        )[0];

        const initialCountryCodes = countries.map(countryCodes => countryCodes);

        this.state = {
            countryCodeOptions: initialCountryCodes,
            paymentDetailsError: false,
            currentSuccessMessage: '',
            currentErrorMessage: '',
            modalPage: 1,
            triggerSubDataRefresh: false,
            currentCreditCards: linkedCreditCards,
            oldSelectedPaymentMethod: this.props.subscriptionDetails.details.payment_method.creditcard_id
                ? this.props.subscriptionDetails.details.payment_method.creditcard_id.toString()
                : null,
            selectedPaymentMethod: this.props.subscriptionDetails.details.payment_method.creditcard_id
                ? this.props.subscriptionDetails.details.payment_method.creditcard_id.toString()
                : null,
            updateDefaultCardReady: false,
            paymentMethod: {
                cardNumber: '',
                cardName: '',
                expiryMonth: '',
                expiryYear: '',
                cvv: '',
            },
            billingDetails: {
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                postcode: '',
                country: '',
            },
            currentInvoice,
        };
    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    triggerNewCardAdd = e => {
        e.preventDefault();
        this.setState({
            modalPage: 2,
            addedNewPayment: true,
        });
    };

    handleSectionChange = e => {
        e.preventDefault();
        if (this.state.modalPage < 2) {
            this.setState({
                modalPage: this.state.modalPage + 1,
            });
        }
    };

    handleSectionChangeReverse = e => {
        e.preventDefault();
        if (this.state.modalPage > 1) {
            this.setState({
                modalPage: this.state.modalPage - 1,
            });
        }
    };

    handleCardOptionChange = e => {
        const targetValue = e.target.value.toString();
        let updateDefaultReadyValue;
        if (targetValue === this.state.oldSelectedPaymentMethod) {
            updateDefaultReadyValue = false;
        }
        else {
            updateDefaultReadyValue = true;
        }
        this.setState({
            selectedPaymentMethod: targetValue,
            updateDefaultCardReady: updateDefaultReadyValue,
        });
    };

    handlePaymentMethodChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState(prevState => ({
            paymentMethod: {
                ...prevState.paymentMethod,
                [targetName]: targetValue,
            },
        }));
    };

    handleBillingDetailsChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState(prevState => ({
            billingDetails: {
                ...prevState.billingDetails,
                [targetName]: targetValue,
            },
        }));
    };

    handleInvoicePayment = () => {
        this.setState(
            {
                loadingButton: true,
            },
            () => {
                const token = this.context.token ? this.context.token : localStorage.getItem('token');
                /* eslint-disable-next-line*/
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/subscriptions/${this.props.subscriptionDetails.id}/pay_invoice/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };
                axios
                    .post(
                        apiCallURL,
                        {
                            invoice_id: this.props.invoiceID,
                        },
                        { headers }
                    )
                    .then(() => {
                        this.setState(
                            {
                                loadingButton: false,
                                triggerSubDataRefresh: true,
                                modalPage: 3,
                            },
                            () => {
                                this.displaySuccessMessage('Invoice successfully paid');
                            }
                        );
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.setState({
                            loadingButton: false,
                        });
                        this.displayErrorMessage('Invoice payment failed - please try again soon');
                    });
            }
        );
    };

    handleCardDeletionConfirm = (status, id) => {
        if (status) {
            this.setState({
                paymentCardDeletionID: id.toString(),
                // selectedPaymentMethod: null
            });
        }
        else {
            this.setState({
                paymentCardDeletionID: null,
                // selectedPaymentMethod: this.state.oldSelectedPaymentMethod
            });
        }
    };

    handleCardDeletion = id => {
        this.setState(
            {
                loadingCardDeletionID: id.toString(),
            },
            () => {
                const token = this.context.token ? this.context.token : localStorage.getItem('token');
                /* eslint-disable-next-line*/
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/subscriptions/delete_credit_card/`;
                const payload = {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                    data: {
                        delete_credit_card_id: id,
                    },
                };
                let newDefaultCardId;
                if (this.state.selectedPaymentMethod === id.toString()) {
                    if (this.state.currentCreditCards.length > 1) {
                        const newCardsList = this.state.currentCreditCards;
                        for (let i = 0; i < newCardsList.length; i++) {
                            if (newCardsList[i].id === id) {
                                newCardsList.splice(i, 1);
                            }
                        }

                        newDefaultCardId = newCardsList[newCardsList.length - 1].id;
                        payload.data.default_credit_card_id = newDefaultCardId;
                    }
                    else {
                        newDefaultCardId = null;
                    }
                }
                else {
                    newDefaultCardId = this.state.selectedPaymentMethod;
                }

                axios
                    .delete(apiCallURL, payload)
                    .then(res => {
                        this.setState(
                            {
                                currentCreditCards: res.data.credit_cards,
                                oldSelectedPaymentMethod: newDefaultCardId ? newDefaultCardId.toString() : null,
                                selectedPaymentMethod: newDefaultCardId ? newDefaultCardId.toString() : null,
                                paymentCardDeletionID: null,
                                loadingCardDeletionID: null,
                                triggerSubDataRefresh: true,
                            },
                            () => {
                                this.displaySuccessMessage('Payment method successfully deleted');
                            }
                        );
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.displayErrorMessage('There was a problem deleting this payment method');
                        this.setState({
                            loadingCardDeletionID: null,
                        });
                    });
            }
        );
    };

    handleAddNewPaymentMethod = () => {
        // Simple Field Validation
        const { cardNumber, cardName, cvv, expiryMonth, expiryYear } = this.state.paymentMethod;
        if (
            !(
                cardNumber.length > 4 &&
                cardName.length > 2 &&
                [3, 4].includes(cvv.length) &&
                expiryMonth.length > 0 &&
                expiryYear.length > 0 &&
                this.state.billingDetails.addressLine1.length > 1 &&
                this.state.billingDetails.city.length > 1 &&
                this.state.billingDetails.state.length > 1 &&
                this.state.billingDetails.postcode.length > 1 &&
                this.state.billingDetails.country.length > 1
            )
        ) {
            this.setState({
                paymentDetailsError: true,
            });
            return false;
        }

        if (this.state.paymentDetailsError) {
            this.setState({
                paymentDetailsError: false,
            });
        }

        this.setState(
            {
                loadingButton: true,
            },
            () => {
                const token = this.context.token ? this.context.token : localStorage.getItem('token');
                /* eslint-disable-next-line*/
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/subscriptions/create_credit_card/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };
                const paymentMethodData = {
                    number: this.state.paymentMethod.cardNumber,
                    cvv: this.state.paymentMethod.cvv,
                    expdate_year: +expiryYear,
                    expdate_month: +expiryMonth,
                    name: this.state.paymentMethod.cardName,
                    address: `${this.state.billingDetails.addressLine1} ${this.state.billingDetails.addressLine2}`,
                    city: this.state.billingDetails.city,
                    state: this.state.billingDetails.state,
                    postcode: this.state.billingDetails.postcode,
                    country: this.state.billingDetails.country,
                };
                axios
                    .post(apiCallURL, paymentMethodData, { headers })
                    .then(res => {
                        const oldCards = this.state.currentCreditCards;
                        this.setState(
                            {
                                currentCreditCards: res.data.data,
                                loadingButton: false,
                                modalPage: 1,
                            },
                            () => {
                                let difference = [];
                                if (this.state.currentCreditCards.length !== oldCards.length) {
                                    const newCards = this.state.currentCreditCards;
                                    difference = newCards.filter(item => {
                                        for (let i = 0, len = oldCards.length; i < len; i++) {
                                            if (oldCards[i].id === item.id) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    });
                                }

                                this.setState(
                                    {
                                        selectedPaymentMethod:
                                            difference.length > 0
                                                ? difference[0].id.toString()
                                                : this.state.selectedPaymentMethod,
                                        oldSelectedPaymentMethod:
                                            difference.length > 0
                                                ? difference[0].id.toString()
                                                : this.state.oldSelectedPaymentMethod,
                                        triggerSubDataRefresh: true,
                                        paymentMethod: {
                                            cardNumber: '',
                                            cardName: '',
                                            expiryMonth: '',
                                            expiryYear: '',
                                            cvv: '',
                                        },
                                        billingDetails: {
                                            addressLine1: '',
                                            addressLine2: '',
                                            city: '',
                                            state: '',
                                            postcode: '',
                                            country: '',
                                        },
                                    },
                                    () => {
                                        this.displaySuccessMessage('New payment method successfully added');
                                    }
                                );
                            }
                        );
                    })
                    .catch(error => {
                        console.log(error);
                        this.setState(
                            {
                                loadingButton: false,
                            },
                            () => {
                                this.displayErrorMessage('There was a problem adding this payment method');
                            }
                        );
                    });
            }
        );
    };

    createCountrySelectOptions = () =>
        this.state.countryCodeOptions.map(option => (
            <option key={option.id} value={option.name}>
                {option.name}
            </option>
        ));

        renderExpdateYearOptions = () => {
            const currentYear = new Date().getFullYear();
            const years = [];
            for (let i = currentYear; i <= currentYear + 10; i++) {
                years.push(i);
            }
    
            return years.map(item => <option key={item}>{item}</option>);
        };
    
        renderExpdateMonthOptions = () => {
            const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
            const now = new Date();
            let filtered = [...months];
            
            if (+now.getFullYear() === +this.state.paymentMethod.expiryYear) 
                filtered = months.filter(item => +item - 1 > now.getMonth());
    
            return filtered.map(item => (
                <option key={item} value={item}>
                    {parseInt(item)}
                </option>
            ));
        };

        render () {
            const {
                loadingButton,
                modalPage,
                currentSuccessMessage,
                currentErrorMessage,
                selectedPaymentMethod,
                paymentCardDeletionID,
                loadingCardDeletionID,
                billingDetails,
                paymentMethod,
                triggerSubDataRefresh,
                currentInvoice,
                paymentDetailsError,
            } = this.state;
            const currentPaymentMethods = this.state.currentCreditCards.map(card => (
                <div
                    key={card.id}
                    className={`subscription-modal-radio-option ${
                    selectedPaymentMethod === card.id.toString() ? 'smro-active' : ''
                }`}
                >
                    {paymentCardDeletionID === card.id.toString() ? (
                        <div>
                            {loadingCardDeletionID === card.id.toString() ? (
                                <div className="card-deletion-confirmation">
                                    <p className="cdc-copy">
                                    Deleting payment method ending {card.number.substr(card.number.length - 4, 4)}{' '}
                                        <img className="deleting-card-loader" src={SmallLoader} alt="loader" />
                                    </p>
                                </div>
                            ) : (
                                <div className="card-deletion-confirmation">
                                    <p className="cdc-copy">
                                    Are you sure you want to remove the
                                        <span className="payment-method-inline-logo">
                                            {card.type === 'Visa' ? (
                                                <img
                                                    src={require('../../images/visa.png')}
                                                    className="pm-visa-logo-inline"
                                                    alt="visa-logo"
                                                />
                                            ) : (
                                                <MastercardIcon />
                                            )}
                                        </span>
                                    card ending {card.number.substr(card.number.length - 4, 4)}?
                                    </p>
                                    <div className="cdc-buttons">
                                        <p
                                            className="cdc-card-btn"
                                            onClick={() => this.handleCardDeletionConfirm(false, card.id)}
                                        >
                                        Cancel
                                        </p>
                                        <p className="cdc-card-btn" onClick={() => this.handleCardDeletion(card.id)}>
                                        Confirm removal
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <label className="smro-label">
                            <input
                                type="radio"
                                name="react-tips"
                                value={card.id}
                                checked={selectedPaymentMethod === card.id.toString()}
                                onChange={this.handleCardOptionChange}
                                className={`smro-check ${
                                selectedPaymentMethod === card.id.toString() ? 'smro-check-checked' : ''
                            }`}
                            />
                            <div className="smro-copy">
                                <p className="smro-title">
                                xxxx xxxx xxxx {card.number.substr(card.number.length - 4, 4)}
                                    <span className="payment-method-inline-logo">
                                        {card.type === 'Visa' ? (
                                            <img
                                                src={require('../../images/visa.png')}
                                                className="pm-visa-logo"
                                                alt="visa-logo"
                                            />
                                        ) : (
                                            <MastercardIcon />
                                        )}
                                    </span>
                                </p>
                                <p className="smro-desc">
                                Expires {card.expdate_month}/{card.expdate_year}
                                </p>
                            </div>
                            <div className="smro-button">
                                <button
                                    className="delete-card-circle-btn"
                                    onClick={() => this.handleCardDeletionConfirm(true, card.id)}
                                >
                                    <TrashIcon />
                                </button>
                            </div>
                        </label>
                    )}
                </div>
            ));

            let modalPageContent;
            // VIEW INVOICE AND SELECT OR ADD PAYMENT METHOD
            if (modalPage === 1) {
                modalPageContent = (
                    <div className="modal-content-section">
                        <div className="summary-modal-section">
                            <p className="subscription-modal-header">Invoice summary</p>
                            <div className="sms-group">
                                <p className="smsg-title">Amount to pay</p>
                                <p className="smsg-info">£{currentInvoice.total}</p>
                            </div>
                            <div className="sms-group">
                                <p className="smsg-title">Original due date</p>
                                <p className="smsg-info">
                                    {moment(currentInvoice.due_date, 'YYYY-MM-DD').format('Do MMM YYYY')}
                                    <span className="smsg-info-span"> - {currentInvoice.days_overdue} days ago</span>
                                </p>
                            </div>
                        </div>
                        <div className="customer-details-modal-section pay-invoice-modal-section">
                            <p className="subscription-modal-header">Select payment method</p>
                            {currentPaymentMethods}
                            <div className="subscription-modal-warning-message">
                                <p className="stm-copy">
                                    <span className="stm-copy-highlight">
                                        <span className="subscription-warning-icon">
                                            <ExclamationIcon />
                                        </span>
                                    Note:
                                    </span>{' '}
                                We are in the process of upgrading our payment system.
                                 As a result, please contact us to make any changes to your payment method(s).
                                </p>
                            </div>
                        </div>
                        <div className="modal-btn-row">
                            {loadingButton ? (
                                <div
                                    role="button"
                                    className={'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'}
                                >
                                    <img className="subscription-btn-loader" src={SmallLoader} alt="loader" />
                                </div>
                            ) : (
                                <div
                                    role="button"
                                    className={'btn sub-fixed-width-btn primary-btn modal-form-btn'}
                                    onClick={this.handleInvoicePayment}
                                >
                                Pay £{currentInvoice.total}
                                </div>
                            )}
                        </div>
                    </div>
                );
            // CUSTOMER DETAILS MODAL SECTION
            }
            else if (modalPage === 2) {
                modalPageContent = (
                    <div className="modal-content-section">
                        {paymentDetailsError && (
                            <div className="subscription-modal-error-message">
                                <p className="stm-copy">
                                    <span className="stm-copy-highlight">
                                        <span className="subscription-error-icon">
                                            <ExclamationIcon />
                                        </span>
                                    Error:
                                    </span>{' '}
                                Ensure all required fields have valid information.
                                </p>
                            </div>
                        )}
                        <div className="payment-method-modal-section">
                            <p className="subscription-modal-header">Payment method</p>
                            <div className="payment-method-container">
                                <form className="subscription-modal-payment-form">
                                    <div className="modal-input-row">
                                        <div className="modal-input-group">
                                            <label htmlFor="payment-details-card-number" className="modal-input-label">
                                            Card number <span className="required-field">*</span>
                                            </label>
                                            <input
                                                id="payment-details-card-number"
                                                value={paymentMethod.cardNumber}
                                                onChange={this.handlePaymentMethodChange}
                                                className="input modal-input"
                                                name="cardNumber"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-input-row">
                                        <div className="modal-input-group">
                                            <label htmlFor="payment-details-card-name" className="modal-input-label">
                                            Cardholder name <span className="required-field">*</span>
                                            </label>
                                            <input
                                                id="payment-details-card-name"
                                                value={paymentMethod.cardName}
                                                onChange={this.handlePaymentMethodChange}
                                                className="input modal-input"
                                                name="cardName"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-input-row">
                                        <div className="modal-input-group modal-input-group-quarter">
                                            <div className="modal-input-group-half expiry-date-half">
                                                <label className="modal-input-label" htmlFor="expiry-date">
                                                Expiry Date: <span className="required-field">*</span>
                                                </label>
                                                <div className="expiry-date-container" id="expiry-date">
                                                    <div className="expdate-month-container">
                                                        <select
                                                            id="payment-details-expdate-month"
                                                            className="input modal-input expdate-month"
                                                            name="expiryMonth"
                                                            type="text"
                                                            placeholder="-"
                                                            required
                                                            value={paymentMethod.expiryMonth}
                                                            onChange={this.handlePaymentMethodChange}
                                                        >
                                                            <option value="">-</option>
                                                            {this.renderExpdateMonthOptions()}
                                                        </select>
                                                    </div>
                                                    <span className="expdate-separator">/</span>
                                                    <div className="expdate-year-container">
                                                        <select
                                                            id="payment-details-expdate-year"
                                                            className="input modal-input expdate-year"
                                                            name="expiryYear"
                                                            type="text"
                                                            required
                                                            value={paymentMethod.expiryYear}
                                                            onChange={this.handlePaymentMethodChange}
                                                        >
                                                            <option value="">-</option>
                                                            {this.renderExpdateYearOptions()}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-input-group-half">
                                                <label htmlFor="payment-details-cvv" className="modal-input-label">
                                                CVV <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="payment-details-cvv"
                                                    value={paymentMethod.cvv}
                                                    onChange={this.handlePaymentMethodChange}
                                                    className="input modal-input"
                                                    name="cvv"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className="payment-method-card-preview">
                                    <div className="payment-card payment-card-bg1">
                                        <div className="payment-card-logo">
                                            <MastercardIcon />
                                        </div>
                                        <div className="payment-card-chip">
                                            <div className="payment-card-chip-line" />
                                            <div className="payment-card-chip-line" />
                                            <div className="payment-card-chip-line" />
                                            <div className="payment-card-chip-line" />
                                            <div className="payment-card-chip-center" />
                                        </div>
                                        <p className="payment-card-number">{paymentMethod.cardNumber}</p>
                                        <div className="payment-card-expiry-container">
                                            <p className="payment-card-expires-header">expires</p>
                                            <p className="payment-card-expiry">
                                                {paymentMethod.expiryMonth} / {paymentMethod.expiryYear}
                                            </p>
                                        </div>
                                        <p className="payment-card-name">{paymentMethod.cardName}</p>
                                    </div>
                                    <p className="payment-card-disclaimer">
                                        <span className="payment-warning-icon">
                                            <ExclamationIcon />
                                        </span>
                                    Racecheck never stores your card details
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="billing-details-modal-section">
                            <p className="subscription-modal-header">Billing details</p>
                            <form className="subscription-modal-billing-form">
                                <div className="modal-input-row">
                                    <div className="modal-input-group-half">
                                        <label htmlFor="billing-details-address-1" className="modal-input-label">
                                        Address line 1 <span className="required-field">*</span>
                                        </label>
                                        <input
                                            id="billing-details-address-1"
                                            value={billingDetails.addressLine1}
                                            onChange={this.handleBillingDetailsChange}
                                            className="input modal-input"
                                            name="addressLine1"
                                            type="text"
                                        />
                                    </div>
                                    <div className="modal-input-group-half">
                                        <label htmlFor="billing-details-address-2" className="modal-input-label">
                                        Address line 2
                                        </label>
                                        <input
                                            id="billing-details-address-2"
                                            value={billingDetails.addressLine2}
                                            onChange={this.handleBillingDetailsChange}
                                            className="input modal-input"
                                            name="addressLine2"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="modal-input-row">
                                    <div className="modal-input-group-half">
                                        <label htmlFor="billing-details-city" className="modal-input-label">
                                        Town/city <span className="required-field">*</span>
                                        </label>
                                        <input
                                            id="billing-details-city"
                                            value={billingDetails.city}
                                            onChange={this.handleBillingDetailsChange}
                                            className="input modal-input"
                                            name="city"
                                            type="text"
                                        />
                                    </div>
                                    <div className="modal-input-group-half">
                                        <label htmlFor="customer-details-state" className="modal-input-label">
                                        County/state <span className="required-field">*</span>
                                        </label>
                                        <input
                                            id="customer-details-state"
                                            value={billingDetails.state}
                                            onChange={this.handleBillingDetailsChange}
                                            className="input modal-input"
                                            name="state"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="modal-input-row">
                                    <div className="modal-input-group-half">
                                        <label htmlFor="billing-details-country" className="modal-input-label">
                                        Country <span className="required-field">*</span>
                                        </label>
                                        <select
                                            id="billing-details-country"
                                            className="input org-address-input upgrade-country-select"
                                            name="country"
                                            onChange={this.handleBillingDetailsChange}
                                            value={billingDetails.country}
                                            required
                                        >
                                            <option value="" disabled>
                                            Select country
                                            </option>
                                            {this.createCountrySelectOptions()}
                                        </select>
                                    </div>
                                    <div className="modal-input-group-half">
                                        <label htmlFor="billing-details-postcode" className="modal-input-label">
                                        Postcode <span className="required-field">*</span>
                                        </label>
                                        <input
                                            id="billing-details-postcode"
                                            value={billingDetails.postcode}
                                            onChange={this.handleBillingDetailsChange}
                                            className="input modal-input"
                                            name="postcode"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-btn-row sm-modal-btn-row">
                            <div
                                role="button"
                                className={'btn secondary-btn secondary-btn-rounded modal-form-btn'}
                                onClick={this.handleSectionChangeReverse}
                            >
                            Back
                            </div>
                            {loadingButton ? (
                                <div
                                    role="button"
                                    className={
                                        'btn sub-fixed-width-btn-large primary-btn modal-form-btn disabled-primary-btn'
                                    }
                                >
                                    <img className="subscription-btn-loader" src={SmallLoader} alt="userprofile" />
                                </div>
                            ) : (
                                <div
                                    role="button"
                                    className={'btn sub-fixed-width-btn-large primary-btn modal-form-btn'}
                                    onClick={this.handleAddNewPaymentMethod}
                                >
                                Save new payment method
                                </div>
                            )}
                        </div>
                    </div>
                );
            }
            else if (modalPage === 3) {
                modalPageContent = (
                    <div className="modal-content-section">
                        <div className="invoice-paid-confirmation">
                            <p>Invoice #{this.props.invoiceID} has been successfully paid.</p>
                            {/* <VaultImage /> */}
                            <div
                                role="button"
                                className={'btn sub-fixed-width-btn-large primary-btn modal-form-btn'}
                                onClick={() => this.props.toggleModal(triggerSubDataRefresh)}
                            >
                            Back to dashboard
                            </div>
                        </div>
                    </div>
                );
            }

            return (
                <div className="modal-backdrop">
                    {currentSuccessMessage && (
                        <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                    )}
                    {currentErrorMessage && (
                        <ErrorMessage message={currentErrorMessage} resetErrorMessage={this.resetErrorMessage} />
                    )}
                    <div className="modal-padding" />
                    <div className="modal-container">
                        <div className="modal-header">
                            <button
                                className="modal-close-btn"
                                onClick={() => this.props.toggleModal(triggerSubDataRefresh)}
                            >
                                <CloseIcon />
                            </button>
                            <h2 className="modal-header-title modal-title-no-nav">Pay invoice #{this.props.invoiceID}</h2>
                        </div>
                        <div className="modal-scrollable">
                            <div className="modal-content">{modalPageContent}</div>
                        </div>
                    </div>
                    <div className="modal-padding" />
                </div>
            );
        }
}

PayInvoiceModal.propTypes = {
    invoiceID: PropTypes.number,
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            open_invoices: PropTypes.arrayOf(
                PropTypes.shape({
                    invoice_id: PropTypes.number,
                })
            ),
            statuses: PropTypes.shape({
                can_cancel: PropTypes.bool,
                can_upgrade_within_paycycle: PropTypes.bool,
                can_downgrade_within_paycycle: PropTypes.bool,
                can_change_paycycle: PropTypes.bool,
                credit_card_status: PropTypes.string,
                customer_status: PropTypes.string,
            }).isRequired,
            widgets_status: PropTypes.shape({
                premium: PropTypes.number,
                upgradable: PropTypes.number,
            }),
            credit_cards: PropTypes.shape({}),
            payment_method: PropTypes.shape({
                creditcard_id: PropTypes.string,
            }),
        }),
        id: PropTypes.number.isRequired,
        plan: PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            base_price: PropTypes.string.isRequired,
            paycycle: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    toggleModal: PropTypes.func,
};

export default PayInvoiceModal;
