import React from 'react';
import PropTypes from 'prop-types';

const NoReviewsDataPlaceholder = ({ message = '' }) => (
    <div className="no-data-placeholder">
        <h2 className="no-data-placeholder__title">Not enough data</h2>
        <span className="no-data-placeholder__description">{message}</span>
    </div>
);

NoReviewsDataPlaceholder.propTypes = {
    message: PropTypes.string,
};

export default NoReviewsDataPlaceholder;
