import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';
import { ReactComponent as CopyIcon } from '../../images/icons/copy.svg';
import AppContext from '../app/AppContext';

const ShareLink = ({ event }) => {
    const appContext = useContext(AppContext);
    const mpProps = appContext.selectedOrganisation ? {
        organisation: appContext.selectedOrganisation?.name,
        premium_subscriber: appContext.selectedOrganisation?.is_premium_subscriber
    } : {};

    const [codeCopied, setCodeCopied] = useState(false);
    const [linkToShare, setLinkToShare] = useState(null);
    const [tooltip, setTooltip] = useState(false);

    const domain = process.env.REACT_APP_API_URL;

    const copyToClipboard = () => {
        mixpanel.track('Dashboard - Collect Reviews - Share review link copy click', mpProps);
        navigator.clipboard.writeText(linkToShare);
        setCodeCopied(true);

        setTimeout(() => {
            setCodeCopied(false);
        }, 2000);
    };

    const toggleTooltip = () => {
        setTooltip(prevState => !prevState);
    };


    useEffect(() => {
        setLinkToShare(`${domain}/races/${event.slug}/review/`);
        
    }, [event, domain]);

    return (
        <div className="share-link-container">
            <div className="share-link-header">
                <h2 className="share-link-title">Share link</h2>
                <p className="share-link-intro">
                    Share the link below with your event participants so they can quickly and easily provide feedback on
                    your event.
                </p>
            </div>
            <div className="share-link-content">
                
                <div className="share-link-preview">
                    <div className="share-link-downloads">
                        <div className="share-link-code-container" onClick={copyToClipboard}>
                            <div className="share-link-copy-code">
                                {codeCopied ? <p>Copied</p> : <p>Copy</p>}
                                <CopyIcon />
                            </div>
                            <div
                                className="share-link-code-content"
                                onMouseEnter={toggleTooltip}
                                onMouseLeave={toggleTooltip}
                            >
                                <pre className="share-link-code-pre">{linkToShare || 'Loading...'}</pre>
                            </div>
                            {tooltip && linkToShare && (
                                <div className="share-link-tooltip">
                                    <p>{linkToShare}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ShareLink.propTypes = {
    event: PropTypes.shape({
        slug: PropTypes.string
    })
};

export default ShareLink;
