export const privateRoutes = {
    events: '/events',
    analytics: '/analytics',
    teamMembers: '/team',
    subscriptionPlan: '/subscription',
    createOrganisation: '/create-organisation',
    resources: '/resources',
    webinar: '/webinar',
};

export const publicRoutes = {
    login: '/login',
    signup: '/signup',
    bookDemo: '/book-demo',
    webinar: '/webinar',
    stravaLogin: '/strava-login',
    facebookLogin: '/facebook-login',
    googleLogin: '/google-login',
    acceptTeamInvitation: '/accept_team_invitation',
    resetPassword: '/reset-password',
    landing: '/',
};

/* eslint-disable */
export const isPrivatePage = pathname => Object.values(privateRoutes).includes(pathname) && !Object.values(publicRoutes).includes(pathname);
export const isPublicPage = pathname => Object.values(publicRoutes).includes(pathname) && !Object.values(privateRoutes).includes(pathname);
/* eslint-enable */