import React from 'react';
import PropTypes from 'prop-types';

const AutocompleteSuggestions = ({ results, handleClick, modalAutocomplete }) => {
    const options = results.map(r => (
        <li className="autocomplete-item" key={r.id} onClick={() => handleClick(r.id, r.name, r.organisation_id)}>
            {r.name}
        </li>
    ));
    return (
        <div className={modalAutocomplete ? 'autocomplete-results modal-autocomplete-results' : 'autocomplete-results'}>
            <ul>{options}</ul>
        </div>
    );
};

AutocompleteSuggestions.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            organisation_id: PropTypes.number,
        })
    ),
    handleClick: PropTypes.func.isRequired,
    modalAutocomplete: PropTypes.bool,
};

AutocompleteSuggestions.defaultProps = {
    results: [
        {
            id: null,
            title: '',
            organisation_id: null,
        }
    ],
    handleClick: () => {},
    modalAutocomplete: false,
};

export default AutocompleteSuggestions;
