import React, { Component } from 'react';
import { ReactComponent as QuestionCircleIcon } from '../../images/icons/help.svg';
import { ReactComponent as RacecheckAIIcon } from '../../images/icons/racecheck-ai_outline.svg';
import { ReactComponent as SmartQuestionHiddenIcon } from '../../images/icons/sq-hidden.svg';
import PropTypes from 'prop-types';


class SmartQuestionExplainerTooltip extends Component {

    render () {
        return (
            <div className="sq-h-help">
                {this.props.type === 'label' ?
                    <div className="sq-explainer-label">
                        <SmartQuestionHiddenIcon />
                        <p>Private response</p>
                    </div>
                    :
                    <QuestionCircleIcon />
                }

                <div className="sq-h-tooltip">
                    <span className="sq-h-tooltip-desc">
                        These comments are private and only visible to you on your dashboard.
                    </span>
                    <div className="sq-h-tooltip-extra-row">
                        <span className="sq-h-tooltip-desc">
                            Question prompts are generated based on an analysis of review ratings
                            to help you better understand your feedback.
                        </span>
                        <a
                            href="https://blog.racecheck.com/announcing-smart-questions-4de9a9bd276b"
                            className="sq-h-tooltip-link"
                            target="_blank" rel="noopener noreferrer"
                        >
                            <RacecheckAIIcon />
                            Smart Questions explained
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

SmartQuestionExplainerTooltip.propTypes = {
    type: PropTypes.string
};

export default SmartQuestionExplainerTooltip;
