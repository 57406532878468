import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import AppContext from '../app/AppContext';
import ReviewFeed from '../reviews/ReviewFeedNew';
import ManageWidget from '../widgets/manage-widget';
import Analytics from './Analytics';
import { useIsMount } from '../helpers/useIsMount';
import RequestReviews from '../reviews/RequestReviews';
import WidgetLibrary from '../widgets/WidgetLibrary';
import SmartQuestionsReport from '../reviews/SmartQuestionsReport';

const Event = ({ setSectionError }) => {
    const { 
        selectedOrganisation = {},
        token = localStorage.getItem('token')
    } = useContext(AppContext);

    const { REACT_APP_API_URL } = process.env;

    const { params } = useRouteMatch();

    const [event, setEvent] = useState({});
    const [remainingReplies, setRepliesRemaining] = useState(null);

    const history = useHistory();
    const isMount = useIsMount();
    
    useEffect(() => {
        if (!isMount) history.push('/events/events');
        // eslint-disable-next-line
    }, [selectedOrganisation.id]);


    const getEvent = () => {
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/events/${params.id}/`;
        const headers = { Authorization: `Token ${token}` };
        axios
            .get(
                apiCallURL, 
                { headers }
            )
            .then(({ data }) => {
                setEvent(data);
                setRepliesRemaining(data?.replies_remaining);
            })
            .catch(error => console.log(error.response));
    };

    useEffect(getEvent, [selectedOrganisation.id]);

    return (
        <Switch>
            <Route
                path="/events/:id/analytics"
                render={(props) => (
                    <Analytics setSectionError={setSectionError} event={event} {...props} />
                )}
            />
            <Route 
                path="/events/:id/reviews"
                render={(props) => (
                    <ReviewFeed 
                        setSectionError={setSectionError} 
                        event={event} 
                        remainingReplies={remainingReplies}
                        setRepliesRemaining={setRepliesRemaining}
                        {...props}
                    />
                )}
            />
            <Route 
                path="/events/:id/widgets/:widgetType"
                render={(props) => <ManageWidget selectedOrganisation={selectedOrganisation} event={event} {...props} />}
            />
            <Route 
                path="/events/:id/widgets"
                render={(props) => <WidgetLibrary selectedOrganisation={selectedOrganisation} event={event} {...props} />}
            />
            <Route
                path="/events/:id/request-reviews"
                render={() => <RequestReviews event={event} />}
            />
            <Route
                path="/events/:id/smart-questions-report"
                render={() => <SmartQuestionsReport event={event} />}
            />
        </Switch>
    );
};

export default Event;

Event.propTypes = {
    setSectionError: PropTypes.func
};
