import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import Search from '../helpers/autocomplete';
import SuccessMessage from '../helpers/successMessage';
import AppContext from '../app/AppContext';
import { isValidURL } from '../helpers/validationFunctions';
import SmallLoader from '../../images/small_loader.gif';
import { localiseText } from '../../utils';

class AddEventModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            currentSuccessMessage: '',
            activeModalSection: 'claim-event',
            claimEventID: '',
            claimEventWebsite: '',
            claimEventReadyForSubmission: false,
            addEventName: '',
            addEventWebsite: '',
            addEventReadyForSubmission: false,
            clearClaimAutocomplete: false,
            loadingButton: false,
            hasError: false,
        };
    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    handleModalSectionChange = e => {
        this.setState({
            activeModalSection: e.target.title,
        });
    };

    handleChange = e => {
        const changeFocus = e.target.name;
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                if (changeFocus.substr(0, 5) === 'claim') {
                    if (this.state.claimEventWebsite && this.state.claimEventID) {
                        if (isValidURL(this.state.claimEventWebsite)) {
                            this.setState({
                                claimEventReadyForSubmission: true,
                            });
                        }
                        else {
                            this.setState({
                                claimEventReadyForSubmission: false,
                            });
                        }
                    }
                    else {
                        this.setState({
                            claimEventReadyForSubmission: false,
                        });
                    }
                }
                else if (this.state.addEventWebsite && this.state.addEventName) {
                    if (isValidURL(this.state.addEventWebsite) && this.state.addEventName.length > 2) {
                        this.setState({
                            addEventReadyForSubmission: true,
                        });
                    }
                    else {
                        this.setState({
                            addEventReadyForSubmission: false,
                        });
                    }
                }
                else {
                    this.setState({
                        addEventReadyForSubmission: false,
                    });
                }
            }
        );
    };

    handleAddSubmit = e => {
        if (this.state.addEventReadyForSubmission) {
            this.setState(
                {
                    loadingButton: true,
                },
                () => {
                    e.preventDefault();
                    const token = this.context.token ? this.context.token : localStorage.getItem('token');
                    /* eslint-disable-next-line*/
                    const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/events/request_to_add_event/`;
                    const headers = {
                        Authorization: `Token ${token}`,
                    };
                    axios
                        .post(
                            apiCallURL,
                            {
                                requested_event: this.state.addEventName,
                                website: this.state.addEventWebsite,
                            },
                            { headers }
                        )
                        .then(res => {
                            this.setState(
                                {
                                    addEventName: '',
                                    addEventWebsite: '',
                                    addEventReadyForSubmission: false,
                                    loadingButton: false,
                                },
                                () => {
                                    this.displaySuccessMessage(res.data.message);
                                }
                            );
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            );
        }
        else {
            console.log('Add event form validation');
        }
    };

    handleClaimSubmit = e => {
        if (this.state.claimEventReadyForSubmission) {
            this.setState(
                {
                    loadingButton: true,
                },
                () => {
                    e.preventDefault();
                    const token = this.context.token ? this.context.token : localStorage.getItem('token');
                    /* eslint-disable-next-line*/
                    const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/events/${this.state.claimEventID}/request_to_claim_event/`;
                    const headers = {
                        Authorization: `Token ${token}`,
                    };
                    axios
                        .post(
                            apiCallURL,
                            {
                                website: this.state.claimEventWebsite,
                            },
                            { headers }
                        )
                        .then(res => {
                            this.setState(
                                {
                                    claimEventID: '',
                                    claimEventWebsite: '',
                                    claimEventReadyForSubmission: false,
                                    clearClaimAutocomplete: true,
                                    loadingButton: false,
                                },
                                () => {
                                    this.displaySuccessMessage(res.data.message);
                                }
                            );
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
            );
        }
        else {
            console.log('Claim event form validation');
        }
    };

    handleEventSelection = (id, organisation_id) => {
        if (organisation_id !== this.context.selectedOrganisation.id) {
            this.setState(
                {
                    claimEventID: id,
                    hasError: false,
                },
                () => {
                    if (this.state.claimEventReadyForSubmission === false) {
                        if (this.state.claimEventWebsite && this.state.claimEventID) {
                            this.setState({
                                claimEventReadyForSubmission: true,
                            });
                        }
                    }
                    else if (!this.state.claimEventWebsite || !this.state.claimEventID) {
                        this.setState({
                            claimEventReadyForSubmission: false,
                        });
                    }
                }
            );
        }
        else {
            this.setState({
                hasError: `This event already belongs to your ${localiseText('organisation')}`,
            });
        }
    };

    handleSearchChange = () => {
        this.setState({
            claimEventReadyForSubmission: false,
            claimEventID: '',
            clearClaimAutocomplete: false,
        });
    };

    render () {
        const {
            loadingButton,
            clearClaimAutocomplete,
            currentSuccessMessage,
            activeModalSection,
            addEventName,
            addEventWebsite,
            addEventReadyForSubmission,
            claimEventWebsite,
            claimEventReadyForSubmission,
            hasError,
        } = this.state;
        return (
            <div className="modal-backdrop">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                )}
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title">
                            {activeModalSection === 'claim-event' ? 'Claim an event' : 'Add an event'}
                        </h2>
                        <nav className="modal-header-nav">
                            <ul className="modal-nav-list">
                                <li className="modal-nav-item">
                                    <div
                                        className="modal-nav-item-link"
                                        title="claim-event"
                                        onClick={this.handleModalSectionChange}
                                    >
                                        <span
                                            className={`modal-link ${
                                                activeModalSection === 'claim-event' ? 'modal-link-selected' : ''
                                            }`}
                                            title="claim-event"
                                        >
                                            Claim an existing event
                                        </span>
                                    </div>
                                </li>
                                <li className="modal-nav-item">
                                    <div
                                        className="modal-nav-item-link"
                                        title="add-event"
                                        onClick={this.handleModalSectionChange}
                                    >
                                        <span
                                            className={`modal-link ${
                                                activeModalSection === 'add-event' ? 'modal-link-selected' : ''
                                            }`}
                                            title="add-event"
                                        >
                                            Add a new event
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            {activeModalSection === 'claim-event' && (
                                <div className="modal-content-section">
                                    <p className="modal-centered-intro">
                                        If your {localiseText('organisation')}&apos;s event has already been listed on 
                                        Racecheck, select it using the search field below. Provide a link to the 
                                        event on your {localiseText('organisation')}&apos;s website or the event&apos;s Facebook 
                                        page as proof of ownership to help speed up the
                                        process.
                                    </p>
                                    <div className="log-in-form" onSubmit={this.handleClaimSubmit}>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="claim-event-id" className="modal-input-label">
                                                    Event to be claimed <span className="required-field">*</span>
                                                </label>
                                                <Search
                                                    placeholder={'Start typing the name of the event'}
                                                    apiEndpoint={'/api/react/events-autocomplete-react/'}
                                                    handleEventSelection={this.handleEventSelection}
                                                    handleSearchChange={this.handleSearchChange}
                                                    modalAutocomplete
                                                    clearClaimAutocomplete={clearClaimAutocomplete}
                                                />
                                                {/* eslint-disable-next-line*/}
                                                {/* <input id="claim-event-id" value={claimEventID} onChange={this.handleChange} className="input modal-input" name="claimEventID" type="text"></input> */}
                                            </div>
                                        </div>
                                        {hasError && (
                                            <p className="error-text-red error-text-margin-fix"> {hasError}</p>
                                        )}
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="claim-event-website" className="modal-input-label">
                                                    Link to event website or event Facebook page{' '}
                                                    <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="claim-event-website"
                                                    value={claimEventWebsite}
                                                    onChange={this.handleChange}
                                                    className="input modal-input"
                                                    name="claimEventWebsite"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-btn-row">
                                            {loadingButton ? (
                                                <div
                                                    role="button"
                                                    className={
                                                        'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                                    }
                                                >
                                                    <img
                                                        className="subscription-btn-loader"
                                                        src={SmallLoader}
                                                        alt="userprofile"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    role="button"
                                                    className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                        claimEventReadyForSubmission ? '' : 'disabled-primary-btn'
                                                    }`}
                                                    onClick={this.handleClaimSubmit}
                                                >
                                                    Submit event claim
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeModalSection === 'add-event' && (
                                <div className="modal-content-section">
                                    <p className="modal-centered-intro">
                                        Please ensure the event has not already been listed on Racecheck before
                                        submitting a request for it to be added. You can check if this is the case in
                                        the{' '}
                                        <span
                                            className="modal-content-link"
                                            title="claim-event"
                                            onClick={this.handleModalSectionChange}
                                        >
                                            claim tab
                                        </span>
                                        .
                                    </p>
                                    <form className="log-in-form" onSubmit={this.handleAddSubmit}>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="add-event-name" className="modal-input-label">
                                                    Event name <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="add-event-name"
                                                    value={addEventName}
                                                    onChange={this.handleChange}
                                                    className="input modal-input"
                                                    name="addEventName"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="add-event-website" className="modal-input-label">
                                                    Link to event website or event Facebook page{' '}
                                                    <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="add-event-website"
                                                    value={addEventWebsite}
                                                    onChange={this.handleChange}
                                                    className="input modal-input"
                                                    name="addEventWebsite"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-btn-row">
                                            {loadingButton ? (
                                                <div
                                                    role="button"
                                                    className={
                                                        'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                                    }
                                                >
                                                    <img
                                                        className="subscription-btn-loader"
                                                        src={SmallLoader}
                                                        alt="userprofile"
                                                    />
                                                </div>
                                            ) : (
                                                <div
                                                    role="button"
                                                    className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                        addEventReadyForSubmission ? '' : 'disabled-primary-btn'
                                                    }`}
                                                    onClick={this.handleAddSubmit}
                                                >
                                                    Submit request
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

AddEventModal.propTypes = {
    toggleModal: PropTypes.func,
};

export default AddEventModal;
