import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as TrophyIcon } from '../../images/icons/trophy.svg';

const EventLabel = ({ award }) => {
    if (!award) return null;
    return (
        <>
            <p className="event-label" style={{ backgroundColor: award?.banner_color }}>
                <TrophyIcon className="event-label-icon" />
                {award?.banner_text}
            </p>
            <div className={'event-label-line'} style={{ backgroundColor: award?.banner_color }} />
        </>
    );
};

export default EventLabel;

EventLabel.propTypes = {
    award: PropTypes.shape({
        banner_color: PropTypes.string,
        banner_text: PropTypes.string,
    })
};
