import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron_down.svg';
import { ReactComponent as ChevronUp } from '../../images/icons/chevron_up.svg';
import { localiseText } from '../../utils';

class NavBarHelperModal extends Component {
    // static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            active: -1
        };
    }

    renderModalTitle (section) {
        if (section.includes('/events/') & !section.includes('/widgets') & !section.includes('/reviews')) {
            return 'Events FAQ';
        }
        if (section.includes('/widgets')) {
            return 'Widgets FAQ';
        }
        if (section.includes('/reviews')) {
            return 'Reviews FAQ';
        }
        if (section.includes('/analytics/events-report')) {
            return 'Analytics FAQ';
        }
        else if (section.includes('/resources/')) {
            return 'Resources FAQ';
        }
        else if (section.includes('/team/')) {
            return 'Team FAQ';
        }
        else if (section.includes('/subscription/')) {
            return 'Subscription FAQ';
        }
    }

    handleToggle = (index) => {
        if (this.state.active === index) {
            this.setState({
                active: -1
            });
        }
        else {
            this.setState({
                active: index
            });
        }
    };

    render () {
        const { active } = this.state;
        const pathname = window.location.href;
        /* eslint-disable */
        const eventFAQs = [
            {
                question: "🏁 How do I add or claim an event?",
                answer: "To add your event to Racecheck, or to claim an existing event on the platform, simply click the 'Add or claim an event' button at the top right of your event feed."
            },
            {
                question: "📅 How do I renew an event or change the date?",
                answer: "To change the date of an upcoming event click the pencil icon next to the date on the respective event card. If the last instance of your event is in the past click the 'Update event date' button on the event card."
            },
        ]
        const widgetFAQs = [
            {
                question: "ℹ️ What are Racecheck widgets?",
                answer: "The Racecheck widgets are designed to allow you to showcase your reviews on your own website and registration platforms. With a growing range of customisable inline and fixed options, there will be a widget that works for you!"
            },
            {
                question: "👩🏾‍💻 How do I install a Racecheck widget?",
                answer: "We've designed our widgets to be as simple as possible to install. Simply choose the style you want to use from your Widget Library, customise the style and content, and then copy and paste the provided code into your website. Each widget has its own instructions and code (viewable through your dashboard), but should you be stuck don't hesititate to contact the team for installation support."
            },
        ]
        const analyticsFAQs = [
            {
                question: "🧮 How are my analytics calculated?",
                answer: "You analytics are calculated via the Racecheck tracking you can install on your website and checkout process. To install the tracking code for an event's Review Box and registrations, visit that events 'Widgets' -> 'Review Box' page on your dashboard for further instructions."
            },
            {
                question: "🧠 Why are some of my events using estimates?",
                answer: "If you don't have trackers installed for a component of your analytics report we can use that data we have recorded alongside our knowledge of industry trends to calculate an estimated value. If you would rather not see the estimated values you can toggle them off at the top of the report."
            },
            {
                question: "🗓️ Can I view data from a different time frame?",
                answer: "You have the option of viewing data from the past 30, 60, 90, 180 and 360 days. To change the timeframe click the dropdown in the top right of your analytics report."
            },
        ]
        const reviewFAQs = [
            {
                question: "🖼️ How do I generate social posts?",
                answer: "The Racecheck review image generator allows you to easily turn your reviews into images ready to be shared on your social channels. To get started, click the 'Generate social post' button (image icon) on the bottom right of any review. You will then be walked through the step-by-step process for generating your image(s)."
            },
            {
                question: "✍🏻 How do I reply to reviews?",
                answer: "In order to reply to a review, click the 'Reply' button underneath the review - you will then be able to type your reply and post it. Replying to your reviews is a great way to show your audience you are engaged with their feedback."
            },
            {
                question: "🔖 How do I bookmark reviews?",
                answer: "To bookmark a review, click the 'Bookmark' button/icon on the bottom right of the review. If you want to remove a review from your bookmarks simply click the button again to do so. Once you have bookmarked some reviews you can use the filters at the top of your feed to easily access them."
            },
            {
                question: "🚩 How do I report a review?",
                answer: "If you need to report a review, click the 'Report' button (flag) on the bottom right of the review. You will then be directed to a form where you can select the reason you are making the report, and send it to our team. We will endeveour to investigate all reports as soon as we are able to."
            },
            {
                question: "🗣️ How do I share reviews?",
                answer: "There are two ways you can share your event reviews. The first option is to use the Racecheck image generator (explained above) to build dynamic images for your social channels - we find this is the most engaging for potential future participants! ALternatively, you can copy a link to a review by clicking the 'Copy review link' button on the bottom right of a review card."
            },
            {
                question: "🥇 How do I earn Racecheck awards?",
                answer: "The yearly Racecheck Awards are automatically earnt once an event passes a certain criteria. To qualify, your event must have at least 30 reviews and an average rating of 4.55/5 for Bronze, 4.70/5 for Silver and 4.80/5 for Gold. The coveted Top Rated award is given to the events with the highest rating in their sport category."
            },
        ]
        const resourcesFAQs = [
            {
                question: "🥇 How do I earn Racecheck awards?",
                answer: "The yearly Racecheck Awards are automatically earnt once an event passes a certain criteria. To qualify, your event must have at least 30 reviews and an average rating of 4.55/5 for Bronze, 4.70/5 for Silver and 4.80/5 for Gold. The coveted Top Rated award is given to the events with the highest rating in their sport category."
            },
            {
                question: "📥 How do I download my event awards?",
                answer: "To get started, click the 'Download Racecheck award assets' button at the top of the Toolkits page. From here you will be able to select the awards you have earnt and download them in the file types you require."
            }
        ]
        const teamFAQs = [
            {
                question: "👋🏽 How can I add a someone to my " + `${localiseText('organisation')}` + "/team?",
                answer: "To add someone to your " + `${localiseText('organisation')}` + ", click on the 'Invite a new team member' button at the top of the list of current team members. Once you submit the form, the invitee will receive an email granting them access to join your " + `${localiseText('organisation')}` + "."
            },
            {
                question: "❌ How can I remove someone from my " + `${localiseText('organisation')}` + "/team?",
                answer: "It is not currently possible to remove a team member via the Racecheck for " + `${localiseText('Organiser')}` + " Dashboard. Should you need to remove somebody, please contact us so we can do this for you."
            }
        ]
        const subscriptionFAQs = [
            {
                question: "⬆️ How do I upgrade to Racecheck Premium",
                answer: "Upgrading to Racecheck Premium is quick and easy! Simply navigate to the Subscription page of the dashboard and press 'Get started' to start the upgrade process."
            },
            {
                question: "💳 How do I change my payment method?",
                answer: "To change your payment method, click the pencil icon next to your current payment method shown in the subscription summary at the top of the page."
            },
            {
                question: "❌ How do I cancel my subscription?",
                answer: "You can cancel your subscription at any time by clicking the 'Manage your plan' button at the top of the page. Your " + `${localiseText('organisation')}` + " will then be downgraded to Free at the end of your current pay-cycle."
            },
        ]
        /* eslint-enable */
        
        return (
            <div className="modal-backdrop">
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">{this.renderModalTitle(pathname)}</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content nav-bar-helper-modal-content">
                            {pathname.includes('/events/') && !pathname.includes('/widgets') && !pathname.includes('/reviews') &&
                                <div className="modal-content-section">
                                    <div className="faq-container">
                                        {eventFAQs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <div className="faq-q" onClick={() => this.handleToggle(index)}>
                                                    <p>{faq.question}</p>
                                                    {active === index ?
                                                        <ChevronUp />
                                                        :
                                                        <ChevronDown />    
                                                    }
                                                </div>
                                                <div className={`faq-a ${active === index ? 'open' : ''}`}>
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {pathname.includes('/widgets') &&
                                <div className="modal-content-section">
                                    <div className="faq-container">
                                        {widgetFAQs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <div className="faq-q" onClick={() => this.handleToggle(index)}>
                                                    <p>{faq.question}</p>
                                                    {active === index ?
                                                        <ChevronUp />
                                                        :
                                                        <ChevronDown />    
                                                    }
                                                </div>
                                                <div className={`faq-a ${active === index ? 'open' : ''}`}>
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {pathname.includes('/analytics/events-report') &&
                                <div className="modal-content-section">
                                    <div className="faq-container">
                                        {analyticsFAQs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <div className="faq-q" onClick={() => this.handleToggle(index)}>
                                                    <p>{faq.question}</p>
                                                    {active === index ?
                                                        <ChevronUp />
                                                        :
                                                        <ChevronDown />    
                                                    }
                                                </div>
                                                <div className={`faq-a ${active === index ? 'open' : ''}`}>
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {pathname.includes('/reviews') &&
                                <div className="modal-content-section">
                                    <div className="faq-container">
                                        {reviewFAQs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <div className="faq-q" onClick={() => this.handleToggle(index)}>
                                                    <p>{faq.question}</p>
                                                    {active === index ?
                                                        <ChevronUp />
                                                        :
                                                        <ChevronDown />    
                                                    }
                                                </div>
                                                <div className={`faq-a ${active === index ? 'open' : ''}`}>
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {pathname.includes('/resources/') &&
                                <div className="modal-content-section">
                                    <div className="faq-container">
                                        {resourcesFAQs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <div className="faq-q" onClick={() => this.handleToggle(index)}>
                                                    <p>{faq.question}</p>
                                                    {active === index ?
                                                        <ChevronUp />
                                                        :
                                                        <ChevronDown />    
                                                    }
                                                </div>
                                                <div className={`faq-a ${active === index ? 'open' : ''}`}>
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {pathname.includes('/team/') &&
                                <div className="modal-content-section">
                                    <div className="faq-container">
                                        {teamFAQs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <div className="faq-q" onClick={() => this.handleToggle(index)}>
                                                    <p>{faq.question}</p>
                                                    {active === index ?
                                                        <ChevronUp />
                                                        :
                                                        <ChevronDown />    
                                                    }
                                                </div>
                                                <div className={`faq-a ${active === index ? 'open' : ''}`}>
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            {pathname.includes('/subscription/') &&
                                <div className="modal-content-section">
                                    <div className="faq-container">
                                        {subscriptionFAQs.map((faq, index) => (
                                            <div className="faq-item" key={index}>
                                                <div className="faq-q" onClick={() => this.handleToggle(index)}>
                                                    <p>{faq.question}</p>
                                                    {active === index ?
                                                        <ChevronUp />
                                                        :
                                                        <ChevronDown />    
                                                    }
                                                </div>
                                                <div className={`faq-a ${active === index ? 'open' : ''}`}>
                                                    <p>{faq.answer}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

NavBarHelperModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
};

NavBarHelperModal.defaultProps = {
    toggleModal: () => {},
};

export default NavBarHelperModal;
