import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../helpers/calendarStyling.css';
import '../organiserDashboard/Modal.css';
import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import SuccessMessage from '../helpers/successMessage';
import SmallLoader from '../../images/small_loader.gif';
import AppContext from '../app/AppContext';

class BasicEditEventModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        let momentDate = moment(this.props.event.latest_date, 'D MMM YYYY');
        momentDate = moment(momentDate).format('YYYY-MM-DD');

        this.state = {
            currentSuccessMessage: '',
            currentEventYear: moment(momentDate).year(),
            selectedDate: moment(momentDate).toDate(),
            formattedDate: momentDate,
            stringDate: moment(momentDate).format('dddd Do MMMM YYYY'),
            renewEventReadyForSubmission: true,
            dataChanged: false,
            formError: ''
        };
    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    handleDateChange = date => {
        this.setState({
            selectedDate: date,
            formattedDate: moment(date).format('YYYY-MM-DD'),
            stringDate: moment(date).format('dddd Do MMMM YYYY'),
            selectedYear: moment(date).year(),
            renewEventReadyForSubmission: true,
        });
    };

    handleDateEditSubmit = () => {
        if (this.state.renewEventReadyForSubmission) {
            this.setState(
                {
                    loadingButton: true,
                },
                () => {
                    const token = this.context.token ? this.context.token : localStorage.getItem('token');
                    const headers = {
                        Authorization: `Token ${token}`,
                    };
                    const { REACT_APP_API_URL } = process.env;
                    const { id } = this.context.selectedOrganisation;
                    /* eslint-disable-next-line */
                    const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${this.props.event.id}/edit_event/`;
                    const payload = { distance_date: this.state.formattedDate };
                    const successMessageText = 'Your event has successfully been updated';
                    const method = 'PUT';
                    
                    axios({
                        method,
                        url: apiCallURL,
                        data: payload,
                        headers,
                    })
                        .then(() => {
                            this.setState(
                                {
                                    loadingButton: false,
                                    renewEventReadyForSubmission: false,
                                    dataChanged: true,
                                },
                                () => {
                                    this.displaySuccessMessage(successMessageText);
                                }
                            );
                        })
                        .catch(({ response }) => {
                            this.setState({
                                formError: response.data,
                                loadingButton: false
                            });
                        });
                }
            );
        }
        else {
            console.log('Add event form validation');
        }
    };

    render () {
        const {
            currentSuccessMessage,
            selectedDate,
            stringDate,
            renewEventReadyForSubmission,
            loadingButton,
            formError
        } = this.state;

        console.log(formError);
        return (
            <div className="modal-backdrop">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                )}
                <div className="modal-padding" />
                <div className="modal-container">
                    {formError && (
                        <div className="edit-event-modal-error">{formError}</div>
                    )}
                    <div className="modal-header">
                        <button
                            className="modal-close-btn"
                            onClick={() =>
                                this.props.toggleModal(
                                    this.state.dataChanged,
                                    this.props.event.id,
                                    this.state.formattedDate
                                )
                            }
                        >
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">Edit {this.props.event.title}</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="modal-split-container">
                                    <div className="modal-split-child-half msch-date-halves">
                                        <Calendar
                                            onChange={this.handleDateChange}
                                            value={selectedDate}
                                            minDate={new Date()}
                                            minDetail="year"
                                        />
                                    </div>
                                    <div className="modal-split-child-half msch-date-halves">
                                        <p className="modal-centered-intro">
                                            The new date for {this.props.event.title} will be...
                                        </p>
                                        <p className="renew-modal-date">{stringDate}</p>
                                    </div>
                                </div>
                                <p className="basic-edit-event-contact">
                                    Looking to edit something other than the date of your event? Contact our events team
                                    via{' '}
                                    <a className="edit-event-email" href="mailto:events@racecheck.com">
                                        events@racecheck.com
                                    </a>
                                </p>
                                <div className="modal-btn-row">
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                        >
                                            <img
                                                className="subscription-btn-loader"
                                                src={SmallLoader}
                                                alt="userprofile"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            role="button"
                                            className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                renewEventReadyForSubmission ? '' : 'disabled-primary-btn'
                                            }`}
                                            onClick={this.handleDateEditSubmit}
                                        >
                                            Save new date
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

BasicEditEventModal.propTypes = {
    event: PropTypes.shape({
        latest_date: PropTypes.string,
        id: PropTypes.number,
        title: PropTypes.string,
    }),
    toggleModal: PropTypes.func,
};

BasicEditEventModal.defaultProps = {
    event: {
        latest_date: '',
        id: 0,
        title: '',
    },
    toggleModal: () => {},
};

export default BasicEditEventModal;
