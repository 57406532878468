import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import organiserDashboardImg from '../../images/organiser-dashboard.png';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import AppContext from '../app/AppContext';

class ReviewRepliesModal extends Component {
    static contextType = AppContext;

    render () {

        return (
            <div className="modal-backdrop">
                <div className="modal-padding" />
                <div className="modal-container modal-infobox-container">
                    <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                        <CloseIcon />
                    </button>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="reply-review-modal-content">
                                    <h1>Ready to reply to more reviews?</h1>
                                    <p>Your free Racecheck account allows you to reply to 2 reviews.
                                         By upgrading to Racecheck Premium you will have 
                                    <span>unlimited replies across all of your events</span>,
                                     meaning every one of your reviewers can be acknowledged.
                                    </p>
                                    <p>But that&apos;s not all you&apos;ll get...</p>
                                    <div className="reply-perks-container">
                                        <img src={organiserDashboardImg} alt="illustration of the organiser dashboard" />
                                        <ul className="reply-perks-list">
                                            <li><TickIcon />Interactive, expanding Review Boxes for your sales channels</li>
                                            <li><TickIcon />
                                            Analytics reports outlining Review Box performance and sales insights
                                            </li>
                                            <li><TickIcon />SEO & digital marketing boosting tools</li>
                                            <li><TickIcon />Customisable social content generation</li>
                                            <li><TickIcon />Live technical support</li>
                                        </ul>
                                    </div>
                                    <Link 
                                        to={'/subscription/subscription-plan'} 
                                        className="btn primary-btn"
                                        onClick={() => {
                                            const mpProps = this.context.selectedOrganisation ? {
                                                organisation: this.context.selectedOrganisation?.name,
                                                premium_subscriber: this.context.selectedOrganisation?.is_premium_subscriber
                                            } : {};
                                            /* eslint-disable-next-line */
                                            mixpanel.track('Dashboard - Review feed - Reply modal -  Upgrade to Premium click', mpProps);
                                        }}
                                    >Get started with Racecheck Premium
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

ReviewRepliesModal.propTypes = {
    toggleModal: PropTypes.func,
};

ReviewRepliesModal.defaultProps = {
    toggleModal: () => {},
};

export default ReviewRepliesModal;
