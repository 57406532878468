import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import he from 'he';
import RatingStars from '../helpers/starGenerator';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import SuccessMessage from '../helpers/successMessage';
import ErrorMessage from '../helpers/errorMessage';
import AppContext from '../app/AppContext';
import SmallLoader from '../../images/small_loader.gif';

class ReportReviewModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            currentSuccessMessage: '',
            currentErrorMessage: '',
            reportReason: '0',
            reportExtraInfo: '',
            reportReadyForSubmission: false,
            reportReasons: [],
            loadingButton: false,
        };
    }

    componentDidMount () {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/review_report_reasons/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                this.setState({
                    reportReasons: res.data,
                });
            })
            .catch(error => {
                console.log(error);
            });

        
    }

    createReportReasonsSelect = () =>
        this.state.reportReasons.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    handleReportSubmit = e => {
        if (this.state.reportReadyForSubmission) {
            this.setState(
                {
                    loadingButton: true,
                },
                () => {
                    e.preventDefault();
                    const token = this.context.token ? this.context.token : localStorage.getItem('token');
                    /* eslint-disable-next-line*/
                    const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/events/${this.props.review.event_id}/reviews/${this.props.review.id}/report_review/`;
                    const headers = {
                        Authorization: `Token ${token}`,
                    };
                    const payload = {
                        report_reason_id: parseInt(this.state.reportReason),
                    };
                    if (this.state.reportReason === '4' && this.state.reportExtraInfo) {
                        payload.other_reason = this.state.reportExtraInfo;
                    }
                    axios
                        .patch(apiCallURL, payload, { headers })
                        .then(res => {
                            this.setState(
                                {
                                    reportReadyForSubmission: false,
                                    loadingButton: false,
                                },
                                () => {
                                    this.displaySuccessMessage(res.data.message);
                                    this.props.toggleIsReported();
                                }
                            );
                        })
                        .catch(error => {
                            this.setState(
                                {
                                    reportReadyForSubmission: false,
                                    loadingButton: false,
                                },
                                () => {
                                    this.displayErrorMessage(error.response.data.message);
                                }
                            );
                        });
                }
            );
        }
        else {
            console.log('Report review form validation');
        }
    };

    handleChange = e => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                if (this.state.reportReadyForSubmission === false) {
                    if (this.state.reportReason && this.state.reportReason !== '0' && this.state.reportReason !== '4') {
                        this.setState({
                            reportReadyForSubmission: true,
                        });
                    }
                    else if (this.state.reportReason && this.state.reportReason === '4') {
                        if (this.state.reportExtraInfo) {
                            this.setState({
                                reportReadyForSubmission: true,
                            });
                        }
                    }
                }
                else if (
                    !this.state.reportReason ||
                    this.state.reportReason === '0' ||
                    (this.state.reportReason === '4' && this.state.reportExtraInfo === '')
                ) {
                    this.setState({
                        reportReadyForSubmission: false,
                    });
                }
            }
        );
    };

    render () {
        const {
            loadingButton,
            currentSuccessMessage,
            currentErrorMessage,
            reportReason,
            reportExtraInfo,
            reportReadyForSubmission,
        } = this.state;
        return (
            <div className="modal-backdrop">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                )}
                {currentErrorMessage && (
                    <ErrorMessage message={currentErrorMessage} resetErrorMessage={this.resetErrorMessage} />
                )}
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">Report review</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="report-review-modal-content">
                                    <div className="report-review-modal-review-details">
                                        <p>{this.props.review.event_title}</p>
                                        <p className="bullet">•</p>
                                        <p>{this.props.review.year}</p>
                                        <p className="bullet">•</p>
                                        <RatingStars rating={this.props.review.average_rating} />
                                    </div>
                                    <div className="report-review-modal-review-text">
                                        <p>{he.decode(this.props.review.clean_review_text)}</p>
                                    </div>
                                </div>
                                <div className="report-review-modal-review-data">
                                    <div className="rrmrd-row">
                                        <p className="rrmrd-label">Athlete name</p>
                                        <p className="rrmrd-info">{this.props.review.profile.name}</p>
                                    </div>
                                    <div className="rrmrd-row">
                                        <p className="rrmrd-label">Took part in</p>
                                        <p className="rrmrd-info">{this.props.review.year}</p>
                                    </div>
                                    {/* <div className="rrmrd-row">
                                        <p className="rrmrd-label">Distance complete</p>
                                        <p className="rrmrd-info">NEED DATA</p>
                                    </div>
                                    <div className="rrmrd-row">
                                        <p className="rrmrd-label">Bib number</p>
                                        <p className="rrmrd-info">NEED DATA</p>
                                    </div> */}
                                </div>
                                <div className="report-review-modal-review-data">
                                    <div className="rrmrd-row inline-select-container">
                                        <p className="rrmrd-label">
                                            Reason for report <span className="required-field">*</span>
                                        </p>
                                        <select
                                            id="current-event"
                                            className="rounded-dropdown inline-text-dropdown"
                                            name="reportReason"
                                            onChange={this.handleChange}
                                            value={reportReason}
                                            required
                                        >
                                            <option value="0" disabled>
                                                Select the reason for reporting
                                            </option>
                                            {this.createReportReasonsSelect()}
                                        </select>
                                    </div>
                                    {reportReason === '4' && (
                                        <div className="rrmrd-row">
                                            <p className="rrmrd-label">
                                                Extra information <span className="required-field">*</span>
                                            </p>
                                            <textarea
                                                id="claim-event-website"
                                                value={reportExtraInfo}
                                                onChange={this.handleChange}
                                                className="input modal-input report-textarea"
                                                name="reportExtraInfo"
                                                rows="3"
                                                placeholder="Why are you reporting this review?"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="modal-btn-row">
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn sub-fixed-width-btn-small primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                        >
                                            <img
                                                className="subscription-btn-loader"
                                                src={SmallLoader}
                                                alt="userprofile"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            role="button"
                                            className={`btn sub-fixed-width-btn-small primary-btn modal-form-btn ${
                                                reportReadyForSubmission ? '' : 'disabled-primary-btn'
                                            }`}
                                            onClick={this.handleReportSubmit}
                                        >
                                            Submit report
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

ReportReviewModal.propTypes = {
    review: PropTypes.shape({
        event_title: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        average_rating: PropTypes.number.isRequired,
        clean_review_text: PropTypes.string.isRequired,
        event_id: PropTypes.string,
        id: PropTypes.string,
        profile: PropTypes.shape({
            name: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    toggleModal: PropTypes.func,
    toggleIsReported: PropTypes.func,
};

ReportReviewModal.defaultProps = {
    review: {
        event_title: '',
        year: 0,
        average_rating: 0,
        clean_review_text: '',
        profile: {
            name: '',
        },
    },
    toggleModal: () => {},
    toggleIsReported: () => {},
};

export default ReportReviewModal;
