import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SubscriptionSection.css';
import axios from 'axios';
import FreeAccount from './FreeAccount';
import PremiumAccountView from './PremiumAccountView';
import Loader from '../helpers/loader';
import AppContext from '../app/AppContext';
import SelectSizeTierModal from './SelectSizeTierModal';

class SubscriptionPlan extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            userIsPremium: false,
            loadingSubscription: true,
            subscriptionDetails: {},
            fullSubscriptionDetails: {},
            hasError: null,
            shouldOpenChangePlanModal: this.props.location.state
                ? this.props.location.state.openChangePlanModal
                : false,
            subscriptionPlans: {}
        };
    }

    componentDidMount () {
        this.getSubscriptionDetails();
        this.getSubscriptionPlan();
    }

    componentDidUpdate (prevProps) {
        if (this.props.selectedOrganisation?.id !== prevProps.selectedOrganisation?.id) {
            this.getSubscriptionDetails();
            this.getSubscriptionPlan();
        }
    }

    getSubscriptionDetails = (shouldOpenChangePlanModal = null) => {
        this.setState(
            {
                loadingSubscription: true,
                shouldOpenChangePlanModal:
                    shouldOpenChangePlanModal != null
                        ? shouldOpenChangePlanModal
                        : this.state.shouldOpenChangePlanModal,
            },
            () => {
                const token = this.context.token ? this.context.token : localStorage.getItem('token');
                const { REACT_APP_API_URL } = process.env;
                const { id } = this.props.selectedOrganisation;
                const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/subscriptions/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };
                axios
                    .get(apiCallURL, { headers })
                    .then(res => {
                        this.setState({
                            userIsPremium: !!res.data.data.id,
                            loadingSubscription: false,
                            subscriptionDetails: res.data.data.details,
                            fullSubscriptionDetails: res.data.data,
                        });
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.setState({
                            hasError: error.response.data.data,
                            loadingSubscription: false,
                        });
                    });
            }
        );
    };

    calculateTotalCost = (country, paycycle, basePrice, vat) => {
        
        let totalCost;
        let hasVAT = false;
        if (country === 'United Kingdom') {
            hasVAT = true;
            if (paycycle === 'M' || paycycle === 'Monthly') {
                totalCost = basePrice + (basePrice * vat) / 100;
            }
            else {
                totalCost = basePrice * 12 + (basePrice * 12 * vat) / 100;
            }
        }
        else if (paycycle === 'M' || paycycle === 'Monthly') {
            totalCost = basePrice;
        }
        else {
            totalCost = basePrice * 12;
        }
        return {
            totalCost: totalCost.toFixed(2),
            hasVAT,
        };
    };

    getSubscriptionPlan = () => {
        const {
            token = localStorage.getItem('token')
        } = this.context;

        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/subscriptions/get_tier_plan/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                const annual = data.data.find(item => item.paycycle === 'A');
                const monthly = data.data.find(item => item.paycycle === 'M');

                this.setState({ subscriptionPlans: {
                    plans: {
                        premium: [
                            {
                                payment_details: [
                                    {
                                        paycycle: 'M',
                                        paycycle_display: 'Monthly',
                                        vat: monthly.vat,
                                        base_price: monthly.plan_price[0].base_price,
                                        base_price_currency: monthly.plan_price[0].base_price_currency,
                                        id: monthly.id
                                    },
                                    {
                                        paycycle: 'A',
                                        paycycle_display: 'Annual',
                                        vat: annual.vat,
                                        base_price: annual.plan_price[0].base_price,
                                        base_price_currency: annual.plan_price[0].base_price_currency,
                                        id: annual.id
                                    } 
                                ]
                            }
                        ]
                    }
                } });
            })
            .catch(error => {
                console.log(error);
            });
    };


    updateModalOpened = status => {
        this.setState({ modalOpened: status });
    };

    render () {
        const {
            userIsPremium,
            loadingSubscription,
            fullSubscriptionDetails,
            hasError,
            shouldOpenChangePlanModal,
            updateModalOpened,
            subscriptionPlans
        } = this.state;

        return (
            <>
                {this.context.isSizeTierModalOpen && 
                    <SelectSizeTierModal 
                        onClose={this.context.toggleSizeTierModal}
                        handleContinue={this.handleContinueUpgradeToPremium} 
                    />}
                <div>
                    {loadingSubscription ? (
                        <div className="section-loading-container">
                            <Loader />
                        </div>
                    ) : (
                        <div>
                            {hasError ? (
                                <div className="eb-error-container eb-subscription-error">
                                    <p className="eb-title">Something went wrong.</p>
                                    <p className="eb-copy">{hasError}</p>
                                </div>
                            ) : (
                                <div>
                                    {userIsPremium ? (
                                        <PremiumAccountView
                                            subscriptionDetails={fullSubscriptionDetails}
                                            updateSubData={this.getSubscriptionDetails}
                                            shouldOpenChangePlanModal={shouldOpenChangePlanModal}
                                            updateModalOpened={updateModalOpened}
                                            subscriptionPlans={subscriptionPlans}
                                            isUserPremium={userIsPremium}
                                        />
                                    ) : (

                                        <FreeAccount
                                            subscriptionDetails={fullSubscriptionDetails}
                                            isUserPremium={userIsPremium}
                                            plans={subscriptionPlans}
                                            updateSubData={this.getSubscriptionDetails}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </>

        );
    }
}

SubscriptionPlan.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            openChangePlanModal: PropTypes.bool,
        }),
    }),
    selectedOrganisation: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
};

SubscriptionPlan.defaultProps = {
    location: {
        state: {
            openChangePlanModal: false,
        },
    },
};

export default SubscriptionPlan;
