import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { ReactComponent as EventsIcon } from '../../images/icons/calendar.svg';
import { ReactComponent as AnalyticsIcon } from '../../images/icons/analytics-chart.svg';
import { ReactComponent as ResourcesIcon } from '../../images/icons/resources.svg';
import { ReactComponent as TeamIcon } from '../../images/icons/team.svg';
import { ReactComponent as SubscriptionIcon } from '../../images/icons/payment.svg';
import { ReactComponent as MenuIcon } from '../../images/icons/menu.svg';
import { ReactComponent as LockIcon } from '../../images/icons/lock.svg';
// import upgradeImage from '../../images/upgrade_gift.svg';
// import { ReactComponent as UpgradeIcon } from '../../images/locked_features_img.svg';
import AppContext from '../app/AppContext';
import NavBarHelper from '../helpers/NavBarHelper';

export default function MainNav () {
    const appContext = useContext(AppContext);
    const { pathname } = useLocation();
    const premium_subscriber = appContext.selectedOrganisation?.is_premium_subscriber;

    if (appContext.orgMembershipStatus !== 'member-of-org') {
        return null;
    }
    return (
        <div className={`main-nav-container ${!appContext.menuOpen && 'main-nav-closed'}
         ${appContext.overlayMainNav ? 'main-nav-overlay' : ''}`}
        >
            <div className="nav-logo-container">
                <img
                    src={require('../../images/racecheck_logo_black.svg')}
                    className="nav-brand"
                    alt="racecheck-logo"
                />
                <MenuIcon onClick={() => appContext.setMenuStatus(false)} />
            </div>
            <div className="side-nav-links">
                <Link to={'/events/events'} className="side-nav-link">
                    <button
                        className={classnames('side-nav-item',
                            { 'side-nav-active': pathname.includes('/events/') }
                        )}
                        title="events"
                        id="events-link"
                    >
                        <EventsIcon />
                        <p>Events</p>
                    </button>
                </Link>
                <Link
                    to={'/analytics/events-report'}
                    className="side-nav-link"
                    onClick={premium_subscriber ? null : (e) => e.preventDefault()}
                >
                    <button
                        className={classnames('side-nav-item',
                            {
                                'side-nav-active': pathname.startsWith('/analytics/'),
                                'side-nav-item-disabled': !premium_subscriber
                            })}
                        title="analytics"
                        id="analytics-link"
                    >
                        <AnalyticsIcon />
                        <p>
                            Analytics
                            {!premium_subscriber &&
                                <LockIcon />
                            }
                        </p>
                    </button>
                </Link>
                <Link to={'/resources/toolkits'} className="side-nav-link">
                    <button
                        className={classnames('side-nav-item',
                            { 'side-nav-active': pathname.includes('/resources/') }
                        )}
                        title="resources"
                        id="resources-link"
                    >
                        <ResourcesIcon />
                        <p>Resources</p>
                    </button>
                </Link>
                <Link to={'/team/team-members'} className="side-nav-link">
                    <button
                        className={classnames('side-nav-item',
                            { 'side-nav-active': pathname.includes('/team/') }
                        )}
                        title="team"
                        id="team-link"
                    >
                        <TeamIcon />
                        <p>Team</p>
                    </button>
                </Link>
                <Link to={'/subscription/subscription-plan'} className="side-nav-link">
                    <button
                        className={classnames('side-nav-item',
                            { 'side-nav-active': pathname.includes('/subscription/') }
                        )}
                        title="subscription"
                        id="subscription-link"
                    >
                        <SubscriptionIcon />
                        <p>Subscription</p>
                    </button>
                </Link>
            </div>
            {/*eslint-disable*/}
            {/* <div className="nav-promotion">
                <img src={upgradeImage} className="nav-promotion-img" alt="racecheck-logo"/>
                <p className="nav-promotion-copy">Upgrade to <span className="nav-promotion-tier">GOLD</span> for more features.</p>
                <p className="squared-btn">Upgrade</p>
            </div> */}
            {/* eslint-enable*/}
            <footer className="main-nav-footer">
                {/* {appContext.selectedOrganisation && !appContext.selectedOrganisation.is_premium_subscriber && (
                    <div className="nav-promotion">
                        <UpgradeIcon />
                        <p className="nav-promotion-copy">Upgrade events for more features.</p>
                        <Link to={'/subscription/subscription-plan'}>
                            <p className="btn primary-btn">Learn more</p>
                        </Link>
                    </div>
                )} */}
                <NavBarHelper />
                <ul className="footer-list">
                    <li className="footer-link">
                        <a
                            href="https://racecheck.com/terms_conditions/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-link-a"
                        >
                            Terms & Conditions
                        </a>
                        <span> · </span>
                    </li>
                    <li className="footer-link">
                        <a
                            href="https://racecheck.com/privacy_policy/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-link-a"
                        >
                            Privacy
                        </a>
                        <span> · </span>
                    </li>
                    <li className="footer-link">
                        <a
                            href="/"
                            rel="noopener noreferrer"
                            className="footer-link-a"
                            onClick={(e) => {
                                e.preventDefault();
                                appContext.toggleContactUsModal();
                            }}
                        >
                            Contact
                        </a>
                        <span> · </span>
                    </li>
                    <li className="footer-copyright">© {new Date().getFullYear()} Racecheck</li>
                </ul>
            </footer>
        </div>
    );
}
