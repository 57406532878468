import React from 'react';
import ReviewImageGeneratorSizeOption from './ReviewImageGeneratorSizeOption';

const ReviewImageGeneratorStep2 = ({ sizeOptions, selectedSizes, setSelectedSizes, setActivePreviewImage }) => (
    <>
        <p className="rig-section-header">Select image sizes</p>
        {sizeOptions.map(option => (
            <ReviewImageGeneratorSizeOption key={option.id} option={option}
                selectedSizes={selectedSizes} setSelectedSizes={setSelectedSizes}
                setActivePreviewImage={setActivePreviewImage}
            />))}
    </>
);

export default ReviewImageGeneratorStep2;