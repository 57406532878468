import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AddIcon } from '../../images/icons/add_person.svg';
import AddTeamMemberModal from './AddTeamMemberModal';

class ButtonTeamMemberCard extends Component {
    constructor (props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
    }

    toggleModal = (status, requiresRefresh = false) => {
        this.setState({ modalOpen: status });
        if (requiresRefresh) {
            this.props.refreshTeamAudit();
        }
    };

    render () {
        const { modalOpen } = this.state;
        return (
            <div>
                <div className="button-team-member-card-mobile" onClick={() => this.toggleModal(true)}>
                    <div className="btmcm-icon">
                        <AddIcon />
                    </div>
                    <p className="tmm-name">Invite +</p>
                </div>
                <div className="button-team-member-card" onClick={() => this.toggleModal(true)}>
                    <AddIcon />
                    <p>Invite a new team member</p>
                </div>
                {modalOpen && <AddTeamMemberModal toggleModal={this.toggleModal} eventsList={this.props.eventsList} />}
            </div>
        );
    }
}

ButtonTeamMemberCard.propTypes = {
    refreshTeamAudit: PropTypes.func.isRequired,
};

ButtonTeamMemberCard.defaultProps = {
    refreshTeamAudit: () => {},
    eventsList: [],
};

export default ButtonTeamMemberCard;
