import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import AnalyticsReport from './AnalyticsReport';
import './EventsSection.css';
import MonthlyRecap from './MonthlyRecap';

const Analytics = (props) => {
    const { path, url } = useRouteMatch();

    return (
        <div className="analytics-container">
            <div className="analytics-header">
                <NavLink 
                    to={`${url}/widget`}
                    className="analytics-tab-btn"
                >
                    Monthly recap
                </NavLink>
                <NavLink 
                    to={`${url}/event`}
                    className="analytics-tab-btn"
                >
                    Overall breakdown
                </NavLink>
            </div>
            <Switch>
                <Route 
                    path={path}
                    exact
                    render={() => <Redirect to={`${url}/widget`} />}
                /> 
                <Route
                    path={`${path}/event`}
                    render={() => <AnalyticsReport {...props} event={props.event} />}
                />
                <Route
                    path={`${path}/widget`}
                    render={() => <MonthlyRecap event={props.event} />}
                />
            </Switch>
        </div>
    );
};

export default Analytics;

Analytics.propTypes = {
    event: PropTypes.instanceOf(Object)
};
