import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { isEqual } from 'lodash';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Checkbox from '../../helpers/checkbox';
import Loader from '../../helpers/loader';
import { ReactComponent as CopyIcon } from '../../../images/icons/copy.svg';
import RacecheckWidget from '../RacecheckWidget';
import ReviewCarouselWidget from '../ReviewCarouselWidget';
import AppContext from '../../app/AppContext';
import ForwardToDevTeamModal from '../../team/ForwardToDevTeamModal';
import { isValidURL } from '../../helpers/validationFunctions';
import CustomReviewBoxImage from '../../../images/icons/custom-review-box.svg';
import ManageContent from '../../../images/icons/manage-content.svg';
import StatsChart from '../../../images/icons/stats-chart.svg';
import Installation from '../../../images/icons/terminal-outline.svg';
import { ReactComponent as ChevronDown } from '../../../images/icons/chevron_down.svg';
import { ReactComponent as HelpIcon } from '../../../images/icons/help.svg';
import mixpanel from 'mixpanel-browser';
import { ReactComponent as TickIcon } from '../../../images/icons/tick.svg';
import { ReactComponent as PreviewIcon } from '../../../images/icons/preview.svg';
import { ReactComponent as CodeIcon } from '../../../images/icons/terminal-outline.svg';
import FreeReviewBannerWidget from '../FreeReviewBannerWidget';
import OrgDashIllustration from '../../../images/organiser-dashboard.png';

class Input extends Component {
    constructor (props) {
        super(props);

        this.state = {
            value: props.value,
        };
    }

    onChange = e => {
        this.setState({ value: e.target.value });
        this.props.onChange(e);
    }

    render () {
        const { id, required, className, name } = this.props;
        return (
            <input
                value={this.state.value}
                onChange={this.onChange}
                id={id}
                className={className}
                name={name}
                required={required}
            />
        );
    }
}

Input.propTypes = {
    id: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string
};


class ManageWidget extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            widgetId: null,
            widgetType: this.props.match?.params?.widgetType ? this.props.match?.params?.widgetType : 'review-box',
            dataLoaded: false,
            reviewSortingOptions: [],
            positionOptions: [],
            autoplayOptions: [],
            structuredDataOptions: [],
            maximumCardSlidesOptions: [],
            themeOptions: [],
            headerTextOptions: [],
            starRatingFilterOptions: [],
            eventYearFilterOptions: [],
            widgetOrganisationSlug: null,
            widgetEvents: [],
            widgetActiveValue: null,
            widgetPremiumValue: null,
            widgetMultiraceValue: null,
            expandedBlock: 0,
            forwardToDevTeamType: '',
            originalWidgetValues: {
                widgetStatus: null,
                widgetAutoplayValue: null,
                widgetStructuredDataValue: null,
                widgetPositionValue: '',
                widgetReviewSortingValue: '',
                widgetRegistrationURL: '',
                widgetMaximumCardSlides: null,
                widgetTheme: '',
                widgetStarRatingFilter: '',
                widgetEventYearFilter: ''
            },
            changedWidgetValues: {
                widgetStatus: null,
                widgetAutoplayValue: null,
                widgetStructuredDataValue: null,
                widgetPositionValue: '',
                widgetReviewSortingValue: '',
                widgetRegistrationURL: '',
                widgetMaximumCardSlides: null,
                widgetTheme: '',
                widgetStarRatingFilter: '',
                widgetEventYearFilter: ''
            },
            widgetMounted: false,
            saveLoadingButton: false,
            widgetCodeCopied: false,
            widgetCode2Copied: false,
            forwardToDevTeamModalIsOpen: false,
            urlErrorMessage: '',
            registration_completed_tracker_installed: false,
            registration_intent_tracker_installed: false,
            activePreviewBackground: 
                localStorage.getItem('activePreviewBackground') ? localStorage.getItem('activePreviewBackground') : 'light',
            activePreviewSection: 'preview'
        };
    }

    componentDidMount () {
        let widgetId;
        switch (this.state.widgetType) {
            case 'review-box':
                widgetId = this.props.event?.widgets?.review_box?.id;
                break;
            case 'review-carousel':
                widgetId = this.props.event?.widgets?.review_carousel?.id;
                break;
            case 'free-review-banner':
                widgetId = this.props.event?.widgets?.free_review_banner?.id;
                break;
            default:
                widgetId = this.props.event?.widgets?.review_box?.id;
                break;
        }
        this.setState({
            widgetId
        }, this.getWidgetSetup);
    }

    componentDidUpdate (prevProps) {
        // eslint-disable-next-line
        if (this.props.event?.widgets?.review_box?.id !== prevProps.event?.widgets?.review_box?.id || this.props.event?.widgets?.review_carousel?.id !== prevProps.event?.widgets?.review_carousel?.id) {
            let widgetId;
            switch (this.state.widgetType) {
                case 'review-box':
                    widgetId = this.props.event?.widgets?.review_box?.id;
                    break;
                case 'review-carousel':
                    widgetId = this.props.event?.widgets?.review_carousel?.id;
                    break;
                case 'free-review-banner':
                    widgetId = this.props.event?.widgets?.free_review_banner?.id;
                    break;
                default:
                    widgetId = this.props.event?.widgets?.review_box?.id;
                    break;
            }
            this.setState({
                widgetId
            }, this.getWidgetSetup);
        }
    }

    getWidgetSetup = () => {
        if (!this.state.widgetId) return;
        switch (this.state.widgetType) {
            case 'review-box':
                return this.getReviewBoxSetup();
            case 'review-carousel':
                return this.getReviewCarouselSetup();
            case 'free-review-banner':
                return this.getFreeReviewBannerSetup();
            default:
                return this.getReviewBoxSetup();
        }
    }

    getReviewBoxSetup = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = this.props.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/widgets/${this.state.widgetId}/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                const currentAutoplayValue = data.data.parameters.filter(obj => obj.slug === 'autoplay')[0];
                const currentPositionValue = data.data.parameters.filter(obj => obj.slug === 'position')[0];
                const currentReviewSortingValue = data.data.parameters.filter(obj => obj.slug === 'reviewSorting')[0];
                const currentStructuredDataValue = data.data.parameters.filter(obj => obj.slug === 'structuredData')[0];
                const currentRegistrationUrl = data.data.events[0]?.registration_link;
                const eventId = data.data.events[0]?.id;
                this.setState(
                    {
                        widgetOrganisationSlug: data?.data?.organisation.slug,
                        widgetEvents: data?.data?.events,
                        widgetActiveValue: data?.data?.is_active,
                        widgetPremiumValue: data?.data?.is_premium,
                        widgetMultiraceValue: data?.data?.is_multirace,
                        eventId,
                        originalWidgetValues: {
                            widgetStatus: data?.data?.is_active,
                            widgetAutoplayValue: currentAutoplayValue?.value || false,
                            widgetStructuredDataValue: currentStructuredDataValue ? currentStructuredDataValue?.value :
                                this.props.selectedOrganisation.is_premium_subscriber,
                            widgetReviewSortingValue: currentReviewSortingValue?.value || 'most-recent-first',
                            widgetPositionValue: currentPositionValue?.value || 'bottom-right',
                            widgetRegistrationURL: currentRegistrationUrl || null,
                        },
                        changedWidgetValues: {
                            widgetStatus: data?.data?.is_active,
                            widgetAutoplayValue: currentAutoplayValue?.value || false,
                            widgetStructuredDataValue: currentStructuredDataValue ? currentStructuredDataValue?.value :
                                this.props.selectedOrganisation.is_premium_subscriber,
                            widgetReviewSortingValue: currentReviewSortingValue?.value || 'most-recent-first',
                            widgetPositionValue: currentPositionValue?.value || 'bottom-right',
                            widgetRegistrationURL: currentRegistrationUrl || null,
                        },
                        dataLoaded: true,
                        registration_completed_tracker_installed: data?.data?.registration_completed_tracker_installed,
                        registration_intent_tracker_installed: data?.data?.registration_intent_tracker_installed
                    },
                    () => {
                        this.getReviewBoxSettings();
                    }
                );
            })
            .catch(error => {
                console.log(error);
            });
    };

    getReviewCarouselSetup = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = this.props.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/review-carousel-widgets/${this.state.widgetId}/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                const currentAutoplayValue = data.data.parameters.filter(obj => obj.slug === 'autoplay')[0];
                const currentMaximumCardSlidesValue = data.data.parameters.filter(obj => obj.slug === 'cardSlides')[0];
                const currentThemeValue = data.data.parameters.filter(obj => obj.slug === 'theme')[0];
                const currentHeaderTextValue = data.data.parameters.filter(obj => obj.slug === 'headerText')[0];
                const currentReviewSortingValue = data.data.parameters.filter(obj => obj.slug === 'reviewSorting')[0];
                const currentStructuredDataValue = data.data.parameters.filter(obj => obj.slug === 'structuredData')[0];
                const currentStarRatingFilterValue = data.data.parameters.filter(obj => obj.slug === 'starRatingFilter')[0];
                const currentEventYearFilterValue = data.data.parameters.filter(obj => obj.slug === 'eventYearFilter')[0];
                const eventId = data.data.event;
                this.setState(
                    {
                        widgetOrganisationSlug: data?.data?.organisation.slug,
                        widgetEvents: [data?.data?.event],
                        eventId,
                        originalWidgetValues: {
                            widgetAutoplayValue: currentAutoplayValue?.value || false,
                            widgetMaximumCardSlidesValue: currentMaximumCardSlidesValue.value || 3,
                            widgetThemeValue: currentThemeValue.value || 'light',
                            widgetHeaderTextValue: currentHeaderTextValue.value || 'light',
                            widgetReviewSortingValue: currentReviewSortingValue?.value || 'most-recent-first',
                            widgetStructuredDataValue: currentStructuredDataValue ? currentStructuredDataValue?.value :
                                this.props.selectedOrganisation.is_premium_subscriber,
                            widgetStarRatingFilterValue: currentStarRatingFilterValue.value || 'all',
                            widgetEventYearFilterValue: currentEventYearFilterValue.value || 'all'
                        },
                        changedWidgetValues: {
                            widgetAutoplayValue: currentAutoplayValue?.value || false,
                            widgetMaximumCardSlidesValue: currentMaximumCardSlidesValue.value || 3,
                            widgetThemeValue: currentThemeValue.value || 'light',
                            widgetHeaderTextValue: currentHeaderTextValue.value || 'light',
                            widgetReviewSortingValue: currentReviewSortingValue?.value || 'most-recent-first',
                            widgetStructuredDataValue: currentStructuredDataValue ? currentStructuredDataValue?.value :
                                this.props.selectedOrganisation.is_premium_subscriber,
                            widgetStarRatingFilterValue: currentStarRatingFilterValue.value || 'all',
                            widgetEventYearFilterValue: currentEventYearFilterValue.value || 'all'
                        },
                        dataLoaded: true,
                    },
                    () => {
                        this.getReviewCarouselSettings();
                    }
                );
            })
            .catch(error => {
                console.log(error);
            });
    };

    getFreeReviewBannerSetup = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = this.props.selectedOrganisation;
        /* eslint-disable-next-line*/
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/free-widgets/${this.props.event.id}/${this.state.widgetType.replaceAll('-', '_')}/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                const currentHeaderTextValue = data.data.parameters.filter(obj => obj.slug === 'headerText')[0];
                const eventId = data.data.event;
                this.setState(
                    {
                        widgetOrganisationSlug: data?.data?.organisation.slug,
                        widgetEvents: [data?.data?.event],
                        eventId,
                        originalWidgetValues: {
                            widgetHeaderTextValue: currentHeaderTextValue.value || 'light',
                        },
                        changedWidgetValues: {
                            widgetHeaderTextValue: currentHeaderTextValue.value || 'light',
                        },
                        dataLoaded: true,
                    },
                    () => {
                        this.getFreeReviewBannerSettings();
                    }
                );
            })
            .catch(error => {
                console.log(error);
            });
    };

    getReviewBoxSettings = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };

        const settingsURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/review_box_settings/`;

        axios
            .get(settingsURL, { headers })
            .then(({ data }) => {
                this.setState({
                    autoplayOptions: data?.data?.display_settings?.autoplay,
                    positionOptions: data?.data?.display_settings?.position,
                    reviewSortingOptions: data?.data?.content_settings?.review_sorting,
                    structuredDataOptions: data?.data?.content_settings?.structured_data,
                    dataLoaded: true,
                    widgetMounted: true,
                });
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    getReviewCarouselSettings = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        /* eslint-disable-next-line*/
        const settingsURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/review_carousel_settings/?review_carousel_widget_id=${this.state.widgetId}`;

        axios
            .get(settingsURL, { headers })
            .then(({ data }) => {
                this.setState({
                    autoplayOptions: data?.data?.display_settings?.autoplay,
                    maximumCardSlidesOptions: data?.data?.display_settings?.card_slides,
                    themeOptions: data?.data?.display_settings?.theme,
                    headerTextOptions: data?.data?.display_settings?.header_text,
                    reviewSortingOptions: data?.data?.content_settings?.review_sorting,
                    structuredDataOptions: data?.data?.content_settings?.structured_data,
                    starRatingFilterOptions: data?.data?.content_settings?.star_rating_filter,
                    eventYearFilterOptions: data?.data?.content_settings?.event_year_filter,
                    dataLoaded: true,
                    widgetMounted: true,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    getFreeReviewBannerSettings = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        const settingsURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/free_review_banner_settings/`;

        axios
            .get(settingsURL, { headers })
            .then(({ data }) => {
                this.setState({
                    headerTextOptions: data?.data?.display_settings?.header_text,
                    dataLoaded: true,
                    widgetMounted: true,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    createDisplaySettings = () => {
        const { changedWidgetValues, widgetMultiraceValue, widgetType } = this.state;
        return (
            <div className="mw-setup-options-body">
                {/* Active */}
                {widgetType === 'review-box' && (
                    <div className="mw-setup-row">
                        <label
                            htmlFor="mw-display-status"
                            className="mw-setup-row-label"
                        >
                            Display status
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                Set whether your Review Box is being displayed on your website or 
                                not without having to remove the code.
                                </span>
                            </div>
                        </label>
                        <select
                            id="mw-display-status"
                            className="mw-setup-row-select input"
                            name="widgetStatus"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetStatus}
                            required
                        >
                            <option value>Active</option>
                            <option value={false}>Inactive</option>
                        </select>
                    </div>
                )}

                {/* Position */}
                {widgetType === 'review-box' && (
                    <div className="mw-setup-row">
                        <label htmlFor="mw-position" className="mw-setup-row-label">
                            Position
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                Where on your website the widget will be displayed.
                                </span>
                            </div>
                        </label>
                        <select
                            id="mw-position"
                            className="mw-setup-row-select input"
                            name="widgetPositionValue"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetPositionValue}
                            required
                        >
                            {this.createPositionDropdown()}
                        </select>
                    </div>
                )}

                {/* Theme */}
                {widgetType === 'review-carousel' && (
                    <div className="mw-setup-row">
                        <label htmlFor="mw-theme" className="mw-setup-row-label">
                            Card theme
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                    Choose the theme that best works with your website to ensure your 
                                    widget stands out.
                                </span>
                            </div>
                        </label>
                        <select
                            id="mw-theme"
                            className="mw-setup-row-select input"
                            name="widgetThemeValue"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetThemeValue}
                            required
                        >
                            {this.createThemeDropdown()}
                        </select>
                    </div>
                )}

                {/* Header Text */}
                {['review-carousel', 'free-review-banner'].includes(widgetType) && (
                    <div className="mw-setup-row">
                        <label htmlFor="mw-header-text" className="mw-setup-row-label">
                            Header text
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                    Choose the colour that best works with your website to ensure the 
                                    header text stands out.
                                </span>
                            </div>
                        </label>
                        <select
                            id="mw-header-text"
                            className="mw-setup-row-select input"
                            name="widgetHeaderTextValue"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetHeaderTextValue}
                            required
                        >
                            {this.createHeaderTextDropdown()}
                        </select>
                    </div>
                )}

                {/* Maximum cards per slide */}
                {widgetType === 'review-carousel' && (
                    <div className="mw-setup-row">
                        <label htmlFor="mw-max-cards" className="mw-setup-row-label">
                            Maximum cards per slide
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                    The maximum number of review cards shown per slide.
                                </span>
                                <div className="mw-tooltip-extra-row">
                                    <span className="mw-title-desc">
                                        Try experimenting with this value and the width of your 
                                        widget container to find what works best for your site. Less 
                                        cards may be shown if there is not enough space available.
                                    </span>
                                </div>
                            </div>
                        </label>
                        <select
                            id="mw-max-cards"
                            className="mw-setup-row-select input"
                            name="widgetMaximumCardSlidesValue"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetMaximumCardSlidesValue}
                            required
                        >
                            {this.createMaximumCardSlidesDropdown()}
                        </select>
                    </div>
                )}

                {/* Autoplay */}
                {(widgetType === 'review-carousel' || widgetMultiraceValue) && (
                    <div className="mw-setup-row">
                        <label htmlFor="mw-autoplay"
                            className="mw-setup-row-label"
                        >
                            Autoplay
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                {widgetMultiraceValue &&
                                    <span className="mw-title-desc">
                                        When ticked the Review Box will auto-cycle through all events on a timer.
                                    </span>
                                }
                                {widgetType === 'review-carousel' &&
                                    <span className="mw-title-desc">
                                        When ticked the widget will auto-cycle through the included reviews.
                                    </span>
                                }
                            </div>
                        </label>
                        <div className="checkbox-with-label">
                            <Checkbox
                                id="mw-autoplay"
                                label=""
                                isSelected={changedWidgetValues.widgetAutoplayValue}
                                onCheckboxChange={this.handleAutoplayCheckbox}
                                value={changedWidgetValues.widgetAutoplayValue}
                            />
                            <p>{changedWidgetValues.widgetAutoplayValue ? 'Enabled' : 'Disabled'}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    createContentSettings = () => {
        const { changedWidgetValues, urlErrorMessage, widgetType } = this.state;
        return (
            <div className="mw-setup-options-body">
                {/* Registration URL */}
                {widgetType === 'review-box' && (
                    <div className="mw-setup-row mw-setup-row-url">
                        <label
                            htmlFor="mw-registration-url"
                            className="mw-setup-row-label"
                        >
                            Registration URL
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                The URL which will be used to direct people to your registration page when 
                                they click &apos;Book Now&apos; on the Review Box.
                                </span>
                            </div>
                        </label>
                        <Input
                            id="mw-registration-url"
                            className="input mw-setup-row-input"
                            name="widgetRegistrationURL"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetRegistrationURL}
                            required
                        />
                        {urlErrorMessage &&
                        <p className="mw-setup-inline-error">
                            {urlErrorMessage}
                        </p>}
                    </div>
                )}

                {/* Review Sorting */}
                {['review-box', 'review-carousel'].includes(widgetType) && (
                    <div className="mw-setup-row">
                        <label
                            htmlFor="mw-review-sorting"
                            className="mw-setup-row-label"
                        >
                            Review sorting
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                    The default order reviews will be shown in your widget.
                                </span>
                            </div>
                        </label>
                        <select
                            id="mw-review-sorting"
                            className="mw-setup-row-select input"
                            name="widgetReviewSortingValue"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetReviewSortingValue}
                            required
                        >
                            {this.createReviewSortingDropdown()}
                        </select>
                    </div>
                )}

                {/* Star Rating Filter */}
                {widgetType === 'review-carousel' && (
                    <div className="mw-setup-row">
                        <label
                            htmlFor="mw-rating-filter"
                            className="mw-setup-row-label"
                        >
                            Star rating filter
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                Reviews with ratings below this threshold will be excluded from 
                                the widget.
                                </span>
                            </div>
                        </label>
                        <select
                            id="mw-rating-filter"
                            className="mw-setup-row-select input"
                            name="widgetStarRatingFilterValue"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetStarRatingFilterValue}
                            required
                        >
                            {this.createStarRatingFilterDropdown()}
                        </select>
                    </div>
                )}

                {/* Event Year Filter */}
                {widgetType === 'review-carousel' && (
                    <div className="mw-setup-row">
                        <label
                            htmlFor="mw-review-year"
                            className="mw-setup-row-label"
                        >
                            Event year filter
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                Set what year&apos;s reviews you want to be displayed in the 
                                widget - we recommend using the most recent!
                                </span>
                            </div>
                        </label>
                        <select
                            id="mw-review-year"
                            className="mw-setup-row-select input"
                            name="widgetEventYearFilterValue"
                            onChange={this.handleChange}
                            value={changedWidgetValues.widgetEventYearFilterValue}
                            required
                        >
                            {this.createEventYearFilterDropdown()}
                        </select>
                    </div>
                )}

                {/* Structured Data */}
                {['review-box', 'review-carousel'].includes(widgetType) && !this.state.widgetMultiraceValue && (
                    <div className="mw-setup-row">
                        <label
                            htmlFor="mw-structured-data"
                            className="mw-setup-row-label"
                        >
                            Structured data
                            <HelpIcon />
                            <div className="mw-setup-tooltip">
                                <span className="mw-title-desc">
                                    Adding Structured Data to your website via the widget will increase your 
                                    eligibility for Rich Snippets - learn more in the widget support documentation.
                                </span>
                            </div>
                        </label>
                        <div className="checkbox-with-label">
                            <Checkbox
                                id="structured-data"
                                label=""
                                isSelected={
                                    changedWidgetValues.widgetStructuredDataValue
                                }
                                onCheckboxChange={
                                    this.handleStructuredDataCheckbox
                                }
                                value={
                                    changedWidgetValues.widgetStructuredDataValue
                                }
                            />
                            <p>{changedWidgetValues.widgetStructuredDataValue ? 'Enabled' : 'Disabled'}</p>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    createReviewSortingDropdown = () =>
        this.state.reviewSortingOptions.map(option => (
            <option key={option.value} value={option.value}>
                {option.value_name}
            </option>
        ));

    createPositionDropdown = () =>
        this.state.positionOptions.map(option => (
            <option key={option.value} value={option.value}>
                {option.value_name}
            </option>
        ));

    createThemeDropdown = () =>
        this.state.themeOptions.map(option => (
            <option key={option.value} value={option.value}>
                {option.value_name}
            </option>
        ));

    createHeaderTextDropdown = () =>
        this.state.headerTextOptions.map(option => (
            <option key={option.value} value={option.value}>
                {option.value_name}
            </option>
        ));

    createMaximumCardSlidesDropdown = () =>
        this.state.maximumCardSlidesOptions.map(option => (
            <option key={option.value} value={option.value}>
                {option.value_name}
            </option>
        ));

    createStarRatingFilterDropdown = () =>
        this.state.starRatingFilterOptions.map(option => (
            <option key={option.value} value={option.value}>
                {option.value_name}
            </option>
        ));

    createEventYearFilterDropdown = () =>
        this.state.eventYearFilterOptions.map(option => (
            <option key={option.value} value={option.value}>
                {option.value_name}
            </option>
        ))

    handleSaveWidgetChanges = () => {
        switch (this.state.widgetType) {
            case 'review-box':
                return this.handleSaveReviewBoxChanges();
            case 'review-carousel':
                return this.handleSaveReviewCarouselChanges();
            case 'free-review-banner':
                return this.handleSaveFreeReviewBannerChanges();
            default:
                return this.handleSaveReviewBoxChanges();
        }
    }

    handleSaveReviewBoxChanges = () => {
        this.setState(
            {
                widgetMounted: false,
                saveLoadingButton: true,
            },
            () => {
                this.setState({ urlErrorMessage: '' });
                const token = this.context.token ? this.context.token : localStorage.getItem('token');

                const { selectedOrganisation } = this.props;
                /* eslint-disable-next-line*/
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/widgets/${this.state.widgetId}/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };
                
                const { widgetRegistrationURL } = this.state.changedWidgetValues;
                if (widgetRegistrationURL && !isValidURL(widgetRegistrationURL)) {
                    return this.setState({ 
                        urlErrorMessage: 'Please enter a valid URL',
                        saveLoadingButton: false,
                        widgetMounted: true
                    });
                }

                let registrationURL = this.state.changedWidgetValues.widgetRegistrationURL;
                if (registrationURL &&
                    (
                        registrationURL.substring(0, 8) !== 'https://' &&
                        registrationURL.substring(0, 7) !== 'http://'
                    )
                ) {
                    registrationURL = `https://${registrationURL}`;
                }
                const newWidgetData = {
                    events: [
                        {
                            id: this.state.eventId,
                            registration_link: registrationURL || null
                        }
                    ],
                    parameters: [
                        {
                            slug: 'autoplay',
                            value: this.state.changedWidgetValues.widgetAutoplayValue,
                        },
                        {
                            slug: 'position',
                            value: this.state.changedWidgetValues.widgetPositionValue,
                        },
                        {
                            slug: 'structuredData',
                            value: this.state.changedWidgetValues.widgetStructuredDataValue,
                        },
                        {
                            slug: 'reviewSorting',
                            value: this.state.changedWidgetValues.widgetReviewSortingValue,
                        }
                    ],
                    is_active: this.state.changedWidgetValues.widgetStatus,
                };
                axios
                    .put(apiCallURL, newWidgetData, { headers })
                    .then(() => {
                        this.setState({
                            originalWidgetValues: this.state.changedWidgetValues,
                            widgetMounted: true,
                            saveLoadingButton: false,
                        });
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.setState({
                            saveLoadingButton: false,
                            widgetMounted: true
                        });
                        if (error?.response?.data?.errors?.events[0]?.registration_link[0]) {
                            this.setState({
                                urlErrorMessage: error?.response?.data?.errors?.events[0]?.registration_link[0]
                            });
                        }
                    });
            }
        );
    };

    handleSaveReviewCarouselChanges = () => {
        this.setState(
            {
                widgetMounted: false,
                saveLoadingButton: true,
            },
            () => {
                this.setState({ urlErrorMessage: '' });
                const token = this.context.token ? this.context.token : localStorage.getItem('token');

                const { selectedOrganisation } = this.props;
                /* eslint-disable-next-line*/
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/review-carousel-widgets/${this.state.widgetId}/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };

                const newWidgetData = {
                    parameters: [
                        {
                            slug: 'autoplay',
                            value: this.state.changedWidgetValues.widgetAutoplayValue,
                        },
                        {
                            slug: 'cardSlides',
                            value: this.state.changedWidgetValues.widgetMaximumCardSlidesValue,
                        },
                        {
                            slug: 'theme',
                            value: this.state.changedWidgetValues.widgetThemeValue,
                        },
                        {
                            slug: 'headerText',
                            value: this.state.changedWidgetValues.widgetHeaderTextValue,
                        },
                        {
                            slug: 'reviewSorting',
                            value: this.state.changedWidgetValues.widgetReviewSortingValue,
                        },
                        {
                            slug: 'structuredData',
                            value: this.state.changedWidgetValues.widgetStructuredDataValue,
                        },
                        {
                            slug: 'starRatingFilter',
                            value: this.state.changedWidgetValues.widgetStarRatingFilterValue,
                        },
                        {
                            slug: 'eventYearFilter',
                            value: this.state.changedWidgetValues.widgetEventYearFilterValue,
                        }
                    ]
                };
                axios
                    .put(apiCallURL, newWidgetData, { headers })
                    .then(() => {
                        this.setState({
                            originalWidgetValues: this.state.changedWidgetValues,
                            widgetMounted: true,
                            saveLoadingButton: false,
                        });
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.setState({
                            saveLoadingButton: false,
                            widgetMounted: true
                        });
                    });
            }
        );
    };

    handleSaveFreeReviewBannerChanges = () => {
        this.setState(
            {
                widgetMounted: false,
                saveLoadingButton: true,
            },
            () => {
                this.setState({ urlErrorMessage: '' });
                const token = this.context.token ? this.context.token : localStorage.getItem('token');

                const { selectedOrganisation } = this.props;
                /* eslint-disable-next-line*/
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/free-widgets/${this.props.event.id}/${this.state.widgetType.replaceAll('-', '_')}/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };

                const newWidgetData = {
                    parameters: [
                        {
                            slug: 'headerText',
                            value: this.state.changedWidgetValues.widgetHeaderTextValue,
                        }
                    ]
                };
                axios
                    .put(apiCallURL, newWidgetData, { headers })
                    .then(() => {
                        this.setState({
                            originalWidgetValues: this.state.changedWidgetValues,
                            widgetMounted: true,
                            saveLoadingButton: false,
                        });
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.setState({
                            saveLoadingButton: false,
                            widgetMounted: true
                        });
                    });
            }
        );
    };

    handleAutoplayCheckbox = () => {
        this.setState(
            {
                widgetMounted: false,
                changedWidgetValues: {
                    ...this.state.changedWidgetValues,
                    widgetAutoplayValue: !this.state.changedWidgetValues.widgetAutoplayValue,
                },
            },
            () => {
                this.setState({
                    widgetMounted: true,
                });
            }
        );
        this.handleSaveWidgetChanges();
    };

    handleStructuredDataCheckbox = (e) => {
        this.setState(
            {
                changedWidgetValues: {
                    ...this.state.changedWidgetValues,
                    widgetStructuredDataValue: !this.state.changedWidgetValues.widgetStructuredDataValue,
                },
            }
        );
        this.handleSaveWidgetChanges();
    };

    handleChange = e => {
        const targetName = e.target.name;
        let targetValue = e.target.value;
        if (targetName === 'widgetStatus') {
            targetValue = targetValue === 'true';
        }
        this.setState(
            {
                changedWidgetValues: {
                    ...this.state.changedWidgetValues,
                    [targetName]: targetValue,
                },
            },
            () => {
                if (!isEqual(this.state.originalWidgetValues, this.state.changedWidgetValues)) {
                    this.setState({
                        widgetMounted: true,
                    });
                }
                else {
                    this.setState({
                        widgetMounted: true,
                    });
                }
                this.handleSaveWidgetChanges();
            }
        );
    };

    createWidgetInstallationInstructions = (mpProps) => {
        const { widgetId, widgetType, widgetCodeCopied, widgetCode2Copied } = this.state;
        let widgetCode = '';
        let widgetCode2 = '';
        switch (widgetType) {
            case 'review-box':
                widgetCode =
`<script>
    (function(w, d, s, o, f, js, fjs) {
    w['RacecheckWidget']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
    js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'rcw', '${process.env.REACT_APP_API_URL}/static/js/widget.js'));
    rcw('init', {widget: ${widgetId}});
</script>`;
                break;
            case 'review-carousel':
                widgetCode =
`<script>
    (function(w, d, s, o, f, js, fjs) {
    w['RacecheckReviewCarousel']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
    js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'rcrc', '${process.env.REACT_APP_API_URL}/static/js/review_carousel.js'));
    rcrc('init', {carousel: ${widgetId}});
</script>`;
                widgetCode2 =
`<div id="racecheck-review-carousel" style="width: 100%; height: 100%;">
    <a href="https://racecheck.com/races/${this.props.event.id}/" target="_blank">
        Read reviews on Racecheck
    </a>
</div>`;
                break;
            case 'free-review-banner':
                widgetCode =
`<script>
    (function(w, d, s, o, f, js, fjs) {
    w['RacecheckFreeReviewBanner']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
    js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', 'rcfrb', '${process.env.REACT_APP_API_URL}/static/js/free_review_banner.js'));
    rcfrb('init', {race: ${widgetId}});
</script>`;
                widgetCode2 =
`<div id="racecheck-free-review-banner" style="width: 100%; height: 100%;">
    <a href="https://racecheck.com/races/${this.props.event.id}/" target="_blank">
        Read reviews on Racecheck
    </a>
</div>`;
                break;
            default:
                break;
        }
        return (
            <>
                <div className="wpc-content-section">
                    <h1>Installing your Racecheck Widget</h1>
                    {/* Review Box */}
                    {widgetType === 'review-box' && (
                        <>
                            <div className="widget-code-step">
                                <p>
                                To add the Racecheck Review Box to your web page, add the code snippet below to your
                                website&apos;s code base. Any changes you make to the settings will automatically be
                                reflected in Review Box on your site.
                                </p>
                            </div>
                            <div className="mw-code-container">
                                <div
                                    className="mw-copy-code"
                                    onClick={() => this.copyWidgetCodeToClipboard(widgetCode)}
                                >
                                    {widgetCodeCopied ? <p>Code copied</p> : <p>Copy to clipboard</p>}
                                    <CopyIcon />
                                </div>
                                <div className="mw-code-content">
                                    <pre className="mw-code-pre">
                                        {widgetCode}
                                    </pre>
                                </div>
                            </div>
                            <p className="mw-code-p">
                                Need further help? Please refer to our{' '}
                                <Link to={'/resources/faq'} className="mw-inline-link">
                                    FAQs section
                                </Link>
                                .
                            </p>
                        </>
                    )}

                    {/* Review Carousel & Free Review Banner */}
                    {['review-carousel', 'free-review-banner'].includes(widgetType) && (
                        <>
                            <div className="widget-code-step">
                                <h2>1. Add this code to your HTML file</h2>
                                <p>
                                    To add Racecheck widgets to your site, add the code below to
                                    the HTML file in your website&apos;s code base.
                                </p>
                                <div className="mw-code-container">
                                    <div
                                        className="mw-copy-code"
                                        onClick={() => this.copyWidgetCodeToClipboard(widgetCode)}
                                    >
                                        {widgetCodeCopied ? <p>Code copied</p> : <p>Copy to clipboard</p>}
                                        <CopyIcon />
                                    </div>
                                    <div className="mw-code-content">
                                        <pre className="mw-code-pre">
                                            {widgetCode}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                            <div className="widget-code-step">
                                <h2>2. Add this code to the HTML where you want your widget to be displayed</h2>
                                <p>
                                The code below is to display this specific Widget.
                                Add it wherever you want your widget to be displayed within your page. Once
                                installed, any changes made to the widget settings through your Organiser
                                Dashboard will be automatically reflected on the widget on your website.
                                </p>
                                <div className="mw-code-container">
                                    <div
                                        className="mw-copy-code"
                                        onClick={() => this.copyWidgetCode2ToClipboard(widgetCode2)}
                                    >
                                        {widgetCode2Copied ? <p>Code copied</p> : <p>Copy to clipboard</p>}
                                        <CopyIcon />
                                    </div>
                                    <div className="mw-code-content">
                                        <pre className="mw-code-pre">
                                            {widgetCode2}
                                        </pre>
                                    </div>
                                </div>
                                <p className="mw-code-p">
                            Need further help? Please refer to our{' '}
                                    <Link to={'/resources/faq'} className="mw-inline-link">
                                FAQs section
                                    </Link>
                            .
                                </p>
                            </div>
                        </>
                    )}
                </div>
                {widgetType === 'review-box' && this.props.selectedOrganisation.is_premium_subscriber &&
                    <div className="wpc-content-section">
                        <h1>Get started with sales tracking</h1>
                        <p>
                            Once your Review Box is installed, you can begin using it to gain a 
                            true understanding of how it is having an impact on your event entries.
                        </p>
                        <div className="mw-container-footer">
                            <button 
                                className="btn primary-btn modal-form-btn"
                                onClick={() => {
                                    this.handleActivePreviewSection('sales');
                                    mixpanel.track('Dashboard - Widgets - Install your tracking click', mpProps);
                                }
                                }
                            >
                                Install your tracking
                            </button>
                        </div>
                    </div>
                }
            </>
        );
    }

    createSalesTrackingInstructions = (mpProps) => {
        const {
            registration_intent_tracker_installed,
            registration_completed_tracker_installed,
            codeCopiedStep1,
            codeCopiedStep2Mixpanel,
            codeCopiedCompleteRegistration
        } = this.state;
        return (
            <>
                <div className="wpc-content-section">
                    <h1 className="mw-code-header">Intent to Book
                        {registration_intent_tracker_installed ? 
                            <span className="widget-tracking-status wt-tracking">
                                    TRACKING
                            </span>
                            :
                            <span className="widget-tracking-status wt-not-tracking">
                                    NOT TRACKING
                            </span>
                        }
                        <HelpIcon />
                        <div className="mw-setup-tooltip">
                            <span className="mw-title-desc">
                                {registration_intent_tracker_installed ? 
                                    // eslint-disable-next-line max-len
                                    'Your tracking code has been installed and succesfully captured data. Ensure it stays installed correctly to record accurately.' : 
                                    // eslint-disable-next-line max-len
                                    'No tracking data has been recorded. If you have just installed the tracking code, the status will update once the first data is captured.'}
                            </span>
                        </div>
                    </h1>
                    {/* eslint-disable-next-line max-len */}
                    <p>To see how many of your website visitors go on to click a ‘Book Now’ button on your website following an interaction with a Review Box, install the below code on all instances of a button or link which directs to your registration process.</p>
                    <p>
                        <span className="mw-code-step">Step 1: </span>
                        {/* eslint-disable-next-line max-len */}
                            Add the following code on each page you want the tracking for this event to be active.
                    </p>
                    <div className="mw-code-container">
                        <div 
                            className="mw-copy-code" 
                            onClick={this.copyStep1ToClipboard}
                        >
                            {codeCopiedStep1 ? 
                                <p>Code copied</p> : <p>Copy to clipboard</p>}
                            <CopyIcon />
                        </div>
                        <div className="mw-code-content">
                            <pre className="mw-code-pre">
                                {/* eslint-disable-next-line max-len */}
                                {`<script id="mixpanel-library" src="https://racecheck.com/static/js/Mixpanel.js" t="${process.env.REACT_APP_MIXPANEL_TOKEN}" u="RCW"></script>`}
                            </pre>
                        </div>
                    </div>
                    <p>
                        <span className="mw-code-step">Step 2: </span>
                        {/* eslint-disable-next-line max-len */}
                            The following code must then be installed on all instances of a button or link that leads to the registration process as an ‘onClick’ (or equivalent) event.
                    </p>
                    <div className="mw-code-container">
                        <div 
                            className="mw-copy-code" 
                            onClick={this.copyStep2MixpanelToClipboard}
                        >
                            {codeCopiedStep2Mixpanel ? 
                                <p>Code copied</p> : <p>Copy to clipboard</p>}
                            <CopyIcon />
                        </div>
                        <div className="mw-code-content">
                            <pre className="mw-code-pre">
                                {/* eslint-disable-next-line max-len */}
                                {`mixpanel.track('Registration Intent - Organiser', {'widget_id': ${this.state.widgetId}});`}
                            </pre>
                        </div>
                    </div>
                    <div className="mw-code-container">
                        <div className="mw-code-content">
                            <pre className="mw-code-pre">
                                {/* eslint-disable */}
                                    {`<!-- Example code set up -->
<script id="mixpanel-library" src="https://racecheck.com/static/js/Mixpanel.js" t="${process.env.REACT_APP_MIXPANEL_TOKEN}" u="RCW"></script>

<a
  href="https://registrationplatform.com/"
  onclick="javascript:mixpanel.track('Registration Intent - Organiser', {'widget_id': ${this.state.widgetId}});"
>
    Book Now
</a>`}
                                    {/* eslint-enable */}
                            </pre>
                        </div>
                    </div>
                    <div className="mw-container-footer">
                        <button 
                            className="btn primary-btn modal-form-btn" 
                            onClick={() => {
                                this.handleForwardToDevTeamModalOpen('registration_intent');
                                mixpanel.track('Dashboard - Widgets - Forward to your dev team click', mpProps);
                            }
                            }
                        >
                                    Forward to your dev team
                        </button>
                    </div>
                </div>
                <div className="wpc-content-section">
                    <h1 className="mw-code-header">Complete Registrations
                        {registration_completed_tracker_installed ? 
                            <span 
                                className="widget-tracking-status wt-tracking"
                            >TRACKING
                            </span>
                            :
                            <span 
                                className="widget-tracking-status wt-not-tracking"
                            >NOT TRACKING
                            </span>
                        }
                        <HelpIcon />
                        <div className="mw-setup-tooltip">
                            <span className="mw-title-desc">
                                {/* eslint-disable-next-line max-len */}
                                {registration_completed_tracker_installed ? 'Your tracking code has been installed and succesfully captured data. Ensure it stays installed correctly to record accurately.' : 
                                    // eslint-disable-next-line max-len
                                    'No tracking data has been recorded. If you have just installed the tracking code, the status will update once the first data is captured.'}
                            </span>
                        </div>
                    </h1>
                    {/* eslint-disable-next-line max-len */}
                    <p>To see how many of your website visitors complete the registration process following an interaction with a Review Box, install the below code on the page on your website which immediately follows a complete registration - i.e. a thank you page.</p>

                    <div className="mw-code-container">
                        <div 
                            className="mw-copy-code" 
                            onClick={this.copyCompleteRegistrationToClipboard}
                        >
                            {codeCopiedCompleteRegistration ?
                                <p>Code copied</p> : <p>Copy to clipboard</p>}
                            <CopyIcon />
                        </div>
                        <div className="mw-code-content">
                            <pre className="mw-code-pre">
                                {/* eslint-disable-next-line max-len */}
                                {`<script id="mixpanel-library" src="https://racecheck.com/static/js/Mixpanel.js" t="${process.env.REACT_APP_MIXPANEL_TOKEN}" u="RCW"></script>
<script>mixpanel.track('Registration Completed - Organiser', {'widget_id': ${this.state.widgetId}});</script>`}
                            </pre>
                        </div>
                    </div>

                    <div className="mw-container-footer">
                        <button 
                            className="btn primary-btn modal-form-btn" 
                            onClick={() => {
                                this.handleForwardToDevTeamModalOpen('registration_completed');
                                mixpanel.track('Dashboard - Widgets - Forward to your dev team click', mpProps);
                            }
                            }
                        >
                                    Forward to your dev team
                        </button>
                    </div>
                </div>
            </>
        );
    }

    handleForwardToDevTeamModalOpen = (type) => this.setState({ forwardToDevTeamModalIsOpen: true, forwardToDevTeamType: type });
    handleForwardToDevTeamModalClose = () => this.setState({ forwardToDevTeamModalIsOpen: false, forwardToDevTeamType: '' });

    copyWidgetCodeToClipboard = (widgetCode) => {
        this.setState(
            {
                widgetCodeCopied: true,
            },
            () => {
                navigator.clipboard.writeText(widgetCode);
                setTimeout(() => {
                    this.setState({
                        widgetCodeCopied: false,
                    });
                }, 2000);
            }
        );
    };

    copyWidgetCode2ToClipboard = (widgetCode) => {
        this.setState(
            {
                widgetCode2Copied: true,
            },
            () => {
                navigator.clipboard.writeText(widgetCode);
                setTimeout(() => {
                    this.setState({
                        widgetCode2Copied: false,
                    });
                }, 2000);
            }
        );
    };

    copyStep1ToClipboard = () => {
        this.setState(
            {
                codeCopiedStep1: true,
            },
            () => {
                // eslint-disable-next-line max-len
                const codeToCopy = `<script id="mixpanel-library" src="https://racecheck.com/static/js/Mixpanel.js" t="${process.env.REACT_APP_MIXPANEL_TOKEN}" u="RCW"></script>`;
                navigator.clipboard.writeText(codeToCopy);
                setTimeout(() => {
                    this.setState({
                        codeCopiedStep1: false,
                    });
                }, 2000);
            }
        );
    }

    copyStep2MixpanelToClipboard = () => {
        this.setState(
            {
                codeCopiedStep2Mixpanel: true,
            },
            () => {
                const codeToCopy = `mixpanel.track('Registration Intent - Organiser', {'widget_id': ${this.state.widgetId}});`;
                navigator.clipboard.writeText(codeToCopy);
                setTimeout(() => {
                    this.setState({
                        codeCopiedStep2Mixpanel: false,
                    });
                }, 2000);
            }
        );
    }

    copyCompleteRegistrationToClipboard = () => {
        this.setState(
            {
                codeCopiedCompleteRegistration: true,
            },
            () => {
                // eslint-disable-next-line max-len
                const codeToCopy = `<script id="mixpanel-library" src="https://racecheck.com/static/js/Mixpanel.js" t="${process.env.REACT_APP_MIXPANEL_TOKEN}" u="RCW"></script>
                <script>mixpanel.track('Registration Completed - Organiser', {'widget_id': ${this.state.widgetId}});</script>`;
                navigator.clipboard.writeText(codeToCopy);
                setTimeout(() => {
                    this.setState({
                        codeCopiedCompleteRegistration: false,
                    });
                }, 2000);
            }
        );
    }

    handlePreviewBackground = (id) => {
        const activePreviewBackground = (id === 2) ? 'dark' : 'light';
        localStorage.setItem('activePreviewBackground', activePreviewBackground);
        this.setState({
            activePreviewBackground
        });

    }

    handleActivePreviewSection = (section) => {
        switch (section) {
            case 'preview':
                this.setState({
                    activePreviewSection: 'preview'
                });
                break;
            case 'installation':
                this.setState({
                    activePreviewSection: 'installation'
                });
                break;
            case 'sales':
                this.setState({
                    activePreviewSection: 'sales'
                });
                break;
            default:
                break;
        }
    }

    createWidgetPreview = () => {
        const { changedWidgetValues, widgetOrganisationSlug, widgetEvents, widgetType, widgetId } = this.state;
        if (!widgetId) return;
        return (
            <>
                {widgetType === 'review-box' &&
                    <RacecheckWidget
                        parameters={changedWidgetValues}
                        widgetOrganisationSlug={widgetOrganisationSlug}
                        widgetEvents={widgetEvents}
                    />
                }
                {widgetType === 'review-carousel' &&
                    <ReviewCarouselWidget
                        widgetId={widgetId}
                    />
                }
                {widgetType === 'free-review-banner' &&
                    <FreeReviewBannerWidget
                        widgetId={widgetId}
                    />
                }
            </>
        );
    }

    getWidgetTypeTitle = () => {
        if (!this.state.widgetId) return;
        switch (this.state.widgetType) {
            case 'review-box':
                return 'Review Box';
            case 'review-carousel':
                return 'Review Carousel';
            case 'free-review-banner':
                return 'Free Review Banner';
            default:
                return 'Review Box';
        }
    }
    
    render () {
        const {
            widgetId,
            dataLoaded,
            widgetMounted,
            expandedBlock,
            forwardToDevTeamType,
            activePreviewBackground,
            activePreviewSection,
            widgetType
        } = this.state;

        const isPremium = this.props.selectedOrganisation.is_premium_subscriber;
        let multiraceEventList = '';
        if (this.state.widgetMultiraceValue) {
            for (let i = 0; i < this.state.widgetEvents.length; i++) {
                if (i === this.state.widgetEvents.length - 1) {
                    multiraceEventList += this.state.widgetEvents[i].title;
                }
                else {
                    multiraceEventList = `${multiraceEventList + this.state.widgetEvents[i].title}, `;
                }
            }
        }

        const mpProps = this.context.selectedOrganisation ? {
            organisation: this.context.selectedOrganisation?.name,
            premium_subscriber: this.context.selectedOrganisation?.is_premium_subscriber
        } : {};

        return (
            <div>
                <div className="manage-widget-header">
                    <Link to={'/events/' + this.props.event.id + '/widgets'}>←</Link>
                    {isPremium ?
                        <h1>{this.getWidgetTypeTitle()}</h1>
                        :
                        <h1>Back to Widget Library</h1>
                    }
                </div>
                {isPremium || (!isPremium && ['review-box', 'free-review-banner'].includes(widgetType)) ?
                    <>
                        {dataLoaded ? (
                            <div className="manage-widget-parent-container">
                                <div className="widgets-container">
                                    <ForwardToDevTeamModal 
                                        isOpen={this.state.forwardToDevTeamModalIsOpen}  
                                        onClose={this.handleForwardToDevTeamModalClose} 
                                        widgetId={widgetId}
                                        type={forwardToDevTeamType}
                                        widgetType={widgetType}
                                    />
                                    <div className="manage-widget-container">
                                        <div className="manage-widget-content">
                                            <div>
                                                {dataLoaded ? (
                                                    <div className="mw-setup">
                                                        <div className="mw-setup-options-container">
                                                            <div
                                                                className="mw-setup-options-header"
                                                                onClick={() =>
                                                                    this.setState(prevState => ({
                                                                        expandedBlock: prevState?.expandedBlock === 0 ? null : 0
                                                                    }))}
                                                            >
                                                                <div className="mw-setup-options-header-left">
                                                                    <img
                                                                        src={CustomReviewBoxImage}
                                                                        className="section-icon"
                                                                        alt="review box"
                                                                    />
                                                                    <div className="mw-setup-options-header-copy">
                                                                        <h3>Display settings</h3>
                                                                        <p>Customise widget appearance</p>
                                                                    </div>
                                                                </div>
                                                                <ChevronDown
                                                                    className={classnames({
                                                                        sectionArrowActive: expandedBlock === 0
                                                                    })}
                                                                />
                                                            </div>
                                                            {expandedBlock === 0 && this.createDisplaySettings()}
                                                        </div>
                                                        {isPremium && ['review-box', 'review-carousel'].includes(widgetType) &&
                                                    <div className="mw-setup-options-container">
                                                        <div
                                                            className="mw-setup-options-header"
                                                            onClick={() => this.props.selectedOrganisation.is_premium_subscriber ?
                                                                this.setState(prevState => ({
                                                                    expandedBlock: prevState?.expandedBlock === 1 ? null : 1
                                                                }))
                                                                : () => {}}
                                                        >
                                                            <div className="mw-setup-options-header-left">
                                                                <img
                                                                    src={ManageContent}
                                                                    className="section-icon"
                                                                    alt="review box"
                                                                />
                                                                <div className="mw-setup-options-header-copy">
                                                                    <h3>Content settings</h3>
                                                                    <p>What/how reviews are shown</p>
                                                                </div>
                                                            </div>
                                                            <ChevronDown
                                                                className={classnames({
                                                                    sectionArrowActive: expandedBlock === 1
                                                                })}
                                                            />
                                                        </div>
                                                        {expandedBlock === 1 && this.createContentSettings()}
                                                    </div>
                                                        }
                                                        <div className="mw-setup-options-container mw-soc-mobile">
                                                            <div
                                                                className="mw-setup-options-header"
                                                                onClick={() =>
                                                                    this.setState(prevState => ({
                                                                        expandedBlock: prevState?.expandedBlock === 3 ? null : 3
                                                                    }))}
                                                            >
                                                                <div className="mw-setup-options-header-left">
                                                                    <img
                                                                        src={Installation}
                                                                        className="section-icon"
                                                                        alt="review box"
                                                                    />
                                                                    <div className="mw-setup-options-header-copy">
                                                                        <h3>Installation instructions</h3>
                                                                        <p>How to install your widget</p>
                                                                    </div>
                                                                </div>
                                                                <ChevronDown
                                                                    className={classnames({
                                                                        sectionArrowActive: expandedBlock === 3
                                                                    })}
                                                                />
                                                            </div>
                                                            {expandedBlock === 3 && 
                                                        <>
                                                            {this.createWidgetInstallationInstructions(mpProps)}
                                                            <div className="wpc-content-section">
                                                                <h1>Share installation instructions</h1>
                                                                <p>
                                                                    If someone else from your organisation deals with your 
                                                                    website, or you work with an external tech partner, click 
                                                                    the button below to share your widget code and 
                                                                    installation instructions with them.
                                                                </p>
                                                                <div className="mw-container-footer">
                                                                    <button
                                                                        className="btn primary-btn modal-form-btn"
                                                                        onClick={() => {
                                                                            // eslint-disable-next-line max-len
                                                                            this.handleForwardToDevTeamModalOpen('widget_code');
                                                                            /* eslint-disable-next-line */
                                                                            mixpanel.track('Dashboard - Widgets - Forward to your dev team click', mpProps);
                                                                        }
                                                                        }
                                                                    >
                                                                        Forward to your dev team
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                            }
                                                        </div>
                                                        {isPremium && widgetType === 'review-box' &&
                                                    <>
                                                        <div className="mw-setup-options-container mw-soc-static">
                                                            <div
                                                                className="mw-setup-options-header"
                                                                onClick={() => {this.handleActivePreviewSection('sales')}}
                                                            >
                                                                <div className="mw-setup-options-header-left">
                                                                    <img
                                                                        src={StatsChart}
                                                                        className="section-icon"
                                                                        alt="review box"
                                                                    />
                                                                    <div className="mw-setup-options-header-copy">
                                                                        <h3>Sales tracking</h3>
                                                                        <p>Widget performance insights</p>
                                                                    </div>
                                                                </div>
                                                                <ChevronDown
                                                                    className={classnames({
                                                                        sectionArrowActive: expandedBlock === 2
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* MOBILE ALTERNATE FOR SALES TRACKING */}
                                                        <div className="mw-setup-options-container mw-soc-mobile">
                                                            <div
                                                                className="mw-setup-options-header"
                                                                onClick={() =>
                                                                    this.setState(prevState => ({
                                                                        expandedBlock: prevState?.expandedBlock === 2 ? null : 2
                                                                    }))}
                                                            >
                                                                <div className="mw-setup-options-header-left">
                                                                    <img
                                                                        src={StatsChart}
                                                                        className="section-icon"
                                                                        alt="review box"
                                                                    />
                                                                    <div className="mw-setup-options-header-copy">
                                                                        <h3>Sales tracking</h3>
                                                                        <p>Widget performance insights</p>
                                                                    </div>
                                                                </div>
                                                                <ChevronDown
                                                                    className={classnames({
                                                                        sectionArrowActive: expandedBlock === 2
                                                                    })}
                                                                />
                                                            </div>
                                                            {expandedBlock === 2 && this.createSalesTrackingInstructions()}
                                                        </div>
                                                    </>
                                                        }
                                                        <div className="mobile-preview-warning">
                                                            <p>
                                                                To view a live preview of your widget visit 
                                                                this page on a desktop computer.
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="section-loading-container widget-settings-loader">
                                                        <Loader />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* WIDGET PREVIEW */}
                                <div
                                    className={`widget-preview-container 
                            ${this.props.selectedOrganisation.is_trial_eligible ? 'widget-preview-container-free' : ''}
                            ${activePreviewSection === 'preview' ? 'wpc-background-' + activePreviewBackground : ''}
                            `}
                                >
                                    {activePreviewSection === 'installation' &&
                                <div className="wpc-header">
                                    <div className="wpc-header-left">
                                        <CodeIcon />
                                        <h3>Widget code and installation</h3>
                                    </div>
                                    <button
                                        className="btn secondary-btn secondary-btn-rounded modal-form-btn"
                                        onClick={() => {this.handleActivePreviewSection('preview')}}
                                    >
                                        Return to preview
                                    </button>
                                </div>
                                    }
                                    {activePreviewSection === 'sales' &&
                                <div className="wpc-header">
                                    <div className="wpc-header-left">
                                        <CodeIcon />
                                        <h3>Sales tracking installation</h3>
                                    </div>
                                    <button
                                        className="btn secondary-btn secondary-btn-rounded modal-form-btn"
                                        onClick={() => {this.handleActivePreviewSection('preview')}}
                                    >
                                        Return to preview
                                    </button>
                                </div>
                                    }
                                    {activePreviewSection === 'preview' &&
                                <div className="wpc-header">
                                    <div className="wpc-header-left">
                                        <PreviewIcon />
                                        <h3>Widget preview</h3>
                                        <div className="wpc-bg-btns">
                                            <div
                                                className={`wpc-bg-btn wpc-bg-btn-light
                                                 ${activePreviewBackground === 'light' ? 'wpc-bg-btn-selected' : ''}`}
                                                onClick={() => {
                                                    this.handlePreviewBackground(1);
                                                }}
                                            >
                                                {activePreviewBackground === 'light' &&
                                                    <TickIcon />
                                                }
                                            </div>
                                            <div
                                                className={`wpc-bg-btn wpc-bg-btn-dark
                                                 ${activePreviewBackground === 'dark' ? 'wpc-bg-btn-selected' : ''}`}
                                                onClick={() => {
                                                    this.handlePreviewBackground(2);
                                                }}
                                            >
                                                {activePreviewBackground === 'dark' &&
                                                    <TickIcon />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        className="btn primary-btn modal-form-btn"
                                        onClick={() => {this.handleActivePreviewSection('installation')}}
                                    >
                                        View installation code
                                    </button>
                                </div>
                                    }
                                    {activePreviewSection === 'installation' &&
                                <div className="wpc-content wpc-content-code">
                                    {this.createWidgetInstallationInstructions(mpProps)}
                                    <div className="wpc-content-section">
                                        <h1>Share installation instructions</h1>
                                        <p>
                                            If someone else from your organisation deals with your website, or
                                            you work with an external tech partner, click the button below to share your
                                            widget code and installation instructions with them.
                                        </p>
                                        <div className="mw-container-footer">
                                            <button
                                                className="btn primary-btn modal-form-btn"
                                                onClick={() => {
                                                    // eslint-disable-next-line max-len
                                                    this.handleForwardToDevTeamModalOpen('widget_code');
                                                    /* eslint-disable-next-line */
                                                    mixpanel.track('Dashboard - Widgets - Forward to your dev team click', mpProps);
                                                }
                                                }
                                            >
                                                Forward to your dev team
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                    }
                                    {activePreviewSection === 'sales' &&
                                <div className="wpc-content wpc-content-code">
                                    {this.createSalesTrackingInstructions(mpProps)}
                                </div>
                                    }
                                    {activePreviewSection === 'preview' &&
                                <div className="wpc-content">
                                    <div className={`wpc-wireframe ${'wpc-wireframe-' + activePreviewBackground}`}>
                                        <div className=
                                            {`wpc-w-page
                                            ${widgetType === 'review-carousel' ? 'wpc-w-page-carousel' : ''}
                                            ${widgetType === 'review-box' ? 'wpc-w-page-reviewbox' : ''}
                                            ${widgetType === 'free-review-banner' ? 'wpc-w-page-freebanner' : ''}`}
                                        >
                                            <div className="wpc-w-top">
                                                <p className="wpc-w-text">Your Website Here</p>
                                                <div className="wpc-w-line wpc-w-l1">
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    <div className="wpc-w-textblock wpc-w-tb-2" />
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                </div>
                                                <div className="wpc-w-line wpc-w-l2">
                                                    <div className="wpc-w-textblock wpc-w-tb-3" />
                                                    <div className="wpc-w-textblock wpc-w-tb-2" />
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                </div>
                                                <div className="wpc-w-line wpc-w-l3">
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                </div>
                                                {widgetType === 'free-review-banner' &&
                                                <>
                                                    <div className="wpc-w-line wpc-w-l4">
                                                        <div className="wpc-w-textblock wpc-w-tb-2" />
                                                        <div className="wpc-w-textblock wpc-w-tb-2" />
                                                        <div className="wpc-w-textblock wpc-w-tb-1" />
                                                        <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    </div>
                                                    <div className="wpc-w-line wpc-w-l5">
                                                        <div className="wpc-w-textblock wpc-w-tb-3" />
                                                        <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    </div>
                                                </>
                                                }
                                            </div>
                                            <div className="wpc-w-widgetspacer" />
                                            <div className="wpc-w-bottom">
                                                {widgetType === 'free-review-banner' &&
                                                <>
                                                    <div className="wpc-w-line wpc-w-l5">
                                                        <div className="wpc-w-textblock wpc-w-tb-2" />
                                                        <div className="wpc-w-textblock wpc-w-tb-2" />
                                                        <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    </div>
                                                    <div className="wpc-w-line wpc-w-l4">
                                                        <div className="wpc-w-textblock wpc-w-tb-3" />
                                                        <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    </div>
                                                </>
                                                }
                                                <div className="wpc-w-line wpc-w-l3">
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    <div className="wpc-w-textblock wpc-w-tb-2" />
                                                </div>
                                                <div className="wpc-w-line wpc-w-l2">
                                                    <div className="wpc-w-textblock wpc-w-tb-3" />
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    <div className="wpc-w-textblock wpc-w-tb-2" />
                                                </div>
                                                <div className="wpc-w-line wpc-w-l1">
                                                    <div className="wpc-w-textblock wpc-w-tb-1" />
                                                    <div className="wpc-w-textblock wpc-w-tb-2" />
                                                    <div className="wpc-w-textblock wpc-w-tb-3" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {widgetMounted ? (
                                        this.createWidgetPreview()
                                    ) : (
                                        <div
                                            className={
                                                `section-loading-container widget-settings-loader wsl-${activePreviewBackground}`
                                            }
                                        >
                                            <Loader />
                                        </div>
                                    )}
                                </div>
                                    }
                                </div>
                            </div>
                        ) : (
                            <div className="section-loading-container">
                                <Loader />
                            </div>
                        )}
                    </>
                    :
                    <div className="mw-free-placeholder">
                        <div className="analytics-placeholder-container">
                            <img src={OrgDashIllustration} alt="Illustration of Racecheck dashboard" />
                            <h2>Upgrade to Racecheck Premium to access</h2>
                            <p>
                                The complete suite of Racecheck Widgets is only available to Racecheck Premium customers. 
                                Upgrade today to take your event feedback to the next level!
                            </p>
                            <Link to="/subscription/subscription-plan" className="btn primary-btn"
                                onClick={() => {
                                    /* eslint-disable-next-line */
                                    mixpanel.track('Dashboard - Analytics - Upgrade to Premium click', mpProps);
                                }}
                            >
                                Get started for free
                            </Link>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

ManageWidget.propTypes = {
    history: PropTypes.shape({
        match: PropTypes.shape({
            params: PropTypes.shape({
                id: PropTypes.number.isRequired,
            }).isRequired,
        }),
        push: PropTypes.func.isRequired,
    }).isRequired,
    selectedOrganisation: PropTypes.shape({
        id: PropTypes.number.isRequired,
        is_premium_subscriber: PropTypes.bool.isRequired,
        is_trial_eligible: PropTypes.bool.isRequired,
    }).isRequired,
    event: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        main_image: PropTypes.string,
        formatted_latest_date: PropTypes.string,
        address: PropTypes.string,
        distances: PropTypes.shape({
            icon: PropTypes.string,
            value: PropTypes.string,
        }),
        average_rating: PropTypes.string,
        widget: PropTypes.shape({
            status: PropTypes.string,
            id: PropTypes.number
        }),
    }).isRequired
};

ManageWidget.defaultProps = {
    history: {
        match: {
            params: {
                id: 0,
            },
        },
        push: () => {},
    },
};

export default ManageWidget;
