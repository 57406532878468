import React from 'react';
import noDataImage from '../../images/no_content.svg';

export default function NoDataPlaceholder () {
    return (
        <div className="no-data-container">
            <img src={noDataImage} className="no-data-img" alt="empty-clipboard" />
            <p className="no-data-header">Nothing to see here</p>
            <p className="no-data-copy">Try changing some search filters.</p>
        </div>
    );
}
