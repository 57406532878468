import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import AppContext from '../app/AppContext';

class ReviewImagesModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            activePhotoURL: this.props.activeReviewPhoto,
        };
    }

    componentDidMount () {}

    setActivePhoto (photo) {
        this.setState({
            activePhotoURL: photo.photo,
        });
    }

    render () {
        const { activePhotoURL } = this.state;

        let reviewPhotos = [];
        reviewPhotos = this.props.photos.map(photo => (
            <div
                className={`review-photo-container rpc-modal ${
                    activePhotoURL === photo.photo && 'review-photo-active'
                }`}
                key={photo.id}
                onClick={() => this.setActivePhoto(photo)}
            >
                <img src={photo.photo} alt={`Review img ${photo.id}`} />
            </div>
        ));

        return (
            <div className="modal-backdrop">
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">Review photos</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="review-photo-main">
                                    <img className="review-photo-focus" src={activePhotoURL} alt="Review img" />
                                </div>
                                <div className="review-photo-gallery">{reviewPhotos}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

ReviewImagesModal.propTypes = {
    activeReviewPhoto: PropTypes.string.isRequired,
    photos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            photo: PropTypes.string.isRequired,
        })
    ).isRequired,
    toggleModal: PropTypes.func.isRequired,
};

ReviewImagesModal.defaultProps = {
    activeReviewPhoto: '',
    photos: [
        {
            id: 0,
            photo: '',
        }
    ],
    toggleModal: () => {},
};

export default ReviewImagesModal;
