import React from 'react';
import axios from 'axios';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import AppContext from '../app/AppContext';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as SizeIcon } from '../../images/icons/body.svg';
import SmallLoader from '../../images/small_loader.gif';
import ReactPixel from 'react-facebook-pixel';

export default class SelectSizeTierModal extends React.PureComponent {
    static contextType = AppContext;

    constructor (props) {
        super(props);
        this.state = {
            organisationSizes: [],
            sizeTier: '',
            currentSizeTier: '',
            loadingButton: false
        };
    }

    componentDidMount () {
        ReactPixel.trackCustom('Subscription Creation Started', { organisation: this.context.selectedOrganisation.name });
        const token = this.context.token
            ? this.context.token
            : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
    
        let initialOrganisationSizes = [];
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react`;

        // Fetch Organisation Sizes
        axios
            .get(`${apiCallURL}/lookup/size_tiers/`, { headers })
            .then(res => res.data)
            .then(data => {
                
                initialOrganisationSizes = data?.data;

                this.setState({
                    organisationSizes: initialOrganisationSizes,
                });
            });

        // Fetch current organization
        axios
            .get(`${apiCallURL}/organisations/${this.context.selectedOrganisation.id}/`, { headers })
            .then(res => res.data)
            .then(({ data }) => {
                this.setState({
                    currentSizeTier: data?.size_tier,
                    sizeTier: data?.size_tier
                });
            });
    }

    renderTierIcon = index => {
        const iconList = [];
        for (let i = 0; i <= index; i++) {
            iconList.push(<SizeIcon key={i} />);
        }
        return (
            <div className="built-icon">
                {iconList}
            </div>
        );
    }

    handleContinue = async () => {
        this.setState({ loadingButton: true });
        if (this.state.currentSizeTier !== this.state.sizeTier) {
            const token = this.context.token ? this.context.token : localStorage.getItem('token');
            // eslint-disable-next-line max-len
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/`;
            const headers = {
                Authorization: `Token ${token}`,
            };
    
            await axios
                .patch(
                    apiCallURL,
                    { size_tier: this.state.sizeTier },
                    { headers }
                )
                .then(() => {
                    this.setState({
                        currentSizeTier: this.state.sizeTier,
                    });
    
                })
                .catch(error => {
                    console.log(error);
                });
        }
        
        await this.props.handleContinue(this.state.sizeTier);
        this.setState({ loadingButton: false });
    };

    render () {
        const { currentSizeTier, sizeTier, organisationSizes, loadingButton } = this.state;
        return (
            <div className="modal-backdrop">
                
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button
                            className="modal-close-btn"
                            onClick={this.props.onClose}
                        >
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">Confirm your tier</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="customer-details-modal-section">

                                    <h3 className="modal-section-header">Choose your organisation’s size tier</h3>
                                    <p className="modal-section-subheader">
                                        Your organisation size is based on the number 
                                        of <strong>annual participants</strong> across all of your events.
                                    </p>

                                    <div className="size-tier-list">
                                        {organisationSizes.map((item, index) => (
                                            <div
                                                key={item.id}
                                                onClick={() => this.setState({ sizeTier: item.id })}
                                                className={classnames('stl-item', { selected: item.id === sizeTier })}
                                            >
                                                <p className="stl-item-title">{item.title}</p>
                                                {this.renderTierIcon(index)}
                                                <div>
                                                    <p className="stl-item-range">{item.range}</p>
                                                    <p className="stl-item-copy">annual participants</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-btn-row">
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                        >
                                            <img
                                                className="subscription-btn-loader"
                                                src={SmallLoader}
                                                alt="userprofile"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            role="button"
                                            className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                currentSizeTier || sizeTier ? '' : 'disabled-primary-btn'
                                            }`}
                                            onClick={this.handleContinue}
                                        >
                                            Confirm tier
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

SelectSizeTierModal.propTypes = {
    handleContinue: PropTypes.func,
    onClose: PropTypes.func
};