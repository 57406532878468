import React, { Component } from 'react';
import AppContext from '../app/AppContext';
import { reportError } from './reportError';

export default class ErrorBoundary extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);
        this.state = {
            hasError: false,
            errorInfo: '',
            error: '',
        };
    }

    static getDerivedStateFromError (error) {
        return { hasError: true, error };
    }

    componentDidCatch (error, errorInfo) {
        this.setState({ error, errorInfo });
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const errorDetails = {
            user: `${this.context.user.email} - ${this.context.user.id}`,
            organisation: `${this.context.selectedOrganisation.name} - ${this.context.selectedOrganisation.id}`,
            url: window.location.href,
        };
        process.env.NODE_ENV === 'production' && 
        reportError(token, error.message, errorInfo.componentStack.toString(), errorDetails);
    }

    render () {
        const { hasError, errorInfo, error } = this.state;
        if (hasError) {
            return (
                <div className="eb-error-container">
                    <p className="eb-title">Something went wrong.</p>
                    <p className="eb-copy">
                        <span
                            className="eb-link"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Reload this page
                        </span>{' '}
                        to try again. Our team has been notified.
                    </p>
                    <details className="eb-details">
                        <summary>Details</summary>
                        {errorInfo && (
                            <p>
                                {error.message} {errorInfo.componentStack.toString()}
                            </p>
                        )}
                    </details>
                </div>
            );
        }

        return this.props.children;
    }
}
