import React, { Component } from 'react';
import './ResourceSection.css';
import saveAs from 'file-saver';

import assetsCardImg from '../../images/assets_preview.png';
import awardsCardImg from '../../images/awards_preview.png';

import websiteImg from '../../images/toolkits/awards/website.png';
import socialMedia1Img from '../../images/toolkits/awards/social-media-1.png';
import socialMedia2Img from '../../images/toolkits/awards/social-media-2.png';
import emailFooterImg from '../../images/toolkits/awards/footer.png';
import advertisingImg from '../../images/toolkits/awards/badge-usage-ad.png';
import databaseImg from '../../images/toolkits/awards/database.png';
import eventListingImg from '../../images/toolkits/awards/listings.png';
import raceDayMainImg from '../../images/toolkits/awards/raceday-1.png';
import raceDaySmall1Img from '../../images/toolkits/awards/raceday-2.png';
import raceDaySmall2Img from '../../images/toolkits/awards/raceday-3.png';

import rEmailImg from '../../images/toolkits/reviews/email.png';
import rEventBrandingImg from '../../images/toolkits/reviews/event-branding.png';
import rFacebookPostImg from '../../images/toolkits/reviews/facebook-post.png';
import rFlyerImg from '../../images/toolkits/reviews/flyer.png';
import rIncentiveImg from '../../images/toolkits/reviews/incentive.png';
import rRaceBibImg from '../../images/toolkits/reviews/race-bib.png';
import rSocialImg from '../../images/toolkits/reviews/social-media.png';
import rSponsoredPrizesImg from '../../images/toolkits/reviews/sponsored-prizes.png';
import brandAssets from '../../images/Racecheck-Brand-Assets-2021.zip';
import AwardsModal from './AwardsModal';

class Toolkits extends Component {
    constructor (props) {
        super(props);

        this.state = {
            activeToolkitSection: 'awards',
            awardsModalOpen: false,
        };
    }

    handleToolkitSectionChange = e => {
        this.setState({
            activeToolkitSection: e.target.title,
        });
    };

    toggleAwardsModal = () => {
        const { awardsModalOpen } = this.state;
        awardsModalOpen
            ? this.setState({
                awardsModalOpen: false
            })
            : this.setState({
                awardsModalOpen: true
            });
    };
    
    handleBrandAssetDownload = () => {
        saveAs(brandAssets, 'Racecheck-Brand-Assets-2021');
    };

    render () {
        const { activeToolkitSection, awardsModalOpen } = this.state;
        return (
            <div>
                <div className="toolkits-container">
                    <div className="toolkits-top-row">
                        <div className="toolkits-awards-card">
                            <img
                                src={awardsCardImg}
                                alt="Crowd at the start of a race with Racecheck Award badges overlaid"
                            />
                            <div className="toolkit-header-btn">
                                <div
                                    role="button"
                                    className="btn primary-btn modal-form-btn"
                                    onClick={this.toggleAwardsModal}
                                >
                                Download Racecheck award assets
                                </div>
                            </div>
                        </div>
                        <div className="toolkits-assets-card">
                            <img
                                src={assetsCardImg}
                                alt="Racecheck Visorclub waving a Racecheck banner with the Racecheck logo overlaid"
                            />
                            <div className="toolkit-header-btn">
                                <div
                                    role="button"
                                    className="btn primary-btn modal-form-btn"
                                    onClick={this.handleBrandAssetDownload}
                                >
                                Download Racecheck brand assets
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="toolkits-documents-container">
                        <div className="toolkits-header">
                            <nav className="toolkits-header-nav">
                                <ul className="toolkits-nav-list">
                                    {/* <li className="toolkits-nav-item">
                                        <div
                                            className="toolkit-nav-item-link"
                                            title="reviews"
                                            onClick={this.handleToolkitSectionChange}
                                        >
                                            <span
                                                className={`toolkit-link ${
                                                    activeToolkitSection === 'reviews' ? 'toolkit-link-selected' : ''
                                                }`}
                                                title="reviews"
                                            >
                                            Reviews
                                            </span>
                                        </div>
                                    </li> */}
                                    <li className="toolkits-nav-item">
                                        <div
                                            className="toolkit-nav-item-link"
                                            title="awards"
                                            onClick={this.handleToolkitSectionChange}
                                        >
                                            <span
                                                className={`toolkit-link ${
                                                    activeToolkitSection === 'awards' ? 'toolkit-link-selected' : ''
                                                }`}
                                                title="awards"
                                            >
                                            Awards
                                            </span>
                                        </div>
                                    </li>
                                    {/* <li className="toolkits-nav-item">
                                        <div
                                            className="toolkit-nav-item-link"
                                            title="widgets"
                                            onClick={this.handleToolkitSectionChange}
                                        >
                                            <span
                                                className={`toolkit-link ${
                                                    activeToolkitSection === 'widgets' ? 'toolkit-link-selected' : ''
                                                }`}
                                                title="widgets"
                                            >
                                            Widgets
                                            </span>
                                        </div>
                                    </li>
                                    <li className="toolkits-nav-item">
                                        <div
                                            className="toolkit-nav-item-link"
                                            title="brand"
                                            onClick={this.handleToolkitSectionChange}
                                        >
                                            <span
                                                className={`toolkit-link ${
                                                    activeToolkitSection === 'brand' ? 'toolkit-link-selected' : ''
                                                }`}
                                                title="brand"
                                            >
                                            Brand guidelines
                                            </span>
                                        </div>
                                    </li> */}
                                </ul>
                            </nav>
                        </div>
                        {activeToolkitSection === 'reviews' && (
                            <div className="toolkit-content">
                                <h2 className="toolkit-section-header">Racecheck Review Toolkit</h2>

                                <h3 className="toolkit-section-subheader">Introduction</h3>
                                <p className="toolkit-p-first">
                                So, you have decided to embrace the power of impartial reviews and ask your participants
                                to review your event on Racecheck; we are here to help you maximise the feedback and
                                strength of peer recommendation.
                                </p>
                                <p>
                                The more reviews your race has, the more likely it is to stand out versus other races,
                                get noticed by a wider participant community and lead to more event sign ups.
                                </p>
                                <p>
                                More reviews also can result in a chance to earn one of the new Racecheck Award badges,
                                giving events wider credibility and awareness. And you also get partner perks too
                                including discount for your event liability insurance (more to come).
                                </p>
                                <p>
                                How do you get more reviews? Here are some tips we put together from our wealth of
                                experience working with events and hosting over 20,000 reviews to date.
                                </p>

                                <h3 className="toolkit-section-subheader">At the event</h3>
                                <p className="toolkit-p-first">Racecheck logo and call to action for reviews on:</p>
                                <div className="toolkit-triple-imgs">
                                    <div className="toolkit-img-caption-box">
                                        <img
                                            src={rRaceBibImg}
                                            className="toolkit-img"
                                            alt="race bib with racecheck review prompt"
                                        />
                                        <p className="toolkit-img-caption">Race bibs</p>
                                    </div>
                                    <div className="toolkit-img-caption-box">
                                        <img
                                            src={rEventBrandingImg}
                                            className="toolkit-img"
                                            alt="racecheck review prompt on event branding"
                                        />
                                        <p className="toolkit-img-caption">Event branding</p>
                                    </div>
                                    <div className="toolkit-img-caption-box">
                                        <img
                                            src={rSponsoredPrizesImg}
                                            className="toolkit-img"
                                            alt="email containg racecheck review prompt"
                                        />
                                        <p className="toolkit-img-caption">Sponsored prizes</p>
                                    </div>
                                </div>
                                <div className="toolkit-triple-imgs">
                                    <div className="toolkit-img-caption-box">
                                        <img
                                            src={rFlyerImg}
                                            className="toolkit-img"
                                            alt="flyer containing racecheck review prompt"
                                        />
                                        <p className="toolkit-img-caption">Flyers</p>
                                    </div>
                                    <div className="toolkit-img-caption-box">
                                        <img
                                            src={rSocialImg}
                                            className="toolkit-img"
                                            alt="facebook post with racecheck review prompt"
                                        />
                                        <p className="toolkit-img-caption">Social media</p>
                                    </div>
                                    <div className="toolkit-img-caption-box" />
                                </div>

                                <h3 className="toolkit-section-subheader">After the event</h3>
                                <h4 className="toolkit-subsection-header">Email</h4>
                                <p className="toolkit-p-first">
                                    Send an email JUST asking for reviews on the evening of the race. You can programme this
                                    email ahead of time so not delayed by the manic nature of event day.
                                </p>
                                <img src={rEmailImg} className="toolkit-img" alt="email containg racecheck review prompt" />

                                <h4 className="toolkit-subsection-header">Offer an incentive</h4>
                                <p className="toolkit-p-first">
                                    <span className="toolkit-span">Why?</span> You will get approximately 2%-3% of
                                    participants reviewing with no incentive and about 10% with an incentive so it is worth
                                    offering a prize.
                                </p>
                                <p>
                                    <span className="toolkit-span">What?</span> Item of merchandise, training programme, a
                                    race entry for the following year or a gift offered by one of your sponsors
                                </p>
                                <p>
                                    <span className="toolkit-span">How?</span> A successful method seen by organisers is to
                                    carry out a prize draw for all reviews posted within a certain time (for example 10 days
                                    post-race day) and then announce one/two lucky reviewers who have won the prizes.
                                    Include the incentive in the email subject to get a higher opening rate and engagement.
                                </p>
                                <img src={rIncentiveImg} className="toolkit-img" alt="poster showing request for reviews" />

                                <h4 className="toolkit-subsection-header">Post the review request on social media</h4>
                                <p className="toolkit-p-first">
                                    Don’t forget to ask for reviews on Twitter, Facebook and Instagram and mention any prize
                                    draw too.
                                </p>
                                <p>
                                    <span className="toolkit-span">How?</span> A visual of participants having a good time
                                    at your event including a call to action to review is often a compelling post. Tag{' '}
                                    <a
                                        className="toolkit-content-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://twitter.com/racecheck"
                                    >
                                        @racecheck
                                    </a>{' '}
                                    into the social posts so we can RT where possible.
                                </p>
                                <img
                                    src={rFacebookPostImg}
                                    className="toolkit-img-smaller"
                                    alt="facebook post requesting racecheck reviews"
                                />

                                <h4 className="toolkit-subsection-header">Follow up</h4>
                                <p className="toolkit-p-first">
                                    <span className="toolkit-span">How?</span> Send another email a couple of days later to
                                    remind them and thanking them for their comments.
                                </p>

                                <h4 className="toolkit-subsection-header">Reply to reviews</h4>
                                <p className="toolkit-p-first">
                                    Athletes love to know you have read their comments so spend a few minutes replying to
                                    each review.
                                </p>
                                <p>
                                    <span className="toolkit-span">How?</span> There is a reply button under each review
                                    when you are logged into your Racecheck account. This helps racers feel much more like
                                    they’re part of your community, boosting the feel good factor and increasing subsequent
                                    endorsements, loyalty and return rate.
                                </p>

                                <h3 className="toolkit-section-subheader">The reviews are in</h3>
                                <p className="toolkit-p-first">
                                    Celebrate! You hosted a great event and athletes have told you through their reviews,
                                    it’s time to spread the word and let the testimonials do the talking!
                                </p>
                                <p>
                                    <span className="toolkit-span">How?</span> Post quotes on social media, include reviews
                                    as testimonials on your website, share the reviews with sponsors, venues and partners.
                                </p>

                                <h3 className="toolkit-section-subheader">Earn an award</h3>
                                <p className="toolkit-p-first">
                                    Collect at least 30 reviews of 4.6 or higher to start your journey to a top rated
                                    Racecheck Award.
                                </p>
                            </div>
                        )}
                        {activeToolkitSection === 'awards' && (
                            <div className="toolkit-content">
                                <h2 className="toolkit-section-header">Racecheck Award Toolkit</h2>

                                <h3 className="toolkit-section-subheader">Introduction</h3>
                                <p className="toolkit-p-first">
                                    If you&apos;ve qualified for a Racecheck Award, use this toolkit to
                                    maximise the kudos of winning the award.
                                </p>
                                <p>
                                    You should use this recognition in your event communications with pride, demonstrating
                                    that you run a credible event, as enjoyed and rated by athletes themselves.
                                </p>

                                <h3 className="toolkit-section-subheader">Download your award badge(s)</h3>
                                <p className="toolkit-p-first">
                                    Step 1 is to download your hard earned award badge(s). Either{' '}
                                    <span
                                        className="toolkit-content-link"
                                        onClick={this.toggleAwardsModal}
                                    >
                                        click here
                                    </span>{' '}
                                    or click the button above the toolkit to download your assets in the formats that work
                                    best for you.
                                </p>

                                <h3 className="toolkit-section-subheader">How to use your award badge(s)</h3>
                                <h4 className="toolkit-subsection-header">On your website</h4>
                                <p className="toolkit-p-first">
                                    Place the award badge in an easily visible location on your home page and individual
                                    event page.
                                </p>
                                <img
                                    src={websiteImg}
                                    className="toolkit-img"
                                    alt="racecheck award badge on website header"
                                />

                                <h4 className="toolkit-subsection-header">Social media</h4>
                                <p className="toolkit-p-first">
                                    Announce your win and use the award badge in posts to show off your success. You can
                                    also include the award badge in your profile header images as a more permanent reminder
                                    of your win.
                                </p>
                                <p>
                                    Why not use your social posts as a prompt for your participants to write their own posts
                                    about why they enjoyed the race?
                                </p>
                                <div className="toolkit-double-imgs tdi-margin">
                                    <img
                                        src={socialMedia1Img}
                                        className="toolkit-img"
                                        alt="racecheck award badge on facebook post"
                                    />
                                    <img
                                        src={socialMedia2Img}
                                        className="toolkit-img"
                                        alt="racecheck award badge on facebook page header"
                                    />
                                </div>

                                <h4 className="toolkit-subsection-header">Email footer</h4>
                                <p className="toolkit-p-first">
                                    It&apos;s a great opportunity to include the award badge in your email signature to
                                    promote your win on every email you send.
                                </p>
                                <img
                                    src={emailFooterImg}
                                    className="toolkit-img"
                                    alt="racecheck award badge on email footer"
                                />

                                <h4 className="toolkit-subsection-header">Advertising</h4>
                                <p className="toolkit-p-first">
                                    Whether print or digital, including the award badge on your promotional advertising
                                    helps to provide a trusted seal-of-approval that show real athletes rate and endorse
                                    your event.
                                </p>
                                <img
                                    src={advertisingImg}
                                    className="toolkit-img"
                                    alt="racecheck award badge on banner advert"
                                />

                                <h4 className="toolkit-subsection-header">Participant communication</h4>
                                <p className="toolkit-p-first">
                                    Send an email to your participant database sharing the award announcement and some of
                                    your favourite quotes from the reviews.
                                </p>
                                <p>
                                    The award badge could feature in your regular newsletters and any other promotional
                                    emails you send for the year.
                                </p>
                                <img src={databaseImg} className="toolkit-img" alt="racecheck award badge on email" />

                                <h4 className="toolkit-subsection-header">Local media</h4>
                                <p className="toolkit-p-first">
                                    Provide your local/regional media and key contacts with a release telling the news of
                                    your win.
                                </p>
                                <p className="toolkit-quote">
                                    &quot;Local event wins a national award of recognition...&quot;
                                </p>

                                <h4 className="toolkit-subsection-header">Raceday</h4>
                                <p className="toolkit-p-first">
                                    Include the award badge on race bibs, merchandise and printed signs around your event
                                    village and race route. Couple this with a prompt for participants to review the event
                                    to help you win again next year!
                                </p>
                                <img
                                    src={raceDayMainImg}
                                    className="toolkit-img"
                                    alt="racecheck award badge on finish line arch"
                                />
                                <div className="toolkit-double-imgs">
                                    <img
                                        src={raceDaySmall1Img}
                                        className="toolkit-img"
                                        alt="racecheck award badge on finish line arch"
                                    />
                                    <img
                                        src={raceDaySmall2Img}
                                        className="toolkit-img"
                                        alt="racecheck award badge on finish line arch"
                                    />
                                </div>

                                <h3 className="toolkit-section-subheader">Racecheck promotion</h3>
                                <h4 className="toolkit-subsection-header">Race pages</h4>
                                <p className="toolkit-p-first">
                                    Racecheck will automatically place your award badge in the header of your event page on
                                    the Racecheck platform.
                                </p>
                                <img
                                    src={eventListingImg}
                                    className="toolkit-img"
                                    alt="racecheck race page with event award badge"
                                />

                                <h3 className="toolkit-section-subheader">Support</h3>
                                <p className="toolkit-p-first">
                                    If you need further help in relation to your award, email{' '}
                                    <a
                                        className="toolkit-content-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="mailto:info@racecheck.com"
                                    >
                                    info@racecheck.com
                                    </a>{' '}
                                for support with any assets or information in this toolkit.
                                </p>
                            </div>
                        )}
                        {activeToolkitSection === 'widgets' && (
                            <div className="toolkit-content">
                                <h2 className="toolkit-section-header">Racecheck Review Box Toolkit</h2>
                                <p className="toolkit-p-first">Coming soon.</p>
                            </div>
                        )}
                        {activeToolkitSection === 'brand' && (
                            <div className="toolkit-content">
                                <h2 className="toolkit-section-header">Racecheck Brand Guidelines</h2>
                                <p className="toolkit-p-first">Brand guidelines coming soon.</p>
                            </div>
                        )}
                    </div>
                </div>
                {awardsModalOpen && (
                    <AwardsModal toggleModal={this.toggleAwardsModal} />
                )}
            </div>
        );
    }
}

export default Toolkits;
