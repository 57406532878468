import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AppContext from '../app/AppContext';
import SmallLoader from '../../images/small_loader.gif';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import { isValidEmail } from '../helpers/validationFunctions';
import { localiseText } from '../../utils';

class ResetPassword extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        let resetPasswordView = '';
        const splitUrl = window.location.href.split('?');
        const urlParts = splitUrl[0].split('/');
        const urlParams = splitUrl.length > 1 ? splitUrl[1] : '';
        if (urlParts[urlParts.length - 1] === 'reset-password') {
            resetPasswordView = 'email';
        }
        else {
            resetPasswordView = 'password';
        }

        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            resetPasswordView,
            loadingButton: false,
            passwordMismatchError: false,
            submitError: false,
            emailError: false,
            isFacebookProvider: urlParams.includes('provider=facebook')
        };
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleRequestPasswordReset = () => {
        this.setState(
            {
                loadingButton: true,
                emailError: false,
                submitError: false,
            },
            () => {
                const { email } = this.state;
                if (isValidEmail(email)) {
                    const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/password/reset/`;
                    axios
                        .post(apiCallURL, {
                            email,
                        })
                        .then(() => {
                            this.setState({
                                resetPasswordView: 'email-submit',
                                loadingButton: false,
                            });
                        })
                        .catch(() => {
                            this.setState({
                                email: '',
                                loadingButton: false,
                                submitError: true,
                            });
                        });
                }
                else {
                    this.setState({
                        loadingButton: false,
                        emailError: true,
                    });
                }
            }
        );
    };

    handleNewPasswordSubmit = () => {
        this.setState(
            {
                loadingButton: true,
                passwordMismatchError: false,
                submitError: false,
            },
            () => {
                const { password, confirmPassword } = this.state;
                if (password === confirmPassword && password.length > 1 && confirmPassword.length > 1) {
                    const urlParams = window.location.href.split('/');
                    if (urlParams[urlParams.length - 1] === '') {
                        urlParams.pop();
                    }
                    const uid = urlParams[urlParams.length - 2];
                    const token = urlParams[urlParams.length - 1];
                    const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/reset/`;
                    axios
                        .post(apiCallURL, {
                            new_password1: password,
                            new_password2: confirmPassword,
                            uid,
                            token,
                        })
                        .then(() => {
                            this.setState({
                                resetPasswordView: 'password-submit',
                                loadingButton: false,
                            });
                        })
                        .catch(() => {
                            this.setState({
                                password: '',
                                confirmPassword: '',
                                loadingButton: false,
                                submitError: true,
                            });
                        });
                }
                else {
                    this.setState({
                        loadingButton: false,
                        passwordMismatchError: true,
                    });
                }
            }
        );
    };

    render () {
        const {
            email,
            password,
            confirmPassword,
            resetPasswordView,
            loadingButton,
            passwordMismatchError,
            submitError,
            emailError,
            isFacebookProvider
        } = this.state;
        let resetPasswordViewContent;
        if (resetPasswordView === 'email') {
            resetPasswordViewContent = (
                <div className="log_in_container">
                    <h2 className="rp-title">Reset password</h2>
                    <div>
                        {isFacebookProvider ?
                            <div className="rp-fb-warning-container">
                                <p className="rp-fb-warning-title">Facebook login temporarily unavailable</p>
                                <p className="rp-fb-warning-copy">Our Facebook login is temporarily unavailable. Please
                                    provide the email address linked to your Facebook account to access Racecheck. We&apos;ll
                                    send instructions to create a password</p>
                                <br />
                                <span className="rp-fb-warning-copy">Already have a password?</span>
                                <a className="btn rp-fb-warning-btn" href="/login">
                                    Log in with email
                                </a>
                            </div>
                            :
                            <p className="rp-copy">
                                Enter your email below and we will send a unique link which can be used to reset your
                                password.
                            </p>
                        }
                        {emailError && (
                            <div className="subscription-modal-error-message">
                                <p className="stm-copy rp-error-copy">
                                    <span className="stm-copy-highlight">
                                        <span className="subscription-error-icon">
                                            <ExclamationIcon />
                                        </span>
                                        Enter a valid email.
                                    </span>
                                </p>
                            </div>
                        )}
                        {submitError && (
                            <div className="subscription-modal-error-message">
                                <p className="stm-copy rp-error-copy">
                                    <span className="stm-copy-highlight">
                                        <span className="subscription-error-icon">
                                            <ExclamationIcon />
                                        </span>
                                        Something went wrong. Please try again.
                                    </span>
                                </p>
                            </div>
                        )}
                        <div className="input-group">
                            <label htmlFor="log-in-email" className="rp-label">
                                Email Address <span className="required-field">*</span>
                            </label>
                            <input
                                id="log-in-email"
                                value={email}
                                onChange={this.handleChange}
                                className="input"
                                name="email"
                                type="email"
                                placeholder={`name@${localiseText('organisation')}.com`}
                                required
                            />
                        </div>
                        <div className="log-in-form-submit">
                            {!loadingButton ? (
                                <button
                                    id="log-in-submit"
                                    className="log-in-btn btn margin-top-btn"
                                    onClick={this.handleRequestPasswordReset}
                                >
                                    {' '}
                                    Reset password
                                </button>
                            ) : (
                                <button id="log-in-submit" className="log-in-btn btn margin-top-btn log-in-btn-loading">
                                    <img className="subscription-btn-loader" src={SmallLoader} alt="loading-icon" />
                                </button>
                            )}
                        </div>
                        <p className="sign-up-disclaimer invite-disclaimer">
                            Not meant to be here? Please&nbsp;
                            <a className="invite-link" rel="noopener noreferrer" href="/">
                                click here
                            </a>
                            .
                        </p>
                    </div>
                    <p className="account-support-link">
                        Need help accessing your account or {localiseText('organisation')}?&nbsp;
                        Get in touch via <a href="mailto:support@racecheck.com">support@racecheck.com</a>
                    </p>
                </div>
            );
        }
        else if (resetPasswordView === 'email-submit') {
            resetPasswordViewContent = (
                <div className="log_in_container">
                    <h2 className="rp-title">Reset password</h2>
                    <div>
                        <p className="rp-copy">
                            If an account exists, an email will be sent with further instructions.
                        </p>
                        <Link to="/login" className="invite-link rp-return-link">
                            <button id="log-in-submit" className="log-in-btn btn margin-top-btn">
                                Return to log in
                            </button>
                        </Link>
                    </div>
                </div>
            );
        }
        else if (resetPasswordView === 'password') {
            resetPasswordViewContent = (
                <div className="log_in_container">
                    <h2 className="rp-title">Change password</h2>
                    <div>
                        <p className="rp-copy">Enter your new password below to update your account.</p>
                        {passwordMismatchError && (
                            <div className="subscription-modal-error-message">
                                <p className="stm-copy rp-error-copy">
                                    <span className="stm-copy-highlight">
                                        <span className="subscription-error-icon">
                                            <ExclamationIcon />
                                        </span>
                                        Passwords do not match.
                                    </span>
                                </p>
                            </div>
                        )}
                        {submitError && (
                            <div className="subscription-modal-error-message">
                                <p className="stm-copy rp-error-copy">
                                    <span className="stm-copy-highlight">
                                        <span className="subscription-error-icon">
                                            <ExclamationIcon />
                                        </span>
                                        Something went wrong. Please try again.
                                    </span>
                                </p>
                            </div>
                        )}
                        <div className="input-group">
                            <label htmlFor="log-in-password" className="rp-label">
                                New password <span className="required-field">*</span>
                            </label>
                            <input
                                id="log-in-password"
                                value={password}
                                onChange={this.handleChange}
                                className="input"
                                name="password"
                                type="password"
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="log-in-confirmpassword" className="rp-label">
                                Re-enter your new password <span className="required-field">*</span>
                            </label>
                            <input
                                id="log-in-confirmpassword"
                                value={confirmPassword}
                                onChange={this.handleChange}
                                className="input"
                                name="confirmPassword"
                                type="password"
                                required
                            />
                        </div>
                        <div className="log-in-form-submit">
                            {!loadingButton ? (
                                <button
                                    id="log-in-submit"
                                    className="log-in-btn btn margin-top-btn"
                                    onClick={this.handleNewPasswordSubmit}
                                >
                                    {' '}
                                    Reset password
                                </button>
                            ) : (
                                <button id="log-in-submit" className="log-in-btn btn margin-top-btn log-in-btn-loading">
                                    <img className="subscription-btn-loader" src={SmallLoader} alt="loading-icon" />
                                </button>
                            )}
                        </div>
                        <p className="sign-up-disclaimer invite-disclaimer">
                            Not meant to be here? Please&nbsp;
                            <a className="invite-link" rel="noopener noreferrer" href="/">
                                click here
                            </a>
                            .
                        </p>
                    </div>
                </div>
            );
        }
        else if (resetPasswordView === 'password-submit') {
            resetPasswordViewContent = (
                <div className="log_in_container">
                    <h2 className="rp-title">Change password</h2>
                    <div>
                        <p className="rp-copy">Your password has successfully been changed.</p>
                        <Link to="/login" className="invite-link rp-return-link">
                            <button id="log-in-submit" className="log-in-btn btn margin-top-btn">
                                Return to log in
                            </button>
                        </Link>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <div className="logged_out_container">
                    <a href="/">
                        <img
                            className="logged_out_logo"
                            src={require('../../images/racecheck_logo_black.svg')}
                            width="250px"
                            alt="racecheck-logo" 
                        />
                    </a>
                    {resetPasswordViewContent}
                </div>
            </div>
        );
    }
}

export default ResetPassword;
