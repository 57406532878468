import React, { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import './ReviewSection.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Loader from '../helpers/loader';
import { ReactComponent as ListIcon } from '../../images/icons/document.svg';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron_down.svg';
import { ReactComponent as ChevronUp } from '../../images/icons/chevron_up.svg';
import { ReactComponent as CopyIcon } from '../../images/icons/copy.svg';

// TODO: REFACTOR STRUCTURE AND ADD DATA WHEN READY
class RequestReviewChecklist extends Component {
    constructor (props) {
        super(props);

        this.state = {
            openSections: [],
            checklistLoaded: false,
            checklistProgress: [],
            codeCopied: false,
        };
    }

    componentDidMount () {
        this.getEventReviewProgress();
    }

    getEventReviewProgress = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        /* eslint-disable-next-line*/
        let apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.props.selectedOrganisation.id}/events/${this.props.event}/tasks_progress/`;
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                let nextOpenSection;
                if (!res.data.data.widget_installed) {
                    nextOpenSection = '2';
                }
                else if (!res.data.data.review_collected) {
                    nextOpenSection = '3';
                }
                else {
                    nextOpenSection = '0';
                }
                this.setState({
                    checklistProgress: res.data.data,
                    checklistLoaded: true,
                    openSections: [nextOpenSection]
                }, () => {

                });
            })
            .catch(error => {
                console.log('Could not retrieve progress', error);
                return null;
            });
    };

    handleOpenSectionChange = section => {
        const openSections = this.state.openSections;
        if (openSections.indexOf(section) > -1) {
            openSections.splice(openSections.indexOf(section), 1);
        }
        else {
            openSections.push(section);
        }
        this.setState({
            openSections,
        });
    };

    copyCodeToClipboard = () => {
        this.setState(
            {
                codeCopied: true,
            },
            () => {
                const codeToCopy = `
                        <script>
                            (function(w, d, s, o, f, js, fjs) {
                            w['RacecheckWidget']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
                            js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
                            js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
                            }(window, document, 'script', 'rcw', '${process.env.REACT_APP_API_URL}/static/js/widget.js'));
                            rcw('init', {widget: ${this.props.fullevent?.widgets?.review_box?.id}});
                        </script>`;
                navigator.clipboard.writeText(codeToCopy);
                setTimeout(() => {
                    this.setState({
                        codeCopied: false,
                    });
                }, 2000);
            }
        );
    };

    render () {
        const { openSections, checklistLoaded, checklistProgress, codeCopied } = this.state;
        const remainingTasks = checklistProgress.total_tasks - checklistProgress.completed_tasks;
        const percentageCompleted = Math.round((checklistProgress.completed_tasks / checklistProgress.total_tasks) * 100);
        return (
            <div className="request-reviews-checklist-container">
                {checklistLoaded ?
                    <>
                        <div className="rr-checklist-header">
                            <ListIcon />
                            <div className="rr-checklist-header-info">
                                <p className="rr-checklist-title">Getting started with Racecheck</p>
                                <div className="rr-checklist-progress-bar">
                                    <div className="rr-checklist-progress-fill" style={{ width: `${percentageCompleted}%` }} />
                                </div>
                                <div className="rr-checklist-stats">
                                    <p>
                                        {percentageCompleted}% completed
                                    </p>
                                    <span className="bullet">·</span>
                                    {remainingTasks > 0 ?
                                        <p>
                                            {remainingTasks} task{remainingTasks > 1 ? 's' : ''} remaining
                                        </p>
                                        :
                                        <p>
                                        All tasks completed!
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="rr-checklist-items">
                            <div className={`rr-checklist-item ${checklistProgress.event_claimed ?
                                'rr-checklist-item-complete' : ''}`}
                            >
                                <div className="checklist-check" />
                                <div className="rr-checklist-item-body">
                                    <div className="checklist-item-header">
                                        <div className="checklist-item-header-copy">
                                            <p>Claim your event {this.props.fullevent.title}</p>
                                            <p>Claiming your event on Racecheck is the first
                                                 step to harnessing the power of reviews
                                            </p>
                                        </div>
                                        <div className="checklist-item-chevron" onClick={() => this.handleOpenSectionChange('1')}>
                                            <ChevronDown />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`rr-checklist-item ${checklistProgress.widget_installed ?
                                'rr-checklist-item-complete' : ''}`}
                            >
                                <div className="checklist-check" />
                                <div className="rr-checklist-item-body">
                                    <div className="checklist-item-header">
                                        <div className="checklist-item-header-copy">
                                            <p>Display your event&apos;s Racecheck Review Box on your website</p>
                                            <p>The Racecheck Review Box will help increase sign ups, boost SEO and more</p>
                                        </div>
                                        <div className="checklist-item-chevron"
                                            onClick={() => this.handleOpenSectionChange('2')}
                                        >
                                            {(openSections.includes('2')) ?
                                                <ChevronUp />
                                                :
                                                <ChevronDown />
                                            }
                                        </div>
                                    </div>
                                    {(openSections.includes('2')) &&
                                <div className="checklist-item-extra-info">
                                    <p>The first, and most important, step to utilising race reviews is to install
                                         the Racecheck Review Box on your website.
                                    </p>
                                    {checklistProgress.review_collected ?
                                        /* eslint-disable */
                                        <p>
                                            Congratulations on receiving {this.props.fullevent.reviews} review{this.props.fullevent.reviews > 1 ? 's ' : ' '}
                                            for {this.props.fullevent.title}! Now it's time to show them off to your prospective participants. The Review Box code
                                            below will display the Racecheck Review Box on your site, meaning visitors will instantly be able to see how your event
                                            is rated.
                                        </p>
                                        :
                                        <p>
                                            As {this.props.fullevent.title} currently has no reviews, the Review Box code below will automatically
                                            display a Review Box designed to allow your event participants to quickly and easily leave a review.
                                            Once you've gathered your first review, the Review Box will then automatically begin displaying your feedback,
                                            right there on your website. No need for you to change any code!
                                        </p>
                                        /* eslint-enable */
                                    }
                                    <p>To get started, simply add the code below to the
                                         page where you want the Review Box to be displayed.
                                    </p>
                                    <div className="mw-code-container">
                                        <div className="mw-copy-code" onClick={this.copyCodeToClipboard}>
                                            {codeCopied ? <p>Code copied</p> : <p>Copy</p>}
                                            <CopyIcon />
                                        </div>
                                        <div className="mw-code-content">
                                            <pre className="mw-code-pre">
                                                {`<script>
  (function(w, d, s, o, f, js, fjs) {
  w['RacecheckWidget']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
  js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
  js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
  }(window, document, 'script', 'rcw', '${process.env.REACT_APP_API_URL}/static/js/widget.js'));
  rcw('init', {widget: ${this.props.fullevent?.widgets?.review_box?.id}});
</script>`}
                                            </pre>
                                        </div>
                                    </div>
                                    <p>For further help or information regarding installation or customisation, refer to the&nbsp;
                                        <Link className="checklist-item-link" to="/resources/faq">
                                            FAQs section
                                        </Link>.
                                    </p>
                                    {!this.props.selectedOrganisation.is_premium_subscriber &&
                                        <div className="checklist-item-promo">
                                            <div className="checklist-item-promo-copy">
                                                <p>Get the expanding Racecheck Review Box</p>
                                                <p>
                                                    Upgrade your account to Premium to unlock the full
                                                    expanding Racecheck Review Box. Elegantly display detailed event feedback on
                                                    your website, gather reviews, boost your SEO and more.
                                                </p>
                                            </div>
                                            <Link to={'/subscription/subscription-plan'} 
                                                className="btn primary-btn modal-form-btn"
                                            >
                                                Get started with Premium
                                            </Link>
                                        </div>
                                    }
                                </div>
                                    }
                                </div>
                            </div>
                            <div className={`rr-checklist-item ${checklistProgress.review_collected ?
                                'rr-checklist-item-complete' : ''}`}
                            >
                                <div className="checklist-check" />
                                <div className="rr-checklist-item-body">
                                    <div className="checklist-item-header">
                                        <div className="checklist-item-header-copy">
                                            <p>Collect your first review</p>
                                            <p>It&apos;s time to get your first authentic feedback from participants</p>
                                        </div>
                                        <div className="checklist-item-chevron" onClick={() => this.handleOpenSectionChange('3')}>
                                            {openSections.includes('3') ?
                                                <ChevronUp />
                                                :
                                                <ChevronDown />
                                            }
                                        </div>
                                    </div>
                                    {openSections.includes('3') &&
                                <div className="checklist-item-extra-info">
                                    {!checklistProgress.widget_installed &&
                                        <p>
                                            We highly recommend using the free review collection Review Box to gather your
                                            first review - for information on how to install the Review Box, check out the
                                            task above this one!
                                        </p>
                                    }
                                    <p>
                                        {checklistProgress.widget_installed ?
                                            'With the Racecheck Review Box installed on your website '
                                            :
                                            'Once the Racecheck Review Box is installed on your website '
                                        } 
                                        the best way to collect reviews is to direct participants to your own website so they 
                                        can review the event through the Review Box. 
                                        It&apos;s quick, simple and doesn&apos;t require
                                        your participants to jump any hurdles such as creating accounts.
                                    </p>
                                    <p>
                                        For further information about collecting reviews, check out the&nbsp;
                                        <Link className="checklist-item-link" to="/resources/faq">
                                            FAQs section
                                        </Link>
                                        .
                                    </p>
                                </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="section-loading-container">
                        <Loader />
                    </div>
                }
            </div>
        );
    }
}

RequestReviewChecklist.propTypes = {
    selectedOrganisation: PropTypes.shape({
        is_premium_subscriber: PropTypes.bool,
    }),
    fullevent: PropTypes.shape({
        widget: PropTypes.shape({
            id: number
        }),
        title: PropTypes.string,
        status: PropTypes.string
    })
};

export default RequestReviewChecklist;
