import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './ResourceSection.css';
import Loader from '../helpers/loader';
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';
import { localiseText } from '../../utils';

const FAQSection = ({ section }) => (
    <div className="faq-section">
        <h2 className="faq-section-header">{section.icon}&nbsp;{localiseText(section.header)}</h2>
        <div className="faq-items-list">
            {section.subpages.map(item => (
                <FAQItem
                    key={item.icon}
                    item={item}
                />
            ))}
        </div>
    </div>
);

const FAQItem = ({ item }) => (
    <div className="faq-section-item">
        <a href={item.url} target="_blank" rel="noopener noreferrer">
            {item.icon}&nbsp;{localiseText(item.title)}
            <ChevronRight />
        </a>
    </div>
);

const FAQ = () => {
    const [FAQList, setFAQList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const { REACT_APP_API_URL } = process.env;

    const getFAQs = () => {
        setLoaded(false);

        const apiCallURL = `${REACT_APP_API_URL}/api/notion-racecheck-organiser-hub/`;

        axios.get(apiCallURL)
            .then(({ data: { data } }) => {
                setFAQList(data);
                setLoaded(true);
            })
            .catch(error => console.log(error));
    };

    useEffect(getFAQs, []);

    return (
        <div className="faq-container">
            {loaded ?
                <>
                    {FAQList.map(item => (
                        <FAQSection 
                            key={item.icon}
                            section={item}
                        />
                    ))}
                </>
                :
                <div className="section-loading-container">
                    <Loader />
                </div>
            }
        </div>
    );
};

export default FAQ;

FAQSection.propTypes = {
    section: PropTypes.shape({
        icon: PropTypes.string,
        header: PropTypes.string,
        subpages: PropTypes.arrayOf(
            PropTypes.shape({
                icon: PropTypes.string,
                url: PropTypes.string,
                title: PropTypes.string,
            })
        )
    })
};

FAQItem.propTypes = {
    item: PropTypes.shape({
        icon: PropTypes.string,
        url: PropTypes.string,
        title: PropTypes.string,
    })
};