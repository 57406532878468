import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../organiserDashboard/Modal.css';
import AppContext from '../app/AppContext';
import onboardingDashboard from '../../images/onboarding/onboarding-dashboard.png';
import onboardingFeedback from '../../images/onboarding/onboarding-feedback.png';
import onboardingResources from '../../images/onboarding/onboarding-resources.png';
// import onboardingTeam from '../../images/onboarding/onboarding-team.png';
// import onboardingRun from '../../images/onboarding/onboarding-run.png';
import SmallLoader from '../../images/small_loader.gif';
import { localiseText } from '../../utils';

class OnboardingFlow extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            loadingButton: false,
            isAddEventModalOpen: false
        };
    }

    handleProgressBtn = () => {
        this.setState({
            loadingButton: true
        }, () => {
            const token = this.context.token ? this.context.token : localStorage.getItem('token');
            const headers = {
                Authorization: `Token ${token}`,
            };
            /* eslint-disable-next-line*/
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/user/${this.context.user.id}/`;
            axios
                .put(
                    apiCallURL,
                    {
                        onboarded: true
                    },
                    { headers }
                )
                .then(res => {
                    this.context.setUser(res.data);
                    this.props.updateUserOnboarded(true);
                    if (!this.context.selectedOrganisation.has_events) {
                        this.props.toggleAddEventModal(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }

    render () {
        const { loadingButton } = this.state;
        return (
            <div className="modal-backdrop">
                <div className="modal-padding" />
                <div className="modal-container onboarding-modal-container">
                    <div className="modal-scrollable onboarding-modal-content">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                {/* eslint-disable */}
                                    <div className="onboarding-step-content">
                                        <p className="onboarding-step-title">Welcome to your {localiseText('Organiser')} Dashboard</p>
                                        {/* <p className="onboarding-step-copy">
                                            From here you can manage your events, gather and reply to invaluable participant reviews, set up your Racecheck Widgets and much more.
                                        </p> */}
                                        <div className="onboarding-img-row">
                                            <div className="onboarding-img-group">
                                                <img className="onboarding-img" src={onboardingDashboard} alt="Person looking at dashboard" />
                                                <p className="onboarding-img-caption"><span>Manage</span> your events</p>
                                            </div>
                                            <div className="onboarding-img-group">
                                                <img className="onboarding-img" src={onboardingFeedback} alt="Person leaving a review" />
                                                <p className="onboarding-img-caption"><span>Gather and reply</span> to reviews</p>
                                            </div>
                                            <div className="onboarding-img-group">
                                                <img className="onboarding-img" src={onboardingResources} alt="Person reading an article" />
                                                <p className="onboarding-img-caption"><span>Learn</span> from industry experts</p>
                                            </div>
                                        </div>
                                    </div>
                                {/* {activePage === 2 &&
                                    <div className="onboarding-step-content">
                                        <p className="onboarding-step-title">Manage event feedback</p>
                                        <p className="onboarding-step-copy">
                                            Customise and deploy the Racecheck Widget to easily gather feedback, and use the analytics reports to understand what your participants truly think.
                                        </p>
                                        <img className="onboarding-img" src={onboardingFeedback} alt="Person leaving a review" />
                                    </div>
                                }
                                {activePage === 3 &&
                                    <div className="onboarding-step-content">
                                        <p className="onboarding-step-title">A wealth of knowledge</p>
                                        <p className="onboarding-step-copy">
                                            Immerse yourself in toolkits and articles to learn how you can use the dashboard tools to take your event to the best level it can be.
                                        </p>
                                        <img className="onboarding-img" src={onboardingResources} alt="Person reading an article" />
                                    </div>
                                }
                                {activePage === 4 &&
                                    <div className="onboarding-step-content">
                                        <p className="onboarding-step-title">Your whole organisation in one place</p>
                                        <p className="onboarding-step-copy">
                                            Invite team members so you can collaboratively reply to reviews, manage events and more. An audit keeps track of who did what when so you can effectively delegate responsibilities.
                                        </p>
                                        <img className="onboarding-img" src={onboardingTeam} alt="Person being greeted by team" />
                                    </div>
                                }
                                {activePage === 5 &&
                                    <div className="onboarding-step-content">
                                        <p className="onboarding-step-title">Hit the ground running</p>
                                        <p className="onboarding-step-copy">
                                            Get started by adding or claiming your events on the next page. Should you need any further help, be sure to check out the FAQs in the bottom left!
                                        </p>
                                        <img className="onboarding-img" src={onboardingRun} alt="Person running" />
                                    </div>
                                } */}
                                {/* eslint-enable */}
                                <div className="modal-btn-row onboarding-modal-btns">
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                        >
                                            <img
                                                className="subscription-btn-loader"
                                                src={SmallLoader}
                                                alt="userprofile"
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            role="button"
                                            className="btn primary-btn modal-form-btn"
                                            onClick={this.handleProgressBtn}
                                        >
                                            {this.context.selectedOrganisation.has_events ?
                                                'Continue to the dashboard' : 'Add your first event'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

export default OnboardingFlow;

OnboardingFlow.propTypes = {
    updateUserOnboarded: PropTypes.func,
    toggleAddEventModal: PropTypes.func,
};