import React from 'react';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import NoReviewsDataPlaceholder from '../../helpers/NoReviewsDataPlaceholder';
import { Chart, PointElement, LineController, LineElement } from 'chart.js';

Chart.register(PointElement, LineController, LineElement);

const options = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: false,
                    precision: 2,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Avg. Rating',
                },
            }
        ],
        xAxes: [
            {
                scaleLabel: {
                    display: true,
                    labelString: 'Years',
                },
            }
        ],
    },
    elements: {
        line: {
            tension: 0,
        },
    },
    legend: {
        display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        datalabels: {
            display: false,
        },
    }
};

const mapStylesToTrend = {
    upwards: {
        backgroundColor: 'rgba(0, 153, 51, 0.1)',
        borderColor: 'rgba(0, 153, 51, 0.9)',
    },
    downward: {
        backgroundColor: 'rgba(212, 38, 15, 0.1)',
        borderColor: 'rgba(212, 38, 15, 0.9)',
    },
    steady: {
        backgroundColor: 'rgba(255, 204, 0, 0.1)',
        borderColor: 'rgba(255, 204, 0, 0.9)',
    },
    category: {
        backgroundColor: 'rgba(30, 144, 255, 0.1)',
        borderColor: 'rgba(30, 144, 255, 0.9)',
    }
};

const YearOnYearRating = ({
    data = {
        event_yoy_dataset: [],
        category_yoy_dataset: [],
        message: '',
        trend: '',
    },
}) => {
    const { event_yoy_dataset, category_yoy_dataset, category, message, trend } = data;

    const title = <p className="review-trends-title analytics-card-title">Year-on-year rating trend</p>;

    const chartData = {
        labels: event_yoy_dataset?.map(item => item.year),
        datasets: [
            {
                label: 'Avg. Rating',
                data: event_yoy_dataset.map(item => item.average_rating),
                fill: true,
                backgroundColor: mapStylesToTrend[trend]?.backgroundColor,
                borderColor: mapStylesToTrend[trend]?.borderColor,
            },
            {
                label: category + ' Events Avg. Rating',
                data: category_yoy_dataset.map(item => item.average_rating),
                fill: true,
                backgroundColor: mapStylesToTrend.category?.backgroundColor,
                borderColor: mapStylesToTrend.category?.borderColor,
            }
        ],
    };

    if (!event_yoy_dataset.length || !category_yoy_dataset.length) {
        return (
            <>
                {title}
                <NoReviewsDataPlaceholder message={message} />
            </>
        );
    }

    return (
        <>
            <div className="review-trends-header">{title}</div>
            <div className="review-trends-chart">
                <Line data={chartData} options={options} />
            </div>
            <p className="review-trends-description">{message}</p>
        </>
    );
};

YearOnYearRating.propTypes = {
    data: PropTypes.shape({
        event_yoy_dataset: PropTypes.arrayOf(
            PropTypes.shape({
                year: PropTypes.number,
                average_rating: PropTypes.number,
            })
        ),
        category_yoy_dataset: PropTypes.arrayOf(
            PropTypes.shape({
                year: PropTypes.number,
                average_rating: PropTypes.number,
            })
        ),
        trend: PropTypes.oneOf(['downward', 'upwards', 'steady']),
        message: PropTypes.string,
        category: PropTypes.string,
    }),
};

export default YearOnYearRating;
