export function getContinentFromTimezone () {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone.includes('America/') || timezone.includes('Pacific/') || timezone.includes('Antarctica/')) {
        return 'NA';
    }

    return 'EU';
}


export function localiseText (text) {
    if (getContinentFromTimezone() === 'NA') {
        return text.replace('rganis', 'rganiz').replace('ise', 'ize');
    }
    return text;
}
