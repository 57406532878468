import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import './Modal.css';
import './LandingPage.module.css';
import AppContext from '../app/AppContext';

class Notifications extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            notifications: {}
        };
    }

    componentDidMount () {
        const { token: userToken } = this.context;
        const token = userToken || localStorage.getItem('token');
        // eslint-disable-next-line
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/notification_setting/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        this.setState({ widgetsLoaded: false });
        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                this.setState({ notifications: data?.data });
            })
            .catch(error => {
                console.log(error.response);
            });
    }

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    handleNotificationToggle = (notificationType, type, is_on) => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        /* eslint-disable-next-line*/
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/notification_setting/${notificationType}/`;
        axios
            .put(
                apiCallURL,
                {
                    type,
                    is_on
                },
                { headers }
            )
            .then(({ data }) => {
                this.setState({ notifications: data?.data });
            })
            .catch(error => {
                console.log(error);
            });
    }

    render () {
        const { notifications } = this.state;

        return (
            <>
                <div className="modal-content-section push-notification-settings">
                    <h3 className="modal-section-header">Dashboard notifications</h3>
                    <div className="settings-notifications-container">

                        {notifications?.push_notification_settings?.map(({ type, display, description, is_on }) => (
                            <div className="notification-row" key={`push_${type}`}>
                                <div className="notification-info">
                                    <p className="notification-name">{display}</p>
                                    <p className="notification-desc">{description}</p>
                                </div>
                                <div className="notification-status">
                                    <p className="ns-tag">
                                        {is_on ?
                                            'On'
                                            :
                                            'Off'
                                        }
                                    </p>
                                    <label className="ns-toggle">
                                        <input
                                            className="ns-toggle-input"
                                            type="checkbox"
                                            onClick={(e) => this.handleNotificationToggle('push', type, e.target.checked)}
                                            readOnly
                                            defaultChecked={is_on}
                                        />
                                        <span className="ns-toggle-slider" />
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="modal-content-section email-notification-settings">
                    <h3 className="modal-section-header">Email notifications</h3>
                    <div className="settings-notifications-container">
                        {notifications?.email_notification_settings?.map(({ type, display, description, is_on }) => (
                            <div className="notification-row" key={`email_${type}`}>
                                <div className="notification-info">
                                    <p className="notification-name">{display}</p>
                                    <p className="notification-desc">{description}</p>
                                </div>
                                <div className="notification-status">
                                    <p className="ns-tag">
                                        {is_on ?
                                            'On'
                                            :
                                            'Off'
                                        }
                                    </p>
                                    <label className="ns-toggle">
                                        <input
                                            className="ns-toggle-input"
                                            type="checkbox"
                                            onClick={(e) => this.handleNotificationToggle('email', type, e.target.checked)}
                                            readOnly
                                            defaultChecked={is_on}
                                        />
                                        <span className="ns-toggle-slider" />
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }
}

Notifications.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    displayErrorMessage: PropTypes.func.isRequired,
    displaySuccessMessage: PropTypes.func.isRequired,
};

export default Notifications;
