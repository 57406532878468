import React, { Component } from 'react';
import axios from 'axios';
import Loader from '../helpers/loader';
import AppContext from '../app/AppContext';
import SocialLoginError from '../helpers/socialLoginError';

class StravaLogIn extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            hasError: null,
        };
    }

    componentDidMount () {
        const stravaCode = new URLSearchParams(window.location.search).get('code');
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/login/strava/`;
        axios
            .post(apiCallURL, {
                code: stravaCode,
                claim: this.context.getClaimId(),
            })
            .then(res => {
                localStorage.removeItem('claim');
                const {
                    membershipStatus,
                    defaultOrganisation,
                } = this.context.getMemberShipStatusAndDefaultOrganisation(res.data.user);
                if (sessionStorage.getItem('invite_code')) {
                    this.context.acceptTeamInvite(res);
                }
                else {
                    this.context.handleLogInState(res.data.token, membershipStatus, defaultOrganisation);
                }
            })
            .catch(error => {
                console.log('Strava login error', error.response.data);
                this.setState({
                    hasError: error.response.data.message
                        ? error.response.data.message
                        : 'An error occurred. Please try again later or contact our team at info@racecheck.com.',
                });
            });
    }

    render () {
        return (
            <div>
                {this.state.hasError ? (
                    <SocialLoginError errorMessage={this.state.hasError} />
                ) : (
                    <div className="social-login-loading-container">
                        <Loader />
                        <p>Using Strava to log you in...</p>
                    </div>
                )}
            </div>
        );
    }
}

export default StravaLogIn;
