import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { mapCurrencyToSign } from '../../utils';
import AppContext from '../app/AppContext'; 

const SummaryCard = ({ 
    subscriptionDetails,
    totalCost,
    paycycle,
    currency,
    nextPaymentDate = undefined,
    customerDetails,
}) => {
    const { selectedOrganisation } = useContext(AppContext);
    const nextPayment = nextPaymentDate || subscriptionDetails.next_payment_date;

    const vatAmount = (totalCost / (1 + subscriptionDetails?.plan?.vat / 100)) * (subscriptionDetails?.plan?.vat / 100);

    return (
        <div className="subscription-modal-radio-option subscription-modal-info-card">
            <div className="smro-label">
                <div className="smro-copy">
                    <p className="smro-title">
                        Racecheck Premium
                    </p>
                    <div className="smro-desc">
                        <p>for {selectedOrganisation.name}</p>
                    </div>
                    {/* <div className="smro-desc">
                        <p>
                            {selectedEvents[0]?.title}
                            {eventsCount > 2 && ` + ${eventsCount - 1} others`}
                            {eventsCount === 2 && ` + ${eventsCount - 1} other`}
                        </p>
                        {eventsCount > 1 &&
                            <Tooltip array={selectedEvents.map(event => event.title)} />
                        }
                    </div> */}
                    
                </div>
                <div className="smro-price">
                    <h2 className="spc-plan-title smro-price-title">
                        <span className="spc-pt-currency">{mapCurrencyToSign[currency]}</span>
                        {totalCost}
                        <span className="spc-pt-month">
                            {/* eslint-disable-next-line */}
                            {customerDetails?.country === 'United Kingdom' ? `(inc. ${mapCurrencyToSign[currency]}${(vatAmount).toFixed(2)} VAT)` : ''}
                            {paycycle === 'M' ? '/mo' : '/yr'}
                        </span>
                    </h2>
                </div>
            </div>
            <p className="smro-desc smro-desc-extra smro-payment-summary-copy">
                {paycycle === 'M' ? 
                    <>
                        <span>Monthly&nbsp;</span>payments from&nbsp;
                        {subscriptionDetails.details.statuses.is_trial_eligible ?
                            <span>
                                <s>{moment(nextPayment).format('Do MMM YYYY')}</s>
                                &nbsp;
                                {moment(nextPayment).add(14, 'days').format('Do MMM YYYY')}
                            </span>
                            :
                            <span>
                                {moment(nextPayment).format('Do MMM YYYY')}
                            </span>
                        }
                    </>
                    :
                    <>
                        <span>Yearly&nbsp;</span>payments from&nbsp;
                        {subscriptionDetails.details.statuses.is_trial_eligible ?
                            <span>
                                <s>{moment(nextPayment).format('Do MMM YYYY')}</s>
                                &nbsp;
                                {moment(nextPayment).add(14, 'days').format('Do MMM YYYY')}
                            </span>
                            :
                            <span>
                                {moment(nextPayment).format('Do MMM YYYY')}
                            </span>
                        }
                    </>
                }
            </p>
        </div>
    );
};

SummaryCard.propTypes = {
    currency: PropTypes.string,
    eventsCount: PropTypes.number,
    couponApplied: PropTypes.bool,
    selectedEvents: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string
        })
    ),
    subscriptionDetails: PropTypes.shape({
        title: PropTypes.string,
        next_payment_date: PropTypes.oneOf(PropTypes.instanceOf(Date), PropTypes.string),
        details: PropTypes.shape({
            customer: PropTypes.shape({
                country: PropTypes.string
            }),
            statuses: PropTypes.shape({
                is_trial_eligible: PropTypes.bool
            })
        }),
        plan: PropTypes.shape({
            vat: PropTypes.number
        })
    }),
    totalCost: PropTypes.number,
    paycycle: PropTypes.string,
    customerDetails: PropTypes.shape({
        country: PropTypes.string
    }),
    nextPaymentDate: PropTypes.string
};

export default SummaryCard;
