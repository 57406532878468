import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../organiserDashboard/Modal.css';
import { isEqual } from 'lodash';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import SuccessMessage from '../helpers/successMessage';
import SmallLoader from '../../images/small_loader.gif';
import ErrorMessage from '../helpers/errorMessage';
// import {ReactComponent as ExclamationIcon} from '../../images/icons/exclamation_circle.svg';
// import {ReactComponent as MastercardIcon} from '../../images/mastercard.svg';
import AppContext from '../app/AppContext';
import countries from '../helpers/countries';
import { localiseText } from '../../utils';

class EditDetailsModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        let customerDetails = {
            organisation: '',
            firstName: '',
            lastName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
        };

        let oldCustomerDetails = {
            organisation: '',
            firstName: '',
            lastName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
        };

        if (this.props.subscriptionDetails.details.statuses.customer_status === 'OK') {
            const customer = this.props.subscriptionDetails.details.customer;
            customerDetails = {
                organisation: customer.company ? customer.company : '',
                firstName: customer.first_name ? customer.first_name : '',
                lastName: customer.last_name ? customer.last_name : '',
                email: customer.email ? customer.email : '',
                addressLine1: customer.address ? customer.address : '',
                addressLine2: customer.address2 ? customer.address2 : '',
                city: customer.city ? customer.city : '',
                state: customer.state ? customer.state : '',
                postcode: customer.postcode ? customer.postcode : '',
                country: customer.country ? customer.country : '',
            };
            oldCustomerDetails = {
                organisation: customer.company ? customer.company : '',
                firstName: customer.first_name ? customer.first_name : '',
                lastName: customer.last_name ? customer.last_name : '',
                email: customer.email ? customer.email : '',
                addressLine1: customer.address ? customer.address : '',
                addressLine2: customer.address2 ? customer.address2 : '',
                city: customer.city ? customer.city : '',
                state: customer.state ? customer.state : '',
                postcode: customer.postcode ? customer.postcode : '',
                country: customer.country ? customer.country : '',
            };
        }

        const initialCountryCodes = countries.map(countryCodes => countryCodes);

        this.state = {
            countryCodeOptions: initialCountryCodes,
            currentSuccessMessage: '',
            currentErrorMessage: '',
            customerDetails,
            oldCustomerDetails,
            changesMade: false,
            triggerSubDataRefresh: false,
        };
    }

    componentDidMount () {}

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    handleCustomerDetailsChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState(
            prevState => ({
                customerDetails: {
                    ...prevState.customerDetails,
                    [targetName]: targetValue,
                },
            }),
            () => {
                if (isEqual(this.state.customerDetails, this.state.oldCustomerDetails)) {
                    this.setState({
                        changesMade: false,
                    });
                }
                else {
                    this.setState({
                        changesMade: true,
                    });
                }
            }
        );
    };

    handleDetailsChange = () => {
        if (this.state.changesMade) {
            this.setState(
                {
                    loadingButton: true,
                },
                () => {
                    const token = this.context.token ? this.context.token : localStorage.getItem('token');
                    const { REACT_APP_API_URL } = process.env;
                    const { id } = this.context.selectedOrganisation;
                    const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/subscriptions/update_customer/`;
                    const headers = {
                        Authorization: `Token ${token}`,
                    };
                    const customerData = {
                        company: this.state.customerDetails.organisation,
                        first_name: this.state.customerDetails.firstName,
                        last_name: this.state.customerDetails.lastName,
                        email: this.state.customerDetails.email,
                        address: this.state.customerDetails.addressLine1,
                        address2: this.state.customerDetails.addressLine2,
                        city: this.state.customerDetails.city,
                        state: this.state.customerDetails.state,
                        postcode: this.state.customerDetails.postcode,
                        country: this.state.customerDetails.country,
                    };
                    axios
                        .post(apiCallURL, customerData, { headers })
                        .then(() => {
                            this.setState(
                                {
                                    oldCustomerDetails: this.state.customerDetails,
                                    changesMade: false,
                                    loadingButton: false,
                                    triggerSubDataRefresh: true,
                                },
                                () => {
                                    this.displaySuccessMessage('Customer information successfully updated');
                                }
                            );
                        })
                        .catch(error => {
                            console.log(error.response);
                            this.setState(
                                {
                                    loadingButton: false,
                                },
                                () => {
                                    this.displayErrorMessage('There was a problem updating your details');
                                }
                            );
                        });
                }
            );
        }
    };

    createCountrySelectOptions = () =>
        this.state.countryCodeOptions.map(option => (
            <option key={option.id} value={option.name}>
                {option.name}
            </option>
        ));

    render () {
        const {
            changesMade,
            loadingButton,
            customerDetails,
            currentSuccessMessage,
            currentErrorMessage,
            triggerSubDataRefresh,
        } = this.state;

        return (
            <div className="modal-backdrop">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                )}
                {currentErrorMessage && (
                    <ErrorMessage message={currentErrorMessage} resetErrorMessage={this.resetErrorMessage} />
                )}
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button
                            className="modal-close-btn"
                            onClick={() => this.props.toggleModal(triggerSubDataRefresh)}
                        >
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">Edit your details</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="customer-details-modal-section">
                                    <p className="subscription-modal-header">Customer details</p>
                                    <form className="subscription-modal-customer-form">
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label
                                                    htmlFor="customer-details-organisation"
                                                    className="modal-input-label"
                                                >
                                                    {localiseText('Organisation')}/company name&nbsp;
                                                    <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-organisation"
                                                    value={customerDetails.organisation}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="organisation"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group-half">
                                                <label
                                                    htmlFor="customer-details-first-name"
                                                    className="modal-input-label"
                                                >
                                                    First name <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-first-name"
                                                    value={customerDetails.firstName}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="firstName"
                                                    type="text"
                                                />
                                            </div>
                                            <div className="modal-input-group-half">
                                                <label
                                                    htmlFor="customer-details-last-name"
                                                    className="modal-input-label"
                                                >
                                                    Surname <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-last-name"
                                                    value={customerDetails.lastName}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="lastName"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="customer-details-email" className="modal-input-label">
                                                    Email address <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-email"
                                                    value={customerDetails.email}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="email"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group-half">
                                                <label
                                                    htmlFor="customer-details-address-1"
                                                    className="modal-input-label"
                                                >
                                                    Address line 1 <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-address-1"
                                                    value={customerDetails.addressLine1}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="addressLine1"
                                                    type="text"
                                                />
                                            </div>
                                            <div className="modal-input-group-half">
                                                <label
                                                    htmlFor="customer-details-address-2"
                                                    className="modal-input-label"
                                                >
                                                    Address line 2
                                                </label>
                                                <input
                                                    id="customer-details-address-2"
                                                    value={customerDetails.addressLine2}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="addressLine2"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group-half">
                                                <label htmlFor="customer-details-city" className="modal-input-label">
                                                    Town/city <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-city"
                                                    value={customerDetails.city}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="city"
                                                    type="text"
                                                />
                                            </div>
                                            <div className="modal-input-group-half">
                                                <label htmlFor="customer-details-state" className="modal-input-label">
                                                    County/state <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-state"
                                                    value={customerDetails.state}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="state"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group-half">
                                                <label htmlFor="customer-details-country" className="modal-input-label">
                                                    Country <span className="required-field">*</span>
                                                </label>
                                                <select
                                                    id="customer-details-country"
                                                    className="input org-address-input upgrade-country-select"
                                                    name="country"
                                                    onChange={this.handleCustomerDetailsChange}
                                                    value={customerDetails.country}
                                                    required
                                                >
                                                    <option value="" disabled>
                                                        Select country
                                                    </option>
                                                    {this.createCountrySelectOptions()}
                                                </select>
                                            </div>
                                            <div className="modal-input-group-half">
                                                <label
                                                    htmlFor="customer-details-postcode"
                                                    className="modal-input-label"
                                                >
                                                    Postcode <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    id="customer-details-postcode"
                                                    value={customerDetails.postcode}
                                                    onChange={this.handleCustomerDetailsChange}
                                                    className="input modal-input"
                                                    name="postcode"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-btn-row">
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                            onClick={this.handleNewDefaultCard}
                                        >
                                            <img className="subscription-btn-loader" src={SmallLoader} alt="loader" />
                                        </div>
                                    ) : (
                                        <div
                                            role="button"
                                            className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                changesMade ? '' : 'disabled-primary-btn'
                                            }`}
                                            onClick={this.handleDetailsChange}
                                        >
                                            Save changes
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

EditDetailsModal.propTypes = {
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            customer: PropTypes.shape({
                organisation: PropTypes.string,
                firstName: PropTypes.string,
                lastName: PropTypes.string,
                email: PropTypes.string,
                addressLine1: PropTypes.string,
                addressLine2: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                postcode: PropTypes.string,
                country: PropTypes.string,
                company: PropTypes.string,
                first_name: PropTypes.string,
                last_name: PropTypes.string,
                address: PropTypes.string,
                address2: PropTypes.string,
            }).isRequired,

            statuses: PropTypes.shape({
                customer_status: PropTypes.string,
            }).isRequired,
        }).isRequired,
    }).isRequired,

    toggleModal: PropTypes.func.isRequired,
    updateSubdata: PropTypes.func,
};

EditDetailsModal.defaultProps = {
    subscriptionDetails: {
        details: {
            customer: {
                organisation: '',
                firstName: '',
                lastName: '',
                email: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: '',
                postcode: '',
                country: '',
            },
            statuses: {
                customer_status: '',
            },
        },
    },
    toggleModal: () => {},
};

export default EditDetailsModal;
