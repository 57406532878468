import React, { Component } from 'react';
import { ReactComponent as ProfileIcon } from '../../images/icons/person.svg';
import { ReactComponent as AcceptIcon } from '../../images/icons/tick.svg';
import { ReactComponent as DeclineIcon } from '../../images/icons/close.svg';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron_down.svg';
import { ReactComponent as EmailIcon } from '../../images/icons/email.svg';
import { ReactComponent as ReceivedIcon } from '../../images/icons/enter.svg';

class RequestToJoinMemberCard extends Component {
    constructor (props) {
        super(props);

        this.state = {
            requestToJoinMemberDetailsOpen: false,
        };
    }

    toggleRequestToJoinMemberDetails = () => {
        const { requestToJoinMemberDetailsOpen } = this.state;
        requestToJoinMemberDetailsOpen
            ? this.setState({
                requestToJoinMemberDetailsOpen: false,
            })
            : this.setState({
                requestToJoinMemberDetailsOpen: true,
            });
    };

    render () {
        const { requestToJoinMemberDetailsOpen } = this.state;
        return (
            <div className="team-member-card">
                <div className="team-member-card-top">
                    <div className="team-member-card-left">
                        {this.props.avatar ? (
                            <img
                                className="team-member-card-profile-img"
                                src={this.props.avatar}
                                alt="user-profile-avatar"
                            />
                        ) : (
                            <div className="team-member-card-profile-img-placeholder">
                                <ProfileIcon />
                            </div>
                        )}
                    </div>
                    <div className="team-member-card-right">
                        <p className="tm-card-name">{this.props.name}</p>
                        <div className="tm-card-btns">
                            <p onClick={this.toggleRequestToJoinMemberDetails}>
                                {requestToJoinMemberDetailsOpen ? 'Hide ' : 'View '}
                                details
                                <ChevronDown className={requestToJoinMemberDetailsOpen ? 'tm-cb-open-svg' : ''} />
                            </p>
                            {/* <p className="bullet">•</p>
                            <p onClick={this.toggleEditTeamMemberModal}>Edit</p> */}
                        </div>
                        {/* { this.props.role && <p className="tm-card-role">{this.props.role}</p>} */}
                        {/* <div className="team-member-card-priorities">
                            <p className="tm-card-priority">Editing</p>
                            <p className="tm-card-priority">Replying</p>
                        </div> */}
                    </div>
                </div>
                {requestToJoinMemberDetailsOpen && (
                    <div className="team-member-card-bottom">
                        <p className="tm-card-extra-details">
                            <EmailIcon />
                            {this.props.email}
                        </p>
                        <p className="tm-card-extra-details">
                            <ReceivedIcon />
                            {this.props.requestedAt}
                        </p>
                        {/* <p className="tm-card-extra-details">
                            <span className="tm-card-label">Requested at:</span> {this.props.requestedAt}
                        </p> */}
                        {/* {this.props.role && (
                            <p className="tm-card-extra-details">
                                <span className="tm-card-label">Role:</span> {this.props.role}
                            </p>
                        )} */}
                    </div>
                )}
                <div className={`tm-card-request-btns ${requestToJoinMemberDetailsOpen ? 'tm-card-request-btns-expanded' : ''}`}>
                    <button
                        onClick={() => this.props.replyRequestToJoin('accept', this.props.id)}
                        className="tm-crb-accept"
                    >
                        <AcceptIcon />
                        Accept
                    </button>
                    <button
                        onClick={() => this.props.replyRequestToJoin('decline', this.props.id)}
                        className="tm-crb-decline"
                    >
                        <DeclineIcon />
                        Decline
                    </button>
                </div>
            </div>
        );
    }
}

export default RequestToJoinMemberCard;