import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Confetti from 'react-dom-confetti';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import AppContext from '../app/AppContext';
import axios from 'axios';
import moment from 'moment';
import SummaryCard from './SummaryCard';
import Loader from '../helpers/loader';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import { mapCurrencyToSign } from '../../utils';

const confettiConfigRight = {
    angle: '135',
    spread: '100',
    startVelocity: 40,
    elementCount: 80,
    dragFriction: 0.08,
    duration: 5000,
    stagger: 5,
    width: '10px',
    height: '10px',
    perspective: '650px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const confettiConfigLeft = {
    angle: '55',
    spread: '100',
    startVelocity: 40,
    elementCount: 80,
    dragFriction: 0.08,
    duration: 5000,
    stagger: 5,
    width: '10px',
    height: '10px',
    perspective: '650px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const PlanCard = ({ plan, checked, onChange, canChangePayCycle, current }) => (
    <div className={`subscription-modal-radio-option
     ${checked ? 'smro-active' : ''}
     ${(!canChangePayCycle && !checked) ? 'smro-disabled' : ''}`}
    >
        <label className={`smro-label ${!canChangePayCycle && !checked ? 'smro-label-disabled' : ''}`}>
            <input className="smro-check" type="radio" checked={checked} value={plan.paycycle}
                onChange={canChangePayCycle ? onChange : () => {}}
            />
            <div className="smro-copy">
                <p className="smro-title">
                    {plan.paycycle_display} plan 
                    {current && 
                        <span className="smro-current-tag">
                            Current plan
                        </span>
                    }
                </p>
                <p className="smro-desc">
                    {plan.paycycle === 'A' ? 'Pay yearly' : 'Pay monthly'}
                    <span className="bullet">•</span>
                    {plan.paycycle === 'A' ? 'Our best value plan' : 'Our most flexible plan'}
                </p>
            </div>
            <div className="smro-price">
                <h2 className="spc-plan-title smro-price-title">
                    <span className="spc-pt-currency">{mapCurrencyToSign[plan.base_price_currency]}</span>
                    {plan.base_price}
                    <span className="spc-pt-month">{' '}/mo</span>
                </h2>
            </div>
        </label>
    </div>
);

PlanCard.propTypes = {
    plan: PropTypes.shape({
        paycycle_display: PropTypes.string,
        paycycle: PropTypes.string,
        base_price: PropTypes.string,
        base_price_currency: PropTypes.string,
    }),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    getTotalCost: PropTypes.func,
    canChangePayCycle: PropTypes.bool,
    current: PropTypes.bool
};

const Step1 = ({
    choosedPlan,
    plansDetails,
    setChoosedPlan,
    getTotalCost,
    canChangePayCycle,
    nextPaymentDate,
    subscriptionDetails,
    openEditDetailsModal,
    openNewPaymentMethodModal,
}) => (
    <div className="choose-payment-change-modal-section">
        {subscriptionDetails?.chargeover_id && (
            <div className="subscription-modal-warning-message">
                <p className="stm-copy">
                    <span className="stm-copy-highlight">
                        <span className="subscription-warning-icon">
                            <ExclamationIcon />
                        </span>
                        Note:
                    </span>{' '}
                    We are in the process of upgrading our payment system.
                     As a result, please contact us to make any changes to your subscription.
                </p>
            </div>
        )}
        {!subscriptionDetails?.details?.statuses?.can_change_paycycle && !subscriptionDetails?.chargeover_id && (
            <div className="subscription-modal-error-message">
                <p className="stm-copy">
                    <span className="stm-copy-highlight">
                        <span className="subscription-error-icon">
                            <ExclamationIcon />
                        </span>
                        Important:
                    </span>{' '}
                    You will not be able to change your payment plan 
                    until you are within the last 30 days of your annual paycycle 
                    (from {moment(nextPaymentDate).subtract(30, 'days').format('DD MMM YYYY')}).
                </p>
            </div>
        )}
        <p className="subscription-modal-header">Change your payment plan</p>
        {/* <p className="smro-build-plan-desc">
            First premium event <span>£{parseInt(choosedPlan.base_price)}</span>, 
            each additional premium event <span>£{parseInt(choosedPlan.extra_quantity_base_price)} </span> 
            ({choosedPlan.paycycle === 'A' ? 'Annual' : 'Monthly'}).
        </p> */}
        {plansDetails.map(plan => (
            <PlanCard 
                key={plan.paycycle} 
                plan={plan} 
                checked={choosedPlan.paycycle === plan.paycycle} 
                onChange={() => setChoosedPlan(plan)}
                getTotalCost={getTotalCost}
                canChangePayCycle={canChangePayCycle || !subscriptionDetails?.chargeover_id}
                current={subscriptionDetails.plan.paycycle === plan.paycycle}
            />
        ))}
        <div className={`change-plan-modal-links ${subscriptionDetails?.chargeover_id ? 'change-plan-links-disabled' : ''}`}>
            <p>
                <span 
                    role="button" 
                    onClick={!subscriptionDetails?.chargeover_id ? openNewPaymentMethodModal : ''}
                >Manage payment
                </span>
            </p>
            <span className="bullet">•</span>
            <p>
                <span 
                    role="button" 
                    onClick={!subscriptionDetails?.chargeover_id ? openEditDetailsModal : ''}
                >Update customer details
                </span>
            </p>
        </div>
    </div>
);

Step1.propTypes = {
    choosedPlan: PropTypes.shape({
        base_price: PropTypes.string,
        paycycle: PropTypes.string,
        extra_quantity_base_price: PropTypes.string,
    }),
    plansDetails: PropTypes.arrayOf(
        PropTypes.shape({
            paycycle: PropTypes.string,
        })
    ),
    setChoosedPlan: PropTypes.func,
    getTotalCost: PropTypes.func,
    canChangePayCycle: PropTypes.bool,
    nextPaymentDate: PropTypes.string,
    subscriptionDetails: PropTypes.shape({
        chargeover_id: PropTypes.string,
        plan: PropTypes.shape({
            paycycle: PropTypes.string
        }),
        details: PropTypes.shape({
            statuses: PropTypes.shape({
                can_change_paycycle: PropTypes.bool
            })
        }),
    }),
    openEditDetailsModal: PropTypes.func,
    openNewPaymentMethodModal: PropTypes.func
};

const Step2 = ({ 
    subscriptionDetails, 
    totalCost, 
    choosedPlan,
    trialStatus,
    previewProration
}) => {
    const [nextPaymentDate, setNextPaymentDate] = useState(subscriptionDetails.next_payment_date);


    useEffect(() => {
        const {
            next_payment_date
        } = subscriptionDetails;

        if (!trialStatus)
            setNextPaymentDate(moment());
        else
            setNextPaymentDate(next_payment_date);

        // eslint-disable-next-line
    }, [subscriptionDetails.plan.paycycle, subscriptionDetails.next_payment_date]);

    return (
        <>
            {!trialStatus && !previewProration ?
                <div className="sm-confirmation-loading">
                    <div className="section-loading-container sm-confirmation-loader">
                        <Loader />
                    </div>
                </div>
                :
                <>
                    {!trialStatus && 
                    <div className="change-plan-immediate-cost-modal-section">
                        <p className="subscription-modal-header">
                            Your plan change explained
                        </p>
                        {(subscriptionDetails.plan.paycycle === 'M' && choosedPlan.paycycle === 'A') ?
                            <p>
                                <span className="proration-value">{
                                    mapCurrencyToSign[previewProration?.currency]}{Math.abs(previewProration?.proration)
                                }
                                </span> has been deducted from the standard 
                               annual cost to account for the remaining days in your monthly paycycle.
                            </p>
                            :
                            <p>
                                <span className="proration-value">{
                                    mapCurrencyToSign[previewProration?.currency]}{Math.abs(previewProration?.proration)}
                                </span> 
                                has been deducted from the standard monthly cost
                                 to account for the remaining days in your annual paycycle.
                            </p>
                        }
                        <p>Please note that your payment cycle will also change to today&apos;s date.</p>
                    </div>
                    }
                    <div className={`change-plan-new-plan-modal-section 
                    ${trialStatus ? 'change-plan-new-plan-trial-modal-section' : ''}`}
                    >
                        <p className="subscription-modal-header">
                            Your new subscription summary
                        </p>
                        {!trialStatus ?
                            <SummaryCard 
                                subscriptionDetails={subscriptionDetails}
                                totalCost={previewProration?.total_amount}
                                paycycle={choosedPlan.paycycle}
                                customerDetails={subscriptionDetails.details.customer}
                                nextPaymentDate={nextPaymentDate}
                                currency={previewProration?.currency}
                                choosedPlan={choosedPlan}
                            />
                            :
                            <SummaryCard 
                                subscriptionDetails={subscriptionDetails}
                                totalCost={totalCost.toFixed(2)}
                                paycycle={choosedPlan.paycycle}
                                customerDetails={subscriptionDetails.details.customer}
                                nextPaymentDate={nextPaymentDate}
                                currency={subscriptionDetails?.subscription_total_price_wo_vat_currency}
                                choosedPlan={choosedPlan}
                            />
                        }
                    </div>
                </>
            }
        </>
    );
};

Step2.propTypes = {
    subscriptionDetails: PropTypes.shape({
        plan: PropTypes.shape({
            paycycle: PropTypes.string,
            paycycle_display: PropTypes.string,
            vat: PropTypes.number,
            plan_price: PropTypes.arrayOf(
                PropTypes.shape({
                    base_price: PropTypes.string,
                    base_price_currency: PropTypes.string,
                })
            )
        }),
        details: PropTypes.shape({
            customer: PropTypes.shape({
                country: PropTypes.string
            })
        }),
        next_payment_date: PropTypes.string,
        quantity: PropTypes.number,
        subscription_total_price_wo_vat_currency: PropTypes.string,
    }), 
    totalCost: PropTypes.number, 
    choosedPlan: PropTypes.shape({
        paycycle: PropTypes.string,
        base_price_currency: PropTypes.string,
        base_price: PropTypes.string
    }), 
    calculateProratedCost: PropTypes.func,
    trialStatus: PropTypes.bool,
    previewProration: PropTypes.shape({
        currency: PropTypes.string,
        total_amount: PropTypes.number,
        proration: PropTypes.number,
    })
};

const Step3 = ({ success, loaded }) => {

    if (!loaded) return (
        <div className="sm-confirmation-loading">
            <div className="section-loading-container sm-confirmation-loader">
                <Loader />
            </div>
            <p className="sm-loading-copy">Changing subscription plan</p>
        </div>
    );

    return (
        <>
            {success ? 
                <div className="sm-confirmation-text">
                    <p className="sm-confirmation-title">
                        Plan change completed
                    </p>
                    <p className="sm-confirmation-copy">
                        Your Racecheck Premium plan has successfully been changed.
                    </p>
                </div>
                :
                <div className="sm-confirmation-text">
                    <p className="sm-confirmation-title">Something went wrong</p>
                    <p className="sm-confirmation-copy">
                    There was a problem changing your plan. Please try again later or
                    contact our team at info@racecheck.com.
                    </p>
                </div>
            }
        </>
    );
};

Step3.propTypes = {
    success: PropTypes.bool,
    loaded: PropTypes.bool
};

const ChangePlanModal = ({ 
    getTotalCost,
    subscriptionDetails,
    subscriptionPlans,
    onClose,
    getFinalCost,
    toggleCancelSubscriptionModal,
    toggleEditDetailsModal,
    toggleNewPaymentMethodModal,
    trialStatus,
}) => {

    const plan = {
        paycycle: subscriptionDetails.plan.paycycle,
        paycycle_display: subscriptionDetails.plan.paycycle_display,
        vat: subscriptionDetails.plan.vat,
        base_price: subscriptionDetails.plan.plan_price[0].base_price,
        base_price_currency: subscriptionDetails.plan.plan_price[0].base_price_currency,
        id: subscriptionDetails.plan.id
    };
    const [choosedPlan, setChoosedPlan] = useState(plan);
    const [currentStep, setCurrentStep] = useState(0);
    const [totalCost, setTotalCost] = useState(undefined);
    const [loaded, setLoaded] = useState(true);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [triggerSubDataRefresh, setTriggerSubDataRefresh] = useState(false);
    const [confettiActive, setConfettiActive] = useState(false);
    const [previewProration, setPreviewProration] = useState(null);
    const plansDetails = subscriptionPlans.plans.premium[0].payment_details;

    const {
        selectedOrganisation = {},
        token = localStorage.getItem('token')
    } = useContext(AppContext);

    const { 
        REACT_APP_API_URL
    } = process.env;

    const { can_change_paycycle } = subscriptionDetails.details.statuses;
        
    const calculateProratedCost = () => {
        const oldPaymentCycle = subscriptionDetails.plan.paycycle;
        const selectedPaymentCycle = choosedPlan.paycycle;

        let baseProratedCost = 0;

        // Case: Different pay cycles - changing from monthly to annual
        if (
            oldPaymentCycle === 'M' &&
            selectedPaymentCycle === 'A'
        ) {
            baseProratedCost =
                choosedPlan.base_price * 12;
        }

        let totalProratedCost = baseProratedCost;
        if (subscriptionDetails.details.customer.country === 'United Kingdom') {
            totalProratedCost = baseProratedCost + (baseProratedCost * choosedPlan.vat) / 100;
        }

        return totalProratedCost.toFixed(2);
    };

    const continueAllowed = () => (
        can_change_paycycle && choosedPlan.paycycle !== subscriptionDetails.plan.paycycle
    );

    useEffect(() => {
        if (choosedPlan.id) {

            const { totalCost } = getFinalCost(
                choosedPlan.paycycle,
                choosedPlan.base_price,
                subscriptionDetails.details.customer.country
            );

            setTotalCost(totalCost);
        }
        // eslint-disable-next-line
    }, [choosedPlan]);

    const getPreviewProration = () => {
        // eslint-disable-next-line
         const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/subscriptions/${subscriptionDetails.id}/preview_proration/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
 
        axios
            .post(
                apiCallURL,
                {
                    plan_id: choosedPlan.id,
                },
                { headers }
            )
            .then(({ data }) => {
                setPreviewProration(data);
            })  
            .catch(err => {
                console.log(err);
            });
    };

    const handleContinue = () => {
        setCurrentStep(prevState => prevState + 1);
        const momentTrialEndDate = moment(subscriptionDetails.trial_ending_date, 'YYYY-MM-DD');
        if (!(momentTrialEndDate > new Date())) {
            getPreviewProration();
        }
    };

    const handleStepBack = () => {
        setCurrentStep(prevState => prevState - 1);
    };
    
    const handleSubmit = () => {

        // eslint-disable-next-line
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/subscriptions/${subscriptionDetails.id}/change_paycycle/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        setCurrentStep(2);
        setLoaded(false);

        axios
            .post(
                apiCallURL,
                {
                    plan_id: choosedPlan.id,
                },
                { headers }
            )
            .then(() => {
                setLoaded(true);
                setUpdateSuccess(true);
                setTriggerSubDataRefresh(true);
                setConfettiActive(true);
            })  
            .catch(err => {
                console.log(err);
                setUpdateSuccess(false);
                setLoaded(true);
                setTriggerSubDataRefresh(true);
            });
    };

    
    const openEditDetailsModal = () => {
        toggleEditDetailsModal();
        onClose();
    };

    const openNewPaymentMethodModal = () => {
        toggleNewPaymentMethodModal();
        onClose();
    };

    const handleCancel = (evt) => {
        evt.preventDefault();
        onClose(triggerSubDataRefresh);
    };
    
    return (
        <div className="modal-backdrop">
            <div className="confetti-container-right">
                <Confetti active={confettiActive} config={confettiConfigRight} />
            </div>
            <div className="confetti-container-left">
                <Confetti active={confettiActive} config={confettiConfigLeft} />
            </div>
            <div className="modal-padding" />
            <div className="change-plan-modal modal-container">
                <div className="modal-header">
                    <h2 className="modal-header-title">
                        Manage your plan
                    </h2>
                    <button className="modal-close-btn" onClick={handleCancel}>
                        <CloseIcon />
                    </button>
                </div>
                <div className="modal-scrollable">
                    <div className="modal-content">
                        <div className="modal-content-section">
                            {currentStep === 0 &&
                                <Step1
                                    totalCost={totalCost}
                                    getTotalCost={getTotalCost}
                                    canChangePayCycle={can_change_paycycle}
                                    subscriptionDetails={subscriptionDetails}
                                    choosedPlan={choosedPlan}
                                    plansDetails={plansDetails}
                                    setChoosedPlan={setChoosedPlan}
                                    nextPaymentDate={subscriptionDetails.next_payment_date}
                                    openEditDetailsModal={openEditDetailsModal}
                                    openNewPaymentMethodModal={openNewPaymentMethodModal}
                                    onClose={onClose}
                                />
                            }
                            {currentStep === 1 &&
                                <Step2 
                                    subscriptionDetails={subscriptionDetails}
                                    calculateProratedCost={calculateProratedCost}
                                    totalCost={totalCost}
                                    choosedPlan={choosedPlan}
                                    trialStatus={trialStatus}
                                    previewProration={previewProration?.data}
                                />
                            }
                            {currentStep === 2 && <Step3 success={updateSuccess} loaded={loaded} />}
                            {currentStep !== 2 &&
                                <div className="modal-btn-row sm-modal-btn-row">
                                    {currentStep !== 0 ?
                                        <button 
                                            className="btn modal-form-btn secondary-btn secondary-btn-rounded"
                                            onClick={handleStepBack}
                                        >
                                            Back
                                        </button>
                                        :
                                        <button 
                                            className="subscription-cancel-btn"
                                            onClick={toggleCancelSubscriptionModal}
                                        >
                                            Cancel subscription
                                        </button>
                                    }
                                    {currentStep === 1 ?
                                        <>
                                            {trialStatus ?
                                                <button
                                                    className="btn primary-btn modal-form-btn sub-fixed-width-btn"
                                                    onClick={handleSubmit}
                                                >
                                                Confirm plan change
                                                </button>
                                                :
                                                <button
                                                    className={`btn primary-btn modal-form-btn
                                                ${!previewProration?.data ? 'disabled-primary-btn' : ''}`}
                                                    onClick={handleSubmit}
                                                >
                                                Confirm change and pay
                                                </button>
                                            }
                                        </>
                                        :
                                        <button 
                                            className={`btn primary-btn modal-form-btn sub-fixed-width-btn
                                             ${continueAllowed() ? '' : 'disabled-primary-btn'}`}
                                            onClick={continueAllowed() ? handleContinue : () => {}}
                                        >
                                            Continue
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-padding" />
        </div>
    );
};

ChangePlanModal.propTypes = {
    availablePlans: PropTypes.shape({}),
    choosedPlan: PropTypes.shape({
        paycycle: PropTypes.string
    }),
    setChoosedPlan: PropTypes.func,
    getTotalCost: PropTypes.func,
    getCurrentPlan: PropTypes.func,
    plans: PropTypes.shape({
        plans: PropTypes.shape({
            premium: PropTypes.arrayOf(
                PropTypes.shape({
                    payment_details: PropTypes.arrayOf(
                        PropTypes.shape({
                            paycycle: PropTypes.string
                        })
                    )
                })
            )
        })
    }),
    setContinueAllowed: PropTypes.func,
    subscriptionDetails: PropTypes.shape({
        chargeover_id: PropTypes.string,
        trial_ending_date: PropTypes.string,
        plan: PropTypes.shape({
            id: PropTypes.number,
            paycycle: PropTypes.string,
            paycycle_display: PropTypes.string,
            vat: PropTypes.number,
            plan_price: PropTypes.arrayOf(
                PropTypes.shape({
                    base_price: PropTypes.string,
                    base_price_currency: PropTypes.string,
                })
            )
        }),
        details: PropTypes.shape({
            statuses: PropTypes.instanceOf(Object),
            customer: PropTypes.shape({
                country: PropTypes.string
            })
        }),
        next_payment_date: PropTypes.string,
        id: PropTypes.number,
        quantity: PropTypes.number,
        statuses: PropTypes.shape({
            can_change_paycycle: PropTypes.bool
        })
    }),
    subscriptionPlans: PropTypes.shape({
        plans: PropTypes.shape({
            premium: PropTypes.arrayOf(
                PropTypes.shape({
                    payment_details: PropTypes.instanceOf(Array),
                })
            )
        })
    }),
    onClose: PropTypes.func,
    getFinalCost: PropTypes.func,
    toggleCancelSubscriptionModal: PropTypes.func,
    toggleEditDetailsModal: PropTypes.func,
    toggleNewPaymentMethodModal: PropTypes.func,
    trialStatus: PropTypes.string
};

export default ChangePlanModal;
