import React, { Component } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import styles from './LandingPage.module.css';
import AppContext from '../app/AppContext';
import RCWAnimated from './landing-page-images/RCW-Animation.svg';
import { ReactComponent as MenuIcon } from '../../images/icons/menu.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';

class LandingPage extends Component {
    static contextType = AppContext;

    constructor () {
        super();

        this.state = {
            carouselIndex: 0,
            testimonialIndex: 0,
            mobileNavOpen: false,
            email: '',
        };
    }

    componentDidMount () {
        window.addEventListener('scroll', this.handleNavScroll);
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.handleNavScroll);
    }

    handleNavScroll () {
        try {
            const headerHeight = document.querySelector('header').offsetHeight;
            const scroll = this.scrollY;
            const header = document.querySelector('header');
            if (scroll > headerHeight - 30) {
                header.classList.add(styles.scrolled);
            }
            else {
                header.classList.remove(styles.scrolled);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    updateCarouselIndex = selectedIndex => {
        this.setState({
            carouselIndex: selectedIndex,
        });
    };

    updateTestimonialIndex = direction => {
        let currentIndex = this.state.testimonialIndex;
        if (direction === 'left') {
            if (currentIndex === 0) {
                currentIndex = 3;
            }
            else {
                currentIndex -= 1;
            }
            this.setState({
                testimonialIndex: currentIndex,
            });
        }
        else {
            if (currentIndex === 3) {
                currentIndex = 0;
            }
            else {
                currentIndex += 1;
            }
            this.setState({
                testimonialIndex: currentIndex,
            });
        }
    };

    toggleMobileNav = () => {
        this.setState({
            mobileNavOpen: !this.state.mobileNavOpen,
        });
    };

    handleEmailChange = e => {
        this.setState({
            email: e.target.value,
        });
    };

    render () {
        const { carouselIndex, mobileNavOpen, email, testimonialIndex } = this.state;
        AOS.init();

        return (
            <div className={styles.landing_page_container}>
                {/*eslint-disable*/}
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css"
                />
                {/* eslint-enable*/}
                <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet" />
                <header>
                    <nav className={`${styles.navbar} ${styles.navbar_expand_lg} ${styles.navbar_light}`}>
                        <a className={styles.navbar_brand} href="/">
                            <img
                                className={styles.img_responsive}
                                alt="Racecheck logo"
                                src={require('./landing-page-images/logo.png')}
                            />
                        </a>
                        <button
                            className={styles.navbar_toggler}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={this.toggleMobileNav}
                        >
                            {mobileNavOpen ? (
                                <CloseIcon className={styles.landing_page_menu_icon} />
                            ) : (
                                <MenuIcon className={styles.landing_page_menu_icon} />
                            )}
                        </button>

                        <div
                            className={`${styles.collapse} ${styles.navbar_collapse} ${
                                mobileNavOpen && styles.collapsed_nav
                            }`}
                            id="navbarSupportedContent"
                        >
                            <ul className={`${styles.navbar_nav} ${styles.ml_auto}`}>
                                <li className={styles.nav_item}>
                                    <a className={styles.nav_link} href="#benefits">
                                        Benefits
                                    </a>
                                </li>
                                <li className={styles.nav_item}>
                                    <a className={styles.nav_link} href="#pricing">
                                        Pricing
                                    </a>
                                </li>
                                <li className={`${styles.nav_item} ${styles.border_left_nav_item}`}>
                                    <Link to="/login" className={`${styles.nav_link} ${styles.mobile_log_in_btn}`}>
                                        Log in
                                    </Link>
                                </li>
                                <li className={`${styles.nav_item} ${styles.button_wrapper}`}>
                                    <Link
                                        to={{
                                            pathname: '/signup',
                                            state: {
                                                email,
                                            },
                                        }}
                                        className={`${styles.nav_link} ${styles.button}`}
                                    >
                                        Sign up
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>

                <section className={styles.banner_section}>
                    <div className={styles.container_fluid}>
                        <div className={styles.row}>
                            <div className={styles.image} data-aos="fade-up" data-aos-duration="700">
                                <img
                                    src={require('./landing-page-images/Hero-Image.png')}
                                    alt="Widget"
                                    className={styles.img_responsive}
                                />
                            </div>

                            <div
                                className={styles.headline}
                                data-aos="fade-up"
                                data-aos-duration="700"
                                data-aos-delay="300"
                            >
                                <h1>Harness the power of reviews</h1>
                            </div>

                            <div className={styles.supplemental_text}>
                                <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="600">
                                    Drive more traffic to your website and boost sales for your events.
                                </p>
                            </div>

                            <div
                                className={styles.hero_bottom}
                                data-aos="fade-up"
                                data-aos-duration="700"
                                data-aos-delay="900"
                            >
                                <input
                                    type="text"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={this.handleEmailChange}
                                />
                                <Link
                                    to={{
                                        pathname: '/signup',
                                        state: {
                                            email,
                                        },
                                    }}
                                    className={styles.button}
                                >
                                    Sign up for free
                                </Link>
                            </div>

                            <p
                                className={styles.header_small_text}
                                data-aos="fade-up"
                                data-aos-duration="700"
                                data-aos-delay="700"
                            >
                                Trusted by 1,000+ organisers having collected 40,000+ reviews.
                            </p>
                        </div>
                    </div>
                </section>

                <section className={styles.dashboard_management}>
                    <div className={styles.container}>
                        <div className={styles.heading}>
                            <h1 data-aos="fade-up" data-aos-duration="700" data-aos-delay="1000">
                                Your Racecheck Organiser <span>Dashboard</span>
                            </h1>
                            {carouselIndex === 0 && (
                                <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                    Insightful reports from your reviews are now only a click away.
                                </p>
                            )}
                            {carouselIndex === 1 && (
                                <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                    Reply, share and manage your reviews in a clean and organised manner.
                                </p>
                            )}
                            {carouselIndex === 2 && (
                                <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                    Transparent analytics that prove the widget’s value. Don’t take our word for it.
                                </p>
                            )}
                            {carouselIndex === 3 && (
                                <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                    Simple and easy to generate, customise and upload your widgets. No tech support
                                    required.
                                </p>
                            )}
                        </div>

                        <div className={styles.row}>
                            <div className={`${styles.carousel} ${styles.slide}`}>
                                <ol className={styles.carousel_indicators}>
                                    <li
                                        className={`${carouselIndex === 0 && styles.active}`}
                                        onClick={() => this.updateCarouselIndex(0)}
                                    >
                                        Event Analytics
                                    </li>
                                    <li
                                        className={`${carouselIndex === 1 && styles.active}`}
                                        onClick={() => this.updateCarouselIndex(1)}
                                    >
                                        Review Feed
                                    </li>
                                    <li
                                        className={`${carouselIndex === 2 && styles.active}`}
                                        onClick={() => this.updateCarouselIndex(2)}
                                    >
                                        Widget Analytics
                                    </li>
                                    <li
                                        className={`${carouselIndex === 3 && styles.active}`}
                                        onClick={() => this.updateCarouselIndex(3)}
                                    >
                                        Widget Setup
                                    </li>
                                </ol>
                                <div className={styles.carousel_inner}>
                                    <div className={`${styles.carousel_item} ${carouselIndex === 0 && styles.active}`}>
                                        <img
                                            className={`${styles.d_block} ${styles.w_100}`}
                                            src={require('./landing-page-images/Event-Analytics.png')}
                                            alt="Event analytics dashboard page"
                                        />
                                    </div>
                                    <div className={`${styles.carousel_item} ${carouselIndex === 1 && styles.active}`}>
                                        <img
                                            className={`${styles.d_block} ${styles.w_100}`}
                                            src={require('./landing-page-images/Review-Feed.png')}
                                            alt="Review feed dashboard page"
                                        />
                                    </div>
                                    <div className={`${styles.carousel_item} ${carouselIndex === 2 && styles.active}`}>
                                        <img
                                            className={`${styles.d_block} ${styles.w_100}`}
                                            src={require('./landing-page-images/Widget-Analytics.png')}
                                            alt="Widget analytics dashboard page"
                                        />
                                    </div>
                                    <div className={`${styles.carousel_item} ${carouselIndex === 3 && styles.active}`}>
                                        <img
                                            className={`${styles.d_block} ${styles.w_100}`}
                                            src={require('./landing-page-images/Widget-Setup-Image.png')}
                                            alt="Widget setup dashboard page"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.single_testimonial} ${styles.dark}`}>
                    <div className={styles.container}>
                        <div className={styles.row}>
                            <div
                                className={styles.image}
                                data-aos="fade-right"
                                data-aos-duration="700"
                                data-aos-delay="0"
                            >
                                <img
                                    src={require('./landing-page-images/Testimonial-Logo.jpeg')}
                                    alt="Company logo"
                                    className={styles.img_responsive}
                                />
                            </div>
                            <div className={styles.content}>
                                <div
                                    className={styles.quotation}
                                    data-aos="fade-up"
                                    data-aos-duration="700"
                                    data-aos-delay="300"
                                >
                                    Racecheck reviews and the subsequent award helped us grow the event by 25% the next
                                    year.
                                </div>
                                <div className={styles.author}>
                                    <p
                                        className={styles.name}
                                        data-aos="fade-up"
                                        data-aos-duration="700"
                                        data-aos-delay="600"
                                    >
                                        Brian Adcock
                                    </p>
                                    <p
                                        className={styles.designation}
                                        data-aos="fade-up"
                                        data-aos-duration="700"
                                        data-aos-delay="900"
                                    >
                                        Race Director of Castle Triathlon Series and Commando Series
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="benefits" className={styles.features_section}>
                    <div className={styles.container}>
                        <div className={styles.heading}>
                            <h1 data-aos="fade-up" data-aos-duration="700" data-aos-delay="1000">
                                Built specifically for
                                <span> race organisers</span>
                            </h1>
                            <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                Tools to strategically boost your traffic and increase entries.
                            </p>
                        </div>

                        <div className={styles.features_first}>
                            <div className={styles.row}>
                                <div
                                    className={`${styles.col_md_6} ${styles.col_sm_6} ${styles.col_12} ${styles.column}`}
                                >
                                    <div className={styles.features_wrapper}>
                                        <div className={styles.features_box}>
                                            <div className={styles.icon}>
                                                <img
                                                    src={require('./landing-page-images/icons/pre-event.png')}
                                                    alt="Calendar with star"
                                                    className={styles.img_responsive}
                                                />
                                            </div>
                                            <div className={styles.text}>
                                                <h3>Pre-event</h3>
                                                <p>
                                                    Showcase your reviews on your website via the Racecheck Widget to
                                                    drive more entries and keep 100% of your ticket margin.{' '}
                                                </p>
                                            </div>
                                        </div>

                                        <div className={styles.features_box}>
                                            <div className={styles.icon}>
                                                <img
                                                    src={require('./landing-page-images/icons/post-event.png')}
                                                    alt="Calendar with tick"
                                                    className={styles.img_responsive}
                                                />
                                            </div>
                                            <div className={styles.text}>
                                                <h3>Post-event</h3>
                                                <p>
                                                    Collect reviews on your website via the Racecheck Widget. Why drive
                                                    traffic elsewhere for reviews?
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={`${styles.col_md_6} ${styles.col_sm_6} ${styles.col_12} ${styles.column}`}
                                >
                                    <div className={styles.widget_image}>
                                        <object type="image/svg+xml" data={RCWAnimated}>
                                            svg-animation
                                        </object>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.row}>
                            <div className={`${styles.col_md_4} ${styles.col_sm_6} ${styles.col_12} ${styles.column}`}>
                                <div className={styles.features_box}>
                                    <div className={styles.icon}>
                                        <img
                                            src={require('./landing-page-images/icons/review-mangement.png')}
                                            alt="Review page"
                                            className={styles.img_responsive}
                                        />
                                    </div>
                                    <div className={styles.text}>
                                        <h3>Review Management</h3>
                                        <p>
                                            See all your reviews in a streamlined and organised feed. Reply to your
                                            participants, share reviews directly to your social media accounts and
                                            report any unreliable reviews!
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.col_md_4} ${styles.col_sm_6} ${styles.col_12} ${styles.column}`}>
                                <div className={styles.features_box}>
                                    <div className={styles.icon}>
                                        <img
                                            src={require('./landing-page-images/icons/analytics.png')}
                                            alt="Analytics page"
                                            className={styles.img_responsive}
                                        />
                                    </div>
                                    <div className={styles.text}>
                                        <h3>Analytics</h3>
                                        <p>
                                            Gain quick insight on how your event is improving year to year, what your
                                            participants really value and what you can improve on. Easy to digest
                                            analytics reports take away the guess work. Track how much interaction your
                                            widget is getting to prove its value
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.col_md_4} ${styles.col_sm_6} ${styles.col_12} ${styles.column}`}>
                                <div className={styles.features_box}>
                                    <div className={styles.icon}>
                                        <img
                                            src={require('./landing-page-images/icons/seo.png')}
                                            alt="Data search page"
                                            className={styles.img_responsive}
                                        />
                                    </div>
                                    <div className={styles.text}>
                                        <h3>SEO</h3>
                                        <p>
                                            Review text and star ratings are a goldmine for search engines to rank your
                                            website higher in search. The Racecheck Widget does wonders for your SEO and
                                            star ratings can even appear next to your Google search results as rich
                                            snippets, increasing CTRs by 10%!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className={styles.features_bottom}
                            data-aos="fade-up"
                            data-aos-duration="700"
                            data-aos-delay="900"
                        >
                            <input
                                type="text"
                                placeholder="Enter your email"
                                value={email}
                                onChange={this.handleEmailChange}
                            />
                            <Link
                                to={{
                                    pathname: '/signup',
                                    state: {
                                        email,
                                    },
                                }}
                                className={styles.button}
                            >
                                Sign up for free
                            </Link>
                        </div>
                    </div>
                </section>

                <section className={styles.single_testimonial}>
                    <div className={styles.container}>
                        <div className={styles.row}>
                            <div
                                className={styles.image}
                                data-aos="fade-right"
                                data-aos-duration="700"
                                data-aos-delay="0"
                            >
                                <img
                                    src={require('./landing-page-images/runSeries-black.png')}
                                    alt="Run Series logo"
                                    className={styles.img_responsive}
                                />
                            </div>
                            <div className={styles.content}>
                                <div
                                    className={styles.quotation}
                                    data-aos="fade-up"
                                    data-aos-duration="700"
                                    data-aos-delay="300"
                                >
                                    the new dashboard has been designed with the event organiser&apos;s needs in mind -
                                    a simple easy to use tool to immediately add immense marketing value.
                                </div>
                                <div className={styles.author}>
                                    <p
                                        className={styles.name}
                                        data-aos="fade-up"
                                        data-aos-duration="700"
                                        data-aos-delay="600"
                                    >
                                        David Kelly
                                    </p>
                                    <p
                                        className={styles.designation}
                                        data-aos="fade-up"
                                        data-aos-duration="700"
                                        data-aos-delay="900"
                                    >
                                        Race Director of Run Series
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="pricing" className={styles.pricing_table}>
                    <div className={styles.container}>
                        <div className={styles.row}>
                            <div className={styles.heading}>
                                <h1 data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                                    Our <span>Prices</span>
                                </h1>
                                <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                    Flexible pricing to match the needs of your organisation.
                                </p>
                            </div>

                            <div className={styles.table_wrapper}>
                                <div className={styles.stamp}>
                                    <span>Free Premium trial available for a limited time!</span>
                                </div>

                                <table className={styles.table}>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                FEATURES <hr />
                                            </th>
                                            <th scope="col">
                                                FREE <hr />
                                            </th>
                                            <th scope="col">
                                                PREMIUM <hr />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Pricing</td>
                                            <td>£0</td>
                                            <td>Starting from £39 a month</td>
                                        </tr>

                                        <tr>
                                            <td />
                                            <td>Great for smaller events</td>
                                            <td className={styles.stand_out}>
                                                Great for events with 200+ participants
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Collect reviews</td>
                                            <td>On Racecheck.com</td>
                                            <td>On your website via the widget</td>
                                        </tr>

                                        <tr>
                                            <td>Widget on site</td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Event Listings</td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Display reviews on widget</td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.uncheck}`}>
                                                    <i className="zmdi zmdi-close" />
                                                </div>
                                                <span>No</span>
                                            </td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Reviews Insight Reports</td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.uncheck}`}>
                                                    <i className="zmdi zmdi-close" />
                                                </div>
                                                <span>No</span>
                                            </td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Widget analytics</td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.uncheck}`}>
                                                    <i className="zmdi zmdi-close" />
                                                </div>
                                                <span>No</span>
                                            </td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>SEO Enhancement</td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.uncheck}`}>
                                                    <i className="zmdi zmdi-close" />
                                                </div>
                                                <span>No</span>
                                            </td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Marketing Toolkits</td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.uncheck}`}>
                                                    <i className="zmdi zmdi-close" />
                                                </div>
                                                <span>No</span>
                                            </td>
                                            <td>
                                                <div className={`${styles.icon} ${styles.check}`}>
                                                    <i className="zmdi zmdi-check" />
                                                </div>
                                                <span>Yes</span>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Support</td>
                                            <td>48hr Email</td>
                                            <td>Live</td>
                                        </tr>

                                        <tr>
                                            <td />
                                            <td>
                                                <Link
                                                    to={{
                                                        pathname: '/signup',
                                                        state: {
                                                            email: '',
                                                        },
                                                    }}
                                                    className={`${styles.button} ${styles.secondary}`}
                                                >
                                                    Get started
                                                </Link>
                                            </td>
                                            <td>
                                                <Link
                                                    to={{
                                                        pathname: '/signup',
                                                        state: {
                                                            email: '',
                                                        },
                                                    }}
                                                    className={styles.button}
                                                >
                                                    Get started
                                                </Link>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles.logo_section}>
                    <div className={styles.container}>
                        <div className={styles.heading} data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                            <h3>
                                <strong>Trusted by 1,000+ Organisers having collected 40,000+ reviews</strong>
                            </h3>
                        </div>

                        <div className={styles.row}>
                            <ul className={styles.logos_wrapper}>
                                <li className={styles.logo_slide}>
                                    <img
                                        src={require('./landing-page-images/logo1-grey.png')}
                                        alt="Logo"
                                        className={styles.img_responsive}
                                    />
                                </li>
                                <li className={styles.logo_slide}>
                                    <img
                                        src={require('./landing-page-images/logo2-grey.png')}
                                        alt="Logo"
                                        className={styles.img_responsive}
                                    />
                                </li>
                                <li className={styles.logo_slide}>
                                    <img
                                        src={require('./landing-page-images/logo3-grey.png')}
                                        alt="Logo"
                                        className={styles.img_responsive}
                                    />
                                </li>
                                <li className={styles.logo_slide}>
                                    <img
                                        src={require('./landing-page-images/logo4-grey.png')}
                                        alt="Logo"
                                        className={styles.img_responsive}
                                    />
                                </li>
                                <li className={styles.logo_slide}>
                                    <img
                                        src={require('./landing-page-images/logo5-grey.png')}
                                        alt="Logo"
                                        className={styles.img_responsive}
                                    />
                                </li>
                                <li className={styles.logo_slide}>
                                    <img
                                        src={require('./landing-page-images/logo6-grey.png')}
                                        alt="Logo"
                                        className={styles.img_responsive}
                                    />
                                </li>
                                <li className={styles.logo_slide}>
                                    <img
                                        src={require('./landing-page-images/logo7-grey.png')}
                                        alt="Logo"
                                        className={styles.img_responsive}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section className={styles.testimonial_section}>
                    <div className={styles.container}>
                        <div className={styles.heading}>
                            <h1 data-aos="fade-up" data-aos-duration="700" data-aos-delay="0">
                                Trusted by organisers <span>big and small</span>
                            </h1>
                            <p data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
                                Hear from a handful of the event organisers who already benefit from Racecheck&apos;s
                                services.
                            </p>
                        </div>

                        <div className={styles.row}>
                            <div className={`${styles.carousel} ${styles.slide}`}>
                                <div className={styles.carousel_inner}>
                                    <div
                                        className={`${styles.carousel_item} ${
                                            testimonialIndex === 0 && styles.testimonial_active_item
                                        }`}
                                    >
                                        <div className={styles.slide_box}>
                                            <div className={styles.content}>
                                                <div className={styles.quotation}>
                                                    <p>
                                                        “Racecheck reviews help us to showcase swimmers’ opinions to
                                                        those that have yet to experience a Henley Swim for themselves
                                                        is an incredibly useful tool”
                                                    </p>
                                                </div>
                                                <div className={styles.author}>
                                                    <div className={styles.text}>
                                                        <p className={styles.name}>Juliet Hulme</p>
                                                        <p className={styles.organization}>Henley Swim Race Director</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.image}>
                                                <img
                                                    src={require('./landing-page-images/rfb_testimonial1_henley.jpg')}
                                                    alt="People running"
                                                    className={styles.img_responsive}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`${styles.carousel_item} ${
                                            testimonialIndex === 1 && styles.testimonial_active_item
                                        }`}
                                    >
                                        <div className={styles.slide_box}>
                                            <div className={styles.content}>
                                                <div className={styles.quotation}>
                                                    <p>
                                                        “We use Racecheck reviews as a stamp of approval. In a really
                                                        crowded environment something this simple might make a
                                                        competitor choose to enter my event over another.”
                                                    </p>
                                                </div>
                                                <div className={styles.author}>
                                                    <div className={styles.text}>
                                                        <p className={styles.name}>Paul Albon</p>
                                                        <p className={styles.organization}>
                                                            Big Bear Events Race Director
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.image}>
                                                <img
                                                    src={require('./landing-page-images/rfb_testimonial2_bigbear.jpg')}
                                                    alt="People running"
                                                    className={styles.img_responsive}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`${styles.carousel_item} ${
                                            testimonialIndex === 2 && styles.testimonial_active_item
                                        }`}
                                    >
                                        <div className={styles.slide_box}>
                                            <div className={styles.content}>
                                                <div className={styles.quotation}>
                                                    <p>
                                                        “Racecheck reviews help us to understand if we need to make any
                                                        changes or improvements to our events. Every year we come up
                                                        with a list that we work from.”
                                                    </p>
                                                </div>
                                                <div className={styles.author}>
                                                    <div className={styles.text}>
                                                        <p className={styles.name}>Andy Hully</p>
                                                        <p className={styles.organization}>
                                                            MK Marathon Weekend Founder & Race Director
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.image}>
                                                <img
                                                    src={require('./landing-page-images/rfb_testimonial3_mk.jpg')}
                                                    alt="People running"
                                                    className={styles.img_responsive}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`${styles.carousel_item} ${
                                            testimonialIndex === 3 && styles.testimonial_active_item
                                        }`}
                                    >
                                        <div className={styles.slide_box}>
                                            <div className={styles.content}>
                                                <div className={styles.quotation}>
                                                    <p>
                                                        “Racecheck helps us by facilitating entirely honest and
                                                        independently managed — warts and all — feedback about how good
                                                        or how bad an event actually is.”
                                                    </p>
                                                </div>
                                                <div className={styles.author}>
                                                    <div className={styles.text}>
                                                        <p className={styles.name}>David Kelly</p>
                                                        <p className={styles.organization}>
                                                            Run Series Founder & Race Director
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={styles.image}>
                                                <img
                                                    src={require('./landing-page-images/rfb_testimonial4_runseries.jpg')}
                                                    alt="People running"
                                                    className={styles.img_responsive}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={styles.carousel_control_prev}
                                    onClick={() => this.updateTestimonialIndex('left')}
                                >
                                    <i className="zmdi zmdi-arrow-left" />
                                </button>
                                <button
                                    className={styles.carousel_control_next}
                                    onClick={() => this.updateTestimonialIndex('right')}
                                >
                                    <i className="zmdi zmdi-arrow-right" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

                <footer>
                    <div className={styles.container}>
                        <div className={styles.row}>
                            <div className={`${styles.col_md_3} ${styles.col_sm_4} ${styles.col_12}`}>
                                <div className={styles.footer_column}>
                                    <div className={styles.brand_logo}>
                                        <img
                                            src={require('./landing-page-images/logo.png')}
                                            alt="Brand Logo"
                                            className={styles.img_responsive}
                                        />
                                    </div>
                                    <div className={styles.address}>
                                        <p>
                                            Office 3. 09, Scott House Suite 1, The Concourse, Waterloo Station, London
                                            SE1 7LY, UK.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.col_md_6} ${styles.col_sm_4} ${styles.col_12}`}>
                                <div className={styles.footer_column}>
                                    <ul className={styles.useful_links}>
                                        <li>
                                            <a href="https://racecheck.com/about/">ABOUT US</a>
                                        </li>
                                        <li>
                                            <a href="https://racecheck.com/contact/">CONTACT US</a>
                                        </li>
                                        <li>
                                            <a href="https://racecheck.com/terms_conditions/">T&Cs</a>
                                        </li>
                                        <li>
                                            <a href="https://racecheck.com/privacy_policy/">PRIVACY POLICY</a>
                                        </li>
                                        <li>
                                            <a href="https://organisers.racecheck.com/">RACECHECK FOR ORGANISERS</a>
                                        </li>
                                        <li>
                                            <a href="https://racecheck.com/racecheck-for-developers/">
                                                RACECHECK FOR DEVELOPERS
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className={`${styles.col_md_3} ${styles.col_sm_4} ${styles.col_12}`}>
                                <div className={styles.footer_column}>
                                    <ul className={styles.social_links}>
                                        <li>
                                            <a href="https://twitter.com/racecheck">
                                                <i className="zmdi zmdi-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/racecheck/">
                                                <img
                                                    src={require('./landing-page-images/icons/instagram.svg')}
                                                    alt="Icon"
                                                />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.facebook.com/racecheck">
                                                <i className="zmdi zmdi-facebook" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className={styles.copyright_text}>
                            <p>Copyright &copy; 2021 Racecheck</p>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default LandingPage;
