import React from 'react';
import classnames from 'classnames';

const ReviewImageGeneratorStep3 = ({ backgroundOptions, selectedBackground, setSelectedBackground }) => (
    <>
        <p className="rig-section-header">Select image background</p>
        <div className="rig-backgrounds-list" >
            { backgroundOptions.map(option => (
                <div
                    key={option.id}
                    className={classnames('rig-bo-container', { 'rig-bo-selected': selectedBackground.id === option.id })}
                    onClick={() => {
                        setSelectedBackground(option);
                    }}
                >
                    <div className={`rig-background-option rig-bo-${option.theme} rig-bo-${option.style}`} />
                </div>
            ))}
        </div>
    </>
);

export default ReviewImageGeneratorStep3;