import axios from 'axios';

export const hubspotFormsApiRequest = (formId, data) => {
    // eslint-disable-next-line max-len
    const hubspotURL = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.REACT_APP_HUBSPOT_PORTAL_ID}/${formId}`;

    axios
        .post(hubspotURL, data, {
            headers: { 'Content-Type': 'application/json' },
        })
        .catch(error => {
            console.log('Hubspot Form API error');
            console.log(error);
        });
};
