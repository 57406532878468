import React, { useEffect, useState, useContext, useMemo } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';

import AppContext from '../app/AppContext';
import RatingStars from '../helpers/starGenerator';
import { ordinalSuffixOf } from '../../utils';
import Loader from '../helpers/loader';

import Chatbubble from '../../images/icons/chatbubble.svg';
import ChatbubbleEllipses from '../../images/icons/chatbubble-ellipses.svg';
import Chatbubbles from '../../images/icons/chatbubbles.svg';
import StatsChart2 from '../../images/icons/stats-chart-2.svg';
import GitMerge from '../../images/icons/git-merge.svg';
import ThumbDown from '../../images/icons/thumbs-down.svg';
import List from '../../images/icons/list-outline.svg';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as BulbIcon } from '../../images/icons/bulb.svg';
import { ReactComponent as ChevronRightIcon } from '../../images/icons/chevron-right.svg';
import { ReactComponent as InfoIcon } from '../../images/icons/alert.svg';
// import { ReactComponent as WarningIcon } from '../../images/icons/warning-triangle.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/icons/arrow_right.svg';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    AnnotationPlugin,
    ChartDataLabels
);
  
let labels = [];

const EventReviews = ({ event, period, setIsComparativeAnalysisLoading, setIsReviewAnalyticsLoading }) => {
    
    const appContext = useContext(AppContext);

    const [reviewAnalytics, setReviewAnalytics] = useState(null);
    const [comparativeAnalysis, setComparativeAnalysis] = useState(null);

    const getComparativeAnalytics = () => {
        const { id } = appContext.selectedOrganisation;
        
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${id}/events/${event?.id}/event_comparative_analysis/`;
        const headers = {
            Authorization: `Token ${appContext.token}`,
        };

        axios.get(apiCallURL, { headers })
            .then(({ data: { data } }) => {
                setComparativeAnalysis(data);
            })
            .finally(() => setIsComparativeAnalysisLoading(false));
    };

    useEffect(() => {
        const { id } = appContext.selectedOrganisation;
        
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${id}/events/${event?.id}/event_review_analytics/?period=${period}`;
        const headers = {
            Authorization: `Token ${appContext.token}`,
        };

        if (event?.id) {
            axios.get(apiCallURL, { headers })
                .then(({ data: { data } }) => {
                    setReviewAnalytics(data);
                })
                .finally(() => setIsReviewAnalyticsLoading(false));
        }
        // eslint-disable-next-line
    }, [event?.id, period]);

    useEffect(() => {
        if (event?.id) {
            getComparativeAnalytics();
        }
        // eslint-disable-next-line
    }, [event?.id]);

    const data = useMemo(() => {
        const totalEvents = comparativeAnalysis?.comparative_analysis?.total_events;
        labels = comparativeAnalysis?.comparative_analysis?.labels || labels;
        return {
            labels,
            datasets: [
                {
                    label: 'Percentage of events',
                    data: [
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p75_to_5 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p65_to_4p74 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p55_to_4p64 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4_to_4p54 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_1_to_4 / totalEvents * 100).toFixed(2)
                    ],
                    datalabels: {
                        color: '#9B9B9B',
                        anchor: 'end',
                        align: 'end',
                        offset: 3,
                        formatter (value) {
                            return (Math.round(value * 10) / 10).toFixed(1) + '%';
                        }
                    },
                    options: {
                        animation: {
                            duration: 0
                        }
                    },
                    borderColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'],
                    backgroundColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'],
                    borderRadius: 5,
                    barPercentage: .9,
                    categoryPercentage: 1,
                }
            ],
        };
        // eslint-disable-next-line
    },[reviewAnalytics, comparativeAnalysis]);

    const dataMobile = useMemo(() => {
        const totalEvents = comparativeAnalysis?.comparative_analysis?.total_events;
        labels = comparativeAnalysis?.comparative_analysis?.labels || labels;
        return {
            labels: [...labels].reverse(),
            datasets: [
                {
                    label: 'Percentage of events',
                    data: [
                        (comparativeAnalysis?.comparative_analysis?.events_rated_1_to_4 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4_to_4p54 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p55_to_4p64 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p65_to_4p74 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p75_to_5 / totalEvents * 100).toFixed(2)
                    ],
                    datalabels: {
                        color: '#9B9B9B',
                        anchor: 'end',
                        align: 'end',
                        offset: 3,
                        formatter (value) {
                            return (Math.round(value * 10) / 10).toFixed(1) + '%';
                        }
                    },
                    options: {
                        animation: {
                            duration: 0
                        }
                    },
                    borderColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'].reverse(),
                    backgroundColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'].reverse(),
                    borderRadius: 5,
                    barPercentage: .9,
                    categoryPercentage: 1,
                }
            ],
        };
        // eslint-disable-next-line
    },[reviewAnalytics, comparativeAnalysis]);

    const options = useMemo(() => {
        const totalAverageRating = reviewAnalytics?.event_review_analytics?.event_reviews?.total_average_rating;
        const eventPercentile = comparativeAnalysis?.comparative_analysis?.event_percentile;
        const convertedLabels = labels.map(label => [+label.split('-')[0], +label.split('-')[1]]);
        const indexOfAverageRating = convertedLabels?.reduce((acc, [min, max], index) => {
            if (totalAverageRating >= min && totalAverageRating <= max) {
                return -0.25 + index + (max - totalAverageRating) / (max - min) * 0.5;
            }
            return acc;
        }, 0);

        return {
            indexAxis: 'y',
            reponsive: true,
            elements: {
                bar: {
                    borderWidth: 1
                },
            },
            scales: {
                x: {
                    max: 70,
                    min: 0,
                    title: {
                        display: true,
                        color: '#58585892',
                        text: '% of similar events',
                        padding: 5,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                        callback (value) {
                            return value + '%';
                        }
                    }
                },
                y: {
                    title: {
                        display: true,
                        color: '#58585892',
                        text: 'Avg. Rating',
                        padding: 10,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                    }
                },
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                    display: false
                },
                title: {
                    display: false,
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            yMin: indexOfAverageRating,
                            yMax: indexOfAverageRating,
                            borderColor: '#BCBCBCBD',
                            borderWidth: 1,
                            font: {
                                size: 20
                            },
                            borderDash: [4, 3]
                        },
                        label1: {
                            type: 'label',
                            yValue: indexOfAverageRating,
                            xValue: 62,
                            content: [`Top ${eventPercentile}%`],
                            color: '#3E3E3E',
                            font: {
                                size: 17,
                                weight: 'bold'
                            },
                            textAlign: 'end',
                            yAdjust: 11,
                            xAdjust: -2
                        },
                        label2: {
                            type: 'label',
                            yValue: indexOfAverageRating,
                            xValue: 62,
                            content: ['This event'],
                            color: '#58585874',
                            font: {
                                size: 12
                            },
                            textAlign: 'end',
                            yAdjust: -8,
                            xAdjust: 5
                        }
                    }
                }
            },
        };
    }, [reviewAnalytics, comparativeAnalysis]);

    const optionsMobile = useMemo(() => 
    // const totalAverageRating = reviewAnalytics?.event_review_analytics?.event_reviews?.total_average_rating;
    // const eventPercentile = comparativeAnalysis?.comparative_analysis?.event_percentile;
    // const convertedLabels = labels.map(label => [+label.split('-')[0], +label.split('-')[1]]);
    // const indexOfAverageRating = convertedLabels?.reduce((acc, [min, max], index) => {
    //     if (totalAverageRating >= min && totalAverageRating <= max) {
    //         return -0.25 + index + (max - totalAverageRating) / (max - min) * 0.5;
    //     }
    //     return acc;
    // }, 0);

        ({
            indexAxis: 'x',
            reponsive: true,
            maintainAspectRatio: false,
            elements: {
                bar: {
                    borderWidth: 1
                },
            },
            scales: {
                x: {
                    max: 70,
                    min: 0,
                    title: {
                        display: true,
                        color: '#58585892',
                        text: 'Avg. Rating',
                        padding: 10,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                    }
                },
                y: {
                    min: 0,
                    max: 100,
                    title: {
                        display: true,
                        color: '#58585892',
                        text: '% of similar events',
                        padding: 5,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                    }
                },
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                    display: false
                },
                title: {
                    display: false,
                },
                // annotation: {
                //     annotations: {
                //         line1: {
                //             type: 'line',
                //             xMin: indexOfAverageRating,
                //             xMax: indexOfAverageRating,
                //             borderColor: '#BCBCBCBD',
                //             borderWidth: 1,
                //             font: {
                //                 size: 20
                //             },
                //             borderDash: [4, 3]
                //         },
                //         label1: {
                //             type: 'label',
                //             yValue: 50,
                //             xValue: 2,
                //             content: [`Top ${eventPercentile}%`],
                //             color: '#3E3E3E',
                //             font: {
                //                 size: 17,
                //                 weight: 'bold'
                //             },
                //             textAlign: 'end',
                //             yAdjust: 3,
                //             xAdjust: 3
                //         },
                //         label2: {
                //             type: 'label',
                //             // yValue: 55,
                //             xValue: 2,
                //             content: ['This event'],
                //             color: '#58585874',
                //             font: {
                //                 size: 12
                //             },
                //             textAlign: 'end',
                //             // yAdjust: -8,
                //             // xAdjust: 5
                //         }
                //     }
                // }
            },
        })
    , []);

    if (!reviewAnalytics) return null;

    return (
        <>
            <div className="mr-section-group-container">
                <div className="mr-section-bottom-info mr-download-hide">
                    <div className="mr-info-left">
                        <BulbIcon />
                        <p>To dive deeper into the reviews for a given year, view your event’s ‘Overall Breakdown’ report.</p>
                    </div>
                    <Link className="btn secondary-btn secondary-btn-rounded" to={`/events/${event?.id}/analytics/event`}>
                        View the report
                        <ArrowRightIcon />
                    </Link>
                </div>
                <div className="mr-section-container">
                    <h2 className="mr-section-title">Event Reviews</h2>
                    <p className="mr-section-intro">
                        How many new reviews you’ve received and how they&apos;ve impacted your rating.
                    </p>
                    <div className="mr-data-row">
                        <div className="mr-stat-container">
                            <img alt="cloud" src={Chatbubble} />
                            <p className="mr-stat">{
                            reviewAnalytics?.event_review_analytics?.event_reviews?.new_reviews.toLocaleString('en-US')}
                            </p>
                            <p className="mr-stat-title">
                                <span>
                                    New reviews
                                    <HelpIcon className="mr-download-hide" />
                                </span>
                                <div className="mr-title-tooltip">
                                    <span className="mr-tooltip-desc">
                                    The number of reviews your event has received in the selected time period.
                                    </span>
                                </div>
                            </p>
                        </div>
                        <ChevronRightIcon className="mr-chevron-right" />
                        <div className="mr-stat-container">
                            <img alt="rating" src={StatsChart2} />
                            <p className="mr-stat mr-stat-stars">
                                {reviewAnalytics?.event_review_analytics?.event_reviews?.total_average_rating}
                                <RatingStars 
                                    rating={reviewAnalytics?.event_review_analytics?.event_reviews?.total_average_rating}
                                />
                            </p>
                            <p className="mr-stat-title">
                                <span>
                                    Avg. Rating
                                    <HelpIcon className="mr-download-hide" />
                                </span>
                                <div className="mr-title-tooltip">
                                    <span className="mr-tooltip-desc">
                                    Your events average rating, generated from all reviews left on the Racecheck platform.
                                    </span>
                                </div>
                            </p>
                        </div>
                        <ChevronRightIcon className="mr-chevron-right" />
                        <div className="mr-stat-container">
                            <img alt="ratio" src={Chatbubbles} />
                            <p className="mr-stat">{
                            reviewAnalytics?.event_review_analytics?.event_reviews?.total_reviews.toLocaleString('en-US')
                            }
                            </p>
                            <p className="mr-stat-title">
                                <span>
                                    Total reviews
                                    <HelpIcon className="mr-download-hide" />
                                </span>
                                <div className="mr-title-tooltip">
                                    <span className="mr-tooltip-desc">
                                    The total number of reviews that have been left for your event across all years.
                                    </span>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className="mr-section-group-container">
                <div className="mr-section-container">
                    <h2 className="mr-section-title">Comparative Analysis</h2>
                    <p className="mr-section-intro">
                        How your rating compares to other events on the Racecheck platform.
                    </p>
                    {comparativeAnalysis ?
                        <div className="mr-data-row">
                            <div className="mr-stat-container mr-chart-container">
                                <Bar options={options} data={data} />
                            </div>
                            <div className="mr-stat-container mr-chart-container mobile">
                                <Bar options={optionsMobile} data={dataMobile} height={null} width={null} />
                            </div>
                            <ChevronRightIcon className="mr-chevron-right" />
                            <div className="mr-stat-container mr-stat-container-vertical-center">
                                <img alt="ratio" src={List} />
                                <p className="mr-stat">{
                                    ordinalSuffixOf(comparativeAnalysis?.comparative_analysis?.category_rank)}
                                </p>
                                <p className="mr-stat-title">
                                    <span>
                                        Best {comparativeAnalysis?.comparative_analysis?.category} Event
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                        How your event compares to others in your category.
                                         Rankings are weighted based on both number of reviews and average rating.
                                        </span>
                                    </div>
                                </p>
                            </div>
                        </div>
                        :
                        <div className="mr-section-loader">
                            <Loader />
                        </div>
                    }
                </div>
            </div>

            <div className="mr-section-group-container">
                <div className="mr-section-bottom-info mr-download-hide">
                    <div className="mr-info-left">
                        <BulbIcon />
                        <p>Responding to reviews shows participants that you are engaged
                             and committed to providing a great experience.
                        </p>
                    </div>
                    <Link className="btn secondary-btn secondary-btn-rounded" to={`/events/${event?.id}/reviews?unreplied=true`}>
                        Reply to reviews
                        <ArrowRightIcon />
                    </Link>
                </div>
                <div className="mr-section-container">
                    <h2 className="mr-section-title">Review Replies</h2>
                    <p className="mr-section-intro">
                        How many of your reviews have been replied to.
                    </p>
                    <div className="mr-data-row">
                        <div className="mr-stat-container">
                            <img alt="reply" src={ChatbubbleEllipses} />
                            <p className="mr-stat">{
                            reviewAnalytics?.event_review_analytics?.review_replies?.unreplied_reviews.toLocaleString('en-US')}
                            </p>
                            <p className="mr-stat-title">
                                <span>
                                    Unreplied Reviews
                                    <HelpIcon className="mr-download-hide" />
                                </span>
                                <div className="mr-title-tooltip">
                                    <span className="mr-tooltip-desc">
                                    How many of the reviews for your event are still waiting to be replied to.
                                    </span>
                                    <div className="mr-tooltip-extra-row">
                                        <InfoIcon className="mr-icon-info" />
                                        <span className="mr-tooltip-desc">
                                        You want this to be 0.
                                        </span>
                                    </div>
                                </div>
                            </p>
                        </div>
                        <ChevronRightIcon className="mr-chevron-right" />
                        <div className="mr-stat-container">
                            <img alt="ratio" src={GitMerge} />
                            <p className="mr-stat">{
                            (reviewAnalytics?.event_review_analytics?.review_replies?.reply_ratio)?.toFixed(2)}%
                            </p>
                            <p className="mr-stat-title">
                                <span>
                                    Reply Ratio
                                    <HelpIcon className="mr-download-hide" />
                                </span>
                                <div className="mr-title-tooltip">
                                    <span className="mr-tooltip-desc">
                                    The percentage of your reviews which have received a reply.
                                    </span>
                                    <div className="mr-tooltip-extra-row">
                                        <InfoIcon className="mr-icon-info" />
                                        <span className="mr-tooltip-desc">
                                        Try to get this to 100%!
                                        </span>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mr-section-group-container">
                <div className="mr-section-bottom-info mr-download-hide">
                    <div className="mr-info-left">
                        <BulbIcon />
                        <p>A response to a negative review can turn something potentially damaging
                             into a valuable interaction. All responses are worthwhile, but especially these ones.
                        </p>
                    </div>
                    <Link 
                        className="btn secondary-btn secondary-btn-rounded" 
                        to={`/events/${event?.id}/reviews?selectedSorting=lowest-rated-first&unreplied=true`}
                    >
                    Start responding
                        <ArrowRightIcon />
                    </Link>
                </div>
                <div className="mr-section-container">
                    <h2 className="mr-section-title">Negative Reviews</h2>
                    <p className="mr-section-intro">
                    Respond to constructive feedback and turn negative reviews into an asset.
                    </p>
                    <div className="mr-data-row">
                        <div className="mr-stat-container">
                            <img alt="negative" src={ThumbDown} />
                            <p className="mr-stat">{
                            reviewAnalytics?.event_review_analytics?.negative_reviews?.negative_reviews.toLocaleString('en-US')}
                            </p>
                            <p className="mr-stat-title">
                                <span>
                                    Negative Reviews
                                    <HelpIcon className="mr-download-hide" />
                                </span>
                                <div className="mr-title-tooltip">
                                    <span className="mr-tooltip-desc">
                                    A negative review is one which has a rating of 2.99 and below.
                                    </span>
                                </div>
                            </p>
                        </div>
                        <ChevronRightIcon className="mr-chevron-right" />
                        <div className="mr-stat-container">
                            <img alt="ratio" src={ChatbubbleEllipses} />
                            {/* eslint-disable-next-line max-len */}
                            <p className="mr-stat">{reviewAnalytics?.event_review_analytics?.negative_reviews?.unreplied_negative_reviews.toLocaleString('en-US')}</p>
                            <p className="mr-stat-title">
                                <span>
                                    Unreplied Negative Reviews
                                    <HelpIcon className="mr-download-hide" />
                                </span>
                                <div className="mr-title-tooltip">
                                    <span className="mr-tooltip-desc">
                                    How many of your negative reviews are requiring a reply.
                                    </span>
                                    <div className="mr-tooltip-extra-row">
                                        <InfoIcon className="mr-icon-info" />
                                        <span className="mr-tooltip-desc">
                                        You want this to be 0.
                                        </span>
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventReviews;

EventReviews.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.number
    }), 
    period: PropTypes.number,
    setIsComparativeAnalysisLoading: PropTypes.func,
    setIsReviewAnalyticsLoading: PropTypes.func,
};