import React from 'react';
import PropTypes from 'prop-types';
import './starGenerator.css';
import { ReactComponent as FullStar } from '../../images/stars/gold_star.svg';
import { ReactComponent as HalfStar } from '../../images/stars/gold_star_half.svg';
import { ReactComponent as EmptyStar } from '../../images/stars/gold_star_empty.svg';

export default function RatingStars (props) {
    const { rating } = props;
    const stars = [];
    for (let i = 1; i < 6; i++) {
        if (rating >= i) {
            stars.push(<FullStar key={i} />);
        }
        else if (rating < i && rating >= i - 0.75) {
            if (rating <= i - 0.25) {
                stars.push(<HalfStar key={i} />);
            }
            else {
                stars.push(<FullStar key={i} />);
            }
        }
        else if (rating < i && rating >= i - 0.25) {
            stars.push(<FullStar key={i} />);
        }
        else {
            stars.push(<EmptyStar key={i} />);
        }
    }
    return (
        <div className="rating-stars">
            <p className="rating-value">
                {props.rating}
                {/* <span className="rating-out-of">/5</span> */}
            </p>
            {stars}
        </div>
    );
}

RatingStars.propTypes = {
    rating: PropTypes.number,
};
