import { usePageView, useUsermaven } from './useUsermaven';
import { useContext } from 'react';
import AppContext from '../app/AppContext';


const Usermaven = () => {
    const context = useContext(AppContext);
    const { usermaven } = useUsermaven({
        key: process.env.REACT_APP_USERMAVEN_ID,
        tracking_host: 'https://events.usermaven.com',
    });
    usePageView(usermaven);

    if (context?.loggedIn && context?.user?.id) {
        const { user, selectedOrganisation } = context;
        const payload = {
            id: user?.id,
            email: user?.email,
            created_at: user?.date_joined,
            first_name: user?.first_name,
            last_name: user?.last_name
        };

        if (selectedOrganisation?.id) {
            payload.company = {
                id: selectedOrganisation?.id,
                name: selectedOrganisation?.name,
                custom: {
                    premium: selectedOrganisation?.is_premium_subscriber
                }
            };
        }
        usermaven.id(payload);
    }

    return null;
};

export default Usermaven;
