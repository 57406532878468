import React from 'react';
import './cookieBanner.css';
import PropTypes from 'prop-types';

const CookieBanner = ({ setCookieResponse }) => (
    <div className="cookie-banner modal" id="cookieBannerContainer">
        <div className="cookie-banner-content">
            <h1>Allow Racecheck to use cookies on this browser?</h1>
            <p>
                We use cookies to help enhance and improve your experience on the Racecheck platform.
            </p>
            <ul>
                <li>
                    Essential cookies: These cookies are necessary and are required in order for the 
                    Racecheck platform to work as intended.
                </li>
                <li>
                    Optional cookies: These cookies (from us and the companies we integrate with) are used 
                    to help us understand and analyse traffic on the Racecheck platform in order to ensure 
                    we are giving you the best, and most personal, experience we can.
                </li>
            </ul>
            <p>
                You can decide whether to allow the use of optional cookies. To learn more about how Racecheck 
                uses cookies, or to change your decision in the future, you can view our&nbsp;
                <a href="https://racecheck.com/cookie_policy/" target="_blank" rel="noopener noreferrer">
                    Cookie Policy
                </a>. For further information about your data, please refer to our&nbsp;
                <a href="https://racecheck.com/privacy_policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>.
            </p>
            <div className="cookie-banner-btns">
                <button
                    className="btn secondary-btn secondary-btn-rounded"
                    id="cookie-banner-btn-refuse"
                    onClick={() => setCookieResponse(0)}
                >
                    Decline optional cookies
                </button>
                <button
                    className="btn primary-btn"
                    id="cookie-banner-btn-accept"
                    onClick={() => setCookieResponse(1)}
                >
                    Allow all cookies
                </button>
            </div>
        </div>
    </div>
);

CookieBanner.propTypes = {
    setCookieResponse: PropTypes.func,
};

export default CookieBanner;
