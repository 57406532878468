import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import './AnalyticsSection.css';
import AppContext from '../app/AppContext';
import SectionNav from '../organiserDashboard/SectionNav';
import ErrorBoundary from '../helpers/errorBoundary';
import EventsReport from './EventsReport';

const Analytics = () => {
    const appContext = useContext(AppContext);

    return (
        <div className={`section-container ${!appContext.menuOpen && 'section-container-main-nav-closed'}`}>
            <SectionNav section="analytics" />
            <ErrorBoundary>
                <div
                    className={'section-content section-content-no-bottom-pad'}
                >
                    <Route path="/analytics/events-report">
                        <EventsReport />
                    </Route>
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default Analytics;