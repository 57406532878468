import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import AppContext from '../app/AppContext';

export default function PremiumFeaturePlaceholder () {
    const appContext = useContext(AppContext);
    const mpProps = appContext.selectedOrganisation ? {
        organisation: appContext.selectedOrganisation?.name,
        premium_subscriber: appContext.selectedOrganisation?.is_premium_subscriber
    } : {};

    return (
        <div className="no-data-container">
            <p className="no-data-header">Not available for Free accounts</p>
            <p className="no-data-copy">
                Upgrade your account to Premium to gain access to a wide range of additional features.
            </p>
            <Link to="/subscription/subscription-plan" className="no-text-decoration"
                onClick={() => {
                    /* eslint-disable-next-line */
                      mixpanel.track('Dashboard - Premium Feature - Upgrade to Premium click', mpProps);
                }}
            >
                <div className="btn primary-btn modal-form-btn no-data-add-event-btn">Upgrade to Premium</div>
            </Link>
        </div>
    );
}
