import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import AppContext from '../app/AppContext';
import SuccessMessage from '../helpers/successMessage';
import axios from 'axios';
import moment from 'moment';
import Confetti from 'react-dom-confetti';
import classnames from 'classnames';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../helpers/loader'; 
import countries from '../helpers/countries';
import { isEmpty, isEqual } from 'lodash';
import SmallLoader from '../../images/small_loader.gif';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import { ReactComponent as MastercardIcon } from '../../images/mastercard.svg';
import { ReactComponent as TrashIcon } from '../../images/icons/trashcan.svg';
import { isValidEmail } from '../helpers/validationFunctions';
import Checkbox from '../helpers/checkbox';
import TrialBanner from '../helpers/trialBanner';
import { mapCurrencyToSign } from '../../utils';
import ReactPixel from 'react-facebook-pixel';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { localiseText } from '../../utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PlanCard = ({ plan, checked, onChange }) => (
    <div className={`subscription-modal-radio-option ${checked ? 'smro-active' : ''}`}>
        <label className="smro-label">
            <input className="smro-check" type="radio" checked={checked} value={plan.paycycle} onChange={onChange} />
            <div className="smro-copy">
                <p className="smro-title">
                    {plan.paycycle_display} plan
                </p>
                <p className="smro-desc">
                    {plan.paycycle === 'A' ? 'Pay yearly' : 'Pay monthly'}
                    <span className="bullet">•</span>
                    {plan.paycycle === 'A' ? 'Our best value plan' : 'Our most flexible plan'}
                </p>
            </div>
            <div className="smro-price">
                <h2 className="spc-plan-title smro-price-title">
                    <span className="spc-pt-currency">{mapCurrencyToSign[plan.base_price_currency]}</span>
                    {plan.base_price}
                    <span className="spc-pt-month">/mo +VAT</span>
                </h2>
            </div>
        </label>
    </div>
);

PlanCard.propTypes = {
    plan: PropTypes.shape({
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.number,
        paycycle: PropTypes.string,
        paycycle_display: PropTypes.string,
        base_price: PropTypes.string,
        base_price_currency: PropTypes.string,
    }),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    getTotalCost: PropTypes.func,
};

const confettiConfigRight = {
    angle: '135',
    spread: '100',
    startVelocity: 40,
    elementCount: 80,
    dragFriction: 0.08,
    duration: 5000,
    stagger: 5,
    width: '10px',
    height: '10px',
    perspective: '650px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const confettiConfigLeft = {
    angle: '55',
    spread: '100',
    startVelocity: 40,
    elementCount: 80,
    dragFriction: 0.08,
    duration: 5000,
    stagger: 5,
    width: '10px',
    height: '10px',
    perspective: '650px',
    colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
};

const Step2 = ({ 
    choosedPlan, 
    setChoosedPlan, 
    getTotalCost, 
    setContinueAllowed, 
    subscriptionDetails,
}) => {
    
    const [plans, setPlans] = useState({});
    const context = useContext(AppContext);

    const getSubscriptionPlan = () => {
        const {
            token = localStorage.getItem('token')
        } = context;

        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${context.selectedOrganisation.id}/subscriptions/get_tier_plan/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                const annual = data.data.find(item => item.paycycle === 'A');
                const monthly = data.data.find(item => item.paycycle === 'M');

                setPlans({
                    premium: [
                        {
                            payment_details: [
                                {
                                    paycycle: 'M',
                                    paycycle_display: 'Monthly',
                                    vat: monthly.vat,
                                    base_price: monthly.plan_price[0].base_price,
                                    base_price_currency: monthly.plan_price[0].base_price_currency,
                                    id: monthly.id
                                },
                                {
                                    paycycle: 'A',
                                    paycycle_display: 'Annual',
                                    vat: annual.vat,
                                    base_price: annual.plan_price[0].base_price,
                                    base_price_currency: annual.plan_price[0].base_price_currency,
                                    id: annual.id
                                } 
                            ]
                        }
                    ]
                }
                );
            })
            .catch(error => {
                console.log(error);
            });
    };

    const plansDetails = plans?.premium?.length ? plans?.premium[0]?.payment_details : [];

    useEffect(() => {
        getSubscriptionPlan();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (plans?.premium?.length) {
            const choosedPlanDetails = plansDetails?.find(item => item?.paycycle === choosedPlan?.paycycle);
            setChoosedPlan(choosedPlanDetails);
        }
        // eslint-disable-next-line
    }, [plans?.premium]);

    useEffect(() => {
        if (choosedPlan) setContinueAllowed(true);

        // eslint-disable-next-line
    }, [choosedPlan]);
    
    return (
        <div className="create-subscription-plan-section">
            {subscriptionDetails?.details?.statuses?.is_trial_eligible && <TrialBanner type="note" />}
            <p className="subscription-modal-header smro-payment-header">Choose your payment plan</p>
            {plansDetails.map(plan => (
                <PlanCard 
                    key={plan.paycycle} 
                    plan={plan} 
                    checked={choosedPlan?.paycycle === plan?.paycycle} 
                    onChange={() => setChoosedPlan(plan)}
                    getTotalCost={getTotalCost}
                />
            ))}
        </div>
    );
};

Step2.propTypes = {
    availablePlans: PropTypes.shape({}),
    choosedPlan: PropTypes.shape({
        paycycle: PropTypes.string,
        base_price_currency: PropTypes.string,
    }),
    setChoosedPlan: PropTypes.func,
    getTotalCost: PropTypes.func,
    getCurrentPlan: PropTypes.func,
    plans: PropTypes.shape({
        plans: PropTypes.shape({
            premium: PropTypes.arrayOf(
                PropTypes.shape({
                    payment_details: PropTypes.arrayOf(
                        PropTypes.shape({
                            paycycle: PropTypes.string
                        })
                    )
                })
            )
        })
    }),
    setContinueAllowed: PropTypes.func,
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            statuses: PropTypes.shape({
                is_trial_eligible: PropTypes.bool
            })
        })
    })
};

class Step3 extends React.Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        let customerDetails = {
            organisation: '',
            firstName: '',
            lastName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
        };

        let oldCustomerDetails = {
            organisation: '',
            firstName: '',
            lastName: '',
            email: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postcode: '',
            country: '',
        };

        if (this.props.subscriptionDetails?.details?.statuses?.customer_status === 'OK') {
            const customer = this.props.subscriptionDetails.details.customer;
            customerDetails = {
                organisation: customer.company ? customer.company : '',
                firstName: customer.first_name ? customer.first_name : '',
                lastName: customer.last_name ? customer.last_name : '',
                email: customer.email ? customer.email : '',
                addressLine1: customer.address ? customer.address : '',
                addressLine2: customer.address2 ? customer.address2 : '',
                city: customer.city ? customer.city : '',
                state: customer.state ? customer.state : '',
                postcode: customer.postcode ? customer.postcode : '',
                country: customer.country ? customer.country : '',
            };
            oldCustomerDetails = {
                organisation: customer.company ? customer.company : '',
                firstName: customer.first_name ? customer.first_name : '',
                lastName: customer.last_name ? customer.last_name : '',
                email: customer.email ? customer.email : '',
                addressLine1: customer.address ? customer.address : '',
                addressLine2: customer.address2 ? customer.address2 : '',
                city: customer.city ? customer.city : '',
                state: customer.state ? customer.state : '',
                postcode: customer.postcode ? customer.postcode : '',
                country: customer.country ? customer.country : '',
            };
        }

        const initialCountryCodes = countries.map(countryCodes => countryCodes);

        this.state = {
            countryCodeOptions: initialCountryCodes,
            currentSuccessMessage: '',
            currentErrorMessage: '',
            customerDetails,
            oldCustomerDetails,
            changesMade: false,
            triggerSubDataRefresh: false,
        };
    }

    componentDidMount () {
        this.props.setContinueAllowed(false);
        this.setState({
            changesMade: false
        });

        if (!isEmpty(this.props.customerDetails)) 
            this.setState({
                customerDetails: this.props.customerDetails
            });
        else
            this.props.setCustomerDetails(this.state.customerDetails);
    }

    componentDidUpdate () {
        if (this.state.changesMade) {
            this.props.setChangesMade(true);        
            this.props.setCustomerDetails(this.state.customerDetails);
        }

        const { 
            organisation,
            firstName,
            lastName,
            email,
            addressLine1,
            city,
            state,
            postcode,
            country
        } = this.state.customerDetails;

        if (
            organisation &&
            firstName &&
            lastName &&
            email &&
            addressLine1 &&
            city &&
            state &&
            postcode &&
            country
        ) 
            this.props.setContinueAllowed(true);
        else 
            this.props.setContinueAllowed(false);
    
    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    handleCustomerDetailsChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState(
            prevState => ({
                customerDetails: {
                    ...prevState.customerDetails,
                    [targetName]: targetValue,
                },
            }), () => {
                if (isEqual(this.state.customerDetails, this.state.oldCustomerDetails)) {
                    this.setState({
                        changesMade: false,
                    });
                }
                else {
                    this.setState({
                        changesMade: true,
                    });
                }
            }
        );
    };

    createCountrySelectOptions = () =>
        this.state.countryCodeOptions.map(option => (
            <option key={option.id} value={option.name}>
                {option.name}
            </option>
        ));

    render () {
        const {
            customerDetails
        } = this.state;

        return (
            <div className="customer-details-modal-section">
                {this.props.subscriptionDetails?.details?.statuses?.is_trial_eligible && <TrialBanner type="note" />}
                <p className="subscription-modal-header">Customer details</p>
                {this.props.errorMessage && (
                    <StepError message={this.props.errorMessage} />
                )}
                <form className="subscription-modal-customer-form">
                    <div className="modal-input-row">
                        <div className="modal-input-group">
                            <label
                                htmlFor="customer-details-organisation"
                                className="modal-input-label"
                            >
                                {localiseText('Organisation')}/company name <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-organisation"
                                value={customerDetails.organisation}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="organisation"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="modal-input-row">
                        <div className="modal-input-group-half">
                            <label
                                htmlFor="customer-details-first-name"
                                className="modal-input-label"
                            >
                                First name <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-first-name"
                                value={customerDetails.firstName}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="firstName"
                                type="text"
                            />
                        </div>
                        <div className="modal-input-group-half">
                            <label
                                htmlFor="customer-details-last-name"
                                className="modal-input-label"
                            >
                                Surname <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-last-name"
                                value={customerDetails.lastName}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="lastName"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="modal-input-row">
                        <div className="modal-input-group">
                            <label htmlFor="customer-details-email" className="modal-input-label">
                                Email address <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-email"
                                value={customerDetails.email}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="email"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="modal-input-row">
                        <div className="modal-input-group-half">
                            <label
                                htmlFor="customer-details-address-1"
                                className="modal-input-label"
                            >
                                Address line 1 <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-address-1"
                                value={customerDetails.addressLine1}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="addressLine1"
                                type="text"
                            />
                        </div>
                        <div className="modal-input-group-half">
                            <label
                                htmlFor="customer-details-address-2"
                                className="modal-input-label"
                            >
                                Address line 2
                            </label>
                            <input
                                id="customer-details-address-2"
                                value={customerDetails.addressLine2}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="addressLine2"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="modal-input-row">
                        <div className="modal-input-group-half">
                            <label htmlFor="customer-details-city" className="modal-input-label">
                                Town/city <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-city"
                                value={customerDetails.city}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="city"
                                type="text"
                            />
                        </div>
                        <div className="modal-input-group-half">
                            <label htmlFor="customer-details-state" className="modal-input-label">
                                County/state <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-state"
                                value={customerDetails.state}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="state"
                                type="text"
                            />
                        </div>
                    </div>
                    <div className="modal-input-row">
                        <div className="modal-input-group-half">
                            <label htmlFor="customer-details-country" className="modal-input-label">
                                Country <span className="required-field">*</span>
                            </label>
                            <select
                                id="customer-details-country"
                                className="input org-address-input upgrade-country-select"
                                name="country"
                                onChange={this.handleCustomerDetailsChange}
                                value={customerDetails.country}
                                required
                            >
                                <option value="" disabled>
                                    Select country
                                </option>
                                {this.createCountrySelectOptions()}
                            </select>
                        </div>
                        <div className="modal-input-group-half">
                            <label
                                htmlFor="customer-details-postcode"
                                className="modal-input-label"
                            >
                                Postal/Zip code <span className="required-field">*</span>
                            </label>
                            <input
                                id="customer-details-postcode"
                                value={customerDetails.postcode}
                                onChange={this.handleCustomerDetailsChange}
                                className="input modal-input"
                                name="postcode"
                                type="text"
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }
};

Step3.propTypes = {
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            statuses: PropTypes.shape({
                customer_status: PropTypes.string,
                is_trial_eligible: PropTypes.bool
            }),
            customer: PropTypes.shape({
                company: PropTypes.string,
                first_name: PropTypes.string,
                last_name: PropTypes.string,
                email: PropTypes.string,
                address: PropTypes.string,
                address2: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                country: PropTypes.string,
                postcode: PropTypes.string,
            })
        }),
    }),
    setContinueAllowed: PropTypes.func,
    setCustomerDetails: PropTypes.func,
    setChangesMade: PropTypes.func,
    customerDetails: PropTypes.instanceOf(Object),
    errorMessage: PropTypes.string
};

const SetupForm = ({
    customerDetails,
    choosedPlan, couponName,
    backButton }) => {
    const stripe = useStripe();
    const elements = useElements();
  
    const [errorMessage, setErrorMessage] = useState(null);
    const [stripeLoading, setStripeLoading] = useState(false);
  
    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setStripeLoading(true);
  
        if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
  
        const parameters = queryString.stringify({
            subscription: choosedPlan?.id,
            first_name: customerDetails?.firstName,
            coupon: couponName
        },{});

        const { error } = await stripe.confirmSetup({
        // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/subscription/subscription-plan?${parameters}`,
            },
        });
  
        if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
            setErrorMessage(error.message);
            setStripeLoading(false);
        }
        else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
            setStripeLoading(false);
        }
    };
  
    return (
        <form onSubmit={handleSubmit}>
            {errorMessage && <StepError message={errorMessage} />}
            <PaymentElement />
            <div className="modal-btn-row sm-modal-btn-row">
                <div
                    role="button"
                    className={'btn secondary-btn secondary-btn-rounded modal-form-btn'}
                    onClick={backButton}
                >
                    Back
                </div>
                {stripeLoading ? (
                    <div
                        role="button"
                        className={`btn secondary-btn
                         secondary-btn-rounded modal-form-btn sub-fixed-width-btn disabled-secondary-btn`}
                    >
                        <img
                            className="subscription-btn-loader"
                            src={SmallLoader}
                            alt="userprofile"
                        />
                    </div>
                ) : (
                    <button
                        className={`btn primary-btn modal-form-btn sub-fixed-width-btn
                            ${!stripe ? 'disabled-primary-btn' : ''}`}
                        disabled={!stripe}
                    >
                        Submit
                    </button>
                )}
            </div>
        </form>
    );
};

SetupForm.propTypes = {
    customerDetails: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
    }).isRequired,
    choosedPlan: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    couponName: PropTypes.string.isRequired,
    backButton: PropTypes.func.isRequired,
};

class Step4 extends React.Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        let linkedCreditCards = [];
        if (
            this.props.subscriptionDetails.details.statuses.customer_status === 'OK' &&
            this.props.subscriptionDetails.details.statuses.credit_card_status === 'OK'
        ) {
            linkedCreditCards = this.props.subscriptionDetails.details.credit_cards;
        }

        const initialCountryCodes = countries.map(countryCodes => countryCodes);

        this.state = {
            countryCodeOptions: initialCountryCodes,
            paymentDetailsError: false,
            currentSuccessMessage: '',
            currentErrorMessage: '',
            modalPage: 1,
            triggerSubDataRefresh: false,
            currentCreditCards: linkedCreditCards,
            oldSelectedPaymentMethod: this.props.subscriptionDetails.details.payment_method?.creditcard_id
                ? this.props.subscriptionDetails.details.payment_method.creditcard_id.toString()
                : null,
            selectedPaymentMethod: this.props.paymentMethod
                ? this.props.paymentMethod.creditcard_id
                : this.props.subscriptionDetails.details.payment_method.creditcard_id.toString(),
            updateDefaultCardReady: false,
            paymentMethod: !isEmpty(this.props.paymentMethod) 
                ? this.props.paymentMethod 
                : {
                    cardNumber: '',
                    cardName: '',
                    expiryYear: '',
                    expiryMonth: '',
                    cvv: ''
                },
            billingDetails: !isEmpty(this.props.billingDetails)
                ? this.props.billingDetails
                : {
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    state: '',
                    postcode: '',
                    country: '',
                },
            pageChangeReady: true,
            useCustomerAddress: false,
        };
    }

    componentDidMount () {
        this.props.setContinueAllowed(false);
        this.props.setAddedNewPayment(false);

        if (this.props.addedNewPayment) {
            this.setState({ modalPage: 2 });
            this.props.setHideControlButtons(true);
        }

        const { credit_cards } = this.props.subscriptionDetails.details;

        if (!credit_cards || !credit_cards.length) {
            this.setState({
                modalPage: 2,
            });
            
            this.props.setHideControlButtons(true);
        }
    }

    componentDidUpdate (prevProps, prevState) {

        if (this.state.selectedPaymentMethod !== prevState.selectedPaymentMethod && this.state.selectedPaymentMethod) {
            const cards = this.props.subscriptionDetails.details.credit_cards;

            this.props.setPaymentMethod(
                cards.find(
                    card => card.id === +this.state.selectedPaymentMethod
                )
            );

            this.props.setContinueAllowed(!this.state.addedNewPayment);
        }

        if (this.state.useCustomerAddress !== prevState.useCustomerAddress)
            if (this.state.useCustomerAddress) {
                this.setState({
                    billingDetails: this.props.customerDetails
                });
            }
            else {
                this.setState({
                    billingDetails: {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        postcode: '',
                        country: '',
                    }
                });
            }

        if (!isEqual(this.state.billingDetails, prevState.billingDetails)) {
            this.props.setBillingDetails(this.state.billingDetails);
        }

        const { credit_cards } = this.props.subscriptionDetails.details;

        if (
            !isEqual(credit_cards, prevProps.subscriptionDetails.details.credit_cards)
            && (!credit_cards || !credit_cards.length)
        ) {
            this.setState({
                modalPage: 2
            });

            this.props.setHideControlButtons(true);
        }
    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    triggerNewCardAdd = e => {
        e.preventDefault();

        this.setState({
            modalPage: 2,
            addedNewPayment: true,
        });

        this.props.setHideControlButtons(true);
    };

    handleSectionChange = () => {
        // e.preventDefault();
        if (this.state.modalPage < 2) {
            this.setState({
                modalPage: this.state.modalPage + 1,
            });
        }
    };

    handleSectionChangeReverse = e => {
        e.preventDefault();
        if (this.state.modalPage > 1) {
            this.setState({
                modalPage: this.state.modalPage - 1,
                addedNewPayment: false,
            });
            this.props.setAddedNewPayment(false);
            this.props.setHideControlButtons(false);
            this.props.skipChoosePayment();
        }
    };

    handleCardOptionChange = e => {
        const targetValue = e.target.value.toString();
        let updateDefaultReadyValue;
        if (targetValue === this.state.oldSelectedPaymentMethod) {
            updateDefaultReadyValue = false;
        }
        else {
            updateDefaultReadyValue = true;
        }
        this.setState({
            selectedPaymentMethod: targetValue,
            updateDefaultCardReady: updateDefaultReadyValue,
        }, () => this.props.setAddedNewPayment(false));
    };

    handlePaymentMethodChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState(prevState => ({
            paymentMethod: {
                ...prevState.paymentMethod,
                [targetName]: targetValue,
            },
        }));
    };

    handleBillingDetailsChange = e => {
        const targetName = e.target.name;
        const targetValue = e.target.value;
        this.setState(prevState => ({
            billingDetails: {
                ...prevState.billingDetails,
                [targetName]: targetValue,
            },
            useCustomerAddress: false,
        }));
    };

    handleSectionValidation = () => {
        const {
            first_name, last_name, city, country, state, postcode, email, address
        } = this.props.subscriptionDetails.details.customer;

        if (
            first_name.length > 1 &&
            last_name.length > 1 &&
            isValidEmail(email) &&
            address.length > 1 &&  
            city.length > 1 &&
            state.length > 1 &&
            postcode.length > 1 &&
            country.length > 1
        ) {
            if (this.state.createSubscriptionError) {
                this.setState({
                    createSubscriptionError: false,
                });
            }
            this.handleAddNewPaymentMethod();
        }
        else {
            
            this.setState({
                createSubscriptionError: true,
            });
        }
    };

    handleCardDeletionConfirm = (status, id) => {
        if (status) {
            this.setState({
                paymentCardDeletionID: id.toString(),
                selectedPaymentMethod: null
            });
        }
        else {
            this.setState({
                paymentCardDeletionID: null,
                // selectedPaymentMethod: this.state.oldSelectedPaymentMethod
            });
        }
    };

    handleCardDeletion = id => {
        this.setState(
            {
                loadingCardDeletionID: id.toString(),
            },
            () => {
                const token = this.context.token ? this.context.token : localStorage.getItem('token');
                /* eslint-disable-next-line*/
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/subscriptions/delete_credit_card/`;
                const payload = {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                    data: {
                        delete_credit_card_id: id,
                    },
                };
                let newDefaultCardId;
                if (this.state.selectedPaymentMethod === id.toString()) {
                    if (this.state.currentCreditCards.length > 1) {
                        const newCardsList = this.state.currentCreditCards;
                        for (let i = 0; i < newCardsList.length; i++) {
                            if (newCardsList[i].id === id) {
                                newCardsList.splice(i, 1);
                            }
                        }

                        newDefaultCardId = newCardsList[newCardsList.length - 1].id;
                        payload.data.default_credit_card_id = newDefaultCardId;
                    }
                    else {
                        newDefaultCardId = null;
                    }
                }
                else {
                    newDefaultCardId = this.state.selectedPaymentMethod;
                }

                axios
                    .delete(apiCallURL, payload)
                    .then(res => {

                        this.setState({
                            currentCreditCards: res.data.data.credit_cards,
                            oldSelectedPaymentMethod: newDefaultCardId ? newDefaultCardId.toString() : null,
                            selectedPaymentMethod: newDefaultCardId ? newDefaultCardId.toString() : null,
                            // paymentCardDeletionID: null,
                            loadingCardDeletionID: null,
                            triggerSubDataRefresh: true,
                        },
                        () => {
                            this.displaySuccessMessage('Payment method successfully deleted');
                        }
                        );
                    })
                    .catch(error => {
                        console.log(error.response);
                        this.displayErrorMessage('There was a problem deleting this payment method');
                        this.setState({
                            loadingCardDeletionID: null,
                        });
                    });
            }
        );
    };

    handleAddNewPaymentMethod = () => {
        // Simple Field Validation
        const { cardNumber, cardName, cvv, expiryYear, expiryMonth } = this.state.paymentMethod;
        const { addressLine1, addressLine2, city, state, postcode, country } = this.state.billingDetails;

        if (
            !(
                cardNumber.length > 4 &&
                cardName.length > 2 &&
                [3, 4].includes(cvv.length) &&
                expiryYear.length > 3 &&
                expiryMonth.length > 0 &&
                addressLine1.length > 1 &&
                city.length > 1 &&
                state.length > 1 &&
                postcode.length > 1 &&
                country.length > 1
            )
        ) {
            this.setState({
                paymentDetailsError: true,
            });
            return false;
        } 
        else {
            const paymentMethodData = {
                cardNumber,
                cvv,
                expiryYear,
                expiryMonth,
                cardName,
                addressLine1,
                addressLine2,
                city,
                state,
                postcode,
                country
            };

            this.props.setPaymentMethod(paymentMethodData);
            this.props.setAddedNewPayment(true);
        }

        if (this.state.paymentDetailsError) {
            this.setState({
                paymentDetailsError: false,
            });
        }
    };

    createCountrySelectOptions = () =>
        this.state.countryCodeOptions.map(option => (
            <option key={option.id} value={option.name}>
                {option.name}
            </option>
        ));

    toggleCustomerAddress = () => {
        this.setState(prevState => ({
            useCustomerAddress: !prevState.useCustomerAddress
        }));
    };

    renderExpdateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = currentYear; i <= currentYear + 10; i++) {
            years.push(i);
        }

        return years.map(item => <option key={item}>{item}</option>);
    };

    renderExpdateMonthOptions = () => {
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        const now = new Date();
        let filtered = [...months];
        
        if (+now.getFullYear() === +this.state.paymentMethod.expiryYear) 
            filtered = months.filter(item => +item - 1 > now.getMonth());

        return filtered.map(item => (
            <option key={item} value={item}>
                {parseInt(item)}
            </option>
        ));
    };

    render () {
        const {
            modalPage,
            selectedPaymentMethod,
            paymentCardDeletionID,
            loadingCardDeletionID,
            billingDetails,
            paymentMethod,
            paymentDetailsError,
            pageChangeReady,
            useCustomerAddress
        } = this.state;
        
        const currentPaymentMethods = this.state.currentCreditCards?.map(card => (
            <div
                key={card.id}
                className={`subscription-modal-radio-option ${
                    selectedPaymentMethod === card.id.toString() ? 'smro-active' : ''
                }`}
            >
                {paymentCardDeletionID === card.id.toString() ? (
                    <div>
                        {loadingCardDeletionID === card.id.toString() ? (
                            <div className="card-deletion-confirmation">
                                <p className="cdc-copy">
                                    Deleting payment method ending {card.number.substr(card.number.length - 4, 4)}{' '}
                                    <img className="deleting-card-loader" src={SmallLoader} alt="loader" />
                                </p>
                            </div>
                        ) : (
                            <div className="card-deletion-confirmation">
                                <p className="cdc-copy">
                                    Are you sure you want to remove the
                                    <span className="payment-method-inline-logo">
                                        {card.type === 'Visa' ? (
                                            <img
                                                src={require('../../images/visa.png')}
                                                className="pm-visa-logo-inline"
                                                alt="visa-logo"
                                            />
                                        ) : (
                                            <MastercardIcon />
                                        )}
                                    </span>
                                    card ending {card.number.substr(card.number.length - 4, 4)}?
                                </p>
                                <div className="cdc-buttons">
                                    <p
                                        className="cdc-card-btn"
                                        onClick={() => this.handleCardDeletionConfirm(false, card.id)}
                                    >
                                        Cancel
                                    </p>
                                    <p className="cdc-card-btn" onClick={() => this.handleCardDeletion(card.id)}>
                                        Confirm removal
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <label className="smro-label">
                        <input
                            type="radio"
                            name="react-tips"
                            value={card.id}
                            checked={selectedPaymentMethod === card.id.toString()}
                            onChange={this.handleCardOptionChange}
                            className={`smro-check ${
                                selectedPaymentMethod === card.id.toString() ? 'smro-check-checked' : ''
                            }`}
                        />
                        <div className="smro-copy">
                            <p className="smro-title">
                                xxxx xxxx xxxx {card.number.substr(card.number.length - 4, 4)}
                                <span className="payment-method-inline-logo">
                                    {card.type === 'Visa' ? (
                                        <img
                                            src={require('../../images/visa.png')}
                                            className="pm-visa-logo"
                                            alt="visa-logo"
                                        />
                                    ) : (
                                        <MastercardIcon />
                                    )}
                                </span>
                            </p>
                            <p className="smro-desc">
                                Expires {card.expdate_month}/{card.expdate_year}
                            </p>
                        </div>
                        <div className="smro-button">
                            <button
                                className="delete-card-circle-btn"
                                onClick={() => this.handleCardDeletionConfirm(true, card.id)}
                            >
                                <TrashIcon />
                            </button>
                        </div>
                    </label>
                )}
            </div>
        ));

        let modalPageContent;
        // VIEW INVOICE AND SELECT OR ADD PAYMENT METHOD
        if (modalPage === 1) {
            modalPageContent = (
                <>
                    <div className="customer-details-modal-section">
                        <p className="subscription-modal-header">Select payment method</p>
                        {currentPaymentMethods}
                        <div className="add-new-payment-btn" onClick={this.triggerNewCardAdd}>
                            <p>+ Add a new payment method</p>
                        </div>
                    </div>
                </>
            );
            // CUSTOMER DETAILS MODAL SECTION
        }
        else if (modalPage === 2) {
            modalPageContent = (
                <>
                    {paymentDetailsError && (
                        <StepError message="Ensure all required fields have valid information." />
                    )}
                    <div className="payment-method-modal-section">
                        <p className="subscription-modal-header">Payment method</p>
                        <div className="payment-method-container">
                            <form className="subscription-modal-payment-form">
                                <div className="modal-input-row">
                                    <div className="modal-input-group">
                                        <label htmlFor="payment-details-card-number" className="modal-input-label">
                                            Card number <span className="required-field">*</span>
                                        </label>
                                        <input
                                            id="payment-details-card-number"
                                            value={paymentMethod.cardNumber}
                                            onChange={this.handlePaymentMethodChange}
                                            className="input modal-input"
                                            name="cardNumber"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="modal-input-row">
                                    <div className="modal-input-group">
                                        <label htmlFor="payment-details-card-name" className="modal-input-label">
                                            Cardholder name <span className="required-field">*</span>
                                        </label>
                                        <input
                                            id="payment-details-card-name"
                                            value={paymentMethod.cardName}
                                            onChange={this.handlePaymentMethodChange}
                                            className="input modal-input"
                                            name="cardName"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="modal-input-row">
                                    <div className="modal-input-group modal-input-group-quarter">
                                        <div className="modal-input-group-half expiry-date-half">
                                            <label className="modal-input-label" htmlFor="expiry-date">
                                                Expiry Date: <span className="required-field">*</span>
                                            </label>
                                            <div className="expiry-date-container" id="expiry-date">
                                                <div className="expdate-month-container">
                                                    <select
                                                        id="payment-details-expdate-month"
                                                        className="input modal-input expdate-month"
                                                        name="expiryMonth"
                                                        type="text"
                                                        placeholder="-"
                                                        required
                                                        value={paymentMethod.expiryMonth}
                                                        onChange={this.handlePaymentMethodChange}
                                                    >
                                                        <option value="">-</option>
                                                        {this.renderExpdateMonthOptions()}
                                                    </select>
                                                </div>
                                                <span className="expdate-separator">/</span>
                                                <div className="expdate-year-container">
                                                    <select
                                                        id="payment-details-expdate-year"
                                                        className="input modal-input expdate-year"
                                                        name="expiryYear"
                                                        type="text"
                                                        required
                                                        value={paymentMethod.expiryYear}
                                                        onChange={this.handlePaymentMethodChange}
                                                    >
                                                        <option value="">-</option>
                                                        {this.renderExpdateYearOptions()}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-input-group-half">
                                            <label htmlFor="payment-details-cvv" className="modal-input-label">
                                                CVV <span className="required-field">*</span>
                                            </label>
                                            <input
                                                id="payment-details-cvv"
                                                value={paymentMethod.cvv}
                                                onChange={this.handlePaymentMethodChange}
                                                className="input modal-input"
                                                name="cvv"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="payment-method-card-preview">
                                <div className="payment-card payment-card-bg1">
                                    <div className="payment-card-logo">
                                        <MastercardIcon />
                                    </div>
                                    <div className="payment-card-chip">
                                        <div className="payment-card-chip-line" />
                                        <div className="payment-card-chip-line" />
                                        <div className="payment-card-chip-line" />
                                        <div className="payment-card-chip-line" />
                                        <div className="payment-card-chip-center" />
                                    </div>
                                    <p className="payment-card-number">{paymentMethod.cardNumber}</p>
                                    <div className="payment-card-expiry-container">
                                        <p className="payment-card-expires-header">expires</p>
                                        <p className="payment-card-expiry">
                                            {paymentMethod.expiryMonth} / {paymentMethod.expiryMonth}
                                        </p>
                                    </div>
                                    <p className="payment-card-name">{paymentMethod.cardName}</p>
                                </div>
                                <p className="payment-card-disclaimer">
                                    <span className="payment-warning-icon">
                                        <ExclamationIcon />
                                    </span>
                                    Racecheck never stores your card details
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="billing-details-modal-section">
                        <p className="subscription-modal-header">Billing details</p>
                        <Checkbox 
                            isSelected={useCustomerAddress}
                            label="Same as customer address" 
                            onCheckboxChange={this.toggleCustomerAddress}
                        />
                        <form className="subscription-modal-billing-form">
                            <div className="modal-input-row">
                                <div className="modal-input-group-half">
                                    <label htmlFor="billing-details-address-1" className="modal-input-label">
                                        Address line 1 <span className="required-field">*</span>
                                    </label>
                                    <input
                                        id="billing-details-address-1"
                                        value={billingDetails.addressLine1}
                                        onChange={this.handleBillingDetailsChange}
                                        className="input modal-input"
                                        name="addressLine1"
                                        type="text"
                                    />
                                </div>
                                <div className="modal-input-group-half">
                                    <label htmlFor="billing-details-address-2" className="modal-input-label">
                                        Address line 2
                                    </label>
                                    <input
                                        id="billing-details-address-2"
                                        value={billingDetails.addressLine2}
                                        onChange={this.handleBillingDetailsChange}
                                        className="input modal-input"
                                        name="addressLine2"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="modal-input-row">
                                <div className="modal-input-group-half">
                                    <label htmlFor="billing-details-city" className="modal-input-label">
                                        Town/city <span className="required-field">*</span>
                                    </label>
                                    <input
                                        id="billing-details-city"
                                        value={billingDetails.city}
                                        onChange={this.handleBillingDetailsChange}
                                        className="input modal-input"
                                        name="city"
                                        type="text"
                                    />
                                </div>
                                <div className="modal-input-group-half">
                                    <label htmlFor="customer-details-state" className="modal-input-label">
                                        County/state <span className="required-field">*</span>
                                    </label>
                                    <input
                                        id="customer-details-state"
                                        value={billingDetails.state}
                                        onChange={this.handleBillingDetailsChange}
                                        className="input modal-input"
                                        name="state"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="modal-input-row">
                                <div className="modal-input-group-half">
                                    <label htmlFor="billing-details-country" className="modal-input-label">
                                        Country <span className="required-field">*</span>
                                    </label>
                                    <select
                                        id="billing-details-country"
                                        className="input org-address-input upgrade-country-select"
                                        name="country"
                                        onChange={this.handleBillingDetailsChange}
                                        value={billingDetails.country}
                                        required
                                    >
                                        <option value="" disabled>
                                            Select country
                                        </option>
                                        {this.createCountrySelectOptions()}
                                    </select>
                                </div>
                                <div className="modal-input-group-half">
                                    <label htmlFor="billing-details-postcode" className="modal-input-label">
                                        Postcode <span className="required-field">*</span>
                                    </label>
                                    <input
                                        id="billing-details-postcode"
                                        value={billingDetails.postcode}
                                        onChange={this.handleBillingDetailsChange}
                                        className="input modal-input"
                                        name="postcode"
                                        type="text"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-btn-row sm-modal-btn-row">
                        <div
                            role="button"
                            className={'btn secondary-btn secondary-btn-rounded modal-form-btn'}
                            onClick={this.handleSectionChangeReverse}
                        >
                            Back
                        </div>
                        <div
                            role="button"
                            className={`btn primary-btn modal-form-btn ${
                                pageChangeReady ? '' : 'disabled-primary-btn'
                            }`}
                            onClick={this.handleAddNewPaymentMethod}
                        >
                            Continue
                        </div>
                    </div>
                </>
            );
        }

        return (
            modalPageContent
        );
    }
};

Step4.propTypes = {
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            statuses: PropTypes.shape({
                customer_status: PropTypes.string,
                credit_card_status: PropTypes.string,
            }),
            credit_cards: PropTypes.arrayOf(
                PropTypes.instanceOf(Object)
            ),
            payment_method: PropTypes.shape({
                creditcard_id: PropTypes.string
            }),
            customer: PropTypes.shape({
                company: PropTypes.string,
                first_name: PropTypes.string,
                last_name: PropTypes.string,
                email: PropTypes.string,
                address: PropTypes.string,
                address2: PropTypes.string,
                city: PropTypes.string,
                state: PropTypes.string,
                country: PropTypes.string,
                postcode: PropTypes.string,
                
            })
        }),
    }),
    setContinueAllowed: PropTypes.func,
    customerDetails: PropTypes.instanceOf(Object),
    setBillingDetails: PropTypes.func,
    setHideControlButtons: PropTypes.func,
    setAddedNewPayment: PropTypes.func,
    setPaymentMethod: PropTypes.func,
    billingDetails: PropTypes.instanceOf(Object),
    paymentMethod: PropTypes.instanceOf(Object),
    skipChoosePayment: PropTypes.func,
    addedNewPayment: PropTypes.bool
};

const Step5 = ({ 
    customerDetails,
    subscriptionDetails,
    totalCost,
    choosedPlan,
    setContinueAllowed,
    setCouponName
}) => {

    const [coupon, setCoupon] = useState('');
    const [loadingCouponButton, setLoadingCouponButton] = useState(false);
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponActive, setCouponActive] = useState('');
    const [couponInvalid, setCouponInvalid] = useState(false);
    const [nextPaymentDate, setNextPaymentDate] = useState(new Date());
    const [termsAgreed, setTermsAgreed] = useState(false);

    const handleTermsSelect = () => setTermsAgreed(prevState => !prevState);

    const { 
        token = localStorage.getItem('token'),
        selectedOrganisation
    } = useContext(AppContext);
    const { REACT_APP_API_URL } = process.env;


    // eslint-disable-next-line
    useEffect(() => setContinueAllowed(false), []);
    
    useEffect(() => {
        if (termsAgreed) 
            setContinueAllowed(true);
        else 
            setContinueAllowed(false);

        // eslint-disable-next-line
    }, [termsAgreed]);
    
    const handleCouponCodeChange = e => {
        setCoupon(e.target.value);
    };

    const handleCouponApply = () => {
        setLoadingCouponButton(true);

        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/subscriptions/check_coupon/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .post(
                apiCallURL,
                {
                    name: coupon,
                },
                { headers }
            )
            .then(res => {
                setCouponApplied(true);
                setCouponActive(res.data.data.name);
                setCouponName(res.data.data.name);
                setCoupon('');
                setCouponInvalid(false);
                setLoadingCouponButton(false);
                setNextPaymentDate(res.data.data.next_payment_date);
            })
            .catch(error => {
                console.log(error.response);
                setCouponApplied(false);
                setCouponActive('');
                setCouponName('');
                setCoupon('');
                setCouponInvalid(true);
                setLoadingCouponButton(false);
            });
    };

    const isTrial = subscriptionDetails?.details?.statuses?.is_trial_eligible;
    // eslint-disable-next-line max-len
    const calculatedVat = ((choosedPlan.paycycle === 'M' ? choosedPlan.base_price : choosedPlan.base_price * 12) * (choosedPlan.vat / 100)).toFixed(2);

    const handleCouponRemove = () => {
        const nextPaymentDate = new Date();
        if (isTrial) {
            nextPaymentDate.setDate(new Date().getDate() + 14);
        }

        setCouponApplied(false);
        setCouponActive('');
        setCouponName('');
        setCoupon('');
        setNextPaymentDate(nextPaymentDate);
    };

    return (
        <>
            <div className="summary-modal-section">
                <p className="subscription-modal-header">Your details summary</p>
                <div className="sms-group">
                    <p className="smsg-title">Account holder</p>
                    <p className="smsg-info">
                        {customerDetails.firstName} {customerDetails.lastName} ({customerDetails.email})
                    </p>
                </div>
                <div className="sms-group">
                    <p className="smsg-title">Company/organisation</p>
                    <p className="smsg-info">{customerDetails.organisation}</p>
                </div>
                <div className="sms-group">
                    <p className="smsg-title">Address</p>
                    {/* MUST ADD IF CHECK FOR ADDRESS LINES */}
                    <p className="smsg-info">
                        {customerDetails.addressLine1},{' '}
                        {customerDetails.addressLine2 && <span>{customerDetails.addressLine2}, </span>}
                        {customerDetails.city}, {customerDetails.country}, {customerDetails.postcode}
                    </p>
                </div>
                {/* <div className="sms-group">
                    <p className="smsg-title">Payment method</p>
                    <p className="smsg-info">
                        {' '}Credit Card{' '}
                    </p>
                </div> */}
            </div>

            <div className="coupon-code-section">
                <form>
                    <div className="modal-input-row">
                        <div className="modal-input-group smro-coupon-row">
                            <input
                                id="input-coupon"
                                value={coupon}
                                onChange={handleCouponCodeChange}
                                className="input modal-input"
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleCouponApply();
                                    }
                                }}
                                name="coupon"
                                placeholder="Enter promo code"
                                type="text"
                                autoComplete="off"
                            />
                            {loadingCouponButton ? (
                                <div
                                    role="button"
                                    className={'btn secondary-btn modal-form-btn disabled-secondary-btn'}
                                >
                                    <img
                                        className="subscription-btn-loader"
                                        src={SmallLoader}
                                        alt="userprofile"
                                    />
                                </div>
                            ) : (
                                <div
                                    role="button"
                                    className={`btn secondary-btn modal-form-btn ${
                                        !coupon && 'disabled-secondary-btn'
                                    }`}
                                    onClick={handleCouponApply}
                                >
                                    Apply
                                </div>
                            )}
                        </div>
                        {couponApplied && (
                            <p className="smro-coupon-copy">
                                Code
                                <span className="smro-coupon-name"> {couponActive}</span> applied
                                <span className="smro-coupon-remove" onClick={handleCouponRemove}>
                                    remove
                                </span>
                            </p>
                        )}
                        {couponInvalid && (
                            <p className="smro-coupon-copy smro-coupon-copy-invalid">Invalid code</p>
                        )}
                    </div>
                </form>
            </div>
                
            <div className="to-pay-modal-section">
                <p className="subscription-modal-header">
                    Your subscription summary {customerDetails.country === 'United Kingdom' && '(VAT included)'}
                </p>
                {subscriptionDetails?.details?.statuses?.is_trial_eligible && !couponApplied &&
                    <TrialBanner type="applied" startDate={moment().add(14, 'days').format('Do MMM YYYY')} />
                }
                {couponApplied && (
                    <div className="subscription-modal-trial-message">
                        <p className="stm-copy">
                            <span className="stm-copy-highlight">
                                <span className="subscription-trial-icon">
                                    <ExclamationIcon />
                                </span>
                                {couponActive}:
                            </span>
                            <span>
                                Payments begin&nbsp;
                                <s>{moment().format('Do MMM YYYY')}</s>
                                &nbsp;
                                {moment(nextPaymentDate).format('Do MMM YYYY')}
                            </span>
                        </p>
                    </div>
                )}
                <div className="subscription-modal-radio-option subscription-modal-info-card">
                    <div className="smro-label">
                        <div className="smro-copy">
                            <p className="smro-title">
                                Racecheck Premium
                            </p>
                            <div className="smro-desc">
                                <p>for {selectedOrganisation.name}</p>
                            </div>
                        </div>
                        <div className="smro-price">
                            <h2 className="spc-plan-title smro-price-title">
                                <span className="spc-pt-currency">{mapCurrencyToSign[choosedPlan.base_price_currency]}</span>
                                {totalCost.toFixed(2)} 
                                <span className="spc-pt-month">
                                    {customerDetails?.country === 'United Kingdom' ? 
                                    `(inc. ${mapCurrencyToSign[choosedPlan.base_price_currency]}${calculatedVat} VAT)` : ''}
                                    {choosedPlan.paycycle === 'M' ? '/mo' : '/yr'}
                                    {/* {customerDetails.country === 'United Kingdom' 
                                            && '+VAT'   
                                    } */}
                                </span>
                            </h2>
                        </div>
                    </div>
                    <p className="smro-desc smro-desc-extra smro-payment-summary-copy">
                        {choosedPlan.paycycle === 'M' ? 
                            <>
                                <span>Monthly&nbsp;</span>payments from&nbsp;
                                {couponApplied ?
                                    <>
                                        <s>{moment().format('Do MMM YYYY')}</s>
                                        &nbsp;
                                        {moment(nextPaymentDate).format('Do MMM YYYY')}
                                    </>
                                    :
                                    isTrial ? 
                                        <>
                                            <s>{moment().format('Do MMM YYYY')}</s>
                                            &nbsp;
                                            {moment().add(14, 'days').format('Do MMM YYYY')}
                                        </>
                                        :
                                        moment().format('Do MMM YYYY')
                                }
                            </>
                            :
                            <>
                                <span>Yearly&nbsp;</span>payments from&nbsp;
                                {
                                    couponApplied ?
                                        <>
                                            <s>{moment().format('Do MMM YYYY')}</s>
                                        &nbsp;
                                            {moment(nextPaymentDate).format('Do MMM YYYY')}
                                        </> :
                                        isTrial ?
                                            <>
                                                <s>{moment().format('Do MMM YYYY')}</s>
                                        &nbsp;
                                                {moment().add(14, 'days').format('Do MMM YYYY')}
                                            </>
                                            :
                                            moment().format('Do MMM YYYY')
                                }
                            </>
                        }
                    </p>
                </div>
            </div>

            <div className="create-subscription-terms-container">
                <Checkbox
                    label=""
                    isSelected={termsAgreed}
                    onCheckboxChange={handleTermsSelect}
                    value={termsAgreed}
                />
                <p className="cstc-label">
                    I agree to the Racecheck{' '}
                    <span>
                        <a
                            href="https://racecheck.com/widget-terms-and-conditions/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="footer-link-a"
                        >
                            Terms & Conditions
                        </a>
                    </span>
                </p>
            </div>
        </>
    );
};

Step5.propTypes = {
    customerDetails: PropTypes.shape({
        organisation: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
        postcode: PropTypes.string,    
    }),
    paymentMethod: PropTypes.instanceOf(Object),
    billingDetails: PropTypes.instanceOf(Object),
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            statuses: PropTypes.shape({
                is_trial_eligible: PropTypes.bool
            })
        })
    }),
    totalCost: PropTypes.number,
    choosedPlan: PropTypes.shape({
        paycycle: PropTypes.string,
        base_price_currency: PropTypes.string,
        base_price: PropTypes.number,
        vat: PropTypes.number
    }),
    setContinueAllowed: PropTypes.func,
    addedNewPayment: PropTypes.bool,
    setCouponName: PropTypes.func
};

const Step6 = ({ setConfettiActive }) => {
    const [loaded, setLoaded] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);
    const { search } = useLocation();

    const {
        redirect_status,
        subscription,
        setup_intent,
        coupon,
    } = queryString.parse(search);

    const { 
        token = localStorage.getItem('token'),
        selectedOrganisation
    } = useContext(AppContext);

    useEffect(() => {
        if (redirect_status === 'succeeded') {
            const { REACT_APP_API_URL } = process.env;


            const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/subscriptions/`;
            const headers = {
                Authorization: `Token ${token}`,
            };

            axios
                .post(
                    apiCallURL,
                    {
                        setup_intent_id: setup_intent,
                        subscription_plan_id: subscription,
                        ...(coupon ? { coupon: { name: coupon } } : {})
                    },
                    { headers }
                )
                .then(() => {
                    setLoaded(true);
                    setConfettiActive(true);
                })
                .catch(() => {
                    setSubscriptionError(true);
                    setLoaded(true);
                });
        }
        // eslint-disable-next-line
    },[]);
    
    return (
        <>
            {loaded ? (
                <div>
                    {subscriptionError ? (
                        <div className="sm-confirmation-text">
                            <p className="sm-confirmation-title">Something went wrong</p>
                            <p className="sm-confirmation-copy">
                            There was a problem upgrading your account. Please try again later or
                            contact our team at info@racecheck.com.
                            </p>
                        </div>
                    ) : (
                        <div className="sm-confirmation-text">
                            <p className="sm-confirmation-title">
                            Congratulations!
                            </p>
                            <p className="sm-confirmation-copy">
                            Your account has been upgraded to Racecheck Premium.
                            </p>
                        </div>
                    )}
                </div>
            ) : (
                <div className="sm-confirmation-loading">
                    <div className="section-loading-container sm-confirmation-loader">
                        <Loader />
                    </div>
                    <p className="sm-loading-copy">Upgrading your account...</p>
                </div>
            )}
        </>
    );
};

Step6.propTypes = {
    loaded: PropTypes.bool,
    customerDetails: PropTypes.shape({
        firstName: PropTypes.string
    }),
    subscriptionError: PropTypes.bool,
    setConfettiActive: PropTypes.func,
};

const StepError = ({ message }) => (
    <div className="subscription-modal-error-message">
        <p className="stm-copy">
            <span className="stm-copy-highlight">
                <span className="subscription-error-icon">
                    <ExclamationIcon />
                </span>
                Error:
            </span>{' '}
            {message}
        </p>
    </div>
);

StepError.propTypes = {
    message: PropTypes.string
};

const CreateSubscriptionModals = ({ onClose, subscriptionDetails, getFinalCost, plans, getTotalCost }) => {

    const { search } = useLocation();
    const history = useHistory();

    const params = queryString.parse(search);

    const [currentSuccessMessage, setCurrentSuccessMessage] = useState('');
    const [currentErrorMessage, setCurrentErrorMessage] = useState('');
    const [choosedPlan, setChoosedPlan] = useState({ paycycle: 'M' });      
    const [currentStep, setCurrentStep] = useState(1);
    const [customerDetails, setCustomerDetails] = useState({});
    const [customerDetailsChanged, setCustomerDetailsChanged] = useState(false);
    const [paymentMethod] = useState({});
    const [billingDetails] = useState({});
    const [continueAllowed, setContinueAllowed] = useState(true);
    const [addedNewPayment] = useState(false);
    const [hideControlButtons, setHideControlButtons] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [triggerSubDataRefresh, setTriggerSubDataRefresh] = useState(false);
    const [confettiActive, setConfettiActive] = useState(false);
    const [couponName, setCouponName] = useState('');
    const [clientSecret, setClientSecret] = useState('');
    const [continueToPaymentLoader, setContinueToPaymentLoader] = useState(false);
    
    const resetSuccessMessage = () => setCurrentSuccessMessage('');

    const context = useContext(AppContext);

    const { 
        token = localStorage.getItem('token'),
        selectedOrganisation = {},
    } = context;

    const { REACT_APP_API_URL } = process.env;

    const displayErrorMessage = (message) => {
        setCurrentErrorMessage(message);
    };

    // const skipChoosePayment = () => {
    //     setCurrentStep(2);
    // };

    useEffect(() => {
        if (addedNewPayment) {
            setCurrentStep(4);
            setHideControlButtons(false);
        };
    }, [addedNewPayment]);

    
    useEffect(() => {
        if (params?.redirect_status) {
            setCurrentStep(5);
        }
    }, [params]);

    // useEffect(() => {
    //     if (currentStep === 4)
    //         setHideControlButtons(true);
    // }, [currentStep]);

    const handleSubmit = () => {

        if (!continueAllowed) return false;

        const { id: orgId } = selectedOrganisation;
        const { setSelectedOrganisation } = context;

        const { id: plan_id } = choosedPlan;

        const {
            organisation: company,
            firstName: first_name,
            lastName: last_name,
            email,
            addressLine1: address,
            addressLine2: address2,
            city,
            postcode,
            country,
            state
        } = customerDetails;

        setLoaded(false);
        setCurrentStep(5);

        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${orgId}/subscriptions/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        const body = {
            subscription_plan: {
                plan_id,
            }
        };

        if (addedNewPayment) 
            body.credit_card = {
                number: paymentMethod.cardNumber,
                cvv: paymentMethod.cvv,
                expdate_year: paymentMethod.expiryYear,
                expdate_month: paymentMethod.expiryMonth,
                name: paymentMethod.cardName,
                address: `${paymentMethod.addressLine1} ${paymentMethod.addressLine2}`,
                city: paymentMethod.city,
                state: paymentMethod.state,
                postcode: paymentMethod.postcode,
                country: paymentMethod.country
            };  
        else 
            body.default_credit_card_id = paymentMethod.id;

        if (customerDetailsChanged)
            body.customer = {
                company,
                first_name,
                last_name,
                email,
                address,
                address2,
                city,
                postcode,
                country,
                state
            };

        if (couponName)
            body.coupon = { name: couponName };

        axios
            .post(
                apiCallURL,
                body,
                { headers }
            )
            .then(() => {
                ReactPixel.trackCustom('Subscription Created', { organisation: context.selectedOrganisation.name });
                setLoaded(true);
                setSubscriptionError(false);
                setTriggerSubDataRefresh(true);
                setConfettiActive(true);
                setSelectedOrganisation({
                    ...context.selectedOrganisation,
                    is_trial_eligible: false,
                    is_premium_subscriber: true
                });
            })  
            .catch(err => {
                console.log(err);
                setLoaded(true);
                setSubscriptionError(true);
                setTriggerSubDataRefresh(true);
            });
    };

    const handleContinue = () => {
        if (continueAllowed && currentStep === 3) {
            const { id: orgId } = selectedOrganisation;
            const headers = {
                Authorization: `Token ${token}`,
            };

            setContinueToPaymentLoader(true);

            // eslint-disable-next-line max-len
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${orgId}/subscriptions/create_setup_intent/`;
            axios
                .post(apiCallURL, {
                    company: customerDetails?.organisation,
                    first_name: customerDetails?.firstName,
                    last_name: customerDetails?.lastName,
                    email: customerDetails?.email,
                    address: customerDetails?.addressLine1,
                    address2: customerDetails?.addressLine2,
                    city: customerDetails?.city,
                    state: customerDetails?.state,
                    postcode: customerDetails?.postcode,
                    country: customerDetails?.country,
                }, { headers })
                .then(res => {
                    setClientSecret(res.data.data.client_secret);
                    setCurrentStep(prevState => prevState + 1);
                    setContinueToPaymentLoader(false);
                })
                .catch(error => {
                    console.log('error', error);
                    setContinueToPaymentLoader(false);
                });
            return;
        }
        if (continueAllowed)
            setCurrentStep(prevState => prevState + 1);
        else if (currentStep === 2)
            displayErrorMessage('There was a problem updating your details');
            
    };

    const handleStepBack = () => {
        setCurrentStep(prevState => prevState - 1);
    };
    
    const handleCancel = (evt) => {
        evt.preventDefault();
        const {
            setup_intent,
        } = queryString.parse(search);
        if (currentStep === 5 && setup_intent) {
            history.replace({
                search: ''
            });
            window.location.reload();
        }
        onClose(triggerSubDataRefresh);
    };
    
    return (
        <div className="modal-backdrop">
            <div className="confetti-container-right">
                <Confetti active={confettiActive} config={confettiConfigRight} />
            </div>
            <div className="confetti-container-left">
                <Confetti active={confettiActive} config={confettiConfigLeft} />
            </div>
            <div className="modal-padding" />
            <div className="modal-container">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={resetSuccessMessage} />
                )}
                <div className="modal-header">
                    <h2 className="modal-header-title">
                        Upgrade to Premium
                    </h2>
                    <button className="modal-close-btn" onClick={handleCancel}>
                        <CloseIcon />
                    </button>
                </div>
                
                <div className="modal-scrollable">
                    <div className="modal-content">
                        <div className="modal-content-section">
                            {currentStep === 1 &&
                                <Step2 
                                    choosedPlan={choosedPlan}
                                    setChoosedPlan={setChoosedPlan}
                                    getTotalCost={getTotalCost}
                                    plans={plans}
                                    setContinueAllowed={setContinueAllowed}
                                    subscriptionDetails={subscriptionDetails}
                                />
                            }
                            {currentStep === 2 && 
                                <Step3
                                    subscriptionDetails={subscriptionDetails}
                                    setCustomerDetails={setCustomerDetails}
                                    customerDetails={customerDetails}
                                    setContinueAllowed={setContinueAllowed}
                                    setChangesMade={setCustomerDetailsChanged}
                                    errorMessage={currentErrorMessage}
                                />
                            }
                            {/* {
                                currentStep === 3 && 
                                <Step4
                                    skipChoosePayment={skipChoosePayment}
                                    subscriptionDetails={subscriptionDetails}
                                    setContinueAllowed={setContinueAllowed}
                                    setPaymentMethod={setPaymentMethod}
                                    paymentMethod={paymentMethod}
                                    setBillingDetails={setBillingDetails}
                                    setAddedNewPayment={setAddedNewPayment}
                                    customerDetails={customerDetails}
                                    billingDetails={billingDetails}
                                    setHideControlButtons={setHideControlButtons}
                                />
                            } */}
                            {currentStep === 3 && 
                                <Step5
                                    customerDetails={customerDetails}
                                    billingDetails={billingDetails}
                                    paymentMethod={paymentMethod}
                                    addedNewPayment={addedNewPayment}
                                    subscriptionDetails={subscriptionDetails}
                                    setContinueAllowed={setContinueAllowed}
                                    totalCost={
                                        getFinalCost(
                                            choosedPlan.paycycle,
                                            choosedPlan.base_price,
                                            customerDetails.country
                                        ).totalCost
                                    }
                                    choosedPlan={choosedPlan}
                                    setCouponName={setCouponName}
                                />
                            }
                            { currentStep === 4 && 
                                <Elements stripe={stripePromise} options={{
                                    clientSecret
                                }}
                                >
                                    <SetupForm 
                                        customerDetails={customerDetails}
                                        billingDetails={billingDetails}
                                        paymentMethod={paymentMethod}
                                        addedNewPayment={addedNewPayment}
                                        subscriptionDetails={subscriptionDetails}
                                        setContinueAllowed={setContinueAllowed}
                                        totalCost={
                                            getFinalCost(
                                                choosedPlan.paycycle,
                                                choosedPlan.base_price,
                                                customerDetails.country
                                            ).totalCost
                                        }
                                        choosedPlan={choosedPlan}
                                        setCouponName={setCouponName}
                                        couponName={couponName}
                                        backButton = {handleStepBack}
                                    />
                                </Elements>
                            }
                            {currentStep === 5 &&
                                <Step6
                                    loaded={loaded}
                                    setConfettiActive={setConfettiActive}
                                    customerDetails={customerDetails}
                                    subscriptionError={subscriptionError}
                                />
                            }
                            {hideControlButtons || currentStep === 4 || currentStep === 5 ||
                                <div 
                                    className="upgrade-event-modal__footer modal-btn-row" 
                                    style={{ justifyContent: currentStep === 1 ? 'flex-end' : 'space-between' }}
                                >
                                    {currentStep !== 1 &&
                                        <button 
                                            className={classnames('btn modal-form-btn secondary-btn secondary-btn-rounded', {
                                                'disabled-primary-btn': currentStep === 1
                                            })}
                                            onClick={handleStepBack}
                                            disabled={currentStep === 1}
                                        >
                                                Back
                                        </button>
                                    }
                                    {currentStep !== 4 ?
                                        <>
                                            {currentStep === 3 ?
                                                <>
                                                    {continueToPaymentLoader ?
                                                        <div
                                                            role="button"
                                                            className={`btn 
                                                            secondary-btn 
                                                            secondary-btn-rounded 
                                                            modal-form-btn 
                                                            sub-fixed-width-btn 
                                                            disabled-secondary-btn`}
                                                        >
                                                            <img
                                                                className="subscription-btn-loader"
                                                                src={SmallLoader}
                                                                alt="userprofile"
                                                            />
                                                        </div>
                                                        :
                                                        <button
                                                            className={`btn primary-btn modal-form-btn sub-fixed-width-btn
                                                        ${continueAllowed ? '' : 'disabled-primary-btn'}`}
                                                            onClick={handleContinue}
                                                        >
                                                        Continue to payment
                                                        </button>
                                                    }
                                                </>
                                                :
                                                <button
                                                    className={`btn primary-btn modal-form-btn sub-fixed-width-btn
                                                    ${continueAllowed ? '' : 'disabled-primary-btn'}`}
                                                    onClick={handleContinue}
                                                >
                                                    Continue
                                                </button>
                                            }
                                        </>
                                        :
                                        <button 
                                            className={`btn primary-btn modal-form-btn sub-fixed-width-btn
                                             ${continueAllowed ? '' : 'disabled-primary-btn'}`}
                                            onClick={handleSubmit}
                                        >
                                            Subscribe
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-padding" />
        </div>
    );
};

CreateSubscriptionModals.propTypes = {
    onClose: PropTypes.func,
    subscriptionDetails: PropTypes.shape({
        id: PropTypes.number,
        plan: PropTypes.shape({
            id: PropTypes.number,
            paycycle: PropTypes.string,
        }),
        quantity: PropTypes.number,
        details: PropTypes.shape({
            customer: PropTypes.shape({
                country: PropTypes.string
            }),
            statuses: PropTypes.shape({
                can_change_paycycle: PropTypes.bool,
                can_upgrade_within_paycycle: PropTypes.bool
            })
        }),
        next_payment_date: PropTypes.string,
        getFinalCost: PropTypes.func,
        getTotalCost: PropTypes.func,
    }),
    plans: PropTypes.shape({
        plans: PropTypes.shape({
            premium: PropTypes.arrayOf(
                PropTypes.shape({
                    payment_details: PropTypes.arrayOf(
                        PropTypes.shape({
                            paycycle: PropTypes.string
                        })
                    )
                })
            )
        })
    }),
    getFinalCost: PropTypes.func,
    getTotalCost: PropTypes.func,
};

export default CreateSubscriptionModals;
