import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import he from 'he';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import RatingStars from '../helpers/starGenerator';
// import VerifyReviewModal from './VerifyReviewModal';
// import ShareReviewModal from './ShareReviewModal';
import ReportReviewModal from './ReportReviewModal';
import { ReactComponent as ProfileIcon } from '../../images/icons/person.svg';
import AppContext from '../app/AppContext';
import ReviewImagesModal from './ReviewImagesModal';
import ReviewRepliesModal from './ReviewRepliesModal';
import ReviewImageGenerator from './ReviewImageGenerator';

// import {ReactComponent as VerifiedTick} from '../../images/icons/filled_tick.svg';
import { ReactComponent as VerifiedIcon } from '../../images/icons/outline_tick.svg';

import goldLaurel from '../../images/laurel_gold_with_background.svg';
import silverLaurel from '../../images/laurel_silver_with_background.svg';
import bronzeLaurel from '../../images/laurel_bronze_with_background.svg';
import { ReactComponent as EmptyStar } from '../../images/stars/gold_star_empty.svg';
import { ReactComponent as ReplyIcon } from '../../images/icons/chat.svg';
// import { ReactComponent as ShareIcon } from '../../images/icons/share.svg';
import { ReactComponent as CopyIcon } from '../../images/icons/link.svg';
import { ReactComponent as ImageIcon } from '../../images/icons/image-outline.svg';
import { ReactComponent as BookmarkIcon } from '../../images/icons/bookmark.svg';
import { ReactComponent as BookmarkedIcon } from '../../images/icons/bookmark-fill.svg';
import { ReactComponent as ReportIcon } from '../../images/icons/flag.svg';
import { ReactComponent as ReportedIcon } from '../../images/icons/flag-fill.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/icons/arrow_right.svg';
import { ReactComponent as ReplyCountIcon } from '../../images/icons/reply-message.svg';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron_down.svg';
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';
import { ReactComponent as ExclamationCircleIcon } from '../../images/icons/exclamation_circle.svg';
import { ReactComponent as NAIcon } from '../../images/icons/ban.svg';
import { ReactComponent as CancelIcon } from '../../images/icons/close.svg';
import { ReactComponent as LockIcon } from '../../images/icons/lock.svg';
import { ReactComponent as RacecheckAIIcon } from '../../images/icons/racecheck-ai_outline.svg';
import { ReactComponent as SmartQuestionHiddenIcon } from '../../images/icons/sq-hidden.svg';
import ImageGeneratorFreeModal from './ImageGeneratorFreeModal';
import SmartQuestionExplainerTooltip from './SmartQuestionExplainerTooltip';

class ReviewCard extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        let shortReview = he.decode(this.props.review.clean_review_text);
        let hasFullReview = false;
        if (shortReview.length > 350) {
            let trimmedReview = shortReview.substr(0, 350);
            trimmedReview = trimmedReview.substr(0, Math.min(trimmedReview.length, trimmedReview.lastIndexOf(' ')));
            shortReview = trimmedReview;
            hasFullReview = true;
        }

        const reviewReply = this.props.review.organizer_reply ? this.props.review.organizer_reply : '';

        this.state = {
            // verifyReviewModalOpen: false,
            // shareReviewModalOpen: false,
            reportReviewModalOpen: false,
            reviewImagesModalOpen: false,
            reviewRepliesModalOpen: false,
            imageGeneratorFreeModalOpen: false,
            replyBoxOpen: false,
            replyText: reviewReply,
            currentReply: reviewReply,
            replyReadyForSubmission: false,
            reviewIsBookmarked: false,
            reviewFull: he.decode(this.props.review.clean_review_text),
            reviewShort: shortReview,
            readingFullReview: !hasFullReview,
            activeReviewPhoto: '',
            isReported: !!this.props.review.reports.length,
            generateImagePopupOpen: false,
            reviewLinkCopied: false,
            reviewRatings: this.props.review.ratings,
            smartQuestion: this.props.review.smart_question,
            showSmartQuestionTopBanner: this.props.showSmartQuestionTopBanner,
            isPremium: this.props.mpProps.premium_subscriber,
            eventID: this.props.eventID
        };
    }

    componentDidMount () {
        if (this.props.review.is_bookmarked) {
            this.setState({
                reviewIsBookmarked: true,
            });
        }
        else {
            this.setState({
                reviewIsBookmarked: false,
            });
        }
    }

    toggleBookmark = () => {
        mixpanel.track('Dashboard - Review feed - Bookmark review click', this.props.mpProps);
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        /* eslint-disable-next-line*/
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/events/${this.props.review.event_id}/reviews/${this.props.review.id}/bookmark_review/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .patch(apiCallURL, null, { headers })
            .then(() => {
                const { reviewIsBookmarked } = this.state;
                reviewIsBookmarked
                    ? this.setState({
                        reviewIsBookmarked: false,
                    })
                    : this.setState({
                        reviewIsBookmarked: true,
                    });
                // If parent is Bookmarks.js
                if (this.props.refreshList) {
                    this.props.refreshList();
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    toggleFullReview = () => {
        const { readingFullReview } = this.state;
        readingFullReview
            ? this.setState({
                readingFullReview: false,
            })
            : this.setState({
                readingFullReview: true,
            });
    };

    toggleIsReported = () => {
        this.setState(prevState => ({
            isReported: !prevState.isReported,
        }));
    }

    toggleReply = () => {
        const { replyBoxOpen } = this.state;
        replyBoxOpen
            ? this.setState({
                replyBoxOpen: false,
            })
            : this.setState({
                replyBoxOpen: true,
            });
    };

    // toggleVerifyReviewModal = () => {
    //     const verifyReviewModalOpen = this.state.verifyReviewModalOpen;
    //     verifyReviewModalOpen ? this.setState({verifyReviewModalOpen:false}) : this.setState({verifyReviewModalOpen:true})
    // }

    toggleShareReviewModal = () => {
        mixpanel.track('Dashboard - Review feed - Share review click', this.props.mpProps);
        const { shareReviewModalOpen } = this.state;
        shareReviewModalOpen
            ? this.setState({
                shareReviewModalOpen: false,
            })
            : this.setState({
                shareReviewModalOpen: true,
            });
    };

    toggleGenerateImagePopup = () => {
        this.setState(prevState => ({ generateImagePopupOpen: !prevState.generateImagePopupOpen }));
    }
    
    toggleReportReviewModal = () => {
        mixpanel.track('Dashboard - Review feed - Report review click', this.props.mpProps);
        const { reportReviewModalOpen } = this.state;
        reportReviewModalOpen
            ? this.setState({
                reportReviewModalOpen: false,
            })
            : this.setState({
                reportReviewModalOpen: true,
            });
    };

    toggleReviewImagesModal = photoURL => {
        const { reviewImagesModalOpen } = this.state;
        reviewImagesModalOpen
            ? this.setState({
                reviewImagesModalOpen: false,
                activeReviewPhoto: '',
            })
            : this.setState({
                reviewImagesModalOpen: true,
                activeReviewPhoto: photoURL,
            });
    };

    toggleReviewRepliesModal = () => {
        mixpanel.track('Dashboard - Review feed - Reply limit reached', this.props.mpProps);
        const { reviewRepliesModalOpen } = this.state;
        reviewRepliesModalOpen
            ? this.setState({
                reviewRepliesModalOpen: false,
            })
            : this.setState({
                reviewRepliesModalOpen: true,
            });
    };

    toggleImageGeneratorFreeModal = () => {
        const { imageGeneratorFreeModalOpen } = this.state;
        imageGeneratorFreeModalOpen
            ? this.setState({
                imageGeneratorFreeModalOpen: false,
            })
            : this.setState({
                imageGeneratorFreeModalOpen: true,
            });
    };

    handleChange = e => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                if (this.state.currentReply) {
                    const strippedSpacesReply = this.state.replyText.replace(/\s/g, '');
                    if (strippedSpacesReply.length === 0) {
                        this.setState({
                            replyReadyForSubmission: false,
                        });
                    }
                    else {
                        this.setState({
                            replyReadyForSubmission: true,
                        });
                    }
                }
                else {
                    const strippedSpacesReply = this.state.replyText.replace(/\s/g, '');
                    if (this.props.remainingReplies === 0 || strippedSpacesReply.length === 0) {
                        this.setState({
                            replyReadyForSubmission: false,
                        }, () => {
                            if (this.state.replyText.length > 5 && this.props.remainingReplies === 0) {
                                document.getElementById('reply-review-box').blur();
                                this.toggleReviewRepliesModal();
                            }
                        });
                    }
                    else {
                        if (this.state.replyReadyForSubmission === false) {
                            if (this.state.replyText) {
                                this.setState({
                                    replyReadyForSubmission: true,
                                });
                            }
                        }
                        else if (!this.state.replyText) {
                            this.setState({
                                replyReadyForSubmission: false,
                            });
                        }
                    }
                }
            }
        );
    };

    handleReplySubmit = () => {
        if (this.state.replyReadyForSubmission) {
            const token = this.context.token ? this.context.token : localStorage.getItem('token');
            /* eslint-disable-next-line*/
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/events/${this.props.review.event_id}/reviews/${this.props.review.id}/reply_review/`;
            const headers = {
                Authorization: `Token ${token}`,
            };
            axios
                .put(
                    apiCallURL,
                    {
                        reply_text: this.state.replyText,
                    },
                    { headers }
                )
                .then(res => {
                    if (this.props.remainingReplies !== null && !this.state.currentReply) {
                        const remainingRep = this.props.remainingReplies - 1 >= 0 ? this.props.remainingReplies - 1 : 0;
                        this.props.setRepliesRemaining(remainingRep);
                    }

                    this.setState({
                        replyBoxOpen: false,
                        currentReply: res.data.organizer_reply,
                    });
                   
                    this.props.refreshList();
                    mixpanel.track('Dashboard - Review feed - Reply review posted', this.props.mpProps);
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            console.log('reply validation');
        }
    };

    handleCopy = () => {
        mixpanel.track('Dashboard - Review feed - Copy Review', this.props.mpProps);
        this.setState(
            {
                reviewLinkCopied: true,
            },
            () => {
                navigator.clipboard.writeText(this.props.review.share_link);
                setTimeout(() => {
                    this.setState({ reviewLinkCopied: false });
                }, 2000);
            }
        );
    };

    render () {
        const {
            /* verifyReviewModalOpen, */
            /* shareReviewModalOpen, */
            reviewImagesModalOpen,
            reportReviewModalOpen,
            reviewRepliesModalOpen,
            imageGeneratorFreeModalOpen,
            replyBoxOpen,
            replyText,
            currentReply,
            replyReadyForSubmission,
            reviewIsBookmarked,
            reviewFull,
            reviewShort,
            readingFullReview,
            activeReviewPhoto,
            isReported,
            generateImagePopupOpen,
            reviewLinkCopied,
            reviewRatings,
            smartQuestion,
            isPremium,
            eventID,
            showSmartQuestionTopBanner
        } = this.state;

        const reviewPhotos = this.props.review.photos.map(photo => (
            <div
                className="review-photo-container"
                key={photo.id}
                onClick={() => this.toggleReviewImagesModal(photo.photo)}
            >
                <img src={photo.photo} alt={`Review img ${photo.id}`} />
            </div>
        ));

        const nameList = this.props.review?.profile?.name?.split(' ');
        const firstName = nameList[0] || '';
        const lastName = nameList?.length > 1 ? nameList[nameList.length - 1] : '' || '';

        return (
            <>
                <div className="review-card-container">
                    <div className="review-card">
                        <div className="review-card-main">
                            <div className="review-card-left">
                                <div className="review-card-author-details">
                                    {this.props.review.profile.avatar ? (
                                        <div className="review-card-author-avatar">
                                            {this.props.review.profile.visor_status === 'gold' 
                                            && (
                                                <img 
                                                    src={goldLaurel}
                                                    alt="laurel-half" className="avatar-laurel-frame-left"
                                                /> )}
                                            {this.props.review.profile.visor_status === 'silver' 
                                            && (
                                                <img 
                                                    src={silverLaurel} alt="laurel-half" className="avatar-laurel-frame-left"
                                                />) }
                                            {this.props.review.profile.visor_status === 'bronze'
                                             && (
                                                 <img 
                                                     src={bronzeLaurel} alt="laurel-half" className="avatar-laurel-frame-left"
                                                 />) }
                                            <img
                                                className="review-card-profile-img"
                                                src={this.props.review.profile.avatar}
                                                alt="user-profile-avatar"
                                            />
                                            {this.props.review.profile.visor_status === 'gold' 
                                            && <img src={goldLaurel} alt="laurel-half" className="avatar-laurel-frame-right" /> }
                                            {this.props.review.profile.visor_status === 'silver' 
                                            && (
                                                <img 
                                                    src={silverLaurel} alt="laurel-half" className="avatar-laurel-frame-right"
                                                />) }
                                            {this.props.review.profile.visor_status === 'bronze'
                                             && (
                                                 <img 
                                                     src={bronzeLaurel} alt="laurel-half" className="avatar-laurel-frame-right"
                                                 />) }
                                        </div>
                                    ) : (
                                        <div className="review-card-profile-img-placeholder">
                                            <ProfileIcon />
                                        </div>
                                    )}
                                    {this.props.review.profile.id ?
                                        <a 
                                            href={`https://racecheck.com/profile/${this.props.review.profile.id}/`} 
                                            className="review-card-author review-card-author-link" 
                                            target="_blank" rel="noopener noreferrer"
                                        >
                                            {`${firstName} ${lastName}`}
                                        </a>
                                        :
                                        <p className="review-card-author">
                                            {`${firstName} ${lastName}`}
                                        </p>
                                    }
                                    <p className="review-card-author-review-count">
                                        {this.props.review.profile.reviews_count === 1 ?
                                            <>
                                                First review
                                            </>
                                            :
                                            <>
                                                {this.props.review.profile.reviews_count}{' '}reviews
                                            </>
                                        }
                                    </p>
                                    {this.props.review.bib_number &&
                                        <div className="review-card-bib-no">
                                            <VerifiedIcon />
                                            <p>{this.props.review.bib_number}</p>
                                            <div className="review-card-btn-tooltip">
                                                <span className="rc-btn-tooltip-desc">
                                                    Bib no.
                                                </span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="review-card-right">
                                <div className="review-card-review-data">
                                    <div className="review-card-review-details">
                                        <div className="review-card-mobile-profile">
                                            {this.props.review.profile.avatar ? (
                                                <div className="review-card-author-avatar">
                                                    {this.props.review.profile.visor_status === 'gold' 
                                                    && (
                                                        <img 
                                                            src={goldLaurel}
                                                            alt="laurel-half"
                                                            className="avatar-laurel-frame-left"
                                                        /> )}
                                                    {this.props.review.profile.visor_status === 'silver' 
                                                    && (
                                                        <img 
                                                            src={silverLaurel}
                                                            alt="laurel-half"
                                                            className="avatar-laurel-frame-left"
                                                        />) }
                                                    {this.props.review.profile.visor_status === 'bronze'
                                                    && (
                                                        <img 
                                                            src={bronzeLaurel}
                                                            alt="laurel-half"
                                                            className="avatar-laurel-frame-left"
                                                        />) }
                                                    <img
                                                        className="review-card-profile-img"
                                                        src={this.props.review.profile.avatar}
                                                        alt="user-profile-avatar"
                                                    />
                                                    {this.props.review.profile.visor_status === 'gold' 
                                                    && (
                                                        <img
                                                            src={goldLaurel}
                                                            alt="laurel-half"
                                                            className="avatar-laurel-frame-right"
                                                        /> )}
                                                    {this.props.review.profile.visor_status === 'silver' 
                                                    && (
                                                        <img 
                                                            src={silverLaurel}
                                                            alt="laurel-half"
                                                            className="avatar-laurel-frame-right"
                                                        />) }
                                                    {this.props.review.profile.visor_status === 'bronze'
                                                    && (
                                                        <img 
                                                            src={bronzeLaurel}
                                                            alt="laurel-half"
                                                            className="avatar-laurel-frame-right"
                                                        />) }
                                                </div>
                                            ) : (
                                                <div className="review-card-profile-img-placeholder">
                                                    <ProfileIcon />
                                                </div>
                                            )}
                                            <p className="review-card-author">
                                                {`${firstName} ${lastName.charAt(0)}`}
                                                {`${lastName && '.'}`}
                                            </p>
                                        </div>
                                        <p className="bullet mobile-bullet">•</p>
                                        <div className="review-card-rating-container">
                                            {this.props.review.average_rating > 0 ?
                                                <RatingStars rating={this.props.review.average_rating} />
                                                :
                                                <div className="rating-stars unrated-rating-stars">
                                                    <p className="rating-value">
                                                    Unrated
                                                    </p>
                                                    <EmptyStar />
                                                    <EmptyStar />
                                                    <EmptyStar />
                                                    <EmptyStar />
                                                    <EmptyStar />
                                                </div>
                                            }
                                            <ChevronDown className="review-card-rating-btn" />
                                            <div className="review-card-rating-tooltip">
                                                <div className="rcrt-criteria-container">
                                                    {reviewRatings.map(ratingCriteria => (
                                                        <div key={ratingCriteria.title} className="rcrt-criteria">
                                                            <p>{ratingCriteria.title}</p>
                                                            {ratingCriteria.rating === 0 ?
                                                                <div className="rc-na">
                                                                    <NAIcon />
                                                                    <span>Not applicable</span>
                                                                </div>
                                                                :
                                                                <RatingStars rating={ratingCriteria.rating} />
                                                            }
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <p className="bullet">•</p>
                                        <p>{this.props.review.year}</p>
                                        {this.props.review.distance &&
                                            <>
                                                <p className="bullet">•</p>
                                                <p>{this.props.review.distance}</p>
                                            </>
                                        }
                                        {!!this.props.review.average_rating && this.props.review.average_rating < 3 &&
                                            <>
                                                <p className="bullet">•</p>
                                                <p className="review-card-negative-tag">Negative</p>
                                            </>
                                        }
                                        {/* { this.props.review.is_verified &&
                                            <div className="review-card-verified-container">
                                                <p className="bullet">•</p>
                                                <p className="review-verified-tag"><VerifiedTick /> Verified</p>
                                            </div>
                                        } */}
                                    </div>
                                    <div className="review-card-review-text">
                                        {readingFullReview ? (
                                            <p>
                                                {reviewFull}{' '}
                                                {reviewFull !== reviewShort && (
                                                    <span className="review-card-inline-btn" onClick={this.toggleFullReview}>
                                                        {' '}
                                                        Read less
                                                    </span>
                                                )}
                                            </p>
                                        ) : (
                                            <p>
                                                {reviewShort}
                                                ...{' '}
                                                <span className="review-card-inline-btn" onClick={this.toggleFullReview}>
                                                    Read more
                                                </span>
                                            </p>
                                        )}
                                        {this.props.review.photos.length > 0 && (
                                            <div className="review-card-photos">{reviewPhotos}</div>
                                        )}
                                        {this.state.currentReply && (
                                            <div className="review-card-reply-inline">
                                                <p className="review-reply-inline">{currentReply}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="review-card-review-buttons">
                                    {this.state.currentReply ? (
                                        <div
                                            className={`review-card-btn ${
                                                replyBoxOpen ? 'review-card-btn-disabled' : ''
                                            }`}
                                            onClick={this.toggleReply}
                                        >
                                            <ReplyIcon />
                                            <span>Edit reply</span>
                                        </div>
                                    ) : (
                                        <div
                                            className={`review-card-btn ${
                                                replyBoxOpen ? 'review-card-btn-disabled' : ''
                                            }`}
                                            onClick={() => {
                                                // eslint-disable-next-line
                                                mixpanel.track('Dashboard - Review feed - Reply review click', this.props.mpProps);
                                                this.toggleReply();
                                            }}
                                        >
                                            <ReplyIcon />
                                            <span>Reply</span>
                                        </div>
                                    )}
                                    <div className="review-card-btns-right">
                                        {/* eslint-disable */}
                                        <div
                                            className="review-card-btn review-card-btn-mobile"
                                            onClick={() => {
                                                mixpanel.track('Dashboard - Review feed - Generate social post click', this.props.mpProps);
                                                this.context.selectedOrganisation?.is_premium_subscriber ?
                                                    this.toggleGenerateImagePopup() : this.toggleImageGeneratorFreeModal();
                                                }
                                            }
                                        >
                                        {/* eslint-enable */}
                                            <ImageIcon />
                                            <div className="review-card-btn-tooltip">
                                                <span className="rc-btn-tooltip-desc">
                                                    Generate social post
                                                </span>
                                            </div>
                                        </div>
                                        <div className="review-card-btn" onClick={this.handleCopy}>
                                            <CopyIcon />
                                            <div className="review-card-btn-tooltip">
                                                <span className="rc-btn-tooltip-desc">
                                                    {reviewLinkCopied ?
                                                        'Review link copied'
                                                        :
                                                        'Copy review link'
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div 
                                            className={`review-card-btn review-card-btn-bookmark
                                                ${reviewIsBookmarked ? 'review-card-btn-bookmarked' : ''}`} 
                                            onClick={this.toggleBookmark}
                                        >
                                            {reviewIsBookmarked ?
                                                <BookmarkedIcon />
                                                :
                                                <BookmarkIcon />
                                            }
                                            <div className="review-card-btn-tooltip">
                                                <span className="rc-btn-tooltip-desc">
                                                    {reviewIsBookmarked ? 'Remove bookmark' : 'Bookmark'}
                                                </span>
                                            </div>
                                        </div>  
                                        {/* {this.props.review.reports.length > 0 ? (
                                            <p
                                                className="review-card-btn review-card-btn-reported"
                                                onClick={this.toggleReportReviewModal}
                                            >
                                                Reported
                                            </p>
                                        ) : ( */}
                                        <div 
                                            className={`review-card-btn 
                                            ${this.props.review.reports.length > 0 || isReported ? 
                                                'review-card-btn-reported' : ''}`} 
                                            onClick={this.toggleReportReviewModal}
                                        >
                                            {this.props.review.reports.length > 0 || isReported ?
                                                <ReportedIcon />
                                                :
                                                <ReportIcon />
                                            }
                                            <div className="review-card-btn-tooltip">
                                                <span className="rc-btn-tooltip-desc">
                                                    {this.props.review.reports.length > 0 || isReported ? 'Reported' : 'Report'}
                                                </span>
                                            </div>
                                        </div>
                                        {/* )} */}
                                    </div>
                                    {/* eslint-disable-next-line*/}
                                    {/* <p className="review-card-btn" onClick={this.toggleVerifyReviewModal}>{ this.props.review.is_verified ? "Remove verification" : "Verify"}</p> */}
                                </div>
                            </div>
                        </div>
                        {replyBoxOpen && (
                            <div className="review-card-reply-container">
                                {/* eslint-disable */}
                                <textarea
                                    id="reply-review-box"
                                    value={replyText}
                                    onChange={this.handleChange}
                                    className="input modal-input reply-textarea"
                                    name="replyText"
                                    rows="3"
                                    placeholder={
                                        this.props.remainingReplies === 0 && !this.state.currentReply ? 
                                            `You have 0 replies remaining for this event. Upgrade to Racecheck Premium to post your reply to ${firstName}.`
                                            :
                                            `Your reply will be displayed alongside ${firstName}'s review on your Racecheck page...`
                                    }
                                />
                                {/* eslint-enable */}
                                <div 
                                    className={`reply-remaining-disclaimer 
                                    ${(replyText.length > 5 && this.props.remainingReplies === 0 && !this.state.currentReply)
                                         && 'reply-remaining-disclaimer-open'}`}
                                >
                                    <ExclamationCircleIcon />
                                    <p>This event has 0 replies remaining and will
                                         not be able to post any replies until you upgrade.
                                    </p>
                                </div>
                                <div className="review-card-reply-footer">
                                    {(!this.state.currentReply && this.props.remainingReplies !== null) ?
                                        <div className="rc-review-count-container">
                                            <div className="review-count-icon">
                                                <ReplyCountIcon />
                                                <span>{this.props.remainingReplies}</span>
                                            </div>
                                            <div className="review-count-right">
                                                <p 
                                                    className="rc-replies-copy"
                                                >repl{this.props.remainingReplies === 1 ? 'y' : 'ies'} remaining
                                                </p>
                                                {/* eslint-disable */}
                                                <Link 
                                                    to="/subscription/subscription-plan" 
                                                    className="rc-replies-link"
                                                    onClick={() => {
                                                        mixpanel.track('Dashboard - Review feed - Get unlimited replies click', this.props.mpProps);
                                                    }}
                                                >Get unlimited replies <ArrowRightIcon />
                                                </Link>
                                                {/* eslint-enable */}
                                            </div>
                                        </div>
                                        : null }
                                    <div className="modal-btn-row">
                                        <div
                                            role="button"
                                            className="btn secondary-btn secondary-btn-rounded modal-form-btn"
                                            onClick={this.toggleReply}
                                        >
                                            <CancelIcon />
                                        </div>
                                        <div
                                            role="button"
                                            className={`btn primary-btn modal-form-btn ${
                                                replyReadyForSubmission ? 'primary-btn-main-cta' : 'disabled-primary-btn'
                                            }`}
                                            onClick={this.handleReplySubmit}
                                        >
                                            {this.props.review.organizer_reply ? 'Save changes' : 'Post reply'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* SMART QUESTION CARD */}
                    {smartQuestion &&
                    <>
                        <div className="sq-divider" />
                        <div className={`review-smart-question-card ${isPremium ? '' : 'sq-card-locked'}`}>
                            <div className="sq-inner">
                                {showSmartQuestionTopBanner &&
                                    <div className="sq-top-banner">
                                        <SmartQuestionHiddenIcon />
                                        <p>Smart question responses are only visible to you and are not part of the
                                            public
                                            review
                                        </p>
                                        <SmartQuestionExplainerTooltip type={'icon'} />
                                    </div>
                                }
                                <div className="sq-header">
                                    <Link
                                        className="sq-h-question"
                                        /* eslint-disable-next-line max-len */
                                        to={`/events/${eventID}/smart-questions-report?selectedYear=${this.props.review.year}&selectedQuestion=${smartQuestion.id}`}
                                    >
                                        <RacecheckAIIcon />
                                        <p>{smartQuestion.question}</p>
                                        <ChevronRight className="sq-h-chevron" />
                                    </Link>
                                    {!showSmartQuestionTopBanner && <SmartQuestionExplainerTooltip type={'label'} />}
                                </div>
                                {isPremium ?
                                    <p className="sq-answer">{smartQuestion.answer}</p>
                                    :
                                    <>
                                        <p className="sq-answer sq-answer-blurred">
                                            Upgrade your account to Racecheck Premium to see how your participants 
                                            answered their smart questions and get invaluable insights into their 
                                            opinions about your event, alongside many other great features!
                                        </p>
                                        <div className="sq-upgrade">
                                            <LockIcon />
                                            {/* eslint-disable */}
                                            <Link 
                                                to="/subscription/subscription-plan" 
                                                className="sq-upgrade-link"
                                                onClick={() => {
                                                    mixpanel.track('Dashboard - Review feed - Unlock smart question click', this.props.mpProps);
                                                }}
                                            >Upgrade to Premium
                                            </Link>
                                            <span>&nbsp;to unlock your Smart Question insights!</span>
                                            {/* eslint-enable */}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </>
                    }
                </div>

                {/*eslint-disable*/}
                {/* { verifyReviewModalOpen &&
                    <VerifyReviewModal toggleModal={this.toggleVerifyReviewModal} review={this.props.review} eventID={this.props.eventID}/>
                } */}
                {/* {shareReviewModalOpen && (
                    <ShareReviewModal
                        toggleModal={this.toggleShareReviewModal}
                        review={this.props.review} 
                    />
                )} */}
                {/* eslint-enable*/}
                {reportReviewModalOpen && (
                    <ReportReviewModal 
                        toggleModal={this.toggleReportReviewModal} 
                        review={this.props.review} 
                        toggleIsReported={this.toggleIsReported}
                    />
                )}
                {reviewImagesModalOpen && (
                    <ReviewImagesModal
                        toggleModal={this.toggleReviewImagesModal}
                        photos={this.props.review.photos}
                        activeReviewPhoto={activeReviewPhoto}
                    />
                )}
                {reviewRepliesModalOpen && (
                    <ReviewRepliesModal
                        toggleModal={this.toggleReviewRepliesModal}
                    />
                )}
                {imageGeneratorFreeModalOpen && (
                    <ImageGeneratorFreeModal
                        toggleModal={this.toggleImageGeneratorFreeModal}
                        mpMprops={this.props.mpProps}
                    />
                )}
                {generateImagePopupOpen && (
                    <ReviewImageGenerator
                        toggleModal={this.toggleGenerateImagePopup}
                        firstName={firstName}
                        lastName={lastName}
                        review={this.props.review}
                    />
                )}
            </>
        );
    }
}

ReviewCard.propTypes = {
    review: PropTypes.shape({
        distance: PropTypes.number,
        id: PropTypes.number.isRequired,
        event_id: PropTypes.number.isRequired,
        profile: PropTypes.shape({
            name: PropTypes.string.isRequired,
            avatar: PropTypes.string,
            reviews_count: PropTypes.number,
            visor_status: PropTypes.string,
            id: PropTypes.number
        }).isRequired,
        bib_number: PropTypes.number,
        event_title: PropTypes.string.isRequired,
        year: PropTypes.number.isRequired,
        average_rating: PropTypes.number.isRequired,
        organizer_reply: PropTypes.string,
        clean_review_text: PropTypes.string,
        is_bookmarked: PropTypes.bool,
        photos: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                photo: PropTypes.string.isRequired,
            })
        ),
        reports: PropTypes.instanceOf(Array),
        smart_question: PropTypes.shape({
            id: PropTypes.number,
            question: PropTypes.string,
            answer: PropTypes.string
        })
    }),
    eventID: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['all'])]),
    refreshList: PropTypes.func,
    remainingReplies: PropTypes.number,
    setRepliesRemaining: PropTypes.func,
    mpProps: PropTypes.PropTypes.shape({
        organisation: PropTypes.string,
        premium_subscriber: PropTypes.bool
    }),
    showSmartQuestionTopBanner: PropTypes.bool
};

ReviewCard.defaultProps = {
    review: {
        id: 0,
        event_id: 0,
        profile: {
            name: '',
            avatar: '',
            reviews_count: 0,
        },
        event_title: '',
        year: 0,
        average_rating: 0,
    },
    eventID: 'all',
};

export default ReviewCard;
