import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import SectionNav from '../organiserDashboard/SectionNav';
import TeamMembers from './TeamMembers';
import AppContext from '../app/AppContext';
import ErrorBoundary from '../helpers/errorBoundary';

export default function Team () {
    const appContext = useContext(AppContext);

    return (
        <div className={`section-container ${!appContext.menuOpen && 'section-container-main-nav-closed'}`}>
            <SectionNav section="team" />
            <ErrorBoundary>
                <div
                    className={'section-content section-content-no-bottom-pad'}
                >
                    <Route path="/team/team-members">
                        <TeamMembers selectedOrganisation={appContext.selectedOrganisation} />
                    </Route>
                </div>
            </ErrorBoundary>
        </div>
    );
}
