import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../app/AppContext';
import axios from 'axios';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import SmallLoader from '../../images/small_loader.gif';
import SuccessMessage from '../helpers/successMessage';
import ErrorMessage from '../helpers/errorMessage';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import mixpanel from 'mixpanel-browser';

const Step1 = () => (
    <>
        <div className="cancellation-explainer-modal-section">
            <p className="subscription-modal-header">Cancelling your Racecheck Premium subscription</p>
            <p>
                    If you choose to cancel your subscription, no further payments will be scheduled and 
                    all of your Premium Racecheck Review Boxes 
                    will be downgraded to Free at the end of your current pay-cycle or trial.
            </p>
            {/* <p>
                    Should you change your mind before your downgrade date 
                    (the end of your current pay-cycle or trial)
                    you will be able to resume your subscription where you left off. 
            </p> */}
        </div>

        <div className="cancellation-perks-modal-section">
            <p className="subscription-modal-header">What you’ll lose by cancelling</p>
            <p>
                Your account will lose the following great Racecheck benefits:
            </p>
            <ul className="cancellation-perks-list">
                <li>
                    <div>
                        <CloseIcon />
                    </div>
                    Premium Interactive Review Boxes that boost your entries by over 22%
                </li>
                <li>
                    <div>
                        <CloseIcon />
                    </div>
                    Sales analytics show an average ROI of 30:1 for our premium customers!
                </li>
                <li>
                    <div>
                        <CloseIcon />
                    </div>
                    Rich-Snippets that boost your SEO & organic traffic by over 10%+
                </li>
            </ul>
        </div>
    </>
);

Step1.propTypes = {
    subscriptionDetails: PropTypes.shape({
        quantity: PropTypes.number
    })
};

const Step2 = () => (
    <>
        <div className="cancellation-step2-modal-section">
            <p className="cs2-explainer">
                <span className="cs2-title">
                    <span className="cs2-icon">
                        <ExclamationIcon />
                    </span>
                    <span className="cs2-important">
                        Important:&nbsp;&nbsp;
                    </span>
                </span>
                <span>
                    <b>You&#39;ve currently locked in our best value legacy pricing! </b>
                </span>
            </p>
            <div className="cs2-extra-copy">
                <p>You&#39;ll lose your current pricing if you cancel and return later.</p>
                <p>We&#39;ll apply a <b>free 60 days</b> to your account to if you choose to keep all
                    your powerful Racecheck Premium benefits and lock in the best value pricing today.
                </p>
                <p>Please contact&nbsp;
                    <a href="mailto:support@racecheck.com">support@racecheck.com</a>
                    &nbsp;if you need anything else.
                </p>
            </div>
        </div>
    </>
);

const Step3 = ({ cancelReasons = [], cancelReason, extraText, handleChangeCancelReason, handleChangeExtraText }) => (
    <>
        <div className="cancellation-reason-modal-section">
            <p className="subscription-modal-header">Let us know why you’re cancelling</p>
            <p>
                Please tell us why you&#39;re cancelling. Your feedback helps
                to shape Racecheck for Organisers and ensure we’re building
                 the best platform we can.                                
            </p>
        </div>

        <div className="cancellation-form-modal-section">
            <p className="cancellation-q">What is your main reason for cancelling?<span className="required-field">*</span></p>
            <ul className="cancellation-reasons">
                {cancelReasons.map(item => (
                    <li key={item.id}>
                        <label>
                            <input 
                                type="radio" 
                                name="reason" 
                                value={item.id} 
                                checked={cancelReason === item.id} 
                                onChange={handleChangeCancelReason} 
                            />
                            {item.name}
                        </label>
                    </li>
                ))}
            </ul>
        </div>

        <p className="cancellation-q">Anything else you&#39;d like to tell us?</p>
        <textarea
            className="input modal-input cancellation-textarea"
            onChange={handleChangeExtraText}
            value={extraText}
            placeholder="Further explain why you're cancelling, suggest product improvements, or any other thoughts you have..."
        />
    </>
);

Step3.propTypes = {
    cancelReasons: PropTypes.arrayOf(
        PropTypes.instanceOf(Object)
    ),
    cancelReason: PropTypes.string,
    extraText: PropTypes.string,
    handleChangeExtraText: PropTypes.func,
    handleChangeCancelReason: PropTypes.func
};

const CancelSubscriptionModal = ({ subscriptionDetails, toggleModal }) => {

    const [canCancel, setCanCancel] = useState(true);
    const [triggerSubDataRefresh, setTriggerSubDataRefresh] = useState(false);
    const [currentSuccessMessage, setCurrentSuccessMessage] = useState('');
    const [currentErrorMessage, setCurrentErrorMessage] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [step, setStep] = useState(0);
    const [cancelReasons, setCancelReasons] = useState([]);
    const [cancelReason, setCancelReason] = useState('');
    const [extraText, setExtraText] = useState('');

    const handleChangeCancelReason = ({ target }) => setCancelReason(target.value);
    const handleChangeExtraText = ({ target }) => setExtraText(target.value);

    const { 
        token = localStorage.getItem('token') ,
        selectedOrganisation = {}
    } = useContext(AppContext);

    const { REACT_APP_API_URL } = process.env;

    const handleButtonClick = (buttonCode) => {
        const organisationName = selectedOrganisation?.name;
        switch (buttonCode) {
            case 'CWC':
                // eslint-disable-next-line max-len
                mixpanel.track('Dashboard - Subscription page - Continue with cancellation click', { organisation: organisationName });
                setStep(1);
                return;
            case 'KMB':
                // eslint-disable-next-line max-len
                mixpanel.track('Dashboard - Subscription page - Keep my benefits click', { organisation: organisationName });
                toggleModal(triggerSubDataRefresh);
                return;
            case 'SWTC':
                // eslint-disable-next-line max-len
                mixpanel.track('Dashboard - Subscription page - I still wish to cancel click', { organisation: organisationName });
                setStep(2);
                return;
            default:
                return;
        }
    };

    const handleLockInPricing = () => {
        setLoadingButton(true);
        // eslint-disable-next-line max-len
        mixpanel.track('Dashboard - Subscription page - Lock in my pricing click', { organisation: selectedOrganisation?.name });

        const headers = {
            Authorization: `Token ${token}`,
        };

        // eslint-disable-next-line max-len
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/subscriptions/contact_us/`;

        axios
            .get(apiCallURL,{ headers })
            .then((res) => {
                setLoadingButton(false);
                setCurrentSuccessMessage(res.data?.data);
                setTimeout(() => {
                    toggleModal(triggerSubDataRefresh);
                }, 3000);
            })
            .catch(error => {
                console.log(error.response);
                setLoadingButton(false);
                setCurrentErrorMessage('Something went wrong. Please contact us at support@racecheck.com.');
            });
    };

    const handlePlanCancel = () => {
        if (subscriptionDetails.details.statuses.can_cancel && canCancel) {
            setLoadingButton(true);

            // eslint-disable-next-line max-len
            mixpanel.track('Dashboard - Subscription page - Cancel plan click', { organisation: selectedOrganisation?.name });
        
            const headers = {
                Authorization: `Token ${token}`,
            };
            
            /* eslint-disable-next-line*/
            const unsubscribeCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/subscriptions/${subscriptionDetails.id}/unsubscribe/`;
            /* eslint-disable-next-line*/
            const cancelCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/subscriptions/${subscriptionDetails.id}/cancel/`;

            const cancelQuery = axios.post(
                cancelCallURL,
                { plan_id: parseInt(subscriptionDetails.plan.id) },
                { headers }
            );

            const unsubscribeQuery = !cancelReason.length || axios.post(
                unsubscribeCallURL,
                {
                    reason: cancelReason,
                    extra_text: extraText
                },
                { headers }
            );

            axios.all([cancelQuery, unsubscribeQuery])
                .then(axios.spread(() => {
                    setLoadingButton(false);
                    setTriggerSubDataRefresh(true);
                    setCanCancel(false);
                    setCurrentSuccessMessage('Your Racecheck Premium plan has been cancelled');
                }))
                .catch(error => {
                    console.log(error.response);
                    setCurrentErrorMessage('Your Racecheck Premium subscription could not be cancelled');                    
                    setLoadingButton(false);
                });
        }
        else {
            setCurrentErrorMessage('You cannot currently cancel your Racecheck Premium subscription');
        }
    };

    useEffect(() => {
        /* eslint-disable-next-line*/
        const apiCallURL = `${REACT_APP_API_URL}/api/react/lookup/unsubscribe_reasons/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .get(
                apiCallURL,
                { headers }
            )
            .then((res) => {
                setCancelReasons(res.data.data);
            })
            .catch(error => {
                console.log(error.response);
            });
    }, [REACT_APP_API_URL, token]);

    return (
        <div className="modal-backdrop">
            {currentSuccessMessage && (
                <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={() => setCurrentSuccessMessage('')} />
            )}
            {currentErrorMessage && (
                <ErrorMessage message={currentErrorMessage} resetErrorMessage={() => setCurrentErrorMessage('')} />
            )}
            <div className="modal-padding" />
            <div className="modal-container">
                <div className="modal-header">
                    <button
                        className="modal-close-btn"
                        onClick={() => toggleModal(triggerSubDataRefresh)}
                    >
                        <CloseIcon />
                    </button>
                    <h2 className="modal-header-title modal-title-no-nav">Cancel your subscription</h2>
                </div>
                <div className="modal-scrollable">
                    <div className="modal-content">
                        <div className="modal-content-section">
                            {step === 0 &&
                                <Step1 
                                    subscriptionDetails={subscriptionDetails}
                                    canCancel={canCancel}
                                />
                            }
                            {step === 1 &&
                                <Step2
                                    loadingButton={loadingButton}
                                    handleLockInPricing={handleLockInPricing}
                                />
                            }
                            {step === 2 &&
                                <Step3
                                    cancelReasons={cancelReasons}
                                    cancelReason={cancelReason}
                                    extraText={extraText}
                                    handleChangeExtraText={handleChangeExtraText}
                                    handleChangeCancelReason={handleChangeCancelReason}
                                />
                            }

                            <div className={`modal-btn-row cancellation-mbr ${step !== 0 ? 'sm-modal-btn-row' : ''}`}>
                                {step === 0 &&
                                <>
                                    <div
                                        role="button"
                                        className={`btn sub-fixed-width-btn-large
                                         secondary-btn secondary-btn-rounded modal-form-btn ${
                                            canCancel ? '' : 'disabled-primary-btn'
                                        }`}
                                        onClick={() => handleButtonClick('CWC')}
                                    >
                                        Continue with cancellation
                                    </div>
                                    <button
                                        className="btn modal-form-btn primary-btn sub-fixed-width-btn-medium"
                                        onClick={() => handleButtonClick('KMB')}
                                    >
                                        Keep my benefits
                                    </button>
                                </>
                                }
                                {step === 1 &&
                                    <>
                                        <button
                                            className="btn modal-form-btn secondary-btn secondary-btn-rounded"
                                            onClick={() => setStep(prevState => prevState - 1)}
                                        >
                                            Back
                                        </button>
                                        <div className="cs2-modal-btns">
                                            <div
                                                role="button"
                                                className={`btn sub-fixed-width-btn-medium
                                             secondary-btn secondary-btn-rounded modal-form-btn ${
                                                    canCancel ? '' : 'disabled-primary-btn'
                                                }`}
                                                onClick={() => handleButtonClick('SWTC')}
                                            >
                                                I still wish to cancel
                                            </div>

                                            <button
                                                className="btn modal-form-btn primary-btn sub-fixed-width-btn-medium"
                                                onClick={loadingButton ? null : handleLockInPricing}
                                            >
                                                {loadingButton ?
                                                    <img className="subscription-btn-loader" src={SmallLoader} alt="loader" />
                                                    :
                                                    'Lock in my pricing'
                                                }
                                            </button>
                                        </div>
                                    </>
                                }
                                {step === 2 &&
                                    <>
                                        <button 
                                            className="btn modal-form-btn secondary-btn secondary-btn-rounded"
                                            onClick={() => setStep(prevState => prevState - 1)}
                                        >
                                            Back
                                        </button>
                                        <div
                                            role="button"
                                            className={`btn sub-fixed-width-btn-large
                                             secondary-btn secondary-btn-rounded modal-form-btn ${
                                                canCancel ? '' : 'disabled-secondary-btn'
                                            }`}
                                            onClick={handlePlanCancel}
                                        >
                                            {loadingButton ? (
                                                <img className="subscription-btn-loader" src={SmallLoader} alt="loader" />
                                            ) : 
                                                'Cancel my subscription'
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-padding" />
        </div>
    );
};

CancelSubscriptionModal.propTypes = {
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            statuses: PropTypes.shape({
                can_cancel: PropTypes.bool.isRequired,
            }),
        }),
        id: PropTypes.number.isRequired,
        plan: PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired,
        quantity: PropTypes.number
    }).isRequired,
    toggleModal: PropTypes.func,
};

CancelSubscriptionModal.defaultProps = {
    subscriptionDetails: {
        details: {
            statuses: {
                can_cancel: false,
            },
        },
    },
    id: 0,
    plan: {
        id: 0,
    },
};

export default CancelSubscriptionModal;
