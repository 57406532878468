import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';

import ErrorMessage from '../helpers/errorMessage';
import SuccessMessage from '../helpers/successMessage';
import SmallLoader from '../../images/small_loader.gif';
import { isValidEmail } from '../helpers/validationFunctions';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as AdminIcon } from '../../images/icons/business.svg';
import { ReactComponent as ManagerIcon } from '../../images/icons/id-card.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as AddIcon } from '../../images/icons/plus.svg';
import { ReactComponent as RemoveIcon } from '../../images/icons/remove.svg';
import { ReactComponent as SentIcon } from '../../images/icons/mail-new-color.svg';
import RatingStars from '../helpers/starGenerator';
import { ReactComponent as EmptyStar } from '../../images/stars/gold_star_empty.svg';

import AppContext from '../app/AppContext';


const AddTeamMemberModal = ({ toggleModal, eventsList }) => {
    const appContext = useContext(AppContext);
    const [activeStep, setActiveStep] = useState(0);
    const [loadingButton, setLoadingButton] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedRole, setSelectedRole] = useState(1);
    const [unassignedEvents, updateUnassignedEvents] = useState(eventsList);
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [currentSuccessMessage, setCurrentSuccessMessage] = useState('');
    const [currentErrorMessage, setCurrentErrorMessage] = useState('');

    const resetSuccessMessage = () => setCurrentSuccessMessage('');
    const resetErrorMessage = () => setCurrentErrorMessage('');

    useEffect(() => {
        // getSizeOptions();
        // eslint-disable-next-line
    }, []);

    const handleEventAssign = (event) => {
        updateUnassignedEvents(unassignedEvents.filter(item => item.id !== event.id));
        setSelectedEvents([event, ...selectedEvents]);
    };

    const handleEventUnassign = (event) => {
        setSelectedEvents(selectedEvents.filter(item => item.id !== event.id));
        updateUnassignedEvents([event, ...unassignedEvents]);
    };

    const handleInviteTeamMemberSubmit = () => {
        setLoadingButton(true);

        let eventIDList = [];
        if (selectedRole === 1) {
            eventIDList = selectedEvents.map((event) => event.id);
        }

        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = appContext.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/invite/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios.post(apiCallURL, {
            invitee_first_name: firstName,
            invitee_last_name: lastName,
            invitee_email: email,
            events: eventIDList
        }, { headers })
            .then(res => {
                setLoadingButton(false);
                setCurrentSuccessMessage(res.data.message);
                setActiveStep(2);
            })
            .catch(error => {
                console.log(error);
                setCurrentErrorMessage(error.response.data.message);
            })
            .finally(() => {
                setLoadingButton(false);
            });
    };
    
    return (
        <div className="modal-backdrop">
            {currentSuccessMessage && (
                <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={resetSuccessMessage} />
            )}
            {currentErrorMessage && (
                <ErrorMessage message={currentErrorMessage} resetErrorMessage={resetErrorMessage} />
            )}
            <div className="modal-padding" />
            <div className="modal-container">
                <div className="modal-header">
                    <button className="modal-close-btn" onClick={() => toggleModal(false)}>
                        <CloseIcon />
                    </button>
                    <h2 className="modal-header-title modal-title-no-nav">Invite new team member</h2>
                </div>
                <div className="modal-scrollable">
                    <div className="modal-content edit-team-member-modal add-team-member-modal">
                        <div className="modal-content-section">
                            {activeStep === 0 &&
                                <>
                                    {/* <p className="rig-section-header">Select review content</p> */}
                                    <div className="modal-input-row">
                                        <div className="modal-input-group-half">
                                            <label htmlFor="account-details-first-name" className="modal-input-label">
                                                First name <span className="required-field">*</span>
                                            </label>
                                            <input
                                                id="account-details-first-name"
                                                value={firstName}
                                                onChange={e => setFirstName(e.target.value)}
                                                className="input modal-input"
                                                name="firstName"
                                                type="text"
                                            />
                                        </div>
                                        <div className="modal-input-group-half">
                                            <label htmlFor="account-details-last-name" className="modal-input-label">
                                                Surname <span className="required-field">*</span>
                                            </label>
                                            <input
                                                id="account-details-last-name"
                                                value={lastName}
                                                onChange={e => setLastName(e.target.value)}
                                                className="input modal-input"
                                                name="lastName"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                    <div className="modal-input-row">
                                        <div className="modal-input-group">
                                            <label htmlFor="account-details-email" className="modal-input-label">
                                                Email address <span className="required-field">*</span>
                                            </label>
                                            <input
                                                id="account-details-email"
                                                value={email}
                                                onChange={e => setEmail (e.target.value.trim())}
                                                className="input modal-input"
                                                name="email"
                                                type="text"
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                            {activeStep === 1 &&
                                <>
                                    {/* <p className="rig-section-header">Select permissions content</p> */}
                                    <div className="tm-section">
                                        <p className="tm-section-header">Team member role</p>
                                        <div className="tm-role-container">
                                            <div
                                                className={`tm-role 
                                                    ${selectedRole === 0 ? 'tm-role-active' : ''}
                                                    ${!appContext.selectedOrganisation?.is_admin ? 'tm-role-disabled' : ''}
                                                `}
                                                onClick={
                                                    !appContext.selectedOrganisation?.is_admin ? null : () => setSelectedRole(0)
                                                }
                                            >
                                                <div className="tm-role-icon">
                                                    <AdminIcon/>
                                                </div>
                                                <div className="tm-role-copy">
                                                    <h3>Organisation Admin</h3>
                                                    <p>Able to view and manage all events, team members and
                                                        settings.</p>
                                                </div>
                                                <div className="tm-role-check">
                                                    <TickIcon/>
                                                </div>
                                            </div>
                                            <div
                                                className={`tm-role ${selectedRole === 1 ? 'tm-role-active' : ''}`}
                                                onClick={() => setSelectedRole(1)}
                                            >
                                                <div className="tm-role-icon">
                                                    <ManagerIcon />
                                                </div>
                                                <div className="tm-role-copy">
                                                    <h3>Event Manager</h3>
                                                    <p>Only able to view and manage the events they are assigned to.</p>
                                                </div>
                                                <div className="tm-role-check">
                                                    <TickIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {selectedRole === 1 &&
                                    <div className="tm-section">
                                        <p className="tm-section-header">Assigned events</p>
                                        {selectedEvents.length ?
                                            <div className="tm-events-list-container">
                                                {selectedEvents.map(event => (
                                                    <div className="tm-el-event" key={event.id}>
                                                        <div
                                                            className="tm-el-event-inner"
                                                            onClick={() => handleEventUnassign(event)}
                                                        >
                                                            <div className="event-details">
                                                                {/* <div className="event-img">
                                                                </div> */}
                                                                <div className="event-copy">
                                                                    <p className="event-c-title">{event.title}</p>
                                                                    <div className="event-c-info">
                                                                        {event.average_rating > 0 ?
                                                                            <>
                                                                                <RatingStars rating={event.average_rating} />
                                                                                <p className="rating-count">({event.reviews})</p>
                                                                            </>
                                                                            :
                                                                            <div className="rating-stars rs-unreviewed">
                                                                                <p className="rating-value">Unreviewed</p>
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                            </div>
                                                                        }
                                                                        {/* <span className="bullet">•</span>
                                                                        <p>1 mananger</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="event-btn" onClick={() => handleEventUnassign(event)}>
                                                                <RemoveIcon className="event-btn-remove" />
                                                                <div className="tm-eb-tooltip">
                                                                    <p>Unassign</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="tm-events-list-container tm-elc-empty">
                                                <p>Select one or more events below to assign {firstName} access</p>
                                            </div>
                                        }
                                    </div>
                                    }
                                    {selectedRole === 1 &&
                                    <div className="tm-section">
                                        <p className="tm-section-header">Other events</p>
                                        {unassignedEvents?.length ?
                                            <div className="tm-events-list-container">
                                                {unassignedEvents.map(event => (
                                                    <div className="tm-el-event" key={event.id}>
                                                        <div
                                                            className="tm-el-event-inner"
                                                            onClick={() => handleEventAssign(event)}
                                                        >
                                                            <div className="event-details">
                                                                {/* <div className="event-img">
                                                                </div> */}
                                                                <div className="event-copy">
                                                                    <p className="event-c-title">{event.title}</p>
                                                                    <div className="event-c-info">
                                                                        {event.average_rating > 0 ?
                                                                            <>
                                                                                <RatingStars rating={event.average_rating} />
                                                                                <p className="rating-count">({event.reviews})</p>
                                                                            </>
                                                                            :
                                                                            <div className="rating-stars rs-unreviewed">
                                                                                <p className="rating-value">Unreviewed</p>
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                                <EmptyStar />
                                                                            </div>
                                                                        }
                                                                        {/* <span className="bullet">•</span>
                                                                        <p>1 mananger</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="event-btn" onClick={() => handleEventAssign(event)}>
                                                                <AddIcon className="event-btn-add" />
                                                                <div className="tm-eb-tooltip">
                                                                    <p>Assign</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            <div className="tm-events-list-container tm-elc-empty">
                                                <p>All events have been assigned</p>
                                            </div>
                                        }
                                    </div>
                                    }
                                </>
                            }
                            {activeStep === 2 &&
                                <div className="tm-section">
                                    <div className="tm-invite-success-container">
                                        <SentIcon />
                                        <h3>Your invite has been sent</h3>
                                        <p>
                                            {firstName} will receive an email instructing them how to <br />
                                            join your organisation.
                                        </p>
                                    </div>
                                </div>
                            }
                            {activeStep !== 2 &&
                                <div className="modal-btn-row">
                                    <button 
                                        className=
                                            {`btn modal-form-btn secondary-btn secondary-btn-rounded 
                                            ${activeStep === 0 ? 'hidden-btn' : ''}
                                            `}
                                        onClick={() => setActiveStep(prevStep => prevStep - 1)}
                                        disabled={activeStep === 0}
                                    >
                                            Back
                                    </button>
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                        >
                                            <img
                                                className="subscription-btn-loader"
                                                src={SmallLoader}
                                                alt="loader"
                                            />
                                        </div>
                                    ) : (
                                        <button
                                            className=
                                                {`btn primary-btn modal-form-btn 
                                                ${(loadingButton || 
                                                    !firstName || 
                                                    !lastName || 
                                                    !(email && isValidEmail(email)) || 
                                                    (activeStep === 1 && selectedRole === 1 && !selectedEvents?.length)) ? 
                                                    'disabled-primary-btn' : ''}
                                                `}
                                            onClick={() => {
                                                if (activeStep === 1 ) {
                                                    return handleInviteTeamMemberSubmit();
                                                }
                                                setActiveStep(prevStep => prevStep + 1);
                                            }}
                                            disabled={!firstName || 
                                                !lastName || 
                                                !(email && isValidEmail(email)) || 
                                                (activeStep === 1 && selectedRole === 1 && !selectedEvents?.length)}
                                        >
                                            {activeStep === 1 ? 'Send invite' : 'Continue to permissions'}
                                        </button>
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-padding" />
        </div>
    );
};

export default AddTeamMemberModal;

AddTeamMemberModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
    eventsList: PropTypes.array.isRequired
};