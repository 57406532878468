import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import Checkbox from '../helpers/checkbox';
import Loader from '../helpers/loader';
import Search from '../helpers/autocomplete';
import AppContext from '../app/AppContext';
import { isValidURL } from '../helpers/validationFunctions';
import countries from '../helpers/countries';
import ReactPixel from 'react-facebook-pixel';
import RequestToJoinCard from './RequestToJoinCard';
import SuccessMessage from '../helpers/successMessage';
import ErrorMessage from '../helpers/errorMessage';
import { localiseText } from '../../utils';

class CreateOrganisation extends Component {
    static contextType = AppContext;

    constructor (props, context) {
        super(props);

        // Rewriting URL after social logins to avoid server error on page refresh
        if (
            window.location.href.includes('google') ||
            window.location.href.includes('facebook') ||
            window.location.href.includes('strava')
        ) {
            window.location.href = '/';
        }

        const initialCountryCodes = countries.map(countryCodes => countryCodes);
        /* eslint-disable */
        this.state = {
            currentSuccessMessage: '',
            currentErrorMessage: '',
            organisationPendingRequests: context.user?.organisation_pending_requests || [],
            selectedOrganisation: null,
            sportOptions: [],
            organisationSizes: [],
            organisationRegistrationPlatforms: [],
            countryCodeOptions: initialCountryCodes,
            inputErrors: {},
            formSubmitted: false,
            creationPage: context.user?.organisation_lead ? 1 : 0,
            organisationName: context.user?.organisation_lead?.name ? context.user?.organisation_lead?.name : '',
            organisationSize: context.user?.organisation_lead?.size_tier ? context.user?.organisation_lead?.size_tier : '',
            organisationRole: '',
            organisationAddressLine1: context.user?.organisation_lead?.address?.address_line1 ? context.user?.organisation_lead?.address?.address_line1 : '',
            organisationAddressLine2: context.user?.organisation_lead?.address?.address_line2 ? context.user?.organisation_lead?.address?.address_line2 : '',
            organisationAddressCity: context.user?.organisation_lead?.address?.city ? context.user?.organisation_lead?.address?.city : '',
            organisationAddressPostcode: context.user?.organisation_lead?.address?.postcode ? context.user?.organisation_lead?.address?.postcode : '',
            organisationAddressCountry: context.user?.organisation_lead?.address?.country?.id ? context.user?.organisation_lead?.address?.country?.id : '',
            organisationCountryCode: context.user?.organisation_lead?.phone?.country_id ? context.user?.organisation_lead?.phone?.country_id : '',
            organisationNumber: context.user?.organisation_lead?.phone?.number ? context.user?.organisation_lead?.phone?.number : '',
            organisationWebsite: context.user?.organisation_lead?.website ? context.user?.organisation_lead?.website : '',
            organisationRegistrationPlatform: context.user?.organisation_lead?.registration_platform ? context.user?.organisation_lead?.registration_platform : '',
            organisationSports: {},
        };
        /* eslint-enable */
    }

    componentDidMount () {
        const token = this.context.token
            ? this.context.token
            : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };

        let initialOrganisationSizes = [];
        let apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/size_tiers/`;

        // Fetch Organisation Sizes
        axios
            .get(apiCallURL, { headers })
            .then(res => res.data)
            .then(data => {
                initialOrganisationSizes = data?.data?.map(({ id, title, range }) => ({ id, name: `${title} (${range})` }));

                this.setState({
                    organisationSizes: initialOrganisationSizes,
                });
            });

        // Fetch Race Categories
        let initialSports = [];
        apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/race_categories/`;
        axios
            .get(apiCallURL, { headers })
            .then(res => res.data)
            .then(data => {
                initialSports = data.map(sport => sport);
                this.setState({
                    organisationSports: initialSports.reduce(
                        (options, option) => ({
                            ...options,
                            [option.id]: false,
                        }),
                        {}
                    ),
                });
                this.setState({
                    sportOptions: initialSports,
                });
            });

        // Fetch Organisation Registration Platforms
        let initialOrganisationRegistrationPlatforms = [];
        apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/registration_platforms/`;

        axios
            .get(apiCallURL, { headers })
            .then(res => res.data)
            .then(data => {
                initialOrganisationRegistrationPlatforms = data?.data?.map(({ id, name }) => ({ id, name: `${name}` }));

                this.setState({
                    organisationRegistrationPlatforms: initialOrganisationRegistrationPlatforms,
                });
            });
    }

    handleOrganisationSelection = (organisationId, organisationName) => {
        this.setState({
            selectedOrganisation: { id: organisationId, name: organisationName }
        });
    };

    handleRequestToJoinOrganisation = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        // eslint-disable-next-line
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.state.selectedOrganisation.id}/requests_to_join/`;
        axios.post(apiCallURL, {}, { headers }).then(() => {
            this.displaySuccessMessage(`Your request to join ${this.state.selectedOrganisation.name} has been sent`);
            this.setState(prevState => ({
                organisationPendingRequests: [...prevState.organisationPendingRequests,
                    { id: this.state.selectedOrganisation.id, organisation: this.state.selectedOrganisation.name }],
                selectedOrganisation: null
            }));
        }).catch(error => {
            this.displayErrorMessage(`Your request to join ${this.state.selectedOrganisation.name} could not be created. 
            Please refresh or try again later.`);
            console.log(error.response?.data?.errors);
        });
    };

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    handleChange = e => {
        if (e.target.name === 'organisationAddressCountry') {
            this.setState({
                [e.target.name]: e.target.value,
                organisationCountryCode: e.target.value,
            });
        }
        else if (e.target.name === 'organisationWebsite') {
            if (e.target.value.length > 3) {
                if (!isValidURL(e.target.value)) {
                    this.setState({
                        ...this.state.inputErrors,
                        websiteError: true,
                    });
                }
                else {
                    this.setState({
                        ...this.state.inputErrors,
                        websiteError: null,
                    });
                }
            }
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
        else {
            this.setState({
                [e.target.name]: e.target.value,
            });
        }
    };

    checkSportsSelected = e => {
        e.preventDefault();
        if (
            Object.keys(this.state.organisationSports).every(
                k => !this.state.organisationSports[k]
            )
        ) {
            const errorList = {};
            errorList.sportsError = true;
            this.setState({
                inputErrors: errorList,
            });
        }
        else {
            this.handleSubmit();
        }
    };

    handleSubmit = () => {
        this.setState({ formSubmitted: true });
        let newCountryCode;
        let organiserWebsite = this.state.organisationWebsite;
        if (
            organiserWebsite.substring(0, 8) !== 'https://' &&
            organiserWebsite.substring(0, 7) !== 'http://'
        ) {
            organiserWebsite = `https://${organiserWebsite}`;
        }
        for (let i = 0; i < this.state.countryCodeOptions.length; i++) {
            if (
                this.state.countryCodeOptions[i].id.toString() ===
                this.state.organisationCountryCode.toString()
            ) {
                newCountryCode = this.state.countryCodeOptions[
                    i
                ].phone.toString();
            }
        }
        const organisationData = {
            name: this.state.organisationName,
            size_tier: this.state.organisationSize,
            role: this.state.organisationRole,
            address: {
                address_line1: this.state.organisationAddressLine1,
                address_line2: this.state.organisationAddressLine2,
                city: this.state.organisationAddressCity,
                postcode: this.state.organisationAddressPostcode,
                country_id: this.state.organisationAddressCountry,
            },
            phone: newCountryCode + this.state.organisationNumber,
            website: organiserWebsite,
            registration_platform: this.state.organisationRegistrationPlatform,
            claim: this.context.getClaimId()
        };
        const token = this.context.token
            ? this.context.token
            : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/`;
        axios
            .post(apiCallURL, organisationData, {
                headers,
            })
            .then(res => {
                ReactPixel.trackCustom('Organisation Created', { organisation: res.data.name });
                const selectedOrganisationItem = {
                    id: res.data.id,
                    name: res.data.name,
                    slug: res.data.slug,
                    published: res.data.published,
                    role: res.data.role,
                    is_premium_subscriber: res.data.is_premium_subscriber,
                };
                localStorage.removeItem('claim');
                this.context.setSelectedOrganisation(selectedOrganisationItem);
                this.context.setOrgMembershipStatus('member-of-org');
                this.context.refreshUserDetails();
                this.props.history.push('/events/events');
            })
            .catch(error => {
                console.log(error);
                console.log('error.response',error?.response);
                if (error.response) {
                    const formErrors = Object.keys(error.response.data.errors);
                    const errorList = {};
                    if (formErrors.includes('phone')) {
                        errorList.phoneError = true;
                    }
                    if (formErrors.includes('website')) {
                        errorList.websiteError = true;
                    }
                    this.setState({
                        formSubmitted: false,
                        creationPage: 2,
                        inputErrors: errorList,
                    });
                }
            });
    };

    updatePage = e => {
        e.preventDefault();
        if (this.state.creationPage < 2) {
            this.setState({
                creationPage: this.state.creationPage + 1,
            });
        }
        else {
            this.handleSubmit();
        }
    };

    updatePageReverse = e => {
        e.preventDefault();
        if (this.state.creationPage > 0) {
            this.setState({
                creationPage: this.state.creationPage - 1,
            });
        }
    };

    handleCheckboxChange = changeEvent => {
        const id = changeEvent.target.value;
        this.setState(prevState => ({
            organisationSports: {
                ...prevState.organisationSports,
                [id]: !prevState.organisationSports[id],
            },
        }));
    };

    createCheckbox = option => (
        <Checkbox
            label={option.name}
            isSelected={this.state.organisationSports[option.id]}
            onCheckboxChange={this.handleCheckboxChange}
            key={option.id}
            value={option.id}
        />
    );

    createCheckboxes = () => this.state.sportOptions.map(this.createCheckbox);

    createSelectOptions = () =>
        this.state.organisationSizes.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));

    createCountryCodeSelectOptions = () =>
        this.state.countryCodeOptions.map(option => (
            <option key={option.id} value={option.id}>
                ({option.phone}) {option.name}
            </option>
        ));

    createCountrySelectOptions = () =>
        this.state.countryCodeOptions.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));

    createRegistrationPlatformSelectOptions = () =>
        this.state.organisationRegistrationPlatforms.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));

    render () {
        const {
            currentSuccessMessage,
            currentErrorMessage,
            organisationPendingRequests,
            selectedOrganisation,
            inputErrors,
            formSubmitted,
            creationPage,
            organisationName,
            organisationSize,
            organisationRole,
            organisationAddressLine1,
            organisationAddressLine2,
            organisationAddressCity,
            organisationAddressPostcode,
            organisationAddressCountry,
            organisationCountryCode,
            organisationNumber,
            organisationWebsite,
            organisationRegistrationPlatform,
        } = this.state;
        let formSection;
        let buttonText;
        if (creationPage === 1) {
            buttonText = 'Next';
            formSection = (
                <div>
                    <form className="log-in-form" onSubmit={this.updatePage}>
                        <div className="input-group">
                            <label htmlFor="organisation-name">
                                Name of your {localiseText('organisation')}{' '}
                                <span className="required-field">*</span>
                            </label>
                            <input
                                id="organisation-name"
                                value={organisationName}
                                onChange={this.handleChange}
                                className="input"
                                name="organisationName"
                                type="text"
                                placeholder="Top Events"
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="organisation-size">
                                {localiseText('Organisation')} size tier{' '}
                                <span className="required-field">*</span>
                            </label>
                            <select
                                className="input"
                                name="organisationSize"
                                onChange={this.handleChange}
                                value={organisationSize}
                                required
                            >
                                <option value="">Select number of annual participants</option>
                                {this.createSelectOptions()}
                            </select>
                        </div>
                        <div className="input-group">
                            <label htmlFor="organisation-role">
                                Your job title in the {localiseText('organisation')}{' '}
                                <span className="required-field">*</span>
                            </label>
                            <input
                                id="organisation-role"
                                value={organisationRole}
                                onChange={this.handleChange}
                                className="input"
                                name="organisationRole"
                                type="text"
                                placeholder="Race Director"
                                required
                            />
                        </div>
                        <div className="log-in-form-submit">
                            <button
                                id="log-in-submit"
                                className="log-in-btn btn"
                            >
                                {buttonText}
                            </button>
                        </div>
                    </form>
                </div>
            );
        }
        else if (creationPage === 2) {
            buttonText = `Create ${localiseText('organisation')}`;
            formSection = (
                <div>
                    <form className="log-in-form" onSubmit={this.updatePage}>
                        <div className="input-group">
                            <label htmlFor="organisation-address">
                                {localiseText('Organisation')} address{' '}
                                <span className="required-field">*</span>
                            </label>
                            <input
                                id="organisation-address"
                                value={organisationAddressLine1}
                                onChange={this.handleChange}
                                className="input org-address-input"
                                name="organisationAddressLine1"
                                type="text"
                                placeholder="Address line 1"
                                required
                            />
                            <input
                                id="organisation-address"
                                value={organisationAddressLine2}
                                onChange={this.handleChange}
                                className="input org-address-input"
                                name="organisationAddressLine2"
                                type="text"
                                placeholder="Address line 2 (optional)"
                            />
                            <div className="org-address-double-row">
                                <input
                                    id="organisation-address"
                                    value={organisationAddressCity}
                                    onChange={this.handleChange}
                                    className="input org-address-input org-address-input-half"
                                    name="organisationAddressCity"
                                    type="text"
                                    placeholder="City"
                                    required
                                />
                                <input
                                    id="organisation-address"
                                    value={organisationAddressPostcode}
                                    onChange={this.handleChange}
                                    className="input org-address-input org-address-input-half"
                                    name="organisationAddressPostcode"
                                    type="text"
                                    placeholder="Postcode"
                                    required
                                />
                            </div>
                            <select
                                className="input org-address-input"
                                id="organisation-address"
                                name="organisationAddressCountry"
                                onChange={this.handleChange}
                                value={organisationAddressCountry}
                                required
                            >
                                <option value="" disabled>
                                    Select country
                                </option>
                                {this.createCountrySelectOptions()}
                            </select>
                        </div>
                        <div className="input-group">
                            <label htmlFor="organisation-number">
                                {localiseText('Organisation')} contact number{' '}
                                <span className="required-field">*</span>
                            </label>
                        
                            <div className="input-phone-with-code">
                                <select
                                    className="input country-code-input"
                                    name="organisationCountryCode"
                                    onChange={this.handleChange}
                                    value={organisationCountryCode}
                                    required
                                >
                                    <option value="" disabled>
                                        Select code
                                    </option>
                                    {this.createCountryCodeSelectOptions()}
                                </select>
                                <input
                                    id="organisation-number"
                                    value={organisationNumber}
                                    onChange={this.handleChange}
                                    className="input org-number-input"
                                    name="organisationNumber"
                                    type="text"
                                    placeholder="7123456789"
                                    required
                                />
                            </div>
                            {inputErrors.phoneError ? (
                                <div className="small-form-error">
                                    Invalid phone number. Check the formatting
                                    of your number is correct.
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="input-group">
                            <label htmlFor="organisation-website">
                                {localiseText('Organisation')} website{' '}
                                <span className="required-field">*</span>
                            </label>
                           
                            <input
                                id="organisation-website"
                                value={organisationWebsite}
                                onChange={this.handleChange}
                                className="input"
                                name="organisationWebsite"
                                type="text"
                                placeholder="www.topevents.com"
                                required
                            />
                            {inputErrors.websiteError ? (
                                <div className="small-form-error">
                                    Invalid website. Check the formatting of
                                    your website address is correct.
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className="input-group">
                            <label htmlFor="organisation-registration-platform">
                                Registration Platform{' '}
                                <span className="required-field">*</span>
                            </label>

                            <select
                                className="input org-registration-platform-input"
                                id="organisation-registration-platform"
                                name="organisationRegistrationPlatform"
                                onChange={this.handleChange}
                                value={organisationRegistrationPlatform}
                                required
                            >
                                <option value="" disabled>
                                    Select registration platform
                                </option>
                                {this.createRegistrationPlatformSelectOptions()}
                            </select>
                        </div>
                        <div className="log-in-form-submit">
                            <button
                                id="log-in-submit"
                                className="log-in-btn btn"
                            >
                                {buttonText}
                            </button>
                        </div>
                    </form>
                </div>
            );
        }
       
        else if (creationPage === 0) {
            buttonText = `Create a new ${localiseText('organisation')}`;
            formSection = (
                <div>
                    {!selectedOrganisation &&
                        <p className="organisation-list-copy">
                            Check if your {localiseText('organisation')} already exists on Racecheck
                            using the search below.
                        </p>
                    }

                    <Search
                        placeholder={`Search for your ${localiseText('organisation')}`}
                        apiEndpoint={
                            '/api/react/organisation-autocomplete-react/'
                        }
                        handleOrganisationSelection={this.handleOrganisationSelection}
                    />

                    {selectedOrganisation &&
                        <p className="organisation-list-copy">
                            Request to join your {localiseText('organisation')} below, or contact the owner to be invited.
                        </p>
                    }

                    {organisationPendingRequests.length > 0 &&
                        <div className="pending-requests-to-join">
                            <h4>Your pending requests:</h4>
                            {
                                organisationPendingRequests.map((requestToJoin, index) =>
                                    <RequestToJoinCard key={index} title={requestToJoin.organisation} />
                                )}
                        </div>
                    }

                    {selectedOrganisation &&
                    <>
                        <br />
                        <button className="log-in-btn btn"
                            onClick={this.handleRequestToJoinOrganisation}
                        >
                            Request to join {selectedOrganisation.name}
                        </button>
                    </>
                    }
                    <div className="sign_up_divider divider-spaced">or</div>
                    <div className="log-in-form-submit">
                        <button
                            id="log-in-submit"
                            className="log-in-btn btn"
                            onClick={this.updatePage}
                        >
                            {buttonText}
                        </button>
                    </div>
                </div>
            );
        }

        return (
            <div className="create-organisation-wrapper">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage}
                        resetSuccessMessage={this.resetSuccessMessage}
                    />
                )}
                {currentErrorMessage && (
                    <ErrorMessage message={currentErrorMessage} resetErrorMessage={this.resetErrorMessage} />
                )}
                {formSubmitted ? (
                    <div className="social-login-loading-container">
                        <Loader />
                        <p>Creating your {localiseText('organisation')}...</p>
                    </div>
                ) : (
                    <div className="logged_out_container">
                        <img
                            src={require('../../images/racecheck_logo_black.svg')}
                            width="250px"
                            alt="racecheck-logo"
                        />
                        <div className="log_in_container">
                            <h2>
                                {creationPage === 0
                                    ? `Get started with your ${localiseText('organisation')}`
                                    : `Create your ${localiseText('organisation')}`}
                            </h2>
                            {formSection}
                            {/* <div className="log-in-form-submit">
                            <button id="log-in-submit" className="log-in-btn btn" onClick={this.updatePage}>{buttonText}</button>
                        </div> */}
                            {creationPage === 0 ? (
                                <p className="log-in-sign-up-link">
                                    <span onClick={this.context.handleLogOut}>
                                        Click here
                                    </span>{' '}
                                    to cancel
                                </p>
                            ) : (
                                <p className="create-organisation-step">
                                    Step {creationPage} of 2{' '}
                                    <span className="create-org-bullet">•</span>{' '}
                                    <span
                                        className="create-org-back-btn"
                                        onClick={this.updatePageReverse}
                                    >
                                        Go back
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(CreateOrganisation);
