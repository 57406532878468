import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../organiserDashboard/Modal.css';
import moment from 'moment';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import SuccessMessage from '../helpers/successMessage';
import SmallLoader from '../../images/small_loader.gif';
import ErrorMessage from '../helpers/errorMessage';
// import {ReactComponent as ExclamationIcon} from '../../images/icons/exclamation_circle.svg';
import { ReactComponent as MastercardIcon } from '../../images/mastercard.svg';
import AppContext from '../app/AppContext';
import SummaryCard from './SummaryCard';
import { isEmpty } from 'lodash';


class RestartSubscriptionModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        const momentCancellationDate = moment(this.props.subscriptionDetails.cancellation_date, 'YYYY-MM-DD');

        this.state = {
            canResume: true,
            triggerSubDataRefresh: false,
            cancellationDateString: moment(momentCancellationDate).format('Do MMMM YYYY'),
        };
    }

    componentDidMount () {
        const { payment_method: { creditcard_id }, credit_cards } = this.props.subscriptionDetails.details;
        this.setState({
            paymentMethod: credit_cards.find(card => card.id === creditcard_id)
        });
    }

    componentDidUpdate (prevProps) {
        const { payment_method: { creditcard_id }, credit_cards } = this.props.subscriptionDetails.details;

        if (creditcard_id !== prevProps.subscriptionDetails.details.payment_method.creditcard_id)
            this.setState({
                paymentMethod: credit_cards.find(card => card.id === creditcard_id)
            });
    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    handleOpenPaymentMethodModal = () => {
        this.props.toggleModal();
        this.props.toggleNewPaymentMethodModal(false, this.props.toggleModal);
    };

    handleOpenDetailsModal = () => {
        this.props.toggleModal();
        this.props.toggleEditDetailsModal(false, this.props.toggleModal);
    }

    handlePlanRestart = () => {
        if (this.props.subscriptionDetails.details.statuses.can_resume && this.state.canResume) {
            this.setState(
                {
                    loadingButton: true,
                },
                () => {
                    const token = this.context.token ? this.context.token : localStorage.getItem('token');
                    const { REACT_APP_API_URL } = process.env;
                    const { id } = this.context.selectedOrganisation;
                    const { subscriptionDetails } = this.props;
                    const apiCallURL = `
                        ${REACT_APP_API_URL}/api/react/organisations/${id}/subscriptions/${subscriptionDetails.id}/resume/
                    `;
                    const headers = {
                        Authorization: `Token ${token}`,
                    };
                    axios
                        .post(
                            apiCallURL,
                            {
                                plan_id: parseInt(subscriptionDetails.plan.id),
                            },
                            { headers }
                        )
                        .then(() => {
                            this.setState(
                                {
                                    loadingButton: false,
                                    triggerSubDataRefresh: true,
                                    canResume: false,
                                },
                                () => {
                                    this.displaySuccessMessage('Your Racecheck Premium plan has been resumed');
                                }
                            );
                        })
                        .catch(error => {
                            console.log(error.response);
                            this.displayErrorMessage('You cannot currently resume your Racecheck Premium subscription');
                            this.setState({
                                loadingButton: false,
                            });
                        });
                }
            );
        }
        else {
            this.displayErrorMessage('You cannot currently resume your Racecheck Premium subscription');
        }
    };

    render () {
        const {
            currentSuccessMessage,
            currentErrorMessage,
            loadingButton,
            triggerSubDataRefresh,
            canResume,
        } = this.state;

        return (
            <div className="modal-backdrop">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                )}
                {currentErrorMessage && (
                    <ErrorMessage message={currentErrorMessage} resetErrorMessage={this.resetErrorMessage} />
                )}
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button
                            className="modal-close-btn"
                            onClick={() => this.props.toggleModal(triggerSubDataRefresh)}
                        >
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">Resume your subscription</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="summary-modal-section">
                                    <p className="subscription-modal-header">Your customer details</p>
                                    <div className="sms-group">
                                        <p className="smsg-title">Account holder</p>
                                        <p className="smsg-info">
                                            {this.props.subscriptionDetails.details.customer.first_name}{' '}
                                            {this.props.subscriptionDetails.details.customer.last_name}{' '}
                                            ({this.props.subscriptionDetails.details.customer.email})
                                        </p>
                                    </div>
                                    <div className="sms-group">
                                        <p className="smsg-title">Company/organisation</p>
                                        <p className="smsg-info">
                                            {this.props.subscriptionDetails.details.customer.company}
                                        </p>
                                    </div>
                                    <div className="sms-group">
                                        <p className="smsg-title">Address</p>
                                        {/* MUST ADD IF CHECK FOR ADDRESS LINES */}
                                        <p className="smsg-info">
                                            {this.props.subscriptionDetails.details.customer.address},{' '}
                                            {this.props.subscriptionDetails.details.customer.address2 && (
                                                <span>
                                                    {this.props.subscriptionDetails.details.customer.address2},{' '}
                                                </span>
                                            )}
                                            {this.props.subscriptionDetails.details.customer.city},{' '}
                                            {this.props.subscriptionDetails.details.customer.country},{' '}
                                            {this.props.subscriptionDetails.details.customer.postcode}
                                        </p>
                                    </div>
                                    <p className="smsg-link" onClick={this.handleOpenDetailsModal}>
                                        Edit your customer details →
                                    </p>
                                    {/* eslint-disable */}
                                    {/* { addedNewPayment &&
                                    <div className="sms-group">
                                        <p className="smsg-title">Billing address</p>
                                        <p className="smsg-info">{billingDetails.addressLine1}, {billingDetails.addressLine2 && <span>{billingDetails.addressLine2}, </span>}{billingDetails.city}, {billingDetails.country}, {billingDetails.postcode}</p>
                                    </div>
                                    } */}
                                    {/* eslint-enable*/}
                                </div>
                                <div className="resume-payment-method-modal-section">
                                    <p className="subscription-modal-header">
                                        Your default payment method
                                    </p>
                                    {isEmpty(this.state.paymentMethod) ||
                                        <div className="subscription-modal-radio-option subscription-modal-info-card">
                                            <div className="smro-copy">
                                                <div className="smro-resume-card-preview">
                                                    <p className="smro-title">
                                                    xxxx xxxx xxxx {' '}
                                                        {this.state.paymentMethod.number.substr(
                                                            this.state.paymentMethod.number.length - 4, 4
                                                        )}
                                                    </p>
                                                    <span className="payment-method-inline-logo">
                                                        {this.state.paymentMethod.type === 'Visa' ? (
                                                            <img
                                                                src={require('../../images/visa.png')}
                                                                className="pm-visa-logo"
                                                                alt="visa-logo"
                                                            />
                                                        ) : (
                                                            <MastercardIcon />
                                                        )}
                                                    </span>
                                                </div>
                                                <p className="smro-desc smro-desc-extra">
                                                    Expires{' '}
                                                    {this.state.paymentMethod.expdate_month}
                                                    /
                                                    {this.state.paymentMethod.expdate_year}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    <p className="smsg-link" onClick={this.handleOpenPaymentMethodModal}>
                                        Change your default payment method →
                                    </p>
                                </div>
                                <div className="resume-summary-modal-section">
                                    <p className="subscription-modal-header">
                                        Your subscription summary&nbsp;
                                        {this.props.subscriptionDetails.details.customer.country === 'United Kingdom'
                                            && '(VAT included)'
                                        }
                                    </p>
                                    <SummaryCard 
                                        subscriptionDetails={this.props.subscriptionDetails}
                                        totalCost={this.props.totalCost}
                                        paycycle={this.props.subscriptionDetails?.plan?.paycycle}
                                        customerDetails={this.props.subscriptionDetails.details.customer}
                                        nextPaymentDate={this.props.subscriptionDetails.cancellation_date}
                                        currency={this.props.subscriptionDetails?.subscription_total_price_wo_vat_currency}

                                    />
                                    {/* <div className="subscription-modal-radio-option subscription-modal-plan-summary">
                                        <label className="smro-label">
                                            <div className="smro-copy">
                                                <p className="smro-title">
                                                    Racecheck {this.props.subscriptionDetails.plan.title} (
                                                    {this.props.subscriptionDetails.plan.paycycle === 'A'
                                                        ? 'Annual'
                                                        : 'Monthly'}
                                                    )
                                                </p>
                                                <p className="smro-desc">Due to cancel {cancellationDateString}</p>
                                            </div>
                                            <div className="smro-price">
                                                <h2 className="spc-plan-title msc-highlight">
                                                    <span className="spc-pt-currency">£</span>
                                                    {this.props.totalCost}
                                                    <span className="spc-pt-month">
                                                        {this.props.subscriptionDetails.plan.paycycle === 'M'
                                                            ? '/month'
                                                            : '/year'}{' '}
                                                        {this.props.hasVAT && '(VAT included)'}
                                                    </span>
                                                </h2>
                                            </div>
                                        </label>
                                    </div> */}
                                </div>
                                <div className="modal-btn-row">
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                            onClick={this.handleNewDefaultCard}
                                        >
                                            <img className="subscription-btn-loader" src={SmallLoader} alt="loader" />
                                        </div>
                                    ) : (
                                        <div
                                            role="button"
                                            className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                canResume ? '' : 'disabled-primary-btn'
                                            }`}
                                            onClick={this.handlePlanRestart}
                                        >
                                            {loadingButton ? (
                                                <img className="widget-card-loader" src={SmallLoader} alt="loader" />
                                            ) : (
                                                'Resume subscription'
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

RestartSubscriptionModal.propTypes = {
    totalCost: PropTypes.number,
    hasVAT: PropTypes.bool,
    subscriptionDetails: PropTypes.shape({
        id: PropTypes.number,
        cancellation_date: PropTypes.string,
        subscription_total_price_wo_vat_currency: PropTypes.string,
        plan: PropTypes.shape({
            title: PropTypes.string,
            paycycle: PropTypes.string,
            statuses: PropTypes.bool,
            id: PropTypes.number,
            next_payment_date: PropTypes.string,
            cancellation_date: PropTypes.string
        }),
        details: PropTypes.shape({
            statuses: PropTypes.shape({
                can_resume: PropTypes.bool,
            }),
            status: PropTypes.shape({
                can_resume: PropTypes.bool,
            }),
            credit_cards: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                })
            ),
            payment_method: PropTypes.shape({
                creditcard_id: PropTypes.string,
            }),
            customer: PropTypes.shape({
                email: PropTypes.string,
                address: PropTypes.string,
                company: PropTypes.string,
                first_name: PropTypes.string,
                last_name: PropTypes.string,
                address2: PropTypes.string,
                city: PropTypes.string,
                country: PropTypes.string,
                postcode: PropTypes.string,
            }),
        }),
    }),
    toggleModal: PropTypes.func.isRequired,
    toggleNewPaymentMethodModal: PropTypes.func,
    toggleEditDetailsModal: PropTypes.func
};

export default RestartSubscriptionModal;
