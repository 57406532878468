import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import App from './components/app/App';
import * as serviceWorker from './serviceWorker';
import { shouldAllowCookies } from './utils';

if (shouldAllowCookies()) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);

    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init(process.env.REACT_APP_FBP_ID, options);
    ReactPixel.pageView();
}

ReactDOM.render(
    <Router>
        <App />
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
