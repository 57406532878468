import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';
import Loader from '../helpers/loader';
import './ResourceSection.css';
import { ReactComponent as DateIcon } from '../../images/icons/calendar.svg';
import { ReactComponent as AuthorIcon } from '../../images/icons/person.svg';

const ArticleCard = ({ article }) => {
    const formattedDate = moment(article.published_at).format('Do MMMM YYYY');
    return (
        <a className="article-card" href={article.link} target="_blank" rel="noopener noreferrer">
            <div className="article-card-img">
                <img src={article.image} alt="article thumbnail" />
            </div>
            <div className="article-info">
                <div className="article-info-top">
                    <h2>{article.title}</h2>
                </div>
                <div className="article-info-bottom">
                    <p><AuthorIcon />{article.author}</p>
                    <p><DateIcon />{formattedDate}</p>
                </div>
            </div>
        </a>
    );
};

const Articles = () => {
    const [articlesList, setArticlesList] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const { REACT_APP_API_URL } = process.env;

    const getArticles = () => {
        setLoaded(false);

        const apiCallURL = `${REACT_APP_API_URL}/api/medium-feed/`;

        axios.get(apiCallURL)
            .then(({ data: { data } }) => {
                setArticlesList(data);
                setLoaded(true);
            })
            .catch(error => console.log(error));
    };

    useEffect(getArticles, []);

    return (
        <div className="articles-container">
            {loaded ?
                <div className="articles-list">
                    {articlesList.map(article => (
                        <ArticleCard 
                            key={article.link}
                            article={article}
                        />
                    ))}
                </div>
                :
                <div className="section-loading-container">
                    <Loader />
                </div>
            }
        </div>
    );
};

export default Articles;

ArticleCard.propTypes = {
    article: PropTypes.shape({
        published_at: PropTypes.string,
        link: PropTypes.string,
        image: PropTypes.string,
        title: PropTypes.string,
        author: PropTypes.string,
    })
};