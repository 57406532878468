import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';

const TrialBanner = ({ type = 'default', startDate, onClick = () => {} }) => (
    <div className="subscription-modal-trial-message">
        <p className="stm-copy">
            <span className="stm-copy-highlight">
                <span className="subscription-trial-icon">
                    <ExclamationIcon />
                </span>
                {type === 'note' && 'Note:'}
                {type === 'applied' && '14-day free trial applied:'}
            </span>
            {type === 'default' && 'You have 14 DAY FREE TRIAL to try Racecheck Premium!'}
            {type === 'note' && 'Your 14-day free trial will be applied at checkout.'}
            {type === 'applied' && `Paid subscription begins ${startDate}`}
        </p>
        
        {type === 'default' &&
            <button className="trial-banner__get-started" onClick={() => onClick()}>
                Get started now
            </button>
        }
    </div>
);

TrialBanner.propTypes = {
    type: PropTypes.string,
    startDate: PropTypes.string,
    onClick: PropTypes.func
};

export default TrialBanner;
