import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import classnames from 'classnames';
import SectionNav from '../organiserDashboard/SectionNav';
import EventFeed from './EventFeed';
import AppContext from '../app/AppContext';
import ErrorBoundary from '../helpers/errorBoundary';
import SectionError from '../helpers/sectionError';
import TrialEligibleBanner from '../helpers/trialEligibleBanner';
import Event from './Event';
import { shouldAllowCookies } from '../../utils';

class Events extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            eventsList: [],
            hasError: null,
        };
    }

    componentDidMount () {
        if (shouldAllowCookies()) {
            try {
                const delightedProps = {
                    email: this.context?.user?.email,
                    name: this.context?.user?.first_name + ' ' + this.context?.user?.last_name,
                    createdAt: this.context?.user?.date_joined,
                    properties: {
                        organisation: this.context?.selectedOrganisation?.name,
                        premium_subscriber: this.context?.selectedOrganisation?.is_premium_subscriber
                    }
                };
                const delightedScript = document.createElement('script');
                delightedScript.type = 'text/javascript';
                /*eslint-disable*/
                delightedScript.innerHTML = `
                !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var l=t.getElementsByTagName("script")[0];l.parentNode.insertBefore(o,l)}}(window,document,"${process.env.REACT_APP_DELIGHTED_KEY}","delighted");
          delighted.survey(${JSON.stringify(delightedProps)});`;
                /* eslint-enable*/
                delightedScript.id = 'delighted-script';
                delightedScript.async = true;
                document.body.appendChild(delightedScript);
            }
            catch (e) {
                console.log('Could not initialise Delighted', e);
            }
        }
    }

    setSectionError = error => {
        this.setState({ hasError: error });
    };

    
    render () {
        return (
            <div className={classnames('section-container', { 'section-container-main-nav-closed':!this.context.menuOpen })}>
                <SectionNav section="events" />
                {this.context.selectedOrganisation.is_trial_eligible && <TrialEligibleBanner />}
                <ErrorBoundary>
                    <div
                        className={`section-content ${
                            this.context.selectedOrganisation.is_trial_eligible && 'section-content-with-banner'
                        }`}
                    >
                        {this.state.hasError ? (
                            <SectionError message={this.state.hasError} />
                        ) : (
                            <Switch>
                                <Route
                                    path="/events/events"
                                    exact
                                    render={() => <EventFeed setSectionError={this.setSectionError} />}
                                />
                                <Route
                                    path="/events/:id"
                                    render={(props) => <Event setSectionError={this.setSectionError} {...props} />}
                                />
                            </Switch>
                        )}
                    </div>
                </ErrorBoundary>
            </div>
        );
    }
}

export default Events;
