import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AppContext from '../app/AppContext';
import '../organiserDashboard/LandingPage.module.css';
import { localiseText, mapCurrencyToSign } from '../../utils';
import AwardProgress from './AwardProgress';
import EventReviews from './EventReviews';
import { Link, useLocation } from 'react-router-dom';
import qs from 'qs';

import ErrorMessage from '../helpers/errorMessage';
import Loader from '../helpers/loader';

import Eye from '../../images/icons/eye.svg';
import ColorWand from '../../images/icons/color-wand.svg';
import GitMerge from '../../images/icons/git-merge.svg';
import Basket from '../../images/icons/basket.svg';
import Ticket from '../../images/icons/ticket.svg';
import Rocket from '../../images/icons/rocket.svg';
import Cash from '../../images/icons/cash.svg';
import Influenced from '../../images/icons/influence.svg';
import TrendingUp from '../../images/icons/trending-up.svg';
import OrgDashIllustration from '../../images/organiser-dashboard.png';
import ReviewsIllustration from '../../images/reviews-illustration.png';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as ArrowRightIcon } from '../../images/icons/arrow_right.svg';
import { ReactComponent as EditIcon } from '../../images/icons/pencil.svg';
import { ReactComponent as ChevronRightIcon } from '../../images/icons/chevron-right.svg';
import { ReactComponent as InfoIcon } from '../../images/icons/alert.svg';
import { ReactComponent as WarningIcon } from '../../images/icons/warning-triangle.svg';
import { ReactComponent as BulbIcon } from '../../images/icons/bulb.svg';
import { ReactComponent as Download } from '../../images/icons/download.svg';
import SmallLoader from '../../images/small_loader.gif';
import mixpanel from 'mixpanel-browser';

const MonthlyRecap = ({ event }) => {
    const { search } = useLocation();

    const { period: defaultPeriod } = qs.parse(search, { ignoreQueryPrefix: true });

    const appContext = useContext(AppContext);

    const [analytics, setAnalytics] = useState(null);
    const [widgetDetail, setWidgetDetail] = useState(null);
    const [averageCost, setAverageCost] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [period, setPeriod] = useState(defaultPeriod ? +defaultPeriod : 30);
    const [widgetInstallStatus, setInstallStatus] = useState(false);
    const [intentToBookTrackerInstalled, setIntentToBookTrackerInstalled] = useState(false);
    const [completedSalesTrackerInstalled, setCompletedSalesTrackerInstalled] = useState(false);
    const [downloadReportError, setDownloadReportError] = useState('');

    const [isAwardProgressLoading, setIsAwardProgressLoading] = useState(true);
    const [isComparativeAnalysisLoading,setIsComparativeAnalysisLoading] = useState(true);
    const [isReviewAnalyticsLoading, setIsReviewAnalyticsLoading] = useState(true);
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);

    const mpProps = appContext.selectedOrganisation ? {
        organisation: appContext.selectedOrganisation?.name,
        premium_subscriber: appContext.selectedOrganisation?.is_premium_subscriber
    } : {};

    const getReviewBoxAnalytics = () => {
        const { id } = appContext.selectedOrganisation;
        
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${id}/widgets/${event?.widgets?.review_box?.id}/review_box_analytics/?period=${period}`;
        const headers = {
            Authorization: `Token ${appContext.token}`,
        };

        if (event?.widgets?.review_box?.id) {
            axios.get(apiCallURL, { headers })
                .then(({ data: { data } }) => {
                    setAnalytics(data?.review_box_analytics);
                    setInstallStatus(data?.widget_installed);
                    setIntentToBookTrackerInstalled(data?.registration_intent_tracker_installed);
                    setCompletedSalesTrackerInstalled(data?.registration_completed_tracker_installed);
                });
        }
    };
    
    const downloadReport = () => {
        mixpanel.track('Dashboard - Analytics - Download Monthly Recap click', mpProps);
        setIsDownloadLoading(true);
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${appContext.selectedOrganisation?.id}/events/${event?.id}/download_report/`;
        const headers = {
            Authorization: `Token ${appContext.token}`,
        };

        axios.post(apiCallURL, {
            path: `/events/${event?.id}/analytics/widget?period=${period}`
        }, { headers })
            .then(({ data }) => {
                const fileUrl = data?.data?.report_url;
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileUrl?.slice(fileUrl?.lastIndexOf('/') + 1));
                aElement.href = fileUrl;
                aElement.click();
            })
            .catch(err => {
                if (err?.response?.status >= 400 && err?.response?.status < 500) {
                    setDownloadReportError(err?.response?.data?.error);
                }
            })
            .finally(() => {
                setIsDownloadLoading(false);
            });
    };

    useEffect(() => {
        appContext?.selectedOrganisation?.is_premium_subscriber && getReviewBoxAnalytics();
        // eslint-disable-next-line
    }, [event, period]);

    useEffect(() => {
        getWidgetDetails();
        // eslint-disable-next-line
    }, [event?.widgets?.review_box?.id]);

    const getWidgetDetails = () => {
        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = appContext.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/widgets/${event?.widgets?.review_box?.id}/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        if (event?.widgets?.review_box?.id) {
            axios
                .get(apiCallURL, { headers })
                .then(({ data }) => {
                    setWidgetDetail(data?.data);
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    };
    const editEvent = (e) => {
        e.preventDefault();
        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = appContext.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${event?.id}/edit_event/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .put(apiCallURL, {
                average_entry_cost: +averageCost
            }, { headers })
            .then(() => {
                setIsEditMode(false);
                getReviewBoxAnalytics();
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    // eslint-disable-next-line max-len
    const showIntentToBookEstimate = !intentToBookTrackerInstalled && analytics?.analytics?.racecheck_registration_intents !== null;
    // eslint-disable-next-line max-len
    const showCompletedSalesEstimate = !completedSalesTrackerInstalled && analytics?.analytics?.racecheck_registrations_completed !== null;

    if (!appContext?.selectedOrganisation?.is_premium_subscriber) {
        return (
            <div className="analytics-placeholder-container">
                <img src={OrgDashIllustration} alt="Illustration of Racecheck dashboard" />
                <h2>Upgrade to Racecheck Premium to access</h2>
                <p>Analytics reports are currently only available to 
                    Racecheck Premium {localiseText('organisers')}. Upgrade your account to learn
                    how much value your Review Box could be generating and see how your event compares against others.
                </p>
                <Link to="/subscription/subscription-plan" className="btn primary-btn"
                    onClick={() => {
                        /* eslint-disable-next-line */
                          mixpanel.track('Dashboard - Analytics - Upgrade to Premium click', mpProps);
                    }}
                >
                    Get started for free
                </Link>
            </div>
        );
    }
    if (!analytics) {
        return (
            <div className="section-loading-container">
                <Loader />
            </div>
        );
    }
    if (!event?.reviews) {
        return (
            <div className="analytics-placeholder-container">
                <img src={ReviewsIllustration} alt="Illustration of Racecheck reviews" />
                <h2>This event hasn&apos;t been reviewed</h2>
                <p>Analytics reports will be available once you&apos;ve collected your 
                    first review for this event. Start gathering feedback to learn how much value your 
                    Review Box could be generating and see how your event compares against others.
                </p>
                <Link to={`/events/${event?.id}/request-reviews`} className="btn primary-btn">
                    Help collecting reviews
                </Link>
            </div>
        );
    }
    return (
        <>
            {downloadReportError && (
                <ErrorMessage message={downloadReportError} resetErrorMessage={() => setDownloadReportError('')} />
            )} 
            <div className="analytics-cards">
                <div className="mr-download-wrap">
                    <button
                        onClick={downloadReport}
                        id="mr-download-btn" 
                        className="mr-download-btn mr-download-hide" 
                        disabled={!analytics || !widgetDetail || isAwardProgressLoading || 
                        isComparativeAnalysisLoading || isReviewAnalyticsLoading || isDownloadLoading}
                    >
                        {
                            !analytics || !widgetDetail || isAwardProgressLoading || 
                        isComparativeAnalysisLoading || isReviewAnalyticsLoading || isDownloadLoading ? ( 
                                    <img
                                        className="subscription-btn-loader"
                                        src={SmallLoader}
                                        alt="loader"
                                    />) : <>Download<Download /></>
                        }
                    </button>
                </div>
                <div className="mr-report-header">
                    <img
                        src={require('../../images/racecheck_logo_black.svg')}
                        className="mr-rc-logo"
                        alt="racecheck-logo"
                    />
                    <h1>{event?.title}</h1>
                    <div className="mr-header-info">
                        <p>Monthly Recap Analytics Report</p>
                        <p className="bullet">•</p>
                        <select
                            className="mr-period-select rounded-dropdown"
                            name="period"
                            onChange={(e) => setPeriod(e.target.value)}
                            value={period}
                            required
                        >
                            <option value={30}>Past 30d</option>
                            <option value={60}>Past 60d</option>
                            <option value={90}>Past 90d</option>
                            <option value={180}>Past 180d</option>
                            <option value={360}>Past 360d</option>
                        </select>
                        <p className="bullet">•</p>
                        <p>{analytics?.period?.period_start} - {analytics?.period?.period_end}</p>
                    </div>
                </div>

                <div className="mr-section-divider">
                    <p>Review Box Analytics</p>
                </div>

                <div className="mr-section-group-container">
                    {!widgetInstallStatus &&
                    <div className="mr-section-bottom-info mr-download-hide">
                        <div className="mr-info-left">
                            <BulbIcon />
                            {analytics?.analytics?.loads ?
                                <p>Ensure your Review Box is installed correctly to 
                                    keep your analytics report up to date and accurate.
                                </p>
                                :
                                <p>Once you’ve installed your Racecheck Review Box it will start collecting interaction data.</p>
                            }
                        </div>
                        <Link className="btn primary-btn" to={`/events/${event?.id}/widgets/review-box`}
                            onClick={() => {
                                /* eslint-disable-next-line */
                                  mixpanel.track('Dashboard - Analytics - Install Review Box click', mpProps);
                            }
                            }
                        >
                            Install Review Box
                            <ArrowRightIcon />
                        </Link>
                    </div>
                    }
                    <div className="mr-section-container">
                        <h2 className="mr-section-title">Review Box Interaction</h2>
                        <p className="mr-section-intro">
                        How many website visitors are seeing and using your Review Box.
                        </p>
                        <div className="mr-data-row">
                            <div className="mr-stat-container">
                                <img alt="eye" src={Eye} />
                                {analytics?.analytics?.loads ?
                                    <p className="mr-stat">{analytics?.analytics?.loads.toLocaleString('en-US')}</p>
                                    :
                                    <p className="mr-stat">-</p>
                                }
                                <p className="mr-stat-title">
                                    <span>
                                        Impressions
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                        The number of times the Review Box has been loaded and displayed on your website.
                                        </span>
                                        {!analytics?.analytics?.loads &&
                                        <div className="mr-tooltip-extra-row">
                                            <WarningIcon className="mr-icon-warning" />
                                            <span className="mr-tooltip-desc">
                                                No data recorded in this period.
                                            </span>
                                        </div>
                                        }
                                    </div>
                                </p>
                            </div>
                            <ChevronRightIcon className="mr-chevron-right" />
                            <div className="mr-stat-container">
                                <img alt="stick" src={ColorWand} />
                                {analytics?.analytics?.interactions ?
                                    <p className="mr-stat">{analytics?.analytics?.interactions.toLocaleString('en-US')}</p>
                                    :
                                    <p className="mr-stat">-</p>
                                }
                                <p className="mr-stat-title">
                                    <span>
                                        Interactions
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                        The number of times a website visitor has either moused over or clicked on the Review Box.
                                        </span>
                                        {!analytics?.analytics?.interactions &&
                                        <div className="mr-tooltip-extra-row">
                                            <WarningIcon className="mr-icon-warning" />
                                            <span className="mr-tooltip-desc">
                                                No data recorded in this period.
                                            </span>
                                        </div>
                                        }
                                    </div>
                                </p>
                            </div>
                            <ChevronRightIcon className="mr-chevron-right" />
                            <div className="mr-stat-container">
                                <img alt="ratio" src={GitMerge} />
                                {analytics?.analytics?.interaction_ratio ?
                                    <p className="mr-stat">{(analytics?.analytics?.interaction_ratio * 100).toFixed(2)}%</p>
                                    :
                                    <p className="mr-stat">-</p>
                                }
                                <p className="mr-stat-title">
                                    <span>
                                        Interaction Ratio
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                        The percentage of website visitors (impressions) who 
                                        went on to interact with your Review Box.
                                        </span>
                                        <div className="mr-tooltip-extra-row">
                                            <InfoIcon className="mr-icon-info" />
                                            <span className="mr-tooltip-desc">
                                            A ratio of 4%+ is good.
                                            </span>
                                        </div>
                                        {!analytics?.analytics?.interaction_ratio &&
                                        <div className="mr-tooltip-extra-row">
                                            <WarningIcon className="mr-icon-warning" />
                                            <span className="mr-tooltip-desc">
                                                No data recorded in this period.
                                            </span>
                                        </div>
                                        }
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mr-section-group-container">
                    {showIntentToBookEstimate &&
                        <div className="mr-section-bottom-info">
                            <div className="mr-info-left">
                                <WarningIcon className="warning-icon" />
                                <p>These values are an estimation - to get accurate and 
                                personalised data install your tracking code.
                                </p>
                            </div>
                            <Link className="btn primary-btn mr-download-hide" to={`/events/${event?.id}/widgets/review-box`}
                                onClick={() => {
                                    /* eslint-disable-next-line */
                                      mixpanel.track('Dashboard - Analytics - Install tracking click', mpProps);
                                }
                                }
                            >
                            Install tracking
                                <ArrowRightIcon />
                            </Link>
                        </div>
                    }
                    <div 
                        className={`mr-section-container 
                    ${showIntentToBookEstimate
                            ? 'mr-section-container-est' : ''}`}
                    >
                        <h2 className="mr-section-title">
                            {showIntentToBookEstimate
                                ? 'Estimated Intent to Book' : 'Intent to Book' }
                        </h2>
                        <p className="mr-section-intro">
                            Learn how Racecheck influences the number of visitors clicking a &apos;book now&apos; button.
                        </p>
                        <div className={`mr-data-row ${showIntentToBookEstimate
                            ? 'mr-data-row-est' : ''}`}
                        >
                            <div className="mr-stat-container">
                                <img alt="shopping cart" src={Basket} />
                                <p
                                    className="mr-stat"
                                >
                                    {analytics?.analytics?.registration_intents?.toLocaleString('en-US') || '-'}
                                </p>
                                <p className="mr-stat-title">
                                    <span>
                                        Total Book Now clicks
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                            The total number of website visitors who clicked on one of your
                                            registration links.
                                        </span>
                                        {!intentToBookTrackerInstalled &&
                                        <>
                                            {analytics?.analytics?.registration_intents !== null ?
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        Estimated based on industry trends.
                                                    </span>
                                                </div>
                                                :
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        No data recorded in this period.
                                                    </span>
                                                </div>
                                            }
                                        </>
                                        }
                                    </div>
                                </p>
                                {showIntentToBookEstimate &&
                                    <div className="mr-title-tag-container">
                                        <span className="mr-title-tag mr-title-tag-est">
                                            <WarningIcon className="mr-icon-warning" />
                                        ESTIMATED
                                        </span>
                                    </div>
                                }
                            </div>
                            <ChevronRightIcon className="mr-chevron-right" />

                            <div className="mr-stat-container">
                                <img alt="shopping cart" src={Influenced} />
                                <p className="mr-stat">
                                    {analytics?.analytics?.racecheck_registration_intents?.toLocaleString('en-US') || '-'}
                                </p>

                                <p className="mr-stat-title">
                                    <span>
                                        Racecheck influenced <br />Book Now clicks
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                        The number of website visitors who clicked on a 
                                        registration link after interacting with the Review Box.
                                        </span>
                                        {!intentToBookTrackerInstalled &&
                                        <>
                                            {analytics?.analytics?.racecheck_registration_intents !== null ?
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        Estimated based on industry trends.
                                                    </span>
                                                </div>
                                                :
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        No data recorded in this period.
                                                    </span>
                                                </div>
                                            }
                                        </>
                                        }
                                    </div>
                                </p>
                                {showIntentToBookEstimate &&
                                    <div className="mr-title-tag-container">
                                        <span className="mr-title-tag mr-title-tag-est">
                                            <WarningIcon className="mr-icon-warning" />
                                        ESTIMATED
                                        </span>
                                    </div>
                                }
                            </div>
                            <ChevronRightIcon className="mr-chevron-right" />
                            <div className="mr-stat-container">
                                <img alt="stick" src={GitMerge} />
                                <p
                                    className="mr-stat"
                                >
                                    {analytics?.analytics?.registration_intent_ratio !== null ?
                                            `${(analytics?.analytics?.registration_intent_ratio * 100)?.toFixed(2)}%`
                                        :
                                        '-'
                                    }
                                </p>
                                <p className="mr-stat-title">
                                    <span>
                                        Intent to Book Ratio
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                            The percentage of &apos;book now&apos; button clicks 
                                            that were influenced by Racecheck.
                                        </span>
                                        {!intentToBookTrackerInstalled &&
                                            <>
                                                {analytics?.analytics?.registration_intent_ratio !== null ?
                                                    <div className="mr-tooltip-extra-row">
                                                        <WarningIcon className="mr-icon-warning" />
                                                        <span className="mr-tooltip-desc">
                                                        Estimated based on industry trends.
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="mr-tooltip-extra-row">
                                                        <WarningIcon className="mr-icon-warning" />
                                                        <span className="mr-tooltip-desc">
                                                        No data recorded in this period.
                                                        </span>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </p>
                                {showIntentToBookEstimate &&
                                    <div className="mr-title-tag-container">
                                        <span className="mr-title-tag mr-title-tag-est">
                                            <WarningIcon className="mr-icon-warning" />
                                        ESTIMATED
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mr-section-group-container">
                    {showCompletedSalesEstimate &&
                        <div className="mr-section-bottom-info">
                            <div className="mr-info-left">
                                <WarningIcon className="warning-icon" />
                                <p>These values are an estimation - to get accurate
                                 and personalised data install your tracking code.
                                </p>
                            </div>
                            <Link className="btn primary-btn mr-download-hide" to={`/events/${event?.id}/widgets/review-box`}
                                onClick={() => {
                                    /* eslint-disable-next-line */
                                      mixpanel.track('Dashboard - Analytics - Install tracking click', mpProps);
                                }
                                }
                            >
                            Install tracking
                                <ArrowRightIcon />
                            </Link>
                        </div>
                    }
                    <div className={`mr-section-container 
                ${showCompletedSalesEstimate
                        ? 'mr-section-container-est' : ''}`}
                    >
                        <h2 className="mr-section-title">
                            {showCompletedSalesEstimate
                                ? 'Estimated Completed Sales' : 'Completed Sales' }
                        </h2>
                        <p className="mr-section-intro">
                        How many visitors complete registration after interacting with your Review Box.
                        </p>
                        <div className="mr-data-row">
                            <div className="mr-stat-container">
                                <img alt="ticket" src={Ticket} />
                                <p className="mr-stat">
                                    {analytics?.analytics?.registrations_completed?.toLocaleString('en-US') || '-'}
                                </p>

                                <p className="mr-stat-title">
                                    <span>
                                        Total completed entries
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                        The total number of people who completed the sales process.
                                        </span>
                                        {!completedSalesTrackerInstalled &&
                                        <>
                                            {analytics?.analytics?.registrations_completed !== null ?
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        Estimated based on industry trends.
                                                    </span>
                                                </div>
                                                :
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        No data recorded in this period.
                                                    </span>
                                                </div>
                                            }
                                        </>
                                        }
                                    </div>
                                </p>
                                {showCompletedSalesEstimate &&
                                    <div className="mr-title-tag-container">
                                        <span className="mr-title-tag mr-title-tag-est">
                                            <WarningIcon className="mr-icon-warning" />
                                        ESTIMATED
                                        </span>
                                    </div>
                                }
                            </div>
                            <ChevronRightIcon className="mr-chevron-right" />

                            <div className="mr-stat-container">
                                <img alt="ticket" src={Influenced} />
                                <p className="mr-stat" >
                                    {analytics?.analytics?.racecheck_registrations_completed?.toLocaleString('en-US') || '-'}
                                </p>

                                <p className="mr-stat-title">
                                    <span>
                                        Racecheck influenced <br />completed entries
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                            The number of people who completed the sales process following
                                             an interaction with the Review Box.
                                        </span>
                                        {!completedSalesTrackerInstalled &&
                                        <>
                                            {analytics?.analytics?.racecheck_registrations_completed !== null ?
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        Estimated based on industry trends.
                                                    </span>
                                                </div>
                                                :
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        No data recorded in this period.
                                                    </span>
                                                </div>
                                            }
                                        </>
                                        }
                                    </div>
                                </p>
                                {showCompletedSalesEstimate &&
                                    <div className="mr-title-tag-container">
                                        <span className="mr-title-tag mr-title-tag-est">
                                            <WarningIcon className="mr-icon-warning" />
                                        ESTIMATED
                                        </span>
                                    </div>
                                }
                            </div>
                            <ChevronRightIcon className="mr-chevron-right" />
                            <div className="mr-stat-container">
                                <img alt="ratio" src={GitMerge} />
                                <p className="mr-stat">
                                    {analytics?.analytics?.registration_completed_ratio !== null ?
                                        `${(analytics?.analytics?.registration_completed_ratio * 100)?.toFixed(2)}%`
                                        :
                                        '-'
                                    }
                                </p>
                                <p className="mr-stat-title">
                                    <span>
                                        Completed Sales Ratio
                                        <HelpIcon className="mr-download-hide" />
                                    </span>
                                    <div className="mr-title-tooltip">
                                        <span className="mr-tooltip-desc">
                                            The percentage of completed sales that were influenced by Racecheck.
                                        </span>
                                        {!completedSalesTrackerInstalled &&
                                        <>
                                            {analytics?.analytics?.registration_completed_ratio !== null ?
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        Estimated based on industry trends.
                                                    </span>
                                                </div>
                                                :
                                                <div className="mr-tooltip-extra-row">
                                                    <WarningIcon className="mr-icon-warning" />
                                                    <span className="mr-tooltip-desc">
                                                        No data recorded in this period.
                                                    </span>
                                                </div>
                                            }
                                        </>
                                        }
                                    </div>
                                </p>
                                {showCompletedSalesEstimate &&
                                    <div className="mr-title-tag-container">
                                        <span className="mr-title-tag mr-title-tag-est">
                                            <WarningIcon className="mr-icon-warning" />
                                        ESTIMATED
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={`mr-section-group-container ${!analytics?.average_entry_cost ? 'mr-download-hide' : ''}`}>
                    <div className="mr-section-container mr-section-container-last">
                        <h2 className="mr-section-title">Estimated Return on Investment</h2>
                        <p className="mr-section-intro">
                            How much value your Review Box generated in this period.
                        </p>
                        <div className="mr-data-row">
                            {
                                (analytics?.average_entry_cost && !isEditMode) ? (
                                    <>
                                        <div className="mr-stat-container">
                                            <img alt="money" src={Cash} />
                                            <p className="mr-stat">
                                                {/* eslint-disable-next-line max-len */}
                                                {mapCurrencyToSign[analytics?.average_entry_cost?.currency]}{analytics?.average_entry_cost?.amount.toLocaleString('en-US')}
                                                <button className="roi-edit-btn mr-download-hide" onClick={() => {
                                                    setAverageCost(analytics?.average_entry_cost?.amount);
                                                    setIsEditMode(true);
                                                }}
                                                >
                                                    <EditIcon />
                                                </button>
                                            </p>
                                            <p className="mr-stat-title">
                                                <span>
                                                    Avg. Entry Cost
                                                    <HelpIcon className="mr-download-hide" />
                                                </span>
                                                <div className="mr-title-tooltip">
                                                    <span className="mr-tooltip-desc">
                                                    How much does it cost a participant to enter your event?
                                                     If you have multiple entry options, use the average.
                                                    </span>
                                                </div>
                                            </p>
                                        </div>
                                        <ChevronRightIcon className="mr-chevron-right" />
                                        <div className="mr-stat-container">
                                            <img alt="rocket" src={Rocket} />
                                            <p className="mr-stat">
                                                {analytics?.average_entry_cost && analytics?.roi?.revenue?.amount ?
                                                    /* eslint-disable-next-line max-len */
                                                    `${mapCurrencyToSign[analytics?.roi?.revenue?.currency]}${analytics?.roi?.revenue?.amount.toFixed((2))}`
                                                    :
                                                    '-'
                                                }
                                            </p>

                                            <p className="mr-stat-title">
                                                <span>
                                                    Est. Revenue
                                                    <HelpIcon className="mr-download-hide" />
                                                </span>
                                                <div className="mr-title-tooltip">
                                                    <span className="mr-tooltip-desc">
                                                    Based on the tracked number of completed entries and avg.
                                                     entry cost, your Review Box has helped generate the above amount of revenue.
                                                    </span>
                                                    {analytics?.roi?.is_estimate &&
                                                    <>
                                                        {analytics?.analytics?.registration_completed_ratio ?
                                                            <div className="mr-tooltip-extra-row">
                                                                <WarningIcon className="mr-icon-warning" />
                                                                <span className="mr-tooltip-desc">
                                                                    Estimated based on industry trends.
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="mr-tooltip-extra-row">
                                                                <WarningIcon className="mr-icon-warning" />
                                                                <span className="mr-tooltip-desc">
                                                                    No data recorded in this period.
                                                                </span>
                                                            </div>
                                                        }
                                                    </>
                                                    }
                                                </div>
                                            </p>
                                        </div>
                                        <ChevronRightIcon className="mr-chevron-right" />
                                        <div className="mr-stat-container">
                                            <img alt="rocket" src={TrendingUp} />
                                            <p
                                                // eslint-disable-next-line max-len
                                                className={`mr-stat ${analytics?.roi?.revenue?.amount > 0 ? 'roi-positive' : ''}`}
                                            >
                                                {analytics?.roi?.ratio !== null ?
                                                    <>
                                                        {analytics?.roi?.ratio.toFixed(1)}x
                                                    </>
                                                    :
                                                    <>-</>
                                                }
                                            </p>
                                            <p className="mr-stat-title">
                                                <span>
                                                    Est. R.O.I.
                                                    <HelpIcon className="mr-download-hide" />
                                                </span>
                                                <div className="mr-title-tooltip">
                                                    <span className="mr-tooltip-desc">
                                                    Using your monthly Premium subscription cost,
                                                     this is the estimated return on investment for your Review Box
                                                    </span>
                                                    {analytics?.roi?.is_estimate &&
                                                    <>
                                                        {analytics?.roi?.revenue?.amount !== null ?
                                                            <div className="mr-tooltip-extra-row">
                                                                <WarningIcon className="mr-icon-warning" />
                                                                <span className="mr-tooltip-desc">
                                                                    Estimated based on industry trends.
                                                                </span>
                                                            </div>
                                                            :
                                                            <div className="mr-tooltip-extra-row">
                                                                <WarningIcon className="mr-icon-warning" />
                                                                <span className="mr-tooltip-desc">
                                                                    No data recorded in this period.
                                                                </span>
                                                            </div>
                                                        }
                                                    </>
                                                    }
                                                </div>
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <div className="mr-stat-container">
                                        <img alt="money" src={Cash} />
                                        <form onSubmit={editEvent} className="roi-input-row">
                                            <p className="mr-roi-currency">
                                                {mapCurrencyToSign[analytics?.investment?.currency]}
                                            </p>
                                            <input 
                                                className="input roi-input" 
                                                type="number" 
                                                value={averageCost} 
                                                onChange={e => setAverageCost(e.target.value)}
                                            />
                                            <button 
                                                type="submit" 
                                                className={`btn primary-btn roi-btn 
                                                ${!averageCost ? 'disabled-primary-btn' : ''}`}
                                            >
                                                <ArrowRightIcon />
                                            </button>
                                        </form>
                                        <p className="mr-stat-title">
                                            <span>
                                                Avg. Entry Cost
                                                <HelpIcon className="mr-download-hide" />
                                            </span>
                                            <div className="mr-title-tooltip">
                                                <span className="mr-tooltip-desc">
                                                How much does it cost a participant to enter your event?
                                                 If you have multiple entry options, use the average.
                                                </span>
                                            </div>
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="mr-section-divider">
                    <p>Event Review Analytics</p>
                </div>
            
                <EventReviews 
                    event={event} 
                    period={period} 
                    setIsComparativeAnalysisLoading={setIsComparativeAnalysisLoading} 
                    setIsReviewAnalyticsLoading={setIsReviewAnalyticsLoading}
                />
                <AwardProgress event={event} setIsAwardProgressLoading={setIsAwardProgressLoading} />
            </div>
        </>
    );

};


MonthlyRecap.propTypes = {
    setSectionError: PropTypes.func,
    event: PropTypes.shape({
        id: PropTypes.number,
        status: PropTypes.string,
        title: PropTypes.string,
        widget: PropTypes.shape({
            id: PropTypes.number,
        }),
        reviews: PropTypes.arrayOf(),
    })
};

MonthlyRecap.defaultProps = {
    setSectionError: () => {},
};

export default MonthlyRecap;
