import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function SocialLoginError (props) {
    return (
        <div className="logged_out_container">
            <img src={require('../../images/racecheck_logo_black.svg')} width="250px" alt="racecheck-logo" />
            <div className="log_in_container">
                <h2 className="rp-title">Log in error</h2>
                <div>
                    <p className="rp-copy">{props.errorMessage}</p>
                    <Link to="/login" className="no-text-decoration">
                        <button id="log-in-submit" className="log-in-btn btn margin-top-btn">
                            Return to log in
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

SocialLoginError.propTypes = {
    errorMessage: PropTypes.string,
};
