import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import AppContext from '../app/AppContext';
import { isValidEmail } from '../helpers/validationFunctions';
import { hubspotFormsApiRequest } from '../helpers/hubspotFormsApiRequest';
import ReactPixel from 'react-facebook-pixel';
import { localiseText } from '../../utils';

class FormSignUp extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            firstName: '',
            surname: '',
            email: this.props.email,
            password: '',
            hasError: null,
        };
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = e => {
        const { firstName, surname, email, password } = this.state;
        e.preventDefault();
        if (firstName.length > 1 && surname.length > 1 && password.length > 7 && isValidEmail(email)) {
            // Post to Hubspot Forms API
            const data = {
                fields: [
                    {
                        objectTypeId: '0-1',
                        name: 'email',
                        value: email
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'firstname',
                        value: firstName
                    },
                    {
                        objectTypeId: '0-1',
                        name: 'lastname',
                        value: surname
                    }
                ]
            };

            hubspotFormsApiRequest(process.env.REACT_APP_HUBSPOT_CONTACT_FORM_ID, data);

            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/create_organiser/`;
            axios
                .post(apiCallURL, {
                    username: email,
                    password,
                    first_name: firstName,
                    last_name: surname,
                    email,
                    claim: this.context.getClaimId()
                })
                .then(res => {
                    ReactPixel.trackCustom('Organiser Sign Up', { email, first_name: firstName, last_name: surname });
                    this.setState({
                        firstName: '',
                        surname: '',
                        email: '',
                        password: '',
                    });
                    this.context.setUser(res.data.user);
                    this.context.setToken(res.data.token);
                    const {
                        membershipStatus,
                        defaultOrganisation,
                    } = this.context.getMemberShipStatusAndDefaultOrganisation(res.data.user);
                    if (sessionStorage.getItem('invite_code')) {
                        return this.context.acceptTeamInvite(res);
                    }
                    else {
                        this.context.handleLogInState(res.data.token, membershipStatus, defaultOrganisation);
                    }


                    if (['no-org', 'organisation-lead-claim'].includes(membershipStatus)) {
                        return this.props.history.push('/create-organisation');
                    }
                    else if (membershipStatus === 'accepted-invite') {
                        return this.props.history.push('/accept_team_invitation');
                    }
                    else {
                        this.props.history.push('/events/events');
                    }
                })
                .catch(error => {
                    console.log(error.response);
                    this.setState({
                        firstName: '',
                        surname: '',
                        email: '',
                        password: '',
                        hasError: error.response && error.response.data ? error.response.data.error : null,
                    });
                });
        }
        else {
            console.log('Invalid details');
            this.setState({
                hasError: 'Please make sure all fields are valid',
            });
        }
    };

    render () {
        const { firstName, surname, email, password, hasError } = this.state;
        return (
            <div>
                {hasError && <div className="log-in-error"> {hasError} </div>}
                <form className="log-in-form" onSubmit={this.handleSubmit}>
                    <div className="sign-up-name-row">
                        <div className="input-group">
                            <label htmlFor="log-in-first-name">
                                First Name <span className="required-field">*</span>
                            </label>
                            <input
                                id="log-in-first-name"
                                value={firstName}
                                onChange={this.handleChange}
                                className="input"
                                name="firstName"
                                type="text"
                                placeholder="Mo"
                                required
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="log-in-surname">
                                Surname <span className="required-field">*</span>
                            </label>
                            <input
                                id="log-in-surname"
                                value={surname}
                                onChange={this.handleChange}
                                className="input"
                                name="surname"
                                type="text"
                                placeholder="Farah"
                                required
                            />
                        </div>
                    </div>
                    <div className="input-group">
                        <label htmlFor="log-in-email">
                            Email Address <span className="required-field">*</span>
                        </label>
                        <input
                            id="log-in-email"
                            value={email}
                            onChange={this.handleChange}
                            className="input"
                            name="email"
                            type="email"
                            placeholder={`name@${localiseText('organisation')}.com`}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="log-in-password">
                            Password <span className="required-field">*</span>
                        </label>
                        <input
                            id="log-in-password"
                            value={password}
                            onChange={this.handleChange}
                            className="input"
                            name="password"
                            type="password"
                            placeholder="Password"
                            required
                        />
                    </div>
                    <div className="log-in-form-submit">
                        <input id="log-in-submit" type="submit" className="log-in-btn btn" value="Sign Up" />
                    </div>
                    <p className="sign-up-disclaimer">
                        By signing up to Racecheck, you agree to the{' '}
                        <a href={'https://racecheck.com/terms_conditions/'} target="_blank" rel="noopener noreferrer">
                            Terms of Service
                        </a>
                        . View our{' '}
                        <a href={'https://racecheck.com/privacy_policy/'} target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                        </a>
                        .
                    </p>
                </form>
            </div>
        );
    }
}

FormSignUp.propTypes = {
    email: PropTypes.string,
};

FormSignUp.defaultProps = {
    email: '',
};

export default withRouter(FormSignUp);
