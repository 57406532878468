import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../helpers/loader';
import AppContext from '../app/AppContext';
import axios from 'axios';
import { useRouteMatch } from 'react-router';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import NoDataPlaceholder from '../helpers/noDataPlaceholder';

import SmartQuestionCard from './SmartQuestionCard';
import SmartQuestionResponsesModal from './SmartQuestionResponsesModal';
import SmallLoader from '../../images/small_loader.gif';
import { ReactComponent as Download } from '../../images/icons/download.svg';
import ErrorMessage from '../helpers/errorMessage';

const SmartQuestionsReport = ({ event }) => {
    const location = useLocation();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const context = useContext(AppContext);

    const { params } = useRouteMatch();
    const history = useHistory();

    const [selectedYear, setSelectedYear] = useState(queryParams?.selectedYear || '');
    const [selectedQuestion, setSelectedQuestion] = useState(queryParams?.selectedQuestion || 'all');
    const [questionsYears, setQuestionsYears] = useState([]);
    const [questionsList, setQuestionsList] = useState([]);
    const [questionsLoaded, setQuestionsLoaded] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [expandedQuestion, setExpandedQuestion] = useState({});
    const [allResponses, setAllResponses] = useState([]);
    const [answersLoaded, setAnswersLoaded] = useState(false);
    const [responsesModalOpen, setResponsesModalOpen] = useState(false);
    const [csvReportLoading, setCsvReportLoading] = useState(false);
    const [downloadcsvReportError, setDownloadCsvReportError] = useState('');


    const {
        token = localStorage.getItem('token'),
        selectedOrganisation
    } = context;

    const mpProps = selectedOrganisation ? {
        organisation: selectedOrganisation?.name,
        premium_subscriber: selectedOrganisation?.is_premium_subscriber
    } : {};


    const { REACT_APP_API_URL } = process.env;
    const headers = {
        Authorization: `Token ${token}`
    };

    useEffect(() => {
        getYearsList();
        getQuestionsList();
        getQuestions(selectedYear, selectedQuestion);

        // eslint-disable-next-line
    }, [selectedOrganisation.id, params.id]);

    const getYearsList = () => {
        const { id } = selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/smart_questions/years/`;

        axios.get(apiCallURL, { headers })
            .then(({ data: { data } }) => setQuestionsYears(data.smart_questions_years))
            .catch(err => console.log(err.response));
    };

    const createYearSelect = () =>
        questionsYears.map(option => (
            <option key={option} value={option}>
                {option}
            </option>
        ));

    const getQuestionsList = (year = '') => {
        const { id } = selectedOrganisation;
        // eslint-disable-next-line max-len
        let apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/smart_questions/questions_list/`;
        year && (apiCallURL += `year/${year}/`);
        axios.get(apiCallURL, { headers })
            .then(({ data: { data } }) => setQuestionsList(data.questions_list))
            .catch(err => console.log(err.response));
    };

    const createQuestionSelect = () =>
        questionsList.map(({ id, question }) => (
            <option key={id} value={id}>
                {question}
            </option>
        ));


    const handleChange = ({ target }) => {
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (target.name === 'selectedYear') {
            setSelectedYear(target.value);
            setSelectedQuestion('all');
            getQuestionsList(target.value);
            getQuestions(target.value, 'all');
            history.push({
                search: qs.stringify({
                    ...queryParams,
                    selectedYear: target.value,
                    selectedQuestion: 'all'
                })
            });
        }
        else if (target.name === 'selectedQuestion') {
            setSelectedQuestion(target.value);
            getQuestions(selectedYear, target.value);
            history.push({
                search: qs.stringify({
                    ...queryParams,
                    selectedQuestion: target.value,
                })
            });
        }
    };

    const getQuestions = (year = '', question = 'all') => {
        setQuestionsLoaded(false);
        const { id } = selectedOrganisation;
        // eslint-disable-next-line max-len
        let apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/smart_questions/?year=${year}`;
        question !== 'all' && (apiCallURL += `&question=${question}`);
        const headers = { Authorization: `Token ${token}` };
        axios
            .get(
                apiCallURL,
                { headers }
            )
            .then(({ data }) => {
                setQuestions(data.data);
                setQuestionsLoaded(true);
            })
            .catch(error => {
                console.log(error.response);
                setQuestionsLoaded(true);
            });
    };

    const getQuestionAnswers = (question) => {
        setAnswersLoaded(false);
        const { id } = selectedOrganisation;
        // eslint-disable-next-line max-len
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/smart_questions/${question.id}/answers/`;
        const headers = { Authorization: `Token ${token}` };
        axios
            .get(
                apiCallURL,
                { headers }
            )
            .then(({ data }) => {
                setAllResponses(data.data);
                setAnswersLoaded(true);
            })
            .catch(error => {
                console.log(error.response);
                setAnswersLoaded(true);
            });
    };

    const handleViewAllResponses = (question) => {
        getQuestionAnswers(question);
        setExpandedQuestion(question);
        setResponsesModalOpen(true);
    };

    const createQuestionsFeed = () => {
        if (!questions.length && questionsLoaded) return (
            <div style={{ flex: 1 }}>
                <NoDataPlaceholder />
            </div>
        );

        return (
            questions.map(question => (
                <SmartQuestionCard
                    key={question.id}
                    smartQuestion={question}
                    isPremium={selectedOrganisation?.is_premium_subscriber}
                    handleViewAllResponses={handleViewAllResponses}
                    mpProps={mpProps}
                    params={params}
                />
            ))
        );
    };

    const downloadSmartQuestionReportCSV = () => {
        setCsvReportLoading(true);
        const { id } = selectedOrganisation;
        // eslint-disable-next-line max-len
        let apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/smart_questions/?export=csv&year=${selectedYear}`;
        selectedQuestion !== 'all' && (apiCallURL += `&question=${selectedQuestion}`);
        const headers = { Authorization: `Token ${token}` };
        axios
            .get(
                apiCallURL,
                { headers }
            )
            .then(({ data }) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `smart_questions_report_${params.id}_${selectedYear}_${selectedQuestion}.csv`);
                document.body.appendChild(link);
                link.click();
                link.remove();

            })
            .catch(err => {
                if (err?.response?.status >= 400 && err?.response?.status < 500) {
                    console.log(err?.response);
                    setDownloadCsvReportError(err?.response?.data?.error ||
                        'Your report could not be downloaded. Please try again or contact our team at info@racecheck.com');
                }
            })
            .finally(() => {
                setCsvReportLoading(false);
            });
    };

    return (
        <>
            {responsesModalOpen && answersLoaded && (
                <SmartQuestionResponsesModal
                    setResponsesModalOpen={setResponsesModalOpen}
                    smartQuestion={expandedQuestion}
                    responses={allResponses}
                />
            )}
            <div className="smart-question-report-container">
                {downloadcsvReportError && (
                    <ErrorMessage
                        message={downloadcsvReportError}
                        resetErrorMessage={() => setDownloadCsvReportError('')}
                    />
                )}
                <div className="sq-r-top">
                    <Link to={'/events/' + params.id + '/reviews'}><span>←</span> Back to reviews</Link>
                    <div className="sq-r-h-title">
                        <h1>Smart Questions Report</h1>
                    </div>
                    <button
                        onClick={downloadSmartQuestionReportCSV}
                        id="mr-download-btn"
                        className="mr-download-btn mr-download-hide"
                        disabled={!questionsLoaded || csvReportLoading}
                    >
                        {
                            !questionsLoaded || csvReportLoading ? (
                                <img
                                    className="subscription-btn-loader"
                                    src={SmallLoader}
                                    alt="loader"
                                />) : <>Download<Download/></>
                        }
                    </button>
                </div>
                <div className="horizontal-dropdowns sq-r-filters">
                    {/* <label htmlFor="current-year" className="horiztonal-select-label">
                        Year:
                    </label> */}
                    <select
                        id="current-year"
                        className=""
                        name="selectedYear"
                        onChange={handleChange}
                        value={selectedYear}
                        required
                    >
                        {questionsYears && createYearSelect()}
                    </select>
                    {/* <label htmlFor="current-question" className="horiztonal-select-label">
                        Question:
                    </label> */}
                    <select
                        id="current-question"
                        className=""
                        name="selectedQuestion"
                        onChange={handleChange}
                        value={selectedQuestion}
                        required
                    >
                        <option value="all">All Smart Questions</option>
                        {questionsList && createQuestionSelect()}
                    </select>
                </div>
                {/* <div className="sq-r-header">
                    <div className="sq-r-h-title">
                        <Link to={'/events/' + params.id + '/reviews'}>←</Link>
                        <h1>Smart Questions Report</h1>
                    </div>
                </div> */}
                <div>
                    {questionsLoaded ?
                        createQuestionsFeed()
                        :
                        <div className="no-data-container">
                            <Loader />
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

SmartQuestionsReport.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.number
    })
};

SmartQuestionsReport.defaultProps = {
    event: {
        id: 0,
    },
};

export default SmartQuestionsReport;
