import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import SectionNav from '../organiserDashboard/SectionNav';
import SubscriptionPlan from './SubscriptionPlan';
import AppContext from '../app/AppContext';
import ErrorBoundary from '../helpers/errorBoundary';

export default function Subscription () {
    const appContext = useContext(AppContext);
    return (
        <div className={`section-container ${!appContext.menuOpen && 'section-container-main-nav-closed'}`}>
            <SectionNav section="subscription" />
            <ErrorBoundary>
                <div className="section-content">
                    <Route path="/subscription/subscription-plan" exact>
                        <SubscriptionPlan selectedOrganisation={appContext.selectedOrganisation} />
                    </Route>
                </div>
            </ErrorBoundary>
        </div>
    );
}
