import React, { Component } from 'react';
import PropTypes, { arrayOf } from 'prop-types';
import moment from 'moment';
import qs from 'qs';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import { ReactComponent as MastercardIcon } from '../../images/mastercard.svg';
import NewPaymentMethodModal from './NewPaymentMethodModal';
import ChangePlanModal from './ChangePlanModal';
import EditDetailsModal from './EditDetailsModal';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import RestartSubscriptionModal from './RestartSubscriptionModal';
import PayInvoiceModal from './PayInvoiceModal';
import penIcon from '../../images/pen.svg';
import AppContext from '../app/AppContext';
import { mapCurrencyToSign } from '../../utils';
class PremiumAccountView extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        // const defaultPayment = this.props.subscriptionDetails.details.credit_cards.filter(
        //     obj => obj.id === this.props.subscriptionDetails.details.Ypayment_method.creditcard_id
        // );

        const defaultPayment = {};

        const momentNextPayment = moment(this.props.subscriptionDetails.next_payment_date, 'YYYY-MM-DD');
        const momentTrialEndDate = moment(this.props.subscriptionDetails.trial_ending_date, 'YYYY-MM-DD');
        const momentCancellationDate = moment(this.props.subscriptionDetails.cancellation_date, 'YYYY-MM-DD');

        let subscriptionStatus;
        if (this.props.subscriptionDetails.status === 'A' && momentCancellationDate > new Date()) {
            subscriptionStatus = {
                title: 'Active',
                color: 'orange',
            };
        }
        else if (this.props.subscriptionDetails.status === 'A') {
            subscriptionStatus = {
                title: 'Active',
                color: 'green',
            };
        }
        else if (this.props.subscriptionDetails.status === 'S') {
            subscriptionStatus = {
                title: 'Suspended',
                color: 'red',
            };
        }
        else if (this.props.subscriptionDetails.status === 'C') {
            subscriptionStatus = {
                title: 'Cancelled',
                color: 'gray',
            };
        }
        else {
            subscriptionStatus = {
                title: 'Error',
                color: 'gray',
            };
        }

        this.state = {
            selectedInvoice: null,
            payInvoiceModalOpen: false,
            newPaymentMethodModalOpen: false,
            changePlanModalOpen: this.props.shouldOpenChangePlanModal,
            editDetailsModalOpen: false,
            cancelSubscriptionModalOpen: false,
            subscriptionStatus,
            defaultPaymentMethod: defaultPayment.length > 0 ? defaultPayment[0] : {},
            nextPaymentDateString:
                momentNextPayment > new Date() ? moment(momentNextPayment).format('Do MMMM YYYY') : null,
            cancellationDateString:
                momentCancellationDate > new Date() ? moment(momentCancellationDate).format('Do MMMM YYYY') : null,
            trialEndingDateString: momentTrialEndDate > new Date() ? momentTrialEndDate.format('Do MMMM YYYY') : null,
            toggleModalCallback: undefined,
            invoicesList: [],
        };
    }

    componentDidMount () {
        this.getDefaultPaymentMethod();
        const { addNewPaymentMethod, action } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        if (addNewPaymentMethod === 'success') {
            this.setState({
                newPaymentMethodModalOpen: true,
            });
        }

        if (action === 'edit_payment_methods') {
            this.setState({
                newPaymentMethodModalOpen: true,
            });
        }
        
        const { id } = this.context.selectedOrganisation;
        const { REACT_APP_API_URL } = process.env;
        const token = this.context.token
            ? this.context.token
            : localStorage.getItem('token');

        const { id: subscriptionId } = this.props.subscriptionDetails;
        
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/subscriptions/${subscriptionId}/get_invoices/`;
        const headers = {
            Authorization: `Token ${token}`,
        };


        axios.get(apiCallURL, { headers })
            .then(({ data }) => {
                this.setState({
                    invoicesList: data?.data,
                });
            });
    }

    getDefaultPaymentMethod = () => {
        const {
            payment_method,
            credit_cards = []
        } = this.props.subscriptionDetails.details;

        const defaultPaymentMethod = credit_cards.find(
            ({ id }) => id === payment_method.creditcard_id
        );

        this.setState({ defaultPaymentMethod });
    };

    togglePayInvoiceModal = (triggerSubDataRefresh, invoiceID) => {
        const { payInvoiceModalOpen } = this.state;
        payInvoiceModalOpen
            ? this.setState(
                {
                    payInvoiceModalOpen: false,
                    selectedInvoice: null,
                },
                () => {
                    if (triggerSubDataRefresh) {
                        this.props.updateSubData(false);
                    }
                }
            )
            : this.setState({
                payInvoiceModalOpen: true,
                selectedInvoice: invoiceID,
            });
    };

    toggleNewPaymentMethodModal = (triggerSubDataRefresh, toggleModalCallback = undefined) => {
        const { newPaymentMethodModalOpen } = this.state;

        this.props.history.replace({
            search: ''
        });
        
        if (toggleModalCallback)
            this.setState({
                toggleModalCallback
            });

        newPaymentMethodModalOpen
            ? this.setState(
                {
                    newPaymentMethodModalOpen: false,
                },
                () => {
                    if (triggerSubDataRefresh) {
                        this.props.updateSubData(false);
                    }
                    if (this.state.toggleModalCallback)
                        this.state.toggleModalCallback();
                }
            )
            : this.setState({
                newPaymentMethodModalOpen: true,
            });
    };

    toggleEditDetailsModal = (triggerSubDataRefresh, toggleModalCallback) => {
        const { editDetailsModalOpen } = this.state;

        if (toggleModalCallback)
            this.setState({
                toggleModalCallback
            });

        editDetailsModalOpen
            ? this.setState(
                {
                    editDetailsModalOpen: false,
                },
                () => {
                    if (triggerSubDataRefresh) {
                        this.props.updateSubData(false);
                    }

                    if (this.state.toggleModalCallback)
                        this.state.toggleModalCallback();
                }
            )
            : this.setState({
                editDetailsModalOpen: true,
            });
    };

    toggleChangePlanModal = trigger => {
        const { changePlanModalOpen } = this.state;
        changePlanModalOpen
            ? this.setState(
                {
                    changePlanModalOpen: false,
                },
                () => {
                    if (trigger === 'cancelTrigger') {
                        this.toggleCancelSubscriptionModal();
                    }
                    else if (trigger === true) {
                        this.props.updateSubData(false);
                    }
                }
            )
            : this.setState({
                changePlanModalOpen: true,
            });
    };

    toggleCancelSubscriptionModal = triggerSubDataRefresh => {
        const { cancelSubscriptionModalOpen } = this.state;
        cancelSubscriptionModalOpen
            ? this.setState(
                {
                    cancelSubscriptionModalOpen: false,
                },
                () => {
                    if (triggerSubDataRefresh) {
                        this.props.updateSubData(false);
                    }
                }
            )
            : this.setState({
                changePlanModalOpen: false,
                cancelSubscriptionModalOpen: true,
            });
    };

    toggleRestartSubscriptionModal = triggerSubDataRefresh => {
        const { restartSubscriptionModalOpen } = this.state;
        restartSubscriptionModalOpen
            ? this.setState(
                {
                    restartSubscriptionModalOpen: false,
                },
                () => {
                    if (triggerSubDataRefresh) {
                        this.props.updateSubData(false);
                    }
                }
            )
            : this.setState({
                restartSubscriptionModalOpen: true,
            });
    };

    
    getFinalCost = (paycycle, basePrice) => {
        const { country } = this.props.subscriptionDetails.details.customer;
        const { vat } = this.props.subscriptionPlans.plans.premium[0].payment_details.find(plan => plan.paycycle === paycycle);
        
        let totalCost;
        let hasVAT = false;
        if (country === 'United Kingdom') {
            hasVAT = true;
            if (paycycle === 'M' || paycycle === 'Monthly') {
                totalCost = +basePrice + (basePrice * vat) / 100;
            }
            else {
                totalCost = basePrice * 12 + (basePrice * 12 * vat) / 100;
            }
        }
        else if (paycycle === 'M' || paycycle === 'Monthly') {
            totalCost = +basePrice;
        }
        else {
            totalCost = +basePrice * 12;
        }
        return {
            totalCost: parseFloat(totalCost.toFixed(2)),
            hasVAT,
        };
    };

    getTotalCost = (count, plan = this.props.subscriptionDetails) => {

        const result = parseFloat(
            ((count - 1) * plan.extra_quantity_base_price) + parseFloat(plan.base_price)
        );

        return result;
    };

    calculateSubscriptionPaymentAmount = (country, subscriptionBasePrice, vat) => {
        let totalCost;
        let hasVAT = false;
        if (country === 'United Kingdom') {
            hasVAT = true;
            totalCost = subscriptionBasePrice + (subscriptionBasePrice * vat) / 100;
        }
        else {
            totalCost = subscriptionBasePrice;
        }
        return {
            totalCost: totalCost.toFixed(2),
            hasVAT,
        };
    };

    render () {
        const {
            selectedInvoice,
            payInvoiceModalOpen,
            newPaymentMethodModalOpen,
            changePlanModalOpen,
            editDetailsModalOpen,
            cancelSubscriptionModalOpen,
            restartSubscriptionModalOpen,
            defaultPaymentMethod,
            nextPaymentDateString,
            cancellationDateString,
            trialEndingDateString,
            subscriptionStatus,
            invoicesList
        } = this.state;

        const { totalCost, hasVAT } = this.calculateSubscriptionPaymentAmount(
            this.props.subscriptionDetails?.details?.customer?.country,
            parseFloat(this.props.subscriptionDetails?.subscription_total_price_wo_vat),
            parseFloat(this.props.subscriptionDetails?.plan?.vat)
        );

        const dueInvoices = [];
        if (this.props.subscriptionDetails.details.open_invoices.length > 0) {
            dueInvoices.push(...this.props.subscriptionDetails.details.open_invoices.map(invoice => (
                <tr className="msc-invoice-details" key={invoice.invoice_id}>
                    <td>{invoice.invoice_id}</td>
                    <td>{moment(invoice.due_date, 'YYYY-MM-DD').format('Do MMM YYYY')}</td>
                    <td>
                        <div className="ac-tag">
                            <p className="ac-tag-content ac-tag-Subscription">Overdue</p>
                        </div>
                    </td>
                    <td className="msc-invoice-cost">£{invoice.balance}</td>
                    <td>
                        <div className="modal-btn-row msc-btn">
                            <div
                                role="button"
                                className="btn primary-btn modal-form-btn msc-invoice-btn"
                                onClick={() => this.togglePayInvoiceModal(false, invoice.invoice_id)}
                            >
                                Pay invoice
                            </div>
                        </div>
                    </td>
                </tr>
            )));
        }

        return (
            <div className="subscription-plans-container">
                {cancellationDateString && (
                    <div className="subscription-warning-banner">
                        <p className="subscription-warning-text">
                            <span className="subscription-warning-icon">
                                <ExclamationIcon />
                            </span>
                            Your Racecheck Premium plan will downgrade to Free on{' '}
                            {cancellationDateString}.
                        </p>
                        {this.props.subscriptionDetails.details.statuses.can_resume && 
                            <p className="subscription-warning-btn" onClick={this.toggleRestartSubscriptionModal}>
                                Cancel downgrade
                            </p>
                        }
                    </div>
                )}
                {this.props.subscriptionDetails.chargeover_id && (
                    <div className="subscription-co-banner">
                        <p className="subscription-co-text">
                            <span className="subscription-co-icon">
                                <ExclamationIcon />
                            </span>
                            We are currently in the process of upgrading our payment system.
                            As a result, contact us to make any changes to your Racecheck Premium subscription plan.
                        </p>
                    </div>
                )}
                {trialEndingDateString && !cancellationDateString && (
                    <div className="subscription-info-banner">
                        <p className="subscription-info-text">
                            <span className="subscription-info-icon">
                                <ExclamationIcon />
                            </span>
                            Your free period ends on {trialEndingDateString}. See your renewal details below.
                        </p>
                    </div>
                )}
                <div className="manage-subscription-card">
                    <div className="msc-left">
                        <p className="msc-title">Your subscription</p>
                        <h2 className="msc-highlight">
                            Racecheck Premium
                        </h2>
                        {/* <p className="msc-description">
                            Premium widgets x{this.props.subscriptionDetails.quantity} ·{' '}
                            {this.props.subscriptionDetails.plan.paycycle === 'M' ? 'Monthly plan' : 'Annual plan'}
                        </p> */}
                        <div className="msc-renewal-status">
                            <span className={`msc-renewal-badge msc-renewal-badge-${subscriptionStatus.color}`} />
                            {nextPaymentDateString && subscriptionStatus.title === 'Active' && !cancellationDateString && (
                                <div className="msc-renewal-title">Renews automatically on {nextPaymentDateString}</div>
                            )}
                            {subscriptionStatus.title === 'Suspended' && (
                                <div className="msc-renewal-title">Your subscription is suspended.&nbsp;</div>
                            )}
                            {cancellationDateString && (
                                <div className="msc-renewal-status">
                                    Set to downgrade to Free on {cancellationDateString}
                                </div>
                            )}
                        </div>
                        <div className="msc-payment-info">
                            <p className="msc-payment-next-date">
                                {this.props.subscriptionDetails?.plan?.paycycle === 'M' ? 'Monthly' : 'Annual'}{' '}
                            </p>
                            <span className="bullet">•</span>
                            <span className="msc-payment-paycycle">
                                {mapCurrencyToSign[this.props.subscriptionDetails?.subscription_total_price_wo_vat_currency]}
                                {/* eslint-disable-next-line */}
                                {this.props.subscriptionDetails?.subscription_total_price_wo_vat}

                                {/* eslint-disable-next-line */}
                                {hasVAT ? ` + ${mapCurrencyToSign[this.props.subscriptionDetails?.subscription_total_price_wo_vat_currency]}${(this.props.subscriptionDetails?.subscription_total_price_wo_vat * (this.props.subscriptionDetails?.plan?.vat / 100)).toFixed(2)} (VAT)` : ''}
                                <span className="msc-payment-period">
                                    {this.props.subscriptionDetails?.plan?.paycycle === 'M' ? ' /mo' : ' /yr'}{' '}
                                </span>
                            </span>
                            {defaultPaymentMethod?.number && defaultPaymentMethod?.type &&
                            <>
                                <span className="bullet">•</span>
                                <div className="msc-payment-card">
                                    <span className="payment-method-inline-logo">
                                        {defaultPaymentMethod.type === 'Visa' ? (
                                            <img
                                                src={require('../../images/visa.png')}
                                                className="pm-visa-logo-inline"
                                                alt="visa-logo"
                                            />
                                        ) : (
                                            <MastercardIcon />
                                        )}
                                    </span>
                                    
                                    <span className="msc-payment-card-number">
                                        {defaultPaymentMethod.number.substr(defaultPaymentMethod.number.length - 4, 4)}
                                    </span>
                                </div>
                                <img 
                                    src={penIcon} 
                                    alt="edit payment method" 
                                    className="msc-payment-edit-card" 
                                    onClick={this.toggleNewPaymentMethodModal}  
                                />
                            </>
                            }
                        </div>
                        {!defaultPaymentMethod?.number && !defaultPaymentMethod?.type &&
                        <div className="msc-payment-warning">
                            <p onClick={this.toggleNewPaymentMethodModal}>Update your expired payment method →</p>
                        </div>
                        }
                    </div>
                    <div className="msc-right">
                        <div className="modal-btn-col msc-btn">
                            <button
                                className={`btn secondary-btn secondary-btn-rounded modal-form-btn ${
                                    cancellationDateString ||
                                    subscriptionStatus.title !== 'Active'
                                        ? 'disabled-secondary-btn'
                                        : ''
                                }`}
                                onClick={this.toggleChangePlanModal}
                                disabled={cancellationDateString || 
                                    subscriptionStatus.title !== 'Active'}
                            >
                                Manage your plan
                            </button>
                            <div
                                role="button"
                                className="btn secondary-btn secondary-btn-rounded modal-form-btn"
                                onClick={this.toggleEditDetailsModal}
                            >
                                Edit your details
                            </div>
                        </div>
                    </div>
                </div>
                {invoicesList?.length ? (
                    <div className="manage-subscription-card msc-invoices">
                        <p className="msc-title">
                            {/* {this.props.subscriptionDetails.details.open_invoices.length} Overdue Invoices */}
                            Your invoices
                        </p>
                        <table className="msc-invoice-table">
                            <thead>
                                <tr>
                                    <th>Date created</th>
                                    <th>Payment amount</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoicesList?.map(invoice => 
                                    (
                                        <tr 
                                            key={invoice?.id} 
                                            className={`msc-invoice-item 
                                            ${invoice.status === 'open' ? 'msc-invoice-item-open' : ''}`}
                                        >
                                            <td>{moment(invoice.created).format('Do MMMM YYYY')}</td>
                                            {invoice.status === 'paid' ?
                                                <td>{mapCurrencyToSign[invoice.currency]}{invoice.amount_paid}</td>
                                                :
                                                <td>{mapCurrencyToSign[invoice.currency]}{invoice.amount_due}</td>
                                            }
                                            <td className="msc-i-status-container">
                                                <div className="msc-i-status">
                                                    <p className={`msc-i-status-${invoice.status}`}>{invoice.status}</p>
                                                </div>
                                            </td>
                                            <td>
                                                {invoice.status === 'paid' ?
                                                    <a 
                                                        href={invoice.invoice_pdf} 
                                                        className={`btn 
                                                        secondary-btn 
                                                        secondary-btn-rounded 
                                                        invoice-action-btn 
                                                        modal-form-btn`}
                                                    >Download
                                                    </a>
                                                    :
                                                    <a 
                                                        href={invoice.hosted_invoice_url} 
                                                        target="_blank" rel="noopener noreferrer" 
                                                        className="btn primary-btn invoice-action-btn modal-form-btn"
                                                    >Pay invoice
                                                    </a>
                                                }
                                            </td>
                                        </tr>)
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : ''}

                {this.props.subscriptionDetails.details.open_invoices.length > 0 ? (
                    <div className="manage-subscription-card msc-invoices">
                        <p className="msc-title">
                            {/* {this.props.subscriptionDetails.details.open_invoices.length} Overdue Invoices */}
                            Your invoices
                        </p>
                        <table className="msc-invoice-table">
                            <thead>
                                <tr>
                                    <th>Date created</th>
                                    <th>Payment amount</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.subscriptionDetails.details.open_invoices?.map(invoice => 
                                    (
                                        <tr 
                                            key={invoice?.invoice_id} 
                                            className={`msc-invoice-item 
                                            ${invoice.status === 'open' ? 'msc-invoice-item-open' : ''}`}
                                        >
                                            <td>{moment(invoice.created).format('Do MMMM YYYY')}</td>
                                            <td>
                                                {mapCurrencyToSign[
                                                    this.props.subscriptionDetails?.subscription_total_price_wo_vat_currency
                                                ]}
                                                {invoice.total}
                                            </td>
                                            <td className="msc-i-status-container">
                                                <div className="msc-i-status">
                                                    <p className={`msc-i-status-${invoice.status_name}`}>{invoice.status_name}</p>
                                                </div>
                                            </td>
                                            <td>
                                                <button 
                                                    onClick={() => this.togglePayInvoiceModal(false, invoice.invoice_id)}
                                                    className="btn primary-btn invoice-action-btn modal-form-btn"
                                                >Pay invoice
                                                </button>
                                            </td>
                                        </tr>)
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : ''}

                {/* {invoicesList?.length && (
                    <>
                        {invoicesList?.map(invoice => 
                            <div className="msc-invoice-card">
                                <div className="msc-i-item">
                                    <p className="msc-i-item-val">Racecheck Premium</p>
                                    <p className="msc-i-item-title">Tier 3 Monthly</p>
                                </div>
                                <div className="msc-i-item">
                                    <p className="msc-i-item-val">23rd Jun '22</p>
                                    <p className="msc-i-item-title">Payment date</p>
                                </div>
                                <div className="msc-i-item">
                                    <p className="msc-i-item-val">{mapCurrencyToSign[invoice.currency]}{invoice.amount_due}</p>
                                    <p className="msc-i-item-title">Payment amount</p>
                                </div>
                                <div className="msc-i-status">
                                    <p className={`msc-i-status-${invoice.status}`}>{invoice.status}</p>
                                </div>
                            </div>
                        )}
                    </>
                )} */}

                {newPaymentMethodModalOpen && (
                    <NewPaymentMethodModal
                        toggleModal={this.toggleNewPaymentMethodModal}
                        subscriptionDetails={this.props.subscriptionDetails.details}
                        updateSubData={this.props.updateSubData}
                        chargeoverSubscription={this.props.subscriptionDetails?.chargeover_id}
                    />
                )}
                {payInvoiceModalOpen && (
                    <PayInvoiceModal
                        toggleModal={this.togglePayInvoiceModal}
                        subscriptionDetails={this.props.subscriptionDetails}
                        invoiceID={selectedInvoice}
                        updateSubData={this.props.updateSubData}
                    />
                )}
                {editDetailsModalOpen && (
                    <EditDetailsModal
                        toggleModal={this.toggleEditDetailsModal}
                        subscriptionDetails={this.props.subscriptionDetails}
                        updateSubData={this.props.updateSubData}
                    />
                )}
                {changePlanModalOpen && !cancellationDateString && (
                    <ChangePlanModal
                        onClose={this.toggleChangePlanModal}
                        subscriptionDetails={this.props.subscriptionDetails}
                        updateSubData={this.props.updateSubData}
                        trialEndingDateString={trialEndingDateString}
                        subscriptionPlans={this.props.subscriptionPlans}
                        getFinalCost={this.getFinalCost}
                        getTotalCost={this.getTotalCost}
                        toggleCancelSubscriptionModal={this.toggleCancelSubscriptionModal}
                        toggleEditDetailsModal={this.toggleEditDetailsModal}
                        toggleNewPaymentMethodModal={this.toggleNewPaymentMethodModal}
                        trialStatus={trialEndingDateString}
                    />
                )}
                {cancelSubscriptionModalOpen && (
                    <CancelSubscriptionModal
                        toggleModal={this.toggleCancelSubscriptionModal}
                        subscriptionDetails={this.props.subscriptionDetails}
                        updateSubData={this.props.updateSubData}
                    />
                )}
                {restartSubscriptionModalOpen && (
                    <RestartSubscriptionModal
                        toggleModal={this.toggleRestartSubscriptionModal}
                        toggleEditDetailsModal={this.toggleEditDetailsModal}
                        subscriptionDetails={this.props.subscriptionDetails}
                        updateSubData={this.props.updateSubData}
                        totalCost={totalCost}
                        hasVAT={hasVAT}
                        toggleNewPaymentMethodModal={this.toggleNewPaymentMethodModal}
                    />
                )}
            </div>
        );
    }
}

PremiumAccountView.propTypes = {
    subscriptionDetails: PropTypes.shape({
        chargeover_id: PropTypes.string,
        id: PropTypes.number.isRequired,
        details: PropTypes.shape({
            customer: PropTypes.shape({
                country: PropTypes.string.isRequired,
            }).isRequired,
            credit_cards: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                })
            ).isRequired,
            payment_method: PropTypes.shape({
                creditcard_id: PropTypes.number,
            }).isRequired,
            open_invoices: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
            statuses: PropTypes.shape({
                can_resume: PropTypes.bool,
            }),
        }).isRequired,
        subscription_total_price_wo_vat: PropTypes.number,
        next_payment_date: PropTypes.string,
        trial_ending_date: PropTypes.string,
        cancellation_date: PropTypes.string,
        status: PropTypes.string,
        plan: PropTypes.shape({
            title: PropTypes.string.isRequired,
            vat: PropTypes.string.isRequired,
            paycycle: PropTypes.string.isRequired,
            extra_quantity_base_price: PropTypes.string,
            base_price: PropTypes.string
        }).isRequired,
        quantity: PropTypes.number.isRequired,
        subscription_total_price_wo_vat_currency: PropTypes.string
    }).isRequired,
    shouldOpenChangePlanModal: PropTypes.bool.isRequired,
    updateSubData: PropTypes.func.isRequired,
    subscriptionPlans: PropTypes.shape({
        plans: PropTypes.shape({
            premium: PropTypes.arrayOf(
                PropTypes.shape({
                    payment_details: arrayOf(
                        PropTypes.shape({
                            paycycle: PropTypes.string
                        })
                    )
                })
            )
        })
    })
};

PremiumAccountView.defaultProps = {
    subscriptionDetails: {
        details: {
            customer: {
                country: '',
            },
            credit_cards: [
                {
                    id: 0,
                }
            ],
            payment_method: {
                creditcard_id: 0,
            },
        },
        plan: {
            title: '',
            vat: '',
            paycycle: '',
        },
        quantity: 0,
        shouldOpenChangePlanModal: false,
        updateSubData: () => {},
    },
};

export default withRouter(PremiumAccountView);
