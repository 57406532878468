import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import qs from 'qs';
import mixpanel from 'mixpanel-browser';

import './ReviewSection.css';
import ReviewCard from './ReviewCard';
import AwardProgress from './AwardProgress';
import RatingBreakdown from './RatingBreakdown';
import TrophyCabinet from './TrophyCabinet';
import NoDataPlaceholder from '../helpers/noDataPlaceholder';
import AppContext from '../app/AppContext';
import Loader from '../helpers/loader';
import Checkbox from '../helpers/checkbox';

import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';
import { ReactComponent as ExclamationCircleIcon } from '../../images/icons/exclamation_circle.svg';
import { ReactComponent as RacecheckAIIcon } from '../../images/icons/racecheck-ai_outline.svg';
import ReviewAnalytics from './ReviewAnalytics';
import NPSReport from './NPSReport';

const ReviewFeed = ({ event, remainingReplies, setRepliesRemaining }) => {
    const location = useLocation();
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });


    const [reviewList, setReviewList] = useState([]);
    const [reviewCount, setReviewCount] = useState(0);
    const [reviewsLoaded, setReviewsLoaded] = useState(false);
    const [ratingBreakdownLoaded, setRatingBreakdownLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [hasMoreReviews, setHasMoreReviews] = useState(false);
    const [awardData, setAwardData] = useState([]);
    const [awardsLoaded, setAwardsLoaded] = useState(false);
    const [ratingBreakdownData, setRatingBreakdownData] = useState();
    const [npsData, setNPSData] = useState(null);
    const [npsLoaded, setNPSLoaded] = useState(false);
    const [selectedYear, setSelectedYear] = useState(queryParams?.selectedYear || '');
    const [selectedSorting, setSelectedSorting] = useState(queryParams?.selectedSorting || 'most-recent-first');
    const [reviewFilters, setReviewFilters] = useState([]);
    const [reviewedYears, setReviewedYears] = useState([]);
    const [isBookmarked, setIsBookmarked] = useState(queryParams['bookmark-only'] === 'true');
    const [isUnreplied, setIsUnreplied] = useState(queryParams?.unreplied === 'true');

    const { params } = useRouteMatch();
    const history = useHistory();

    const context = useContext(AppContext);

    const { 
        token = localStorage.getItem('token'),
        selectedOrganisation
    } = context;

    const { REACT_APP_API_URL } = process.env;
    const headers = {
        Authorization: `Token ${token}`
    };

    const getYearsList = () => {
        const { id } = selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/reviewed_years/`;

        axios.get(apiCallURL, { headers })
            .then(({ data: { data } }) => setReviewedYears(data.reviewed_years))
            .catch(err => console.log(err.response));
    };

    const createYearSelect = () =>
        reviewedYears.map(option => (
            <option key={option} value={option}>
                {option}
            </option>
        ));

    const createSortingSelect = () =>
        reviewFilters.map(option => (
            <option key={option.id} value={option.parameter_value}>
                {option.display}
            </option>
        ));

    const getEventReviews = (pageNumber) => {
        setCurrentPage(pageNumber);
        const { id: orgId } = selectedOrganisation;
        const sortBy = `sort-by=${selectedSorting}`;
        const page = pageNumber > 1 ? `page=${pageNumber}` : '';
        const year = !selectedYear || selectedYear === 'all' ? '' : `year/${selectedYear}/`;
        const bookmarked = !isBookmarked ? '' : 'bookmarked=True';
        const unreplied = !isUnreplied ? '' : 'unreplied=True';

        /* eslint-disable-next-line*/
        const apiCallURL = 
            `${REACT_APP_API_URL}/api/react/organisations/${orgId}/events/${params.id}/reviews/${year}`
            + `?${sortBy}&${page}&${bookmarked}&${unreplied}`;

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                setReviewCount(data.total_reviews);
                setHasMoreReviews(!!data.next);
                if (pageNumber === 1) {
                    setReviewList([]);
                }
                setReviewList(prevState => [...prevState, ...data.results]);
                setReviewsLoaded(true);
            })
            .catch((err) => console.log('Could not retrieve Reviews', err));
    };

    const getAwardData = () => {
        const { id: orgId } = selectedOrganisation;
        const year = !selectedYear || selectedYear === 'all' ? '' : `${selectedYear}/`;

        /* eslint-disable-next-line*/
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${orgId}/events/${params.id}/award_progress/${year}`;
        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                setAwardData(data.results);
                setAwardsLoaded(true);
            })
            .catch(({ response }) => console.log(response));
    };

    const getNPSData = () => {
        const { id: orgId } = selectedOrganisation;
        const year = !selectedYear || selectedYear === 'all' ? '' : `${selectedYear}/`;

        /* eslint-disable-next-line*/
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${orgId}/events/${params.id}/nps/${year}`;
        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                setNPSData(data.data);
                setNPSLoaded(true);
            })
            .catch(({ response }) => {
                console.log(response);
                setNPSLoaded(true);
            });
    };


    const handleChange = ({ target }) => {
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (target.name === 'selectedYear') {
            setSelectedYear(target.value);
            setRatingBreakdownLoaded(false);
        }
        else if (target.name === 'selectedSorting') {
            setSelectedSorting(target.value);
        }
        history.push({
            search: qs.stringify({
                ...queryParams,
                [target.name]: target.value
            })
        });
        setReviewsLoaded(false);
        setCurrentPage(1);
    };

    const getRatingBreakdown = () => {
        const year = !selectedYear || selectedYear === 'all' ? '' : `${selectedYear}/`;
        /* eslint-disable-next-line*/
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/events/${params.id}/review_rating_breakdown/${year}`;

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                setRatingBreakdownData(data);
                setRatingBreakdownLoaded(true);
            })
            .catch(error => console.log(error.response));
    };

    const getReviewFilters = () => {
        const apiCallURL = `${REACT_APP_API_URL}/api/react/lookup/review_sort_by/`;

        axios
            .get(apiCallURL, { headers })
            .then(res => {
                setReviewFilters(res?.data);
                if (!selectedSorting) {
                    setSelectedSorting(res?.data[0]?.parameter_value);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const refreshDataAfterUpdate = () => {
        const pageNumber = 1;
        setCurrentPage(1);
        const { id: orgId } = selectedOrganisation;
        const sortBy = `sort-by=${selectedSorting}`;
        const page = pageNumber > 1 ? `page=${pageNumber}` : '';
        const year = !selectedYear || selectedYear === 'all' ? '' : `year/${selectedYear}/`;
        const bookmarked = !isBookmarked ? '' : 'bookmarked=True';
        const unreplied = !isUnreplied ? '' : 'unreplied=True';
        /* eslint-disable-next-line*/
        const apiCallURL = 
            `${REACT_APP_API_URL}/api/react/organisations/${orgId}/events/${params.id}/reviews/${year}`
            + `?${sortBy}&${page}&${bookmarked}&${unreplied}`;

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                setReviewCount(data.total_reviews);
                setHasMoreReviews(!!data.next);
                setReviewList(data.results);
                setReviewsLoaded(true);
            })
            .catch((err) => console.log('Could not retrieve Reviews', err));
    };

    const handleBookmarkedChange = () => {
        setReviewsLoaded(false);
        setIsBookmarked(prevState => !prevState);
    };

    const handleUnrepliedChange = () => {
        setReviewsLoaded(false);
        setIsUnreplied(prevState => !prevState);
    };

    const feed = () => {
        let isFirstSmartQuestion = true;
        const mpProps = selectedOrganisation ? {
            organisation: selectedOrganisation?.name,
            premium_subscriber: selectedOrganisation?.is_premium_subscriber
        } : {};

        const reviewListView = reviewList.map(review => {
            const showSmartQuestionTopBanner = isFirstSmartQuestion;
            if (review.smart_question) {
                isFirstSmartQuestion = false;
            }
            return (
                <ReviewCard
                    key={review.id}
                    review={review}
                    eventID={params.id}
                    remainingReplies={remainingReplies}
                    refreshList={refreshDataAfterUpdate}
                    setRepliesRemaining={setRepliesRemaining}
                    mpProps={mpProps}
                    showSmartQuestionTopBanner={showSmartQuestionTopBanner}
                />
            );
        }
        );
    
        if (!reviewList.length && reviewsLoaded) return (
            <div className="review-feed">
                <div className="review-feed-cols">
                    <div style={{ flex: 1 }}>
                        <NoDataPlaceholder />
                    </div>
                    <div className="review-feed-right">
                        <div className="rfr-inner-container">
                            {params.id !== 'all' && (
                                <>
                                    {event.remaining_tasks > 0 &&
                                    <Link 
                                        to={`/events/${params.id}/request-reviews`} 
                                        className="review-checklist-container"
                                        onClick={() => {
                                            /* eslint-disable-next-line */
                                            mixpanel.track('Dashboard - Review feed - Outstanding review tasks click', mpProps);
                                        }}
                                    >
                                        <div className="review-checklist-copy">
                                            <div className="review-checklist-copy-info">
                                                <span className="count">{event.remaining_tasks}</span>
                                                <span className="text">
                                                    Outstanding review task{event.remaining_tasks === 1 ? '' : 's'}
                                                </span>
                                            </div>
                                            <span className="arrow">
                                                <ChevronRight />
                                            </span>
                                        </div>
                                    </Link>
                                    }
                                    {ratingBreakdownData &&
                                        <RatingBreakdown
                                            ratingBreakdownData={ratingBreakdownData}
                                            year={selectedYear}
                                            count={reviewCount}
                                        />
                                    }
                                    {/* <div className="review-count-container">
                                        <p className="review-count-title">
                                            {selectedYear !== 'all'
                                                ? selectedYear
                                                : 'All time'}{' '}
                                                    review count
                                        </p>
                                        <p className="review-count-number">
                                            {reviewCount}
                                            <span className="review-count-copy"> reviews</span>
                                        </p>
                                    </div> */}
                                    {/* {ratingBreakdownData &&
                                        <RatingBreakdown
                                            ratingBreakdownData={ratingBreakdownData}
                                            year={selectedYear}
                                        />
                                    } */}
                                    {awardsLoaded &&
                                    <>
                                        {awardData && !(
                                            awardData.year <
                                                    awardData.active_award_year
                                        ) && <AwardProgress awardData={awardData} />}
                                        {awardData?.all_awards?.length > 0 && (
                                            <TrophyCabinet
                                                awardData={awardData}
                                                year={selectedYear}
                                            />
                                        )}
                                    </>
                                    }
                                    <ReviewAnalytics event={event} />
                                </>
                            )}
                            {/* <div className="reviews-toolkit-cta">
                                <p className="toolkits-cta-title">
                                            Use reviews to your advantage
                                </p>
                                <p className="toolkits-cta-copy">
                                            Our review toolkits give you the resources you need to
                                            market your event like a pro.
                                </p>
                                <Link to="/resources/toolkits">
                                    <p className="toolkits-cta-link">View toolkits</p>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="review-feed">
                <div className="review-feed-cols">
                    <div className="review-feed-left">
                        {remainingReplies === 0 &&
                            <div className="review-no-replies-banner">
                                <div className="review-no-replies-copy">
                                    <div className="review-no-replies-copy-info">
                                        <ExclamationCircleIcon />
                                        <p className="text">
                                            <span>0 replies remaining for this event</span>; upgrade for unlimited!
                                        </p>
                                    </div>
                                    <Link 
                                        to="/subscription/subscription-plan" 
                                        className="btn primary-btn"
                                        onClick={() => {
                                            /* eslint-disable-next-line */
                                            mixpanel.track('Dashboard - Review feed - Reply banner - Upgrade to Premium click', mpProps);
                                        }}
                                    >
                                        Upgrade to Premium
                                    </Link>
                                </div>
                            </div>
                        }
                        {reviewListView.length > 0 ? (
                            <InfiniteScroll
                                className="event-cards-container"
                                dataLength={reviewList?.length}
                                next={() => getEventReviews(currentPage + 1)}
                                hasMore={hasMoreReviews}
                                loader={
                                    <div className="no-data-container">
                                        <Loader />
                                    </div>
                                }
                            >
                                {reviewListView}
                            </InfiniteScroll>
                        ) : null}
                    </div>
                    <div className="review-feed-right">
                        <div className="rfr-inner-container">
                            {params.id !== 'all' && (
                                <>
                                    {/* <div className="smart-questions-intro">
                                        <div className="sq-i-inner">
                                            <RacecheckAIIcon />
                                            <p className="sq-i-title">
                                                Smart Questions are here!
                                            </p>
                                            <p className="sq-i-copy">
                                                Uncover invaluable insights with dynamically generated review questions.
                                            </p>
                                            <a href="https://blog.racecheck.com/announcing-smart-questions-4de9a9bd276b">
                                                Learn more
                                            </a>
                                        </div>
                                    </div>
                                    <div className="review-rc-divider" /> */}
                                    {event.remaining_tasks > 0 &&
                                    <Link 
                                        to={`/events/${params.id}/request-reviews`} 
                                        className="review-checklist-container"
                                        onClick={() => {
                                            mixpanel.track('Dashboard - Review feed - Outstanding review tasks click', mpProps);
                                        }}
                                    >
                                        <div className="review-checklist-copy">
                                            <div className="review-checklist-copy-info">
                                                <span className="count">{event.remaining_tasks}</span>
                                                <span className="text">
                                                    Outstanding review task{event.remaining_tasks === 1 ? '' : 's'}
                                                </span>
                                            </div>
                                            <span className="arrow">
                                                <ChevronRight />
                                            </span>
                                        </div>
                                    </Link>
                                    }

                                    {event.smart_questions > 0 &&
                                        <Link
                                            // eslint-disable-next-line max-len
                                            className={`sq-report-btn ${event.smart_questions === 0 ? 'sq-report-btn-disabled' : ''}`}
                                            to={`/events/${params.id}/smart-questions-report`}
                                        >
                                            <div className="sq-r-btn-inner">
                                                <div className="review-checklist-copy">
                                                    <div className="review-checklist-copy-info">
                                                        <RacecheckAIIcon />
                                                        <span className="text">
                                                            Smart Questions report
                                                        </span>
                                                    </div>
                                                    <span className="arrow">
                                                        <ChevronRight />
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    }

                                    {(event.remaining_tasks > 0 || event.smart_questions > 0) &&
                                        <div className="review-rc-divider" />
                                    }

                                    {ratingBreakdownData &&
                                        <RatingBreakdown
                                            ratingBreakdownData={ratingBreakdownData}
                                            year={selectedYear}
                                            count={reviewCount}
                                        />
                                    }
                                    {/* <div className="review-count-container">
                                        <p className="review-count-title">
                                            {selectedYear !== 'all'
                                                ? selectedYear
                                                : 'All time'}{' '}
                                                    review count
                                        </p>
                                        <p className="review-count-number">
                                            {reviewCount}
                                            <span className="review-count-copy"> reviews</span>
                                        </p>
                                    </div> */}
                                    {/* {ratingBreakdownData &&
                                        <RatingBreakdown
                                            ratingBreakdownData={ratingBreakdownData}
                                            year={selectedYear}
                                        />
                                    } */}
                                    {awardsLoaded &&
                                    <>
                                        {awardData && !(
                                            awardData.year <
                                                    awardData.active_award_year
                                        ) && <AwardProgress awardData={awardData} />}
                                        {awardData?.all_awards?.length > 0 && (
                                            <TrophyCabinet
                                                awardData={awardData}
                                                year={selectedYear}
                                            />
                                        )}
                                    </>
                                    }
                                    <ReviewAnalytics event={event} />
                                    {npsLoaded && npsData && <NPSReport npsData={npsData} />}

                                </>
                            )}
                            {/* <div className="reviews-toolkit-cta">
                                <p className="toolkits-cta-title">
                                            Use reviews to your advantage
                                </p>
                                <p className="toolkits-cta-copy">
                                            Our review toolkits give you the resources you need to
                                            market your event like a pro.
                                </p>
                                <Link to="/resources/toolkits">
                                    <p className="toolkits-cta-link">View toolkits</p>
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    
    useEffect(() => {
        if (params.id) {
            setReviewsLoaded(false);
            getReviewFilters();
            getYearsList();
        }
        else setReviewsLoaded(true);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setReviewList([]);
        // if (location?.search) {
        //     const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        //     if (queryParams?.selectedYear) {
        //         setSelectedYear(queryParams?.selectedYear);
        //     }
        //     if (queryParams?.selectedSorting) {
        //         setSelectedSorting(queryParams?.selectedSorting);
        //     }
        //     if (queryParams?.unreplied) {
        //         setIsUnreplied(queryParams?.unreplied === 'true');
        //     }
        //     if (queryParams['bookmark-only']) {
        //         setIsBookmarked(queryParams['bookmark-only'] === 'true');
        //     }
        // }

        // if (selectedSorting) {
        setCurrentPage(1);
        getEventReviews(1);
        // }
        // eslint-disable-next-line
    }, [selectedYear, selectedSorting, isBookmarked, isUnreplied]);

    useEffect(() => {
        getRatingBreakdown();
        getAwardData();
        getNPSData();
        // eslint-disable-next-line
    },[selectedYear]);

    return (
        <div>
            <div className="review-feed-container">
                {reviewsLoaded && ratingBreakdownLoaded ? (
                    <div>
                        <div className="horizontal-dropdowns">
                            <div className="horizontal-select-group">
                                <label htmlFor="current-year" className="horiztonal-select-label">
                                    Year:
                                </label>
                                <select
                                    id="current-year"
                                    className="rounded-dropdown"
                                    name="selectedYear"
                                    onChange={handleChange}
                                    value={selectedYear}
                                    required
                                >
                                    <option value="all">All</option>
                                    {reviewedYears && createYearSelect()}
                                </select>
                            </div>
                            <div className="horizontal-select-group">
                                <label htmlFor="current-sorting" className="horiztonal-select-label">
                                    Sort:
                                </label>
                                <select
                                    id="current-sorting"
                                    className="rounded-dropdown"
                                    name="selectedSorting"
                                    onChange={handleChange}
                                    value={selectedSorting}
                                    required
                                >
                                    {createSortingSelect()}
                                </select>
                            </div>
                            <div className="horizontal-select-group bookmarked-review-checkbox">
                                <label htmlFor="bookmark-only" className="horiztonal-select-label">
                                    Filters:
                                </label>
                                <Checkbox
                                    id="bookmark-only"
                                    label="Bookmarked"
                                    onCheckboxChange={() => {
                                        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      
                                        history.push({
                                            search: qs.stringify({
                                                ...queryParams,
                                                'bookmark-only': !isBookmarked
                                            })
                                        });
                                        handleBookmarkedChange();
                                    }}
                                    value={isBookmarked}
                                    isSelected={isBookmarked}
                                />
                                <Checkbox
                                    id="unreplied"
                                    label="Unreplied"
                                    onCheckboxChange={() => {
                                        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
      
                                        history.push({
                                            search: qs.stringify({
                                                ...queryParams,
                                                unreplied: !isUnreplied
                                            })
                                        });
                                        handleUnrepliedChange();
                                    }}
                                    value={isUnreplied}
                                    isSelected={isUnreplied}
                                />
                            </div>
                        </div>
                        {feed()}
                    </div>
                ) : (
                    <div className="no-data-container">
                        <Loader />
                    </div>
                )}
            </div>
        </div>
    );
};

ReviewFeed.propTypes = {
    eventsList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['all'])]).isRequired,
            title: PropTypes.string,
            qr_code: PropTypes.string,
            reviewed_years: PropTypes.arrayOf(PropTypes.number),
            reviews: PropTypes.number
        })
    ),
    event: PropTypes.shape({
        remaining_tasks: PropTypes.number,
        smart_questions: PropTypes.number,
    }),
    currentSelections: PropTypes.shape({
        selectedEvent: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['all'])]).isRequired,
            reviewed_years: PropTypes.arrayOf(PropTypes.number),
            title: PropTypes.string,
        }),
        setSelectedEvent: PropTypes.func,
        setSelectedYear: PropTypes.func,
        setSelectedSorting: PropTypes.func,
        selectedYear: PropTypes.string,
        selectedSorting: PropTypes.oneOf(['most-recent-first', 'highest-rated-first', 'lowest-rated-first']),
    }).isRequired,

    reviewFilters: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['all'])]).isRequired,
            display: PropTypes.string,
            parameter_value: PropTypes.string,
        })
    ),

    selectedOrganisation: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['all'])]).isRequired,
    }).isRequired,

    setLoaded: PropTypes.func,
    remainingReplies: PropTypes.number,
    reviewFeedLoaded: PropTypes.bool,
    setRepliesRemaining: PropTypes.func,
};

ReviewFeed.defaultProps = {
    eventsList: [
        {
            id: 0,
            reviewed_years: [],
            reviews: 0,
        }
    ],

    currentSelections: {
        selectedEvent: {
            id: 0,
            reviewed_years: [],
            title: '',
        },
        setSelectedEvent: () => {},
        setSelectedSorting: () => {},
        setSelectedYear: () => {},
        selectedYear: '',
        selectedSorting: 'most-recent-first',
    },

    reviewFilters: [
        {
            id: 0,
            display: '',
            parameter_value: '',
        }
    ],
};

export default ReviewFeed;
