import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import './TeamSection.css';
import uniqBy from 'lodash/uniqBy';
import AuditCard from './AuditCard';
import TeamMemberCard from './TeamMemberCard';
import ButtonTeamMemberCard from './ButtonTeamMemberCard';
import RequestToJoinMemberCard from './RequestToJoinMemberCard';
import Loader from '../helpers/loader';
import NoDataPlaceholder from '../helpers/noDataPlaceholder';
import AppContext from '../app/AppContext';
import { ReactComponent as FilterIcon } from '../../images/icons/filters.svg';

class TeamMembers extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            eventsList: [],
            teamMemberList: [],
            pendingJoinRequestsList: [],
            teamMembersFilterList: [],
            teamAuditList: [],
            tagsList: [],
            currentEvent: 'all',
            currentEventId: 'all',
            currentTeamMemberId: 'all',
            currentTagId: 'all',
            auditFeedLoaded: false,
            noAuditData: false,
            currentPage: 1,
            teamMembersLoaded: false,
            auditFiltersOpen: true,
            pendingRequest: false,
        };
    }

    componentDidMount () {
        document.getElementsByClassName('audit-feed-cards')[0].addEventListener('scroll', this.infiniteScroll);
        this.getEvents();
        this.getTeamMembers();
        this.getTags();
        this.getPendingJoinRequests();
        this.getTeamAudit(this.state.currentPage);
    }

    componentDidUpdate (prevProps) {
        if (this.props.selectedOrganisation?.id !== prevProps.selectedOrganisation?.id) {
            this.getEvents();
            this.getTeamMembers();
            this.getPendingJoinRequests();
            this.getTeamAudit(this.state.currentPage, true);
        }
    }

    infiniteScroll = () => {
        const obj = document.getElementsByClassName('audit-feed-cards')[0];
        if (obj.scrollTop >= obj.scrollHeight - obj.offsetHeight) {
            if (this.state.hasMoreAudit && !this.state.pendingRequest) {
                let newPage = this.state.currentPage;
                newPage++;
                this.setState({
                    currentPage: newPage,
                    pendingRequest: true,
                });
                this.getTeamAudit(newPage);
            }
        }
    };

    getEvents = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = this.props.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/titles/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                this.setState({
                    eventsList: res.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    getTeamMembers = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = this.props.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/team_members/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        this.setState({ teamMembersLoaded: false });
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                this.setState({
                    teamMemberList: res.data,
                    teamMemberCount: res.data.length,
                    teamMembersLoaded: true,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    getPendingJoinRequests = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = this.props.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/requests_to_join/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                this.setState({
                    pendingJoinRequestsList: res.data.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    updatePendingJoinRequests = (id) => {
        this.setState({
            pendingJoinRequestsList: this.state.pendingJoinRequestsList.filter((joinRequest) => joinRequest.id !== id)
        });
    };

    getTags = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/audit_tags/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                this.setState({
                    tagsList: res.data,
                });
            })
            .catch(error => {
                console.log(error);
            });
    };

    createParamString = (paramString, key, value) => {
        let firstChar = '&';
        if (paramString === '') {
            firstChar = '?';
        }
        // eslint-disable-next-line
        paramString += firstChar + key + '=' + value;
        return paramString;
    };

    getTeamAudit = (pageNumber, changeOrganisation) => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        let paramString = '';
        if (this.state.currentEventId !== 'all') {
            paramString = this.createParamString(paramString, 'event', this.state.currentEventId);
        }
        if (this.state.currentTeamMemberId !== 'all') {
            paramString = this.createParamString(paramString, 'team_member', this.state.currentTeamMemberId);
        }
        if (this.state.currentTagId !== 'all') {
            paramString = this.createParamString(paramString, 'tag', this.state.currentTagId);
        }
        if (pageNumber > 1) {
            paramString = this.createParamString(paramString, 'page', pageNumber);
        }
        const { REACT_APP_API_URL } = process.env;
        const { id } = this.props.selectedOrganisation;

        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/team_audit/${paramString}`;
        this.setState({ auditFeedLoaded: false });
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                let hasMoreAudit = false;
                if (res.data.next) {
                    hasMoreAudit = true;
                }
                // Initial load: Add more (old) members to the dropdown if necessary
                const extra_members = this.state.teamMembersFilterList;
                if (pageNumber === 1 && res.data.filters.team_members.length > this.state.teamMemberList.length) {
                    for (const mem of res.data.filters.team_members) {
                        extra_members.push({
                            id: mem.id,
                            member: {
                                name: `${mem.first_name} ${mem.last_name}`,
                                avatar: '',
                            },
                        });
                    }
                }
                if (!changeOrganisation) {
                    this.setState({
                        teamAuditList: [...this.state.teamAuditList, ...res.data.results],
                        teamMembersFilterList: uniqBy([...this.state.teamMemberList, ...extra_members], 'id'),
                        auditFeedLoaded: true,
                        auditCount: res.data.count,
                        hasMoreAudit,
                        pendingRequest: false,
                    });
                }
                else {
                    this.setState({
                        teamAuditList: [...res.data.results],
                        teamMembersFilterList: uniqBy([...this.state.teamMemberList], 'id'),
                        auditFeedLoaded: true,
                        auditCount: res.data.count,
                        hasMoreAudit,
                        pendingRequest: false,
                    });
                }
                
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    refreshTeamAudit = () => {
        this.setState(
            {
                auditFeedLoaded: false,
                currentPage: 1,
                teamAuditList: [],
            },
            () => {
                this.getTeamAudit(this.state.currentPage);
            }
        );
    };

    handleChange = e => {
        if (e.target.name === 'currentEventId') {
            const newCurrentEvent = this.state.eventsList.filter(obj => obj.id === parseInt(e.target.value))[0];
            this.setState(
                {
                    [e.target.name]: e.target.value,
                    currentEvent: newCurrentEvent,
                    auditFeedLoaded: false,
                    currentPage: 1,
                    teamAuditList: [],
                },
                () => {
                    this.getTeamAudit();
                }
            );
        }
        else {
            this.setState(
                {
                    [e.target.name]: e.target.value,
                    auditFeedLoaded: false,
                    currentPage: 1,
                    teamAuditList: [],
                },
                () => {
                    this.getTeamAudit();
                }
            );
        }
    };

    toggleAuditFilters = () => {
        const { auditFiltersOpen } = this.state;
        auditFiltersOpen
            ? this.setState({
                auditFiltersOpen: false,
            })
            : this.setState({
                auditFiltersOpen: true,
            });
    };

    createEventsSelect = () =>
        this.state.eventsList.map(option => (
            <option key={option.id} value={option.id}>
                {option.title}
            </option>
        ));

    createTeamMembersSelect = () =>
        this.state.teamMembersFilterList.map(option => (
            <option key={option.id} value={option.id}>
                {option.member.name}
            </option>
        ));

    createTagsSelect = () =>
        this.state.tagsList.map(option => (
            <option key={option.id} value={option.id}>
                {option.name}
            </option>
        ));

    replyRequestToJoin = ( action, requestToJoinID ) => {
        const { REACT_APP_API_URL } = process.env;
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        // eslint-disable-next-line
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${this.props.selectedOrganisation.id}/requests_to_join/${requestToJoinID}/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        const status = (action === 'accept') ? 'A' : 'D';
        axios
            .patch(apiCallURL, { status }, { headers })
            .then(res => {
                this.updatePendingJoinRequests(requestToJoinID);
                this.refreshTeamAudit();
                this.getTeamMembers();
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    render () {
        const { auditFiltersOpen, auditFeedLoaded, auditCount, teamMemberCount, teamMembersLoaded, eventsList } = this.state;
        const pendingJoinRequestsListView = this.state.pendingJoinRequestsList.map(requestToJoin => (
            <RequestToJoinMemberCard
                key={requestToJoin.id}
                selectedOrganisation={this.props.selectedOrganisation}
                id={requestToJoin.id}
                name={requestToJoin.profile.name}
                avatar={requestToJoin.profile.avatar}
                email={requestToJoin.profile.email}
                requestedAt={requestToJoin.requested_at}
                replyRequestToJoin={this.replyRequestToJoin}
            />
        ));
        const teamMemberListView = this.state.teamMemberList.map(member => (
            <TeamMemberCard
                key={member.id}
                memberID={member.id}
                name={member.member.name}
                avatar={member.member.avatar}
                role={member.job_title}
                email={member.member.email}
                selfEmail={this.context?.user?.email}
                selfIsAdmin={this.context?.selectedOrganisation?.is_admin}
                isAdmin={member.is_admin}
                allowedEvents={member.allowed_events}
                eventsList={eventsList}
                refreshTeamAudit={this.refreshTeamAudit}
                refreshTeamMembers={this.getTeamMembers}
            />
        ));
        const teamAuditListView = this.state.teamAuditList.map(log => <AuditCard key={log.id} auditLog={log} />);
        const teamMembersListMobile = this.state.teamMemberList.map(member => (
            <div key={member.id} className="tmm-member">
                <img src={member.member.avatar} alt="team members icon" />
                <p className="tmm-name">{member.member.name}</p>
            </div>
        ));

        return (
            <div className="team-container">
                <div className="team-members-mobile">
                    {pendingJoinRequestsListView}
                    <ButtonTeamMemberCard refreshTeamAudit={this.refreshTeamAudit} />
                    {teamMembersListMobile}
                </div>
                <div className="team-audit">
                    <h2 className="team-members-title">
                        Team audit{' '}
                        {auditFeedLoaded && (
                            <span className="audit-count">
                                {auditCount} log
                                {auditCount !== 1 && <span>s</span>}
                            </span>
                        )}
                    </h2>
                    <div className="team-audit-filter-icon" onClick={() => this.toggleAuditFilters()}>
                        <FilterIcon />
                    </div>
                    {auditFiltersOpen && (
                        <div className="horizontal-dropdowns">
                            <div className="horizontal-select-group">
                                <label htmlFor="current-team-member" className="horiztonal-select-label">
                                    Team member:
                                </label>
                                <select
                                    id="current-team-member"
                                    className="rounded-dropdown"
                                    name="currentTeamMemberId"
                                    onChange={this.handleChange}
                                    value={this.state.currentTeamMemberId}
                                    required
                                >
                                    <option value="all">All team members</option>
                                    {this.createTeamMembersSelect()}
                                </select>
                            </div>
                            <div className="horizontal-select-group">
                                <label htmlFor="current-event" className="horiztonal-select-label">
                                    Event:
                                </label>
                                <select
                                    id="current-event"
                                    className="rounded-dropdown event-name-rounded-dropdown"
                                    name="currentEventId"
                                    onChange={this.handleChange}
                                    value={this.state.currentEventId}
                                    required
                                >
                                    <option value="all">All events</option>
                                    {this.createEventsSelect()}
                                </select>
                            </div>
                            <div className="horizontal-select-group">
                                <label htmlFor="current-tag" className="horiztonal-select-label">
                                    Tag:
                                </label>
                                <select
                                    id="current-tag"
                                    className="rounded-dropdown"
                                    name="currentTagId"
                                    onChange={this.handleChange}
                                    value={this.state.currentTagId}
                                    required
                                >
                                    <option value="all">All tags</option>
                                    {this.createTagsSelect()}
                                </select>
                            </div>
                        </div>
                    )}
                    <div className="audit-feed">
                        {/* <div className="audit-feed-titles">
                            <p>Member</p>
                            <p>Action</p>
                            <p>Tag</p>
                            <p>Timestamp</p>
                        </div> */}
                        <div className="audit-feed-cards">
                            {auditFeedLoaded ? (
                                <div>
                                    {this.state.teamAuditList.length > 0 ? (
                                        <div>{teamAuditListView}</div>
                                    ) : (
                                        <NoDataPlaceholder />
                                    )}
                                </div>
                            ) : (
                                <div className="section-loading-container">
                                    <Loader />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="team-cards">
                    <div className="team-member-cards-container">
                        {this.state.pendingJoinRequestsList.length > 0 &&
                        <>
                            <h2 className="team-members-secondary-title">
                                Join requests {this.state.pendingJoinRequestsList.length && 
                                    <span className="audit-count">
                                        {this.state.pendingJoinRequestsList.length}&nbsp;
                                        request{this.state.pendingJoinRequestsList.length > 1 && 's'}
                                    </span>}
                            </h2>
                            <div className="team-member-cards">
                                {pendingJoinRequestsListView}
                            </div>
                        </>
                        }
                        <h2 className="team-members-secondary-title">
                            Team {teamMembersLoaded && 
                            <span className="audit-count">
                                {teamMemberCount} member{teamMemberCount > 1 && 's'}
                            </span>}
                        </h2>
                        <div className="team-member-cards">
                            <ButtonTeamMemberCard refreshTeamAudit={this.refreshTeamAudit} eventsList={eventsList} />
                            {teamMemberListView}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

TeamMembers.propTypes = {
    selectedOrganisation: PropTypes.shape({
        id: PropTypes.number
    }).isRequired,
};

export default TeamMembers;
