import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import axios from "axios";
import AwardsModal from '../resources/AwardsModal';

class TrophyCabinet extends Component {
    constructor (props) {
        super(props);

        this.state = {
            allEventAwards: this.props.awardData.all_awards.reverse(),
            currentYearEventAwards: this.props.awardData.year_awards.reverse(),
            awardsReady: true,
            isShowDownloadAwardAssert: false
        };
    }

    handleOpenDownloadAwardAssert = () => {
        this.toggleIsShowDownloadAwardAssert(true);
    };

    toggleIsShowDownloadAwardAssert = (show) => {
        document.querySelector('.section-top-container').style.zIndex = show ? 0 : 2000;
        this.setState({
            isShowDownloadAwardAssert: show
        });
    }
    render () {
        const { awardsReady, allEventAwards, currentYearEventAwards, isShowDownloadAwardAssert } = this.state;
        let awardList = '';
        if (this.props.year === 'all' || this.props.year === '') {
            awardList = allEventAwards.map(award => (
                <div className="individual-award" key={award.title}>
                    <img src={award.image} className="trophy-cabinet-award" alt="award-badge" />
                    <p className="trophy-cabinet-award-name">{award.title}</p>
                </div>
            ));
        }
        else {
            awardList = currentYearEventAwards.map(award => (
                <div className="individual-award" key={award.title}>
                    <img src={award.image} className="trophy-cabinet-award" alt="award-badge" />
                    <p className="trophy-cabinet-award-name">{award.title}</p>
                </div>
            ));
        }
        if (awardsReady && awardList.length === 0) {
            return null;
        }
        return (
            <div className="trophy-cabinet-container">
                <p className="trophy-cabinet-title">
                    {!this.props.year || this.props.year === 'all' ? 'All Time' : this.props.year} Trophy Cabinet
                </p>
                {awardsReady ? (
                    <div>
                        {awardList.length > 0 ? (
                            <div className="award-containers">{awardList}</div>
                        ) : (
                            <div>
                                <p>You didn&apos;t qualify for any awards in {this.props.year}.</p>
                                <p>
                                    Check out the <span className="trophy-toolkits-link">Racecheck Toolkits</span> to
                                    ensure you have the best chance in the future!
                                </p>
                            </div>
                        )}
                    </div>
                ) : (
                    <p>Loading</p>
                )}
                <button 
                    onClick={() => this.toggleIsShowDownloadAwardAssert(true)} 
                    className="btn primary-btn review-side-btn"
                >Download award badge{allEventAwards.length > 1 ? 's' : ''}
                </button>
                {isShowDownloadAwardAssert && (
                    <AwardsModal toggleModal={this.toggleIsShowDownloadAwardAssert} />
                )}
            </div>
        );
    }
}

TrophyCabinet.propTypes = {
    awardData: PropTypes.shape({
        all_awards: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired,
            })
        ).isRequired,
        year_awards: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                image: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['all'])]).isRequired,
};

TrophyCabinet.defaultProps = {
    awardData: {
        all_awards: [
            {
                title: '',
                image: '',
            }
        ],
        year_awards: [
            {
                title: '',
                image: '',
            }
        ],
    },
    year: 'all',
};

export default TrophyCabinet;
