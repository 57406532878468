import React from 'react';
import Loader from '../helpers/loader';
    
class HubspotDemoForm extends React.Component {
    componentDidMount () {
        const script = document.createElement('script');
        script.src = 'https://js-eu1.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
  
        script.addEventListener('load', () => {
        // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    target: '#hubspotForm',
                    ...this.props,
                });
            }
        });
    }
  
    render () {
        return (
            <div>
                <div id="hubspotForm">
                    <Loader />
                </div>
            </div>
        );
    }
}
  
export default HubspotDemoForm;