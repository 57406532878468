import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Loader from '../helpers/loader';
import AppContext from '../app/AppContext';
import PropTypes from 'prop-types';

const INVITE_STATUSES = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    INVALID: 'INVALID',
};
const API_BASE_URL = `${process.env.REACT_APP_API_URL}/api/react`;

const TeamInvite = ({ history, match }) => {
    const context = useContext(AppContext);
    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(localStorage.getItem('token'));
        return () => setToken();
    }, []);

    const inviteCode = sessionStorage.getItem('invite_code')
        ? sessionStorage.getItem('invite_code')
        : match?.params?.inviteCode;

    const [userData, setUserData] = useState({
        organisation: '',
        invitedBy: '',
        inviteeFirstName: '',
        hasAccount: '',
    });

    const [inviteStatus, setInviteStatus] = useState({});

    useEffect(() => {
        if (inviteCode) {
            const apiCallURL = `${API_BASE_URL}/get_invitation/${inviteCode}/`;
            const headers = token && {
                Authorization: `Token ${token}`,
            };

            axios
                .get(apiCallURL, { headers })
                .then((res) => {
                    setUserData({
                        organisation: res.data.organisation,
                        invitedBy: res.data.invited_by.name,
                        inviteeFirstName: res.data.invitee_first_name,
                        hasAccount: res.data.has_profile
                    });
                    
                    if (res.status === 202) return setInviteStatus({ status: INVITE_STATUSES.ACCEPTED }); 
                    setInviteStatus({ status: INVITE_STATUSES.PENDING });
                })
                .catch(() => {
                    setInviteStatus({ status: INVITE_STATUSES.INVALID });
                    sessionStorage.removeItem('invite_code');
                });
        }
    }, [inviteCode, token]);
    
    const acceptInviteLoggedOut = () => {
        sessionStorage.setItem('invite_code', inviteCode);
        context.setOrgMembershipStatus('member-of-org');
        if (!userData.hasAccount) {
            context.setRegistering(true);
            return history.push('/signup');
        } 
        history.push('/login');
    };

    const acceptInviteLoggedIn = () => {
        const apiCallURL = `${API_BASE_URL}/accept_invitation/${inviteCode}/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .post(apiCallURL, null, { headers })
            .then(({ data }) => {
                setInviteStatus({ status: INVITE_STATUSES.ACCEPTED });
                context.setOrgMembershipStatus('member-of-org');
                context.setSelectedOrganisation(data.organisation);
                context.refreshUserDetails();
                sessionStorage.removeItem('invite_code');
            })
            .catch(() => {
                setInviteStatus({ status: INVITE_STATUSES.INVALID });
                sessionStorage.removeItem('invite_code');
            });
    };

    const handleAcceptInvite = () => {
        if (!token) return acceptInviteLoggedOut();
        return acceptInviteLoggedIn();
    };

    const handleContinue = () => {
        history.push('/events/events');

        context.setOrgMembershipStatus('member-of-org');
        context.setSelectedOrganisation(userData.organisation);
        context.refreshUserDetails();
        sessionStorage.removeItem('invite_code');
    };

    if (!inviteStatus.status) return (
        <div className="fullpage-loading-container">
            <Loader />
            <p>Fetching your invite...</p>
        </div>
    );

    const renderForm = () => {
        const { ACCEPTED, PENDING, INVALID } = INVITE_STATUSES;

        if (inviteStatus.status === PENDING) {
            if (token) return (
                <div className="log_in_container">
                    <h2>Invitation to join {userData.organisation.name}</h2>
                    <div>
                        <p className="organisation-list-copy">
                            {userData.invitedBy}
                            &apos;s invited you to join{' '}
                            <span className="invite-bold">{userData.organisation.name}</span>. Accept the
                            invitation to start managing events, gathering reviews and much more.
                        </p>
                        <div className="log-in-form-submit">
                            <button
                                id="log-in-submit"
                                className="log-in-btn btn margin-top-btn"
                                onClick={handleAcceptInvite}
                            >
                                Accept invitation
                            </button>
                        </div>
                        <p className="sign-up-disclaimer invite-disclaimer">
                            Not meant to be here? Please{' '}
                            <a className="invite-link" rel="noopener noreferrer" href="/">
                                click here
                            </a>
                            .
                        </p>
                    </div>
                </div>
            );

            return (
                <div className="log_in_container">
                    <h2>Invitation to join {userData.organisation.name}</h2>
                    <div>
                        <p className="organisation-list-copy">
                            {userData.invitedBy} has invited you to join{' '}
                            <span className="invite-bold">{userData.organisation.name}</span>.{' '}
                            {userData.hasAccount ? 'Log in' : 'Sign up'} to accept the invite and start managing
                            events, gathering reviews and much more.
                        </p>
                        <div className="log-in-form-submit">
                            <button
                                id="log-in-submit"
                                className="log-in-btn btn margin-top-btn"
                                onClick={handleAcceptInvite}
                            >
                                {' '}
                                {userData.hasAccount ? 'Log in' : 'Sign up'} now
                            </button>
                        </div>
                        <p className="sign-up-disclaimer invite-disclaimer">
                            Not meant to be here? Please{' '}
                            <a className="invite-link" rel="noopener noreferrer" href="/">
                                click here
                            </a>
                            .
                        </p>
                    </div>
                </div>
            );
        }

        if (inviteStatus.status === ACCEPTED) return (
            <div className="log_in_container">
                <h2>
                    Invite accepted!{' '}
                    <span role="img" aria-label="wave">
                        🎉
                    </span>
                </h2>
                <div>
                    <p className="organisation-list-copy">
                        You&apos;ve accepted {userData.invitedBy}
                        &apos;s invite to join{' '}
                        <span className="invite-bold">{userData.organisation.name}</span>. Visit the
                        Dashboard to get started.
                    </p>
                    <div className="log-in-form-submit">
                        <button
                            id="log-in-submit"
                            className="log-in-btn btn margin-top-btn"
                            onClick={handleContinue}
                        >
                            Continue to dashboard
                        </button>
                    </div>
                    <p className="sign-up-disclaimer invite-disclaimer">
                        Not meant to be here? Please{' '}
                        <a className="invite-link" rel="noopener noreferrer" href="/">
                            click here
                        </a>
                        .
                    </p>
                </div>
            </div>
        );

        if (inviteStatus.status === INVALID) return (
            <div className="log_in_container">
                <h2>This invitation has expired</h2>
                <div>
                    <p className="organisation-list-copy">
                        If you were unable to accept this invitation in time please contact your
                        colleague <span className="invite-bold">{userData.invitedBy}</span> to request a new
                        one.
                    </p>
                    {token && (
                        <p className="sign-up-disclaimer invite-disclaimer">
                            <a
                                className="invite-link"
                                rel="noopener noreferrer"
                                href={`${process.env.REACT_APP_BASE_URL}/events/events/`}
                            >
                            Return to dashboard
                            </a>
                        </p>
                    )}
                    {!token && (
                        <p className="sign-up-disclaimer invite-disclaimer">
                        Trying to log in?
                            <br />
                            <a className="invite-link" rel="noopener noreferrer" href="/">
                            Click here
                            </a>
                        .
                        </p>)}
                </div>
            </div>
        );
    };

    return (
        <div className="team-invite">
            <img
                src={require('../../images/racecheck_logo_black.svg')}
                width="250px"
                alt="racecheck-logo"
            />
            {renderForm()}
        </div>
    );
};

TeamInvite.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,

    match: PropTypes.shape({
        params: PropTypes.shape({
            inviteCode: PropTypes.string,
        }),
    }).isRequired,
};

export default withRouter(TeamInvite);