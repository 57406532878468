import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { privateRoutes, publicRoutes } from '../../routes/routes';

const NotFound = () => {
    const { pathname } = useLocation();
    const { push } = useHistory();

    const routesList = useMemo(() => [...Object.values(privateRoutes), ...Object.values(publicRoutes)], []);
    
    if (
        pathname !== '/404' && 
        !routesList.some(item => {
            if (pathname === '/') return true;
            if (!item.slice(1).length) return false;
            return pathname.slice(1).includes(item.slice(1));
        })
    ) {
        push('/404');
        return null;
    }
    if (pathname === '/404')
        return (
            <div className="logged_out_container">
                <a href="/">
                    <img
                        className="logged_out_logo"
                        src={require('../../images/racecheck_logo_black.svg')}
                        width="250px"
                        alt="racecheck-logo"
                    />
                </a>
                <div className="log_in_container">
                    <h2>Page not found</h2>
                    <div>
                        <img className="404-img" src={require('../../images/404-img.png')} width="350px" alt="404-img" />
                        <p className="rp-copy">
                            The page you were looking for could not be located or does not exist.
                        </p>
                        <a className="log-in-btn btn margin-top-btn" rel="noopener noreferrer" href="/">
                            Return to your dashboard
                        </a>
                    </div>
                </div>
            </div>
        );

    return null;
};

export default NotFound;