import React, { Component } from 'react';
import PropTypes from 'prop-types';

class RacecheckWidget extends Component {
    constructor (props) {
        super(props);

        this.state = {};
    }

    componentDidMount () {
        const widgetScript = document.createElement('script');
        document.body.appendChild(widgetScript);
        let rcwScript = '';

        /*eslint-disable*/
        if (this.props.widgetEvents.length > 1) {
            rcwScript = `
            (function(w, d, s, o, f, js, fjs) {
            w['RacecheckWidget']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
            js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
            js.id = o;js.src = f;js.async = 1;fjs.parentNode.insertBefore(js, fjs);
            }(window, document, 'script', 'rcw', '${process.env.REACT_APP_API_URL}/static/js/widget.js'));
            rcw('init', {organisation: '${this.props.widgetOrganisationSlug}', tracking: false, demo: true, position: '${this.props.parameters.widgetPositionValue}', structuredData: ${this.props.parameters.widgetStructuredDataValue}, reviewSorting: '${this.props.parameters.widgetReviewSortingValue}', autoplay: ${this.props.parameters.widgetAutoplayValue}});`;
        }
        else {
            rcwScript = `
            (function(w, d, s, o, f, js, fjs) {
            w['RacecheckWidget']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
            js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
            js.id = o;js.src = f;js.async = 1;fjs.parentNode.insertBefore(js, fjs);
            }(window, document, 'script', 'rcw', '${process.env.REACT_APP_API_URL}/static/js/widget.js'));
            rcw('init', {organisation: '${this.props.widgetOrganisationSlug}', race: '${this.props.widgetEvents[0].slug}', tracking: false, demo: true, position: '${this.props.parameters.widgetPositionValue}', structuredData: ${this.props.parameters.widgetStructuredDataValue}, reviewSorting: '${this.props.parameters.widgetReviewSortingValue}'});`;
        }
        {/* eslint-enable*/}
        widgetScript.type = 'text/javascript';
        widgetScript.innerHTML = rcwScript;
        this.checkForWidgetLoad();
    }

    componentWillUnmount () {
        window.rcw.widgetCleanup();
    }

    async checkForWidgetLoad () {
        while (!document.querySelector('#racecheck-widget')) {
            // eslint-disable-next-line
            await new Promise(r => setTimeout(r, 100));
        }
        try {
            document.getElementById('racecheck-widget').style.position = 'absolute';
            document.getElementById('widget-preview-container').appendChild(document.getElementById('racecheck-widget'));
            document.getElementById('racecheck-widget').style.display = 'block';
        }
        // eslint-disable-next-line
        catch {}
    }

    render () {
        return <div id="widget-preview-container" />;
    }
}

RacecheckWidget.propTypes = {
    widgetEvents: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string.isRequired,
        })
    ),
    widgetOrganisationSlug: PropTypes.string.isRequired,
    parameters: PropTypes.shape({
        widgetPositionValue: PropTypes.string.isRequired,
        widgetStructuredDataValue: PropTypes.bool.isRequired,
        widgetReviewSortingValue: PropTypes.string.isRequired,
        widgetAutoplayValue: PropTypes.bool.isRequired,
    }).isRequired,
};

RacecheckWidget.defaultProps = {
    widgetEvents: [
        {
            slug: '',
        }
    ],
    widgetOrganisationSlug: '',
    parameters: {
        widgetPositionValue: '',
        widgetReviewSortingValue: '',
        widgetStructuredDataValue: false,
        widgetAutoplayValue: false,
    },
};

export default RacecheckWidget;
