import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import EventCardsList from './EventCardsList';
import './EventsSection.css';
import AppContext from '../app/AppContext';
import { debounce } from 'lodash';
import { ReactComponent as SearchIcon } from '../../images/icons/search.svg';
import { ReactComponent as AddIcon } from '../../images/icons/add.svg';
import mixpanel from 'mixpanel-browser';

const Header = ({ eventStatus, setEventStatus, returnSearchQuery, handleOpenModal, hasUnreviewed }) => {

    const [searchQuery, setSearchQuery] = useState('');

    const inputRef = useRef();
    const deb = debounce((value) => setSearchQuery(value), 1000);
    
    const handleClick = ({ currentTarget }) => {
        deb.cancel();
        returnSearchQuery('');
        setSearchQuery('');
        inputRef.current.value = '';
        setEventStatus(currentTarget.value);
    };

    const handleChange = ({ currentTarget }) => deb(currentTarget.value);
    
    useEffect(() => {
        if (searchQuery.length && eventStatus !== 'A') setEventStatus('A');
        returnSearchQuery(searchQuery);

        // eslint-disable-next-line
    }, [searchQuery]);

    return (
        <div className="events-feed-header">
            <div className="efh-status-btns">
                <button
                    type="button"
                    value="A"
                    onClick={handleClick}
                    className={eventStatus === 'A' ? 'active' : ''}
                >
                    All
                </button>
                <button
                    type="button"
                    value="U"
                    onClick={hasUnreviewed ? handleClick : null}
                    className={`${eventStatus === 'U' ? 'active' : ''} ${hasUnreviewed ? '' : 'disabled'}`}
                >
                    Unreviewed
                </button>
            </div>
            <div className="efh-search">
                <div className="efh-search-input">
                    <SearchIcon />
                    <input
                        placeholder="Search for an event"
                        onChange={handleChange}
                        ref={inputRef}
                    />
                </div>
            </div>
            <div className="efh-add-btn">
                <button onClick={handleOpenModal}>
                    <AddIcon />
                    Add or claim an event
                </button>
            </div>
        </div>
    );
};

const EventFeed = ({ setSectionError }) => {

    const { selectedOrganisation } = useContext(AppContext);
    const [eventStatus, setEventStatus] = useState('A');    
    const [searchQuery, setSearchQuery] = useState('');
    const [isAddEventModalOpen, toggleIsAddEventModalOpen] = useState(false);   
    const [hasUnreviewed, setHasUnreviewed] = useState(false);

    return (
        <div className="event-cards-list">
            <Header
                eventStatus={eventStatus}
                setEventStatus={setEventStatus}
                returnSearchQuery={setSearchQuery}
                handleOpenModal={() => {
                    toggleIsAddEventModalOpen(true);
                    const mpProps = selectedOrganisation ? {
                        organisation: selectedOrganisation?.name,
                        premium_subscriber: selectedOrganisation?.is_premium_subscriber
                    } : {};
                    mixpanel.track('Dashboard - Add or claim an event click', mpProps);
                }}
                hasUnreviewed={hasUnreviewed}
            />
            <EventCardsList
                setSectionError={setSectionError}
                selectedOrganisation={selectedOrganisation}
                eventStatus={eventStatus}
                searchQuery={searchQuery}
                isAddEventModalOpen={isAddEventModalOpen}
                toggleIsAddEventModalOpen={toggleIsAddEventModalOpen}
                setHasUnreviewed={setHasUnreviewed}
            />
        </div>
    );
};

EventFeed.propTypes = {
    setSectionError: PropTypes.func,
};

Header.propTypes = {
    value: PropTypes.string, 
    setSearchQuery: PropTypes.func,
    setEventStatus: PropTypes.func,
    eventStatus: PropTypes.string,
    returnSearchQuery: PropTypes.func,
    handleOpenModal: PropTypes.func,
    hasUnreviewed: PropTypes.bool,
};

export default EventFeed;
