import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './errorMessage.css';

class errorMessage extends Component {
    constructor (props) {
        super(props);

        this.state = {
            messageHidden: true,
        };
    }

    componentDidMount () {
        setTimeout(() => {
            this.setState(
                {
                    messageHidden: false,
                },
                () => {
                    setTimeout(() => {
                        this.setState(
                            {
                                messageHidden: true,
                            },
                            () => {
                                setTimeout(() => {
                                    this.props.resetErrorMessage();
                                }, 200);
                            }
                        );
                    }, 2700);
                }
            );
        }, 200);
    }

    render () {
        return (
            <div className={`error-message-container ${this.state.messageHidden ? '' : 'display-error-message'}`}>
                <p className="error-message-text">{this.props.message}</p>
            </div>
        );
    }
}

errorMessage.propTypes = {
    message: PropTypes.string,
    resetErrorMessage: PropTypes.func,
};

export default errorMessage;
