import React, { useContext } from 'react';
import AppContext from '../app/AppContext';
import { Link } from 'react-router-dom';
import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import mixpanel from 'mixpanel-browser';

export default function TrialEligibleBanner () {
    const appContext = useContext(AppContext);
    const mpProps = appContext.selectedOrganisation ? {
        organisation: appContext.selectedOrganisation?.name,
        premium_subscriber: appContext.selectedOrganisation?.is_premium_subscriber
    } : {};

    return (
        <Link to={'/subscription/subscription-plan'} className="ft-link"
            onClick={() => {
                /* eslint-disable-next-line */
                  mixpanel.track('Dashboard - Trial banner - Upgrade to Premium click', mpProps);
            }}
        >
            <div className="free-trial-banner">
                <p>
                    <span className="ft-title">
                        <span className="ft-icon">
                            <ExclamationIcon />
                        </span>
                        You have a 30-day free trial<span className="ft-extra-copy">:</span>{' '}
                    </span>
                    <span className="ft-extra-copy">
                        Try out the very best Racecheck features
                        {' '}with zero cost for the first month!{' '}
                    </span>
                    <span className="ft-get-started">
                        Get started →
                    </span>
                </p>
            </div>
        </Link>
    );
}
