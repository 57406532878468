import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import mixpanel from 'mixpanel-browser';

import AppContext from '../app/AppContext';
import GitMerge from '../../images/icons/git-merge.svg';
import ThumbDown from '../../images/icons/thumbs-down.svg';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as InfoIcon } from '../../images/icons/alert.svg';

const ReviewAnalytics = ({ event }) => {
    const appContext = useContext(AppContext);
    const [reviewAnalytics, setReviewAnalytics] = useState(null);
    
    useEffect(() => {
        const { id } = appContext.selectedOrganisation;
        
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${id}/events/${event?.id}/event_review_analytics/`;
        const headers = {
            Authorization: `Token ${appContext.token}`,
        };

        if (event?.id) {
            axios.get(apiCallURL, { headers })
                .then(({ data: { data } }) => {
                    setReviewAnalytics(data?.event_review_analytics );
                });
        }
        // eslint-disable-next-line
    }, [event?.id]);

    return (
        <div className="review-analytics-container">
            <p className="review-analytics-title">
                All Time Review Analytics
            </p>
            <div className="review-analytics-wrap">
                <div className="mr-stat-container">
                    <img src={ThumbDown} alt="dislike" />
                    <div className="mr-copy-container">
                        <p className="mr-stat">{reviewAnalytics?.negative_reviews?.negative_reviews}</p>
                        <p className="mr-stat-title">
                            <span>
                                Negative Reviews <HelpIcon className="mr-download-hide" />
                            </span>
                            <div className="mr-title-tooltip">
                                <span className="mr-tooltip-desc">
                                    A negative review has a rating of 2.99 or less.
                                </span>
                            </div>
                        </p>
                    </div>
                </div>
                <div className="divider" />
                <div className="mr-stat-container">
                    <img src={GitMerge} alt="merge" />
                    <div className="mr-copy-container">
                        <p className="mr-stat">{(reviewAnalytics?.review_replies?.reply_ratio)?.toFixed(2)}%</p>
                        <p className="mr-stat-title">
                            <span>
                                Review Reply Ratio <HelpIcon className="mr-download-hide" />
                            </span>
                            <div className="mr-title-tooltip">
                                <span className="mr-tooltip-desc">
                                    The percentage of reviews which have replies.
                                </span>
                                <div className="mr-tooltip-extra-row">
                                    <InfoIcon className="mr-icon-info" />
                                    <span className="mr-tooltip-desc">
                                    Get this to 100%!
                                    </span>
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
            </div>
            <Link 
                onClick={() => {
                    const mpProps = appContext.selectedOrganisation ? {
                        organisation: appContext.selectedOrganisation?.name,
                        premium_subscriber: appContext.selectedOrganisation?.is_premium_subscriber
                    } : {};
                    mixpanel.track('Dashboard - Review feed - Read analytics reports click', mpProps);
                }}
                to={`/events/${event?.id}/analytics/widget`} 
                className="btn primary-btn modal-form-btn primary-btn-main-cta review-side-btn"
            >Read your analytics report
            </Link>
        </div>
    );

};

export default ReviewAnalytics;

ReviewAnalytics.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
};
