import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppContext from '../app/AppContext';

import { ReactComponent as TickIcon } from '../../images/icons/outline_tick.svg';
import { ReactComponent as TrophyIcon } from '../../images/icons/trophy.svg';
import AwardBadges from '../../images/award-badges-row.png';
import AwardsModal from '../resources/AwardsModal';

const AwardProgress = ({ event, setIsAwardProgressLoading }) => {
    const appContext = useContext(AppContext);
    const [awardData, setAwardData] = useState(null);
    const [isShowDownloadAwardAssert, toggleIsShowDownloadAwardAssert] = useState(false);

    useEffect(() => {
        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = appContext.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${event?.id}/award_progress/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        if (event?.id) {
            axios
                .get(apiCallURL, { headers })
                .then(({ data }) => {
                    setAwardData(data?.results);
                })
                .catch(error => {
                    console.log(error.response);
                });
            setIsAwardProgressLoading(false);

        }
        // eslint-disable-next-line
    }, [event?.id]);

    let reviewBarStyle = { width: `${0}%` };
    let ratingBarStyle = { width: `${0}%` };

    if (awardData) {
        let tempReviewBarValue = awardData?.reviews_progress?.substr(
            0,
            awardData?.reviews_progress.length - 1
        );
        tempReviewBarValue = 100 - tempReviewBarValue;
        if (tempReviewBarValue < 0) {
            reviewBarStyle = {
                width: '0%',
            };
        }
        else {
            reviewBarStyle = {
                width: `${tempReviewBarValue}%`,
            };
        }

        let tempRatingPercentage;
        if (awardData?.average_rating === 0) {
            tempRatingPercentage = 0;
        }
        else {
            tempRatingPercentage = Math.floor(
                (awardData?.average_rating / awardData?.challenge?.rating) * 100
            );
        }
        tempRatingPercentage = 100 - tempRatingPercentage;
        ratingBarStyle = {
            width: `${tempRatingPercentage}%`,
        };
    }

    const handleOpenDownloadAwardAssert = () => {
        toggleIsShowDownloadAwardAssert(true);
    };

    const reviewDiff = awardData?.reviews_threshold - awardData?.reviews_count;
    let reviewCountText = '';
    if (reviewDiff > 0) {
        reviewCountText = `${reviewDiff} more reviews and `;
    }

    if (!event?.id) return null;
    return (
        <div className="mr-section-group-container">
            <div className="mr-section-container mr-section-container-last">
                <h2 className="mr-section-title">Racecheck Award Progress</h2>
                <p className="mr-section-intro">
                    How you’re progressing to earn your next {awardData?.active_award_year} Racecheck Award.
                </p>
                {awardData?.active_award_year !== awardData?.year ?
                    <div className="mr-award-update-year-container">
                        <img src={AwardBadges} alt="award" />
                        <div className="mr-award-yu-content">
                            <TrophyIcon />
                            <p>In order to start qualifying for a Racecheck Award,
                                 you need to renew this event with a {awardData?.active_award_year} date.
                            </p>
                            <div className="mr-award-btns mr-download-hide">
                                <Link className="btn primary-btn" to={'/events/events'}>
                                    Update event date
                                </Link>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="mr-award-container">
                        {awardData?.next_award?.image &&
                            <div className="mr-award-badge-container">
                                <img src={awardData?.next_award?.image} className="mr-award-badge" alt="award" />
                            </div>
                        }
                        <div className="mr-award-info">
                            <div className="mr-award-progress-bars">
                                <div className="mr-award-progress-group">
                                    <div className="progress-bar-label">
                                        <div className="progress-bar-label-group">
                                            <p className="progress-bar-value">
                                                {awardData?.reviews_count}
                                            </p>
                                            <p className="progress-bar-percentage">/ {awardData?.reviews_threshold} reviews</p>
                                            {awardData?.reviews_count >= awardData?.reviews_threshold &&
                                                <TickIcon />
                                            }
                                        </div>
                                    </div>
                                    <div className="progress-bar-container">
                                        <div className="progress-bar-child progress" />
                                        <div className="progress-bar-child shrinker" style={reviewBarStyle} />
                                    </div>
                                </div>
                                <div className="mr-award-progress-group">
                                    <div className="progress-bar-label">
                                        <div className="progress-bar-label-group">
                                            <p className="progress-bar-value">
                                                {awardData?.average_rating}
                                            </p>
                                            <p className="progress-bar-percentage"> {
                                            awardData?.challenge?.rating && `/ ${awardData?.challenge?.rating}`} avg. rating
                                            </p>
                                            {awardData?.average_rating >= awardData?.challenge?.rating &&
                                                <TickIcon />
                                            }
                                        </div>
                                    </div>
                                    <div className="progress-bar-container">
                                        <div className="progress-bar-child progress" />
                                        <div className="progress-bar-child shrinker" style={ratingBarStyle} />
                                    </div>
                                </div>
                            </div>
                            <p>
                                {awardData?.year_awards.length > 0 && awardData?.message} 
                                {awardData?.challenge?.award === 'Top Rated' ? 
                                    ' Maintain your high rating to be in the running for a Racecheck Top Rated Award.' :
                                    ` Get ${reviewCountText} an average rating of at least ${awardData?.challenge?.rating}
                                    in order to unlock the Racecheck ${awardData?.challenge?.award}`}
                            </p>
                            <div className="mr-award-btns mr-download-hide">
                                <Link className="btn primary-btn" to={`/events/${event?.id}/request-reviews`}>
                                    <span className="mr-mobile-hide">Help getting more reviews</span>
                                    <span className="mr-mobile-view">Get reviews</span>
                                </Link>
                                {awardData?.all_awards?.length > 0 && (
                                    <button className="btn secondary-btn secondary-btn-rounded"
                                        onClick={handleOpenDownloadAwardAssert}
                                    >
                                        <span className="mr-mobile-hide">Download all unlocked awards</span>
                                        <span className="mr-mobile-view">Download awards</span>
                                    </button>
                                )}
                                {isShowDownloadAwardAssert && (
                                    <AwardsModal toggleModal={toggleIsShowDownloadAwardAssert} />
                                )}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default AwardProgress;

AwardProgress.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.number
    }),
    setIsAwardProgressLoading: PropTypes.func
};