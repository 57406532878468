import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reportError } from './reportError';
import AppContext from '../app/AppContext';

class sectionError extends Component {
    static contextType = AppContext;

    componentDidMount () {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const errorDetails = {
            user: `${this.context.user.email} - ${this.context.user.id}`,
            organisation: `${this.context.selectedOrganisation.name} - ${this.context.selectedOrganisation.id}`,
            url: window.location.href,
        };
        reportError(token, this.props.message, errorDetails);
    }

    render () {
        return (
            <div className="eb-section-error-container">
                <p className="eb-title">Something went wrong.</p>
                <p className="eb-copy">{this.props.message}</p>
                <p className="eb-copy-last">
                    <span
                        className="eb-link"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Reload this page
                    </span>{' '}
                    to try again. Our team has been notified.
                </p>
            </div>
        );
    }
}

sectionError.propTypes = {
    message: PropTypes.string,
};
export default sectionError;
