import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import classnames from 'classnames';
// import ProfileSidebar from "./ProfileSidebar";
import Modal from './Modal';
import { ReactComponent as SettingsIcon } from '../../images/icons/settings.svg';
import { ReactComponent as LogOutIcon } from '../../images/icons/logout.svg';
import { ReactComponent as SwitchOrgIcon } from '../../images/icons/switch.svg';
import { ReactComponent as MenuIcon } from '../../images/icons/menu.svg';
import { ReactComponent as CaretDownIcon } from '../../images/icons/caret_down.svg';
import { ReactComponent as ArrowInIcon } from '../../images/icons/arrow_in.svg';
import { ReactComponent as ArrowLeftIcon } from '../../images/icons/arrow_left.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as RoadmapIcon } from '../../images/icons/map.svg';
import { ReactComponent as ContactIcon } from '../../images/icons/chat.svg';
// import {ReactComponent as NotificationIcon} from '../../images/icons/notification.svg';
import AppContext from '../app/AppContext';
import axios from 'axios';
import OnboardingFlow from './OnboardingFlow';
import AddEventModal from '../events/AddEventModal';
import ContactUs from './ContactUs';
import { localiseText } from '../../utils';

export default function SectionNav ({ section }) {
    const appContext = useContext(AppContext);
    const history = useHistory();
    const currentSubSection = history.location.pathname.split('/')[3] 
        ? history.location.pathname.split('/')[3] 
        : history.location.pathname.split('/')[2];

    const [activeSubNavItem, updateActiveSubNavItem] = useState(currentSubSection);
    // const [profileOpen, updateProfileSidebarState] = useState(false);
    const [modalOpen, updateModalState] = useState(false);
    const [navMenuOpen, updateNavMenuState] = useState(false);
    const [navMoreOpen, updateNavMoreDropdown] = useState(false);
    const [navMenuPage, updateNavMenuPage] = useState(1);
    const [currentEvent, setCurrentEvent] = useState({});
    const [addEventModalOpen, toggleAddEventModal] = useState(false);
    const [userOnboarded, updateUserOnboarded] = useState(appContext.user.onboarded);

    const { pathname } = history.location;

    const eventId = pathname.split('/')[2];

    const navMenuDropdown = useRef(null);

    useEffect(() => updateActiveSubNavItem(currentSubSection), [currentSubSection]);

    const { 
        token = localStorage.getItem('token') 
    } = appContext;

    useEffect(() => {
        const handler = e => {
            if (navMenuDropdown.current && !navMenuDropdown.current.contains(e.target)) {
                e.preventDefault();
                updateNavMenuState();
                updateNavMenuPage(1);
            }
        };

        document.addEventListener('mousedown', handler);

        return () => {
            document.removeEventListener('mousedown', handler);
        };
    });

    useEffect(() => {
        if (section === 'events' && currentSubSection !== 'events') getEvent();

        // eslint-disable-next-line
    }, [section, currentSubSection]);

    function getEvent () {
        const { REACT_APP_API_URL } = process.env;

        const { selectedOrganisation } = appContext;

        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/events/${eventId}/`;
        const headers = { Authorization: `Token ${token}` };
        axios
            .get(
                apiCallURL, 
                { headers }
            )
            .then(({ data }) => setCurrentEvent(data)) 
            .catch(error => console.log(error.response));
    };

    function handleSubSectionChange (e) {
        if (currentEvent.reviews) updateActiveSubNavItem(e.target.title);
    }

    function renderSectionHeader (section) {
        switch (section) {
            case 'events':
                if (currentSubSection !== 'events') return (
                    <div className="section-header-events">
                        <Link to={'/events/events'}>←</Link>
                        <span>{currentEvent.title}</span>
                    </div>
                );
                return 'Events';
            case 'analytics':
                return 'Analytics';
            case 'team':
                return 'Team';
            case 'subscription':
                return 'Subscription';
            case 'resources':
                return 'Resources';
            default:
                return 'Events';
        }
    }

    function renderSectionNav (section) {
        switch (section) {
            case 'events':
                if (currentSubSection !== 'events') {
                    return (
                        <ul className="section-nav-list">
                            <li className="section-nav-item">
                                <Link
                                    to={`${!currentEvent.reviews ? '#' : `/events/${eventId}/reviews`}`}  
                                    className="section-nav-item-link"
                                    title="reviews"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link 
                                            ${activeSubNavItem === 'reviews' ? 'section-link-selected' : ''}
                                            ${!currentEvent.reviews && 'section-link-disabled'}
                                        `}
                                        title="reviews"
                                    >
                                    Reviews
                                    </span>
                                </Link>
                            </li>
                            <li className="section-nav-item">
                                <Link
                                    to={`/events/${eventId}/widgets`}    
                                    className="section-nav-item-link"
                                    title="widget"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem === 'widgets' ? 'section-link-selected' : ''
                                        }`}
                                        title="widget"
                                    >
                                    Widgets
                                    </span>
                                </Link>
                            </li>
                            <li className="section-nav-item">
                                <Link
                                    to={`${!currentEvent.reviews ? '#' : `/events/${eventId}/analytics/widget`}`}
                                    className="section-nav-item-link"
                                    title="analytics"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link 
                                            ${activeSubNavItem === 'analytics' ? 'section-link-selected' : ''}
                                            ${!currentEvent.reviews && 'section-link-disabled'}
                                        `}
                                        title="analytics"
                                    >
                                        Analytics
                                    </span>
                                </Link>
                            </li>
                            <li className="section-nav-item">
                                <Link
                                    to={`/events/${eventId}/request-reviews`}
                                    className="section-nav-item-link"
                                    title="request-reviews"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem === 'request-reviews' ? 'section-link-selected' : ''}
                                            ${!currentEvent.id && 'section-link-disabled'}
                                            `}
                                        title="request-reviews"
                                    >
                                        Collect reviews
                                    </span>
                                    {currentEvent.remaining_tasks > 0 &&
                                        <div className="section-tab-notification">
                                            <span>{currentEvent.remaining_tasks}</span>
                                        </div>
                                    }
                                </Link>
                            </li>
                        </ul>
                    );
                }
                else {
                    return (
                        <ul className="section-nav-list">
                            <li className="section-nav-item">
                                <Link
                                    to="/events/events"
                                    className="section-nav-item-link"
                                    title="events"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem === 'events' ? 'section-link-selected' : ''
                                        }`}
                                        title="events"
                                    >
                                        Events
                                    </span>
                                </Link>
                            </li>
                        </ul>
                    );
                }
            case 'analytics':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/analytics/events-report"
                                className="section-nav-item-link"
                                title="events-report"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'events-report' ? 'section-link-selected' : ''
                                    }`}
                                    title="events-report"
                                >
                                    Events report
                                </span>
                            </Link>
                        </li>
                    </ul>
                );
            case 'team':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/team/team-members"
                                className="section-nav-item-link"
                                title="team-members"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'team-members' ? 'section-link-selected' : ''
                                    }`}
                                    title="team-members"
                                >
                                    Team members
                                </span>
                            </Link>
                        </li>
                    </ul>
                );
            case 'subscription':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/subscription/subscription-plan"
                                className="section-nav-item-link"
                                title="subscription-plan"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'subscription-plan' ? 'section-link-selected' : ''
                                    }`}
                                    title="subscription-plan"
                                >
                                    Subscription plan
                                </span>
                            </Link>
                        </li>
                    </ul>
                );
            case 'resources':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/resources/toolkits"
                                className="section-nav-item-link"
                                title="toolkits"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'toolkits' ? 'section-link-selected' : ''
                                    }`}
                                    title="toolkits"
                                >
                                    Toolkits & assets
                                </span>
                            </Link>
                        </li>
                        <li className="section-nav-item">
                            <Link
                                to="/resources/articles"
                                className="section-nav-item-link"
                                title="articles"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'articles' ? 'section-link-selected' : ''
                                    }`}
                                    title="articles"
                                >
                                    Articles
                                </span>
                            </Link>
                        </li>
                        <li className="section-nav-item">
                            <Link
                                to="/resources/faq"
                                className="section-nav-item-link"
                                title="faq"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'faq' ? 'section-link-selected' : ''
                                    }`}
                                    title="faq"
                                >
                                    FAQs
                                </span>
                            </Link>
                        </li>
                    </ul>
                );
            default:
                return 'Events';
        }
    }

    function renderMobileSectionNav (section) {
        switch (section) {
            case 'events':
                if (currentSubSection !== 'events') {
                    return (
                        <ul className="section-nav-list">
                            <li className="section-nav-item">
                                <Link
                                    to={`${!currentEvent.reviews ? '#' : `/events/${eventId}/reviews`}`}  
                                    className="section-nav-item-link"
                                    title="reviews"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link 
                                            ${activeSubNavItem === 'reviews' ? 'section-link-selected' : ''}
                                            ${!currentEvent.reviews && 'section-link-disabled'}
                                        `}
                                        title="reviews"
                                    >
                                    Reviews
                                    </span>
                                </Link>
                            </li>
                            <li className="section-nav-item">
                                <div className="section-nav-item-link" title="more" onClick={toggleNavMoreDropdown}>
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem !== 'reviews' ? 'section-link-selected' : ''
                                        }`}
                                        title="more"
                                    >
                                        More...
                                    </span>
                                    {navMoreOpen && (
                                        <div className="section-nav-more-menu">
                                            <Link
                                                to={`/events/${eventId}/widgets`}
                                                className={`section-nav-more-menu-item ${
                                                    activeSubNavItem === 'widgets'
                                                        ? 'section-nav-more-menu-item-active'
                                                        : ''
                                                }`}
                                                title="widgets"
                                                onClick={handleSubSectionChange}
                                            >
                                                <p title="widget">Widgets</p>
                                            </Link>
                                            <Link
                                                to={`${!currentEvent.reviews ? '#' : 'analytics'}`}
                                                className={`section-nav-more-menu-item ${
                                                    activeSubNavItem === 'analytics'
                                                        ? 'section-nav-more-menu-item-active'
                                                        : ''
                                                }`}
                                                title="analytics"
                                                onClick={handleSubSectionChange}
                                            >
                                                <p
                                                    title="analytics"
                                                    className={`${!currentEvent.reviews && 'section-link-disabled'}`}
                                                >
                                                    Analytics
                                                </p>
                                            </Link>
                                            <Link
                                                to={`/events/${eventId}/request-reviews`}
                                                className={`section-nav-more-menu-item ${
                                                    activeSubNavItem === 'request-reviews'
                                                        ? 'section-nav-more-menu-item-active'
                                                        : ''
                                                }`}
                                                title="request-reviews"
                                                onClick={handleSubSectionChange}
                                            >
                                                <p
                                                    title="request-reviews"
                                                    className={`${!currentEvent.id && 'section-link-disabled'}`}
                                                >
                                                    Collect reviews
                                                </p>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </li>
                            {/* <li className="section-nav-item">
                                <Link
                                    to={`/events/${eventId}/widget`}    
                                    className="section-nav-item-link"
                                    title="widget"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem === 'widget' ? 'section-link-selected' : ''
                                        }`}
                                        title="widget"
                                    >
                                    Widget
                                    </span>
                                </Link>
                            </li>
                            <li className="section-nav-item">
                                <Link
                                    to={`${!currentEvent.reviews ? '#' : 'analytics'}`}    
                                    className="section-nav-item-link"
                                    title="analytics"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link 
                                            ${activeSubNavItem === 'analytics' ? 'section-link-selected' : ''}
                                            ${!currentEvent.reviews && 'section-link-disabled'}
                                        `}
                                        title="analytics"
                                    >
                                        Analytics
                                    </span>
                                </Link>
                            </li>
                            <li className="section-nav-item">
                                <Link
                                    to={`/events/${eventId}/request-reviews`}
                                    className="section-nav-item-link"
                                    title="request-reviews"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem === 'request-reviews' ? 'section-link-selected' : ''
                                        }`}
                                        title="request-reviews"
                                    >
                                        Collect reviews
                                    </span>
                                    {currentEvent.remaining_tasks > 0 &&
                                        <div className="section-tab-notification">
                                            <span>{currentEvent.remaining_tasks}</span>
                                        </div>
                                    }
                                </Link>
                            </li> */}
                        </ul>
                    );
                }
                else {
                    return (
                        <ul className="section-nav-list">
                            <li className="section-nav-item">
                                <Link
                                    to="/events/events"
                                    className="section-nav-item-link"
                                    title="events"
                                    onClick={handleSubSectionChange}
                                >
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem === 'events' ? 'section-link-selected' : ''
                                        }`}
                                        title="events"
                                    >
                                        Events
                                    </span>
                                </Link>
                            </li>
                            {/* <li className="section-nav-item">
                                <div className="section-nav-item-link" title="more" onClick={toggleNavMoreDropdown}>
                                    <span
                                        className={`section-link ${
                                            activeSubNavItem !== 'events' ? 'section-link-selected' : ''
                                        }`}
                                        title="more"
                                    >
                                        More...
                                    </span>
                                    {navMoreOpen && (
                                        <div className="section-nav-more-menu">
                                            <Link
                                                to="/events/analytics"
                                                className={`section-nav-more-menu-item ${
                                                    activeSubNavItem === 'analytics'
                                                        ? 'section-nav-more-menu-item-active'
                                                        : ''
                                                }`}
                                                title="analytics"
                                                onClick={handleSubSectionChange}
                                            >
                                                <p title="analytics">Analytics</p>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </li> */}
                        </ul>
                    );
                }
            case 'reviews':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <div className="section-nav-item-link" title="more" onClick={toggleNavMoreDropdown}>
                                <span
                                    className={`section-link ${
                                        activeSubNavItem !== 'review-feed' ? 'section-link-selected' : ''
                                    }`}
                                    title="more"
                                >
                                    More...
                                </span>
                                {/* {navMoreOpen && (
                                    <div className="section-nav-more-menu">
                                        <Link
                                            to="/reviews/bookmarks"
                                            className={`section-nav-more-menu-item ${
                                                activeSubNavItem === 'bookmarks'
                                                    ? 'section-nav-more-menu-item-active'
                                                    : ''
                                            }`}
                                            title="bookmarks"
                                            onClick={handleSubSectionChange}
                                        >
                                            <p title="bookmarks">Bookmarks</p>
                                        </Link>
                                        <Link
                                            to="/reviews/request-reviews"
                                            className={`section-nav-more-menu-item ${
                                                activeSubNavItem === 'request-reviews'
                                                    ? 'section-nav-more-menu-item-active'
                                                    : ''
                                            }`}
                                            title="request-reviews"
                                            onClick={handleSubSectionChange}
                                        >
                                            <p title="request-reviews">Request reviews</p>
                                        </Link>
                                        <Link
                                            to="/reviews/toolkits"
                                            className={`section-nav-more-menu-item ${
                                                activeSubNavItem === 'toolkits'
                                                    ? 'section-nav-more-menu-item-active'
                                                    : ''
                                            }`}
                                            title="toolkits"
                                            onClick={handleSubSectionChange}
                                        >
                                            <p title="toolkits">Toolkits</p>
                                        </Link>
                                        <Link
                                            to="/reviews/sentiment"
                                            className={`section-nav-more-menu-item ${
                                                activeSubNavItem === 'sentiment'
                                                    ? 'section-nav-more-menu-item-active'
                                                    : ''
                                            }`}
                                            title="sentiment"
                                            onClick={handleSubSectionChange}
                                        >
                                            <p title="sentiment">Sentiment</p>
                                        </Link>
                                    </div>
                                )} */}
                            </div>
                        </li>
                    </ul>
                );
            case 'widgets':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/widgets/manage-widgets"
                                className="section-nav-item-link"
                                title="manage-widgets"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'manage-widgets' ? 'section-link-selected' : ''
                                    }`}
                                    title="manage-widgets"
                                >
                                    Manage widgets
                                </span>
                            </Link>
                        </li>
                        <li className="section-nav-item">
                            <div className="section-nav-item-link" title="more" onClick={toggleNavMoreDropdown}>
                                <span
                                    className={`section-link ${
                                        activeSubNavItem !== 'manage-widgets' ? 'section-link-selected' : ''
                                    }`}
                                    title="more"
                                >
                                    More...
                                </span>
                                {/* {navMoreOpen && (
                                    <div className="section-nav-more-menu">
                                        <Link
                                            to="/widgets/support"
                                            className={`section-nav-more-menu-item ${
                                                activeSubNavItem === 'support'
                                                    ? 'section-nav-more-menu-item-active'
                                                    : ''
                                            }`}
                                            title="support"
                                            onClick={handleSubSectionChange}
                                        >
                                            <p title="support">Support</p>
                                        </Link>
                                    </div>
                                )} */}
                            </div>
                        </li>
                    </ul>
                );
            case 'team':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/team/team-members"
                                className="section-nav-item-link"
                                title="team-members"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'team-members' ? 'section-link-selected' : ''
                                    }`}
                                    title="team-members"
                                >
                                    Team members
                                </span>
                            </Link>
                        </li>
                    </ul>
                );
            case 'subscription':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/subscription/subscription-plan"
                                className="section-nav-item-link"
                                title="subscription-plan"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'subscription-plan' ? 'section-link-selected' : ''
                                    }`}
                                    title="subscription-plan"
                                >
                                    Subscription plan
                                </span>
                            </Link>
                        </li>
                    </ul>
                );
            case 'resources':
                return (
                    <ul className="section-nav-list">
                        <li className="section-nav-item">
                            <Link
                                to="/resources/toolkits"
                                className="section-nav-item-link"
                                title="toolkits"
                                onClick={handleSubSectionChange}
                            >
                                <span
                                    className={`section-link ${
                                        activeSubNavItem === 'toolkits' ? 'section-link-selected' : ''
                                    }`}
                                    title="toolkits"
                                >
                                    Toolkits & assets
                                </span>
                            </Link>
                        </li>
                        <li className="section-nav-item">
                            <div className="section-nav-item-link" title="more" onClick={toggleNavMoreDropdown}>
                                <span
                                    className={`section-link ${
                                        activeSubNavItem !== 'toolkits' ? 'section-link-selected' : ''
                                    }`}
                                    title="more"
                                >
                                    More...
                                </span>
                                {navMoreOpen && (
                                    <div className="section-nav-more-menu">
                                        <Link
                                            to="/resources/articles"
                                            className={`section-nav-more-menu-item ${
                                                activeSubNavItem === 'articles'
                                                    ? 'section-nav-more-menu-item-active'
                                                    : ''
                                            }`}
                                            title="articles"
                                            onClick={handleSubSectionChange}
                                        >
                                            <p title="articles">Articles</p>
                                        </Link>
                                        <Link
                                            to="/resources/faq"
                                            className={`section-nav-more-menu-item ${
                                                activeSubNavItem === 'faq'
                                                    ? 'section-nav-more-menu-item-active'
                                                    : ''
                                            }`}
                                            title="faq"
                                            onClick={handleSubSectionChange}
                                        >
                                            <p title="faq">FAQs</p>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </li>
                    </ul>
                );
            default:
                return 'Events';
        }
    }

    // function toggleProfileSidebar() {
    //     if (!profileOpen){
    //         appContext.refreshUserDetails()
    //     }
    //     updateProfileSidebarState(!profileOpen);
    // }

    function toggleModal (status) {
        updateNavMenuState();
        if (status) {
            appContext.refreshUserDetails();
        }
        updateModalState(status);
    }

    function toggleContactModal () {
        updateNavMenuState();
        appContext.toggleContactUsModal();
    }

    // function createOrganisationSelect(){
    /* eslint-disable-next-line*/
    //     return appContext.user.organisations.map(option => <option key={option.id} value={JSON.stringify(option)}>{option.name}</option>);
    // }

    function createOrganisationMenuSelect () {
        return appContext.user.organisations?.map(option => (
            <div
                key={option.id}
                value={JSON.stringify(option)}
                className="nbdm-item nbdm-item-settings"
                onClick={() => appContext.setSelectedOrganisation(option)}
            >
                <div className="nbdm-item-icon">
                    <ArrowInIcon />
                </div>
                <p className="nbdm-item-title">{option.name}</p>
                {option.id === appContext.selectedOrganisation.id && (
                    <div className="nbdm-item-selected">
                        <TickIcon />
                    </div>
                )}
            </div>
        ));
    }

    // function setSelectedOrganisation(e) {
    //     appContext.setSelectedOrganisation(JSON.parse(e.currentTarget.value))
    // }

    function setMenuStatus (status) {
        appContext.setMenuStatus(status);
    }

    // function determineSelectLength(orgName) {
    //     let canvas = determineSelectLength.canvas || (determineSelectLength.canvas = document.createElement("canvas"));
    //     let context = canvas.getContext("2d");
    //     context.font = 'bold 16px arial';
    //     let metrics = context.measureText(orgName)
    //     let textWidth = Math.round(metrics.width) + 65 + 'px'
    //     return {width: textWidth}
    // }

    function toggleMenuDropdown () {
        updateNavMenuState(!navMenuOpen);
        updateNavMenuPage(1);
    }

    function toggleNavMoreDropdown () {
        console.log('in toggle');
        updateNavMoreDropdown(!navMoreOpen);
        console.log(navMoreOpen);
    }

    return (
        <div className={`section-top-container ${!appContext.menuOpen && 'section-top-container-main-nav-closed'}`}>
            {!appContext.menuOpen && (
                <div className="section-top-menu-icon" onClick={() => setMenuStatus(true)}>
                    <MenuIcon />
                </div>
            )}
            <div className="section-top-header-and-nav">
                <h1 className="section-header">{renderSectionHeader(section)}</h1>
                <div className="section-nav-container">
                    <nav className="section-nav">{renderSectionNav(section)}</nav>
                    <nav className="section-nav-mobile">{renderMobileSectionNav(section)}</nav>
                </div>
            </div>
            <div className="section-top-profile">
                <div className="section-top-user">
                    <div className="section-top-user-details">
                        <h6>{appContext?.selectedOrganisation?.name}</h6>
                        <div
                            className={
                                classnames('user-label',{ 
                                    'label-free': !appContext?.selectedOrganisation?.is_premium_subscriber,
                                    'label-premium': appContext?.selectedOrganisation?.is_premium_subscriber 
                                })}
                        >
                            {appContext?.selectedOrganisation?.is_premium_subscriber ? 'PREMIUM' : 'FREE'}
                        </div>
                    </div>

                    <img className="section-top-profile-img" src={appContext.user.avatar} alt="userprofile" />
                </div>
                {/* <button className="section-top-circle-btn section-top-notification-btn">
                    <NotificationIcon />
                </button> */}
                {navMenuOpen ? (
                    <button className="section-top-circle-btn section-top-dropdown-close-btn">
                        <CaretDownIcon />
                    </button>
                ) : (
                    <button
                        className="section-top-circle-btn section-top-dropdown-btn"
                        onClick={() => toggleMenuDropdown(true)}
                    >
                        <CaretDownIcon />
                    </button>
                )}
                {/* <div ref={profileSidebarRef}>
                    <ProfileSidebar open={profileOpen} toggleProfileSidebar={toggleProfileSidebar}/>
                </div> */}
                {navMenuOpen && (
                    <div className="nav-bar-dropdown-menu-main" ref={navMenuDropdown}>
                        {navMenuPage === 1 && (
                            <div className="nav-bar-dropdown-menu">
                                <div className="nbdm-profile">
                                    <img className="nbdm-profile-img" src={appContext.user.avatar} alt="userprofile" />
                                    <div className="nbdm-profile-copy">
                                        <p className="nbdm-profile-name">
                                            {appContext.user.first_name} {appContext.user.last_name}
                                        </p>
                                        <p className="nbdm-profile-org">{appContext.selectedOrganisation.name}</p>
                                        <p className="nbdm-profile-edit-btn" onClick={() => toggleModal(true)}>
                                            Edit your profile
                                        </p>
                                    </div>
                                </div>
                                <div className="nbdm-separator" />
                                <div className="nbdm-item nbdm-item-switch" onClick={() => updateNavMenuPage(2)}>
                                    <div className="nbdm-item-icon">
                                        <SwitchOrgIcon />
                                    </div>
                                    <p className="nbdm-item-title">Switch {localiseText('organisation')}</p>
                                </div>
                                <div className="nbdm-separator" />
                                <div className="nbdm-item nbdm-item-settings" onClick={() => toggleModal(true)}>
                                    <div className="nbdm-item-icon">
                                        <SettingsIcon />
                                    </div>
                                    <p className="nbdm-item-title">Settings & preferences</p>
                                </div>
                                <a
                                    href="https://racecheck.canny.io"
                                    className="nbdm-a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div className="nbdm-item nbdm-item-settings">
                                        <div className="nbdm-item-icon">
                                            <RoadmapIcon />
                                        </div>
                                        <p className="nbdm-item-title">Roadmap & requests</p>
                                    </div>
                                </a>
                                <Link
                                    to={'/resources/faq'}
                                    className="nbdm-a"
                                >
                                    <div className="nbdm-item nbdm-item-settings">
                                        <div className="nbdm-item-icon">
                                            <HelpIcon />
                                        </div>
                                        <p className="nbdm-item-title">Help</p>
                                    </div>
                                </Link>
                                <div className="nbdm-item nbdm-item-contact" onClick={() => toggleContactModal()}>
                                    <div className="nbdm-item-icon">
                                        <ContactIcon />
                                    </div>
                                    <p className="nbdm-item-title">Contact us</p>
                                </div>
                                <div className="nbdm-item nbdm-item-logout" onClick={() => appContext.handleLogOut()}>
                                    <div className="nbdm-item-icon">
                                        <LogOutIcon />
                                    </div>
                                    <p className="nbdm-item-title">Log out</p>
                                </div>
                            </div>
                        )}
                        {navMenuPage === 2 && (
                            <div className="nav-bar-dropdown-menu">
                                <div className="nbdm-title-item nbdm-item-switch">
                                    <div className="nbdm-back-icon" onClick={() => updateNavMenuPage(1)}>
                                        <ArrowLeftIcon />
                                    </div>
                                    <p className="nbdm-section-title">Your {localiseText('organisations')}</p>
                                </div>
                                {createOrganisationMenuSelect()}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {modalOpen && <Modal toggleModal={toggleModal} />}
            {appContext.isContactUsModalOpen && <ContactUs />}
            {addEventModalOpen && <AddEventModal toggleModal={toggleAddEventModal} />}
            {!userOnboarded &&
                <OnboardingFlow updateUserOnboarded={updateUserOnboarded} toggleAddEventModal={toggleAddEventModal} />
            }
        </div>
    );
}

SectionNav.propTypes = {
    section: PropTypes.string.isRequired,
};
