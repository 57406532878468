import axios from 'axios';  
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Chips from 'react-chips';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { emailRegex } from '../../utils/regex';
import AppContext from '../app/AppContext';
import SuccessMessage from '../helpers/successMessage';

const ForwardToDevTeamModal = ({ isOpen, onClose, widgetId, type, widgetType }) => {

    const [emails, setEmails] = useState([]);
    const [currentSuccessMessage, setCurrentSuccessMessage] = useState('');

    const resetSuccessMessage = () => setCurrentSuccessMessage('');

    const { 
        token = localStorage.getItem('token'),
        selectedOrganisation = {}
    } = useContext(AppContext);

    const { REACT_APP_API_URL } = process.env;

    useEffect(() => {
        setEmails([]);
    }, [isOpen]);

    const validateEmails = emails => emails.filter(email => email.match(emailRegex));

    const handleChange = emails => {
        const validated = validateEmails(emails);
        setEmails(validated);
    };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (!emails.length) return console.error('Emails is empty');

        const widgetTypeSlug = widgetType.replaceAll('-', '_');
        const { id } = selectedOrganisation;
        // eslint-disable-next-line max-len
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/widgets/${widgetId}/forward_to_dev_team/?type=${type}&widget_type=${widgetTypeSlug}`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .post(
                apiCallURL,
                { emails },
                { headers }
            )
            .then(() => 
                setCurrentSuccessMessage(type === 'widget_code' ? 
                    'Successfully sent Widget installation guide' : 'Successfully sent tracking installation guide'))
            .catch(error => console.log(error));
    };

    const handleCancel = (evt) => {
        evt.preventDefault();
        onClose();
    };

    if (!isOpen) return '';

    return (
        <div className="modal-backdrop">
            <div className="modal-padding" />
            <div className="modal-container">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={resetSuccessMessage} />
                )}
                <div className="modal-header">
                    <button className="modal-close-btn" onClick={handleCancel}>
                        <CloseIcon />
                    </button>
                    <h2 className="modal-header-title modal-title-no-nav">Forward to your dev team</h2>
                </div>
                <div className="modal-scrollable">
                    <div className="modal-content">
                        <div className="modal-content-section">
                            <p className="modal-centered-intro">
                                Enter the email(s) of your developer(s) below and we&apos;ll send them all of the information 
                                they need to get this Racecheck Widget installed on your website.
                            </p>
                            <form>
                                <div className="modal-input-row">
                                    <div className="modal-input-group">
                                        <label htmlFor="dev-team-emails" className="modal-input-label">
                                            Email addresses <span className="required-field">*</span>
                                        </label>
                                        <div className="fwd-to-dev-email-input">
                                            <Chips
                                                id="dev-team-emails"
                                                value={emails}
                                                onChange={handleChange}
                                                uniqueChips
                                                createChipKeys={['Enter', ',']}
                                            />
                                        </div>
                                        <p className="fwd-to-dev-info">
                                            Enter a comma <kbd>,</kbd> or press <kbd>Enter ⏎</kbd> after each email
                                            address to add it to list.
                                        </p>
                                    </div>
                                </div>
                                <div className="modal-btn-row">
                                    <button 
                                        className={`btn primary-btn modal-form-btn ${
                                            emails?.length ? 'button-active' : 'disabled-primary-btn'
                                        }`} 
                                        onClick={handleSubmit}
                                    >
                                        Send instructions
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ForwardToDevTeamModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.string,
    widgetId: PropTypes.number
};

export default ForwardToDevTeamModal;
