import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../app/AppContext';
import './WidgetSection.css';
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';
import { ReactComponent as LockIcon } from '../../images/icons/lock.svg';
import { ReactComponent as WarningCircle } from '../../images/icons/exclamation_circle.svg';
import review_carousel from '../../images/widget-library/review-carousel.png';
import expanding_review_box from '../../images/widget-library/expanding-review-box.png';
import mini_review_box from '../../images/widget-library/mini-review-box-free.png';
import free_review_banner from '../../images/widget-library/free-review-banner.png';
import mixpanel from 'mixpanel-browser';

const WidgetLibrary = ({ event, selectedOrganisation }) => {
    const appContext = useContext(AppContext);
    const mpProps = appContext.selectedOrganisation ? {
        organisation: appContext.selectedOrganisation?.name,
        premium_subscriber: appContext.selectedOrganisation?.is_premium_subscriber
    } : {};
    const freeReviewBannerUnlocked = event?.widgets?.free_review_banner?.id;
    const reviewCarouselUnlocked = selectedOrganisation.is_premium_subscriber && event?.widgets?.review_carousel?.id;
    const freeReviewBoxUnlocked = event?.widgets?.review_box?.id;
    const premiumReviewBoxUnlocked = selectedOrganisation.is_premium_subscriber && event?.widgets?.review_box?.id;
    
    return (
        <div className="widget-library-container">
            <div className="wl-section">
                <div className="wl-s-copy">
                    <div className="user-label label-free">
                        FREE
                    </div>
                    <h1>Free widgets</h1>
                    <p>A great starting pointing for directing people to your reviews and asking them to contribute.</p>
                </div>
                <div className="wl-s-widget-list">
                    <Link
                        to={`${freeReviewBannerUnlocked ? `/events/${event.id}/widgets/free-review-banner` : '#'}`}
                        className="wl-card-link"
                    >
                        <div className={`wl-widget-card ${freeReviewBannerUnlocked ? '' : 'wl-widget-card-locked'}`}>
                            <div className="wc-header">
                                <img
                                    src={free_review_banner}
                                    alt="img"
                                />
                            </div>
                            <div className="wc-footer">
                                <h2>Free Review Banner</h2>
                                {freeReviewBannerUnlocked ?
                                    <ChevronRight />
                                    :
                                    <LockIcon className="wc-footer-lock" />
                                }
                            </div>
                        </div>
                    </Link>
                    {!selectedOrganisation.is_premium_subscriber &&
                        <Link
                            to={`${freeReviewBoxUnlocked ? `/events/${event.id}/widgets/review-box` : '#'}`}
                            className="wl-card-link"
                        >
                            <div className={`wl-widget-card ${freeReviewBoxUnlocked ? '' : 'wl-widget-card-locked'}`}>
                                <div className="wc-header">
                                    <img
                                        src={mini_review_box}
                                        alt="img"
                                    />
                                </div>
                                <div className="wc-footer">
                                    <h2>Free Review Box</h2>
                                    {freeReviewBoxUnlocked ?
                                        <ChevronRight />
                                        :
                                        <LockIcon className="wc-footer-lock" />
                                    }
                                </div>
                            </div>
                        </Link>
                    }
                </div>
            </div>
            <div className="wl-section-group">
                <div className="wl-section">
                    <div className="wl-s-copy">
                        <div className="user-label label-premium">
                        PREMIUM
                        </div>
                        <h1>Premium widgets {!selectedOrganisation.is_premium_subscriber && <LockIcon />}</h1>
                        <p>All-in-one solutions for showcasing and collecting reviews.</p>
                    </div>
                    <div className="wl-s-widget-list">
                        <Link
                            to={`${premiumReviewBoxUnlocked ? `/events/${event.id}/widgets/review-box` : '#'}`}
                            className="wl-card-link"
                        >
                            <div className={`wl-widget-card ${premiumReviewBoxUnlocked ? '' : 'wl-widget-card-locked'}`}>
                                <div className="wc-header">
                                    <img
                                        src={expanding_review_box}
                                        alt="img"
                                    />
                                </div>
                                <div className="wc-footer">
                                    <h2>Premium Review Box</h2>
                                    {premiumReviewBoxUnlocked ?
                                        <ChevronRight />
                                        :
                                        <LockIcon className="wc-footer-lock" />
                                    }
                                </div>
                            </div>
                        </Link>
                        <Link
                            to={`${reviewCarouselUnlocked ? `/events/${event.id}/widgets/review-carousel` : '#'}`}
                            className="wl-card-link"
                        >
                            <div className={`wl-widget-card ${reviewCarouselUnlocked ? '' : 'wl-widget-card-locked'}`}>
                                <div className="wc-header">
                                    <img
                                        src={review_carousel}
                                        alt="img"
                                    />
                                </div>
                                <div className="wc-footer">
                                    <h2>Review Carousel</h2>
                                    {reviewCarouselUnlocked ?
                                        <ChevronRight />
                                        :
                                        <LockIcon className="wc-footer-lock" />
                                    }
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                {!selectedOrganisation.is_premium_subscriber &&
                <div className="mr-section-bottom-info">
                    <div className="mr-info-left">
                        <WarningCircle />
                        <p>
                            Upgrade to Racecheck Premium to get access to the complete collection of review widgets.
                        </p>
                    </div>
                    <Link className="btn primary-btn mr-download-hide" to={'/subscription/subscription-plan'}
                        onClick={() => {
                            mixpanel.track('Dashboard - Widgets Library - Upgrade to Premium click', mpProps);
                        }}
                    >
                        Upgrade to Premium
                    </Link>
                </div>
                }
            </div>
        </div>
    );
};

export default WidgetLibrary;
