import axios from 'axios';

export const request = (url, method = 'GET', headers = {}, body) => {
    const requestURL = process.env.REACT_APP_API_URL + url;
    const token = localStorage.getItem('token');

    return axios({
        method,
        url: requestURL,
        headers: {
            Authorization: `Token ${token}`,
            ...headers
        },
        data: body,

    });
};

export default request;