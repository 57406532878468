import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import AutocompleteSuggestions from './autocompleteSuggestions';
import AppContext from '../app/AppContext';

class Search extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            query: '',
            results: [],
        };
    }

    getData = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        const apiCallURL = `${process.env.REACT_APP_API_URL}${this.props.apiEndpoint}?q=${this.state.query}`;
        axios.get(apiCallURL, { headers }).then(({ data }) => {
            this.setState({
                results: data.results,
            });
        });
    };

    handleInputChange = () => {
        this.setState(
            {
                query: this.search.value,
            },
            () => {
                if (this.props.handleSearchChange) {
                    this.props.handleSearchChange();
                }
                if (this.state.query) {
                    this.getData();
                }
            }
        );
    };

    handleClick = (id, name, organisation_id) => {
        this.setState(
            {
                query: name,
                results: [],
            },
            () => {
                this.search = name;
                if (this.props.handleEventSelection) {
                    this.props.handleEventSelection(id, organisation_id);
                }

                if (this.props.handleOrganisationSelection) {
                    this.props.handleOrganisationSelection(id, name);
                }
            }
        );
    };

    render () {
        return (
            <form className="autocomplete-form">
                <input
                    placeholder={this.props.placeholder}
                    ref={input => (this.search = input)}
                    onChange={this.handleInputChange}
                    className={this.props.modalAutocomplete ? 'input modal-input' : 'input'}
                    value={this.props.clearClaimAutocomplete ? '' : this.state.query}
                />
                <AutocompleteSuggestions
                    results={this.state.results}
                    handleClick={this.handleClick}
                    modalAutocomplete={this.props.modalAutocomplete}
                />
            </form>
        );
    }
}

Search.propTypes = {
    apiEndpoint: PropTypes.string.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    handleEventSelection: PropTypes.func.isRequired,
    handleOrganisationSelection: PropTypes.func,
    placeholder: PropTypes.string,
    modalAutocomplete: PropTypes.bool,
    clearClaimAutocomplete: PropTypes.bool,
};

Search.defaultProps = {
    apiEndpoint: '',
    handleSearchChange: () => {},
    handleEventSelection: () => {},
    handleOrganisationSelection: () => {},
    placeholder: '',
    modalAutocomplete: false,
    clearClaimAutocomplete: false,
};

export default Search;
