import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormLogIn from './FormLogIn';
import FormSignUp from './FormSignUp';
import SocialAccounts from './SocialAccounts';
import './LogInSignUp.css';
import LogInRoutes from '../../routes/PublicRoutes';
import AppContext from '../app/AppContext';
import { localiseText } from '../../utils';

class LogInSignUp extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            email:
                this.props.location && this.props.location.state && this.props.location.state.email
                    ? this.props.location.state.email
                    : '',
            registering: this.props.registering,
            viewSource: 'racecheck',
        };
    }

    componentDidMount () {
        if (window.location.href.includes('strava-login')) {
            this.setState({
                viewSource: 'strava',
            });
        }
        else if (window.location.href.includes('facebook-login')) {
            this.setState({
                viewSource: 'facebook',
            });
        }
        else if (window.location.href.includes('google-login')) {
            this.setState({
                viewSource: 'google',
            });
        }
        else if (window.location.href.includes('create-organisation')) {
            this.setState({
                viewSource: 'creatingOrg',
            });
        }
        else if (window.location.href.includes('accept_team_invitation')) {
            this.setState({
                viewSource: 'accept-team-invite',
            });
        }
        else if (window.location.href.includes('reset-password')) {
            this.setState({
                viewSource: 'reset-password',
            });
        }
    }

    logInSignUpSwitcher = () => {
        const { registering } = this.state;
        registering
            ? this.setState(
                {
                    registering: false,
                },
                () => {
                    this.props.history.push('/login');
                }
            )
            : this.setState(
                {
                    registering: true,
                },
                () => {
                    this.props.history.push('/signup');
                }
            );
    };

    render () {
        const { registering } = this.state;
        const source = this.state.viewSource;
        if (
            source === 'strava' ||
            source === 'facebook' ||
            source === 'google' ||
            source === 'creatingOrg' ||
            source === 'accept-team-invite' ||
            this.context.membershipStatus === 'no-org' ||
            source === 'reset-password'
        ) {
            return <LogInRoutes />;
        }
        return (
            <div className="logged_out_container">
                <a href="/">
                    <img
                        className="logged_out_logo"
                        src={require('../../images/racecheck_logo_black.svg')}
                        width="250px"
                        alt="racecheck-logo"
                    />
                </a>
                <div className="log_in_container">
                    {registering ? (
                        <h2>Create your free account</h2>
                    ) : (
                        <h2>
                            Welcome back{' '}
                            <span role="img" aria-label="wave">
                                👋
                            </span>
                        </h2>
                    )}
                    <SocialAccounts type={registering} />
                    {registering ? (
                        <div>
                            <div className="sign_up_divider">or</div>
                            <FormSignUp email={this.state.email} />
                        </div>
                    ) : (
                        <div>
                            <div className="log_in_divider">or use your Racecheck account</div>
                            <FormLogIn />
                        </div>
                    )}
                    {registering ? (
                        <p className="log-in-sign-up-link">
                            Already have an account? <span onClick={this.logInSignUpSwitcher}>Log in</span>
                        </p>
                    ) : (
                        <p className="log-in-sign-up-link">
                            Don&apos;t have an account? <span onClick={this.logInSignUpSwitcher}>Sign up</span>
                        </p>
                    )}
                    <p className="account-support-link">
                        Need help accessing your account or {localiseText('organisation')}?&nbsp;
                        Get in touch via <a href="mailto:support@racecheck.com">support@racecheck.com</a>
                    </p>
                </div>
            </div>
        );
    }
};

LogInSignUp.propTypes = {
    registering: PropTypes.bool,
};

export default LogInSignUp;
