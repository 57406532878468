import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios/index';
import moment from 'moment';
import EventCard from './EventCard';
import Loader from '../helpers/loader';
import noDataImage from '../../images/no_content.svg';
import AddEventModal from './AddEventModal';
import AppContext from '../app/AppContext';
import LoaderEventCard from './LoaderEventCard';
import InfiniteScroll from 'react-infinite-scroll-component';



const EventCardsList = ({ 
    selectedOrganisation, 
    eventStatus = 'A', 
    isAddEventModalOpen, 
    toggleIsAddEventModalOpen,
    searchQuery,
    setHasUnreviewed
}) => {

    const context = useContext(AppContext);
    const [eventsList, setEventsList] = useState([]);
    const [eventsLoaded, setEventsLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreEvents, setHasMoreEvents] = useState(false);

    const { 
        token = localStorage.getItem('token') 
    } = context;
    const { REACT_APP_API_URL } = process.env;

    const getEvents = () => {

        const { id } = selectedOrganisation;
        const query = searchQuery === '' ? '' : `&q=${searchQuery}`;
        
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/?status=${eventStatus}${query}`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        setCurrentPage(1);
        setEventsLoaded(false);

        axios.get(apiCallURL, { headers })
            .then(({ data: { data } }) => {
                const hasMore = (data.current_page * data.items_per_page) < data.items_total_count;
            
                setEventsList(data.data);
                setEventsLoaded(true);
                setHasMoreEvents(hasMore);
                setHasUnreviewed(data.has_unreviewed);
            });
    };

    const getMoreEvents = () => {

        const { id } = selectedOrganisation;
        /* eslint-disable-next-line*/
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/?status=${eventStatus}&page=${currentPage}&q=${searchQuery}`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .get(apiCallURL, { headers })
            .then(({ data: { data } }) => {
                const hasMore = (data.current_page * data.items_per_page) < data.items_total_count;

                setEventsList(prevState => [
                    ...prevState,
                    ...data.data
                ]);
                setEventsLoaded(true);
                setHasMoreEvents(hasMore);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const updateEventsLists = (eventId, new_date, renewed = false) => {
        const formattedDate = moment(new Date(new_date)).format('Do MMMM YYYY').toString();
        
        if (renewed) {
            const event = eventsList.find(({ id }) => id === eventId);
            event.latest_date = new_date;
            event.formatted_latest_date = formattedDate;

            setEventsList(prevState => [...prevState, event], () => {
                setEventsLoaded(false);
                sortUpcomingEvents();
            });
        }
        else {
            const eventIdx = eventsList.findIndex(({ id }) => id === eventId);

            setEventsList(prevState => [
                ...prevState.slice(0, eventIdx),
                {
                    ...eventsList[eventIdx],
                    formatted_latest_date: formattedDate,
                    latest_date: new_date
                },
                ...eventsList.slice(eventIdx + 1)
            ], () => {
                setEventsLoaded(true);
                sortUpcomingEvents();
            });
            
        }
    };

    const sortUpcomingEvents = () => setEventsList(
        prevState => prevState.sort(
            (a, b) => new Date(a.latest_date) - new Date(b.latest_date)
        ), () => setEventsLoaded(true)
    );

    useEffect(() => {
        if (currentPage > 1) getMoreEvents();
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        getEvents();

        return () => setEventsList([]);

        // eslint-disable-next-line
    }, [selectedOrganisation.id, eventStatus]);

    useEffect(() => {
        getEvents();

        // eslint-disable-next-line
    }, [searchQuery]);

    const toggleAddEventModal = () => {
        toggleIsAddEventModalOpen(prevState => !prevState);
    };

    return (
        <div>
            {eventsLoaded ? (
                <div className="event-cards-container-main">
                    {eventsList.length > 0 ? (
                        <>
                            <InfiniteScroll
                                className="event-cards-container"
                                dataLength={eventsList.length}
                                next={() => setCurrentPage(prevState => prevState + 1)}
                                hasMore={hasMoreEvents}
                                loader={
                                    <LoaderEventCard />
                                }
                            >
                                {eventsList.map(event => (
                                    <EventCard
                                        key={event.id}
                                        event={event}
                                        showingCompleted
                                        updateEventsLists={updateEventsLists}
                                    />
                                ))}
                            </InfiniteScroll>
                        </>
                    ) : (
                        <div className="no-data-container">
                            <img src={noDataImage} className="no-data-img" alt="empty-clipboard" />
                            <p className="no-data-header">
                                You currently have no events
                            </p>
                            <div
                                className="btn primary-btn modal-form-btn no-data-add-event-btn"
                                onClick={toggleAddEventModal}
                            >
                                Add or claim an event
                            </div>
                        </div>
                    )}
                    {isAddEventModalOpen && <AddEventModal toggleModal={toggleAddEventModal} />}
                </div>
            ) : (
                <div className="section-loading-container">
                    <Loader />
                </div>
            )}
        </div>
    );
};

EventCardsList.propTypes = {
    showingUpcoming: PropTypes.bool.isRequired,
    selectedOrganisation: PropTypes.shape({
        id: PropTypes.number
    }).isRequired,
    eventStatus: PropTypes.string,
    isAddEventModalOpen: PropTypes.bool,
    toggleIsAddEventModalOpen: PropTypes.func,
    searchQuery: PropTypes.string,
    setHasUnreviewed: PropTypes.func,
};

EventCardsList.defaultProps = {
    showingUpcoming: false,
};

export default EventCardsList;
