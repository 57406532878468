import React from 'react';
import Loader from '../helpers/loader';

function LoaderEventCard () {
    return (
        <div className="button-event-card-container">
            <div className="button-event-card">
                <Loader />
            </div>
        </div>
    );
}

export default LoaderEventCard;
