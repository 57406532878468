import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';

const NPSReport = ({ npsData }) => {

    const npsTotal = npsData.promoters + npsData.passives + npsData.detractors;
    const npsPromoterPerc = Math.round((npsData.promoters / npsTotal) * 100);
    const npsPassivePerc = Math.round((npsData.passives / npsTotal) * 100);
    const npsDetractorPerc = Math.round((npsData.detractors / npsTotal) * 100);
    // const npsScore = npsPromoterPerc - npsDetractorPerc;

    const chartData = {
        labels: ['Promoters', 'Passives', 'Detractors'],
        datasets: [
            {
                data: [npsData.promoters, npsData.passives, npsData.detractors],
                backgroundColor: [
                    'rgba(65, 172, 111, 1)',
                    'rgba(242, 171, 60, 1)',
                    'rgba(203, 61, 53, 1)'
                ],
                hoverBackgroundColor: [
                    'rgba(65, 172, 111, 1)',
                    'rgba(242, 171, 60, 1)',
                    'rgba(203, 61, 53, 1)'
                ],
                cutout: '80%',
            }
        ],
    };

    const chartOptions = {
        title: {
            display: true,
            text: '99',
            fontSize: 30
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label (tooltipItems, data) {
                    return ` ${data.datasets[0].data[tooltipItems.index]} ${data.labels[tooltipItems.index]}`;
                },
            },
        },
        plugins: {
            legend: {
                display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                animateRotate: false
            },
            datalabels: {
                display: false
            }
        }
    };

    return (
        <div className="review-nps-container">
            <div className="review-right-header-container">
                <p className="review-nps-title">
                    Net Promoter Score <HelpIcon />
                </p>
                <div className="nps-title-tooltip">
                    <span className="nps-tooltip-desc">
                        NPS is a standardised measure of customer satisfaction. A high NPS indicates a well-liked event.
                    </span>
                    <div className="nps-tooltip-extra-row">
                        <p className="nps-tooltip-desc">
                            NPS =  <span>Promoters(%)</span> - <span>Detractors(%)</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="review-nps-wrap">
                <div className="nps-chart">
                    <Doughnut data={chartData} options={chartOptions} />
                    {/* eslint-disable-next-line max-len */}
                    <div className="nps-score-container">
                        <span>{npsData.score.toFixed(1)}</span>
                    </div>
                </div>
                <div className="nps-breakdown-container">
                    <div className="nps-value-group">
                        <div className="nps-vg-copy">
                            <p>Promoters</p>
                            <p>{npsPromoterPerc}%</p>
                        </div>
                        <div className="progress-bar-container pbc-nps-promoters">
                            <div className="progress-bar-child progress" />
                            <div className="progress-bar-child shrinker" style={{ width: `${100 - npsPromoterPerc}%` }} />
                        </div>
                    </div>
                    <div className="nps-value-group">
                        <div className="nps-vg-copy">
                            <p>Passives</p>
                            <p>{npsPassivePerc}%</p>
                        </div>
                        <div className="progress-bar-container pbc-nps-passives">
                            <div className="progress-bar-child progress" />
                            <div className="progress-bar-child shrinker" style={{ width: `${100 - npsPassivePerc}%` }} />
                        </div>
                    </div>
                    <div className="nps-value-group">
                        <div className="nps-vg-copy">
                            <p>Detractors</p>
                            <p>{npsDetractorPerc}%</p>
                        </div>
                        <div className="progress-bar-container pbc-nps-detractors">
                            <div className="progress-bar-child progress" />
                            <div className="progress-bar-child shrinker" style={{ width: `${100 - npsDetractorPerc}%` }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default NPSReport;

NPSReport.propTypes = {
    npsData: PropTypes.shape({
        promoters: PropTypes.number,
        passives: PropTypes.number,
        detractors: PropTypes.number,
        score: PropTypes.number
    }).isRequired,
};
