import React, { Component } from 'react';
import AppContext from '../app/AppContext';
// import { ReactComponent as QuestionMarkImg } from '../../images/question_mark_img.png';
import NavBarHelperModal from './NavBarHelperModal';
import navBarHelperImg from '../../images/question_mark_img.png';
import mixpanel from 'mixpanel-browser';

class NavBarHelper extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            helperModalOpen: false,
        };
    }

    toggleHelperModal = () => {
        const { helperModalOpen } = this.state;
        if (helperModalOpen) {
            this.context.setMainNavOverlayStatus(false);
            this.setState({
                helperModalOpen: false
            });
        }
        else {
            this.context.setMainNavOverlayStatus(true);
            this.setState({
                helperModalOpen: true
            });
        }
    };

    renderQ = (pathname) => {
        if (pathname.includes('/events/') & !pathname.includes('/widgets') & !pathname.includes('/reviews')) {
            return 'How do I add or claim an event?';
        }
        else if (pathname.includes('/reviews')) {
            return 'How do I generate social posts?';
        }
        else if (pathname.includes('/analytics/events-report')) {
            return 'How are my analytics calculated?';
        }
        else if (pathname.includes('/widgets')) {
            return 'What are Racecheck widgets?';
        }
        else if (pathname.includes('/resources/')) {
            return 'How do I earn Racecheck awards?';
        }
        else if (pathname.includes('/team/')) {
            return 'How do I add a member to my team?';
        }
        else if (pathname.includes('/subscription/')) {
            return 'How do I upgrade to Racecheck Premium?';
        }
    }

    render () {
        const pathname = window.location.href;
        const mpProps = this.context.selectedOrganisation ? {
            organisation: this.context.selectedOrganisation?.name,
            premium_subscriber: this.context.selectedOrganisation?.is_premium_subscriber
        } : {};

        return (
            <div>
                <div className="nav-promotion">
                    <img className="nav-promotion-img" src={navBarHelperImg} alt="Man thinking with question mark" />
                    <p className="nav-promotion-copy">{this.renderQ(pathname)}</p>
                    <p className="btn secondary-btn secondary-btn-rounded" onClick={() => {
                        this.toggleHelperModal();
                        mixpanel.track('Dashboard - Section FAQ click', mpProps);
                    }
                    }
                    >
                        Section FAQ
                    </p>
                </div>
                {this.state.helperModalOpen && (
                    <NavBarHelperModal toggleModal={this.toggleHelperModal} />
                )}
            </div>
        );
    }
}

export default NavBarHelper;
