import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import AppContext from '../app/AppContext';
import { localiseText } from '../../utils';

class FormLogIn extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            formError: false,
        };
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleSubmit = e => {
        const { username, password } = this.state;
        e.preventDefault();
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/login/`;
        axios
            .post(apiCallURL, {
                username,
                password,
                claim: this.context.getClaimId()
            })
            .then(res => {
                this.context.setUser(res.data.user);
                this.context.setToken(res.data.token);
                this.setState({
                    username: '',
                    password: '',
                });
                const {
                    membershipStatus,
                    defaultOrganisation,
                } = this.context.getMemberShipStatusAndDefaultOrganisation(res.data.user);

                localStorage.removeItem('claim');
                const inviteCode = sessionStorage.getItem('invite_code');
                const queryParams = queryString.parse(this.props.location.search);

                if (inviteCode) {
                    this.context.acceptTeamInvite(res);
                }
                else {
                    this.context.handleLogInState(res.data.token, membershipStatus, defaultOrganisation);
                }

                if (['no-org', 'organisation-lead-claim', 'organisation-pending-requests'].includes(membershipStatus)) {
                    return this.props.history.push('/create-organisation');
                }
                else if (membershipStatus === 'accepted-invite') {
                    return this.props.history.push(`/accept_team_invitation/${inviteCode}`);
                }
                else if (queryParams.next && queryParams.next !== undefined && queryParams.next !== null) {
                    return this.props.history.push(queryParams.next);
                }
                else {
                    this.props.history.push('/events/events');
                }

            })
            .catch(error => {
                console.log(error);
                this.setState({
                    username: '',
                    password: '',
                    formError: true,
                });
            });
    };

    render () {
        const { username, password, formError } = this.state;
        return (
            <div>
                <form className="log-in-form" onSubmit={this.handleSubmit}>
                    {formError ? (
                        <div className="log-in-error">Email or password incorrect. Please try again.</div>
                    ) : (
                        ''
                    )}
                    <div className="input-group">
                        <label htmlFor="log-in-email">Email Address</label>
                        <input
                            id="log-in-email"
                            value={username}
                            onChange={this.handleChange}
                            className="input"
                            name="username"
                            type="email"
                            placeholder={`name@${localiseText('organisation')}.com`}
                            required
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="log-in-password">Password</label>
                        <input
                            id="log-in-password"
                            value={password}
                            onChange={this.handleChange}
                            className="input"
                            name="password"
                            type="password"
                            placeholder="Password"
                            required
                        />
                        <a href="/reset-password" className="forgotten-password-link">
                            Forgot your password?
                        </a>
                    </div>
                    <div className="log-in-form-submit">
                        <input id="log-in-submit" type="submit" className="log-in-btn btn" value="Log In" />
                    </div>
                </form>
            </div>
        );
    }
}

export default withRouter(FormLogIn);
