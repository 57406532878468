import React, { useState, useEffect, useContext } from 'react';

import he from 'he';
import PropTypes from 'prop-types';
import axios from 'axios';

import Loader from '../helpers/loader';
import ErrorMessage from '../helpers/errorMessage';
import SmallLoader from '../../images/small_loader.gif';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import RatingStars from '../helpers/starGenerator';
import { ReactComponent as EmptyStar } from '../../images/stars/gold_star_empty.svg';
import { ReactComponent as ProfileIcon } from '../../images/icons/person.svg';
import goldLaurel from '../../images/laurel_gold_with_background.svg';
import silverLaurel from '../../images/laurel_silver_with_background.svg';
import bronzeLaurel from '../../images/laurel_bronze_with_background.svg';
import { ReactComponent as SpeechMarksLeft } from '../../images/social-images/speech-marks-left.svg';
import { ReactComponent as SpeechMarksRight } from '../../images/social-images/speech-marks-right.svg';
import { ReactComponent as FacebookLogo } from '../../images/icons/facebook_logo.svg';
import { ReactComponent as TwitterLogo } from '../../images/icons/twitter_logo.svg';
import { ReactComponent as LinkedInLogo } from '../../images/icons/linkedin_logo.svg';
import { ReactComponent as InstagramLogo } from '../../images/icons/instagram_logo.svg';
import { ReactComponent as ChevronIcon } from '../../images/icons/chevron_left.svg';
import { ReactComponent as AddIcon } from '../../images/icons/add-circle.svg';
import { ReactComponent as RemoveIcon } from '../../images/icons/remove-circle.svg';

import ReviewImageGeneratorStep2 from './ReviewImageGeneratorStep2';
import ReviewImageGeneratorStep3 from './ReviewImageGeneratorStep3';
import AppContext from '../app/AppContext';


window.onmousemove = function (e) {
    const tooltips = document.querySelectorAll('.rig-sentence-tooltip');
    const x = e.clientX + 'px';
    const y = (e.clientY - 40) + 'px';
    for (let i = 0; i < tooltips.length; i++) {
        tooltips[i].style.top = y;
        tooltips[i].style.left = x;
    }
};

const ReviewImageGenerator = ({ toggleModal, firstName, lastName, review }) => {
    const appContext = useContext(AppContext);
    const [activeStep, setActiveStep] = useState(0);
    const [selectedText] = useState('');
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [sizeOptions, setSizeOptions] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [backgroundOptions, setBackgroundOptions] = useState([]);
    const [selectedBackground, setSelectedBackground] = useState({});
    const [activePreviewImage, setActivePreviewImage] = useState(0);
    const [reviewSizerLoading, setReviewSizerLoading] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const [downloadFileError, setDownloadFileError] = useState('');

    const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight;

    const resizeText = ({ element, elements, minSize = 10, maxSize = 45, step = 1, unit = 'px', adjustHeight = false }) => {
        (elements || [element]).forEach(el => {
            let i = minSize;
            let overflow = false;
            const parent = el.parentElement;

            if (adjustHeight) {
                parent.style.height = '70%';
            }

            while (!overflow && i < maxSize) {
                el.style.fontSize = `${i}${unit}`;
                overflow = isOverflown(parent);

                if (!overflow) i += step;
            }

            el.style.fontSize = `${i - step}${unit}`;
            
            if (adjustHeight) {
                parent.style.height = 'fit-content';
            }
            setReviewSizerLoading(false);
        });
    };

    useEffect(() => {
        setReviewSizerLoading(true);
        const reviewText = he.decode(review?.sentences
            ?.sort((a,b) => a - b)
            ?.filter((sentence, index) => selectedIndexes?.includes(index)).join(' '));
        if (reviewText.length > 200) {
            document.getElementById('review-share-text').innerHTML = reviewText.substring(0, 200) + '...';
        }
        resizeText({
            elements: document.querySelectorAll('.review-share-text'),
            step: 0.5,
            adjustHeight: true,
            minSize: 7
        });
        // eslint-disable-next-line
    }, [selectedIndexes, activePreviewImage, selectedSizes]);

    useEffect(() => {
        resizeText({
            elements: document.querySelectorAll('.spc-event-title'),
            step: 0.5,
            minSize: 1
        });
    });

    useEffect(() => {
        getSizeOptions();
        getBackgroundOptions();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sizeOptions?.length > 0) setSelectedSizes([sizeOptions[0]]);
    },[sizeOptions]);

    useEffect(() => {
        if (backgroundOptions?.length > 0) setSelectedBackground(backgroundOptions[0]);
    },[backgroundOptions]);

    const getSizeOptions = () => {
        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/share_review_image_ratios/`;

        axios
            .get(apiCallURL, { headers })
            .then(res => {
                setSizeOptions(res?.data?.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const getBackgroundOptions = () => {
        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/share_review_image_backgrounds/`;

        axios
            .get(apiCallURL, { headers })
            .then(res => {
                setBackgroundOptions(res?.data?.data);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const downloadImages = () => {
        setLoadingButton(true);
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${appContext.selectedOrganisation?.id}/events/${review?.event_id}/reviews/${review?.id}/share_review_image/`;
        const headers = {
            Authorization: `Token ${appContext.token}`,
        };

        axios.post(apiCallURL, {
            sentences: selectedIndexes,
            ratios: selectedSizes.map(item => item?.id),
            backgrounds: [selectedBackground?.id]
        }, { headers })
            .then(({ data }) => {
                setLoadingButton(false);
                const url = data?.data;
                const aElement = document.createElement('a');
                aElement.setAttribute('download', url?.slice(url?.lastIndexOf('/') + 1));
                aElement.href = url;
                aElement.click();
            })
            .catch(err => {
                console.log('Could not download file', err);
                if (err?.response?.status >= 400 && err?.response?.status < 501) {
                    setDownloadFileError('Unable to download your image. Please refresh the page and try again');
                }
            })
            .finally(() => {
                setLoadingButton(false);
            });
    };
    
    return (
        <div className="modal-backdrop">
            {downloadFileError && (
                <ErrorMessage message={downloadFileError} resetErrorMessage={() => setDownloadFileError('')} />
            )}
            <div className="modal-padding" />
            <div className="modal-container">
                <div className="modal-header">
                    <button className="modal-close-btn" onClick={() => toggleModal(false)}>
                        <CloseIcon />
                    </button>
                    <h2 className="modal-header-title modal-title-no-nav">Review image generator</h2>
                </div>
                <div className="modal-scrollable">
                    <div className="modal-content rig-modal-content">
                        <div className="modal-content-section rig-modal-content-section">
                            
                            {activeStep === 0 &&
                            <>
                                <p className="rig-section-header">Select review content</p>
                                <div>
                                    <div className="review-card-review-details rig-review-details">
                                        {review.profile.avatar ? (
                                            <div className="review-card-author-avatar">
                                                {review.profile.visor_status === 'gold' 
                                                && (
                                                    <img 
                                                        src={goldLaurel}
                                                        alt="laurel-half" className="avatar-laurel-frame-left"
                                                    /> )}
                                                {review.profile.visor_status === 'silver' 
                                                && (
                                                    <img 
                                                        src={silverLaurel} alt="laurel-half" className="avatar-laurel-frame-left"
                                                    />) }
                                                {review.profile.visor_status === 'bronze'
                                                && (
                                                    <img 
                                                        src={bronzeLaurel} alt="laurel-half" className="avatar-laurel-frame-left"
                                                    />) }
                                                <img
                                                    className="review-card-profile-img"
                                                    src={review.profile.avatar}
                                                    alt="user-profile-avatar"
                                                />
                                                {review.profile.visor_status === 'gold' 
                                                && (
                                                    <img
                                                        src={goldLaurel} alt="laurel-half" className="avatar-laurel-frame-right"
                                                    />) }
                                                {review.profile.visor_status === 'silver' 
                                                && (
                                                    <img 
                                                        src={silverLaurel} alt="laurel-half" className="avatar-laurel-frame-right"
                                                    />) }
                                                {review.profile.visor_status === 'bronze'
                                                && (
                                                    <img 
                                                        src={bronzeLaurel} alt="laurel-half" className="avatar-laurel-frame-right"
                                                    />) }
                                            </div>
                                        ) : (
                                            <div className="review-card-profile-img-placeholder">
                                                <ProfileIcon />
                                            </div>
                                        )}
                                        {review.profile.id ?
                                            <a 
                                                href={`https://racecheck.com/profile/${review.profile.id}/`} 
                                                className="review-card-author review-card-author-link" 
                                                target="_blank" rel="noopener noreferrer"
                                            >
                                                {`${firstName} ${lastName.charAt(0)}`}
                                                {`${lastName && '.'}`}
                                            </a>
                                            :
                                            <p className="review-card-author">
                                                {`${firstName} ${lastName.charAt(0)}`}
                                                {`${lastName && '.'}`}
                                            </p>
                                        }
                                        <p className="bullet">•</p>
                                        {review.average_rating > 0 ?
                                            <RatingStars rating={review.average_rating} />
                                            :
                                            <div className="rating-stars unrated-rating-stars">
                                                <p className="rating-value">
                                                    Unrated
                                                </p>
                                                <EmptyStar />
                                                <EmptyStar />
                                                <EmptyStar />
                                                <EmptyStar />
                                                <EmptyStar />
                                            </div>
                                        }
                                        <p className="bullet">•</p>
                                        <p>{review.year}</p>
                                        {review.distance &&
                                                <>
                                                    <p className="bullet">•</p>
                                                    <p>{review.distance}</p>
                                                </>
                                        }
                                        {!!review.average_rating && review.average_rating < 3 &&
                                                <>
                                                    <p className="bullet">•</p>
                                                    <p className="review-card-negative-tag">Negative</p>
                                                </>
                                        }
                                    </div>
                                </div>

                                <div>
                                    <p className="rig-review-copy">
                                        {review?.sentences?.map((sentence, index) => (
                                            <span
                                                className=
                                                    {`rig-sentence 
                                                    ${selectedIndexes.includes(index) && 'rig-sentence-selected'}
                                                    `}
                                                key={index}
                                                onClick={() => {
                                                    if (!selectedIndexes.includes(index)) {
                                                        setSelectedIndexes(prev => [...prev,index]);
                                                    }
                                                    else {
                                                        setSelectedIndexes(prev => [...prev.filter(item => item !== index)]);
                                                    }
                                                }}
                                            >
                                                {he.decode(sentence)}
                                                {selectedIndexes.includes(index) ?
                                                    <span className="rig-sentence-tooltip">
                                                        <RemoveIcon className="rig-st-remove" />
                                                Remove from image
                                                    </span>
                                                    :
                                                    <span className="rig-sentence-tooltip">
                                                        <AddIcon className="rig-st-add" />
                                                Add to image
                                                    </span>
                                                }
                                            </span>
                                        ))}
                                    </p>
                                </div>
                               
                            </>
                            }

                            {activeStep === 1 &&
                                <ReviewImageGeneratorStep2
                                    selectedText={selectedText}
                                    firstName={firstName}
                                    lastName={lastName}
                                    review={review}
                                    sizeOptions={sizeOptions}
                                    selectedSizes={selectedSizes}
                                    setSelectedSizes={setSelectedSizes}
                                    setActivePreviewImage={setActivePreviewImage}
                                />
                            }
                            {activeStep === 2 &&
                                <ReviewImageGeneratorStep3
                                    backgroundOptions={backgroundOptions}
                                    selectedBackground={selectedBackground}
                                    setSelectedBackground={setSelectedBackground}
                                />
                            }
                        </div>

                        <div className="social-content-preview">
                            {sizeOptions.length && backgroundOptions.length ?
                                <div className=
                                    {`social-post-canvas 
                                    spc-${selectedBackground?.theme} 
                                    spc-${selectedSizes[activePreviewImage]?.slug}
                                    spc-${selectedBackground?.style}`}
                                >
                                    <div className="spc-event-title-container">
                                        <p className="spc-event-title">
                                            {review.event_title}
                                        </p>
                                    </div>
                                    <div className="spc-review-container">
                                        <SpeechMarksLeft className="speech-mark sp-left" />
                                        <div className="spc-review-content">
                                            <div className="spc-stars">
                                                {review.average_rating > 0 ?
                                                    <RatingStars rating={review.average_rating} />
                                                    :
                                                    <div className="rating-stars unrated-rating-stars">
                                                        <p className="rating-value">
                                                                Unrated
                                                        </p>
                                                        <EmptyStar />
                                                        <EmptyStar />
                                                        <EmptyStar />
                                                        <EmptyStar />
                                                        <EmptyStar />
                                                    </div>
                                                }
                                            </div>
                                            {!reviewSizerLoading &&
                                                <div className=
                                                    {`social-text-container 
                                                    ${!selectedIndexes?.length ? 'placeholder-text-container' : ''}
                                                    `}
                                                >
                                                    {!selectedIndexes?.length ?
                                                        <span className="review-share-text placeholder-review-text">
                                                            Click a sentence from the review above
                                                             to add or remove it from the image.
                                                        </span>
                                                        :
                                                        <span className="review-share-text" id="review-share-text">
                                                            {/*eslint-disable*/}
                                                            {
                                                                he.decode(review?.sentences
                                                                    ?.sort((a,b) => a - b)
                                                                    ?.filter((sentence, index) => selectedIndexes?.includes(index)).join(' '))
                                                            }
                                                            {/* eslint-enable*/}
                                                        </span>
                                                    }
                                                </div>
                                            }
                                            <p className="spc-review-author">
                                                {`${firstName} ${lastName.charAt(0)}`}
                                                {`${lastName && '.'}`}
                                            </p>
                                        </div>
                                        <SpeechMarksRight className="speech-mark sp-right" />
                                    </div>
                                    <div className="spc-footer">
                                        <p>reviewed on</p>
                                        <img
                                            src={require('../../images/racecheck_logo_white.svg')}
                                            alt="racecheck-logo"
                                        />
                                    </div>
                                </div>
                                :
                                <div className={'social-post-canvas spc-dark spc-landscape spc-confetti spc-loading'}>
                                    <div className="section-loading-container">
                                        <Loader />
                                    </div>
                                </div>
                            }
                            <div className="social-images-footer">
                                {sizeOptions.length ?
                                    <>
                                        {selectedSizes.length > 1 &&
                                            <ChevronIcon
                                                className="si-footer-arrow"
                                                onClick={activePreviewImage === 0 ?
                                                    () => setActivePreviewImage(selectedSizes.length - 1)
                                                    :
                                                    () => setActivePreviewImage(prevStep => prevStep - 1)
                                                }
                                            />
                                        }
                                        {selectedSizes.length > 0 &&
                                            <div className="si-footer-info">
                                                <p className="si-footer-title">
                                                    {selectedSizes[activePreviewImage]?.apps.includes('facebook') &&
                                                    <FacebookLogo />
                                                    }
                                                    {selectedSizes[activePreviewImage]?.apps.includes('twitter') &&
                                                    <TwitterLogo />
                                                    }
                                                    {selectedSizes[activePreviewImage]?.apps.includes('linkedin') &&
                                                    <LinkedInLogo />
                                                    }
                                                    {selectedSizes[activePreviewImage]?.apps.includes('instagram') &&
                                                    <InstagramLogo />
                                                    }
                                                    {selectedSizes[activePreviewImage]?.text}
                                                </p>
                                                <p className="bullet">•</p>
                                                <p className="si-footer-size">
                                                    {selectedSizes[activePreviewImage]?.dimensions}
                                                </p>
                                                <p className="bullet">•</p>
                                                <p className="si-footer-ratio">
                                                    {selectedSizes[activePreviewImage]?.ratio}
                                                </p>
                                            </div>
                                        }
                                        {selectedSizes.length > 1 &&
                                            <ChevronIcon
                                                className="si-footer-arrow"
                                                onClick={activePreviewImage === selectedSizes.length - 1 ?
                                                    () => setActivePreviewImage(0)
                                                    :
                                                    () => setActivePreviewImage(prevStep => prevStep + 1)
                                                }
                                            />
                                        }
                                    </>
                                    :
                                    <div className="si-footer-info">
                                        <p className="si-footer-title">Loading image preview...</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-btn-row rig-modal-btn-row">
                            <button 
                                className=
                                    {`btn modal-form-btn secondary-btn secondary-btn-rounded 
                                    ${activeStep === 0 ? 'hidden-btn' : ''}
                                    `}
                                onClick={() => setActiveStep(prevStep => prevStep - 1)}
                                disabled={activeStep === 0}
                            >
                                    Back
                            </button>
                            <div className="rig-footer-stepper">
                                <div
                                    className=
                                        {`rig-stepper-step 
                                        ${activeStep === 0 ? 'rig-stepper-active-step' : ''} 
                                        ${activeStep > 0 ? 'rig-stepper-completed-step' : ''}
                                        `}
                                    onClick={() => setActiveStep(0)}
                                >
                                    <p>
                                            1
                                    </p>
                                    <div className="review-card-btn-tooltip">
                                        <span className="rc-btn-tooltip-desc">
                                                Review content
                                        </span>
                                    </div>
                                </div>
                                <div className=
                                    {`rig-stepper-connector 
                                    ${activeStep > 0 ? 'rig-stepper-active-connector' : ''} 
                                    ${activeStep === 0 && !selectedIndexes?.length ? 'rig-stepper-disabled-connector' : ''}
                                    `} 
                                />
                                <div
                                    className=
                                        {`rig-stepper-step 
                                        ${activeStep === 1 ? 'rig-stepper-active-step' : ''} 
                                        ${activeStep > 1 ? 'rig-stepper-completed-step' : ''} 
                                        ${activeStep === 0 && !selectedIndexes?.length ? 'rig-stepper-disabled-step' : ''}
                                        `}
                                    onClick={activeStep === 0 && !selectedIndexes?.length ? null : () => setActiveStep(1)}
                                >
                                    <p>
                                            2
                                    </p>
                                    <div className="review-card-btn-tooltip">
                                        <span className="rc-btn-tooltip-desc">
                                                Sizes
                                        </span>
                                    </div>
                                </div>
                                <div className=
                                    {`rig-stepper-connector 
                                    ${activeStep > 1 ? 'rig-stepper-active-connector' : ''} 
                                    ${activeStep === 0 && !selectedIndexes?.length ? 'rig-stepper-disabled-connector' : ''}
                                    `}
                                />
                                <div
                                    className=
                                        {`rig-stepper-step 
                                        ${activeStep === 2 ? 'rig-stepper-active-step' : ''} 
                                        ${activeStep > 2 ? 'rig-stepper-completed-step' : ''} 
                                        ${activeStep === 0 && !selectedIndexes?.length ? 'rig-stepper-disabled-step' : ''}
                                        `}
                                    onClick={activeStep === 0 && !selectedIndexes?.length ? null : () => setActiveStep(2)}
                                >
                                    <p>
                                            3
                                    </p>
                                    <div className="review-card-btn-tooltip">
                                        <span className="rc-btn-tooltip-desc">
                                                Style
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {loadingButton ? (
                                <div
                                    role="button"
                                    className={
                                        'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                    }
                                >
                                    <img
                                        className="subscription-btn-loader"
                                        src={SmallLoader}
                                        alt="loader"
                                    />
                                </div>
                            ) : (
                                <button
                                    className=
                                        {`btn primary-btn modal-form-btn 
                                    ${((activeStep === 0 && !selectedIndexes?.length) || loadingButton) ? 
                                            'disabled-primary-btn' : ''}
                                    `}
                                    onClick={() => {
                                        if (activeStep === 2 ) {
                                            return downloadImages();
                                        }
                                        setActiveStep(prevStep => prevStep + 1);
                                    }}
                                    disabled={activeStep === 0 && !selectedIndexes?.length}
                                >
                                    {activeStep === 2 ? 'Download' : 'Next'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-padding" />
        </div>
    );
};

export default ReviewImageGenerator;

ReviewImageGenerator.propTypes = {
    review: PropTypes.shape({
        sentences: PropTypes.arrayOf(PropTypes.string)
    })
};