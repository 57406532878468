import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);

function RatingBreakdown ({ ratingData = [] }) {
    const data = {
        labels: ['Excellent', 'Great', 'Average', 'Poor', 'Bad'],
        datasets: [
            {
                label: 'Rating Breakdown',
                data: ratingData,
                backgroundColor: [
                    'rgb(0, 153, 51)',
                    'rgb(51, 204, 0)',
                    'rgb(255, 232, 23)',
                    'rgb(231, 165, 9)',
                    'rgb(212, 38, 15)'
                ],
                hoverBackgroundColor: [
                    'rgb(0, 153, 51)',
                    'rgb(51, 204, 0)',
                    'rgb(255, 232, 23)',
                    'rgb(231, 165, 9)',
                    'rgb(212, 38, 15)'
                ],
            }
        ],
    };

    const options = {
        title: {
            display: false,
            text: 'Rating Breakdown',
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        cutoutPercentage: 0,
        tooltips: {
            callbacks: {
                label (tooltipItems, data) {
                    return ` ${data.datasets[0].data[tooltipItems.index]} ${data.labels[tooltipItems.index]} reviews`;
                },
            },
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        }
    };

    return <Doughnut data={data} options={options} />;
}

RatingBreakdown.propTypes = {
    ratingData: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default RatingBreakdown;
