import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import AppContext from '../app/AppContext';
import SuccessMessage from '../helpers/successMessage';
import ErrorMessage from '../helpers/errorMessage';
import SmallLoader from '../../images/small_loader.gif';
import { localiseText } from '../../utils';

class ContactUs extends React.Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            organisation: '',
            name: '',
            email: '',
            contact_number: '',
            currentSuccessMessage: '',
            currentErrorMessage: '',
            readyForSubmission: false,
        };
    }

    componentDidMount () {
        this.setState({
            organisation: this.context.selectedOrganisation.name,
            name: `${this.context.user.first_name} ${this.context.user.last_name}`,
            email: this.context.user.email,
        });

    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };
    
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        }, () => {
            const { name, email, organisation, contact_number } = this.state;
            if (name && email && organisation && contact_number) {
                this.setState({
                    readyForSubmission: true
                });
            }
            else {
                this.setState({
                    readyForSubmission: false
                });
            }
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.state.loadingButton) return;

        this.setState({
            loadingButton: true
        }, () => {
            const { name, email, organisation, contact_number } = this.state;

            if (!name || !email || !organisation || !contact_number) {
                this.setState({
                    loadingButton: false
                }, () => this.displayErrorMessage('Ensure all fields are completed.'));
                return;
            }

            // eslint-disable-next-line no-useless-escape
            if (!contact_number.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im )) {
                this.setState({
                    loadingButton: false
                }, () => this.displayErrorMessage('Phone number is invalid.'));
                return;
            }

            const token = this.context.token
                ? this.context.token
                : localStorage.getItem('token');
            const headers = {
                Authorization: `Token ${token}`,
            };

            // eslint-disable-next-line max-len
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/subscriptions/contact_us/`;

            axios
                .post(apiCallURL, { name, organisation, email, contact_number }, { headers })
                .then(() => {
                    this.setState(
                        {
                            loadingButton: false,
                            readyForSubmission: false,
                        },
                        () => {
                            this.displaySuccessMessage('Thank you for your message, we will contact you as soon as possible.');
                        }
                    );
                    // setTimeout(() => {
                    //     this.props.onClose();
                    //     this.props.closeSizeTierModal();
                    // }, 2000);
                });
        });
    }

    render () {
        const { currentSuccessMessage, currentErrorMessage, loadingButton, readyForSubmission } = this.state;
        return (
            <div className="modal-backdrop">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                )}
                {currentErrorMessage && (
                    <ErrorMessage message={currentErrorMessage} resetErrorMessage={this.resetErrorMessage} />
                )}

                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button className="modal-close-btn" onClick={this.props.onClose}>
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title">Contact us</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="contact-modal-section">
                                    <p className="modal-section-header">Contact us for your custom package</p>
                                    <p className="modal-section-subheader">
                                        {'Our bespoke pricing is tailored to suit you and your events. ' +
                                            'Complete the form below and we\'ll be in contact ' +
                                            'as soon as possible to discuss the options with you'}
                                    </p>
                       
                                    <form>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="account-details-first-name" className="modal-input-label">
                                                    {localiseText('Organisation')}/company name{' '}
                                                    <span className="required-field">*</span>
                                                </label>
                                                <input
                                                    value={this.state.organisation}
                                                    id="organisation-name"
                                                    onChange={this.handleChange}
                                                    className="input modal-input"
                                                    name="organisation"
                                                    type="text"
                                                />
                                            </div>
            
                                        </div>
                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="account-details-first-name" className="modal-input-label">
                                                Your name{' '}<span className="required-field">*</span>
                                                </label>
                                                <input
                                                    value={this.state.name}
                                                    id="organisation-name"
                                                    onChange={this.handleChange}
                                                    className="input modal-input"
                                                    name="name"
                                                    type="text"
                                                />
                                            </div>
            
                                        </div>

                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="account-details-first-name" className="modal-input-label">
                                                Email{' '}<span className="required-field">*</span>
                                                </label>
                                                <input
                                                    value={this.state.email}
                                                    id="organisation-name"
                                                    onChange={this.handleChange}
                                                    className="input modal-input"
                                                    name="email"
                                                    type="text"
                                                />
                                            </div>
            
                                        </div>

                                        <div className="modal-input-row">
                                            <div className="modal-input-group">
                                                <label htmlFor="account-details-first-name" className="modal-input-label">
                                                Contact number{' '}<span className="required-field">*</span>
                                                </label>
                                                <input
                                                    value={this.state.contact_number}
                                                    id="organisation-name"
                                                    onChange={this.handleChange}
                                                    className="input modal-input"
                                                    name="contact_number"
                                                    type="tel"
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-btn-row sm-modal-btn-row">
                                    <div
                                        role="button"
                                        className={'btn secondary-btn secondary-btn-rounded modal-form-btn'}
                                        onClick={() => this.props.onClose()}
                                    >
                                        Cancel
                                    </div>
                                    {loadingButton ? (
                                        <div
                                            role="button"
                                            className={
                                                'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                            }
                                        >
                                            <img className="subscription-btn-loader" src={SmallLoader} alt="userprofile" />
                                        </div>
                                    ) : (
                                        <div
                                            role="button"
                                            className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                readyForSubmission ? '' : 'disabled-primary-btn'
                                            }`}
                                            onClick={readyForSubmission && this.handleSubmit}
                                        >
                                            Send message
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

export default ContactUs;

ContactUs.propTypes = {
    onClose: PropTypes.func,
    closeSizeTierModal: PropTypes.func
};