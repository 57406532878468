import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import axios from 'axios';

import ErrorMessage from '../helpers/errorMessage';
import SmallLoader from '../../images/small_loader.gif';
import RatingStars from '../helpers/starGenerator';
import SuccessMessage from '../helpers/successMessage';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as AdminIcon } from '../../images/icons/business.svg';
import { ReactComponent as ManagerIcon } from '../../images/icons/id-card.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as AddIcon } from '../../images/icons/plus.svg';
import { ReactComponent as RemoveIcon } from '../../images/icons/remove.svg';
import { ReactComponent as EmptyStar } from '../../images/stars/gold_star_empty.svg';

import AppContext from '../app/AppContext';


const EditTeamMemberModal = (
    {
        toggleModal, 
        memberID, 
        memberName, 
        eventsList, 
        isAdmin,
        assignedEvents, 
        refreshTeamAudit, 
        refreshTeamMembers
    }) => {
    const appContext = useContext(AppContext);
    const [currentSuccessMessage, setCurrentSuccessMessage] = useState('');
    const [currentErrorMessage, setCurrentErrorMessage] = useState('');
    const [loadingButton, setLoadingButton] = useState(false);
    const [selectedRole, setSelectedRole] = useState(isAdmin ? 0 : 1);
    const [unassignedEvents, updateUnassignedEvents] = useState(eventsList);
    const [selectedEvents, setSelectedEvents] = useState(assignedEvents || []);

    const resetSuccessMessage = () => setCurrentSuccessMessage('');
    const resetErrorMessage = () => setCurrentErrorMessage('');

    useEffect(() => {
        if (!isAdmin) {
            const remainingEvents = eventsList.filter(
                (objFromA) => !assignedEvents.find((objFromB) => objFromA.id === objFromB.id)
            );
            updateUnassignedEvents(remainingEvents);
        }
    }, [isAdmin, assignedEvents, eventsList]);

    const handleEventAssign = (event) => {
        updateUnassignedEvents(unassignedEvents.filter(item => item.id !== event.id));
        setSelectedEvents([event, ...selectedEvents]);
    };

    const handleEventUnassign = (event) => {
        setSelectedEvents(selectedEvents.filter(item => item.id !== event.id));
        updateUnassignedEvents([event, ...unassignedEvents]);
    };

    const checkForChange = (a, b) => {
        if (a == null) {
            a = [];
        }
        return JSON.stringify(a.sort()) === JSON.stringify(b.sort());
    };

    const handleEditTeamMemberSubmit = () => {
        setLoadingButton(true);

        let eventIDList = [];
        if (selectedRole === 1) {
            eventIDList = selectedEvents.map((event) => event.id);
        }

        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = appContext.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/change_member_role/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios.post(apiCallURL, {
            events: eventIDList,
            member: memberID
        }, { headers })
            .then(res => {
                setLoadingButton(false);
                setCurrentSuccessMessage('Permissions successfully updated');
                refreshTeamAudit();
                refreshTeamMembers();
            })
            .catch(error => {
                console.log(error);
                setCurrentErrorMessage(error.response.data.message);
            })
            .finally(() => {
                setLoadingButton(false);
            });
    };
    
    return (
        <div className="modal-backdrop">
            {currentSuccessMessage && (
                <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={resetSuccessMessage} />
            )}
            {currentErrorMessage && (
                <ErrorMessage message={currentErrorMessage} resetErrorMessage={resetErrorMessage} />
            )}
            <div className="modal-padding" />
            <div className="modal-container">
                <div className="modal-header">
                    <button className="modal-close-btn" onClick={() => toggleModal(false)}>
                        <CloseIcon />
                    </button>
                    <h2 className="modal-header-title modal-title-no-nav">Edit team member</h2>
                </div>
                <div className="modal-scrollable">
                    <div className="modal-content edit-team-member-modal">
                        <div className="modal-content-section">
                            <div className="tm-section">
                                <p className="tm-section-header">Team member role</p>
                                <div className="tm-role-container">
                                    <div
                                        className={`tm-role ${selectedRole === 0 ? 'tm-role-active' : ''}`}
                                        onClick={() => setSelectedRole(0)}
                                    >
                                        <div className="tm-role-icon">
                                            <AdminIcon />
                                        </div>
                                        <div className="tm-role-copy">
                                            <h3>Organisation Admin</h3>
                                            <p>Able to view and manage all events, team members and settings.</p>
                                        </div>
                                        <div className="tm-role-check">
                                            <TickIcon />
                                        </div>
                                    </div>
                                    <div
                                        className={`tm-role ${selectedRole === 1 ? 'tm-role-active' : ''}`}
                                        onClick={() => setSelectedRole(1)}
                                    >
                                        <div className="tm-role-icon">
                                            <ManagerIcon />
                                        </div>
                                        <div className="tm-role-copy">
                                            <h3>Event Manager</h3>
                                            <p>Only able to view and manage the events they are assigned to.</p>
                                        </div>
                                        <div className="tm-role-check">
                                            <TickIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {selectedRole === 1 &&
                                <div className="tm-section">
                                    <p className="tm-section-header">Assigned events</p>
                                    {selectedEvents?.length ?
                                        <div className="tm-events-list-container">
                                            {selectedEvents.map(event => (
                                                <div className="tm-el-event" key={event.id}>
                                                    <div className="tm-el-event-inner" onClick={() => handleEventUnassign(event)}>
                                                        <div className="event-details">
                                                            {/* <div className="event-img">
                                                        </div> */}
                                                            <div className="event-copy">
                                                                <p className="event-c-title">{event.title}</p>
                                                                <div className="event-c-info">
                                                                    {event.average_rating > 0 ?
                                                                        <>
                                                                            <RatingStars rating={event.average_rating} />
                                                                            <p className="rating-count">({event.reviews})</p>
                                                                        </>
                                                                        :
                                                                        <div className="rating-stars rs-unreviewed">
                                                                            <p className="rating-value">Unreviewed</p>
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                        </div>
                                                                    }
                                                                    {/* <span className="bullet">•</span>
                                                                    <p>1 mananger</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event-btn">
                                                            <RemoveIcon
                                                                className="event-btn-remove"
                                                                onClick={() => handleEventUnassign(event)}
                                                            />
                                                            <div className="tm-eb-tooltip">
                                                                <p>Unassign</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div className="tm-events-list-container tm-elc-empty">
                                            <p>Select one or more events below to assign {memberName} access</p>
                                        </div>
                                    }
                                </div>
                            }
                            {selectedRole === 1 &&
                                <div className="tm-section">
                                    <p className="tm-section-header">Other events</p>
                                    {unassignedEvents?.length ?
                                        <div className="tm-events-list-container">
                                            {unassignedEvents.map(event => (
                                                <div className="tm-el-event" key={event.id}>
                                                    <div className="tm-el-event-inner" onClick={() => handleEventAssign(event)}>
                                                        <div className="event-details">
                                                            {/* <div className="event-img">
                                                            </div> */}
                                                            <div className="event-copy">
                                                                <p className="event-c-title">{event.title}</p>
                                                                <div className="event-c-info">
                                                                    {event.average_rating > 0 ?
                                                                        <>
                                                                            <RatingStars rating={event.average_rating} />
                                                                            <p className="rating-count">({event.reviews})</p>
                                                                        </>
                                                                        :
                                                                        <div className="rating-stars rs-unreviewed">
                                                                            <p className="rating-value">Unreviewed</p>
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                            <EmptyStar />
                                                                        </div>
                                                                    }
                                                                    {/* <span className="bullet">•</span>
                                                                    <p>1 mananger</p> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="event-btn">
                                                            <AddIcon
                                                                className="event-btn-add" 
                                                                onClick={() => handleEventAssign(event)}
                                                            />
                                                            <div className="tm-eb-tooltip">
                                                                <p>Assign</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        <div className="tm-events-list-container tm-elc-empty">
                                            <p>All events have been assigned</p>
                                        </div>
                                    }
                                </div>
                            }
                            <div className="modal-btn-row">
                                {loadingButton ? (
                                    <div
                                        role="button"
                                        className={
                                            'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                        }
                                    >
                                        <img
                                            className="subscription-btn-loader"
                                            src={SmallLoader}
                                            alt="loader"
                                        />
                                    </div>
                                ) : (
                                    <button
                                        className=
                                            {`btn primary-btn modal-form-btn 
                                            ${(loadingButton ||
                                                (isAdmin && selectedRole === 0) ||
                                                (selectedRole === 1 && ((!selectedEvents?.length) || 
                                                (checkForChange(assignedEvents, selectedEvents))))) ? 
                                                'disabled-primary-btn' : ''}
                                            `}
                                        onClick={() => handleEditTeamMemberSubmit()}
                                        disabled={loadingButton ||
                                            (isAdmin && selectedRole === 0) ||
                                            (selectedRole === 1 && ((!selectedEvents?.length) ||
                                                (checkForChange(assignedEvents, selectedEvents))))}
                                    >
                                        Update permissions
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-padding" />
        </div>
    );
};

export default EditTeamMemberModal;

EditTeamMemberModal.propTypes = {
    toggleModal:PropTypes.func.isRequired,
    memberID: PropTypes.number.isRequired,
    memberName: PropTypes.string.isRequired,
    eventsList: PropTypes.array.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    assignedEvents: PropTypes.array.isRequired,
    refreshTeamAudit: PropTypes.func.isRequired,
    refreshTeamMembers: PropTypes.func.isRequired
};