import React from 'react';
import classnames from 'classnames';

import { ReactComponent as FacebookLogo } from '../../images/icons/facebook_logo.svg';
import { ReactComponent as TwitterLogo } from '../../images/icons/twitter_logo.svg';
import { ReactComponent as LinkedInLogo } from '../../images/icons/linkedin_logo.svg';
import { ReactComponent as InstagramLogo } from '../../images/icons/instagram_logo.svg';

const ReviewImageGeneratorSizeOption = ({ option, selectedSizes, setSelectedSizes, setActivePreviewImage }) => (
    <>

        <div className="rig-image-size-list">
            <div
                className={classnames('rig-size-option', { 'rig-so-active': selectedSizes?.includes(option) })}
                onClick={() => {
                    if (!selectedSizes.includes(option)) {
                        return setSelectedSizes(prev => ([...prev, option]));
                    }
                    if (selectedSizes?.length > 1) {
                        setSelectedSizes(prev => ([...prev?.filter(item => item.slug !== option.slug)]));
                        setActivePreviewImage(selectedSizes.length - 2);
                    }
                }}
            >
                <label className="rig-so-label">
                    <input className="smro-check" type="radio" checked={selectedSizes?.includes(option)} />
                    <div className="rig-so-copy">
                        <p className="rig-so-title">
                            { option.apps.includes('facebook') && <FacebookLogo /> }
                            { option.apps.includes('instagram') && <InstagramLogo /> }
                            { option.apps.includes('twitter') && <TwitterLogo /> }
                            { option.apps.includes('linkedin') && <LinkedInLogo /> }
                            {option.text}
                        </p>
                        <p className="rig-so-desc">
                            {option.dimensions}
                            <span className="bullet">•</span>
                            {option.ratio} ratio
                        </p>
                    </div>
                </label>
            </div>
        </div>
    </>
);

export default ReviewImageGeneratorSizeOption;