import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as FullStar } from '../../images/stars/gold_star.svg';
import { ReactComponent as TickIcon } from '../../images/icons/outline_tick.svg';

class AwardProgress extends Component {
    constructor (props) {
        super(props);

        let reviewBarStyle = { width: `${0}%` };
        let ratingBarStyle = { width: `${0}%` };

        if (this.props.awardData) {
            let tempReviewBarValue = this.props.awardData.reviews_progress?.substr(
                0,
                this.props.awardData.reviews_progress.length - 1
            );
            tempReviewBarValue = 100 - tempReviewBarValue;
            if (tempReviewBarValue < 0) {
                reviewBarStyle = {
                    width: '0%',
                };
            }
            else {
                reviewBarStyle = {
                    width: `${tempReviewBarValue}%`,
                };
            }

            let tempRatingPercentage;
            if (this.props.awardData.average_rating === 0) {
                tempRatingPercentage = 0;
            }
            else {
                tempRatingPercentage = Math.floor(
                    (this.props.awardData.average_rating / this.props.awardData.challenge?.rating) * 100
                );
            }
            tempRatingPercentage = 100 - tempRatingPercentage;
            if (tempRatingPercentage < 0) {
                ratingBarStyle = {
                    width: '0%',
                };
            }
            else {
                ratingBarStyle = {
                    width: `${tempRatingPercentage}%`,
                };
            }
        }

        this.state = {
            reviewThreshold: this.props.awardData.reviews_threshold,
            reviewsCount: this.props.awardData.reviews_count,
            awardGoal: this.props.awardData.average_rating_goal,
            reviewBarStyle,
            ratingBarStyle
        };
    }

    render () {
        const {
            reviewThreshold,
            reviewsCount,
            reviewBarStyle,
            ratingBarStyle
        } = this.state;

        const reviewDiff = reviewThreshold - reviewsCount;
        let reviewCountText = '';
        if (reviewDiff > 0) {
            reviewCountText = `${reviewDiff} more reviews and `;
        }
        
        return (
            <div>
                {this.props.awardData.challenge && (
                    <div className="award-progress-container">
                        <p className="award-progess-title">
                            {this.props.awardData?.active_award_year} Racecheck Awards
                            {/* <span className={`apc-award apc-${this.props.awardData.challenge.award}`}>
                                {' '}{this.props.awardData.challenge.award} Award{' '}
                            </span>
                            progress &#39;{this.props.awardData?.year?.toString().slice(-2)} */}
                        </p>
                        {this.props.awardData.challenge.award === 'Top Rated' ? (
                            <p className="award-progress-copy ap-top-rated-copy">
                                Be the highest rated event in your category to qualify for the{' '}
                                {this.props.awardData.active_award_year}{' '}
                                <span className="apc-award">
                                    Racecheck {this.props.awardData.challenge.award} Award
                                </span>
                            </p>
                        ) : (
                            <div>
                                <p className="award-progress-copy">
                                    Get {reviewCountText} an average rating of at least
                                    <span className="apc-rating">
                                        {this.props.awardData.challenge.rating}
                                        <FullStar />
                                    </span>{' '}
                                    in {this.props.awardData.active_award_year} to unlock the&nbsp;
                                    <span className="apc-award">
                                        Racecheck {this.props.awardData.challenge.award} Award
                                    </span>
                                </p>
                                <div className="progress-bar-group">
                                    <div className="progress-bar-label">
                                        <div className="progress-bar-label-group">
                                            <p className="progress-bar-value">
                                                {reviewsCount}
                                            </p>
                                            <p className="progress-bar-percentage">/ {reviewThreshold} reviews</p>
                                            {reviewsCount >= reviewThreshold &&
                                                <TickIcon />
                                            }
                                        </div>
                                    </div>
                                    <div className="progress-bar-container">
                                        <div className="progress-bar-child progress" />
                                        <div className="progress-bar-child shrinker" style={reviewBarStyle} />
                                    </div>
                                </div>

                                <div className="progress-bar-group">
                                    <div className="progress-bar-label">
                                        <div className="progress-bar-label-group">
                                            <p className="progress-bar-value">
                                                {this.props.awardData.average_rating}
                                            </p>
                                            <p className="progress-bar-percentage">
                                                {this.props.awardData.challenge?.rating && 
                                                `/ ${this.props.awardData.challenge?.rating}`} avg. rating
                                            </p>
                                            {this.props.awardData.average_rating >= this.props.awardData.challenge?.rating &&
                                                <TickIcon />
                                            }
                                        </div>
                                    </div>
                                    <div className="progress-bar-container">
                                        <div className="progress-bar-child progress" />
                                        <div className="progress-bar-child shrinker" style={ratingBarStyle} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

AwardProgress.propTypes = {
    awardData: PropTypes.shape({
        challenge: PropTypes.shape({
            award: PropTypes.string,
            rating: PropTypes.number,
        }),
        active_award_year: PropTypes.number,
        reviews_progress: PropTypes.string,
        average_rating: PropTypes.number,
        reviews_threshold: PropTypes.number,
        reviews_count: PropTypes.number,
        year: PropTypes.number,
        average_rating_goal: PropTypes.number,
    }).isRequired,
};

export default AwardProgress;
