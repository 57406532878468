import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AppContext from '../app/AppContext';
import Loader from '../helpers/loader';
import Checkbox from '../helpers/checkbox';
import { mapCurrencyToSign } from '../../utils';
import { ReactComponent as TooltipIcon } from '../../images/icons/help.svg';
import { ReactComponent as DatesIcon } from '../../images/icons/calendar.svg';
import { ReactComponent as WarningIcon } from '../../images/icons/warning-triangle.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as CrossIcon } from '../../images/icons/close.svg';
import { ReactComponent as EditIcon } from '../../images/icons/pencil.svg';
import { ReactComponent as RightChevronIcon } from '../../images/icons/chevron-right.svg';
import { ReactComponent as StarIcon } from '../../images/stars/gold_star.svg';
import OrgDashIllustration from '../../images/organiser-dashboard.png';
import { localiseText } from '../../utils';

const EventsReport = () => {
    const appContext = useContext(AppContext);
    const [analytics, setAnalytics] = useState(null);
    const [analyticsLoading, setAnalyticsLoading] = useState(true);
    const token = appContext.token ? appContext.token : localStorage.getItem('token');
    const { id } = appContext.selectedOrganisation;
    const premium_subscriber = appContext.selectedOrganisation?.is_premium_subscriber;
    const [period, setPeriod] = useState('');
    const [usingEstimates, setUsingEstimates] = useState(1);
    const [averageCost, setAverageCost] = useState('');

    useEffect(() => {
        getAggregatedReviewBoxAnalytics();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, token, usingEstimates, period]);

    const getAggregatedReviewBoxAnalytics = () => {
        const { REACT_APP_API_URL } = process.env;
        // eslint-disable-next-line max-len
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/review_box_aggregated_analytics/?period=${period}&estimates=${usingEstimates}`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                setAnalytics({
                    ...data?.data,
                    events: data?.data?.events?.map(item => (
                        {
                            ...item,
                            review_box_analytics: {
                                ...item.review_box_analytics,
                                average_entry_cost: {
                                    ...item.review_box_analytics.average_entry_cost,
                                    editing: false
                                }
                            }
                        }))
                });
                setPeriod(data?.data.period);
            })
            .catch(error => {
                console.log(error.response);
            })
            .finally(() => {
                setAnalyticsLoading(false);
            });
    };

    const toggleRowEditMode = (eventId) => {
        setAnalytics({
            ...analytics,
            events: analytics.events?.map(item => (
                {
                    ...item,
                    review_box_analytics: {
                        ...item.review_box_analytics,
                        average_entry_cost: {
                            ...item.review_box_analytics.average_entry_cost,
                            editing: item.event.id === eventId ?
                                !item.review_box_analytics.average_entry_cost.editing
                                : item.review_box_analytics.average_entry_cost.editing
                        }
                    }
                }))
        });
    };

    const editEvent = (e, eventId) => {
        e.preventDefault();
        if (!averageCost) return;

        const token = appContext.token ? appContext.token : localStorage.getItem('token');
        const { REACT_APP_API_URL } = process.env;
        const { id } = appContext.selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${eventId}/edit_event/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios
            .put(apiCallURL, {
                average_entry_cost: +averageCost
            }, { headers })
            .then(() => {
                getAggregatedReviewBoxAnalytics();
                setAverageCost('');
            })
            .catch(error => {
                console.log(error.response);
            });
    };

    /* eslint-disable */
    const renderEventRows = () =>
        analytics?.events?.map(item => (
            <tr className="er-t-data-row" key={item.event.id}>
                <td className="er-t-event-name-container">
                    {!item.widget_installed || !item.registration_intent_tracker_installed || !item.registration_completed_tracker_installed || item.review_box_analytics?.average_entry_cost?.is_estimate || item.review_box_analytics?.roi?.is_estimate ?
                        <div className="er-t-event-name">
                            <WarningIcon />
                            <Link to={`/events/${item.event.id}/analytics/widget`}>
                                {item.event.name}
                            </Link>
                            <div className="er-tooltip">
                                <span className="er-tooltip-desc">
                                    This event is missing analytics trackers or data and is using estimates based on industry trends.
                                </span>
                                <div className="er-tooltip-extra-row">
                                    <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                        Learn more and install tracking
                                    </Link>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="er-t-event-name">
                            <Link to={`/events/${item.event.id}/analytics/widget`}>
                                {item.event.name}
                            </Link>
                        </div>
                    }
                </td>
                <td>
                    <div className="er-t-rating-stars">
                        {item.event.average_rating > 0 ?
                            <>
                                <p className="er-t-rs-value">{item.event.average_rating}</p>
                                <StarIcon />
                                <p className="er-t-rs-count">({item.event.reviews})</p>
                            </>
                            :
                            <p className="er-t-rs-value er-t-rs-v-unrated">Unrated</p>
                        }
                    </div>
                </td>
                <td className="er-t-status">
                    {item.widget_installed ? <TickIcon className="er-t-status-tick" /> : <CrossIcon className="er-t-status-cross" />}
                </td>
                <td>
                    <>{item.review_box_analytics?.analytics?.loads?.toLocaleString('en-US') || '-'}</>
                </td>
                <td>
                    <>{item.review_box_analytics?.analytics?.interactions?.toLocaleString('en-US') || '-'}</>
                </td>
                <td>
                    {item.review_box_analytics?.analytics?.interaction_ratio ?
                        <>{(item.review_box_analytics?.analytics?.interaction_ratio * 100).toFixed(2)}%</>
                        :
                        <>-</>
                    }
                </td>
                <td className="er-t-status">
                    {item.registration_intent_tracker_installed ? <TickIcon className="er-t-status-tick" /> : <CrossIcon className="er-t-status-cross" />}
                </td>
                <td className={`${!item.registration_intent_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.registration_intents ? 'er-t-estimate-cell' : ''}`}>
                    <>{item.review_box_analytics?.analytics?.registration_intents?.toLocaleString('en-US') || '-'}</>
                    {!!(!item.registration_intent_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.racecheck_registration_intents) &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Total Intent to Book clicks estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
                <td className={`${!item.registration_intent_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.racecheck_registration_intents ? 'er-t-estimate-cell' : ''}`}>
                    <>{item.review_box_analytics?.analytics?.racecheck_registration_intents?.toLocaleString('en-US') || '-'}</>
                    {!!(!item.registration_intent_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.racecheck_registration_intents) &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Racecheck influenced Intent to Book clicks estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
                <td className={`${!item.registration_intent_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.registration_intent_ratio ? 'er-t-estimate-cell' : ''}`}>
                    {item.review_box_analytics?.analytics?.registration_intent_ratio !== null ?
                        <>{(item.review_box_analytics?.analytics?.registration_intent_ratio * 100).toFixed(2)}%</>
                        :
                        <>-</>
                    }
                    {!!(!item.registration_intent_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.registration_intent_ratio) &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Intent to Book ratio estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
                <td className="er-t-status">
                    {item.registration_completed_tracker_installed ? <TickIcon className="er-t-status-tick" /> : <CrossIcon className="er-t-status-cross" />}
                </td>
                <td className={`${!item.registration_completed_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.registrations_completed ? 'er-t-estimate-cell' : ''}`}>
                    <>{item.review_box_analytics?.analytics?.registrations_completed?.toLocaleString('en-US') || '-'}</>
                    {!!(!item.registration_completed_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.registrations_completed) &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Total completed sales estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
                <td className={`${!item.registration_completed_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.racecheck_registrations_completed ? 'er-t-estimate-cell' : ''}`}>
                    <>{item.review_box_analytics?.analytics?.racecheck_registrations_completed?.toLocaleString('en-US') || '-'}</>
                    {!!(!item.registration_completed_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.racecheck_registrations_completed) &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Racecheck influenced completed sales estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
                <td className={`${!item.registration_completed_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.registration_completed_ratio ? 'er-t-estimate-cell' : ''}`}>
                    {item.review_box_analytics?.analytics?.registration_completed_ratio !== null ?
                        <>{(item.review_box_analytics?.analytics?.registration_completed_ratio * 100).toFixed(2)}%</>
                        :
                        <>-</>
                    }
                    {!!(!item.registration_completed_tracker_installed && usingEstimates && item.review_box_analytics?.analytics?.registration_completed_ratio) &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Completed sales ratio estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
                <td className={`${item.review_box_analytics?.average_entry_cost?.is_estimate && usingEstimates ? 'er-t-estimate-cell' : ''} er-t-cost-cell`}>
                    {!item.review_box_analytics?.average_entry_cost?.editing ?
                        <>
                            {item.review_box_analytics?.average_entry_cost?.is_estimate && !usingEstimates ?
                                <>-</>
                                :
                                <>{mapCurrencyToSign[item.review_box_analytics?.average_entry_cost?.currency]}{item.review_box_analytics?.average_entry_cost?.amount}</>
                            }
                            <span onClick={() => toggleRowEditMode(item?.event?.id)} className="er-t-cost-edit">
                                <EditIcon />
                            </span>
                            {!!(item.review_box_analytics?.average_entry_cost?.is_estimate && usingEstimates) &&
                                <div className="er-tooltip">
                                    <span className="er-tooltip-desc">
                                        Estimate based on an industry average entry cost of £30.
                                    </span>
                                    <div className="er-tooltip-extra-row">
                                        <Link onClick={() => toggleRowEditMode(item?.event?.id)} className="er-tooltip-desc">
                                            Add your event avg. entry cost
                                        </Link>
                                    </div>
                                </div>
                            }
                        </>
                    :
                        <div>
                            <form onSubmit={(e) => editEvent(e, item.event?.id)} className="roi-input-row er-t-input-row">
                                <span className="er-t-cost-currency">
                                    {mapCurrencyToSign[item.review_box_analytics?.average_entry_cost?.currency]}
                                </span>
                                <input
                                    className="input er-t-cost-input"
                                    type="number"
                                    value={averageCost}
                                    onChange={e => setAverageCost(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className={`btn primary-btn er-t-cost-btn
                                                ${!averageCost ? 'disabled-primary-btn' : ''}`}
                                >
                                    <RightChevronIcon />
                                </button>
                            </form>
                            <div className="er-tooltip er-cost-tooltip">
                                <span className="er-tooltip-desc">
                                    Enter, on average, how much it costs to enter the event.
                                </span>
                                <div className="er-tooltip-extra-row">
                                    <Link onClick={() => toggleRowEditMode(item?.event?.id)} className="er-tooltip-desc">
                                        Cancel
                                    </Link>
                                    <Link
                                        onClick={(e) => editEvent(e, item.event?.id)}
                                        className={`er-tooltip-desc er-tooltip-desc-btn ${!averageCost ? 'er-tooltip-desc-btn-disabled' : ''}`}>
                                        Submit
                                    </Link>
                                </div>
                            </div>
                        </div>
                    }
                </td>
                <td className={`${item.review_box_analytics?.roi?.is_estimate && usingEstimates && item.review_box_analytics?.roi?.revenue?.amount ? 'er-t-estimate-cell' : ''}`}>
                    {(item.review_box_analytics?.roi?.is_estimate && !usingEstimates) || item.review_box_analytics?.roi?.revenue?.amount === null ?
                        <>-</>
                        :
                        <>{mapCurrencyToSign[item.review_box_analytics?.roi?.revenue?.currency]}{item.review_box_analytics?.roi?.revenue?.amount.toLocaleString('en-US')}</>
                    }
                    {!!(item.review_box_analytics?.roi?.is_estimate && usingEstimates && item.review_box_analytics?.roi?.revenue?.amount) &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Direct revenue estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
                <td className={`${item.review_box_analytics?.roi?.is_estimate && usingEstimates && item.review_box_analytics?.roi?.ratio ? 'er-t-estimate-cell' : ''}`}>
                    {(item.review_box_analytics?.roi?.is_estimate && !usingEstimates) || item.review_box_analytics?.roi?.ratio === null ?
                        <>-</>
                        :
                        <>{item.review_box_analytics?.roi?.ratio?.toFixed(1)}x</>
                    }
                    {!!(item.review_box_analytics?.roi?.is_estimate && usingEstimates && item.review_box_analytics?.roi?.ratio) &&
                        <div className="er-tooltip er-tooltip-far-right">
                            <span className="er-tooltip-desc">
                                Return on Investment estimated based on industry trends.
                            </span>
                            <div className="er-tooltip-extra-row">
                                <Link to={`/events/${item.event.id}/widgets/review-box`} className="er-tooltip-desc">
                                    Learn more and install tracking
                                </Link>
                            </div>
                        </div>
                    }
                </td>
            </tr>
        )
        );

    const renderTotalsRow = () => (
        <tr className="er-t-totals-row" key={analytics?.totals.sum_total_reviews}>
            <td className="er-t-event-name-container">
                <div className="er-t-event-name">
                    {!!usingEstimates &&
                        <WarningIcon />
                    }
                    <p>All events</p>
                    {!!usingEstimates &&
                        <div className="er-tooltip">
                            <span className="er-tooltip-desc">
                                Totals calculated using industry estimates from some of your events.
                            </span>
                        </div>
                    }
                </div>
            </td>
            <td>
                <div className="er-t-rating-stars">
                    {analytics?.totals.avg_average_rating > 0 ?
                        <>
                            <p className="er-t-rs-value">{analytics?.totals.avg_average_rating}</p>
                            <StarIcon />
                            <p className="er-t-rs-count">({analytics?.totals.sum_total_reviews})</p>
                        </>
                        :
                        <p className="er-t-rs-value er-t-rs-v-unrated">Unrated</p>
                    }
                </div>
            </td>
            <td className="er-t-status"><span>-</span></td>
            <td>
                <>{analytics?.totals?.sum_loads?.toLocaleString('en-US') || '-'}</>
            </td>
            <td>
                <>{analytics?.totals?.sum_interactions?.toLocaleString('en-US') || '-'}</>
            </td>
            <td>
                {analytics?.totals?.avg_interaction_ratio ?
                    <>{(analytics?.totals?.avg_interaction_ratio * 100).toFixed(2)}%</>
                    :
                    <>-</>
                }
            </td>
            <td className="er-t-status"><span>-</span></td>
            <td>
                {analytics?.totals.sum_registration_intents ?
                    <>{analytics?.totals.sum_registration_intents.toLocaleString('en-US')}</>
                    :
                    <>-</>
                }
            </td>
            <td>
                {analytics?.totals.sum_racecheck_registration_intents ?
                    <>{analytics?.totals.sum_racecheck_registration_intents.toLocaleString('en-US')}</>
                    :
                    <>-</>
                }
            </td>
            <td>
                {analytics?.totals?.avg_registration_intent_ratio ?
                    <>{(analytics?.totals.avg_registration_intent_ratio * 100).toFixed(2)}%</>
                    :
                    <>-</>
                }
            </td>
            <td className="er-t-status"><span>-</span></td>
            <td>
                {analytics?.totals.sum_registrations_completed ?
                    <>{analytics?.totals.sum_registrations_completed.toLocaleString('en-US')}</>
                    :
                    <>-</>
                }
            </td>
            <td>
                {analytics?.totals.sum_racecheck_registrations_completed ?
                    <>{analytics?.totals.sum_racecheck_registrations_completed.toLocaleString('en-US')}</>
                    :
                    <>-</>
                }
            </td>
            <td>
                {analytics?.totals?.avg_registration_completed_ratio ?
                    <>{(analytics?.totals.avg_registration_completed_ratio * 100).toFixed(2)}%</>
                    :
                    <>-</>
                }
            </td>
            <td className={`${analytics?.totals.avg_average_entry_cost?.is_estimate ? 'er-t-estimate-cell' : ''}`}>
                {mapCurrencyToSign[analytics?.totals.avg_average_entry_cost?.currency]}{analytics?.totals.avg_average_entry_cost?.amount}
            </td>
            <td className={`${analytics?.totals.roi?.is_estimate ? 'er-t-estimate-cell' : ''}`}>
                {analytics?.totals.roi?.revenue?.amount ?
                    <>{mapCurrencyToSign[analytics?.totals.roi?.revenue?.currency]}{analytics?.totals.roi?.revenue?.amount.toLocaleString('en-US')}</>
                    :
                    <>-</>
                }
            </td>
            <td>
                {analytics?.totals.roi?.ratio !== null ?
                    <>{analytics?.totals.roi?.ratio?.toFixed(1)}x</>
                    :
                    <>-</>
                }
            </td>
        </tr>
    );
    /* eslint-enable */

    if (analyticsLoading) return (
        <div className="section-loading-container">
            <Loader />
        </div>
    );

    if (analytics && !analytics?.events?.length) return (
        <div className="analytics-placeholder-container">
            <img src={OrgDashIllustration} alt="Illustration of Racecheck dashboard" />
            <h2>Add your first event to start tracking</h2>
            <p>
                Analytics reports will become available once your organisation has added or claimed 
                their first event and started to make use of the Racecheck Review Box.
            </p>
            <Link to="/events/events" className="btn primary-btn">
                Get started now
            </Link>
        </div>
    );

    return (
        <>
            {premium_subscriber ?
                <>
                    <div className="analytics-events-report-table-container">
                        <div className="er-table-header">
                            <div className="er-t-h-title">
                                <h2>
                                    {appContext?.selectedOrganisation?.name}
                                </h2>
                                <p>
                                    {analytics?.events[0].review_box_analytics.period.period_start}
                                    &nbsp;-&nbsp;
                                    {analytics?.events[0].review_box_analytics.period.period_end}
                                </p>
                            </div>
                            <div className="er-t-h-btns">
                                <div className="er-t-h-checkbox">
                                    <Checkbox
                                        id="er-estimates"
                                        label=""
                                        isSelected={usingEstimates === 1}
                                        onCheckboxChange={() => setUsingEstimates(usingEstimates === 1 ? 0 : 1)}
                                        value={usingEstimates === 1}
                                    />
                                    <p>
                                        Use estimates <TooltipIcon />
                                        <div className="er-tooltip">
                                            <span className="er-tooltip-desc">
                                            Using industry trends Racecheck can estimate values for your events
                                                which don’t have trackers installed.
                                            </span>
                                            {/* {!analytics?.analytics?.loads &&
                                            <div className="er-tooltip-extra-row">
                                                <WarningIcon className="er-icon-warning" />
                                                <span className="er-tooltip-desc">
                                                    No data recorded in this period.
                                                </span>
                                            </div>
                                            } */}
                                        </div>
                                    </p>
                                </div>
                                <div className="er-t-h-select-container">
                                    <DatesIcon />
                                    <select
                                        className="er-t-h-btn"
                                        name="period"
                                        onChange={(e) => setPeriod(e.target.value)}
                                        value={period}
                                        required
                                    >
                                        <option value={'30'}>Last 30 days</option>
                                        <option value={'60'}>Last 60 days</option>
                                        <option value={'90'}>Last 90 days</option>
                                        <option value={'180'}>Last 180 days</option>
                                        <option value={'360'}>Last 360 days</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <table className="er-table">
                            <tr className="er-t-main-header">
                                <th colSpan={2}>Event</th>
                                <th colSpan={4}>Review Box</th>
                                <th colSpan={4}>Intent to Book</th>
                                <th colSpan={4}>Completed Sales</th>
                                <th colSpan={3}>Investment & Revenue</th>
                            </tr>
                            <tr className="er-t-sub-header">
                                <td className="er-t-sh-cell">
                                    Name
                                    <div className="er-tooltip er-tooltip-far-left">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Event name
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The name of your event.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Rating
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Event rating
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The average rating for your event.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Ins.
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Installation status
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                Whether or not the Review Box is installed for this event.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Impressions
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Review Box Impressions
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The number of times the Review Box has been loaded
                                                and displayed on your event website.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Interactions
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Review Box Interactions
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The number of times a website visitor has either moused over
                                                or clicked on the Review Box.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Ratio
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Interaction Ratio
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The percentage of website visitors (impressions) who went on to interact
                                                with your Review Box.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Ins.
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Intent to Book tracking installation status
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                Whether or not tracking for Intent to Book clicks is installed for this event.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Total clicks
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Total &apos;Book Now&apos; clicks
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The total number of website visitors who clicked on
                                                one of your registration links.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Racec. influ.
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Racecheck influenced &apos;Book Now&apos; clicks
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The number of website visitors who clicked on a registration link
                                                after interacting with the Review Box.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Ratio
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Intent to Book Ratio
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The percentage of &apos;book now&apos; button clicks
                                                that were influenced by Racecheck.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Ins.
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Completed Sales tracking installation status
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                Whether or not tracking for Completes Sales is installed for this event.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Total sales
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Total completed sales/entries
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The total number of website visitors who completed the sales process.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Racec. influ.
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Racecheck influenced completed sales/entries
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The number of people who completed the sales process
                                                following an interaction with the Review Box.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Ratio
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Completed sales/entries ratio
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                The percentage of completed sales that were influenced by Racecheck.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Avg. entry
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Average entry cost
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                How much it costs a participant to enter the event.
                                                For events with multiple entry options the avg. should be used.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    Direct revenue
                                    <div className="er-tooltip">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Direct revenue
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                Based on the tracked number of completed entries and avg. entry cost,
                                                the event Review Box has helped generate this respective revenue.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td className="er-t-sh-cell">
                                    R.O.I.
                                    <div className="er-tooltip er-tooltip-far-right">
                                        <span className="er-tooltip-desc er-tooltip-desc-b">
                                            Return on Investment
                                        </span>
                                        <div className="er-tooltip-extra-row">
                                            <span className="er-tooltip-desc">
                                                Using your monthly Premium subscription cost,
                                                this is the estimated return on investment for your Review Box.
                                            </span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {renderEventRows()}
                            <tr>
                                <td colSpan={17} />
                            </tr>
                            {renderTotalsRow()}
                            <tr>
                                <td colSpan={17} />
                            </tr>
                        </table>
                    </div>
                    <div className="analytics-events-report-key">
                        <div className="er-k-img">
                            <span>est.</span>
                        </div>
                        <div className="er-k-copy">
                            <p>Estimated values based on industry trends</p>
                            <p>Install relevant event trackers for more accurate and personalised data.</p>
                        </div>
                    </div>
                </>
                :
                <div className="analytics-placeholder-container">
                    <img src={OrgDashIllustration} alt="Illustration of Racecheck dashboard" />
                    <h2>Upgrade to Racecheck Premium to access</h2>
                    <p>Analytics reports are currently only available to Racecheck Premium {localiseText('organisers')}.
                    Upgrade your account to deep dive into your analytics and quickly
                    and easily understand how Racecheck can bring value to your events.
                    </p>
                    <Link to="/subscription/subscription-plan" className="btn primary-btn">
                    Get started for free
                    </Link>
                </div>
            }
        </>
    );
};

export default EventsReport;