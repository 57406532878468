import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classnames from 'classnames';
import './Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import SuccessMessage from '../helpers/successMessage';
import ErrorMessage from '../helpers/errorMessage';
import SmallLoader from '../../images/small_loader.gif';
import AppContext from '../app/AppContext';
import OrganisationSettingsTab from './OrganisationSettingsTab';
import NotificationsTab from './NotificationsTab';
import { localiseText } from '../../utils';

class Modal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            currentSuccessMessage: '',
            currentErrorMessage: '',
            image: null,
            imageUrl: '',
            firstName: '',
            lastName: '',
            email: '',
            currentPassword: '',
            password: '',
            passwordConfirmation: '',
            orgRole: '',
            passwordSection: false,
            accountDetailsReadyForSubmission: false,
            passwordReadyForSubmission: false,
            currentFirstName: '',
            currentLastName: '',
            currentEmail: '',
            currentRole: '',
            loadingProfileImage: true,
            accountDetailsEdited: false,
            activeModalSection: 'account'
        };
    }

    displaySuccessMessage = message => {
        this.setState({
            currentSuccessMessage: message,
        });
    };

    resetSuccessMessage = () => {
        this.setState({
            currentSuccessMessage: '',
        });
    };

    displayErrorMessage = message => {
        this.setState({
            currentErrorMessage: message,
        });
    };

    resetErrorMessage = () => {
        this.setState({
            currentErrorMessage: '',
        });
    };

    componentDidMount () {
        this.setState({
            firstName: this.context.user.first_name,
            lastName: this.context.user.last_name,
            email: this.context.user.email,
            orgRole: this.context.selectedOrganisation.role,
            imageUrl: this.context.user.avatar,
            currentFirstName: this.context.user.first_name,
            currentLastName: this.context.user.last_name,
            currentEmail: this.context.user.email,
            currentRole: this.context.selectedOrganisation.role,
            loadingProfileImage: false,
        });
    }

    togglePasswordSection = () => {
        const { passwordSection } = this.state;
        passwordSection
            ? this.setState({
                passwordSection: false,
                currentPassword: '',
                password: '',
                passwordConfirmation: '',
                passwordReadyForSubmission: false,
            })
            : this.setState({
                passwordSection: true,
                currentPassword: '',
                password: '',
                passwordConfirmation: '',
                passwordReadyForSubmission: false,
            });
    };

    handleImageChange = e => {
        this.setState(
            {
                image: e.target.files[0],
                loadingProfileImage: true,
            },
            () => {
                const form_data = new FormData();
                form_data.append('avatar', this.state.image, this.state.image.name);
                const token = this.context.token ? this.context.token : localStorage.getItem('token');
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/edit_profile_avatar/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };
                axios
                    .post(apiCallURL, form_data, {
                        headers,
                    })
                    .then(res => {
                        this.setState(
                            {
                                image: null,
                                imageUrl: res.data.avatar,
                                loadingProfileImage: false,
                            },
                            () => {
                                this.context.setUser({
                                    ...this.context.user,
                                    avatar: res.data.avatar,
                                });
                                this.displaySuccessMessage('Your profile picture has been updated.');
                            }
                        );
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        );
    };

    handleAccountDetailsChange = e => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                accountDetailsReadyForSubmission: false,
                accountDetailsEdited: true,
            },
            () => {
                if (this.state.accountDetailsReadyForSubmission === false) {
                    if (
                        this.state.firstName &&
                        this.state.lastName &&
                        this.state.email &&
                        (this.state.firstName !== this.state.currentFirstName ||
                            this.state.lastName !== this.state.currentLastName ||
                            this.state.email !== this.state.currentEmail)
                            // this.state.orgRole !== this.state.currentRole)
                    ) {
                        this.setState({
                            accountDetailsReadyForSubmission: true,
                        });
                    }
                }
                else if (
                    !this.state.firstName ||
                    !this.state.lastName ||
                    !this.state.email ||
                    this.state.firstName === this.state.currentFirstName ||
                    this.state.lastName === this.state.currentLastName ||
                    this.state.email === this.state.currentEmail
                    // this.state.orgRole !== this.state.currentRole
                ) {
                    this.setState({
                        accountDetailsReadyForSubmission: false,
                    });
                }
            }
        );
    };

    handleAccountDetailsSubmit = e => {
        if (this.state.accountDetailsReadyForSubmission) {
            e.preventDefault();
            const token = this.context.token ? this.context.token : localStorage.getItem('token');
            const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/user/${this.context.user.id}/`;
            const headers = {
                Authorization: `Token ${token}`,
            };
            const newAccountData = {};
            if (this.state.firstName !== this.state.currentFirstName) {
                newAccountData.first_name = this.state.firstName;
            }
            if (this.state.lastName !== this.state.currentLastName) {
                newAccountData.last_name = this.state.lastName;
            }
            if (this.state.email !== this.state.currentEmail) {
                newAccountData.email = this.state.email;
            }
            // if (this.state.orgRole !== this.state.currentRole) {
            //     newAccountData.role = this.state.orgRole;
            // }
            axios
                .put(apiCallURL, newAccountData, {
                    headers,
                })
                .then(res => {
                    this.context.setUser(res.data);
                    this.setState(
                        {
                            currentFirstName: res.data.first_name,
                            currentLastName: res.data.last_name,
                            currentEmail: res.data.email,
                            // currentRole: res.data.role,
                            accountDetailsReadyForSubmission: false,
                        },
                        () => {
                            this.displaySuccessMessage('Your account details have been updated.');
                        }
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            console.log('Add event form validation');
        }
    };

    handlePasswordChange = e => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                if (this.state.passwordReadyForSubmission === false) {
                    if (this.state.currentPassword && this.state.password && this.state.passwordConfirmation) {
                        this.setState({
                            passwordReadyForSubmission: true,
                        });
                    }
                }
                else if (!this.state.currentPassword || !this.state.password || !this.state.passwordConfirmation) {
                    this.setState({
                        passwordReadyForSubmission: false,
                    });
                }
            }
        );
    };

    handlePasswordSubmit = e => {
        if (this.state.passwordReadyForSubmission) {
            if (this.state.password === this.state.passwordConfirmation) {
                e.preventDefault();
                const token = this.context.token ? this.context.token : localStorage.getItem('token');
                const apiCallURL = `${process.env.REACT_APP_API_URL}/api/accounts/auth/user/${this.context.user.id}/`;
                const headers = {
                    Authorization: `Token ${token}`,
                };
                axios
                    .put(
                        apiCallURL,
                        {
                            current_password: this.state.currentPassword,
                            password: this.state.password,
                        },
                        { headers }
                    )
                    .then(() => {
                        this.setState(
                            {
                                currentPassword: '',
                                password: '',
                                passwordConfirmation: '',
                                passwordReadyForSubmission: false,
                            },
                            () => {
                                this.displaySuccessMessage('Your password has been changed.');
                                this.togglePasswordSection();
                            }
                        );
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                console.log('Mismatch passwords');
            }
        }
        else {
            console.log('Add password form validation');
        }
    };

    handleModalSectionChange = e => {
        this.setState({
            activeModalSection: e.target.title,
        });
    };

    render () {
        const {
            currentSuccessMessage,
            currentErrorMessage,
            currentPassword,
            password,
            passwordConfirmation,
            passwordSection,
            accountDetailsReadyForSubmission,
            passwordReadyForSubmission,
            loadingProfileImage,
            currentFirstName,
            firstName,
            currentLastName,
            lastName,
            currentEmail,
            email,
            orgRole,
            currentRole,
            accountDetailsEdited,
            activeModalSection
        } = this.state;
        return (
            <div className="modal-backdrop">
                {currentSuccessMessage && (
                    <SuccessMessage message={currentSuccessMessage} resetSuccessMessage={this.resetSuccessMessage} />
                )}
                {currentErrorMessage && (
                    <ErrorMessage message={currentErrorMessage} resetErrorMessage={this.resetErrorMessage} />
                )}
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title">Settings</h2>
                        <nav className="modal-header-nav">
                            <ul className="modal-nav-list">
                                <li className="modal-nav-item">
                                    <div 
                                        onClick={this.handleModalSectionChange}
                                        className="modal-nav-item-link" title="account"
                                    >
                                        <span 
                                            className={
                                                classnames('modal-link', 
                                                    { 'modal-link-selected': activeModalSection === 'account' })
                                            } 
                                            title="account"
                                        >
                                            Account
                                        </span>
                                    </div>
                                </li>
                                <li className="modal-nav-item">
                                    <div
                                        onClick={this.handleModalSectionChange}
                                        className="modal-nav-item-link" 
                                        title={localiseText('organisation')}
                                    >
                                        <span
                                            className={
                                                classnames('modal-link', 
                                                    { 'modal-link-selected': activeModalSection === 'organisation' })}
                                            title="organisation"
                                        >{localiseText('Organisation')}
                                        </span>
                                    </div>
                                </li>
                                <li className="modal-nav-item">
                                    <div
                                        onClick={this.handleModalSectionChange}
                                        className="modal-nav-item-link" 
                                        title="notification"
                                    >
                                        <span
                                            className={
                                                classnames('modal-link', 
                                                    { 'modal-link-selected': activeModalSection === 'notification' })}
                                            title="notification"
                                        >Notifications
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            {activeModalSection === 'account' && (
                                <>
                                    <div className="modal-content-section">
                                        <h3 className="modal-section-header">Profile</h3>
                                        <div className="modal-content-profile-photo">
                                            <p className="modal-input-label">Your photo</p>
                                            <div className="profile-photo-container">
                                                {loadingProfileImage ? (
                                                    <div className="profile-photo-loader">
                                                        <img
                                                            className="profile-photo-loader-img"
                                                            src={SmallLoader}
                                                            alt="userprofile"
                                                        />
                                                    </div>
                                                ) : (
                                                    <img
                                                        className="profile-pic-preview"
                                                        src={this.context.user.avatar}
                                                        alt="userprofile"
                                                    />
                                                )}
                                                <div className="profile-photo-copy">
                                                    <div>
                                                        <label
                                                            htmlFor="account-profile-image"
                                                            className="modal-subtext modal-content-link"
                                                        >
                                                    Upload a new photo
                                                        </label>
                                                        <input
                                                            type="file"
                                                            onChange={this.handleImageChange}
                                                            name="file"
                                                            id="account-profile-image"
                                                            className="account-profile-image-input"
                                                        />
                                                    </div>
                                                    {/* eslint-disable-next-line*/}
                                            {/* <p className="modal-subtext"><span className="modal-content-link">Upload a new photo</span><span className="bullet"> • </span><span className="modal-content-link">Remove photo</span></p> */}
                                                    <p className="modal-subtext">
                                                A profile photo helps your colleagues {localiseText('recognise')} your account
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <form className="log-in-form">
                                            <div className="modal-input-row">
                                                <div className="modal-input-group-half">
                                                    <label htmlFor="account-details-first-name" className="modal-input-label">
                                                Your first name{' '}
                                                    </label>
                                                    <input
                                                        id="account-details-first-name"
                                                        value={
                                                            currentFirstName !== firstName && accountDetailsEdited
                                                                ? firstName
                                                                : this.context.user.first_name
                                                        }
                                                        onChange={this.handleAccountDetailsChange}
                                                        className="input modal-input"
                                                        name="firstName"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="modal-input-group-half">
                                                    <label htmlFor="account-details-last-name" className="modal-input-label">
                                                Your surname
                                                    </label>
                                                    <input
                                                        id="account-details-last-name"
                                                        value={
                                                            currentLastName !== lastName && accountDetailsEdited
                                                                ? lastName
                                                                : this.context.user.last_name
                                                        }
                                                        onChange={this.handleAccountDetailsChange}
                                                        className="input modal-input"
                                                        name="lastName"
                                                        type="text"
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-input-row">
                                                <div className="modal-input-group-half">
                                                    <label htmlFor="account-details-email" className="modal-input-label">
                                                Your email address
                                                    </label>
                                                    <input
                                                        id="account-details-email"
                                                        value={
                                                            currentEmail !== email && accountDetailsEdited
                                                                ? email
                                                                : this.context.user.email
                                                        }
                                                        onChange={this.handleAccountDetailsChange}
                                                        className="input modal-input"
                                                        name="email"
                                                        type="text"
                                                    />
                                                </div>
                                                <div className="modal-input-group-half">
                                                    <label
                                                        htmlFor="account-details-org-role"
                                                        className="modal-input-label modal-input-label-disabled"
                                                    >
                                                Your job title
                                                    </label>
                                                    <input
                                                        id="account-details-org-role"
                                                        value={
                                                            currentRole !== orgRole && accountDetailsEdited
                                                                ? orgRole
                                                                : this.context.selectedOrganisation.role
                                                        }
                                                        onChange={this.handleAccountDetailsChange}
                                                        className="input modal-input modal-input-disabled"
                                                        name="orgRole"
                                                        type="text"
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="modal-btn-row">
                                                <div
                                                    role="button"
                                                    className={`btn secondary-btn modal-form-btn ${
                                                accountDetailsReadyForSubmission
                                                    ? 'secondary-btn-main-cta'
                                                    : 'disabled-secondary-btn'
                                            }`}
                                                    onClick={this.handleAccountDetailsSubmit}
                                                >
                                            Update profile
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-content-section">
                                        <h3 className="modal-section-header">Password</h3>
                                        {!passwordSection && (
                                            <div className="modal-collapsed-info">
                                                <p className="modal-subtext">Change the password for your account</p>
                                                <p className="modal-subtext">
                                                    <span className="modal-content-link" onClick={this.togglePasswordSection}>
                                                Change password
                                                    </span>
                                                </p>
                                            </div>
                                        )}
                                        {passwordSection && (
                                            <form className="log-in-form">
                                                <div className="modal-input-group">
                                                    <label
                                                        htmlFor="account-details-current-password"
                                                        className="modal-input-label"
                                                    >
                                                Current password <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        id="account-details-current-password"
                                                        value={currentPassword}
                                                        onChange={this.handlePasswordChange}
                                                        className="input modal-input"
                                                        name="currentPassword"
                                                        type="password"
                                                    />
                                                </div>
                                                <div className="modal-input-group">
                                                    <label htmlFor="account-details-new-password" className="modal-input-label">
                                                New password <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        id="account-details-new-password"
                                                        value={password}
                                                        onChange={this.handlePasswordChange}
                                                        className="input modal-input"
                                                        name="password"
                                                        type="password"
                                                    />
                                                </div>
                                                <div className="modal-input-group">
                                                    <label
                                                        htmlFor="account-details-confirm-password"
                                                        className="modal-input-label"
                                                    >
                                                Confirm new password <span className="required-field">*</span>
                                                    </label>
                                                    <input
                                                        id="account-details-confirm-password"
                                                        value={passwordConfirmation}
                                                        onChange={this.handlePasswordChange}
                                                        className="input modal-input"
                                                        name="passwordConfirmation"
                                                        type="password"
                                                    />
                                                </div>
                                                <div className="modal-btn-row">
                                                    <div
                                                        role="button"
                                                        className="btn secondary-btn modal-form-btn"
                                                        onClick={this.togglePasswordSection}
                                                    >
                                                Cancel
                                                    </div>
                                                    <div
                                                        role="button"
                                                        className={`btn secondary-btn modal-form-btn ${
                                                    passwordReadyForSubmission
                                                        ? 'secondary-btn-main-cta'
                                                        : 'disabled-secondary-btn'
                                                }`}
                                                        onClick={this.handlePasswordSubmit}
                                                    >
                                                Update password
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                </>
                            )}

                            { activeModalSection === 'organisation' && (
                                <OrganisationSettingsTab 
                                    displaySuccessMessage={this.displaySuccessMessage}
                                    displayErrorMessage={this.displayErrorMessage}
                                />
                            )}

                            { activeModalSection === 'notification' && (
                                <NotificationsTab 
                                    displaySuccessMessage={this.displaySuccessMessage}
                                    displayErrorMessage={this.displayErrorMessage}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

Modal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
};

export default Modal;
