import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LockIcon } from '../../images/icons/lock.svg';
import { Link } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

import { ReactComponent as RacecheckAIIcon } from '../../images/icons/racecheck-ai_outline.svg';
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';

class SmartQuestionCard extends Component {

    renderResponsesPreview = () => (
        this.props.smartQuestion?.answers_preview.map((answer, idx) => (
            <p key={idx} className="rs-rc-response">
                {answer}
            </p>
        )
        )
    );

    render () {
        const { smartQuestion, isPremium, handleViewAllResponses } = this.props;
        return (
            <div className="review-smart-question-card rs-report-card" style={{ position: 'relative' }}>
                <div className="sq-inner">
                    <div className="rs-rc-header">
                        <Link
                            className="rs-rc-question"
                            onClick={() => handleViewAllResponses(smartQuestion)}
                        >
                            <RacecheckAIIcon />
                            {smartQuestion.question}
                            <ChevronRight className="rs-rc-q-chevron" />
                        </Link>
                        <p className="rs-rc-count">
                            {smartQuestion.answers_count} responses
                        </p>
                    </div>
                    {isPremium ?
                        <div className="rs-rc-responses">
                            {this.renderResponsesPreview()}
                            <button
                                onClick={() => handleViewAllResponses(smartQuestion)}
                                className="rs-rc-btn"
                            >
                                View all responses
                            </button>
                        </div>
                        : (
                            <>
                                <p className="sq-answer sq-answer-blurred">
                                    Upgrade your account to Racecheck Premium to see how your participants
                                    answered their smart questions and get invaluable insights into their
                                    opinions about your event, alongside many other great features!
                                </p>
                                <div className="sq-upgrade">
                                    <LockIcon />
                                    {/* eslint-disable */}
                                    <Link
                                        to="/subscription/subscription-plan"
                                        className="sq-upgrade-link"
                                        onClick={() => {
                                            mixpanel.track('Dashboard - Smart Question Report - Unlock smart question click', this.props.mpProps);
                                        }}
                                    >Upgrade to Premium
                                    </Link>
                                    <span>&nbsp;to unlock your Smart Question insights!</span>
                                    {/* eslint-enable */}
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        );
    }
}

SmartQuestionCard.propTypes = {
    smartQuestion: PropTypes.shape({
        id: PropTypes.number.isRequired,
        question: PropTypes.string.isRequired,
        answers_count: PropTypes.number.isRequired,
        answers_preview: PropTypes.array.isRequired,
    }),
    isPremium: PropTypes.bool.isRequired,
    handleViewAllResponses: PropTypes.func.isRequired,
};

SmartQuestionCard.defaultProps = {
    smartQuestion: {
        id: 0,
        question: '',
        answers_count: 0,
        answers_preview: [],
    },
    isPremium: false,
};

export default SmartQuestionCard;