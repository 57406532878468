import React, { Component } from 'react';
import '../logInSignUp/LogInSignUp.css';
import AppContext from '../app/AppContext';
import HubspotDemoForm from '../helpers/HubspotDemoForm';

class BookDemo extends Component {
    static contextType = AppContext;

    render () {
        return (
            <div className="logged_out_container">
                <a href="/">
                    <img
                        className="logged_out_logo"
                        src={require('../../images/racecheck_logo_black.svg')}
                        width="250px"
                        alt="racecheck-logo"
                    />
                </a>
                <div className="book-demo-container">
                    <div className="bdc-left">
                        <h2>
                            Discover Racecheck&apos;s powerful suite of tools
                        </h2>
                        <p className="demo-intro">
                            Book a demo with Racecheck to learn why we&apos;re trusted by over 1,200+ event 
                            organisers globally. Boost your trust, traffic, conversion rates, and sales with 
                            Racecheck Premium.
                        </p>
                        <div className="testimonial-images">
                            <img
                                src={require('./landing-page-images/logo10-grey.png')}
                                alt="Logo"
                                className="testimonial-img"
                            />
                            <img
                                src={require('./landing-page-images/logo6-grey.png')}
                                alt="Logo"
                                className="testimonial-img"
                            />
                            <img
                                src={require('./landing-page-images/logo12-grey.png')}
                                alt="Logo"
                                className="testimonial-img"
                            />
                            <p>+ more</p>
                        </div>
                    </div>
                    <div className="bdc-right">
                        <HubspotDemoForm
                            region="eu1"
                            portalId="25712074"
                            formId="3fa25289-83f4-4217-b71a-563faf9afce1"
                        />
                    </div>
                    {/* <p className="log-in-sign-up-link">
                        Don&apos;t need a demo? <Link to="/signup">Get started for free</Link>
                    </p> */}
                </div>
            </div>
        );
    }
};

export default BookDemo;
