import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import axios from "axios";
import parse from 'html-react-parser';
import { ReactComponent as ProfileIcon } from '../../images/icons/person.svg';
import { localiseText } from '../../utils';

class AuditCard extends Component {
    render () {
        return (
            <div className={`audit-card ac-${this.props.auditLog.tag}`}>
                <div className="ac-mobile-header">
                    <div className="ac-mobile-header-left">
                        {this.props.auditLog.avatar ? (
                            <div className="ac-card-profile-img-container">
                                <img
                                    className="audit-card-profile-img"
                                    src={this.props.auditLog.avatar}
                                    alt="userprofile"
                                />
                            </div>
                        ) : (
                            <div className="audit-card-profile-img-placeholder">
                                <ProfileIcon />
                            </div>
                        )}
                        <div className="ac-mh-copy">
                            <p className="acm-team-member-name">{this.props.auditLog.team_member}</p>
                            <p className="acm-timestamp">{this.props.auditLog.time_of_change}</p>
                        </div>
                    </div>
                    <div className="ac-tag acm-tag">
                        <p className={`ac-tag-content ac-tag-${this.props.auditLog.tag}`}>{this.props.auditLog.tag}</p>
                    </div>
                </div>
                <div className="ac-details">
                    {this.props.auditLog.avatar ? (
                        <div className="ac-card-profile-img-container">
                            <img
                                className="audit-card-profile-img"
                                src={this.props.auditLog.avatar}
                                alt="userprofile"
                            />
                        </div>
                    ) : (
                        <div className="audit-card-profile-img-placeholder">
                            <ProfileIcon />
                        </div>
                    )}
                    <div className="ac-log">
                        <p className="ac-extra-info">
                            <span>{this.props.auditLog.team_member}</span>
                            <span className="bullet">•</span>
                            <span>{this.props.auditLog.time_of_change}</span>
                        </p>
                        <p className="ac-action">{parse(localiseText(this.props.auditLog.action_html))}</p>
                    </div>
                </div>
                <div className="ac-tag">
                    <p className={`ac-tag-content ac-tag-${this.props.auditLog.tag}`}>{this.props.auditLog.tag}</p>
                </div>
                {/* <div className="ac-team-member">
                    {this.props.auditLog.avatar ? (
                        <div className="ac-card-profile-img-container">
                            <img
                                className="audit-card-profile-img"
                                src={this.props.auditLog.avatar}
                                alt="userprofile"
                            />
                        </div>
                    ) : (
                        <div className="audit-card-profile-img-placeholder">
                            <ProfileIcon />
                        </div>
                    )}
                    <p className="ac-team-member-name">{this.props.auditLog.team_member}</p>
                </div>
                <div className="ac-action">
                    <p>{parse(localiseText(this.props.auditLog.action_html))}</p>
                </div>
                <div className="ac-tag">
                    <p className={`ac-tag-content ac-tag-${this.props.auditLog.tag}`}>{this.props.auditLog.tag}</p>
                </div>
                <div className="ac-timestamp">
                    <p>{this.props.auditLog.time_of_change}</p>
                </div> */}
            </div>
        );
    }
}

AuditCard.propTypes = {
    auditLog: PropTypes.shape({
        avatar: PropTypes.string,
        team_member: PropTypes.string.isRequired,
        time_of_change: PropTypes.string.isRequired,
        tag: PropTypes.string.isRequired,
        action_html: PropTypes.string.isRequired,
    }),
};

AuditCard.defaultProps = {
    auditLog: {
        avatar: '',
        team_member: '',
        time_of_change: '',
        tag: '',
        action_html: '',
    },
};

export default AuditCard;
