import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as LockIcon } from '../../images/icons/lock.svg';
import AppContext from '../app/AppContext';
import SmallLoader from '../../images/small_loader.gif';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import Loader from '../helpers/loader';

class AwardsModal extends Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);

        this.state = {
            loadingButton: false,
            awardsSelected: false,
            awards: [],
            awardYears: [],
            selectedYear: '',
            awardsLoaded: false,
            awardsToDownload: [],
            selectedFileTypes: []
        };
    }

    componentDidMount () {
        this.getAwards();
    }

    getAwards = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        /* eslint-disable-next-line*/
        let apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/awards/`;
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                const awardYears = res.data.data.map((item) => item.year);
                this.setState({
                    awards: res.data.data,
                    awardYears,
                    selectedYear: res.data.data[res.data.data.length - 1].year,
                    awardsLoaded: true
                }, () => {
                    this.renderAwards();
                });
            })
            .catch(error => {
                console.log('Could not retrieve awards', error);
                return null;
            });
    }

    handleAwardDownload = () => {
        this.setState({
            loadingButton: true,
        }, () => {
            const downloadURLs = [];
            this.state.awardsToDownload.forEach(award => {
                if (this.state.selectedFileTypes.includes('PNG')) {
                    downloadURLs.push(award.image);
                }
                if (this.state.selectedFileTypes.includes('SVG')) {
                    downloadURLs.push(award.image_svg);
                }
                if (this.state.selectedFileTypes.includes('EPS')) {
                    downloadURLs.push(award.image_eps);
                }
            });
            this.buildAndDownloadZip(downloadURLs);
        });
    };

    buildAndDownloadZip = (urls) => {
        const zip = new JSZip();
        const zipFilename = 'Racecheck-Awards.zip';
        const awardsToDownload = this.state.awardsToDownload;
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = { Authorization: `Token ${token}` };

        const fetchPromises = urls.map((url) => {
            const award = awardsToDownload.find(obj => obj.image === url || obj.image_svg === url || obj.image_eps === url);
            const filename = award.slug + url.substr(url.length - 4, url.length);

            const proxyUrl = `${process.env.REACT_APP_API_URL}/api/react/proxy-image/?url=${encodeURIComponent(url)}`;
            return fetch(proxyUrl, { headers })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Failed to fetch ${url}`);
                    }
                    return response.blob().then(blob => {
                        zip.file(filename, blob);
                    });
                })
                .catch(error => {
                    console.error('Error fetching image:', error);
                });
        });

        Promise.all(fetchPromises)
            .then(() => {
                zip.generateAsync({ type: 'blob' }).then((content) => {
                    saveAs(content, zipFilename);
                });
            })
            .catch(error => {
                console.error('Error generating zip:', error);
            })
            .finally(() => {
                this.setState({
                    loadingButton: false,
                    awardsToDownload: [],
                    selectedFileTypes: []
                });
            });
    };

    handleChange = e => {
        this.setState({
            selectedYear: e.target.value,
            awardsToDownload: []
        }, () => {
            this.renderAwards();
        });
    };

    renderAwards = () => {
        const awardYear = parseInt(this.state.selectedYear);
        const yearsAwards = this.state.awards.filter(obj => obj.year === awardYear);
        this.setState({
            currentlySelectedYearAwards: yearsAwards[0].awards
        });
    }

    handleAwardSelection = (award) => {
        const currentAwardsToDownload = this.state.awardsToDownload;
        if (this.checkIfAwardMatch(award)) {
            const indexToRemove = currentAwardsToDownload.map(x => x.id).indexOf(award.id);
            currentAwardsToDownload.splice(indexToRemove, 1);
        }
        else {
            currentAwardsToDownload.push(award);
        }
        this.setState({
            awardsToDownload: currentAwardsToDownload
        });
    }

    handleFileTypeSelection = (type) => {
        const currentFileTypes = this.state.selectedFileTypes;
        if (currentFileTypes.includes(type)) {
            currentFileTypes.splice(currentFileTypes.indexOf(type), 1);
        }
        else {
            currentFileTypes.push(type);
        }
        this.setState({
            selectedFileTypes: currentFileTypes
        });
    }

    checkIfAwardMatch = (award) => this.state.awardsToDownload.some(item => item.id === award.id)

    createYearSelect = () =>
        this.state.awardYears.map(option => (
            <option key={option} value={option}>
                {option}
            </option>
        ));

    render () {
        const { loadingButton, selectedYear, awardsLoaded,
            currentlySelectedYearAwards, awardsToDownload, selectedFileTypes } = this.state;

        const awardContainers = currentlySelectedYearAwards?.map(item => 
            (
                <div className={`award-badge-container ${item.can_download ? '' : 'award-badge-ineligible'}
                    ${this.checkIfAwardMatch(item) ? 'award-badge-selected' : ''}`}
                onClick={() => this.handleAwardSelection(item)}
                key={item.thumbnail}
                >
                    <img className="badge-img" alt={item.slug} src={item.thumbnail} />
                    {!item.can_download &&
                        <LockIcon />
                    }
                    <div className="award-checkbox" />
                </div>
            )
        );

        return (
            <div className="modal-backdrop">
                <div className="modal-padding" />
                <div className="modal-container">
                    <div className="modal-header">
                        <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                            <CloseIcon />
                        </button>
                        <h2 className="modal-header-title modal-title-no-nav">Download award assets</h2>
                    </div>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            {awardsLoaded ?
                                <div className="modal-content-section">
                                    <div className="horizontal-select-group">
                                        <label htmlFor="current-year" className="horiztonal-select-label">
                                        Year:
                                        </label>
                                        <select
                                            id="current-year"
                                            className="rounded-dropdown"
                                            name="selectedYear"
                                            onChange={this.handleChange}
                                            value={selectedYear}
                                            required
                                        >
                                            {this.createYearSelect()}
                                        </select>
                                    </div>
                                    <div className="award-badges-row">
                                        {awardContainers}
                                    </div>
                                    <div className="award-file-types-row">
                                        <div className={`award-file-type ${selectedFileTypes.includes('PNG') ?
                                            'award-file-type-selected' : ''}`}
                                        onClick={() => this.handleFileTypeSelection('PNG')}
                                        >
                                            <div className="file-type-checkbox" />
                                            <span>.PNG</span>
                                        </div>
                                        <div className={`award-file-type ${selectedFileTypes.includes('SVG') ?
                                            'award-file-type-selected' : ''}`}
                                        onClick={() => this.handleFileTypeSelection('SVG')}
                                        >
                                            <div className="file-type-checkbox" />
                                            <span>.SVG</span>
                                        </div>
                                        <div className={`award-file-type ${selectedFileTypes.includes('EPS') ?
                                            'award-file-type-selected' : ''}`}
                                        onClick={() => this.handleFileTypeSelection('EPS')}
                                        >
                                            <div className="file-type-checkbox" />
                                            <span>.EPS</span>
                                        </div>
                                    </div>
                                    <div className="modal-btn-row">
                                        {loadingButton ? (
                                            <div
                                                role="button"
                                                className={
                                                    'btn sub-fixed-width-btn primary-btn modal-form-btn disabled-primary-btn'
                                                }
                                            >
                                                <img
                                                    className="subscription-btn-loader"
                                                    src={SmallLoader}
                                                    alt="userprofile"
                                                />
                                            </div>
                                        ) : (
                                            <div
                                                role="button"
                                                className={`btn sub-fixed-width-btn primary-btn modal-form-btn ${
                                                (awardsToDownload.length > 0 && selectedFileTypes.length > 0) ?
                                                    '' : 'disabled-primary-btn'
                                            }`}
                                                onClick={this.handleAwardDownload}
                                            >
                                            Download awards
                                            </div>
                                        )}
                                    </div>
                                </div>
                                :
                                <div className="section-loading-container award-modal-loading-container">
                                    <Loader />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

AwardsModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
};

AwardsModal.defaultProps = {
    toggleModal: () => {},
};

export default AwardsModal;
