import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import qs from 'query-string';
import { withRouter } from 'react-router';

import CreateSubscriptionModal from './CreateSubscriptionModals';
import ContactModal from './ContactModal';
import AppContext from '../app/AppContext';
import SelectSizeTierModal from './SelectSizeTierModal';
import { mapCurrencyToSign } from '../../utils';
import mixpanel from 'mixpanel-browser';
import { localiseText } from '../../utils';

import { ReactComponent as ExclamationIcon } from '../../images/icons/exclamation_circle.svg';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';

class FreeAccount extends React.Component {
    static contextType = AppContext;

    constructor (props) {
        super(props);
        this.state = {
            selectedInvoice: null,
            payInvoiceModalOpen: false,
            newPaymentMethodModalOpen: false,
            editDetailsModalOpen: false,
            cancelSubscriptionModalOpen: false,
            upgradeEvent: {},
            upgradeModalOpen: false,
            downgradeEvent: {},
            downgradeModalOpen: false,
            refreshEvents: false,
            openEmptyUpgradeModal: false,
            perks: [],
            isShowSelectSizeTierModal: false,
            isShowContactModal: false,
            annualPlanPrice: {},
        };
    }

    componentDidMount () {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/lookup/subscription_perk_groups/`;
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            .get(apiCallURL, { headers })
            .then(res => {
                this.setState({
                    perks: res.data.data,
                });
            })
            .catch(error => {
                console.log(error);
            });

        this.getTierPlan();

        const queryParams = qs.parse(this.props.location.search);

        if (queryParams?.redirect_status) {
            this.setState({
                upgradeModalOpen: true,
            });
        }
    }

    getTierPlan = () => {
        const token = this.context.token ? this.context.token : localStorage.getItem('token');
        const headers = {
            Authorization: `Token ${token}`,
        };
        axios
            // eslint-disable-next-line max-len
            .get(`${process.env.REACT_APP_API_URL}/api/react/organisations/${this.context.selectedOrganisation.id}/subscriptions/get_tier_plan/`, { headers })
            .then(({ data }) => {
                this.setState({
                    annualPlanPrice: data.data.find(item => item?.paycycle === 'A').plan_price[0],
                    currentTier: data.data.find(item => item?.paycycle === 'A').title,
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleUpgradeEvent = (event, eventsList) => this.setState({
        upgradeEvent: event,
        upgradeModalOpen: true,
        eventsList
    });

    handleUpgradeCancel = (refreshEvents = false) => this.setState({
        upgradeEvent: {},
        upgradeModalOpen: false,
        eventsList: []
    }, () => {
        if (refreshEvents)
            this.props.updateSubData(false);
    });

    toggleIsShowSelectSizeTierModal = () => {
        this.setState(prevState => ({
            isShowSelectSizeTierModal: !prevState.isShowSelectSizeTierModal,
        }));
    };

    toggleContactModal = () => {
        this.setState(prevState => ({
            isShowContactModal: !prevState.isShowContactModal,
        }));
    }

    handleContinueUpgradeToPremium = (sizeTier) => {
        this.toggleIsShowSelectSizeTierModal();
        return this.toggleContactModal();
    }

    getFinalCost = (paycycle, basePrice, country) => {
        const { vat } = this.props.plans.plans.premium[0].payment_details.find(plan => plan.paycycle === paycycle);
        
        let totalCost;
        let hasVAT = false;
        if (country === 'United Kingdom') {
            hasVAT = true;
            if (paycycle === 'M' || paycycle === 'Monthly') {
                totalCost = +basePrice + (basePrice * vat) / 100;
            }
            else {
                totalCost = basePrice * 12 + (basePrice * 12 * vat) / 100;
            }
        }
        else if (paycycle === 'M' || paycycle === 'Monthly') {
            totalCost = +basePrice;
        }
        else {
            totalCost = +basePrice * 12;
        }

        return {
            totalCost: parseFloat(totalCost.toFixed(2)),
            hasVAT,
        };
    };

    getTotalCost = (count, paycycle) => {  
        const plan = this.props.plans.plans.premium[0].payment_details.find(item => item.paycycle === paycycle);

        const result = parseFloat(plan.base_price)
        ;

        return result;
    };

    render () {
        const {
            // selectedInvoice,
            // payInvoiceModalOpen,
            // newPaymentMethodModalOpen,
            // changePlanModalOpen,
            // editDetailsModalOpen,
            // cancelSubscriptionModalOpen,
            // restartSubscriptionModalOpen,
            // defaultPaymentMethod,
            // nextPaymentDateString,
            // cancellationDateString,
            // trialEndingDateString,
            // subscriptionStatus,
            // downgradeEvent,
            upgradeEvent,
            eventsList,
            // downgradeModalOpen,
            upgradeModalOpen,
            perks,
            isShowSelectSizeTierModal,
            isShowContactModal,
            annualPlanPrice,
            // currentTier
        } = this.state;

        const mpProps = this.context.selectedOrganisation ? {
            organisation: this.context.selectedOrganisation?.name,
            premium_subscriber: this.context.selectedOrganisation?.is_premium_subscriber
        } : {};

        const perksList = perks?.map((perk, i) => (
            <tbody id={perk.id} key={perk.id}>
                <tr className="sp-perk-header-row">
                    <td colSpan={3}>
                        <p className="sp-phr-subheader">
                            <img
                                src={require(`../../images/icons/ft-${perk?.slug}.svg`)}
                                alt="Widget"
                                className="sp-phr-icon"
                            />
                            { perk.title }
                        </p>
                    </td>
                </tr>
                {perk.perks?.map((perk) => (
                    <>
                        <tr className="sp-table-responsive-row">
                            <td colSpan="2" className="sp-table-responsive-perk">
                                {localiseText(perk.title)}
                                {perk.description && <p className="sp-perk-coming-soon">{perk.description}</p>}
                            </td>
                        </tr>
                        <tr className="sp-perk-row" key={perk.id}>
                            <td className="sp-perk-title">
                                {perk.description && <p className="sp-perk-coming-soon">{perk.description}</p>}
                                {localiseText(perk.title)}
                            </td>
                            <td className="sp-perk-icon">
                                {perk.premium_only ? (
                                    <span className="spc-perks-perk-img spc-perks-perk-img-cross">
                                        <CloseIcon />
                                    </span>
                                ) : (
                                    <span className="spc-perks-perk-img">
                                        <TickIcon />
                                    </span>
                                )}
                            </td>
                            <td
                                className=
                                    {`sp-perk-icon 
                                ${i === perks.length - 1 ? 'sp-perk-included  sp-perk-included-last' : 'sp-perk-included'}`}
                            >
                                <span className="spc-perks-perk-img">
                                    <TickIcon />
                                </span>
                            </td>
                        </tr>
                    </>
                ))}
                <tr><td colSpan="3" className="sp-perk-divider" /></tr>
            </tbody>
        ));
        
        return (
            <div className="subscription-plans-container"> 
                {this.props.subscriptionDetails?.details?.statuses.is_trial_eligible ?
                    <div className="subscription-info-banner subscription-start-trial-banner">
                        <p className="subscription-info-text">
                            <span className="subscription-info-icon">
                                <ExclamationIcon />
                            </span>
                            You have a 30-day free trial for Racecheck Premium!
                        </p>
                        <div
                            role="button"
                            className={
                                'btn sub-fixed-width-btn primary-btn modal-form-btn'
                            }
                            onClick={() => {
                                /* eslint-disable-next-line */
                                mixpanel.track('Dashboard - Subscription page - Trial banner - Upgrade to Premium click', mpProps);
                                this.toggleIsShowSelectSizeTierModal();
                            }
                            }
                        >
                            Get started now
                        </div>
                    </div>
                    :
                    <div className="subscription-start-trial-banner sp-responsive-get-started-btn">
                        <div
                            role="button"
                            className={
                                'btn sub-fixed-width-btn primary-btn modal-form-btn'
                            }
                            onClick={() => {
                                /* eslint-disable-next-line */
                                mixpanel.track('Dashboard - Subscription page - Upgrade to Premium click', mpProps);
                                this.toggleIsShowSelectSizeTierModal();
                            }
                            }
                        >
                            Get started now
                        </div>
                    </div>
                }

                <div className="subscription-plan-cards">
                    <table className="sp-table">
                        <thead>
                            <tr>
                                <th className="sp-table-perks-header" />
                                <th className="sp-table-plan">
                                    <p className="sp-title sp-title-free">Free</p>
                                    <h2 className="sp-plan-title">
                                        <span className="spc-pt-currency">
                                            {mapCurrencyToSign[annualPlanPrice?.base_price_currency]}
                                        </span>0
                                        <span className="spc-pt-month">/mo</span>
                                    </h2>
                                    <p className="sp-plan-title-copy">your current plan</p>
                                </th>
                                <th className="sp-table-plan sp-table-plan-recommended">
                                    <p className="sp-title sp-title-premium">
                                        Premium
                                        {/* {currentTier === 'Tier 5' && 'from'} */}
                                    </p>
                                    <h2 className="sp-custom-pricing-title">
                                        Custom pricing
                                    </h2>
                                    <p className="sp-custom-pricing-desc">
                                        Our bespoke pricing is tailored to suit you and your events.
                                    </p>
                                    <div className="modal-btn-row spc-btn">
                                        <div
                                            role="button"
                                            className="btn primary-btn modal-form-btn"
                                            onClick={() => {
                                                /* eslint-disable-next-line */
                                                mixpanel.track('Dashboard - Subscription page - Contact sales', mpProps);
                                                this.toggleIsShowSelectSizeTierModal();
                                            }
                                            }
                                        >
                                            Contact sales
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        {perksList}
                    </table>
                </div>
                {upgradeModalOpen &&
                    <CreateSubscriptionModal 
                        event={upgradeEvent}
                        events={eventsList} 
                        subscriptionDetails={this.props.subscriptionDetails} 
                        plans={this.props.plans}
                        onClose={this.handleUpgradeCancel} 
                        getFinalCost={this.getFinalCost}
                        getTotalCost={this.getTotalCost}
                        handleUpgradeCancel={this.handleUpgradeCancel}
                    />
                }

                {isShowSelectSizeTierModal && 
                    <SelectSizeTierModal 
                        onClose={this.toggleIsShowSelectSizeTierModal}
                        handleContinue={this.handleContinueUpgradeToPremium} 
                    />}

                {
                    isShowContactModal && 
                    <ContactModal 
                        onClose={this.toggleContactModal}
                        closeSizeTierModal={this.toggleIsShowSelectSizeTierModal}
                    />
                }
            </div>
        );
    }

};

FreeAccount.propTypes = {
    subscriptionDetails: PropTypes.shape({
        details: PropTypes.shape({
            customer: PropTypes.shape({
                country: PropTypes.string
            }),
            statuses: PropTypes.shape({
                is_trial_eligible: PropTypes.bool
            }),
            events: PropTypes.shape({
                premium: PropTypes.number,
                free: PropTypes.number,
            })
        })
    }),
    plans: PropTypes.shape({
        plans: PropTypes.shape({
            premium: PropTypes.arrayOf(
                PropTypes.shape({
                    payment_details: PropTypes.arrayOf(
                        PropTypes.shape({
                            paycycle: PropTypes.string
                        })
                    )
                })
            )
        })
    }),
    updateSubData: PropTypes.func
};

export default withRouter(FreeAccount);