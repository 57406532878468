import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import organiserDashboardImg from '../../images/social-preview-examples.png';
import { ReactComponent as TickIcon } from '../../images/icons/tick.svg';
import mixpanel from 'mixpanel-browser';

class ImageGeneratorFreeModal extends Component {
    render () {

        return (
            <div className="modal-backdrop">
                <div className="modal-padding" />
                <div className="modal-container modal-infobox-container">
                    <button className="modal-close-btn" onClick={() => this.props.toggleModal(false)}>
                        <CloseIcon />
                    </button>
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="modal-content-section">
                                <div className="reply-review-modal-content">
                                    <h1>Want to generate dynamic social images?</h1>
                                    <p>
                                        With a Racecheck Premium account you will be able to
                                         generate social content from your favourite reviews in
                                          just a few clicks.
                                    </p>
                                    <p>But that&apos;s not all you&apos;ll get...</p>
                                    <div className="reply-perks-container">
                                        <img src={organiserDashboardImg} alt="illustration of the organiser dashboard" />
                                        <ul className="reply-perks-list">
                                            <li><TickIcon />Interactive, expanding Review Boxes for your sales channels</li>
                                            <li><TickIcon />
                                            Analytics reports outlining Review Box performance and sales insights
                                            </li>
                                            <li><TickIcon />SEO & digital marketing boosting tools</li>
                                            <li><TickIcon />Unlimited replies to reviews</li>
                                            <li><TickIcon />Live technical support</li>
                                        </ul>
                                    </div>
                                    <Link 
                                        to={'/subscription/subscription-plan'} 
                                        className="btn primary-btn"
                                        onClick={() => {
                                            /* eslint-disable-next-line */
                                            mixpanel.track('Dashboard - Review Feed - Generate social post - Upgrade to Premium click', this.props.mpProps);
                                        }}
                                    >Get started with Racecheck Premium
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

ImageGeneratorFreeModal.propTypes = {
    toggleModal: PropTypes.func,
    mpProps: PropTypes.PropTypes.shape({
        organisation: PropTypes.string,
        premium_subscriber: PropTypes.bool
    })
};

ImageGeneratorFreeModal.defaultProps = {
    toggleModal: () => {},
};

export default ImageGeneratorFreeModal;
