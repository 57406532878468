import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import axios from "axios";
import RatingStars from '../helpers/starGenerator';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron_down.svg';
// import { animateScroll as scroll } from "react-scroll";

class RatingBreakdown extends Component {
    constructor (props) {
        super(props);

        this.state = {
            fullBreakdownOpen: false,
            ratingBreakdown: this.props.ratingBreakdownData.ratings,
            averageRating: this.props.ratingBreakdownData.average_rating,
        };
    }

    componentDidMount () {}

    toggleFullBreakdown = () => {
        const { fullBreakdownOpen } = this.state;
        fullBreakdownOpen
            ? this.setState(
                {
                    fullBreakdownOpen: false,
                },
                () => {
                    // scroll.scrollToTop();
                }
            )
            : this.setState({
                fullBreakdownOpen: true,
            });
    };

    render () {
        const { fullBreakdownOpen, ratingBreakdown, averageRating } = this.state;

        const ratingBreakdownList = ratingBreakdown?.map(rating => (
            <div className="rating-breakdown-group" key={Object.keys(rating)[0]}>
                <p className="rating-breakdown-label">{Object.keys(rating)[0]}</p>
                {rating[Object.keys(rating)[0]] > 0 ? <RatingStars rating={rating[Object.keys(rating)[0]]} />
                    :
                    '-'
                }
            </div>
        ));

        return (
            <div className="rating-breakdown-container">
                <p className="rating-breakdown-title">
                    {this.props.year && this.props.year !== 'all' ? this.props.year : 'All Time'} Review Breakdown
                </p>
                <RatingStars rating={averageRating} />
                <p className="rating-breakdown-count">
                    based on <span>{this.props.count}</span> review{this.props.count === 1 ? '' : 's'}
                </p>
                {fullBreakdownOpen ? (
                    <div>
                        <div className="rating-breakdown-divider" />
                        <p className="rating-breakdown-subheader">Organisation rating</p>
                        {ratingBreakdownList}
                        <p className="hide-full-breakdown" onClick={this.toggleFullBreakdown}>
                            Hide full breakdown{' '}
                            <span>
                                <ChevronDown />
                            </span>
                        </p>
                    </div>
                ) : (
                    <p className="see-full-breakdown" onClick={this.toggleFullBreakdown}>
                        See full breakdown{' '}
                        <span>
                            <ChevronDown />
                        </span>
                    </p>
                )}
            </div>
        );
    }
}

RatingBreakdown.propTypes = {
    ratingBreakdownData: PropTypes.shape({
        average_rating: PropTypes.number.isRequired,
        ratings: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
        course_rating: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    }).isRequired,
    year: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
};

RatingBreakdown.defaultProps = {
    ratingBreakdownData: {
        average_rating: 0,
        ratings: [
            {
                none: 0,
            }
        ],
    },
    year: 'all',
};

export default RatingBreakdown;
