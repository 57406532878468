import React, { Component } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
class SocialAccounts extends Component {
    handleSocialAccount = e => {
        e.preventDefault();
        const socialTarget = e.target.name;

        const queryParams = queryString.parse(window.location.search);
        if (queryParams.next && queryParams.next !== undefined && queryParams.next !== null) {
            sessionStorage.setItem('next',queryParams.next);
        }

        /*eslint-disable*/
        if (socialTarget === 'strava') {
            const stravaURL = `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}/strava-login&response_type=code&scope=profile:read_all`;
            window.location.href = stravaURL;
        }
        else if (socialTarget === 'google') {
            const googleURL = `https://accounts.google.com/o/oauth2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}
&response_type=code&scope=profile+email&redirect_uri=${process.env.REACT_APP_BASE_URL}/google-login`;
            window.location.href = googleURL;
        }
        else if (socialTarget === 'facebook') {
            window.location.href = '/reset-password?provider=facebook';
        }
        /* eslint-enable*/
    };

    render () {
        return (
            <div>
                <div className="strava-log-in">
                    <button className="google_btn btn" name="google" onClick={this.handleSocialAccount}>
                        {this.props.type ? 'Sign up' : 'Log in'} using Google
                    </button>
                </div>

                {!this.props.type &&
                    <div className="facebook-log-in grayscale">
                        <button className="facebook_btn btn" name="facebook" onClick={this.handleSocialAccount}>
                            Log in using Facebook
                        </button>
                    </div>
                }
                {/* <div className="strava-log-in"> */}
                {/* eslint-disable-next-line*/}
                {/* <button className="strava_btn btn" name="strava" onClick={this.handleSocialAccount}>{ this.props.type ? 'Sign up' : 'Log in'} using Strava</button> */}
                {/* </div> */}
            </div>
        );
    }
}

SocialAccounts.propTypes = {
    type: PropTypes.bool,
};

export default SocialAccounts;
