import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../organiserDashboard/Modal.css';
import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import AppContext from '../app/AppContext';

import { ReactComponent as RacecheckAIIcon } from '../../images/icons/racecheck-ai_outline.svg';

class SmartQuestionResponsesModal extends Component {
    static contextType = AppContext;

    renderResponses = () => (
        this.props.responses.map((answer, idx) => (
            <p key={idx} className="rs-rc-response">
                {answer}
            </p>
        )
        )
    );

    render () {
        const { smartQuestion, setResponsesModalOpen } = this.props;
        return (
            <div className="modal-backdrop">
                <div className="modal-padding" />
                <div className="modal-container sq-modal">
                    <div className="modal-scrollable">
                        <div className="modal-content">
                            <div className="rs-rc-header">
                                <p
                                    className="rs-rc-question"
                                >
                                    <RacecheckAIIcon />
                                    {smartQuestion.question}
                                </p>
                                <p className="rs-rc-count">
                                    {smartQuestion.answers_count} responses
                                </p>
                                <button className="modal-close-btn" onClick={() => setResponsesModalOpen(false)}>
                                    <CloseIcon />
                                </button>
                            </div>
                            <div className="modal-content-section">
                                {this.renderResponses()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-padding" />
            </div>
        );
    }
}

SmartQuestionResponsesModal.propTypes = {
    smartQuestion: PropTypes.shape({
        id: PropTypes.number.isRequired,
        question: PropTypes.string.isRequired,
        answers_count: PropTypes.number.isRequired,
    }).isRequired,
    responses: PropTypes.array.isRequired,
    setResponsesModalOpen: PropTypes.func,
};

SmartQuestionResponsesModal.defaultProps = {
    smartQuestion: {
        id: 0,
        question: '',
        answers_count: 0,
    },
    responses: [],
};

export default SmartQuestionResponsesModal;
