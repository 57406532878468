import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useHistory } from 'react-router-dom';

import StravaLogIn from '../components/logInSignUp/StravaLogIn';
import FacebookLogIn from '../components/logInSignUp/FacebookLogIn';
import LogInSignUp from '../components/logInSignUp/LogInSignUp';
import GoogleLogIn from '../components/logInSignUp/GoogleLogIn';
import ResetPassword from '../components/logInSignUp/ResetPassword';
import LandingPage from '../components/organiserDashboard/LandingPage';
import BookDemo from '../components/organiserDashboard/BookDemo';
import Webinar from '../components/organiserDashboard/Webinar';

import { isPublicPage } from './routes';

export default function LogInRoutes ({ isUserLoggedIn, location }) {
    const { push } = useHistory();

    useEffect(() => {
        if (isUserLoggedIn || (isUserLoggedIn && isPublicPage(location.pathname))) {
            console.log('should redirect');
            push('/events/events');
        }
    }, [isUserLoggedIn, location.pathname, push]);
    
    if (isUserLoggedIn) return <></>;

    return (
        <Switch>
            <Route path="/login" render={props => <LogInSignUp {...props} registering={false} />} />
            <Route path="/signup" render={props => <LogInSignUp {...props} registering />} />
            <Route path="/book-demo" render={() => <BookDemo />} />
            <Route path="/webinar" render={() => <Webinar />} />
            <Route path="/strava-login" render={() => <StravaLogIn />} />
            <Route path="/facebook-login" render={() => <FacebookLogIn />} />
            <Route path="/google-login" render={() => <GoogleLogIn />} />
            {/* <Route path="/create-organisation" render={() => <CreateOrganisation />} /> */}
            <Route path="/reset-password" render={() => <ResetPassword />} />
            <Route path="/" render={() => <LandingPage />} />
        </Switch>
    );
}

LogInRoutes.propTypes = {
    isUserLoggedIn: PropTypes.bool.isRequired,
};