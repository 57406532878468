import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import Loader from '../helpers/loader';
import { ReactComponent as DownloadIcon } from '../../images/icons/download.svg';
import AppContext from '../app/AppContext';
import PremiumFeaturePlaceholder from '../helpers/premiumFeaturePlaceholder';
import ShareLink from './ShareLink';
import axios from 'axios';
import { useRouteMatch } from 'react-router';
import RequestReviewChecklist from './RequestReviewChecklist';
import mixpanel from 'mixpanel-browser';

const RequestReviews = ({ event }) => {

    const { 
        selectedOrganisation = {},
        token = localStorage.getItem('token')
    } = useContext(AppContext);
    const { REACT_APP_API_URL } = process.env;
    const { params } = useRouteMatch();
    

    // eslint-disable-next-line
    const [loadingQRCodes, setLoadingQRCodes] = useState(true);
    const [qrCode, setQrCode] = useState('');

    const mpProps = selectedOrganisation ? {
        organisation: selectedOrganisation?.name,
        premium_subscriber: selectedOrganisation?.is_premium_subscriber
    } : {};

    const getQRCode = () => {
        setLoadingQRCodes(true);
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/events/${params.id}/qr_code/`;
        const headers = { Authorization: `Token ${token}` };
        axios
            .get(
                apiCallURL, 
                { headers }
            )
            .then(({ data }) => {
                setQrCode(data.data.qr_code);
                setLoadingQRCodes(false);
            })
            .catch(error => console.log(error.response));
            
    };

    useEffect(() => {
        getQRCode();

        // eslint-disable-next-line
    }, [selectedOrganisation.id, params.id]);

    const handleImageDownloadSVG = (url, slug) => {
        saveAs(url, `${slug}_qr-code.svg`);
    };

    // const handleImageDownloadPNG = (url) => {
    //     saveAs(url, 'code.png');
    // };

    return (
        <div className="request-reviews-container">

            {event.title &&
                <RequestReviewChecklist selectedOrganisation={selectedOrganisation} event={params.id} fullevent={event} />
            }

            <div className="request-reviews-divider">
                <span className="divider-line" />
                <span className="divider-text">Need another option? Check out the solutions below</span>
                <span className="divider-line" />
            </div>
            
            <div className="request-reviews-card-row">
                <ShareLink
                    event={event}
                />

                <div className="qr-code-container">
                    <div className="qr-code-header">
                        <h2 className="qr-code-title">Event QR codes</h2>
                        <p className="qr-code-intro">
                                Share the QR codes below with your event participants so they can quickly and easily
                                leave a review for your event.
                        </p>
                    </div>
                    {selectedOrganisation.is_premium_subscriber ? (
                        <Fragment>
                            {loadingQRCodes ? (
                                <div className="section-loading-container qr-code-loader">
                                    <Loader />
                                </div>
                            ) : (
                                <div className="qr-code-content">
                                    <div className="qr-code-event-info" />
                                    <div className="qr-code-preview">
                                        <div>
                                            <canvas id="viewport" />
                                            <img
                                                className="qr-code-img"
                                                src={qrCode}
                                                alt="qr-code"
                                            />
                                        </div>
                                        <div className="qr-code-downloads">
                                            <div
                                                role="button"
                                                className="btn primary-btn"
                                                onClick={() => {
                                                    handleImageDownloadSVG(
                                                        qrCode,
                                                        event.slug
                                                    );
                                                    // eslint-disable-next-line
                                                    mixpanel.track('Dashboard - Collect Reviews - Download QR code click', mpProps);
                                                }
                                                }
                                            >
                                                <span className="qr-download-copy">
                                                    <DownloadIcon />
                                                    <p>
                                                            Download{' '}
                                                        <span className="qr-download-file-type">SVG</span>
                                                    </p>
                                                </span>
                                            </div>
                                            {/* <div 
                                                role="button" 
                                                className="btn secondary-btn secondary-btn-rounded" 
                                                onClick={() => handleImageDownloadPNG(qrCode)}
                                            >
                                                <span className="qr-download-copy">
                                                    <DownloadIcon />
                                                    <p>
                                                        Download <span className="qr-download-file-type">PNG</span>
                                                    </p>
                                                </span>
                                            </div> */}
                                            <p>More file types coming soon.</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <PremiumFeaturePlaceholder />
                        </Fragment>
                    )}
                </div>
                <div className="request-reviews-wrapped-row">
                    <div className="request-reviews-placeholder1">
                        <h3>Check back again soon!</h3>
                        <p>
                                More tools to help you gather reviews from your participants quickly and effectively
                                are coming soon.
                        </p>
                    </div>
                </div>
            </div>
            
            {/* <div className="no-data-container">
                    <img src={noDataImage} className="no-data-img" alt="empty-clipboard" />
                    <p className="no-data-header">You currently have no events</p>
                    <p className="no-data-copy">
                        Get started by adding your first event in the{' '}
                        <Link to={'/events/events'} className="no-data-inline-link">
                            Events
                        </Link>{' '}
                        tab.
                    </p>
                </div> */}
        </div>
    );
};

RequestReviews.propTypes = {
    event: PropTypes.shape({
        qr_code: PropTypes.string,
        slug: PropTypes.string,
        status: PropTypes.string,
        title: PropTypes.string
    })
};

RequestReviews.defaultProps = {
    eventsList: [
        {
            id: 0,
            title: '',
        }
    ],
    currentSelections: {
        selectedEvent: {
            id: 0,
            reviewed_years: [],
            title: '',
        },
        setSelectedEvent: () => {},
        setSelectedSorting: () => {},
        setSelectedYear: () => {},
        selectedYear: '',
        selectedSorting: 'most-recent-first',
    },
};

export default RequestReviews;
