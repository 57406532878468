import React from 'react';
import PropTypes from 'prop-types';

function PercentageBar ({ tag = '', top = false, value = 0 }) {
    return (
        <div className="percentage-bar-container">
            <p className="percentage-bar-label">{tag}</p>
            <div className="percentage-bar-graph">
                <div
                    className={`percentage-bar-graph-filled ${top ? 'pbgf-green' : 'pbgf-red'}`}
                    style={{ width: `${value}%` }}
                />
            </div>
            <p className="percentage-bar-label-right text-right">
                <span className="pblr-value">{value}%</span>
            </p>
        </div>
    );
}

PercentageBar.propTypes = {
    tag: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    top: PropTypes.bool.isRequired,
};

export default PercentageBar;
