import { usermavenClient } from '@usermaven/sdk-js';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export function useUsermaven (opts) {
    const usermaven = useMemo(() => usermavenClient(opts), [opts]);
    return { usermaven };
}

export function usePageView (usermaven, event) {
    const location = useLocation();
    useEffect(() => {
        usermaven.track('pageview');
    }, [location, usermaven]);
}
