import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes, { number } from 'prop-types';
import axios from 'axios';
import { useLocation, Link, useRouteMatch } from 'react-router-dom';
import qs from 'qs';
import { Bar } from 'react-chartjs-2';

import ErrorMessage from '../helpers/errorMessage';
import RatingStars from '../helpers/starGenerator';
import RatingBreakdown from './charts/RatingBreakdown';
import PercentageBar from './charts/PercentageBar';
import NoDataPlaceholder from '../helpers/noDataPlaceholder';
import Loader from '../helpers/loader';
import AppContext from '../app/AppContext';
import YearOnYearRating from './charts/YearOnYearRating';
import DifficultyChart from './charts/DifficultyChart';
import OrgDashIllustration from '../../images/organiser-dashboard.png';
import ReviewsIllustration from '../../images/reviews-illustration.png';
import { ReactComponent as Download } from '../../images/icons/download.svg';
import SmallLoader from '../../images/small_loader.gif';
import mixpanel from 'mixpanel-browser';
import { localiseText } from '../../utils';

let labels = [];

const Header = ({ handleChange, currentYear, createYearSelect, event }) => (
    <div className="mr-report-header">
        <img
            src={require('../../images/racecheck_logo_black.svg')}
            className="mr-rc-logo"
            alt="racecheck-logo"
        />
        <h1>{event}</h1>
        <div className="mr-header-info">
            <p>Event Recap Analytics Report</p>
            <p className="bullet">•</p>
            <select
                id="current-year"
                className="mr-period-select rounded-dropdown"
                name="currentYear"
                onChange={handleChange}
                value={currentYear}
                required
            >
                <option value="all">All time</option>
                {createYearSelect()}
            </select>
        </div>
    </div>
    // <div className="horizontal-dropdowns">
    //     <div className="horizontal-select-group">
    //         <label htmlFor="current-year" className="horizontal-select-label">
    //             {event}&apos;s
    //         </label>
    //         <select
    //             id="current-year"
    //             className="mr-period-select rounded-dropdown"
    //             name="currentYear"
    //             onChange={handleChange}
    //             value={currentYear}
    //             required
    //         >
    //             <option value="all">all time</option>
    //             {createYearSelect()}
    //         </select>
    //         <label className="horiztonal-select-label">
    //             analytics report
    //         </label>
    //     </div>
    // </div>
);

Header.propTypes = {
    handleChange: PropTypes.func,
    currentYear: PropTypes.string,
    createYearSelect: PropTypes.func,
    event: PropTypes.string,
};

const AnalyticsReport = ({ event }) => {
    const { search } = useLocation();
    const { year } = qs.parse(search, { ignoreQueryPrefix: true });

    const [currentYear, setCurrentYear] = useState(year || 'all');
    const [currentEventRatingBreakdown, setCurrentEventRatingBreakdown] = useState([]);
    const [
        currentEventRatingBreakdownPercentages, 
        setCurrentEventRatingBreakdownPercentages
    ] = useState(['...', '...', '...', '...', '...']);
    const [currentEventRating, setCurrentEventRating] = useState('0.00');
    const [currentEventReviewCount, setCurrentEventReviewCount] = useState(0);
    const [currentEventReviewTags, setCurrentEventReviewTags] = useState([]);
    const [difficultyData, setDifficultyData] = useState(null);
    const [analyticsLoaded, setAnalyticsLoaded] = useState(false);
    const [yearOnYearRatingData, setYearOnYearRatingData] = useState({});
    const [reviewedYears, setReviewedYears] = useState([]);
    const [downloadReportError, setDownloadReportError] = useState('');
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);
    const [comparativeAnalysis, setComparativeAnalysis] = useState(null);

    const { 
        token = localStorage.getItem('token'), 
        selectedOrganisation = {} 
    } = useContext(AppContext);

    const mpProps = selectedOrganisation ? {
        organisation: selectedOrganisation?.name,
        premium_subscriber: selectedOrganisation?.is_premium_subscriber
    } : {};

    const { params } = useRouteMatch();

    const { REACT_APP_API_URL } = process.env;
    const headers = {
        Authorization: `Token ${token}`,
    };

    // function getEvent () {
    //     const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/`;
    //     axios
    //         .get(
    //             apiCallURL, 
    //             { headers }
    //         )
    //         .then(({ data }) => setCurrentEvent(data)) 
    //         .catch(error => console.log(error.response));
    // };

    const getComparativeAnalytics = () => {
        const { id } = selectedOrganisation;
        
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${id}/events/${event?.id}/event_comparative_analysis/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios.get(apiCallURL, { headers })
            .then(({ data: { data } }) => {
                setComparativeAnalysis(data);
            });
    };

    const getRatingBreakdownData = () => {
        const { id } = selectedOrganisation;
        const year = currentYear && currentYear !== 'all' ? `${currentYear}/` : '';
        
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/rating_breakdown/${year}`;
        
        axios
            .get(apiCallURL, { headers })
            .then(({ data: { results } }) => {
                const ratingBreakdownData = [];
                ratingBreakdownData.push(results.Excellent);
                ratingBreakdownData.push(results.Great);
                ratingBreakdownData.push(results.Average);
                ratingBreakdownData.push(results.Poor);
                ratingBreakdownData.push(results.Bad);

                const ratingBreakdownPercentages = [];
                ratingBreakdownPercentages.push(
                    Math.round((results.Excellent / results.Total) * 100)
                );
                ratingBreakdownPercentages.push(Math.round((results.Great / results.Total) * 100));
                ratingBreakdownPercentages.push(Math.round((results.Average / results.Total) * 100));
                ratingBreakdownPercentages.push(Math.round((results.Poor / results.Total) * 100));
                ratingBreakdownPercentages.push(Math.round((results.Bad / results.Total) * 100));

                setCurrentEventRatingBreakdown(ratingBreakdownData);
                setCurrentEventRatingBreakdownPercentages(ratingBreakdownPercentages);
                setCurrentEventReviewCount(results.Total);
                setCurrentEventRating(results['Average Rating']);

                getFeaturesAndCharacteristics();
            })
            .catch(error => {
                console.log(error);

                setCurrentEventRatingBreakdown([]);
                setCurrentEventRatingBreakdownPercentages(['...', '...', '...', '...', '...']);
                setCurrentEventReviewCount(0);
                setCurrentEventRating('0.00');
            });
    };

    const getTagHighlights = () => {
        const year = currentYear && currentYear !== 'all' ? `${currentYear}/` : '';
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}`
            + `/events/${params.id}`
            + `/review_tag_highlights/${year}`
        ;
        
        axios
            .get(apiCallURL, { headers })
            .then(({ data: { results } }) => {
                const totalTaggedReviews = results.reviews_with_tags;
                const taggedReviewData = [];
                for (let i = 0; i < results.tags.length; i++) {
                    const percentageValue = Math.round((results.tags[i].count / totalTaggedReviews) * 100);
                    let topValue = true;
                    if (percentageValue < 50) {
                        topValue = false;
                    }
                    taggedReviewData.push({
                        tag: results.tags[i].name,
                        percentage: percentageValue,
                        top: topValue,
                    });
                }
                setCurrentEventReviewTags(taggedReviewData);
                setAnalyticsLoaded(true);
            })
            .catch(error => {
                console.log(error);
                setAnalyticsLoaded(true);
            });
    };

    const getFeaturesAndCharacteristics = () => {
        const year = currentYear && currentYear !== 'all' ? `${currentYear}/` : '';
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}`
            + `/events/${params.id}`
            + `/review_features_and_characteristics/${year}`
        ;

        axios
            .get(apiCallURL, { headers })
            .then(({ data }) => {
                if (data.data && data.data['degree-of-challenge']) {
                    setDifficultyData(data.data['degree-of-challenge']);
                    setCurrentEventReviewTags([]);
                    setAnalyticsLoaded(true);
                }
                else {
                    setDifficultyData(null);
                    getTagHighlights();
                }
            })
            .catch(error => {
                console.log(error);
                setAnalyticsLoaded(true);
            });
    };

    const getYearsList = () => {
        const { id } = selectedOrganisation;
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${id}/events/${params.id}/reviewed_years/`;
        
        axios.get(apiCallURL, { headers })
            .then(({ data: { data } }) => setReviewedYears(data.reviewed_years))
            .catch(err => console.log(err.response));
    };

    const createYearSelect = () =>
        reviewedYears.map(option => (
            <option key={option} value={option}>
                {option}
            </option>
        ));

    const handleChange = ({ target }) => {
        if (target.name === 'currentYear') setCurrentYear(target.value);
    };          
    
    const getTrendsReviewData = () => {
        /* eslint-disable-next-line*/
        const apiCallURL = `${REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation.id}/events/${params.id}/rating_yoy_trend/`;
        
        axios
            .get(apiCallURL, { headers })
            .then(res => 
                setYearOnYearRatingData({
                    event_yoy_dataset: [...res?.data?.event_yoy],
                    category_yoy_dataset: [...res?.data?.category_yoy],
                    category: res?.data?.category,
                    trend: res?.data?.trend,
                    message: res?.data?.message
                })
            )
            .catch(error => console.log(error));
    };

    useEffect(() => {
        selectedOrganisation?.is_premium_subscriber && getRatingBreakdownData();
        selectedOrganisation?.is_premium_subscriber && getTrendsReviewData();
        selectedOrganisation?.is_premium_subscriber && getYearsList();
        // eslint-disable-next-line
    }, [currentYear]);

    useEffect(() => {
        selectedOrganisation?.is_premium_subscriber && event?.id && getComparativeAnalytics();
        // eslint-disable-next-line
    }, [event?.id]);

    const downloadReport = () => {
        mixpanel.track('Dashboard - Analytics - Download Overall Breakdown click', mpProps);
        setIsDownloadLoading(true);
        // eslint-disable-next-line max-len
        const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/organisations/${selectedOrganisation?.id}/events/${event?.id}/download_report/`;
        const headers = {
            Authorization: `Token ${token}`,
        };

        axios.post(apiCallURL, {
            path: `/events/${event?.id}/analytics/event/?year=${currentYear}`
        }, { headers })
            .then(({ data }) => {
                const fileUrl = data?.data?.report_url;
                const aElement = document.createElement('a');
                aElement.setAttribute('download', fileUrl?.slice(fileUrl?.lastIndexOf('/') + 1));
                aElement.href = fileUrl;
                aElement.click();
            })
            .catch(err => {
                if (err?.response?.status >= 400 && err?.response?.status < 500) {
                    setDownloadReportError(err?.response?.data?.error);
                }
            })
            .finally(() => {
                setIsDownloadLoading(false);
            });
    };


    const data = useMemo(() => {
        const totalEvents = comparativeAnalysis?.comparative_analysis?.total_events;
        labels = comparativeAnalysis?.comparative_analysis?.labels || labels;
        return {
            labels,
            datasets: [
                {
                    label: 'Percentage of events',
                    data: [
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p75_to_5 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p65_to_4p74 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p55_to_4p64 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4_to_4p54 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_1_to_4 / totalEvents * 100).toFixed(2)
                    ],
                    datalabels: {
                        color: '#9B9B9B',
                        anchor: 'end',
                        align: 'end',
                        offset: 3,
                        formatter (value) {
                            return (Math.round(value * 10) / 10).toFixed(1) + '%';
                        }
                    },
                    options: {
                        animation: {
                            duration: 0
                        }
                    },
                    borderColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'],
                    backgroundColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'],
                    borderRadius: 5,
                    barPercentage: .9,
                    categoryPercentage: 1,
                }
            ],
        };
    },[comparativeAnalysis]);

    const dataMobile = useMemo(() => {
        const totalEvents = comparativeAnalysis?.comparative_analysis?.total_events;
        labels = comparativeAnalysis?.comparative_analysis?.labels || labels;
        return {
            labels: [...labels].reverse(),
            datasets: [
                {
                    label: 'Percentage of events',
                    data: [
                        (comparativeAnalysis?.comparative_analysis?.events_rated_1_to_4 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4_to_4p54 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p55_to_4p64 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p65_to_4p74 / totalEvents * 100).toFixed(2),
                        (comparativeAnalysis?.comparative_analysis?.events_rated_4p75_to_5 / totalEvents * 100).toFixed(2)
                    ],
                    datalabels: {
                        color: '#9B9B9B',
                        anchor: 'end',
                        align: 'end',
                        offset: 3,
                        formatter (value) {
                            return (Math.round(value * 10) / 10).toFixed(1) + '%';
                        }
                    },
                    options: {
                        animation: {
                            duration: 0
                        }
                    },
                    borderColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'].reverse(),
                    backgroundColor: ['#FFCC00', '#CCCCC2', '#965A38', '#4ABF6C', '#EB8C57'].reverse(),
                    borderRadius: 5,
                    barPercentage: .9,
                    categoryPercentage: 1,
                }
            ],
        };
        // eslint-disable-next-line
    },[comparativeAnalysis]);

    const optionsMobile = useMemo(() => 
    // const totalAverageRating = comparativeAnalysis?.comparative_analysis?.event_average_rating;
    // const eventPercentile = comparativeAnalysis?.comparative_analysis?.event_percentile;
    // const convertedLabels = labels.map(label => [+label.split('-')[0], +label.split('-')[1]]);
    // const indexOfAverageRating = convertedLabels?.reduce((acc, [min, max], index) => {
    //     if (totalAverageRating >= min && totalAverageRating <= max) {
    //         return -0.25 + index + (max - totalAverageRating) / (max - min) * 0.5;
    //     }
    //     return acc;
    // }, 0);

        ({
            indexAxis: 'x',
            maintainAspectRatio: false,
            elements: {
                bar: {
                    borderWidth: 1
                },
            },
            scales: {
                x: {
                    max: 70,
                    min: 0,
                    title: {
                        display: true,
                        color: '#58585892',
                        text: 'Avg. Rating',

                        padding: 5,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                    }
                },
                y: {
                    min: 0,
                    max: 100,
                    title: {
                        display: true,
                        color: '#58585892',
                        text: '% of similar events',

                        padding: 5,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                    }
                },
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                    display: false
                },
                title: {
                    display: false,
                },
                annotation: {
                    // annotations: {
                    //     line1: {
                    //         type: 'line',
                    //         xMin: indexOfAverageRating,
                    //         xMax: indexOfAverageRating,
                    //         borderColor: '#BCBCBCBD',
                    //         borderWidth: 1,
                    //         font: {
                    //             size: 20
                    //         },
                    //         borderDash: [4, 3]
                    //     },
                    //     label1: {
                    //         type: 'label',
                    //         yValue: 50,
                    //         xValue: 2,
                    //         content: [`Top ${eventPercentile}%`],
                    //         color: '#3E3E3E',
                    //         font: {
                    //             size: 17,
                    //             weight: 'bold'
                    //         },
                    //         textAlign: 'end',
                    //         yAdjust: 3,
                    //         xAdjust: 3
                    //     },
                    //     label2: {
                    //         type: 'label',
                    //         // yValue: 55,
                    //         xValue: 2,
                    //         content: ['This event'],
                    //         color: '#58585874',
                    //         font: {
                    //             size: 12
                    //         },
                    //         textAlign: 'end',
                    //         // yAdjust: -8,
                    //         // xAdjust: 5
                    //     }
                    // }
                }
            },
        })
    , []);

    const options = useMemo(() => {
        const totalAverageRating = comparativeAnalysis?.comparative_analysis?.event_average_rating;
        const eventPercentile = comparativeAnalysis?.comparative_analysis?.event_percentile;
        const convertedLabels = labels.map(label => [+label.split('-')[0], +label.split('-')[1]]);
        const indexOfAverageRating = convertedLabels?.reduce((acc, [min, max], index) => {
            if (totalAverageRating >= min && totalAverageRating <= max) {
                return -0.25 + index + (max - totalAverageRating) / (max - min) * 0.5;
            }
            return acc;
        }, 0);

        return {
            indexAxis: 'y',
            reponsive: true,
            elements: {
                bar: {
                    borderWidth: 1
                },
            },
            scales: {
                x: {
                    max: 70,
                    min: 0,
                    title: {
                        display: true,
                        color: '#58585892',
                        text: '% of similar events',
                        padding: 5,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                        callback (value) {
                            return value + '%';
                        }
                    }
                },
                y: {
                    title: {
                        display: true,
                        color: '#58585892',
                        text: 'Avg. Rating',
                        padding: 10,
                        font: {
                            size: 14,
                        }
                    },
                    grid: {
                        color: '#BCBCBC',
                        display: false
                    },
                    ticks: {
                        color: '#58585874',
                        font: {
                            size: 12,
                        },
                    }
                },
            },
            responsive: true,
            plugins: {
                legend: {
                    position: 'right',
                    display: false
                },
                title: {
                    display: false,
                },
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            yMin: indexOfAverageRating,
                            yMax: indexOfAverageRating,
                            borderColor: '#BCBCBCBD',
                            borderWidth: 1,
                            font: {
                                size: 20
                            },
                            borderDash: [4, 3]
                        },
                        label1: {
                            type: 'label',
                            yValue: indexOfAverageRating,
                            xValue: 62,
                            content: [`Top ${eventPercentile}%`],
                            color: '#3E3E3E',
                            font: {
                                size: 17,
                                weight: 'bold'
                            },
                            textAlign: 'end',
                            yAdjust: 11,
                            xAdjust: -2
                        },
                        label2: {
                            type: 'label',
                            yValue: indexOfAverageRating,
                            xValue: 62,
                            content: ['This event'],
                            color: '#58585874',
                            font: {
                                size: 12
                            },
                            textAlign: 'end',
                            yAdjust: -8,
                            xAdjust: 5
                        }
                    }
                }
            },
        };
    }, [comparativeAnalysis]);


    const reviewTagBars = currentEventReviewTags.map(tagData => (
        <PercentageBar key={tagData.tag} tag={tagData.tag} value={tagData.percentage} top={tagData.top} />
    ));

    if (!selectedOrganisation?.is_premium_subscriber) {
        return (
            <div className="analytics-placeholder-container">
                <img src={OrgDashIllustration} alt="Illustration of Racecheck dashboard" />
                <h2>Upgrade to Racecheck Premium to access</h2>
                <p>Analytics reports are currently only available to Racecheck Premium {localiseText('organisers')}.
                     Upgrade your account to deep dive into your reviews and quickly
                      and easily see what you excel at alongside how you can improve.
                </p>
                <Link to="/subscription/subscription-plan" className="btn primary-btn"
                    onClick={() => {
                        /* eslint-disable-next-line */
                          mixpanel.track('Dashboard - Analytics - Upgrade to Premium click', mpProps);
                    }}
                >
                    Get started for free
                </Link>
            </div>
        );
    }
    if (!event?.reviews) {
        return (
            <div className="analytics-placeholder-container">
                <img src={ReviewsIllustration} alt="Illustration of Racecheck reviews" />
                <h2>This event hasn&apos;t been reviewed</h2>
                <p>Analytics reports will be available once you&apos;ve collected your first review for this event.
                     Start gathering feedback to learn how much value your Review Box
                      could be generating and see how your event compares against others.
                </p>
                <Link to={`/events/${event?.id}/request-reviews`} className="btn primary-btn">
                    Help collecting reviews
                </Link>
            </div>
        );
    }

    return (
        <div style={{ width: '100%' }}>
            {downloadReportError && (
                <ErrorMessage 
                    message={downloadReportError} 
                    resetErrorMessage={() => setDownloadReportError('')}
                />
            )} 
            {analyticsLoaded || (
                <div className="analytics-loader">
                    <Loader />
                </div>
            )}
            {analyticsLoaded && (
                <div>
                    {!currentEventReviewCount > 0 ? <NoDataPlaceholder /> : (
                        <div className="analytics-cards">
                            <div className="mr-download-wrap">
                                <button
                                    onClick={downloadReport}
                                    id="mr-download-btn"
                                    className="mr-download-btn mr-download-hide"
                                    disabled={!comparativeAnalysis || !analyticsLoaded || isDownloadLoading}
                                >
                                    {
                                        !comparativeAnalysis || !analyticsLoaded || isDownloadLoading ? ( 
                                            <img
                                                className="subscription-btn-loader"
                                                src={SmallLoader}
                                                alt="loader"
                                            />) : <>Download<Download /></>
                                    }
                                </button>
                            </div>
                            <Header
                                handleChange={handleChange}
                                currentYear={currentYear}
                                createYearSelect={createYearSelect}
                                event={event.title}
                            />
                            {/* <button className="btn primary-btn modal-form-btn disabled-primary-btn">
                                    <DownloadIcon />
                                    Download report
                                </button> */}
                            <div className="analytics-top-bar analytics-card">
                                <div className="top-bar-stat">
                                    <p className="top-bar-stat-title">
                                        <span className="tb-stat-title-desktop">Total reviews</span>
                                        <span className="tb-stat-title-mobile">reviews</span>
                                    </p>
                                    <p className="top-bar-stat-info">
                                        {currentEventReviewCount}
                                    </p>
                                </div>
                                <div className="top-bar-divider" />
                                <div className="top-bar-stat">
                                    <p className="top-bar-stat-title">
                                        <span className="tb-stat-title-desktop">
                                            Average rating
                                        </span>
                                        <span className="tb-stat-title-mobile">average rating</span>
                                    </p>
                                    <RatingStars rating={currentEventRating} />
                                </div>
                                <div className="top-bar-divider" />
                                <div className="top-bar-stat">
                                    <p className="top-bar-stat-title">
                                        <span className="tb-stat-title-desktop">
                                            Positive reviews
                                        </span>
                                        <span className="tb-stat-title-mobile">positive</span>
                                    </p>
                                    <p className="top-bar-stat-info">
                                        {currentEventRatingBreakdownPercentages[0] +
                                            currentEventRatingBreakdownPercentages[1]}
                                        %
                                    </p>
                                </div>
                            </div>
                            <div className="analytics-report-row">
                                <div className="analytics-review-trends analytics-card">
                                    <YearOnYearRating data={yearOnYearRatingData} />
                                </div>
                            </div>
                            <div className="analytics-report-row">
                                <div className="analytics-breakdown analytics-card">
                                    <p className="analytics-card-title">Rating Breakdown</p>
                                    <div className="rating-breakdown-chart">
                                        <RatingBreakdown
                                            ratingData={currentEventRatingBreakdown}
                                        />
                                    </div>
                                    <div className="rating-breakdown-legend">
                                        <p className="rating-breakdown-legend-tag">
                                            <span className="rating-breakdown-legend-color rblc-excellent" />
                                            Excellent
                                            <span className="rating-breakdown-legend-range">
                                                (4.5 to 5)
                                            </span>
                                            <span className="rating-breakdown-legend-percent">
                                                {currentEventRatingBreakdownPercentages[0]}%
                                            </span>
                                        </p>
                                        <p className="rating-breakdown-legend-tag">
                                            <span className="rating-breakdown-legend-color rblc-great" />
                                            Great
                                            <span className="rating-breakdown-legend-range">
                                                (3.5 to 4.49)
                                            </span>
                                            <span className="rating-breakdown-legend-percent">
                                                {currentEventRatingBreakdownPercentages[1]}%
                                            </span>
                                        </p>
                                        <p className="rating-breakdown-legend-tag">
                                            <span className="rating-breakdown-legend-color rblc-average" />
                                            Average
                                            <span className="rating-breakdown-legend-range">
                                                (2.5 to 3.49)
                                            </span>
                                            <span className="rating-breakdown-legend-percent">
                                                {currentEventRatingBreakdownPercentages[2]}%
                                            </span>
                                        </p>
                                        <p className="rating-breakdown-legend-tag">
                                            <span className="rating-breakdown-legend-color rblc-poor" />
                                            Poor
                                            <span className="rating-breakdown-legend-range">
                                                (1.5 to 2.49)
                                            </span>
                                            <span className="rating-breakdown-legend-percent">
                                                {currentEventRatingBreakdownPercentages[3]}%
                                            </span>
                                        </p>
                                        <p className="rating-breakdown-legend-tag">
                                            <span className="rating-breakdown-legend-color rblc-bad" />
                                            Bad
                                            <span className="rating-breakdown-legend-range">
                                                (0 to 1.49)
                                            </span>
                                            <span className="rating-breakdown-legend-percent">
                                                {currentEventRatingBreakdownPercentages[4]}%
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="tags-breakdown analytics-card">
                                    {difficultyData ?
                                        <>
                                            <p className="analytics-card-title">Event Difficulty</p>
                                            <div className="difficulty-breakdown-chart">
                                                <DifficultyChart
                                                    difficultyData={difficultyData}
                                                />
                                            </div>
                                            <p className="review-trends-description">
                                                The majority of participants found your event
                                                &apos;<b>{difficultyData.max_choice}</b>&apos;
                                            </p>
                                        </>
                                        :
                                        <>
                                            {reviewTagBars.length > 0 ? (
                                                <>
                                                    <p className="analytics-card-title">Review Tag Highlights</p>
                                                    <p className="analytics-value-explainer">(% of reviews)</p>
                                                    <div className="review-tag-charts">
                                                        {reviewTagBars}
                                                    </div>
                                                </>

                                            )
                                                :
                                                <>
                                                    <p className="analytics-card-title mr-download-hide">Event Difficulty</p>
                                                    <div className="empty-card-placeholder">
                                                        <p>
                                                            There are no &apos;Event Difficulty&apos; records yet.
                                                            Check back again soon.
                                                        </p>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {
                                comparativeAnalysis && (
                                    <div className="analytics-comparative analytics-card">
                                        <p className="analytics-card-title">Comparative Analysis</p>
                                        <p className="analytics-card-intro">
                                            How your rating compares to other events on the Racecheck platform.
                                        </p>
                                        <div className="analytics-comparative-chart">
                                            <Bar options={options} data={data} />
                                        </div>
                                        <div className="analytics-comparative-chart mobile">
                                            <Bar options={optionsMobile} data={dataMobile} height={null} width={null} />
                                        </div>
                                    </div>
                                )
                            }
                                
                            <div className="analytics-placeholder mr-download-hide">
                                <h3>Check back again soon!</h3>
                                <p>
                                    More insights to help you better understand your event feedback
                                    will be added to the analytics report soon.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}  
        </div>
    );

};


AnalyticsReport.propTypes = {
    setSectionError: PropTypes.func,
    event: PropTypes.shape({
        id: number,
        status: PropTypes.string,
        title: PropTypes.string,
        reviews: PropTypes.arrayOf()
    }),
};

AnalyticsReport.defaultProps = {
    setSectionError: () => {},
};

export default AnalyticsReport;
