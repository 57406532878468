import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ProfileIcon } from '../../images/icons/person.svg';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron_down.svg';
import { ReactComponent as EmailIcon } from '../../images/icons/email.svg';
import { ReactComponent as RoleIcon } from '../../images/icons/person.svg';
import { ReactComponent as RatingStar } from '../../images/stars/gold_star.svg';
import { ReactComponent as EmptyStar } from '../../images/stars/gold_star_empty.svg';
// import {ReactComponent as EditIcon} from '../../images/icons/edit.svg';
import EditTeamMemberModal from './EditTeamMemberModal';
import { localiseText } from '../../utils';


class TeamMemberCard extends Component {
    constructor (props) {
        super(props);
        this.state = {
            editTeamMemberModalOpen: false,
            teamMemberDetailsOpen: false,
        };
    }

    toggleEditTeamMemberModal = () => {
        const { editTeamMemberModalOpen } = this.state;
        editTeamMemberModalOpen
            ? this.setState({
                editTeamMemberModalOpen: false,
            })
            : this.setState({
                editTeamMemberModalOpen: true,
            });
    };

    toggleTeamMemberDetails = () => {
        const { teamMemberDetailsOpen } = this.state;
        teamMemberDetailsOpen
            ? this.setState({
                teamMemberDetailsOpen: false,
            })
            : this.setState({
                teamMemberDetailsOpen: true,
            });
    };

    render () {
        const { editTeamMemberModalOpen, teamMemberDetailsOpen } = this.state;
        const { avatar, name, isAdmin, selfIsAdmin, allowedEvents, email, selfEmail } = this.props;
        return (
            <div className="team-member-card">
                <div className="team-member-card-top">
                    <div className="team-member-card-left">
                        {avatar ? (
                            <img
                                className="team-member-card-profile-img"
                                src={avatar}
                                alt="user-profile-avatar"
                            />
                        ) : (
                            <div className="team-member-card-profile-img-placeholder">
                                <ProfileIcon />
                            </div>
                        )}
                    </div>
                    <div className="team-member-card-right">
                        <p
                            className={`tm-card-name ${teamMemberDetailsOpen ? 'tm-cn-open' : ''}`}
                            onClick={this.toggleTeamMemberDetails}
                        >
                            {name}
                            <ChevronDown />
                        </p>
                        <div className="tm-card-btns">
                            <p>{isAdmin ? `${localiseText('Organisation')} Admin` : 'Event Manager'}</p>
                            {!isAdmin &&
                                <>
                                    <p className="bullet">•</p>
                                    <p>
                                        {allowedEvents?.length}
                                        &nbsp;Event{allowedEvents?.length === 1 ? '' : 's'}
                                    </p>
                                </>
                            }
                            {/* <p className="bullet">•</p> */}
                            {/* <p onClick={this.toggleEditTeamMemberModal}>Edit</p> */}
                        </div>
                        {/* { this.props.role && <p className="tm-card-role">{this.props.role}</p>} */}
                        {/* <div className="team-member-card-priorities">
                            <p className="tm-card-priority">Editing</p>
                            <p className="tm-card-priority">Replying</p>
                        </div> */}
                    </div>
                </div>
                {teamMemberDetailsOpen && (
                    <div className="team-member-card-bottom">
                        {!isAdmin &&
                        <>
                            <div className="tm-card-events-list">
                                {allowedEvents.map(event => (
                                    <div className="tm-c-event" key={event.id}>
                                        <div className="tm-c-e-inner">
                                            <div className="event-details">
                                                {/* <div className="event-img">
                                                </div> */}
                                                <div className="event-copy">
                                                    <p className="event-c-title">{event.title}</p>
                                                    <div className="event-c-info">
                                                        {event.average_rating > 0 ?
                                                            <div className="rating-stars">
                                                                <p className="rating-value">{event.average_rating}</p>
                                                                <RatingStar />
                                                                <p className="rating-count">({event.reviews})</p>
                                                            </div>
                                                            :
                                                            <div className="rating-stars rs-unreviewed">
                                                                <p className="rating-value">Unreviewed</p>
                                                                <EmptyStar />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                        }
                        {/* <p className="tm-card-label">
                            <span className="tm-cl-header">Contact details</span>
                        </p> */}
                        <p className="tm-card-extra-details">
                            <EmailIcon />
                            {email}
                        </p>
                        {this.props.role && (
                            <p className="tm-card-extra-details">
                                <RoleIcon />
                                {this.props.role}
                            </p>
                        )}
                        {selfEmail !== email && selfIsAdmin &&
                            <div className="tm-card-edit-btn" onClick={this.toggleEditTeamMemberModal}>
                                <p>Manage permissions</p>
                            </div>
                        }
                    </div>
                )}
                {editTeamMemberModalOpen &&
                    <EditTeamMemberModal
                        memberID={this.props.memberID}
                        memberName={name}
                        isAdmin={isAdmin}
                        toggleModal={this.toggleEditTeamMemberModal}
                        eventsList={this.props.eventsList} 
                        assignedEvents={allowedEvents}
                        refreshTeamAudit={this.props.refreshTeamAudit}
                        refreshTeamMembers={this.props.refreshTeamMembers}
                    />
                }
            </div>
        );
    }
}

TeamMemberCard.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    memberID: PropTypes.number.isRequired,
    selfEmail: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    selfIsAdmin: PropTypes.bool.isRequired,
    allowedEvents: PropTypes.array,
    eventsList: PropTypes.array.isRequired,
    refreshTeamAudit: PropTypes.func.isRequired,
    refreshTeamMembers: PropTypes.func.isRequired
};

TeamMemberCard.defaultProps = {
    name: '',
    email: '',
    avatar: '',
    role: '',
    memberID: '',
    allowedEvents: [],
};

export default TeamMemberCard;
