import React from 'react';
import PropTypes from 'prop-types';
import { Radar } from 'react-chartjs-2';
import { Chart, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';

Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler
);


function DifficultyChart ({ difficultyData = [] }) {
    const data = {
        labels: difficultyData.choices.map(choice => choice.choice_title),
        datasets: [
            {
                label: '# of Votes',
                data: difficultyData.choices.map(choice => choice.choice_count),
                backgroundColor: 'rgba(74, 191, 108, 0.2)',
                borderColor: 'rgba(74, 191, 108, 1)',
                borderWidth: 1,
            }
        ],
    };

    const options = {
        title: {
            display: false,
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            r: {
                ticks: {
                    display: false
                }
            }
        },
        scale: {
            anglelines: {
                display: false
            },
            gridlines: {
                display: false
            },
            pointlabels: {
                display: false
            },
            ticks: {
                display: false
            },
        },
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display: false
            }
        }
    };

    return <Radar data={data} options={options} />;
}

DifficultyChart.propTypes = {
    difficultyData: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default DifficultyChart;
