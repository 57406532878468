import axios from 'axios';

export const reportError = (token, errorMessage, errorStack = 'Error has no stack', extraDetails) => {
    const apiCallURL = `${process.env.REACT_APP_API_URL}/api/react/emails/report_react_error/`;
    const headers = {
        Authorization: `Token ${token}`,
    };
    axios
        .post(
            apiCallURL,
            {
                error_message: errorMessage,
                error_stack: errorStack,
                error_details: JSON.stringify(extraDetails),
            },
            { headers }
        )
        .then(res => {
            console.log(res.data);
        })
        .catch(error => {
            console.log(error);
        });
};
