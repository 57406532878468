import React from 'react';
import './loader.css';

export default function Loader () {
    return (
        <div className="lds-roller">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
        </div>
    );
}
