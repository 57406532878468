import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import SectionNav from '../organiserDashboard/SectionNav';
import AppContext from '../app/AppContext';
import ErrorBoundary from '../helpers/errorBoundary';
import Toolkits from './Toolkits';
import Articles from './Articles';
import FAQ from './FAQ';

export default function Resources () {
    const appContext = useContext(AppContext);

    return (
        <div className={`section-container ${!appContext.menuOpen && 'section-container-main-nav-closed'}`}>
            <SectionNav section="resources" />
            <ErrorBoundary>
                <div
                    className={'section-content'}
                >
                    <Route path="/resources/articles">
                        <Articles />
                    </Route>
                    <Route path="/resources/toolkits">
                        <Toolkits />
                    </Route>
                    <Route path="/resources/faq">
                        <FAQ />
                    </Route>
                </div>
            </ErrorBoundary>
        </div>
    );
}