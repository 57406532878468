import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FreeReviewBannerWidget extends Component {
    constructor (props) {
        super(props);

        this.state = {};
    }

    componentDidMount () {
        const widgetScript = document.createElement('script');
        document.body.appendChild(widgetScript);

        /*eslint-disable*/
        let rcfrbScript = `
        (function(w, d, s, o, f, js, fjs) {
        w['RacecheckFreeReviewBanner']=o;w[o] = w[o] || function (){(w[o].q = w[o].q || []).push(arguments)};
        js = d.createElement(s); fjs = d.getElementsByTagName(s)[0];
        js.id = o;js.src = f;js.async = 1;fjs.parentNode.insertBefore(js, fjs);
        }(window, document, 'script', 'rcfrb', '${process.env.REACT_APP_API_URL}/static/js/free_review_banner.js'));
        rcfrb('init', {race: '${this.props.widgetId}', tracking: false, demo: true});`;
        {/* eslint-enable*/}
        widgetScript.type = 'text/javascript';
        widgetScript.innerHTML = rcfrbScript;
        this.checkForWidgetLoad();
    }

    componentWillUnmount () {
        window.rcfrb.widgetCleanup();
    }

    async checkForWidgetLoad () {
        /*eslint-disable*/
        while (!document.querySelector('#racecheck-free-review-banner-iframe')) {
            await new Promise(r => setTimeout(r, 100));
        }
        try {
            document.getElementById('racecheck-free-review-banner').appendChild(document.getElementById('racecheck-free-review-banner-iframe'));
            document.getElementById('racecheck-free-review-banner-iframe').style.display = 'block';
        }
        catch {}
        /* eslint-enable*/
    }

    render () {
        return <div id="racecheck-free-review-banner" />;
    }
}

FreeReviewBannerWidget.propTypes = {
    widgetId: PropTypes.number.isRequired
};

export default FreeReviewBannerWidget;
