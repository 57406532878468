import React, { Component } from 'react';
import './App.css';
import '../organiserDashboard/OrganiserDashboard.css';

import queryString from 'query-string';
import { Route, Switch, withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { hotjar } from 'react-hotjar';
import mixpanel from 'mixpanel-browser';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import AppContext from './AppContext';
import CreateOrganisation from '../logInSignUp/CreateOrganisation';
import StravaLogIn from '../logInSignUp/StravaLogIn';
import FacebookLogIn from '../logInSignUp/FacebookLogIn';
import TeamInvite from '../organiserDashboard/TeamInvite';
import LogInSignUp from '../logInSignUp/LogInSignUp';
import GoogleLogIn from '../logInSignUp/GoogleLogIn';
import ResetPassword from '../logInSignUp/ResetPassword';
// import LandingPage from '../organiserDashboard/LandingPage2022';
import Events from '../events/Events';
import Analytics from '../analytics/Analytics';
import Team from '../team/Team';
import Subscription from '../subscription/Subscription';
import Resources from '../resources/Resources';
import MainNav from '../organiserDashboard/MainNav';
import BookDemo from '../organiserDashboard/BookDemo';
// import Webinar from '../organiserDashboard/Webinar';
import NotFound from '../notFound';
import { shouldAllowCookies, localiseText, shouldShowCookieBanner, setCookieResponse } from '../../utils';
import { isPublicPage, isPrivatePage } from '../../routes/routes';
import { request } from '../../help/request';
import CookieBanner from '../helpers/cookieBanner';
import Usermaven from '../usermaven/Usermaven';

class App extends Component {
    constructor (props) {
        super(props);
        // Remove Cache if it's older than 24 hours old
        if (localStorage.getItem('lastUpdated')) {
            const lastUpdated = new Date(Number(localStorage.getItem('lastUpdated')));
            if (new Date() - lastUpdated > 24 * 60 * 60 * 1000) {
                // 24 hour
                localStorage.removeItem('lastUpdated');
                localStorage.removeItem('token');
                localStorage.removeItem('selectedOrganisation');
            }
        }

        const cachedSelectedOrganisation = localStorage.getItem('selectedOrganisation');
        const selectedOrganisation =
            cachedSelectedOrganisation &&
            cachedSelectedOrganisation !== 'null' &&
            cachedSelectedOrganisation !== 'undefined'
                ? JSON.parse(localStorage.getItem('selectedOrganisation'))
                : null;
        this.state = {
            dataReady: false,
            loggedIn: false,
            isUserDataLoading: false,
            isContactUsModalOpen: false,
            orgMembershipStatus: '',
            setOrgMembershipStatus: this.setOrgMembershipStatus,
            registering: false,
            setRegistering: this.setRegistering,
            user: {},
            setUser: this.setUser,
            token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
            setToken: this.setToken,
            selectedOrganisation,
            setSelectedOrganisation: this.setSelectedOrganisation,
            handleLogInState: this.handleLogInState,
            handleLogOut: this.handleLogOut,
            refreshUserDetails: this.refreshUserDetails,
            menuOpen: true,
            setMenuStatus: this.setMenuStatus,
            overlayMainNav: false,
            setMainNavOverlayStatus: this.setMainNavOverlayStatus,
            getMemberShipStatusAndDefaultOrganisation: this.getMemberShipStatusAndDefaultOrganisation,
            acceptTeamInvite: this.acceptTeamInvite,
            toggleContactUsModal: this.toggleContactUsModal,
            getClaimId: this.getClaimId
        };
    }

    setRegistering = registering => {
        this.setState({ registering });
    };

    setOrgMembershipStatus = orgMembershipStatus => {
        this.setState({ orgMembershipStatus });
    };

    setUser = user => {
        this.setState({ user });
    };

    setToken = token => {
        this.setState({ token });
    };

    toggleContactUsModal = () => {
        this.setState(prevState => ({
            isContactUsModalOpen: !prevState.isContactUsModalOpen
        }));
    };

    setSelectedOrganisation = selectedOrganisation => {
        this.setState({ selectedOrganisation });
        localStorage.setItem('selectedOrganisation', JSON.stringify(selectedOrganisation));
    };

    checkWindowWidth = () => {
        if (window.innerWidth < 1300 && this.state.menuOpen) {
            this.setMenuStatus(false);
        }
        else if (window.innerWidth >= 1300 && !this.state.menuOpen) {
            this.setMenuStatus(true);
        }
    };

    setMenuStatus = menuOpen => {
        this.setState({ menuOpen });
    };

    setMainNavOverlayStatus = overlayMainNav => {
        this.setState({ overlayMainNav });
    };

    refreshUserDetails = () => {
        const token = this.state.token ? this.state.token : localStorage.getItem('token');
        this.setState({
            isUserDataLoading: true,
        });

        let cachedSelectedOrganisation = localStorage.getItem('selectedOrganisation');
        cachedSelectedOrganisation =
            cachedSelectedOrganisation &&
            cachedSelectedOrganisation !== 'null' &&
            cachedSelectedOrganisation !== 'undefined'
                ? JSON.parse(localStorage.getItem('selectedOrganisation'))
                : null;

        let queryParams = '';
        if (this.getClaimId() && cachedSelectedOrganisation) {
            if (window.confirm(`Are you sure you want to claim this event under ${cachedSelectedOrganisation?.name}?`)) {
                queryParams += `?claim=${this.getClaimId()}`;
                if (cachedSelectedOrganisation) {
                    queryParams += `&organisation=${cachedSelectedOrganisation?.id}`;
                }
            }
            localStorage.removeItem('claim');
        }

        request(`/api/accounts/auth/user/${queryParams}`, 'GET')
            .then(res => {
                let orgMembershipStatus = '';
                let selectedOrganisation = null;
                if (res.data.organisations && res.data.organisations.length > 0) {
                    orgMembershipStatus = 'member-of-org';
                    if (cachedSelectedOrganisation) {
                        selectedOrganisation = res.data.organisations.find(({ id }) => id === cachedSelectedOrganisation.id);
                        if (selectedOrganisation === undefined) {
                            selectedOrganisation = res.data.organisations[0];
                            this.setState({
                                selectedOrganisation
                            });
                            localStorage.setItem('selectedOrganisation', JSON.stringify(selectedOrganisation));

                            // alert('You are no longer a member of this organisation. Reloading your session.');
                            // window.location.reload();
                        }
                    }
                    else {
                        selectedOrganisation = res.data.organisations[0];
                    }
                }
                else if (res.data.organisation_pending_requests && res.data.organisation_pending_requests.length > 0) {
                    orgMembershipStatus = 'organisation-pending-requests';
                }
                else {
                    orgMembershipStatus = 'no-org';
                    if (cachedSelectedOrganisation) {
                        alert(`You are no longer a member of this ${localiseText('organisation')}. Reloading your session.`);
                        this.props.history.push('/create-organisation');
                    }
                }

                orgMembershipStatus = this.props.location.pathname.includes('accept_team_invitation')
                    ? 'accepting-invite'
                    : orgMembershipStatus;

                if (
                    !isEqual(this.state.user, res.data) ||
                    !isEqual(this.state.selectedOrganisation, selectedOrganisation) ||
                    this.state.orgMembershipStatus !== orgMembershipStatus ||
                    this.state.token !== token ||
                    !this.state.loggedIn ||
                    !this.state.dataReady
                ) {
                    this.setState({
                        user: res.data,
                        selectedOrganisation,
                        orgMembershipStatus,
                        token,
                        loggedIn: true,
                        dataReady: true,
                        isUserDataLoading: false,
                    });
                    localStorage.removeItem('selectedOrganisation');
                    localStorage.setItem('selectedOrganisation', JSON.stringify(selectedOrganisation));
                }
               
                localStorage.setItem('lastUpdated', new Date().getTime());
            })
            .catch(error => {
                console.log('Failed to refresh User details or authorize token', error);
                this.handleLogOut();
                this.setState({
                    loggedIn: false,
                    dataReady: true,
                    isUserDataLoading: false
                });
            });
    };

    handleLogOut = () => {
        request('/api/accounts/auth/logout/', 'POST');
        localStorage.removeItem('lastUpdated');
        localStorage.removeItem('token');
        localStorage.removeItem('selectedOrganisation');
        sessionStorage.removeItem('invite_code');
        this.handleLogOutState();
        this.props.history.push('/');
    };

    handleLogOutState = () => {
        this.setState({
            dataReady: true,
            loggedIn: false,
            isUserDataLoading: false,
            orgMembershipStatus: '',
            registering: false,
            user: {},
            token: null,
            selectedOrganisation: null,
        });
    };

    handleLogInState = (token, membershipStatus, selectedOrganisation) => {
        localStorage.setItem('token', token);
        localStorage.setItem('lastUpdated', new Date().getTime());
        this.setSelectedOrganisation(selectedOrganisation);
        this.setState({
            token,
            loggedIn: true,
            dataReady: true,
            isUserDataLoading: false,
            orgMembershipStatus: membershipStatus,
        });
    };

    getMemberShipStatusAndDefaultOrganisation = user => {
        if (user.organisations && user.organisations.length > 0) {
            return {
                membershipStatus: 'member-of-org',
                defaultOrganisation: user.organisations[0],
            };
        }
        if (user?.organisation_lead) {
            return {
                membershipStatus: 'organisation-lead-claim',
                defaultOrganisation: null,
            };
        }
        if (user.organisation_pending_requests && user.organisation_pending_requests.length > 0) {
            return {
                membershipStatus: 'organisation-pending-requests',
                defaultOrganisation: null,
            };
        }
        return {
            membershipStatus: 'no-org',
            defaultOrganisation: null,
        };
    };

    acceptTeamInvite = res => {
        const inviteCode = sessionStorage.getItem('invite_code');
        const { token } = res.data;
        localStorage.setItem('token', token);

        request(`/api/react/accept_invitation/${inviteCode}/`, 'POST')
            .then(res => {
                this.handleLogInState(token, 'member-of-org', res.data.organisation);
                this.props.history.push(`/accept_team_invitation/${inviteCode}`);
            })
            .catch(error => {
                console.log('Could not accept invitation', error.response);
                sessionStorage.removeItem('invite_code');
                const { membershipStatus, defaultOrganisation } = this.getMemberShipStatusAndDefaultOrganisation(
                    res.data.user
                );
                this.handleLogInState(token, membershipStatus, defaultOrganisation);
                if (error.response.data.message.includes('expired')) {
                    this.props.history.push(`/accept_team_invitation/${inviteCode}`);
                }
                else {
                    this.props.history.push('/');
                }
            });
    };

    getClaimId = () => {
        let claimId = null;
        const queryParams = queryString.parse(this.props.location.search);
        if (queryParams.claim) return queryParams.claim;
        const storedClaimId = localStorage.getItem('claim');
        if (storedClaimId) {
            const [claimIdValue, lastUpdated] = storedClaimId?.split(',');
            // 7 days
            claimId = (new Date() - lastUpdated < 7 * 24 * 60 * 60 * 1000) ? claimIdValue : null;
        }
        return claimId;
    };

    componentDidMount () {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
        mixpanel.identify('RCD');
        if (shouldAllowCookies()) {
            hotjar.initialize(Number(process.env.REACT_APP_HOTJAR_ID), 6);
            mixpanel.clear_opt_in_out_tracking();
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'hs-script-loader';
            script.src = `//js-eu1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_ID}.js`;
            script.async = true;
            script.defer = true;
            document.body.prepend(script);
        }
        else {
            mixpanel.disable();
            mixpanel.opt_out_tracking();
        }

        window.addEventListener('resize', this.checkWindowWidth);
        this.checkWindowWidth();

        const queryParams = queryString.parse(this.props.location.search);
        // Handle claim requests from racecheck.com
        if (queryParams.claim) {
            localStorage.setItem('claim', queryParams.claim + ',' + new Date().getTime());
        }

        const token = this.state.token ? this.state.token : localStorage.getItem('token');
        if (token && !this.state.loggedIn) {
            this.refreshUserDetails();
        }
        else {
            this.setState({ dataReady: true });
        }

        const mpProps = this.state.selectedOrganisation ? {
            organisation: this.state.selectedOrganisation?.name,
            premium_subscriber: this.state.selectedOrganisation?.is_premium_subscriber
        } : {};
        mixpanel.track('Dashboard - Page Visit', mpProps);

        // Temporary: Handle redirects from racecheck.com
        if (queryParams.token) {
            localStorage.setItem('token', queryParams.token);
            localStorage.setItem('lastUpdated', new Date().getTime());
            return this.props.history.push('/');
        }

        if (!this.state.loggedIn && this.state.dataReady
            && isPrivatePage(this.props.location.pathname.slice(0,this.props.location.pathname.slice(1).indexOf('/') + 1))) {
            return this.props.history.push(`/login?next=${this.props.location.pathname}${this.props.location.search}`);
        }
    }

    componentDidUpdate (prevProps, prevState) {
        const { history, location } = this.props;
        const { loggedIn, orgMembershipStatus, isUserDataLoading, dataReady } = this.state;

        // Redirect if user doesn't have access to private page
        if (prevState.loggedIn !== loggedIn) {
            if (
                loggedIn && orgMembershipStatus === 'member-of-org' && 
                isPublicPage(location.pathname) && 
                !sessionStorage.getItem('invite_code')
            ) {
                return history.push('/events/events');
            }

            if (loggedIn && orgMembershipStatus === 'no-org' && isPublicPage(location.pathname)) {
                return history.push('/create-organisation');
            }

            if (!loggedIn && dataReady
                && isPrivatePage(location.pathname.slice(0,location.pathname.slice(1).indexOf('/') + 1))) {
                return this.props.history.push(`/login?next=${this.props.location.pathname}${this.props.location.search}`);
            }
           
        }
        if (!loggedIn && dataReady && isPrivatePage(location.pathname.slice(0,location.pathname.slice(1).indexOf('/') + 1))) {
            return history.push(`/login?next=${this.props.location.pathname}${this.props.location.search}`);
        }
        if (!prevState.loggedIn && !loggedIn && !isUserDataLoading && isPrivatePage(location.pathname)) {
            return this.props.history.push('/');
        }
    }

    render () {
        const { dataReady, loggedIn } = this.state;

        if (!dataReady) {
            return (
                <div className="social-login-loading-container">
                    <img
                        src={require('../../images/racecheck_logo_black.svg')}
                        width="250px"
                        alt="racecheck-logo"
                    />
                </div>
            );
        }

        return (
            <div className="App">
                <div className="log-in-comp">
                    <AppContext.Provider value={this.state}>
                        {shouldShowCookieBanner() &&
                            <CookieBanner setCookieResponse={setCookieResponse} />
                        }
                        {process.env.REACT_APP_USERMAVEN_ID !== '_' && <Usermaven /> }
                        <Switch>
                            <Route path="/404" exact component={NotFound} />
                            {/* <Route path="/webinar" exact render={() => <Webinar />} />*/}
                            {/* Public routes*/}
                            {
                                !loggedIn && (
                                    <>
                                        <Route path="/login" exact render={props => 
                                            <LogInSignUp {...props} registering={false} />}
                                        />
                                        <Route path="/signup" exact render={props => 
                                            <LogInSignUp {...props} registering />}
                                        />
                                        <Route path="/book-demo" exact render={() => <BookDemo />} />
                                        <Route path="/strava-login" exact render={() => <StravaLogIn />} />
                                        <Route path="/facebook-login" exact render={() => <FacebookLogIn />} />
                                        <Route path="/google-login" exact render={() => <GoogleLogIn />} />
                                        <Route path="/accept_team_invitation/:inviteCode" exact render={() => 
                                            <TeamInvite />}
                                        />
                                        <Route path="/reset-password" render={() => <ResetPassword />} />
                                        <Route path="/" exact render={
                                            () => window.location.replace(process.env.REACT_APP_API_URL)
                                        }
                                        />
                                        <Route path="*" component={NotFound} />

                                    </>
                                )
                            }

                            {/* Private routes*/}

                            {
                                loggedIn && (
                                    <div className="org-dash-container">
                                        <Route path="*" render={() => <MainNav />} />
                                        
                                        <Route path="/events" render={() => <Events />} />
                                        <Route path="/analytics" render={() => <Analytics />} />
                                        <Route path="/team" render={() => <Team />} />
                                        <Route path="/resources" render={() => <Resources />} />
                                        <Route path="/subscription" render={() => 
                                            <Subscription />}
                                        />
                                        <Route path="/accept_team_invitation/:inviteCode" exact render={(matchProps) => 
                                            <TeamInvite {...matchProps} />}
                                        />
                                        <Route path="/create-organisation" exact render={() => 
                                            <CreateOrganisation />}
                                        />
                                        <Route path="*" component={NotFound} />

                                    </div>
                                )
                            }
                        </Switch>
                    </AppContext.Provider>
                </div>
            </div>
        );
    }
}

export default withRouter(App);
