import React, { Component } from 'react';
import '../logInSignUp/LogInSignUp.css';
import AppContext from '../app/AppContext';
import HubspotWebinarForm from '../helpers/HubspotWebinarForm';

import { ReactComponent as TimeIcon } from '../../images/icons/clock.svg';
import { ReactComponent as DateIcon } from '../../images/icons/calendar.svg';

class Webinar extends Component {
    static contextType = AppContext;

    render () {
        return (
            <div className="logged_out_container">
                <a href="/">
                    <img
                        className="logged_out_logo"
                        src={require('../../images/racecheck_logo_black.svg')}
                        width="250px"
                        alt="racecheck-logo"
                    />
                </a>
                <div className="book-demo-container">
                    <div className="bdc-left bwc-left">
                        <h2>
                            Racecheck + Suada | Overcoming Uncertainty Webinar
                        </h2>
                        <p className="bwc-icon-p"><DateIcon />Tuesday 2nd May 2023</p>
                        <p className="bwc-icon-p"><TimeIcon />16:00 (BST)</p>
                        {/* eslint-disable */}
                        <p className="demo-intro">
                            If you&apos;re a mass participation event organiser you don&apos;t want to miss this completely free webinar discussing how to overcome uncertainty and motivate action in 2023 using behavioural science.
                        </p>
                        <p className="demo-intro">
                            We&apos;re living in unprecedented times of uncertainty, and people are working in hybrid or completely remote environments, so they&apos;re more used to isolation. You&apos;re trying to bring people together for mass participation sports events and this will be tougher than it ever was before.
                        </p>
                        <p className="demo-intro">
                            Learn how to win in 2023 and to benefit from behavioural science within your user journey to boost sales and sell out your experiences.
                        </p>
                        <p className="demo-intro">
                            Join this free webinar with Racecheck, the world&apos;s leading review platform for mass participation sport, and our special guest, David Thomson, Chief Executive Officer (CEO) and founder of Suada. David is a master of influence and persuasion and formerly one of only 6 trainers personally trained and endorsed by Dr Robert Cialdini to teach his Pre-suasion and Principles of Persuasion workshop.
                        </p>
                        {/* eslint-enable */}
                        {/* <div className="testimonial-images">
                            <img
                                src={require('./landing-page-images/logo10-grey.png')}
                                alt="Logo"
                                className="testimonial-img"
                            />
                            <img
                                src={require('./landing-page-images/logo6-grey.png')}
                                alt="Logo"
                                className="testimonial-img"
                            />
                            <img
                                src={require('./landing-page-images/logo12-grey.png')}
                                alt="Logo"
                                className="testimonial-img"
                            />
                            <p>+ more</p>
                        </div> */}
                    </div>
                    <div className="bdc-right bwc-right">
                        <HubspotWebinarForm
                            region="eu1"
                            portalId="25712074"
                            formId="7b957258-953a-4e5f-8902-8e34911ea95b"
                        />
                    </div>
                    {/* <p className="log-in-sign-up-link">
                        Don&apos;t need a demo? <Link to="/signup">Get started for free</Link>
                    </p> */}
                </div>
            </div>
        );
    }
};

export default Webinar;
